import { createTheme } from "@mui/material";
import { globalTheme } from "./globalTheme";

export const customFormTheme = createTheme({
  components: {
    ...globalTheme.components,
    MuiFormControl: {
      styleOverrides: {
        root: {
          "& > label": {
            color: "#E6EAEE !important",
            fontSize: 14,
            fontWeight: "300 !important",
            letterSpacing: "-0.01em",
          },
          "& > div": {
            border: "0px !important",
            borderRadius: "4px",
            background: "rgba(0, 0, 0, 0.1)",
            marginTop: "30px !important",
            "& > input::placeholder,& > textarea::placeholder, & > div > p": {
              color: "#B7BEC7",
              opacity: 0.6,
            },
            "& > input, & > textarea, & > div": {
              color: "#B7BEC7",
              padding: "15.5px 20px !important",
              lineHeight: "17px",
            },
            "& > fieldset": {
              border: 0,
            },
            "& > svg": {
              color: "#B7BEC7",
            },
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          background: "#0F2744",
          borderRadius: 4,
          marginTop: 7,
          "& > .MuiList-root": {
            padding: 8,
            "& > li": {
              borderRadius: 4,
              fontWeight: 400,
              fontSize: 14,
              padding: "12px 16px",
              lineHeight: "150%",
              color: "#B7BEC7",
              "&:hover": {
                background: "rgba(0, 0, 0, 0.1)",
              },
              "&.Mui-selected": {
                background: "rgba(0, 0, 0, 0.1)",
                color: "#B7BEC7",
                fontWeight: 600,
              },
            },
          },
        },
      },
    },
  },
});
