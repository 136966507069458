import { Box, Button, Grid, IconButton, Typography } from "@mui/material";

import CustomTable from "../../../shared/customTable";
import DropDown from "../../../shared/formElements/DropDown";
import { Link } from "react-router-dom";
import TextArea from "../../../shared/formElements/TextArea";
import TextInput from "../../../shared/formElements/TextInput";
import { purchase_labels } from "../../../constants";
import { reportLabel2 } from "../../pages/CreateOrder";
import { dropdownOptions } from "../../../shared/utils";
import { useEffect } from "react";
import { SkusById } from "./PurchaseOrderTable";
import { AddCircleOutline, Verified } from "@mui/icons-material";
import { handleDownload } from "../../../warehouse/components/MaterialReceivedReport/MrrTable";
import PrintableTable from "../PrintableTable";
import { MobileSATable } from "./MobileSATable";
import { ImageUpload } from "../../../shared/formElements/ImageUpload";

const getColumns = (isEditable, orderStatus) => {
  return [
    ...[
      ...(orderStatus === "" || !isEditable
        ? [
            {
              Header: "",
              id: "selection",
              width: 50
            }
          ]
        : [])
    ],
    {
      Header: "S.No",
      accessor: "sNo",
      width: 90,
      size: 0.5
    },
    {
      Header: "Item/ SKU",
      accessor: "item",
      width: 200,
      size: 2,
      Cell: ({ row, value }) => {
        return row.original?.skuCode ? (
          <Box display={"flex"} flexDirection={"column"} gap={1}>
            <Box display={"flex"} gap={1} alignItems={"center"}>
              {row.original?.image?.thumbUrl && (
                <img
                  src={row.original?.image?.thumbUrl}
                  alt={row.original?.brandName ?? "--"}
                  height={52}
                  width={50}
                  style={{ objectFit: "contain" }}
                />
              )}
              <Typography
                fontSize={12}
                fontWeight={600}
                color={"primary"}
                component={Link}
              >
                {row.original?.skuCode}
              </Typography>
            </Box>
            <Typography fontSize={13} color={"text.secondary"}>
              {`HSN : ${row.original?.hsn ?? "--"}`}
            </Typography>
          </Box>
        ) : (
          value
        );
      }
    },
    {
      Header: "Product",
      accessor: "product",
      width: 180,
      size: 1.5,
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          row.original?.productName && (
            <Box display={"flex"} flexDirection={"column"} gap={0.5}>
              <Typography fontSize={14}>{`Product: ${
                row.original?.productName ?? "--"
              }`}</Typography>
              <Typography fontSize={13} color={"text.secondary"}>{`Category: ${
                row.original?.categoryName ?? "--"
              }`}</Typography>
              <Typography fontSize={13} color={"text.secondary"}>{`Brand: ${
                row.original?.brandName ?? "--"
              }`}</Typography>
            </Box>
          )
        );
      }
    },
    {
      Header: "UOM",
      accessor: "uom",
      disableSortBy: true,
      width: 80,
      size: 1,
      Cell: ({ row }) => {
        return (
          row.original?.unit && (
            <Typography>{row.original?.unit?.name}</Typography>
          )
        );
      }
    },
    {
      Header: "Warehouse",
      accessor: "warehouse",
      size: 1.5,
      disableSortBy: true
    },
    {
      Header: "Ideal Stock Qty",
      accessor: "idealStockQuantity",
      disableSortBy: true,
      size: 1.5,
      Cell: ({ row }) => {
        return row.original?.skuCode
          ? row.original?.stock?.closingQuantity
          : "";
      }
    },
    {
      Header: "Actual Stock Qty",
      accessor: "actualStockQuantity",
      size: 1.5,
      disableSortBy: true
    },
    {
      Header: "Change",
      accessor: "change",
      size: 1,
      disableSortBy: true
    },
    {
      Header: "Reason",
      accessor: "reason",
      size: 1.5,
      disableSortBy: true
    }
  ];
};

const getRows = (
  rows,
  control,
  watch,
  isEditable,
  skuOptions,
  reasonsOptions = [],
  warehouseCode,
  status,
  remove
) => {
  return rows.map((row, index) => ({
    index: index,
    sNo: index + 1,
    isEditable: isEditable,
    status: status,
    ...row,
    item: !row.item && (
      <DropDown
        control={control}
        name={`newOrders.${index}.item`}
        options={dropdownOptions(skuOptions, "_id", "skuCode")}
        placeholder={
          Boolean(skuOptions?.length) ? "Select SKU" : "Select Warehouse"
        }
        allowSearch
      />
    ),
    warehouse: warehouseCode,
    actualStockQuantity:
      isEditable && Boolean(status) ? (
        row.actualStockQuantity
      ) : (
        <TextInput
          control={control}
          name={`newOrders.${index}.actualStockQuantity`}
          type="number"
          placeholder={"Actual qty"}
        />
      ),
    change:
      watch(`newOrders.${index}.actualStockQuantity`) !== "" &&
      watch(`newOrders.${index}.actualStockQuantity`) !== undefined
        ? parseFloat(
            +watch(`newOrders.${index}.actualStockQuantity`) -
              +row?.stock?.closingQuantity
          ).toFixed(2)
        : 0,
    reason:
      isEditable && Boolean(status) ? (
        row.reasonDisplay
      ) : (
        <DropDown
          control={control}
          name={`newOrders.${index}.reason`}
          options={reasonsOptions}
          placeholder={"Select Reasons"}
        />
      ),
    remove: remove
  }));
};

const StockAdjustmentTable = ({
  isPrint = false,
  fields,
  control,
  watch,
  append,
  remove,
  setValue,
  onRowSelectionChange,
  orderId,
  warehouseCode,
  options,
  SKUs,
  files,
  orderStatus,
  isEditable,
  onAdd,
  preparedBy
}) => {
  const skuWatch = watch(
    `newOrders.${fields.length ? fields.length - 1 : 0}.item`
  );

  useEffect(() => {
    if (skuWatch && fields.length > 0) {
      const skuData = SkusById(SKUs, skuWatch);
      remove(fields.length - 1);
      append({ ...skuData[0] });
    }
  }, [skuWatch, fields.length, remove, append, SKUs]);
  return (
    <>
      {isPrint ? (
        <Grid container>
          <PrintableTable
            columns={getColumns(isEditable, orderStatus)}
            rows={getRows(
              fields,
              control,
              watch,
              isEditable,
              SKUs,
              options?.reasons,
              warehouseCode,
              orderStatus
            )}
            docType="StockAdjustment"
          />
        </Grid>
      ) : (
        <>
          {(orderStatus === "" || (orderStatus === "Draft" && !isEditable)) && (
            <Grid item xs={12} sx={{ display: { xs: "block", md: "none" } }}>
              <IconButton onClick={onAdd}>
                <AddCircleOutline color="primary" />
              </IconButton>
            </Grid>
          )}
          <CustomTable
            columns={getColumns(isEditable, orderStatus)}
            data={getRows(
              fields,
              control,
              watch,
              isEditable,
              SKUs,
              options?.reasons,
              warehouseCode,
              orderStatus,
              remove
            )}
            onRowSelectionChange={onRowSelectionChange}
            mobileComponent={MobileSATable}
          />
        </>
      )}
      <Grid
        container
        spacing={2}
        sx={{ display: { xs: isPrint ? "flex" : "none", md: "flex" } }}
      >
        <Grid item xs={12}>
          <Box display={"flex"} flexDirection={"column"} gap={6}>
            <Box width={"500px"}>
              <TextArea
                control={control}
                name={"remarks"}
                label={purchase_labels.remarksLabel}
                disabled={Boolean(orderStatus) ? isEditable : false}
              />
            </Box>
            <Box display={"flex"} justifyContent={"space-between"}>
              {reportLabel2(purchase_labels.preparedByLabel, preparedBy)}
              <Box display={"flex"} flexDirection={"column"}>
                {files?.length > 0 && (
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    gap={2}
                    padding={"10px"}
                  >
                    {files?.map((file, index) => (
                      <Box
                        key={index}
                        display={"flex"}
                        alignItems={"center"}
                        gap={2}
                      >
                        <Verified sx={{ color: "primary.main" }} />
                        <Button
                          variant="link"
                          disableRipple
                          sx={{ fontWeight: 600 }}
                          onClick={() => handleDownload(file.fileUrl)}
                        >
                          {file.originalName}
                        </Button>
                      </Box>
                    ))}
                  </Box>
                )}
                {(orderStatus === "" || orderStatus === "Draft") && (
                  <Grid item xs={12} md={3}>
                    <ImageUpload
                      control={control}
                      name={"uploadAttachments"}
                      setValue={setValue}
                      compact={true}
                      uploadButtonText={"Upload Attachments"}
                      isMultiple={true}
                      acceptFileType=".jpg, .jpeg, .png, .pdf"
                      minimumUploads={files?.length ? 3 - files?.length : 3}
                      showTextOnly
                    />
                  </Grid>
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default StockAdjustmentTable;
