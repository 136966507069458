export const errorStrings = {
  "searchForm.pickup": "Pickup is not allowed for no transport service",
  "searchForm.additionalServices[1]":
    "AdditionalServices must be one of [Forks, Unloading]",
  "searchForm.outboundDate": "Outbound date is not allowed",
  success_message: "Transaction is successful.",
  failure_message: "Transaction has failed.",
  session_out_message: "Session expired for the transaction.",
  cancel_message: "Merchant has canceled the transaction from the bank page."
};

export const successStrings = {
  success_message_packing_notes: "Successfully saved packing notes"
};
