import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  AppBar,
  Box,
  Container,
  Grid,
  ListItemButton,
  styled,
  ThemeProvider,
  Toolbar,
  Typography
} from "@mui/material";
import { DashboardOutlined } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import { grey } from "@mui/material/colors";
import MobileNavLinkItem from "./MobileNavLinkItem";
import { PROFILE_NAV_LINK, filterNavLinks } from "./SideDrawer";
import { bottomAppBarTheme } from "../theme/bottomAppBarTheme";
import appRoutes from "../../constants/appRoutes";
import { AppContext } from "../context/auth-context";
import Storage from "../utils/Storage";
import { StorageKeys } from "../../constants/storage-keys";
import CustomTour from "../formElements/CustomTour";

const Puller = styled(Box)(() => ({
  width: 40,
  height: 3,
  backgroundColor: grey[300],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)"
}));

const SlideBox = styled(Box)(({ theme, open }) => ({
  position: "fixed",
  bottom: open ? 0 : "-50vh", // adjust the '-50vh' to make it hidden
  left: 0,
  right: 0,
  minHeight: "70px",
  backgroundColor: "#2773FF",
  transition: "bottom 0.5s ease-in-out",
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2),
  marginBottom: "70px",
  borderRadius: "8px 8px 0 0",
  borderBottom: "2px solid white"
}));

const BottomMobileAppBar = () => {
  const { profileData } = useContext(AppContext);
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const slideBoxRef = useRef(null);
  const moreLessButtonRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const searchFormParams = Storage.getItem(StorageKeys.SEARCH_FORM_PARAMS);
  // eslint-disable-next-line
  const [{ run, steps }, setSteps] = useState({
    run: !Boolean(searchFormParams),
    steps: [
      {
        content: "Book Warehouse or Transport",
        disableBeacon: true,
        placement: "top",
        target: "#Explore"
      },
      {
        content: "More other Services",
        disableBeacon: true,
        placement: "top",
        target: "#More"
      }
    ]
  });

  useEffect(() => {
    if (profileData) {
      setLoading(false);
    }
  }, [profileData]);

  const navLinks = useMemo(() => {
    return profileData
      ? filterNavLinks(
          profileData.user.role,
          profileData.user.allowedScModules,
          Boolean(profileData.user.client.scModuleStatus.name === "Active")
        )
      : [];
  }, [profileData]);

  const itemsPerPage = navLinks.length > 4 ? 3 : 4;

  const getInitialDisplayedLinks = () => {
    const currentPath = location.pathname;
    const currentIndex = navLinks.findIndex(
      (link) =>
        link.to === currentPath ||
        link.from === currentPath ||
        (link.children &&
          link.children.some((child) => child.to === currentPath))
    );

    if (currentIndex === -1 || navLinks.length <= itemsPerPage) {
      return navLinks.slice(0, itemsPerPage);
    }

    if (currentIndex === 0) {
      return navLinks.slice(0, 3);
    }

    if (currentIndex === navLinks.length - 1) {
      return navLinks.slice(-3);
    }

    return navLinks.slice(currentIndex - 1, currentIndex + 2);
  };

  const getInitialMoreLinks = (displayedLinks) => {
    return navLinks.filter((link) => !displayedLinks.includes(link));
  };

  const [displayedLinks, setDisplayedLinks] = useState([]);
  const [moreLinks, setMoreLinks] = useState([]);

  useEffect(() => {
    if (navLinks.length) {
      const initialDisplayedLinks = getInitialDisplayedLinks();
      setDisplayedLinks(initialDisplayedLinks);
      setMoreLinks(getInitialMoreLinks(initialDisplayedLinks));
    } // eslint-disable-next-line
  }, [navLinks]);

  const toggleBox = () => {
    setOpen(!open);
  };

  const handleNavLinkClick = (index) => {
    const navLinkIndex = navLinks.findIndex(
      (link) => link === moreLinks[index]
    );

    let newDisplayedLinks;

    if (navLinkIndex === 0) {
      // Selected the first item
      newDisplayedLinks = navLinks.slice(0, 3);
    } else if (navLinkIndex === navLinks.length - 1) {
      // Selected the last item
      newDisplayedLinks = navLinks.slice(-3);
    } else {
      // Selected any other item
      newDisplayedLinks = navLinks.slice(navLinkIndex - 1, navLinkIndex + 2);
    }

    const updatedMoreLinks = navLinks.filter(
      (link) => !newDisplayedLinks.includes(link)
    );

    setDisplayedLinks(newDisplayedLinks);
    setMoreLinks(updatedMoreLinks);
    setOpen(false);
  };

  const isProfileScreen =
    location.pathname.includes(appRoutes.profile.main) || false;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        slideBoxRef.current &&
        !slideBoxRef.current.contains(event.target) &&
        moreLessButtonRef.current &&
        !moreLessButtonRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (loading) {
    return null; // or a loading spinner, etc.
  }

  return (
    <>
      {location.pathname.includes(appRoutes.dashboard) && (
        <CustomTour run={run} steps={steps} isWarehouse={true} />
      )}
      <ThemeProvider theme={bottomAppBarTheme}>
        <AppBar color="primary">
          <Toolbar component={Container}>
            <SlideBox open={open} ref={slideBoxRef}>
              <Box
                sx={{
                  py: 1,
                  borderTopLeftRadius: 50,
                  borderTopRightRadius: 50
                }}
                component={"div"}
                onClick={toggleBox}
              >
                <Puller />
              </Box>
              <Grid container columnSpacing={1} rowSpacing={2}>
                {moreLinks.map(({ icon, name, to, from }, index) => (
                  <Grid item xs={3} key={name}>
                    <Box
                      sx={{
                        border: "0.6px solid #fff",
                        borderRadius: "8px",
                        display: "flex",
                        alignItems: "center",
                        minHeight: "70px"
                      }}
                      onClick={() => handleNavLinkClick(index)}
                    >
                      <MobileNavLinkItem
                        name={name}
                        icon={icon}
                        path={to ? to : from}
                      />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </SlideBox>
          </Toolbar>
        </AppBar>
      </ThemeProvider>
      <ThemeProvider theme={bottomAppBarTheme}>
        <AppBar position="fixed" color="primary">
          <Toolbar component={Container}>
            <Grid container spacing={1}>
              {isProfileScreen
                ? PROFILE_NAV_LINK.map(({ icon, name, to, from }) => (
                    <Grid item xs key={name}>
                      <MobileNavLinkItem
                        name={name}
                        icon={icon}
                        path={to ? to : from}
                      />
                    </Grid>
                  ))
                : displayedLinks.map(({ icon, name, to, from }) => (
                    <Grid item xs key={name}>
                      <div id={name}>
                        <MobileNavLinkItem
                          name={name}
                          icon={icon}
                          path={to ? to : from}
                        />
                      </div>
                    </Grid>
                  ))}
              {navLinks.length > 4 &&
                moreLinks.length > 0 &&
                !isProfileScreen && (
                  <Grid item xs>
                    <div id="More">
                      <ListItemButton
                        onClick={toggleBox}
                        ref={moreLessButtonRef}
                      >
                        <DashboardOutlined sx={{ fontSize: 20, margin: 0 }} />
                        <Typography>{open ? "Less" : "More"}</Typography>
                      </ListItemButton>
                    </div>
                  </Grid>
                )}
            </Grid>
          </Toolbar>
        </AppBar>
      </ThemeProvider>
    </>
  );
};

export default BottomMobileAppBar;
