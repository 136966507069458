import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Grid,
  Typography
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import {
  PackingNotesFormKeys,
  SearchFormKeys
} from "../../../constants/formKeys";
import { action_labels, labels, order_table_labels } from "../../../constants";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { AccordionCard } from "./PackingNotes/AccordionCard";
import { CalendarTodayOutlined } from "@mui/icons-material";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import DateInput from "../../../shared/formElements/DateInput";
import InfoIcon from "@mui/icons-material/Info";
import { LoadingButton } from "@mui/lab";
import SuccessfulDialog from "../../../shared/UiElements/SuccessfulDialog";
import TextInput from "../../../shared/formElements/TextInput";
import appRoutes from "../../../constants/appRoutes";
import { dateTimeFormatter } from "../../../shared/utils";
import { enqueueSnackbar } from "notistack";
import { isMobileDevice } from "../../../App";
import moment from "moment";
import { packingNotesSchema } from "./PackingNotes";
import styled from "@emotion/styled";
import { useCustomDialog } from "../../../shared/customDialog";
import { useGetAllPackingNotes } from "../../../query-hooks/PackagingNotes/useGetAllPackingNotes";
import { useGetOrderDetails } from "../../../query-hooks/Orders/useGetOrderDetails";
import { useRetrieveNotes } from "../../../query-hooks/PackagingNotes/useRetrieveNotes";
import { yupResolver } from "@hookform/resolvers/yup";

const StyledEditMessagedBox = styled(Box)(({ theme }) => ({
  padding: "10px",
  display: "flex",
  alignItems: "center",
  backgroundColor: theme.palette.warning.background,
  borderRadius: "8px",
  height: 72,
  width: "100%"
}));

const RetrievePackages = ({ onSubmit, outboundDate, units }) => {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      [SearchFormKeys.Units]: units,
      [SearchFormKeys.OutboundDate]: dateTimeFormatter(outboundDate),
      [SearchFormKeys.ShippingDate]: ""
    }
  });
  return (
    <Box sx={{ p: { xs: 1, md: 2 }, mt: 3, minHeight: { xs: 420, md: 450 } }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              sx={{
                height: 24,
                fontSize: { xs: 18, md: 20 },
                fontWeight: 500,
                lineHeight: "24.2px"
              }}
            >
              {order_table_labels.retrievePackages}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextInput
              disabled
              control={control}
              name={SearchFormKeys.Units}
              label={labels.noOfPackagesLabelText}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              disabled
              control={control}
              name={SearchFormKeys.OutboundDate}
              label={labels.outboundDateLabelText}
            />
          </Grid>
          <Grid item xs={12}>
            <DateInput
              control={control}
              name={SearchFormKeys.ShippingDate}
              label={labels.shippingDate}
              endIcon={CalendarTodayOutlined}
              maxDate={new Date(outboundDate)}
            />
          </Grid>
          <Grid item xs={12} display={{ md: "flex" }}>
            <StyledEditMessagedBox>
              <InfoIcon color={"warning"} />
              &nbsp;
              <Typography
                sx={{
                  pl: 1,
                  fontSize: 14,
                  fontWeight: 400,
                  lineHeight: "20px"
                }}
              >
                {order_table_labels.modifyRetrieveOrderShippingDateWarningMsg}
              </Typography>
            </StyledEditMessagedBox>
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 2, height: { xs: 48, md: 64 } }}
              fullWidth
            >
              {order_table_labels.requestOutbound}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

const RetrieveOrders = () => {
  const { showDialog, hideDialog } = useCustomDialog();
  const { uuid } = useParams();
  const navigate = useNavigate();

  const [expanded, setExpanded] = useState(false);
  const [isSelectPackage, setIsSelectPackage] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const { control, watch, reset } = useForm({
    defaultValues: {
      packagingNotes: []
    },
    resolver: yupResolver(packingNotesSchema)
  });

  const packagingNotesWatch = watch("packagingNotes");

  const { fields: packingNotesFields } = useFieldArray({
    control,
    name: "packagingNotes"
  });

  const {
    data: packagingNotesData,
    isFetching,
    isFetched,
    isError,
    error
  } = useGetAllPackingNotes(uuid);

  const { data: orderData, isFetching: isOrderDetailsFetching } =
    useGetOrderDetails(uuid);

  const orderDetails = !Boolean(orderData?.order?.warehouseOrder)
    ? {
        code: orderData?.order?.transportOrder.code,
        ...orderData?.order?.recentOrderItem
      }
    : {
        code: orderData?.order?.warehouseOrder.code,
        ...orderData?.order?.recentOrderItem
      };

  const { mutate, isLoading } = useRetrieveNotes();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const onSelectChange = (id) => {
    const selectedValues = [...selectedItems];
    const index = selectedValues?.indexOf(id);
    if (index > -1) {
      selectedValues.splice(index, 1);
    } else {
      selectedValues.push(id);
    }
    setSelectedItems([...selectedValues]);
  };

  const onRetrievePackagesSubmit = (data) => {
    const packagingNoteIds =
      selectedItems.length < 1
        ? packagingNotesData?.packagingNotes?.map((item) => item._id)
        : selectedItems.map((item) => item._id);

    mutate(
      {
        orderId: uuid,
        shippingDate: moment(data.shippingDate).format("YYYY-MM-DD"),
        packagingNoteIds: packagingNoteIds
      },
      {
        onSuccess: () => {
          showDialog({
            component: (
              <SuccessfulDialog
                text={action_labels.outboundRequestSuccess}
                subText={action_labels.warehousesRequestRespondMsgSuccess}
                btn1Text={order_table_labels.checkRetrievalStatus}
                btn2Text={order_table_labels.bookATransporter}
                btn1Callback={() => {
                  navigate(
                    `${appRoutes.orders.main}/${appRoutes.orders.retrieveListView}`
                  );
                  hideDialog();
                }}
                btn2Callback={() => {
                  navigate(appRoutes.explore.listings);
                  hideDialog();
                }}
              />
            ),
            backdropOff: true
          });
        },
        onError: (err) => {
          enqueueSnackbar(err?.response.data.message, {
            variant: "error"
          });
        }
      }
    );
  };

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(error?.response.data.message, { variant: "error" });
    }
  }, [error?.response.data.message, isError]);

  useEffect(() => {
    if (isFetched) {
      reset({
        packagingNotes: packagingNotesData?.packagingNotes?.map((item) => ({
          [PackingNotesFormKeys.PackageName]: item.name,
          [PackingNotesFormKeys.PackagingType]: item.packagingType.name,
          [PackingNotesFormKeys.PackageDimension]: item.packageDimension.name,
          [PackingNotesFormKeys.Items]: item.items.map((subItem) => ({
            [PackingNotesFormKeys.ItemName]: subItem.name,
            [PackingNotesFormKeys.MaterialSubCategory]:
              subItem.materialSubCategory.name,
            [PackingNotesFormKeys.Quantity]: subItem.quantity
          })),
          _id: item._id
        }))
      });
    }
  }, [isFetched, packagingNotesData?.packagingNotes, reset]);

  const onRetrievePackages = () => {
    showDialog({
      component: (
        <RetrievePackages
          onSubmit={onRetrievePackagesSubmit}
          outboundDate={orderDetails?.outboundDate}
          units={`${
            selectedItems.length < 1
              ? packagingNotesData?.packagingNotes?.length
              : selectedItems.length
          }/${packagingNotesData?.packagingNotes?.length}`}
        />
      )
    });
  };

  const ActionButtons = (
    <Box
      display={"flex"}
      sx={{
        flexDirection: {
          xs: "column",
          md: "row"
        },
        gap: {
          xs: 1,
          md: "15px"
        }
      }}
    >
      <Button
        variant={isSelectPackage ? "contained" : "soft"}
        sx={{ height: 48, minWidth: 180 }}
        onClick={() => {
          isSelectPackage
            ? selectedItems.length > 0 && onRetrievePackages()
            : setIsSelectPackage(true);
        }}
        {...(isMobileDevice && { fullWidth: true })}
      >
        {isSelectPackage
          ? order_table_labels.retrieveSelectedPackage(selectedItems.length)
          : order_table_labels.selectPackages}
      </Button>
      {isSelectPackage ? (
        <Button
          variant="soft"
          onClick={() => {
            setSelectedItems([]);
            setIsSelectPackage(false);
          }}
          sx={{
            height: 48,
            minWidth: 180
          }}
          {...(isMobileDevice && { fullWidth: true })}
        >
          {order_table_labels.cancel}
        </Button>
      ) : (
        <Button
          onClick={() => onRetrievePackages()}
          variant="contained"
          sx={{
            height: 48,
            minWidth: 180
          }}
          {...(isMobileDevice && { fullWidth: true })}
          component={LoadingButton}
          loading={isLoading}
        >
          {order_table_labels.retrieveAll}
        </Button>
      )}
    </Box>
  );

  return (
    <>
      <Box
        sx={{
          padding: {
            xs: "24px 16px",
            md: "16px 0 24px 0"
          },
          height: {
            xs: "calc(100vh - 250px)",
            md: "100%"
          },
          overflowX: {
            xs: "auto",
            md: "hidden"
          }
        }}
      >
        {isFetching || isOrderDetailsFetching ? (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isFetching || isOrderDetailsFetching}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <Grid container spacing={5}>
            <Grid
              item
              xs={12}
              sx={{
                display: {
                  xs: "none",
                  md: "block"
                }
              }}
            >
              <Box display={"flex"} flexDirection={"column"} gap={"14px"}>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Typography
                    sx={{
                      fontSize: 20,
                      fontWeight: 500,
                      lineHeight: "normal",
                      letterSpacing: "0.04px"
                    }}
                  >
                    {order_table_labels.retrievePackages}
                  </Typography>
                  {ActionButtons}
                </Box>
                <Divider sx={{ border: "0.7px solid #B7BEC7" }} />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={isMobileDevice ? "10px" : "22px"}>
                {packingNotesFields.map((item, index) => (
                  <Grid item xs={12} container key={item.id}>
                    {isSelectPackage && (
                      <Grid
                        item
                        xs={isMobileDevice ? 1.5 : 0.5}
                        sx={{
                          textAlign: "center",
                          alignSelf: !expanded ? "center" : "flex-start",
                          justifyContent: "flex-start",
                          display: "flex"
                        }}
                      >
                        <Checkbox
                          checkedIcon={<CheckBoxOutlinedIcon />}
                          onChange={() => onSelectChange(item)}
                        />
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={isSelectPackage ? (isMobileDevice ? 10.5 : 11.5) : 12}
                    >
                      <AccordionCard
                        isEdit={false}
                        control={control}
                        watch={watch}
                        item={item.id}
                        handleChange={handleChange}
                        expanded={expanded}
                        index={index}
                        packagingNotesWatch={packagingNotesWatch}
                        orderDetails={orderDetails}
                        qrImage={
                          packagingNotesData?.packagingNotes?.[index]?.qrImage
                        }
                      />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Box>
      {isMobileDevice && (
        <Box
          sx={{
            zIndex: 1101,
            borderTop: "0.6px solid #B7BEC7",
            p: "16px 12px",
            background: "#FFFFFF",
            width: "100%",
            position: "fixed",
            bottom: 0,
            left: 0
          }}
        >
          {ActionButtons}
        </Box>
      )}
    </>
  );
};

export default RetrieveOrders;