import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography
} from "@mui/material";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CustomTable from "../../shared/customTable";
import { CustomTooltip } from "../../shared/theme/globalTheme";
import Filter from "../../purchase/components/Filter";
import Ledger from "../../assets/Ledger.webp";
import {
  currencyFormatter,
  currencyOnlyFormatter,
  dropdownOptions
} from "../../shared/utils";
import { customer_filterKeys } from "../../constants/formKeys";
import { customer_table_labels } from "../../constants";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import appRoutes from "../../constants/appRoutes";
import { useFetchCustomers } from "../../query-hooks/ClientsCustomer/CustomerDetails/useFetchCustomers";
import { useInView } from "react-intersection-observer";
import { useEffect, useMemo, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { useFetchCustomerFilters } from "../../query-hooks/ClientsCustomer/CustomerDetails/useFetchCustomerFilters";
import { CustomTypo, SubtotalTypo } from "../../purchase/pages/Vendors";
import { useFetchCustomersSubtotal } from "../../query-hooks/ClientsCustomer/CustomerDetails/useFetchCustomersSubtotal";
import DropDown from "../../shared/formElements/DropDown";
import { Search } from "@mui/icons-material";
import { isMobileDevice } from "../../App";
import { VendorsMobileTable } from "../../purchase/components/Vendors/VendorsMobileTable";

const getRows = (rows = []) => {
  return rows.map((item, index) => {
    return {
      vendorId: item?._id,
      to: `${appRoutes.sales.main}/${appRoutes.sales.customers}/${item?._id}`,
      vendorCompanyName: item?.companyName,
      pocName: item?.contact?.name,
      mobileNo: item?.contact?.mobile,
      email: item?.contact?.email,
      invoiceValue: item?.totalInvoiceAmount
        ? currencyOnlyFormatter(parseFloat(item?.totalInvoiceAmount).toFixed(2))
        : "",
      amountPaid: item?.totalPaidAmount
        ? currencyOnlyFormatter(parseFloat(item?.totalPaidAmount).toFixed(2))
        : "",
      balance:
        item?.totalInvoiceAmount && item?.totalPaidAmount
          ? currencyOnlyFormatter(
              parseFloat(
                +item?.totalInvoiceAmount - +item?.totalPaidAmount
              ).toFixed(2)
            )
          : "",
      avgDue: item?.avgDue ?? ""
    };
  });
};

const Customers = () => {
  const [params, setParams] = useState();
  const { control, reset, handleSubmit, watch } = useForm();
  const navigate = useNavigate();

  const { data: filterData, isFetching: isFilterFetching } =
    useFetchCustomerFilters();

  const { data: subTotalAmount, isFetching: isFetchingSubtotals } =
    useFetchCustomersSubtotal(params);

  const CustomerWatch = watch(customer_filterKeys.customerName);

  const { ref, inView } = useInView();
  const {
    data,
    isFetching,
    isFetched,
    hasNextPage,
    fetchNextPage,
    refetch,
    isError,
    error
  } = useFetchCustomers(params);

  const getColumns = () => {
    return [
      {
        Header: "Customer Company Name",
        accessor: "vendorCompanyName",
        width: 228,
        Cell: ({ value, row }) => {
          return (
            <Typography
              fontSize={15}
              fontWeight={600}
              color={"primary"}
              component={Link}
              to={row.original.to}
            >
              {value}
            </Typography>
          );
        }
      },
      {
        Header: "POC Name",
        accessor: "pocName",
        width: 144
      },
      {
        Header: "Mobile No.",
        accessor: "mobileNo",
        width: 143
      },
      {
        Header: "Email Id",
        accessor: "email",
        width: 144
      },
      {
        Header: "Invoice Value",
        accessor: "invoiceValue",
        width: 140
      },
      {
        Header: "Amount Paid",
        accessor: "amountPaid",
        width: 140
      },
      {
        Header: "Balance",
        accessor: "balance",
        width: 110
      },
      {
        Header: "Avg. Due",
        accessor: "avgDue",
        width: 110
      },
      {
        Header: "Actions",
        accessor: "actions",
        disableSortBy: true,
        width: 77,
        Cell: ({ row, value }) => {
          return (
            value && (
              <CustomTooltip title={"Ledger"} placement="top">
                <IconButton>
                  <img
                    src={Ledger}
                    className="pull-right cursor-field"
                    alt={value}
                  />
                </IconButton>
              </CustomTooltip>
            )
          );
        }
      }
    ];
  };

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, inView]);

  const customerTable = useMemo(
    () => (
      <CustomTable
        columns={getColumns()}
        data={
          isFetched
            ? data?.pages?.flatMap((page) => getRows(page?.customers)) ?? []
            : []
        }
        isLoading={!isFetched}
        mobileComponent={VendorsMobileTable}
      />
    ),
    // eslint-disable-next-line
    [data?.pages, isFetched]
  );

  const onViewClick = (data) => {
    const filterData = {
      ...{
        ...(data["customerName"] !== ""
          ? {
              [`filters[customer]`]: data["customerName"]
            }
          : {})
      }
    };
    setParams(filterData);
  };

  useEffect(() => {
    if (isMobileDevice && CustomerWatch) {
      const filterData = {
        ...{
          ...(CustomerWatch !== "" && CustomerWatch !== undefined
            ? {
                [`filters[customer]`]: CustomerWatch
              }
            : {})
        }
      };
      setParams(filterData);
    } else if (isMobileDevice && !Boolean(CustomerWatch)) {
      setParams(null);
    } // eslint-disable-next-line
  }, [JSON.stringify(CustomerWatch)]);

  useEffect(() => {
    if (params) {
      refetch();
    }
  }, [params, refetch]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(error?.response?.data?.message, {
        variant: "error"
      });
    }
  }, [error?.response?.data?.message, isError]);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      sx={{
        mb: { xs: 9, md: 0 }
      }}
    >
      <Box
        flexDirection={"column"}
        gap={2}
        sx={{
          display: { xs: "none", md: "flex" },
          position: "sticky",
          top: "64px",
          zIndex: 1000,
          background: "#fff",
          padding: "16px 0"
        }}
      >
        <Grid item xs={12} textAlign={"end"}>
          <Button
            variant="link"
            disableRipple
            onClick={() => {
              navigate(
                `${appRoutes.sales.main}/${appRoutes.sales.customers}/${appRoutes.sales.addCustomerDetails}`
              );
            }}
          >
            <AddOutlinedIcon />
            {customer_table_labels.addNewCustomer}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Filter
              control={control}
              onApply={handleSubmit(onViewClick)}
              onClear={() => {
                reset();
                setParams(null);
              }}
              isFetching={isFilterFetching}
              isLoading={isFetching}
              filters={[
                {
                  type: "DropDown",
                  name: customer_filterKeys.customerName,
                  label: customer_table_labels.customerName,
                  unSelectText: "Select Customer",
                  allowSearch: true,
                  options: dropdownOptions(
                    filterData?.customers,
                    "_id",
                    "companyName"
                  ),
                  filterCount: filterData?.customers?.length
                }
              ]}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              border: "0.6px solid #2773FF",
              borderRadius: 2,
              padding: 4,
              display: "flex",
              gap: 3,
              justifyContent: "flex-end"
            }}
          >
            {isFetchingSubtotals ? (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                width={"100%"}
              >
                <CircularProgress color="primary" />
              </Box>
            ) : (
              <>
                <CustomTypo color="primary">Subtotal :</CustomTypo>
                {SubtotalTypo(
                  subTotalAmount?.subTotals?.totalInvoiceAmount
                    ? currencyFormatter(
                        parseFloat(
                          subTotalAmount?.subTotals?.totalInvoiceAmount
                        ).toFixed(2)
                      )
                    : "--",
                  "Invoice Value"
                )}
                {SubtotalTypo(
                  subTotalAmount?.subTotals?.totalPaidAmount
                    ? currencyFormatter(
                        parseFloat(
                          subTotalAmount?.subTotals?.totalPaidAmount
                        ).toFixed(2)
                      )
                    : "--",
                  "Amount Paid"
                )}
                {SubtotalTypo(
                  !Boolean(subTotalAmount?.subTotals?.totalPaidAmount)
                    ? subTotalAmount?.subTotals?.totalInvoiceAmount
                      ? currencyOnlyFormatter(
                          parseFloat(
                            +subTotalAmount?.subTotals?.totalInvoiceAmount
                          ).toFixed(2)
                        )
                      : "--"
                    : currencyOnlyFormatter(
                        parseFloat(
                          +subTotalAmount?.subTotals?.totalInvoiceAmount -
                            +subTotalAmount?.subTotals?.totalPaidAmount
                        ).toFixed(2)
                      ),
                  "Balance"
                )}
                {SubtotalTypo(
                  subTotalAmount?.subTotals?.avgDueDays ?? "--",
                  "Avg. Due"
                )}
              </>
            )}
          </Box>
        </Grid>
      </Box>
      <Grid
        container
        spacing={2}
        sx={{
          display: { xs: "flex", lg: "none" },
          alignItems: "center",
          position: "sticky",
          top: "60px",
          zIndex: 1,
          background: "#fff",
          padding: "10px 20px"
        }}
      >
        <Grid item xs={8}>
          <DropDown
            control={control}
            name={customer_filterKeys.customerName}
            placeholder={"Search Customer"}
            startIcon={Search}
            options={dropdownOptions(
              filterData?.customers,
              "_id",
              "companyName"
            )}
            allowSearch
            availUnselect
            unSelectText="Select Customer"
            sx={{ borderRadius: 54 }}
          />
        </Grid>
        <Grid item xs={4} display={"flex"} justifyContent={"right"}>
          <Button
            variant="link"
            disableRipple
            sx={{
              fontSize: "13px",
              lineHeight: "16px",
              fontWeight: 700
            }}
            onClick={() =>
              navigate(
                `${appRoutes.sales.main}/${appRoutes.sales.customers}/${appRoutes.sales.addCustomerDetails}`
              )
            }
          >
            {customer_table_labels.addNewCustomerMobile}
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ padding: { xs: "0 15px", md: 0 } }}>{customerTable}</Box>
      {hasNextPage && (
        <Grid
          item
          xs={12}
          ref={ref}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress />
        </Grid>
      )}
    </Box>
  );
};

export default Customers;
