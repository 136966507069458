import { Box, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { AntSwitch } from "../../shared/theme/globalTheme";
import APTable from "../components/Payments/APTable";
import ARTable from "../components/Payments/ARTable";
import { useInView } from "react-intersection-observer";
import { useFetchAllInvoices } from "../../query-hooks/ClientOrderInvoice/useFetchAllInvoices";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import { useFetchPaymentsSubtotal } from "../../query-hooks/ClientOrderInvoice/useFetchPaymentsSubtotal";
import { AppContext } from "../../shared/context/auth-context";

const Payments = () => {
  const { isSwitchChecked, handleSwitchToggle } = useContext(AppContext);
  const [params, setParams] = useState();

  const { data: subTotalAmount, isFetching: isFetchingSubtotals } =
    useFetchPaymentsSubtotal(
      params,
      isSwitchChecked ? "PoInvoice" : "SoInvoice"
    );

  const { ref, inView } = useInView();
  const {
    data,
    isFetching,
    isFetched,
    hasNextPage,
    fetchNextPage,
    refetch,
    isError,
    error
  } = useFetchAllInvoices(params, isSwitchChecked ? "PoInvoice" : "SoInvoice");

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, inView]);

  const onViewClick = (data) => {
    const filterData = {
      ...{
        ...(data["fromDate"] !== undefined
          ? {
              [`filters[fromDate]`]: moment(data["fromDate"]).format(
                "YYYY-MM-DD"
              )
            }
          : {})
      },
      ...{
        ...(data["toDate"] !== undefined
          ? {
              [`filters[toDate]`]: moment(data["toDate"]).format("YYYY-MM-DD")
            }
          : {})
      },
      ...{
        ...(data?.date[0] !== null && data?.date[0] !== undefined
          ? {
              [`filters[fromDate]`]: moment(data?.date[0]).format("YYYY-MM-DD")
            }
          : {})
      },
      ...{
        ...(data?.date[1] !== null && data?.date[1] !== undefined
          ? {
              [`filters[toDate]`]: moment(data?.date[1]).format("YYYY-MM-DD")
            }
          : {})
      }
    };
    setParams(filterData);
  };

  useEffect(() => {
    if (params) {
      refetch();
    }
  }, [params, refetch]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(error?.response?.data?.message, {
        variant: "error"
      });
    }
  }, [error?.response?.data?.message, isError]);

  return (
    <Box display={"flex"} flexDirection={"column"} gap={4}>
      <Box
        justifyContent={"flex-start"}
        alignItems={"center"}
        gap={1}
        sx={{
          display: { xs: "none", md: "flex" },
          position: "sticky",
          top: "64px",
          zIndex: 1000,
          background: "#fff",
          padding: "16px 0"
        }}
      >
        <Typography
          sx={{
            color: isSwitchChecked ? "#C4C4C4" : "#2773FF",
            fontSize: "20px",
            fontWeight: 700,
            lineHeight: "20px"
          }}
        >
          {"AR"}
        </Typography>
        <AntSwitch
          checked={isSwitchChecked}
          onChange={handleSwitchToggle}
          inputProps={{ "aria-label": "toggle switch" }}
        />
        <Typography
          sx={{
            color: isSwitchChecked ? "#F9A90E" : "#D9D9D9",
            fontSize: "20px",
            fontWeight: 700,
            lineHeight: "20px"
          }}
        >
          {"AP"}
        </Typography>
      </Box>
      {isSwitchChecked ? (
        <APTable
          data={data}
          subTotal={subTotalAmount?.subTotals}
          isFetchingSubtotals={isFetchingSubtotals}
          isFetching={isFetching}
          isFetched={isFetched}
          ref={ref}
          hasNextPage={hasNextPage}
          refetch={refetch}
          isSwitchChecked={isSwitchChecked}
          setParams={setParams}
          onViewClick={onViewClick}
        />
      ) : (
        <ARTable
          data={data}
          subTotal={subTotalAmount?.subTotals}
          isFetchingSubtotals={isFetchingSubtotals}
          isFetching={isFetching}
          isFetched={isFetched}
          ref={ref}
          hasNextPage={hasNextPage}
          refetch={refetch}
          isSwitchChecked={isSwitchChecked}
          setParams={setParams}
          onViewClick={onViewClick}
        />
      )}
    </Box>
  );
};

export default Payments;
