import { Drawer, createTheme } from "@mui/material";

import appRoutes from "../../constants/appRoutes";
import { globalTheme } from "./globalTheme";
import { styled } from "@mui/material/styles";

export const drawerWidth = 200;
export const drawerWidthProfile = 311;

export const drawerTheme = createTheme({
  ...globalTheme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        root: ({ theme }) => ({
          "& > .MuiPaper-root": {
            zIndex: 0,
            background: theme.palette.secondary.light,
            border: 0
          }
        })
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          "& > .MuiTypography-root": {
            fontFamily: "Anton",
            color: "#607088",
            textTransform: "uppercase"
          }
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "auto",
          marginRight: "12px"
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.primary,
          "& > .MuiListItemIcon-root": {
            color: theme.palette.text.primary,
            "& > .MuiSvgIcon-root": {
              fill: "transparent"
            }
          },
          "& > .MuiListItemText-root > span": {
            fontSize: "0.875rem",
            lineHeight: "1.25rem"
          },
          "&.Mui-selected": {
            background: "#fff",
            color: theme.palette.primary.main,
            "& > .MuiListItemIcon-root": { color: theme.palette.primary.main },
            "& > .MuiListItemText-root > span": {
              fontWeight: 600
            }
          }
        })
      }
    },
    MuiCollapse: {
      styleOverrides: {
        wrapperInner: ({ theme }) => ({
          "& > .MuiList-root > .MuiButtonBase-root": {
            color: theme.palette.text.secondary
          }
        })
      }
    }
  }
});

export const StyledDrawer = styled((props) => (
  <Drawer
    {...props}
    sx={{
      width: window.location.pathname.includes(appRoutes.profile.main)
        ? drawerWidthProfile
        : drawerWidth,
      flexShrink: 0,
      "& .MuiDrawer-paper": {
        width: window.location.pathname.includes(appRoutes.profile.main)
          ? drawerWidthProfile
          : drawerWidth,
        boxSizing: "border-box",
        height: "100%"
      },
      display: {
        sm: "block",
        xs: "none"
      }
    }}
  />
))(({ token, theme }) => ({}));

export const collapseMenuTheme = createTheme({
  ...globalTheme,
  components: {
    MuiCollapse: {
      wrapperInner: ({ theme }) => ({})
    },
    MuiListItemButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.secondary,
          maxHeight: "20px",
          marginBottom: 16,
          marginLeft: "40px",
          marginRight: 1,
          borderRadius: 2,
          "&:last-child": {
            marginBottom: 0
          },
          "& > .MuiListItemIcon-root": {
            "& > .MuiSvgIcon-root": {
              fontSize: "1.25rem"
            }
          },
          "& > .MuiListItemText-root > span": {
            fontSize: "0.875rem",
            lineHeight: "1.25rem"
          },
          "&:hover": {
            background: "transparent"
          },
          "&.Mui-selected": {
            background: "transparent",
            "&:hover": {
              background: "transparent"
            },
            color: theme.palette.text.primary,
            borderLeft: "2px solid #2773FF",
            borderRadius: 0,
            paddingLeft: 14,
            "& > .MuiListItemText-root > span": {
              fontWeight: 400
            }
          }
        })
      }
    }
  }
});
