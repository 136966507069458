import { getSalesReturnFormData } from "../../../sales/services";
import { queryKeys } from "../../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchSalesReturnFormData = (orderId, orderType) => {
  const querySalesReturnFormData = useQuery(
    [queryKeys.queryFetchSalesReturnFormData, orderId],
    () => getSalesReturnFormData(orderId),
    {
      enabled: Boolean(orderId) && Boolean(orderType === "salesReturns"),
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return querySalesReturnFormData;
};
