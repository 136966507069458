import {
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputBase,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  ThemeProvider,
  Typography
} from "@mui/material";
import { CustomCheckedIcon, CustomIcon } from "./CheckBoxGroup";

import { Controller } from "react-hook-form";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import { checkboxSelectTheme } from "../theme/checkboxSelectTheme";
import { useState } from "react";

const CheckBoxSelect = ({
  label,
  placeholder,
  helperText,
  name,
  control,
  options = [],
  disabled = false,
  startIcon: StartIcon,
  isLoading = false,
  defaultValue = [],
  mandatoryField = false,
  allowSearch = false,
  sx = {}
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredOptions = options?.filter(
    ({ displayName }) =>
      typeof displayName === "string" &&
      displayName?.toLowerCase()?.includes(searchTerm?.toLowerCase())
  );
  return (
    <ThemeProvider theme={checkboxSelectTheme}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field, fieldState: { error } }) => (
          <FormControl fullWidth disabled={disabled}>
            {label && (
              <InputLabel shrink htmlFor={name}>
                {label}
                {mandatoryField && <span style={{ color: "#F05454" }}> *</span>}
              </InputLabel>
            )}
            <Select
              {...field}
              inputProps={{
                id: name
              }}
              multiple={true}
              error={!!error}
              IconComponent={
                isLoading ? CircularProgress : KeyboardArrowDownOutlined
              }
              displayEmpty
              startAdornment={
                StartIcon && (
                  <InputAdornment position="start">
                    <StartIcon />
                  </InputAdornment>
                )
              }
              renderValue={(value) => {
                const selected = value.map((item) => {
                  let valObj = options.find((val) => val.name === item);
                  return valObj;
                });

                return field?.value?.length > 0 ? (
                  selected.map((item) => item?.displayName).join(", ")
                ) : (
                  <Typography fontSize="14px" color="text.disabled">
                    {placeholder}
                  </Typography>
                );
              }}
              onClose={() => setSearchTerm("")}
            >
              {allowSearch && (
                <Box
                  px={1}
                  py={1}
                  position="sticky"
                  top={0}
                  zIndex={1}
                  bgcolor="white"
                >
                  <InputBase
                    autoFocus
                    sx={sx}
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onClick={(e) => e.stopPropagation()}
                    onKeyDown={(e) => e.stopPropagation()}
                  />
                </Box>
              )}
              {allowSearch
                ? filteredOptions?.map(({ displayName, name }) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox
                        checked={field.value.indexOf(name) > -1}
                        checkedIcon={<CustomCheckedIcon />}
                        icon={<CustomIcon />}
                      />
                      <ListItemText primary={displayName} />
                    </MenuItem>
                  ))
                : options?.map(({ displayName, name }) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox
                        checked={field.value.indexOf(name) > -1}
                        checkedIcon={<CustomCheckedIcon />}
                        icon={<CustomIcon />}
                      />
                      <ListItemText primary={displayName} />
                    </MenuItem>
                  ))}
            </Select>
            {helperText && (
              <FormHelperText sx={{ letterSpacing: "0.1em" }}>
                {helperText}
              </FormHelperText>
            )}
            {error && <FormHelperText error>{error.message}</FormHelperText>}
          </FormControl>
        )}
      />
    </ThemeProvider>
  );
};

export default CheckBoxSelect;
