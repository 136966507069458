import { deleteMrrDraft } from "../../inventory/services";
import { useMutation } from "@tanstack/react-query";

export const useDeleteMRRDraft = () => {
  const deleteDraft = useMutation(({ orderId, mrrId }) =>
    deleteMrrDraft(orderId, mrrId)
  );

  return deleteDraft;
};
