import { Box, Typography } from "@mui/material";

import { CustomTabs } from "../../shared/customTab/CustomTabs";
import { NAV_LINK } from "../../shared/layouts/SideDrawer";
import appRoutes from "../../constants/appRoutes";
import { useContext } from "react";
import Vendors from "./Vendors";
import PurchaseOrders from "./PurchaseOrders";
import { AppContext } from "../../shared/context/auth-context";

const Purchase = () => {
  const { activeMobileTab, handleMobileTabChange } = useContext(AppContext);

  const componentPath = {
    [appRoutes.purchase.vendors]: <Vendors />,
    [appRoutes.purchase.purchaseOrders]: <PurchaseOrders />
  };

  const MenuItems = NAV_LINK.find(
    (item) => item.name === "Purchase"
  )?.children?.map((menu) => {
    return {
      label: menu.name,
      Component: componentPath[menu.to]
    };
  });

  return (
    <Box
      sx={{
        mb: { xs: 9 }
      }}
    >
      <Typography
        sx={{
          padding: "20px 21px 18px 21px",
          background: "#EEF1F4",
          fontSize: "16px",
          lineHeight: "19.36px",
          fontWeight: 700
        }}
      >
        {"Purchase"}
      </Typography>
      <CustomTabs
        tabs={MenuItems}
        activeTab={activeMobileTab.Purchase}
        onChange={handleMobileTabChange}
        secondaryTab={true}
      />
    </Box>
  );
};

export default Purchase;
