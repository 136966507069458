export const appServiceKeys = {
  warehouse: "warehouse",
  transport: "transport"
};

export const SearchFormKeys = {
  service: "service",
  city: "city",
  truckType: "truckType",
  IsTransportServiceNeeded: "isTransportServiceNeeded",
  PickupLocation: "pickupLocation",
  PickupLocationCoordinates: "pickupLocationCoordinates",
  Destination: "destination",
  DestinationCoordinates: "destinationCoordinates",
  InboundDate: "inboundDate",
  OutboundDate: "outboundDate",
  MaterialDetails: "materialDetails",
  PackagingType: "packagingType",
  ItemDescription: "itemDescription",
  Dimension: "dimension",
  Units: "units",
  StorageType: "storageType",
  OtherServices: "otherServices",
  AdditionalFeature: "additionalFeature",
  ConfirmPickupLocation: "confirmPickupLocation",
  loginMobileNumber: "loginMobileNumber",
  otp: "otp",
  pallets: "pallets",
  totalWeight: "totalWeight",
  PickUpDate: "pickUpDate",
  ShippingDate: "shippingDate"
};

export const BookOrderFormKeys = {
  PickUpDate: "pickUpDate",
  PickUpTime: "pickUpTime",
  FirstName: "firstName",
  LastName: "lastName",
  FirmsName: "firmsName",
  FirmsEmail: "firmsEmail",
  GSTIN: "gstin",
  Contact: "contact",
  StreetAddress: "streetAddress",
  City: "city",
  Country: "country",
  Area: "area",
  ZipCode: "zipCode",
  AcceptTnC: "acceptTnC",
  State: "state"
};

export const ListingsFormKeys = {
  SortBy: "sortBy"
};

export const rfqServiceKeys = {
  warehouse: "warehouse",
  transport: "transport",
  warehousePriceExpectation: "warehousePriceExpectation",
  transportPriceExpectation: "transportPriceExpectation",
  warehouseRfq: "warehouseRfq",
  transportRfq: "transportRfq"
};

export const inquiryKeyMap = {
  warehouseRfq: "warehouse",
  transportRfq: "transport"
};

export const cartServiceKeys = {
  warehouse: "warehouse",
  transport: "transport"
};

export const contactUsFormKeys = {
  name: "name",
  number: "number",
  email: "email",
  subject: "subject",
  description: "description"
};

export const TableFieldKeys = {
  search: "search"
};

export const CancelInquiryFormKeys = {
  reasonCancel: "reasonCancel"
};

export const OrderStatusKeys = {
  Completed: "Completed",
  ToBePicked: "ToBePicked",
  Cancelled: "Cancelled",
  VehicleAssigned: "VehicleAssigned",
  InTransit: "InTransit",
  ReceivedAtFacility: "ReceivedAtFacility",
  Stored: "Stored",
  PartiallyRetrieved: "PartiallyRetrieved"
};

export const PackingNotesFormKeys = {
  PackageName: "name",
  MaterialCategory: "materialCategory",
  PackagingType: "packagingType",
  PackageDimension: "packageDimension",
  ItemName: "name",
  MaterialSubCategory: "materialSubCategory",
  Quantity: "quantity",
  Items: "items"
};

export const LedgerStatusKeys = {
  Partial: "Partial",
  InvoicePending: "Invoice pending",
  Paid: "Paid"
};

export const RetrieveStatusKeys = {
  GatePassRequested: "GatePassRequested",
  GatePassPrepared: "GatePassPrepared"
};

export const regretFormKeys = {
  regretReason: "regretReason",
  otherReason: "otherReason"
};

export const ProfileFormKeys = {
  FirstName: "firstName",
  LastName: "lastName",
  Phone: "phone",
  Email: "email",
  FirmsName: "firmsName",
  GSTIN: "gstin",
  NatureOfBusiness: "natureOfBusiness",
  Products: "products",
  Materials: "Materials",
  Brands: "brands",
  Street: "street",
  City: "city",
  State: "state",
  Area: "Area",
  ZipCode: "zipCode",
  annualTurnover: "annualTurnover",
  Logo: "Logo"
};

export const inventory_filterKeys = {
  brands: "brands",
  category: "category",
  product: "product",
  sku: "sku",
  warehouse: "warehouse",
  dates: "dates",
  attribute1: "attribute1",
  attribute2: "attribute2",
  name: "name",
  vendorName: "vendorName"
};

export const eWayBills_filterKeys = {
  supplyType: "supplyType",
  status: "status",
  date: "date",
  ewayBillNo: "ewayBillNo",
  gstin: "gstin"
};

export const register_filterKeys = {
  warehouse: "warehouse",
  docType: "docType",
  docNumber: "docNumber",
  status: "status",
  date: "date"
};

export const sku_filterKeys = {
  productName: "productName",
  brandName: "brandName",
  categoryName: "categoryName",
  attribute1: "attribute1",
  attribute2: "attribute2",
  attribute3: "attribute3",
  units: "units",
  gst: "gst",
  sku: "sku",
  hsn: "hsn",
  upc: "upc",
  listPrice: "listPrice",
  minimumStockLevel: "minimumStockLevel",
  currentStockLevel: "currentStockLevel",
  imageUpload: "imageUpload"
};

export const addWarehousesKeys = {
  mapWarehouse: "mapWarehouse",
  address: "address",
  area: "area",
  city: "city",
  state: "state",
  pin: "pin",
  code: "code",
  ownership: "ownership",
  storage: "storage"
};

export const createMrrkeys = {
  invoiceNo: "invoiceNo",
  invoiceDate: "invoiceDate",
  invoiceAmount: "invoiceAmount",
  truckRegNo: "truckRegNo",
  remarks: "remarks",
  attachments: "attachments"
};

export const gatePassKeys = {
  EwayBillNo: "ewayBillNo",
  transporter: "transporter",
  truckRegNo: "truckRegNo",
  remarks: "remarks"
};

export const vendorFormKeys = {
  vendorCompanyName: "vendorCompanyName",
  contactName: "contactName",
  contact: "contact",
  email: "email",
  warehouseAddress: "warehouseAddress",
  lat: "lat",
  lng: "lng",
  billingAddress: "billingAddress",
  address: "address",
  area: "area",
  city: "city",
  state: "state",
  zipCode: "zipCode",
  billingArea: "billingArea",
  billingCity: "billingCity",
  billingState: "billingState",
  billingZipCode: "billingZipCode",
  gst: "gst",
  accountType: "accountType",
  bankName: "bankName",
  accountName: "accountName",
  accountNumber: "accountNumber",
  ifsc: "ifsc",
  pan: "pan",
  skuDealing: "skuDealing",
  useSameBillingAddress: "useSameBillingAddress",
  selectIfUnregistered: "selectIfUnregistered",
  cancelledCheque: "cancelledCheque"
};

export const customer_filterKeys = {
  brands: "brands",
  category: "category",
  product: "product",
  sku: "sku",
  name: "name",
  customerName: "customerName"
};

export const SalesOrders_filterKeys = {
  docType: "docType",
  warehouse: "warehouse",
  status: "status",
  docNo: "docNo",
  date: "date",
  customer: "customer",
  vendor: "vendor",
  sku: "sku",
  Headers: "Headers",
  salesman: "salesman"
};

export const quotes_filterKeys = {
  docType: "docType",
  status: "status",
  date: "date",
  customer: "customer",
  salesman: "salesman",
  orderType: "Order Type",
  salesRepresentative: "salesRepresentative",
  customerName: "customerName",
  customerBilling: "customerBilling",
  dateOfDelivery: "dateOfDelivery",
  paymentTerms: "paymentTerms",
  customerShipTo: "customerShipTo",
  shipping: "shipping",
  termAndCondition: "termAndCondition"
};

export const add_customer_fiterKeys = {
  customerCompanyName: "customerCompanyName",
  contactName: "contactName",
  contact: "contact",
  emailId: "emailId",
  address: "billingAddress",
  area: "area",
  city: "city",
  state: "state",
  zipCode: "ZipCode",
  shipToAddress: "shipToAddress",
  shipToArea: "shipToArea",
  shipToCity: "shipToCity",
  shipToState: "shipToState",
  shipToZipCode: "shipToZipCode",
  gstin: "gstin",
  unRegister: "unRegister",
  accountType: "accountType",
  bankName: "bankName",
  accountName: "accountName",
  accountNumber: "accountNumber",
  IFSC: "IFSC",
  PAN: "PAN",
  cancelledCheck: "cancelledCheck",
  pointofContact: "PointofContact",
  mobileNo: "MobileNo",
  mapstore: "mapstore",
  codeArea: "codeArea",
  lat: "lat",
  lng: "lng"
};

export const allocation_keys = {
  docType: "docType",
  date: "date",
  customer: "customer",
  salesman: "salesman",
  warehouses: "warehouses"
};

export const InvoiceUploadKeys = {
  invoiceId: "invoiceId",
  invoiceDate: "invoiceDate",
  invoiceAmount: "invoiceAmount",
  manualInvoice: "manualInvoice"
};
