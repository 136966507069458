import * as yup from "yup";

import { Box, Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Assets_label, error_msg } from "../../../constants";
import { useForm } from "react-hook-form";
import AutoCompleteMapField from "../../../shared/formElements/AutoCompleteMapField";
import { addWarehousesKeys } from "../../../constants/formKeys";
import TextInput from "../../../shared/formElements/TextInput";
import DropDown from "../../../shared/formElements/DropDown";
import { enqueueSnackbar } from "notistack";
import { useCreateWarehouse } from "../../../query-hooks/WarehouseAssets/useCreateWarehouse";
import { useFetchAssetWarehouseById } from "../../../query-hooks/WarehouseAssets/useFetchAssetWarehouseById";
import { useUpdateWarehouse } from "../../../query-hooks/WarehouseAssets/useUpdateWarehouse";
import { LoadingButton } from "@mui/lab";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFetchCreateWarehouseFormData } from "../../../query-hooks/WarehouseAssets/useFetchCreateWarehouseFormData";
import { useFetchUpdateWarehouseFormData } from "../../../query-hooks/WarehouseAssets/useFetchUpdateWarehouseFormData";
import { dropdownOptions } from "../../../shared/utils";
import { reverseGeocode } from "../../../shared/utils/reverseGeocode";

const schema = yup.object({
  [addWarehousesKeys.mapWarehouse]: yup.string().required(error_msg.required),
  [addWarehousesKeys.address]: yup.string().required(error_msg.required),
  [addWarehousesKeys.area]: yup.string().required(error_msg.required),
  [addWarehousesKeys.city]: yup.string().required(error_msg.required),
  [addWarehousesKeys.state]: yup.string().required(error_msg.required),
  [addWarehousesKeys.pin]: yup.string().required(error_msg.required),
  [addWarehousesKeys.code]: yup.string().required(error_msg.required),
  [addWarehousesKeys.ownership]: yup.string().required(error_msg.required),
  [addWarehousesKeys.storage]: yup.string().required(error_msg.required)
});

const AddWarehouses = ({
  refetch,
  warehouseId = null,
  setShowAddWarehouse,
  handleCardClick,
  hideDrawer,
  isMobile = false
}) => {
  const [destinationCoordinates, setDestinationCoordinates] = useState([]);
  const { control, watch, setValue, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema)
  });

  const { data: formOptions, isFetching: isFetchingOptions } =
    useFetchCreateWarehouseFormData(warehouseId);

  const { data: warehouseOptions, isFetching: isWarehouseOptions } =
    useFetchUpdateWarehouseFormData(warehouseId);

  const {
    data: warehouseData,
    isFetched: isFetchedWarehouse,
    refetch: refetchWarehouseById
  } = useFetchAssetWarehouseById(warehouseId);

  const {
    mutate: createWarehouse,
    isError: isCreateError,
    error: createError,
    isLoading: isCreating
  } = useCreateWarehouse();

  const {
    mutate: UpdateWarehouse,
    isError: isUpdateError,
    error: UpdateError,
    isLoading: isUpdating
  } = useUpdateWarehouse();

  const area = watch(addWarehousesKeys.area);

  useEffect(() => {
    if (isFetchedWarehouse && warehouseId) {
      reverseGeocode(
        warehouseData?.warehouse?.address?.location?.coordinates[0],
        warehouseData?.warehouse?.address?.location?.coordinates[1]
      )
        .then((address) => {
          setValue(addWarehousesKeys.mapWarehouse, address);
        })
        .catch((error) =>
          enqueueSnackbar(`Reverse geocoding failed: ${error}`, {
            variant: "success"
          })
        );
      setDestinationCoordinates(
        warehouseData?.warehouse?.address?.location?.coordinates
      );
      setValue(
        addWarehousesKeys.address,
        warehouseData?.warehouse?.address?.address
      );
      setValue(addWarehousesKeys.area, warehouseData?.warehouse?.address?.area);
      setValue(addWarehousesKeys.city, warehouseData?.warehouse?.address?.city);
      setValue(
        addWarehousesKeys.state,
        warehouseData?.warehouse?.address?.state
      );
      setValue(
        addWarehousesKeys.pin,
        warehouseData?.warehouse?.address?.pincode
      );
      setValue(
        addWarehousesKeys.code,
        warehouseData?.warehouse?.code?.length > 3
          ? warehouseData?.warehouse?.code.substring(3)
          : ""
      );
      setValue(
        addWarehousesKeys.ownership,
        warehouseData?.warehouse?.ownership
      );
      setValue(
        addWarehousesKeys.storage,
        warehouseData?.warehouse?.storageArea?.total
      );
    }
    if (!warehouseId) {
      reset();
    }
    // eslint-disable-next-line
  }, [isFetchedWarehouse, warehouseId]);

  useEffect(() => {
    if (!warehouseId && area) {
      setValue(addWarehousesKeys.code, area.slice(0, 6).toUpperCase());
    }
  }, [warehouseId, area, setValue]);

  const onSaveClick = (data) => {
    const formData = {
      code: `WH-${data[addWarehousesKeys.code]}`,
      ...{
        ...(data[addWarehousesKeys.ownership] && {
          ownership: data[addWarehousesKeys.ownership]
        })
      },
      ...{
        ...(data[addWarehousesKeys.storage] && {
          storageArea: {
            total: data[addWarehousesKeys.storage]
          }
        })
      },
      address: {
        address: data[addWarehousesKeys.address],
        area: data[addWarehousesKeys.area],
        city: data[addWarehousesKeys.city],
        state: data[addWarehousesKeys.state],
        pincode: data[addWarehousesKeys.pin],
        location: {
          coordinates: destinationCoordinates
        }
      }
    };
    warehouseId
      ? UpdateWarehouse(
          { data: { ...formData }, warehouseId: warehouseId },
          {
            onSuccess: () => {
              refetchWarehouseById();
              if (!isMobile) {
                handleCardClick(null);
                setShowAddWarehouse(false);
              } else {
                hideDrawer();
              }
              enqueueSnackbar(`Warehouse Updated!`, {
                variant: "success"
              });
              refetch();
            }
          }
        )
      : createWarehouse(
          { data: { ...formData } },
          {
            onSuccess: () => {
              if (!isMobile) {
                setShowAddWarehouse(false);
              } else {
                hideDrawer();
              }
              enqueueSnackbar(`Warehouse Created!`, {
                variant: "success"
              });
              refetch();
            }
          }
        );
  };

  useEffect(() => {
    if (isCreateError)
      enqueueSnackbar(createError?.response?.data?.message, {
        variant: "error"
      });
    if (isUpdateError)
      enqueueSnackbar(UpdateError?.response?.data?.message, {
        variant: "error"
      });
  }, [
    UpdateError?.response?.data?.message,
    createError?.response?.data?.message,
    isUpdateError,
    isCreateError
  ]);

  const DetailForm = (
    <Grid container rowSpacing={2}>
      <Grid item xs={12} md={6} container rowSpacing={2} columnSpacing={1}>
        <Grid item xs={12}>
          <AutoCompleteMapField
            control={control}
            name={addWarehousesKeys.mapWarehouse}
            placeholder={Assets_label.mapWarehouse}
            reset={(address, location) => {
              setValue(addWarehousesKeys.mapWarehouse, address);
              setDestinationCoordinates([location.lat, location.lng]);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            control={control}
            name={addWarehousesKeys.address}
            placeholder={Assets_label.address}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            control={control}
            name={addWarehousesKeys.area}
            placeholder={Assets_label.area}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            control={control}
            name={addWarehousesKeys.city}
            placeholder={Assets_label.city}
          />
        </Grid>
        <Grid item xs={6}>
          <DropDown
            control={control}
            name={addWarehousesKeys.state}
            placeholder={Assets_label.state}
            isLoading={isFetchingOptions || isWarehouseOptions}
            options={
              warehouseId
                ? dropdownOptions(warehouseOptions?.states, "name", "name")
                : dropdownOptions(formOptions?.states, "name", "name")
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            control={control}
            name={addWarehousesKeys.pin}
            placeholder={Assets_label.pin}
          />
        </Grid>
      </Grid>
      <Grid item xs={0} md={2}></Grid>
      <Grid item xs={12} md={4} container rowSpacing={2} columnSpacing={1}>
        <Grid item xs={12} container>
          <Grid item xs={2.5}>
            <Box
              sx={{
                border: "1px solid #B7BEC7",
                borderRadius: "8px",
                padding: "10px 16px 10px 16px",
                fontWeight: 500
              }}
            >
              {"WH"}
            </Box>
          </Grid>
          <Grid item xs={9.5}>
            <TextInput
              control={control}
              name={addWarehousesKeys.code}
              placeholder={Assets_label.code}
            />
          </Grid>
        </Grid>
        <Grid item xs={6} md={12}>
          <DropDown
            control={control}
            name={addWarehousesKeys.ownership}
            placeholder={Assets_label.ownership}
            isLoading={isFetchingOptions || isWarehouseOptions}
            options={
              warehouseId
                ? dropdownOptions(warehouseOptions?.ownerships)
                : dropdownOptions(formOptions?.ownerships)
            }
          />
        </Grid>
        <Grid item xs={6} md={12} container>
          <Grid item xs={8} md={9.5}>
            <TextInput
              control={control}
              name={addWarehousesKeys.storage}
              placeholder={Assets_label.storage}
            />
          </Grid>
          <Grid item xs={4} md={2.5}>
            <Box
              sx={{
                border: "1px solid #B7BEC7",
                borderRadius: "8px",
                padding: "10px 16px 10px 16px",
                fontWeight: 500
              }}
            >
              {"Sft"}
            </Box>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: { xs: "none", md: "flex" } }}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Button
            variant="contained"
            sx={{ width: "100px", height: "48px" }}
            component={LoadingButton}
            loading={isCreating || isUpdating}
            onClick={handleSubmit(onSaveClick)}
          >
            {"Save"}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"center"}
        width={"100%"}
        sx={{
          padding: { xs: "20px", md: "20px 10px" },
          alignItems: { xs: "center", md: "flex-start" },
          flexDirection: { xs: "column", md: "row" }
        }}
      >
        {DetailForm}
      </Box>
      <Grid
        container
        sx={{ display: { xs: "flex", md: "none" }, background: "#F0F2F5" }}
        justifyContent={"center"}
        alignItems={"center"}
        minHeight={"72px"}
      >
        <Button
          variant="contained"
          sx={{ width: "100px", height: "40px" }}
          component={LoadingButton}
          loading={isCreating || isUpdating}
          onClick={handleSubmit(onSaveClick)}
        >
          {"Save"}
        </Button>
      </Grid>
    </>
  );
};

export default AddWarehouses;
