import {
  Box,
  FormHelperText,
  InputLabel,
  TextareaAutosize,
  styled
} from "@mui/material";
import React, { useState } from "react";

import { Controller } from "react-hook-form";

const StyledTextArea = styled(TextareaAutosize)(({ theme }) => ({
  width: "100%",
  border: "1px solid #C1C7CF",
  padding: 12,
  borderRadius: 4,
  fontFamily: "Inter",
  fontSize: 14,
  marginTop: 8,
  marginBottom: -7,
  color: theme.palette.text.primary,
  "&::placeholder ": {
    color: theme.palette.text.disabled
  },
  "&:focus": {
    border: `1px solid ${theme.palette.primary.main} !important`,
    outline: "none !important",
    "& .textLabel": {
      fontWeight: 500
    }
  },
  "&:hover": {
    border: `1px solid #F0F2F5`
  },
  "&.textarea_error": {
    borderColor: `${theme.palette.error.main} !important`
  },
  "&:disabled": {
    "&::placeholder": { color: theme.palette.text.disabled },
    borderColor: "#F0F2F5",
    background: "#fff"
  },
  "@media print": {
    "&.custom-text-area": {
      width: "500px !important",
      height: "auto !important",
      overflow: "hidden",
      fontSize: "10px",
      fontWeight: 400
    },
    "&.custom-box": {
      width: "500px !important",
      height: "auto !important"
    }
  }
}));

const TextArea = ({
  name,
  control,
  helperText,
  placeholder,
  disabled = false,
  label,
  mandatoryField = false
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleAreaFocus = (e) => {
    setIsFocused((prevFocus) => !prevFocus);
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={""}
      render={({ field, fieldState: { error } }) => (
        <Box>
          <InputLabel
            htmlFor={name}
            sx={{ fontWeight: (field.value || isFocused) && 500 }}
            disabled={disabled}
          >
            {label}
            {mandatoryField && <span style={{ color: "#F05454" }}> *</span>}
          </InputLabel>
          <StyledTextArea
            {...field}
            minRows={6}
            placeholder={placeholder}
            id={name}
            className={`${error ? "textarea_error" : ""} custom-text-area`}
            onFocus={handleAreaFocus}
            onBlur={handleAreaFocus}
            disabled={disabled}
          />
          {helperText && (
            <FormHelperText sx={{ letterSpacing: "0.1em" }}>
              {helperText}
            </FormHelperText>
          )}
          {error && <FormHelperText error>{error.message}</FormHelperText>}
        </Box>
      )}
    />
  );
};

export default TextArea;
