import { getSOSubtotal } from "../../../sales/services/index";
import { queryKeys } from "../../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchSOSubtotal = (params) => {
  const querySOSubtotal = useQuery(
    [queryKeys.queryFetchSOSubtotal, JSON.stringify(params)],
    () => getSOSubtotal(params),
    {
      refetchOnWindowFocus: false
    }
  );

  return querySOSubtotal;
};
