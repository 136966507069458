import { queryKeys } from "../../constants/queryKeys";
import { getDCInCreateFormData } from "../../finance/services";
import { useQuery } from "@tanstack/react-query";

export const useFetchDCInCreateFormData = (orderId, invId, orderType) => {
  const DCInFormData = useQuery(
    [queryKeys.queryFetchDCInCreateFormData, orderId, Boolean(invId)],
    () => getDCInCreateFormData(orderId),
    {
      enabled:
        !Boolean(invId) &&
        Boolean(orderId) &&
        Boolean(orderType === "SaleOrder"),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return DCInFormData;
};
