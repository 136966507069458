import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material";

export const StyledButton = styled(LoadingButton)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    padding: "14px 40px",
    maxHeight: "48px"
  },
  [theme.breakpoints.up("md")]: {
    padding: "22px 40px",
    maxHeight: "64px"
  }
}));