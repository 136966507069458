import { getPublicListings, getListings } from "../../customer/services";

import { AppContext } from "../../shared/context/auth-context";
import { queryKeys } from "../../constants/queryKeys";
import { useContext } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";

export const useFetchListings = (params) => {
  const { currentUser } = useContext(AppContext);
  const key = { Warehouse: "warehouses", Transport: "transports" };
  const queryListings = useInfiniteQuery(
    [queryKeys.queryFetchListings, Boolean(params)],
    currentUser
      ? ({ pageParam = 1 }) => getListings(params, pageParam)
      : () => getPublicListings(params),
    {
      enabled: Boolean(params),
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true,
      getNextPageParam: (lastPage, allPages) => {
        const nextPage =
          lastPage[key[params?.searchForm?.partnerType]]?.length ===
          lastPage.pagination.limit
            ? allPages.length + 1
            : undefined;

        return nextPage;
      }
    }
  );

  return queryListings;
};
