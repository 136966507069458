import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography
} from "@mui/material";
import { action_labels, inquiry_table_labels } from "../../constants";
import { useEffect, useState } from "react";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CustomTable from "../../shared/customTable";
import { CustomTabs } from "../../shared/customTab/CustomTabs";
import InquiryCart from "../components/Inquiry/InquiryCart";
import { InquiryMobileCard } from "../components/Inquiry/InquiryMobileCard";
import { useNavigate } from "react-router-dom";
import RegretForm from "../../shared/UiElements/ReasonForm";
import { StyledChip } from "../../shared/UiElements/StatusChip";
import SuccessfulDialog from "../../shared/UiElements/SuccessfulDialog";
import appRoutes from "../../constants/appRoutes";
import { enqueueSnackbar } from "notistack";
import { isMobileDevice } from "../../App";
import moment from "moment";
import { rfqServiceKeys } from "../../constants/formKeys";
import { useCancelRfqHook } from "../../query-hooks/Inquiry/useCancelRfqHook";
import { useCustomDialog } from "../../shared/customDialog";
import { useDrawer } from "../../shared/customSideDrawer";
import { useFetchActiveInquiry } from "../../query-hooks/Inquiry/useFetchActiveInquiry";
import { useFetchClosedInquiry } from "../../query-hooks/Inquiry/useFetchClosedInquiry";
import { useGetRfqCancelFormDataHook } from "../../query-hooks/Inquiry/useGetRfqCancelFormDataHook";
import { useInView } from "react-intersection-observer";
import { isMobile } from "react-device-detect";
import Storage from "../../shared/utils/Storage";
import { StorageKeys } from "../../constants/storage-keys";

const ActiveButtonGroup = ({ onViewClick, onCancelClick, status }) => {
  return (
    <Box display={"flex"} gap={5}>
      <Button
        variant="link"
        onClick={onViewClick}
        disableRipple
        sx={{ fontWeight: 600, minWidth: 0 }}
      >
        {inquiry_table_labels.view}
      </Button>
      {status !== "Cancelled" && (
        <Button
          variant="link"
          onClick={(e) => {
            e.stopPropagation();
            onCancelClick();
          }}
          disableRipple
          sx={{ fontWeight: 600, minWidth: 0 }}
        >
          {inquiry_table_labels.cancel}
        </Button>
      )}
    </Box>
  );
};

const PastButtonGroup = ({ onViewClick, status }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Button
          variant="link"
          onClick={onViewClick}
          disableRipple
          sx={{ fontWeight: 600, minWidth: 0 }}
        >
          {inquiry_table_labels.view}
        </Button>
      </Grid>
    </Grid>
  );
};

const getActiveColumns = (onViewClick, onCancelClick) => {
  return [
    {
      Header: "Inquiry ID",
      accessor: "code"
    },
    {
      Header: "Inquired On",
      accessor: "inquiryDate",
      Cell: ({ value }) => moment(value).format("DD MMMM YYYY")
    },
    {
      Header: "Service type",
      accessor: "serviceType"
    },
    {
      Header: "Status",
      accessor: "responseStatus",
      Cell: ({ value, cell }) => {
        return (
          <StyledChip
            label={
              cell.row.original.status === "Cancelled"
                ? cell.row.original.status
                : value
            }
          />
        );
      }
    },
    {
      Header: "Respond time",
      accessor: "lastRespondedAt",
      Cell: ({ value }) => {
        if (value)
          return (
            <Typography
              sx={{ fontSize: 14, fontWeight: 400, lineHeight: "20px" }}
            >
              {moment(value).fromNow()}
            </Typography>
          );
      }
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ value, cell }) => {
        return (
          <Box display={"flex"} gap={5}>
            <Button
              variant="link"
              onClick={() =>
                onViewClick(
                  cell.row.original,
                  cell.row.original.serviceType.includes("Warehouse")
                    ? rfqServiceKeys.warehouseRfq
                    : rfqServiceKeys.transportRfq
                )
              }
              disableRipple
              sx={{ fontWeight: 600, minWidth: 0 }}
            >
              {inquiry_table_labels.view}
            </Button>
            {cell.row.original.status !== "Cancelled" && (
              <Button
                variant="link"
                onClick={(e) => {
                  e.stopPropagation();
                  onCancelClick({
                    inquiryId: cell.row.original.id,
                    rfqId: cell.row.original.serviceType.includes("Warehouse")
                      ? cell.row.original.warehouseRfq
                      : cell.row.original.transportRfq
                  });
                }}
                disableRipple
                sx={{ fontWeight: 600, minWidth: 0 }}
              >
                {inquiry_table_labels.cancel}
              </Button>
            )}
          </Box>
        );
      }
    }
  ];
};

const getClosedColumns = (onViewClick) => {
  return [
    {
      Header: "Inquiry ID",
      accessor: "code"
    },
    {
      Header: "Inquired On",
      accessor: "inquiryDate",
      Cell: ({ value }) => moment(value).format("DD MMMM YYYY")
    },
    {
      Header: "Closed on",
      accessor: "closedDate",
      Cell: ({ value }) => (value ? moment(value).format("DD MMMM YYYY") : "")
    },
    {
      Header: "Service type",
      accessor: "serviceType"
    },
    {
      Header: "Status",
      accessor: "responseStatus",
      Cell: ({ value, cell }) => {
        return (
          <StyledChip
            label={
              cell.row.original.status === "Cancelled"
                ? cell.row.original.status
                : value
            }
          />
        );
      }
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ value, cell }) => {
        return (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Button
                variant="link"
                onClick={() =>
                  onViewClick(
                    cell.row.original,
                    cell.row.original.serviceType.includes("Warehouse")
                      ? rfqServiceKeys.warehouseRfq
                      : rfqServiceKeys.transportRfq
                  )
                }
                disableRipple
                sx={{ fontWeight: 600, minWidth: 0 }}
              >
                {inquiry_table_labels.view}
              </Button>
            </Grid>
          </Grid>
        );
      }
    }
  ];
};

const getRows = (rows = [], type = "active", onViewClick, onCancelClick) => {
  const hasActiveTransportRfq = rows.map((key) =>
    key.hasOwnProperty("transportRfq")
  );
  const ButtonGroup = type === "active" ? ActiveButtonGroup : PastButtonGroup;
  return rows.map((item, index) => {
    const isOnlyTransport = !Boolean(item.warehouseRfq);
    let mainRow;
    if (isOnlyTransport) {
      mainRow = {
        serviceType: `Transporter (${item.transportRfq.partners.length})`,
        status: item.transportRfq.status.displayName,
        responseStatus: item.transportRfq.responseStatus.displayName,
        transportRfq: item.transportRfq._id,
        lastRespondedAt: item.transportRfq.lastRespondedAt
      };
    } else {
      mainRow = {
        serviceType: `Warehouse (${item.warehouseRfq.partners.length})`,
        status: item.warehouseRfq.status.displayName,
        responseStatus: item.warehouseRfq.responseStatus.displayName,
        lastRespondedAt: item.warehouseRfq.lastRespondedAt,
        warehouseRfq: item.warehouseRfq._id
      };
    }
    return {
      id: item._id,
      code: item.code,
      inquiryDate: item.createdAt,
      ...mainRow,
      ...{
        ...(hasActiveTransportRfq[index] && !isOnlyTransport
          ? {
              subRow: [
                {
                  serviceType: `Transporter (${item.transportRfq.partners.length})`,
                  status: item.transportRfq.status.displayName,
                  responseStatusName:
                    item.transportRfq.status.displayName === "Cancelled"
                      ? item.transportRfq.status.displayName
                      : item.transportRfq.responseStatus.displayName,
                  responseStatus: (
                    <StyledChip
                      label={
                        item.transportRfq.status.displayName === "Cancelled"
                          ? item.transportRfq.status.displayName
                          : item.transportRfq.responseStatus.displayName
                      }
                      variant={isMobile ? "text" : "outlined"}
                    />
                  ),
                  transportRfq: item.transportRfq._id,
                  lastRespondedAt: item.transportRfq.lastRespondedAt
                    ? moment(item.transportRfq.lastRespondedAt).fromNow()
                    : "",
                  actions: (
                    <ButtonGroup
                      onViewClick={() =>
                        onViewClick(
                          {
                            inquiryDate: item.createdAt,
                            id: item._id,
                            code: item.code,
                            transportRfq: item.transportRfq._id
                          },
                          rfqServiceKeys.transportRfq
                        )
                      }
                      onCancelClick={() =>
                        onCancelClick({
                          inquiryId: item._id,
                          rfqId: item.transportRfq._id
                        })
                      }
                      status={item.transportRfq.status.name}
                    />
                  )
                }
              ]
            }
          : {})
      }
    };
  });
};

const Inquiry = () => {
  const { showDrawer, hideDrawer } = useDrawer();
  const { showDialog, hideDialog } = useCustomDialog();

  const navigate = useNavigate();
  const searchFormParams = Storage.getItem(StorageKeys.SEARCH_FORM_PARAMS);

  const [activeTab, setActiveTab] = useState(0);
  const [inquiryId, setInquiryId] = useState("");
  const [rfqId, setRfqId] = useState("");

  const { ref: activeRef, inView: inActiveView } = useInView({ threshold: 0 });
  const { ref: pastRef, inView: inPastView } = useInView({ threshold: 0 });

  const {
    isError,
    error,
    isFetched,
    isLoading,
    data: reasons,
    isFetching,
    refetch,
    remove
  } = useGetRfqCancelFormDataHook(inquiryId, rfqId);

  const { mutate: regretInquiry } = useCancelRfqHook();

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  const onViewClick = (inquiryParams, service) => {
    showDrawer({
      component: (
        <InquiryCart
          inquiryDate={inquiryParams.inquiryDate}
          inquiryId={inquiryParams.id}
          rfqId={inquiryParams[service]}
          hideDrawer={hideDrawer}
          service={service}
          code={inquiryParams.code}
        />
      ),
      closeIcon: false
    });
  };

  const onCancelClick = ({ inquiryId, rfqId }) => {
    setInquiryId(inquiryId);
    setRfqId(rfqId);
  };

  useEffect(() => {
    if (inquiryId || rfqId) refetch();
  }, [inquiryId, refetch, rfqId]);

  useEffect(() => {
    if (isFetched && !isLoading && !isError) {
      remove();
      showDialog({
        component: (
          <RegretForm
            title={inquiry_table_labels.confirmCloseInquiry}
            subText={inquiry_table_labels.confirmCloseInquirySubText}
            btn1Text={action_labels.yesRegretButtonText}
            btn1Callback={(data) => {
              regretInquiry(
                {
                  inquiryId: inquiryId,
                  rfqId: rfqId,
                  data: {
                    reason: data.regretReason,
                    ...{
                      ...(data.otherReason
                        ? { otherReason: data.otherReason }
                        : {})
                    }
                  }
                },
                {
                  onSuccess: () => {
                    isActiveRefetch();
                    isPastRefetch();
                    showDialog({
                      component: (
                        <SuccessfulDialog
                          text={action_labels.yourInquiryCancelled}
                          subText={
                            action_labels.customerRequestInquiryResponseTime
                          }
                          btn2Text={inquiry_table_labels.close}
                          btn2Callback={() => hideDialog()}
                        />
                      )
                    });
                  },
                  onError: (error) =>
                    enqueueSnackbar(error?.response.data.message, {
                      variant: "error"
                    })
                }
              );
            }}
            btn2Text={inquiry_table_labels.cancel}
            btn2Callback={() => {
              hideDialog();
              setInquiryId("");
              setRfqId("");
            }}
            reasons={reasons}
          />
        ),
        backdropOff: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched, reasons, isLoading, isError]);

  const {
    data: activeInquiryData,
    isFetched: isActiveFetched,
    refetch: isActiveRefetch,
    hasNextPage: hasNextActiveInquiryPage,
    fetchNextPage: fetchNextActiveInquiryPage
  } = useFetchActiveInquiry();

  const {
    data: pastInquiryData,
    isFetched: isPastFetched,
    refetch: isPastRefetch,
    hasNextPage: hasNextPastInquiryPage,
    fetchNextPage: fetchNextPastInquiryPage
  } = useFetchClosedInquiry(1);

  const ActiveInquiry = (
    <Box
      sx={{
        padding: {
          xs: "0 16px 16px 16px",
          md: 0
        }
      }}
    >
      <CustomTable
        searchPlaceholderText={inquiry_table_labels.searchPlaceholderText}
        columns={getActiveColumns(onViewClick, onCancelClick)}
        data={
          isActiveFetched
            ? activeInquiryData?.pages?.flatMap((page) =>
                getRows(page?.inquiries, "active", onViewClick, onCancelClick)
              ) ?? []
            : []
        }
        mobileComponent={InquiryMobileCard}
        isLoading={!isActiveFetched}
      />
    </Box>
  );

  const PastInquiry = (
    <Box
      sx={{
        padding: {
          xs: "0 16px 16px 16px",
          md: 0
        }
      }}
    >
      <CustomTable
        searchPlaceholderText={inquiry_table_labels.searchPlaceholderText}
        columns={getClosedColumns(onViewClick, onCancelClick)}
        data={
          isPastFetched
            ? pastInquiryData?.pages?.flatMap((page) =>
                getRows(page?.inquiries, "past", onViewClick, onCancelClick)
              ) ?? []
            : []
        }
        mobileComponent={InquiryMobileCard}
        isLoading={!isPastFetched}
      />
    </Box>
  );

  const tabs = [
    {
      label: `Active Inquiry (${
        activeInquiryData?.pages?.[0].pagination.rows || 0
      })`,
      Component: ActiveInquiry
    },
    {
      label: `Past Inquiry (${
        pastInquiryData?.pages?.[0].pagination.rows || 0
      })`,
      Component: PastInquiry
    }
  ];

  useEffect(() => {
    if (inActiveView && hasNextActiveInquiryPage) {
      fetchNextActiveInquiryPage();
    }
    if (inPastView && hasNextPastInquiryPage) {
      fetchNextPastInquiryPage();
    }
  }, [
    fetchNextActiveInquiryPage,
    fetchNextPastInquiryPage,
    hasNextActiveInquiryPage,
    hasNextPastInquiryPage,
    inActiveView,
    inPastView
  ]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(error?.response.data.message, { variant: "error" });
    }
  }, [error?.response.data.message, isError]);

  return (
    <Box
      sx={{
        mb: { xs: 9 }
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isFetching}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        sx={{
          background: { xs: "#EEF1F4", lg: "unset" },
          display: { xs: "flex", lg: "block" },
          textAlign: "end",
          height: { xs: "unset", lg: 0 },
          position: "relative",
          top: { lg: "10px" },
          zIndex: 1,
          justifyContent: "space-between"
        }}
      >
        <Typography
          sx={{
            padding: "37px 21px 18px 21px",
            display: {
              xs: "block",
              sm: "none"
            }
          }}
        >
          {inquiry_table_labels.manageInquiry}
        </Typography>
        <Button
          variant="text"
          sx={{
            padding: { xs: "37px 21px 18px 37px", lg: 2 },
            whiteSpace: "nowrap"
          }}
          onClick={() =>
            navigate(
              Boolean(searchFormParams)
                ? appRoutes.explore.listings
                : appRoutes.explore.main
            )
          }
        >
          {inquiry_table_labels.addNewInquiry} <AddOutlinedIcon />
        </Button>
      </Box>
      <CustomTabs
        tabs={tabs}
        activeTab={activeTab}
        onChange={handleTabChange}
        secondaryTab={isMobileDevice ? true : false}
      />
      {hasNextActiveInquiryPage && activeTab === 0 && (
        <Box
          ref={activeRef}
          display={"flex"}
          justifyContent={"center"}
          margin={"16px 0"}
          width={"100%"}
        >
          <CircularProgress />
        </Box>
      )}
      {hasNextPastInquiryPage && activeTab === 1 && (
        <Box
          ref={pastRef}
          display={"flex"}
          justifyContent={"center"}
          margin={"16px 0"}
          width={"100%"}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default Inquiry;
