import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  Typography
} from "@mui/material";
import { BookOrderFormKeys, SearchFormKeys } from "../../../constants/formKeys";
import { appConstants, labels } from "../../../constants";

import { East } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Storage from "../../../shared/utils/Storage";
import { StorageKeys } from "../../../constants/storage-keys";
import { StyleBorderedGrid } from ".";
import { StyledResponsiveText } from "../../../shared/theme/globalTheme";
import { dateTimeFormatter } from "../../../shared/utils";
import { isMobileDevice } from "../../../App";
import { styled } from "@mui/system";
import { useState } from "react";

const StyleBox = styled((props) => <Box {...props} />)(({ theme }) => ({
  background: isMobileDevice ? "#F3F5F9" : theme.palette.success.background,
  borderRadius: 4,
  marginTop: isMobileDevice ? 0 : "20px",
  padding: "12px"
}));

const StyledAccordion = styled((props) => <Accordion {...props} />)(
  ({ theme }) => ({
    minHeight: 101,
    background: "#FFFFFF",
    border: "0.4px solid " + theme.palette.text.disabled,
    borderRadius: "8px !important",
    boxShadow: "none"
  })
);

const StyledPrimaryText = styled((props) => <Typography {...props} />)(
  ({ theme }) => ({
    color: theme.palette.text.primary,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  })
);

const ProductDetailsGrid = ({ gridData = [] }) => {
  return (
    <Box display="flex" alignItems="flex-start" flexWrap={"wrap"}>
      {gridData.map((item, index) => (
        <Box
          display="flex"
          alignItems="center"
          key={index}
          flexWrap={"nowrap"}
          sx={{
            mb: {
              xs: 2,
              md: 0
            }
          }}
        >
          <Box width={"100%"}>
            <Typography
              fontSize={13}
              lineHeight="19px"
              letterSpacing="0.005em"
              fontWeight={500}
              marginTop={1}
              whiteSpace={"nowrap"}
              overflow={"hidden"}
              textOverflow={"ellipsis"}
            >
              {item.value}
            </Typography>
            <Typography
              fontWeight={400}
              fontSize={13}
              lineHeight="20px"
              color="text.secondary"
              whiteSpace={"nowrap"}
              overflow={"hidden"}
              textOverflow={"ellipsis"}
            >
              {item.label}
            </Typography>
          </Box>
          {index + 1 !== gridData.length && (
            <Divider
              orientation="vertical"
              sx={{
                height: 38,
                borderBottomWidth: "0.4px",
                borderRadius: "0.5px",
                m: "0px 20px"
              }}
            />
          )}
        </Box>
      ))}
    </Box>
  );
};

const PartnerDetails = ({ getValues, cartItem, isInquiry }) => {
  const FORM_DATA = Storage.getItem(StorageKeys.SEARCH_FORM_DATA);

  const { warehouse, transport } = cartItem;

  const [accordion, setAccordion] = useState({
    warehouse: false,
    transport: false
  });

  const PartnerHeader = ({ title, city, state, country }) => {
    return (
      <Grid item xs={12}>
        <StyledResponsiveText
          color={"text.primary"}
          desktop={{
            fontSize: 16,
            fontWeight: 600,
            lineHeight: "19px",
            letterSpacing: "-0.002em"
          }}
          mobile={{
            fontSize: 14,
            fontWeight: 500,
            lineHeight: "17px",
            letterSpacing: "0.002em"
          }}
        >
          {title}
        </StyledResponsiveText>
        <Typography
          component="p"
          color={"text.secondary"}
          sx={{
            fontSize: 14,
            fontWeight: 400,
            lineHeight: "20px",
            whiteSpace: "nowrap",
            mt: "4px"
          }}
        >
          {`${city}, ${state}, ${country}`}
        </Typography>
      </Grid>
    );
  };

  const WarehouseDetails = (
    <Grid container spacing={2}>
      <Grid item xs={12} py={2}>
        <StyleBox
          width={isMobileDevice ? "100%" : 276}
          display={"flex"}
          alignItems="flex-end"
        >
          <Box>
            <Typography
              component="span"
              color={"text.secondary"}
              sx={{
                fontSize: 12,
                fontWeight: 500,
                lineHeight: "18px",
                whiteSpace: "nowrap"
              }}
            >
              {labels.inboundDateLabelText}
            </Typography>
            <StyledPrimaryText
              lineHeight={"18px"}
              fontWeight={500}
              sx={{
                color: {
                  xs: "#1B51B3",
                  md: "#0F2744"
                },
                fontSize: {
                  xs: 13,
                  md: 12
                }
              }}
            >
              {dateTimeFormatter(
                isInquiry
                  ? cartItem?.warehouse?.searchForm?.inboundDate
                  : FORM_DATA[SearchFormKeys.InboundDate]
              )}
            </StyledPrimaryText>
          </Box>
          <East sx={{ m: "0 16px" }} />
          <Box>
            <Typography
              component="span"
              color={"text.secondary"}
              sx={{
                fontSize: 12,
                fontWeight: 500,
                lineHeight: "18px",
                whiteSpace: "nowrap"
              }}
            >
              {labels.outboundDateLabelText}
            </Typography>
            <StyledPrimaryText
              lineHeight={"18px"}
              fontWeight={500}
              sx={{
                color: {
                  xs: "#1B51B3",
                  md: "#0F2744"
                },
                fontSize: {
                  xs: 13,
                  md: 12
                }
              }}
            >
              {dateTimeFormatter(
                isInquiry
                  ? cartItem?.warehouse?.searchForm?.outboundDate
                  : FORM_DATA[SearchFormKeys.OutboundDate]
              )}
            </StyledPrimaryText>
          </Box>
        </StyleBox>
      </Grid>
      <Grid item xs={12} sx={{ paddingTop: "8px !important" }}>
        <StyledResponsiveText
          color={"text.primary"}
          mobile={{
            fontSize: 13,
            fontWeight: 400,
            lineHeight: "18px",
            letterSpacing: "0.002em"
          }}
        >
          {labels.productDetails}
        </StyledResponsiveText>
        <Divider
          sx={{
            width: "90%",
            borderBottomWidth: "0.4px",
            borderRadius: "0.5px",
            m: "8px 0 16px 0"
          }}
        />
        <ProductDetailsGrid
          gridData={[
            {
              label: labels.materialTypeLabel,
              value: isInquiry
                ? cartItem?.warehouse?.searchForm?.materialCategory
                    ?.displayName || "--"
                : FORM_DATA[SearchFormKeys.MaterialDetails] || "--"
            },
            {
              label: labels.packagingLabelText,
              value: isInquiry
                ? "--"
                : FORM_DATA[SearchFormKeys.PackagingType] || "--"
            },
            {
              label: labels.dimensionAndUnits,
              value: isInquiry
                ? "-- | --"
                : (FORM_DATA[SearchFormKeys.Dimension] || "--") +
                  " | " +
                  (FORM_DATA[SearchFormKeys.Units] || "--"),
              large: true
            },
            {
              label: labels.spaceType,
              value: isInquiry
                ? cartItem?.warehouse?.searchForm?.storageType?.name || "--"
                : FORM_DATA[SearchFormKeys.StorageType] || "--"
            },
            {
              label: isInquiry
                ? `Area in ${cartItem?.warehouse?.searchForm?.storageType?.name}`
                : `Area in ${FORM_DATA[SearchFormKeys.StorageType]}`,
              value: isInquiry
                ? cartItem?.warehouse?.searchForm?.storageQuantity || "--"
                : FORM_DATA[SearchFormKeys.pallets] || "--"
            }
          ]}
        />
      </Grid>
    </Grid>
  );

  const TransportDetails = (
    <Grid container spacing={2}>
      <Grid item xs={12} py={2}>
        <StyleBox p={1} display={"block"} maxWidth={380}>
          <Typography
            component="span"
            color={"text.secondary"}
            sx={{
              fontSize: 12,
              fontWeight: 500,
              lineHeight: "20px",
              whiteSpace: "nowrap"
            }}
          >
            {labels.pickupLocationLabelText}
          </Typography>
          <StyledPrimaryText fontSize={12} fontWeight={500}>
            {isInquiry
              ? cartItem?.transport?.searchForm?.pickup?.address
              : FORM_DATA[SearchFormKeys.PickupLocation]}
          </StyledPrimaryText>
        </StyleBox>
      </Grid>
      <Grid container spacing={1} p={2}>
        <Grid item xs={12} md={6}>
          <StyleBox
            p={1}
            display={"block"}
            maxWidth={380}
            sx={{ marginTop: 0 }}
          >
            <Typography
              component="span"
              color={"text.secondary"}
              sx={{
                fontSize: 12,
                fontWeight: 500,
                lineHeight: "20px",
                whiteSpace: "nowrap"
              }}
            >
              {labels.pickupDateLabelText}
            </Typography>
            <StyledPrimaryText fontSize={12} fontWeight={500}>
              {dateTimeFormatter(
                isInquiry
                  ? cartItem?.transport?.searchForm?.inboundDate
                  : getValues(BookOrderFormKeys.PickUpDate)
              )}
            </StyledPrimaryText>
          </StyleBox>
        </Grid>
      </Grid>
    </Grid>
  );

  const getValuesForTransporterDateAndTime = () => {
    const pickUpDate = dateTimeFormatter(
      isInquiry
        ? cartItem?.transport?.searchForm?.inboundDate
        : getValues(BookOrderFormKeys.PickUpDate)
    );
    const pickUpTime = dateTimeFormatter(
      getValues(BookOrderFormKeys.PickUpTime),
      appConstants.timeFormat
    );
    const values = [];
    if (pickUpDate) {
      values.push(pickUpDate);
    }
    if (pickUpTime) {
      values.push(pickUpTime);
    }
    return values.join(" | ");
  };

  const getValuesForWarehouseDetails = () => {
    const inboundDate = dateTimeFormatter(
      isInquiry
        ? cartItem?.warehouse?.searchForm?.inboundDate
        : FORM_DATA[SearchFormKeys.InboundDate]
    );
    const outboundDate = dateTimeFormatter(
      isInquiry
        ? cartItem?.warehouse?.searchForm?.outboundDate
        : FORM_DATA[SearchFormKeys.OutboundDate]
    );
    const material = isInquiry
      ? cartItem?.warehouse?.searchForm?.materialCategory?.displayName || "--"
      : FORM_DATA[SearchFormKeys.MaterialDetails] || "--";
    const packagingType = FORM_DATA[SearchFormKeys.PackagingType] || "--";
    const dimension = FORM_DATA[SearchFormKeys.Dimension] || "--";
    const values = [];
    if (inboundDate && outboundDate) {
      values.push(inboundDate + "-" + outboundDate);
    }
    if (material) {
      values.push(material);
    }
    if (packagingType) {
      values.push(packagingType);
    }
    if (dimension) {
      values.push(dimension);
    }
    return values.join(" | ");
  };

  return (
    <Box>
      {isMobileDevice ? (
        <Grid container spacing={2}>
          {warehouse && (
            <Grid item xs={12}>
              <StyledAccordion expanded={accordion.warehouse} disableGutters>
                <AccordionSummary
                  onClick={() =>
                    setAccordion({
                      ...accordion,
                      warehouse: !accordion.warehouse
                    })
                  }
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Grid container>
                    <PartnerHeader
                      title={warehouse?.code}
                      country={warehouse?.address?.state}
                      state={warehouse?.address?.city}
                      city={warehouse?.address?.cluster}
                    />
                    <Grid item xs={12}>
                      {!accordion.warehouse && (
                        <Typography
                          fontWeight={400}
                          fontSize={12}
                          lineHeight="16px"
                          color="text.secondary"
                          whiteSpace={"pre-wrap"}
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                          py={2}
                        >
                          {getValuesForWarehouseDetails()}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails p={0}>{WarehouseDetails}</AccordionDetails>
              </StyledAccordion>
            </Grid>
          )}
          {transport && (
            <Grid item xs={12}>
              <StyledAccordion expanded={accordion.transport}>
                <AccordionSummary
                  onClick={() =>
                    setAccordion({
                      ...accordion,
                      transport: !accordion.transport
                    })
                  }
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Grid container>
                    <PartnerHeader
                      title={transport?.code}
                      country={transport?.address?.state}
                      state={transport?.address?.city}
                      city={transport?.address?.cluster}
                    />
                    <Grid item xs={12}>
                      {!accordion.transport && (
                        <Typography
                          fontWeight={400}
                          fontSize={12}
                          lineHeight="16px"
                          color="text.secondary"
                          whiteSpace={"pre-wrap"}
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                          py={2}
                        >
                          {getValuesForTransporterDateAndTime()}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails p={0}>{TransportDetails}</AccordionDetails>
              </StyledAccordion>
            </Grid>
          )}
        </Grid>
      ) : (
        <StyleBorderedGrid container>
          <Grid
            item
            xs={12}
            md={warehouse && transport ? 7 : warehouse ? 12 : 0}
            padding={3}
            display={!warehouse && "none"}
            borderRight={transport ? "0.4px solid #95A1B0" : ""}
          >
            <PartnerHeader
              title={warehouse?.code}
              country={warehouse?.address?.state}
              state={warehouse?.address?.city}
              city={warehouse?.address?.cluster}
            />
            {WarehouseDetails}
          </Grid>
          <Grid
            item
            xs={12}
            padding={3}
            display={!transport && "none"}
            md={warehouse && transport ? 5 : transport ? 12 : 0}
          >
            <PartnerHeader
              title={transport?.code}
              country={transport?.address?.state}
              state={transport?.address?.city}
              city={transport?.address?.cluster}
            />
            {TransportDetails}
          </Grid>
        </StyleBorderedGrid>
      )}
    </Box>
  );
};

export default PartnerDetails;
