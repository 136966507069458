import { Box, Divider, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { ValueLabelText } from "../../../purchase/components/Purchase/MobilePOTable";

export const MobileSRTable = (row) => {
  return (
    <Grid container spacing={"20px"}>
      <Grid item xs={12} container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={6}>
          {row?.skuCode ? (
            <Box display={"flex"} gap={1} alignItems={"center"}>
              {row?.image?.thumbUrl && (
                <img
                  src={row?.image?.thumbUrl}
                  alt={row?.brandName ?? "--"}
                  style={{
                    objectFit: "contain",
                    maxWidth: "50px",
                    maxHeight: "50px"
                  }}
                />
              )}
              <Typography
                fontSize={12}
                fontWeight={600}
                color={"primary"}
                component={Link}
              >
                {row?.skuCode}
              </Typography>
            </Box>
          ) : (
            row.item
          )}
        </Grid>
        <Grid
          item
          xs={2}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Typography
            fontSize={12}
            fontWeight={400}
            lineHeight={"14.52px"}
            color={"text.disabled"}
          >
            {row?.unit?.name}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          display={"flex"}
          justifyContent={"right"}
          alignItems={"flex-start"}
        >
          <ValueLabelText label={"Qty ordered"} value={row.quantity} />
        </Grid>
        <Grid
          item
          xs={4}
          display={"flex"}
          justifyContent={"left"}
          alignItems={"flex-start"}
        >
          {row.isEditable ? (
            row.returnedQuantity
          ) : (
            <ValueLabelText
              label={"Return qty"}
              value={row?.returnedQuantity || "--"}
            />
          )}
        </Grid>
        <Grid
          item
          xs={4}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <ValueLabelText label={"Rate"} value={row.rate} />
        </Grid>
        <Grid item xs={4} display={"flex"} justifyContent={"flex-end"}>
          <ValueLabelText label={"Subtotal"} value={row?.amount || 0} />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid
          item
          xs={4}
          display={"flex"}
          justifyContent={"left"}
          alignItems={"flex-start"}
        >
          <ValueLabelText
            label={row?.gstType ? "CGST" : "IGST"}
            value={row?.gstType ? row.cgst : row.igst}
          />
        </Grid>
        <Grid
          item
          xs={4}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {row?.gstType && <ValueLabelText label={"SGST"} value={row.sgst} />}
        </Grid>
        <Grid
          item
          xs={4}
          display={"flex"}
          justifyContent={"right"}
          alignItems={"flex-start"}
        >
          <ValueLabelText label={"Total"} value={row.total} />
        </Grid>
      </Grid>
    </Grid>
  );
};
