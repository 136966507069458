import { Box, Button, Grid, Typography } from "@mui/material";
import { eWayBills_labels } from "../../constants";

import CustomTable from "../../shared/customTable";
import { eWayBills_filterKeys } from "../../constants/formKeys";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useMemo } from "react";
import {
  currencyOnlyFormatter,
  stringCapitalization
} from "../../shared/utils";
import moment from "moment";
import { StyledChip } from "../../shared/UiElements/StatusChip";
import { getCustomCell } from "../../purchase/pages/PurchaseOrders";
import Filter from "../../purchase/components/Filter";

const dummyData = [
  {
    EwaybillNo: "EB123456789",
    EwaybillDate: "2024-10-01T10:30:00.380Z",
    Validity: "2024-10-05T23:59:00.380Z",
    Status: "Expired",
    SupplyType: "Outward - Invoice",
    DocNo: "IN-01234",
    orderDate: "2024-09-30T09:45:00.380Z",
    gstin: "27ABCDE1234F2Z5",
    ValueofGoods: 50000,
    VehicleNo: "MH12AB1234",
    Transporter: "ABC Transport Co."
  },
  {
    EwaybillNo: "EB987654321",
    EwaybillDate: "2024-09-25T12:20:44.380Z",
    Validity: "2024-09-30T23:59:00.380Z",
    Status: "Generated",
    SupplyType: "Outward - Invoice",
    DocNo: "IN-01234",
    orderDate: "2024-09-24T15:00:00.380Z",
    gstin: "07XYZ9876H9K4G8",
    ValueofGoods: 120000,
    VehicleNo: "DL4C5678",
    Transporter: "XYZ Logistics"
  },
  {
    EwaybillNo: "EB543216789",
    EwaybillDate: "2024-10-03T14:10:00.380Z",
    Validity: "2024-10-07T23:59:00.380Z",
    Status: "Cancelled",
    SupplyType: "Outward - Invoice",
    DocNo: "IN-01234",
    orderDate: "2024-10-01T11:30:00.380Z",
    gstin: "19LMNOP1234L2Q7",
    ValueofGoods: 75500,
    VehicleNo: "WB11XY7890",
    Transporter: "Speedy Transport"
  }
];

const getColumns = () => {
  return [
    {
      Header: "Ewaybill No.",
      accessor: "EwaybillNo",
      width: 145,
      Cell: ({ value }) => {
        return (
          <Typography fontSize={14} color={"primary"} component={Link}>
            {value}
          </Typography>
        );
      }
    },
    {
      Header: "Ewaybill Date",
      accessor: "EwaybillDate",
      width: 145,
      Cell: ({ value }) => {
        return (
          <Box display={"flex"} justifyContent={"center"} textAlign={"center"}>
            {moment(value).format("DD MMM YYYY")}
            <br />
            {moment(value).format("hh:mm A")}
          </Box>
        );
      }
    },
    {
      Header: "Validity",
      accessor: "Validity",
      width: 120,
      Cell: ({ value }) => {
        return (
          <Box display={"flex"} justifyContent={"center"} textAlign={"center"}>
            {moment(value).format("DD MMM YYYY")}
            <br />
            {moment(value).format("hh:mm A")}
          </Box>
        );
      }
    },
    {
      Header: "Status",
      accessor: "Status",
      width: 130,
      Cell: ({ value }) => {
        return <StyledChip label={stringCapitalization(value)} />;
      }
    },
    {
      Header: "Supply Type",
      accessor: "SupplyType"
    },
    {
      Header: "Doc. No.",
      accessor: "DocNo",
      Cell: ({ row, value }) => {
        return getCustomCell(value, row.original.orderDate, ``);
      }
    },
    {
      Header: "GSTIN",
      accessor: "gstin"
    },
    {
      Header: "Value of Goods",
      accessor: "ValueofGoods",
      width: 155
    },
    {
      Header: "Vehicle No.",
      accessor: "VehicleNo"
    },
    {
      Header: "Transporter",
      accessor: "Transporter"
    }
  ];
};

const getRows = (rows = []) => {
  return rows.map((row) => ({
    EwaybillNo: row?.EwaybillNo,
    EwaybillDate: row?.EwaybillDate,
    Validity: row?.Validity,
    Status: row?.Status,
    SupplyType: row?.SupplyType,
    DocNo: row?.DocNo,
    orderDate: moment(row?.orderDate).format("DD MMM YYYY"),
    gstin: row?.gstin,
    ValueofGoods: `Rs. ${currencyOnlyFormatter(row?.ValueofGoods)}`,
    VehicleNo: row?.VehicleNo,
    Transporter: row?.Transporter
  }));
};

const EWaybills = () => {
  const { control } = useForm();

  const EWaybillsTable = useMemo(
    () => <CustomTable columns={getColumns()} data={getRows(dummyData)} />,
    // eslint-disable-next-line
    []
  );

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      sx={{
        mb: { xs: 9, md: 0 }
      }}
    >
      <Box
        flexDirection={"column"}
        gap={2}
        sx={{
          display: { xs: "none", md: "flex" },
          position: "sticky",
          top: "64px",
          zIndex: 1000,
          background: "#fff",
          padding: "16px 0"
        }}
      >
        <Grid container>
          <Grid item xs={6}>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 700,
                lineHeight: "24px",
                color: "#FF8A8A"
              }}
            >
              *Feature Coming Soon*
            </Typography>
          </Grid>
          <Grid item xs={6} textAlign={"end"}>
            <Button variant="link" disableRipple disabled>
              {eWayBills_labels.createEWaybillButton}
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Filter
              control={control}
              onApply={() => console.log("applied")}
              onClear={() => console.log("cleared")}
              isFetching={false}
              isLoading={false}
              filters={[
                {
                  type: "DropDown",
                  name: eWayBills_filterKeys.supplyType,
                  label: eWayBills_labels.supplyType,
                  placeholder: "Outward - Invoice",
                  allowSearch: false,
                  options: [],
                  filterCount: 45
                },
                {
                  type: "DropDown",
                  name: eWayBills_filterKeys.status,
                  label: eWayBills_labels.status,
                  placeholder: "Active",
                  allowSearch: false,
                  options: [],
                  filterCount: 3
                },
                {
                  type: "DropDown",
                  name: eWayBills_filterKeys.date,
                  label: eWayBills_labels.date,
                  placeholder: "This Week",
                  allowSearch: false,
                  options: []
                },
                {
                  type: "TextInput",
                  name: eWayBills_filterKeys.ewayBillNo,
                  label: eWayBills_labels.ewayBillNo,
                  placeholder: "123456789"
                },
                {
                  type: "TextInput",
                  name: eWayBills_filterKeys.gstin,
                  label: eWayBills_labels.gstin,
                  placeholder: "36xxxxxxxxx"
                }
              ]}
            />
          </Box>
        </Grid>
      </Box>
      <Box sx={{ padding: { xs: "0 15px", md: 0 } }}>{EWaybillsTable}</Box>
    </Box>
  );
};

export default EWaybills;
