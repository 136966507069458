import { Box, Divider, Grid, Typography } from "@mui/material";

import { ActionIconButtons } from "./ActionButtons";
import { StyledChip } from "../../../shared/UiElements/StatusChip";
import { order_table_labels } from "../../../constants";
import { stringCapitalization } from "../../../shared/utils";

const PartnerDetail = ({
  id,
  createdAt,
  partnerName,
  shippingName,
  amount,
  status,
  orderId,
  type
}) => (
  <Grid container spacing={"17px"}>
    <Grid item xs={12} container>
      <Grid item xs={6}>
        <Typography
          color={"text.secondary"}
          fontSize={13}
          fontWeight={500}
          lineHeight={"18px"}
        >{`ID ${id}`}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography
          color={"text.secondary"}
          fontSize={13}
          fontWeight={500}
          lineHeight={"18px"}
          textAlign={"end"}
        >
          {createdAt}
        </Typography>
      </Grid>
    </Grid>
    <Grid item xs={12} container marginTop={"3px"}>
      <Grid
        item
        xs={9}
        display={"flex"}
        gap={"12px"}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
      >
        <Box display={"flex"} flexDirection={"column"} gap={"4px"}>
          <Typography fontSize={14} fontWeight={600} lineHeight={"normal"}>
            {`${partnerName} partner`}
          </Typography>
          <Typography
            fontSize={12}
            fontWeight={400}
            lineHeight={"16px"}
            color={"text.secondary"}
          >
            {shippingName}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={3} display={"flex"} justifyContent={"flex-end"}>
        <Box display={"flex"} flexDirection={"column"} gap={"4px"}>
          <Typography
            fontSize={14}
            fontWeight={600}
            lineHeight={"normal"}
            textAlign={"right"}
          >
            {amount}
          </Typography>
          <Typography
            fontSize={12}
            lineHeight={"16px"}
            color={"text.secondary"}
            textAlign={"right"}
          >
            {order_table_labels.amount}
          </Typography>
        </Box>
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <Divider />
    </Grid>
    <Grid item xs={12} container marginTop={"6px"}>
      <Grid item xs={6}>
        <Typography fontSize={12} lineHeight={"16px"}>
          <StyledChip label={status} variant="filled" />
        </Typography>
        <Typography
          mt={1}
          fontSize={12}
          lineHeight={"16px"}
          color={"text.secondary"}
        >
          {order_table_labels.status}
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        display={"flex"}
        justifyContent={"flex-end"}
        gap={"8px"}
      >
        <ActionIconButtons
          orderId={orderId}
          type={type}
          status={stringCapitalization(status)}
        />
      </Grid>
    </Grid>
  </Grid>
);

const MyOrdersMobileCard = ({
  id,
  orderId,
  createdAt,
  amount,
  status,
  warehouseOrder,
  type,
  ...props
}) => {
  const { transportOrder } = props;
  const isTransport = props.hasOwnProperty("transportOrder");

  return (
    <Box display={"flex"} flexDirection={"column"} gap={2}>
      {warehouseOrder && (
        <PartnerDetail
          id={id}
          createdAt={createdAt}
          amount={amount}
          orderId={orderId}
          type={type}
          partnerName={warehouseOrder?.partner.partnerType.displayName}
          shippingName={warehouseOrder?.partner.name}
          status={status}
        />
      )}
      {isTransport && (
        <>
          {warehouseOrder && transportOrder && (
            <Divider
              sx={{
                borderColor: "#B7BEC7",
                marginLeft: "-16px",
                marginRight: "-16px"
              }}
            />
          )}
          <PartnerDetail
            id={transportOrder.code}
            createdAt={createdAt}
            amount={props?.subRow?.amount ?? amount}
            orderId={orderId}
            type={"transport"}
            partnerName={transportOrder.partner.partnerType.displayName}
            shippingName={transportOrder.partner.name}
            status={status}
          />
        </>
      )}
    </Box>
  );
};

export default MyOrdersMobileCard;
