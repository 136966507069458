import { deletePODraft } from "../../../purchase/services";
import { useMutation } from "@tanstack/react-query";

export const useDeletePODraft = () => {
  const deleteDraft = useMutation(({ purchaseOrderId }) =>
    deletePODraft(purchaseOrderId)
  );

  return deleteDraft;
};
