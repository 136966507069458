import { updateStore } from "../../../sales/services";
import { useMutation } from "@tanstack/react-query";

export const useUpdateShipToAddress = () => {
  const updateStoreById = useMutation(
    ({ data, customerId, billingId, storeId }) =>
      updateStore(data, customerId, billingId, storeId)
  );
  return updateStoreById;
};
