import { Button, Grid, ThemeProvider } from "@mui/material";

import DropDown from "../../shared/formElements/DropDown";
import TextInput from "../../shared/formElements/TextInput";
import { contactUsFormKeys } from "../../constants/formKeys";
import { customFormTheme } from "../../shared/theme/customFormTheme";
import { contactUsSubject, error_msg, labels } from "../../constants";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import styled from "@emotion/styled";

const StyledSubmitButton = styled((props) => (
  <Button
    {...props}
    variant="contained"
    fullWidth
    sx={{
      fontSize: {
        xs: 14,
        md: 17,
      },
      lineHeight: {
        xs: "17px",
        md: "21px",
      },
      width: {
        xs: "100%",
        md: 260,
      },
      height: {
        xs: 64,
        md: 80,
      },
      opacity: 0.99,
      mt: 4,
    }}
  />
))(({ theme }) => ({
  borderRadius: 4,
  fontWeight: 400,
}));

const schema = yup.object({
  [contactUsFormKeys.name]: yup.string().required(error_msg.required),
  [contactUsFormKeys.number]: yup.string().required(error_msg.required),
  [contactUsFormKeys.email]: yup.string().required(error_msg.required),
  [contactUsFormKeys.subject]: yup.string().required(error_msg.required),
  [contactUsFormKeys.description]: yup.string().required(error_msg.required),
});

const ContactForm = () => {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      [contactUsFormKeys.name]: "",
      [contactUsFormKeys.number]: "",
      [contactUsFormKeys.email]: "",
      [contactUsFormKeys.subject]: "",
      [contactUsFormKeys.description]: "",
    },
    resolver: yupResolver(schema),
  });

  const onSubmitClick = (data) => {
    const warexMail = "info@warex.in";
    const subject = data[contactUsFormKeys.subject];
    const body = `Name : ${data[contactUsFormKeys.name]} \n \n Email: ${
      data[contactUsFormKeys.email]
    } \n \n Mobile : ${data[contactUsFormKeys.number]} \n \n ${
      data[contactUsFormKeys.description]
    }`;
    const mailtoLink = `mailto:${warexMail}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  return (
    <ThemeProvider theme={customFormTheme}>
      <Grid
        container
        spacing={"20px"}
        component={"form"}
        onSubmit={handleSubmit(onSubmitClick)}
      >
        <Grid item xs={12} lg={6}>
          <TextInput
            control={control}
            label={labels.nameLabelText}
            placeholder={labels.namePlaceholderText}
            name={contactUsFormKeys.name}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextInput
            control={control}
            label={labels.numberLabelText}
            placeholder={labels.numberPlaceholderText}
            name={contactUsFormKeys.number}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            control={control}
            label={labels.emailLabelText}
            placeholder={labels.emailPlaceholderText}
            name={contactUsFormKeys.email}
            type="email"
          />
        </Grid>
        <Grid item xs={12}>
          <DropDown
            name={contactUsFormKeys.subject}
            control={control}
            placeholder={labels.subjectPlaceholderText}
            label={labels.subjectLabelText}
            options={contactUsSubject}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            control={control}
            label={labels.descriptionLabelText}
            placeholder={labels.descriptionPlaceholderText}
            name={contactUsFormKeys.description}
            multiline={true}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledSubmitButton onClick={handleSubmit(onSubmitClick)}>
            submit
          </StyledSubmitButton>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default ContactForm;
