import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import { getCustomCell } from "../../../purchase/pages/PurchaseOrders";
import { getRouteByOrderType } from "../../pages/Register";
import { stringCapitalization } from "../../../shared/utils";
import { StyledChip } from "../../../shared/UiElements/StatusChip";
import { useNavigate } from "react-router-dom";

const ForkLift = (
  <path d="M19.3549 11.1601H17.8065H15.4839V10.2001H18.4516L19.7419 5.40006H20.9032L19.3549 11.1601ZM23.2258 0.600098V17.6475C22.6722 17.1881 22.0065 16.9201 21.2904 16.9201C19.3662 16.9201 17.8065 18.8542 17.8065 21.2401C17.8065 21.745 17.8763 22.2297 18.0047 22.6801H16.0598C16.1883 22.2296 16.2581 21.745 16.2581 21.2401C16.2581 18.8542 14.6983 16.9201 12.7742 16.9201C10.8501 16.9201 9.29033 18.8542 9.29033 21.2401C9.29033 21.745 9.3602 22.2296 9.48861 22.6801H8.51615V21.7201H7.74197V22.6801H6.19357H0V20.7601H6.19357V0.600098H7.74197V15.9601H8.51615V15L13.9355 0.600098H23.2258ZM15.4838 14.0401C15.4838 12.9797 16.1771 12.1201 17.0323 12.1201H21.6774V2.52006H14.7097L10.0645 15H15.4838V14.0401ZM12.7742 17.8801C14.2707 17.8801 15.4839 19.3844 15.4839 21.2401C15.4839 23.0958 14.2707 24.6001 12.7742 24.6001C11.2777 24.6001 10.0646 23.0958 10.0646 21.2401C10.0646 19.3844 11.2777 17.8801 12.7742 17.8801ZM21.2904 17.8801C22.7868 17.8801 24 19.3844 24 21.2401C24 23.0958 22.7868 24.6001 21.2904 24.6001C19.7938 24.6001 18.5807 23.0958 18.5807 21.2401C18.5807 19.3844 19.7938 17.8801 21.2904 17.8801ZM5.41939 12.12H3.09681V10.2H5.41939V12.12ZM2.32263 12.12H4.89629e-05V10.2H2.32263V12.12ZM5.41939 9.24004H1.5484V7.32002H5.41939V9.24004ZM4.89629e-05 19.8001V13.08H5.41939V19.8001H4.89629e-05Z" />
);

const Unloading = (
  <path d="M1.88252 17.6062C1.96877 18.0047 2.29877 18.2437 2.61752 18.1359L10.7325 15.4172C11.0513 15.3094 11.2425 14.8969 11.1563 14.4984L9.29251 5.80312C9.20626 5.40468 8.87626 5.16562 8.55751 5.27343L5.66252 6.24843L6.59251 10.5937L4.27502 11.3672L3.34502 7.02187L0.44627 7.99218C0.12752 8.09999 -0.0637294 8.51249 0.0225205 8.91093L1.88252 17.6062ZM14.4 0C13.7363 0 13.2 0.670312 13.2 1.5V16.6687L0.22127 21.0937C0.0600205 21.15 -0.0337295 21.3562 0.0112705 21.5531L0.48377 23.7234C0.52877 23.925 0.69377 24.0422 0.85127 23.9859L15.615 18.9515C15.705 21.7547 17.535 24 19.8 24C22.1213 24 24 21.6515 24 18.75V0L14.4 0ZM19.8 21C18.8063 21 18 19.9922 18 18.75C18 17.5078 18.8063 16.5 19.8 16.5C20.7938 16.5 21.6 17.5078 21.6 18.75C21.6 19.9922 20.7938 21 19.8 21Z" />
);

const MobileRegister = (row) => {
  const navigate = useNavigate();
  return (
    <Grid container spacing={"20px"}>
      <Grid item xs={12} container rowSpacing={1}>
        <Grid item xs={6}>
          {getCustomCell(
            row.docNumber,
            "",
            getRouteByOrderType(
              row.docTypeName,
              row.orderId,
              row.relatedOrderId
            )
          )}
        </Grid>
        <Grid
          item
          xs={6}
          display={"flex"}
          justifyContent={"right"}
          alignItems={"center"}
        >
          <Typography
            fontSize={14}
            fontWeight={500}
            lineHeight={"18px"}
            color={"text.secondary"}
          >
            {row.docDate}
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          display={"flex"}
          justifyContent={"left"}
          alignItems={"center"}
        >
          <Typography
            fontSize={13}
            fontWeight={400}
            lineHeight={"16px"}
            color={"text.primary"}
          >
            {row.warehouse}
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          display={"flex"}
          justifyContent={"right"}
          alignItems={"center"}
        >
          <IconButton
            onClick={() =>
              row.registerAction.svgType
                ? navigate(
                    `/warehouse/register/Material-Received-Report/${row.orderId}`
                  )
                : navigate(`/warehouse/register/Gate-Pass/${row.orderId}`)
            }
          >
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              fill={
                row.registerAction.svgType
                  ? row.registerAction.color
                    ? "#027AFF"
                    : "#B7BEC7"
                  : row.registerAction.color
                  ? "#F9A90E"
                  : "#B7BEC7"
              }
              xmlns="http://www.w3.org/2000/svg"
            >
              {row.registerAction.svgType ? ForkLift : Unloading}
            </svg>
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={6}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={0.5}
            alignItems={"flex-start"}
          >
            <StyledChip
              label={stringCapitalization(row.orderProgress)}
              variant="text"
            />
            <Typography
              fontSize={12}
              lineHeight={"16px"}
              color={"text.disabled"}
            >
              Status
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={6}
          display={"flex"}
          justifyContent={"right"}
          alignItems={"center"}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={0.5}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography
              fontSize={13}
              lineHeight={"18px"}
              fontWeight={500}
              color={"#2773FF"}
            >
              {Boolean(row.registerEntries) ? `${row.registerEntries}%` : "0%"}
            </Typography>
            <Typography
              fontSize={12}
              lineHeight={"18px"}
              color={"text.disabled"}
            >
              {Boolean(row.registerDate) ? row.registerDate : "--"}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {row.toOrderProgress &&
        row?.subRow?.map((item, index) => (
          <Grid item xs={12} container rowSpacing={1} key={index}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6}>
              {getCustomCell(
                row.docNumber,
                "",
                getRouteByOrderType(
                  row.docTypeName,
                  row.orderId,
                  row.relatedOrderId
                )
              )}
            </Grid>
            <Grid
              item
              xs={6}
              display={"flex"}
              justifyContent={"right"}
              alignItems={"center"}
            >
              <Typography
                fontSize={14}
                fontWeight={500}
                lineHeight={"18px"}
                color={"text.secondary"}
              >
                {row.docDate}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              display={"flex"}
              justifyContent={"left"}
              alignItems={"center"}
            >
              <Typography
                fontSize={13}
                fontWeight={400}
                lineHeight={"16px"}
                color={"text.primary"}
              >
                {row?.vendorData?.warehouse?.code}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              display={"flex"}
              justifyContent={"right"}
              alignItems={"center"}
            >
              <IconButton
                onClick={() =>
                  (row?.itemDetails?.outQuantity >
                    row?.itemDetails?.inQuantity ||
                    row?.itemDetails?.inQuantity > 0) &&
                  navigate(
                    `/warehouse/register/Material-Received-Report/${row.orderId}`
                  )
                }
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill={
                    row?.itemDetails?.outQuantity > row?.itemDetails?.inQuantity
                      ? "#027AFF"
                      : "#B7BEC7"
                  }
                  xmlns="http://www.w3.org/2000/svg"
                >
                  {ForkLift}
                </svg>
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={0.5}
                alignItems={"flex-start"}
              >
                <StyledChip
                  label={stringCapitalization(row.toOrderProgress)}
                  variant="text"
                />
                <Typography
                  fontSize={12}
                  lineHeight={"16px"}
                  color={"text.disabled"}
                >
                  Status
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              display={"flex"}
              justifyContent={"right"}
              alignItems={"center"}
            >
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={0.5}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Typography
                  fontSize={13}
                  lineHeight={"18px"}
                  fontWeight={500}
                  color={"#2773FF"}
                >
                  {Boolean(row.registerEntries)
                    ? `${row.registerEntries}%`
                    : "0%"}
                </Typography>
                <Typography
                  fontSize={12}
                  lineHeight={"18px"}
                  color={"text.disabled"}
                >
                  {Boolean(row.registerDate) ? row.registerDate : "--"}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        ))}
    </Grid>
  );
};

export default MobileRegister;
