import {
  AppBar,
  Box,
  Grid,
  Tab,
  Tabs,
  Toolbar,
  Typography
} from "@mui/material";
import ContactUsSection from "../components/ContactUsSection";
import Footer from "../components/Footer";
import { contactUsFormKeys } from "../../constants/formKeys";
import { home_labels, labels } from "../../constants";
import { useForm } from "react-hook-form";
import ERPChart from "../../assets/ERP.webp";
import AddSKU from "../../assets/Add_SKU.webp";
import ItemLedger from "../../assets/Item_Ledger.webp";
import SKUPerformance from "../../assets/SKU_Performance.webp";
import Vendors from "../../assets/Vendors.webp";
import PurchaseOrders from "../../assets/PurchaseOrders.webp";
import CreatePO from "../../assets/Create_PO.webp";
import StockTransfer from "../../assets/StockTransfer.webp";
import StockAdjustment from "../../assets/StockAdjustment.webp";
import Stores from "../../assets/Stores.webp";
import Quotes from "../../assets/Quotes.webp";
import SaleOrders from "../../assets/SaleOrders.webp";
import CreateSo from "../../assets/Create_SO.webp";
import Allocation from "../../assets/Allocations.webp";
import Assets from "../../assets/Assets.webp";
import Register from "../../assets/Register.webp";
import MRR from "../../assets/MRR.webp";
import GP from "../../assets/GP.webp";
import Admin from "../../assets/Admin.webp";
import PaymentLedger from "../../assets/PaymentLedger.webp";
import AR from "../../assets/AR.webp";
import Create_Invoice from "../../assets/Create_Invoice.webp";
import { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { Link as ScrollLink } from "react-scroll";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination } from "swiper/modules";
import useInViewSections from "../../shared/hooks/useInViewSections";

const menu = [
  "Inventory",
  "Purchase",
  "Sales",
  "Warehouse",
  "Finance",
  "Admin"
];

const menuComponents = [
  {
    id: "Inventory",
    label: "Manage 1000+ SKUs, Brands, Stock levels and Performance.",
    imageWithText: [
      { id: "AddSKU", image: AddSKU, text: "Add/ Edit/ Upload SKUs" },
      {
        id: "ItemLedger",
        image: ItemLedger,
        text: "Item wise Ledger and Sales queue."
      },
      {
        id: "SKUPerformance",
        image: SKUPerformance,
        text: "Track detailed SKU movement and Gross Margins"
      }
    ]
  },
  {
    id: "Purchase",
    label:
      "Manage Vendors, Create Purchase Orders, Returns, Stock Transfers and Adjustments.",
    imageWithText: [
      {
        id: "Vendors",
        image: Vendors,
        text: "Manage Vendors, Dues and Payables."
      },
      {
        id: "PurchaseOrders",
        image: PurchaseOrders,
        text: "Track Purchase Orders and returns through dashboards."
      },
      {
        id: "CreatePO",
        image: CreatePO,
        text: "Create Purchase Orders and Returns with payment terms."
      },
      {
        id: "StockTransfer",
        image: StockTransfer,
        text: "Track your Stock Transfers in between multiple warehouses"
      },
      {
        id: "StockAdjustment",
        image: StockAdjustment,
        text: "Authorized Stock Adjustments on damage/ theft"
      }
    ]
  },
  {
    id: "Sales",
    label:
      "Manage Customers, Sales Orders, Returns, Quotations and Receivables.",
    imageWithText: [
      { id: "Stores", image: Stores, text: "Manage multiple Customer Stores." },
      {
        id: "Quotes",
        image: Quotes,
        text: "Crete Quotations and followup with status updates.."
      },
      {
        id: "SaleOrders",
        image: SaleOrders,
        text: "Track deliveries of Sales Orders, returns and receivables."
      },
      {
        id: "CreateSo",
        image: CreateSo,
        text: "Create Sales Orders and Returns."
      },
      {
        id: "Allocation",
        image: Allocation,
        text: "Prioritize and Allocate Sales Orders to multiple warehouses."
      }
    ]
  },
  {
    id: "Warehouse",
    label:
      "Control Inwards and Outwards of all stock, receipts and deliveries.",
    imageWithText: [
      {
        id: "Assets",
        image: Assets,
        text: "Manage multiple warehouses and vehicles"
      },
      {
        id: "Register",
        image: Register,
        text: "Perform Inwards and Outwards as per Orders placed"
      },
      {
        id: "MRR",
        image: MRR,
        text: "Prepare Material Received Report / Goods Received Note (along with rejections)"
      },
      {
        id: "GP",
        image: GP,
        text: "Prepare multiple Gate passes till the entire order is fulfilled."
      }
    ]
  },
  {
    id: "Finance",
    label: "Create Invoices and track receivables and payables",
    imageWithText: [
      {
        id: "PaymentLedger",
        image: PaymentLedger,
        text: "View Customer/ Sales Order wise Ledgers"
      },
      {
        id: "AR",
        image: AR,
        text: "Track dues, over dues in receivables and payables."
      },
      {
        id: "Create_Invoice",
        image: Create_Invoice,
        text: "Create Invoices, Credit Notes and Debit Notes."
      }
    ]
  },
  {
    id: "Admin",
    label: "Manage Users and Subscriptions",
    imageWithText: [
      { id: "Admin", image: Admin, text: "Manage Users and allocate roles" }
    ]
  }
];

const customTitleText = (title, text) => {
  return (
    <Box maxWidth={"510px"}>
      <Typography
        sx={{
          fontSize: { xs: "24px", md: "32px" },
          fontWeight: 700,
          lineHeight: { xs: "36px", md: "48px" },
          letterSpacing: "-0.01em",
          textAlign: "justified",
          color: "#F9A90E"
        }}
        component="span"
      >
        {title}
      </Typography>{" "}
      <Typography
        style={{
          fontSize: "24px",
          fontWeight: 700,
          lineHeight: "36px",
          color: "#2773FF"
        }}
        component="span"
      >
        -
      </Typography>{" "}
      <Typography
        style={{
          fontSize: { xs: "14px", md: "20px" },
          fontWeight: 400,
          lineHeight: { xs: "21px", md: "30px" },
          color: "#607088"
        }}
        component="span"
      >
        {text}
      </Typography>
    </Box>
  );
};

const PageImageAndTextComponent = (item) => {
  return (
    <Box
      id={item.id}
      position={"relative"}
      display={"flex"}
      flexDirection={"column"}
      overflow={"hidden"}
      sx={{ gap: { xs: 1, md: 3 } }}
    >
      <Box
        display={"flex"}
        sx={{
          width: "100%",
          position: "relative",
          paddingTop: "100%",
          overflow: "hidden"
        }}
      >
        <img
          loading="lazy"
          src={item.image}
          alt={item.id}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "contain"
          }}
        />
      </Box>
      <Typography
        color={"#607088"}
        sx={{
          fontSize: {
            xs: "14px",
            md: "22px"
          },
          lineHeight: {
            xs: "21px",
            md: "33px"
          },
          fontWeight: 400
        }}
        letterSpacing={"-0.01em"}
      >
        {item.text}
      </Typography>
    </Box>
  );
};

const SliderComponent = (props) => {
  const swiperRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const targetElement = containerRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && entry.intersectionRatio >= 0.4) {
            if (swiperRef.current) swiperRef.current.autoplay.start();
          } else {
            if (swiperRef.current) swiperRef.current.autoplay.stop();
          }
        });
      },
      {
        threshold: 0.4
      }
    );

    if (targetElement) {
      observer.observe(targetElement);
    }

    return () => {
      if (targetElement) {
        observer.unobserve(targetElement);
      }
    };
  }, []);

  const slides = props?.imageWithText || [];

  // Ensure enough slides for loop
  // const duplicatedSlides =
  //   slides.length === 3 ? [...slides, ...slides, ...slides] : slides;

  return (
    <Box ref={containerRef} display={"flex"} flexDirection={"column"}>
      {customTitleText(props.id, props.label)}
      {slides.length > 1 ? (
        <Box>
          <Swiper
            slidesPerView={isMobile ? 1 : 3}
            spaceBetween={30}
            speed={1000}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            }}
            loop={true}
            pagination={{
              clickable: true
            }}
            modules={[Autoplay, Pagination]}
            className="mySwiper-4"
            onSwiper={(swiper) => (swiperRef.current = swiper)}
          >
            {slides.map((item) => (
              <SwiperSlide key={item.label}>
                <Grid item xs={12} md={4} style={{ marginRight: "16px" }}>
                  <PageImageAndTextComponent
                    id={props.id}
                    image={item.image}
                    text={item.text}
                  />
                </Grid>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      ) : (
        slides.map((item) => (
          <Grid container key={item.label}>
            <Grid item xs={12} md={4}>
              <PageImageAndTextComponent
                id={props.id}
                image={item.image}
                text={item.text}
              />
            </Grid>
          </Grid>
        ))
      )}
    </Box>
  );
};

const ERP = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const { control } = useForm({
    defaultValues: {
      [contactUsFormKeys.name]: "",
      [contactUsFormKeys.number]: "",
      [contactUsFormKeys.email]: "",
      [contactUsFormKeys.subject]: "",
      [contactUsFormKeys.description]: ""
    }
  });

  const activeSection = useInViewSections(
    menu.map((item) => item.toLowerCase())
  );

  useEffect(() => {
    if (activeSection) {
      const index = menu
        .map((item) => item.toLowerCase())
        .indexOf(activeSection);
      setSelectedTab(index);
    }
  }, [activeSection]);

  const handleTabChange = (event, index) => {
    setSelectedTab(index);
  };

  useEffect(() => {
    window.scroll({
      left: 0,
      top: 0,
      behavior: "smooth"
    });
  }, []);

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        sx={{
          padding: { xs: "30px 25px 0px 25px", md: "30px 70px 0px 80px" },
          gap: 2
        }}
      >
        <Typography
          sx={{
            color: "#F9A90E",
            fontSize: { xs: "20px", md: "36px" },
            lineHeight: { xs: "30px", md: "54px" },
            letterSpacing: "-1%",
            textAlign: "justify"
          }}
          fontWeight={700}
        >
          {`${labels.erpHeaderText1} `}
          <span style={{ color: "#2773FF" }}>{labels.erpHeaderText2}</span>
        </Typography>
        <Box
          display={"flex"}
          sx={{
            width: "100%",
            position: "relative",
            overflow: "hidden"
          }}
        >
          <img
            loading="lazy"
            src={ERPChart}
            alt={" ERP Software for SMEs"}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain" // Ensures the entire image fits within the box
            }}
          />
        </Box>
        <Box display={"flex"} flexDirection={"column"} mb={2}>
          <Typography
            sx={{
              color: "text.secondary",
              fontSize: { xs: "14px", md: "22px" },
              lineHeight: { xs: "21px", md: "33px" },
              letterSpacing: "-1%",
              textAlign: "justify"
            }}
            fontWeight={400}
          >
            <span style={{ color: "#2773FF" }}>Multi user access simple</span>{" "}
            ERP software designed for Small Businesses.
          </Typography>
          <Typography
            sx={{
              color: "text.secondary",
              fontSize: { xs: "14px", md: "22px" },
              lineHeight: { xs: "21px", md: "33px" },
              letterSpacing: "-1%",
              textAlign: "justify"
            }}
            fontWeight={400}
          >
            <span style={{ color: "#2773FF" }}> Zero training</span>
            required to adapt and implement.
          </Typography>
          <Typography
            sx={{
              color: "text.secondary",
              fontSize: { xs: "14px", md: "22px" },
              lineHeight: { xs: "21px", md: "33px" },
              letterSpacing: "-1%",
              textAlign: "justify"
            }}
            fontWeight={400}
          >
            Specially designed to{" "}
            <span style={{ color: "#2773FF" }}> authenticate</span> will and
            control inventory, sales and purchase tracking and manage accounts
            <span style={{ color: "#2773FF" }}> receivables and payables.</span>
          </Typography>
        </Box>
        <AppBar position="sticky" style={{ backgroundColor: "#F0F2F5" }}>
          <Toolbar
            style={{
              overflowX: isMobile ? "scroll" : "hidden",
              whiteSpace: isMobile ? "nowrap" : "normal"
            }}
          >
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              variant={isMobile ? "scrollable" : "fullWidth"}
              scrollButtons="auto"
              aria-label="scrollable header tabs"
              // textColor="inherit"
              TabIndicatorProps={{ style: { display: "none" } }}
              sx={{
                borderBottom: "none",
                ".MuiTabs-flexContainer": { borderBottom: "none" }
              }}
            >
              {menu.map((label, index) => (
                <ScrollLink
                  key={label}
                  to={label.toLowerCase()}
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-90}
                  onClick={() => setSelectedTab(index)}
                >
                  <Tab
                    label={label}
                    sx={{
                      fontSize: { xs: "16px", md: "24px" },
                      fontWeight: 700,
                      lineHeight: { xs: "24px", md: "36px" },
                      letterSpacing: "-0.01em",
                      textAlign: "justified",
                      color:
                        selectedTab === index
                          ? "#2773FF !important"
                          : "#607088",
                      mr: { xs: 0, md: 6 },
                      opacity: 1,
                      borderBottom: "none"
                    }}
                  />
                </ScrollLink>
              ))}
            </Tabs>
          </Toolbar>
        </AppBar>
        {menuComponents.map((component) => {
          return (
            <Box key={component.id} id={component.id.toLowerCase()} mt={4}>
              <SliderComponent {...component} />
            </Box>
          );
        })}
        <Typography
          sx={{
            color: "text.secondary",
            fontSize: { xs: "12px", md: "22px" },
            lineHeight: { xs: "18px", md: "33px" },
            letterSpacing: "-1%",
            textAlign: "justify"
          }}
          fontWeight={700}
        >
          {labels.contactUsToBookDemoText}
        </Typography>
      </Box>
      <ContactUsSection
        control={control}
        title={home_labels.partnerWithUsTitleText}
      />
      <Footer />
    </>
  );
};
export default ERP;
