import React from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Radio,
  styled,
  Typography
} from "@mui/material";
import { Controller } from "react-hook-form";
import { ExploreSvgs } from "../components/ExploreSvgs";
import {
  otherControlsHover,
  otherControlsResting
} from "../../shared/theme/globalTheme";

const CustomIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 20,
  height: 20,
  backgroundColor: otherControlsResting,
  "input:hover ~ &": {
    backgroundColor: otherControlsHover
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background: "#EEF1F4"
  }
}));

const CustomIconChecked = styled(CustomIcon)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  "&:before": {
    display: "block",
    width: 20,
    height: 20,
    backgroundImage: "radial-gradient(#fff 40%,transparent 50%)",
    content: '""'
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.primary.dark
  }
}));

const RadioCard = ({
  name,
  size,
  mobileSize,
  SvgImg,
  control,
  selectedValue,
  selectedColor,
  label,
  isTruckType = false
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <Box
            display={"flex"}
            flexDirection={"column"}
            sx={{ gap: { xs: 1, md: 2 }, flex: 1 }}
          >
            <Card
              sx={{
                transition: "transform 0.3s, border-color 0.3s",
                borderColor:
                  field.value === selectedValue ? selectedColor : "#607088",
                borderWidth: field.value === selectedValue ? "4px" : "1px",
                borderStyle: "solid",
                transform:
                  field.value === selectedValue ? "translateY(-4px)" : "none",
                cursor: "pointer",
                borderRadius: 0,
                boxShadow:
                  field.value === selectedValue && "0px 4px 4px 0px #00000040",
                p: { xs: 0.5, md: 1 },
                maxHeight: { md: size },
                maxWidth: { md: size },
                flex: 1
              }}
            >
              <CardContent>
                <Box position={"relative"}>
                  <CardActionArea
                    onClick={() => field.onChange(selectedValue)}
                    disableRipple
                    disableTouchRipple
                    sx={{
                      "&:hover": {
                        backgroundColor: "transparent"
                      },
                      "& .MuiCardActionArea-focusHighlight": {
                        backgroundColor: "transparent"
                      }
                    }}
                  >
                    <Box sx={{ position: "absolute", top: 0, left: 0 }}>
                      <Radio
                        checked={field.value === selectedValue}
                        checkedIcon={<CustomIconChecked />}
                        icon={<CustomIcon />}
                      />
                    </Box>
                    <Box
                      sx={{
                        height: { xs: mobileSize, md: size },
                        display: "flex",
                        justifyContent: "center",
                        alignItems: {
                          xs: isTruckType ? "flex-end" : "center",
                          md: "center"
                        }
                      }}
                    >
                      <ExploreSvgs
                        currentColor={
                          field.value === selectedValue
                            ? selectedColor
                            : "#607088"
                        }
                        type={SvgImg}
                      />
                    </Box>
                  </CardActionArea>
                </Box>
              </CardContent>
            </Card>
            <Typography
              sx={{
                fontSize: {
                  xs: isTruckType ? "12px" : "14px",
                  md: isTruckType ? "20px" : "24px"
                },
                lineHeight: {
                  xs: isTruckType ? "14.52px" : "16.94px",
                  md: isTruckType ? "24.2px" : "29.05px"
                },
                fontWeight: 500
              }}
            >
              {label}
            </Typography>
          </Box>
        );
      }}
    />
  );
};

export default RadioCard;
