import { ArrowBack } from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  // Grid,
  IconButton,
  Link,
  Typography
} from "@mui/material";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { useContext } from "react";

import { AppContext } from "../context/auth-context";
// import Filters from "../../customer/components/Filters";
// import Storage from "../utils/Storage";
// import { StorageKeys } from "../../constants/storage-keys";
import appRoutes from "../../constants/appRoutes";
import { isMobileDevice } from "../../App";
// import { useDrawer } from "../customSideDrawer";

function LinkRouter(props) {
  return <Link {...props} component={RouterLink} />;
}

function Crumb({ title, to, last = false }) {
  if (last) {
    return (
      <Typography
        color="text.secondary"
        fontWeight={600}
        fontSize={16}
        letterSpacing="0.005em"
        textTransform={"capitalize"}
      >
        {title}
      </Typography>
    );
  }

  return (
    <LinkRouter
      underline="none"
      color="primary.main"
      to={to}
      key={to}
      fontWeight={600}
      fontSize={16}
      letterSpacing="0.005em"
      textTransform={"capitalize"}
    >
      {title}
    </LinkRouter>
  );
}

function generatedCrumbs(crumbsStack) {
  // const pathnames = location.pathname.split("/").filter((x) => x);

  // const crumbList = pathnames.map((value, index) => {
  //   // const to = `/${pathnames.slice(0, index + 1).join("/")}`;
  //   const to = location.pathname;
  //   console.log(to);
  //   const title = crumbContext.crumbsStack.get(to);
  //   return { to, title };
  // });

  const crumbList = Array.from(crumbsStack, ([to, title]) => ({
    to,
    title
  }));

  return [...crumbList];
}

const BackNavigation = () => {
  // const searchFormParams = Storage.getItem(StorageKeys.SEARCH_FORM_PARAMS);
  const location = useLocation();
  // eslint-disable-next-line
  const { crumbsStack, removeCrumbStack } = useContext(AppContext);
  // const [formParams, setFormParams] = useState(searchFormParams);

  const navigate = useNavigate();
  // const { showDrawer } = useDrawer();

  const isExcludedPathsMobile = ![
    appRoutes.home,
    appRoutes.dashboard,
    appRoutes.explore.main,
    appRoutes.explore.listings,
    appRoutes.inquiry.inquiryListView,
    appRoutes.orders.main,
    appRoutes.inventory.main,
    appRoutes.warehouse.main,
    appRoutes.sales.main,
    appRoutes.purchase.main,
    appRoutes.purchase.main + "/" + appRoutes.purchase.stockTransfer,
    appRoutes.purchase.main + "/" + appRoutes.purchase.stockAdjustment,
    appRoutes.finance.main,
    appRoutes.settings,
    appRoutes.profile.main,
    appRoutes.profile.changePassword,
    appRoutes.profile.helpAndSupport,
    appRoutes.profile.faqs,
    appRoutes.profile.tnc
  ].includes(location.pathname);

  const isExcludedPathsDesktop = ![
    appRoutes.home,
    appRoutes.dashboard,
    appRoutes.explore.main,
    appRoutes.explore.listings,
    appRoutes.inquiry.inquiryListView,
    appRoutes.orders.main + "/" + appRoutes.orders.myOrdersListView,
    appRoutes.orders.main + "/" + appRoutes.orders.invoicesDocsListView,
    appRoutes.orders.main + "/" + appRoutes.orders.retrieveListView,
    "/orders/invoices-docs",
    appRoutes.inventory.main + "/" + appRoutes.inventory.sku,
    appRoutes.inventory.main + "/" + appRoutes.inventory.performance,
    appRoutes.warehouse.main + "/" + appRoutes.warehouse.assets,
    appRoutes.warehouse.main + "/" + appRoutes.warehouse.register,
    appRoutes.warehouse.main + "/" + appRoutes.warehouse.ewaybills,
    appRoutes.sales.main + "/" + appRoutes.sales.customers,
    appRoutes.sales.main + "/" + appRoutes.sales.quotes,
    appRoutes.sales.main + "/" + appRoutes.sales.salesOrders,
    appRoutes.sales.main + "/" + appRoutes.sales.allocation,
    appRoutes.purchase.main + "/" + appRoutes.purchase.vendors,
    appRoutes.purchase.main + "/" + appRoutes.purchase.purchaseOrders,
    appRoutes.purchase.main + "/" + appRoutes.purchase.stockTransfer,
    appRoutes.purchase.main + "/" + appRoutes.purchase.stockAdjustment,
    appRoutes.finance.main + "/" + appRoutes.finance.payments,
    appRoutes.finance.main + "/" + appRoutes.finance.invoices,
    appRoutes.settings,
    appRoutes.profile.main,
    appRoutes.profile.changePassword,
    appRoutes.profile.helpAndSupport,
    appRoutes.profile.faqs,
    appRoutes.profile.tnc
  ].includes(location.pathname);

  const isExcludedPaths = isMobileDevice
    ? isExcludedPathsMobile
    : isExcludedPathsDesktop;

  const breadcrumbs = generatedCrumbs(crumbsStack);

  // console.log(breadcrumbs, crumbsStack);

  return (
    isExcludedPaths && (
      <Box
        display={"flex"}
        alignItems={"center"}
        sx={{
          background: "#FFFFFF",
          padding: {
            xs: "12px 16px",
            sm: "0"
          },
          boxShadow: {
            xs: "0px 8px 24px rgba(21, 21, 22, 0.1)",
            sm: "none"
          },
          marginBottom: {
            xs: 0,
            sm: 2
          },
          position: "relative",
          zIndex: 1
        }}
      >
        {isExcludedPaths && (
          <IconButton
            size="small"
            onClick={() => {
              navigate(-1);
              // removeCrumbStack(location?.pathname);
            }}
            sx={{ marginRight: 1, marginLeft: -1 }}
          >
            <ArrowBack fontSize="small" />
          </IconButton>
        )}
        {/* <Breadcrumbs aria-label="breadcrumb" sx={{ flexGrow: 1 }}>
          {breadcrumbs.map(
            (crumb, idx) =>
              crumbsStack.get(crumb.to) && (
                <Crumb
                  {...crumb}
                  key={idx}
                  last={idx === breadcrumbs.length - 1}
                />
              )
          )}
        </Breadcrumbs> */}
        {/* {location.pathname === appRoutes.explore.listings && (
          <IconButton
            size="small"
            sx={{
              backgroundColor: "#F0F2F5",
              display: {
                xs: "flex",
                sm: "none"
              }
            }}
            onClick={() => {
              showDrawer({
                title: "Filters",
                height: "fit-content",
                component: (
                  <Grid p={2}>
                    <Filters applyFilters={(params) => setFormParams(params)} />
                  </Grid>
                )
              });
            }}
          >
            <TuneOutlined color="primary" fontSize="small" />
          </IconButton>
        )} */}
      </Box>
    )
  );
};

export default BackNavigation;
