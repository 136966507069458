import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography
} from "@mui/material";
import {
  Backpack,
  CalendarMonthOutlined,
  InfoOutlined,
  PlaceOutlined
} from "@mui/icons-material";
import {
  StyledBookNowButton,
  StyledDivider,
  StyledSectionHeader
} from "./DetailedWarehouse";
import { useNavigate, useParams } from "react-router-dom";

import { AppContext } from "../../../shared/context/auth-context";
import DetailsGrid from "./DetailsGrid";
import ImageGrid from "./ImageGrid";
import OrderBox from "./OrderBox";
import Storage from "../../../shared/utils/Storage";
import { StorageKeys } from "../../../constants/storage-keys";
import { StyledResponsiveText } from "../../../shared/theme/globalTheme";
import TransportSearchResults from "./TransportSearchResults";
import appRoutes from "../../../constants/appRoutes";
import { appServiceKeys } from "../../../constants/formKeys";
import { currencyFormatter } from "../../../shared/utils";
import { isMobileDevice } from "../../../App";
import { labels } from "../../../constants";
import { useContext } from "react";
import { useDrawer } from "../../../shared/customSideDrawer";
import { useFetchTransportByPartnerId } from "../../../query-hooks/Transport/useFetchTransportByPartnerId";
import { useState } from "react";

const DetailedTransporter = (props) => {
  const { service, uuid } = useParams();
  const auth = useContext(AppContext);
  const navigate = useNavigate();

  const searchFormParams = Storage.getItem(StorageKeys.SEARCH_FORM_PARAMS);

  const { isFetching: isTransportFetching, data: transportData } =
    useFetchTransportByPartnerId(searchFormParams, uuid);

  const [showMore, setShowMore] = useState(false);

  const { showDrawer, hideDrawer } = useDrawer();

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const onBookOrder = () => {
    auth.setCrumbStack(
      appRoutes.completeOrder.serviceBookOrderRedirection +
        `${service}/${uuid}`,
      labels.completeYourOrder
    );

    navigate(
      appRoutes.completeOrder.serviceBookOrderRedirection +
        `${service}/${uuid}`,
      {
        state: {
          order: {
            transport: transportData.transport
          }
        }
      }
    );
  };

  const openMobileOrderBox = () => {
    showDrawer({
      component: (
        <OrderBox
          totalAmount={transportData?.transport?.costs?.totalAmount}
          storageCost={transportData?.transport?.costs?.serviceCost}
          subtotal={transportData?.transport?.costs?.subTotalAmount}
          gstAt={transportData?.transport?.costs?.taxCost}
          additionalCost={
            transportData?.transport?.costs?.additionalServiceCost
          }
          storageType={transportData?.transport?.vehicles[0].rateUnit}
          rate={transportData?.transport?.vehicles[0].rate}
          facilityLabel={labels.transportCost}
          gstRegime={transportData?.transport?.gstRegime}
          closeDrawer={hideDrawer}
          serviceType={appServiceKeys.transport}
        />
      ),
      closeIcon: false
    });
  };

  const transporter = isTransportFetching ? (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isTransportFetching}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : (
    <Box paddingBottom={2}>
      <Box
        maxHeight={360}
        overflow="hidden"
        marginBottom={5}
        sx={{
          display: {
            xs: "flex",
            sm: "none"
          }
        }}
      >
        <img
          src={transportData?.transport?.vehicles?.[0]?.heroImage?.fileUrl}
          alt={transportData?.transport?.vehicles?.[0]?.heroImage?.originalName}
          width={"100%"}
          style={{ objectFit: "cover" }}
        />
      </Box>
      <Box
        sx={{
          padding: {
            sm: "initial",
            xs: "0 16px"
          }
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sx={{
              display: {
                xs: "none",
                sm: "block"
              }
            }}
          >
            <ImageGrid
              imageList={transportData?.transport?.vehicles?.map(
                (item, index) => {
                  if (item?.heroImage?.highlight || index === 0) {
                    return {
                      img: item?.heroImage?.fileUrl,
                      title: item?.heroImage?.originalName,
                      rows: 2,
                      cols: 2
                    };
                  }
                  return {
                    img: item?.heroImage?.fileUrl,
                    title: item?.heroImage?.originalName
                  };
                }
              )}
            />
          </Grid>
          <Grid item xs={12} container spacing={2}>
            <Grid
              item
              xs={12}
              lg={7}
              container
              spacing={2}
              height="fit-content"
            >
              <Grid item xs={12}>
                <StyledResponsiveText
                  color={"text.primary"}
                  desktop={{
                    fontSize: 28,
                    fontWeight: 600,
                    lineHeight: "33.89px",
                    letterSpacing: "-0.002em"
                  }}
                  mobile={{
                    fontSize: 20,
                    fontWeight: 500,
                    lineHeight: "24.2px",
                    letterSpacing: "0.002em"
                  }}
                >
                  {transportData?.transport.code}
                </StyledResponsiveText>
                <Box
                  component="div"
                  marginTop={"12px"}
                  display="flex"
                  alignItems="center"
                >
                  <PlaceOutlined fontSize="small" sx={{ marginRight: 1 }} />
                  <Typography
                    component="p"
                    color={"text.primary"}
                    sx={{
                      fontSize: 14,
                      fontWeight: 600,
                      lineHeight: "20px",
                      whiteSpace: "nowrap"
                    }}
                  >
                    {`${transportData?.transport.address.cluster}, `}
                    <Typography
                      component="span"
                      color={"text.secondary"}
                      sx={{
                        fontSize: 14,
                        fontWeight: 400,
                        lineHeight: "16.94px",
                        whiteSpace: "nowrap"
                      }}
                    >
                      {`${transportData?.transport.address.city}, ${transportData?.transport.address.state}`}
                    </Typography>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <StyledDivider
                  sx={{
                    mt: 1,
                    mb: 1
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography fontSize={14} lineHeight={"20px"} sx={{ mr: 3 }}>
                  {showMore
                    ? transportData?.transport.description
                    : transportData?.transport.description.slice(0, 350)}
                  {transportData?.transport.description.length > 350 && (
                    <Button
                      variant="text"
                      onClick={toggleShowMore}
                      sx={{
                        color: "#0F2744",
                        display: showMore ? "none" : "",
                        "&:hover": {
                          backgroundColor: "transparent"
                        },
                        "&:active": {
                          backgroundColor: "transparent"
                        }
                      }}
                    >
                      <Typography
                        fontSize={14}
                        lineHeight={"20px"}
                        fontWeight={600}
                      >
                        {labels.showMore}
                      </Typography>
                    </Button>
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} marginTop={isMobileDevice ? 1 : 3}>
                <DetailsGrid
                  gridData={[
                    {
                      icon: (
                        <CalendarMonthOutlined
                          fontSize="small"
                          color="primary"
                          sx={{ mr: "11px" }}
                        />
                      ),
                      label: labels.yearEstablishedLabelText,
                      value: transportData?.transport.establishedYear
                    }
                  ]}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledDivider />
              </Grid>
              <Grid item xs={12}>
                <StyledSectionHeader>
                  {labels.trucksAvailable}
                </StyledSectionHeader>
              </Grid>
              {transportData?.transport?.vehicles.map((item) => {
                return (
                  <Grid item xs={12} key={item?.heroImage?.originalName}>
                    <DetailsGrid
                      gridData={[
                        {
                          icon: (
                            <Backpack
                              fontSize="small"
                              color="primary"
                              sx={{ mr: "11px" }}
                            />
                          ),
                          label: labels.truckType,
                          value:
                            item.make.displayName + " " + item.model.displayName
                        },
                        {
                          icon: (
                            <Backpack
                              fontSize="small"
                              color="primary"
                              sx={{ mr: "11px" }}
                            />
                          ),
                          label: labels.capacity,
                          value: item.weightCapacity + " Ton"
                        },
                        {
                          icon: (
                            <Backpack
                              fontSize="small"
                              color="primary"
                              sx={{ mr: "11px" }}
                            />
                          ),
                          label: labels.pricekm,
                          value: currencyFormatter(item.rate)
                        }
                      ]}
                    />
                  </Grid>
                );
              })}

              <Grid item xs={12}>
                <StyledDivider />
              </Grid>
              <Grid item xs={12} sx={{ display: { xs: "none", sm: "block" } }}>
                <StyledSectionHeader>
                  {labels.additionalServicesTitleText}
                </StyledSectionHeader>
              </Grid>
              <Grid item xs={12}>
                <DetailsGrid
                  gridData={transportData?.transport?.additionalServices.map(
                    (item) => {
                      return {
                        icon: (
                          <Backpack
                            fontSize="small"
                            color="primary"
                            sx={{ mr: "11px" }}
                          />
                        ),
                        label: item.displayName,
                        value:
                          currencyFormatter(item.rate) + " / " + item.rateUnit
                      };
                    }
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledDivider />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              lg={5}
              sx={{
                display: {
                  xs: "none",
                  lg: "block"
                }
              }}
            >
              <OrderBox
                totalAmount={transportData?.transport?.costs?.totalAmount}
                storageCost={transportData?.transport?.costs?.serviceCost}
                subtotal={transportData?.transport?.costs?.subTotalAmount}
                gstAt={transportData?.transport?.costs?.taxCost}
                additionalCost={
                  transportData?.transport?.costs?.additionalServiceCost
                }
                storageType={transportData?.transport?.vehicles[0].rateUnit}
                rate={transportData?.transport?.vehicles[0].rate}
                facilityLabel={labels.transportCost}
                onSubmit={onBookOrder}
                gstRegime={transportData?.transport?.gstRegime}
                serviceType={appServiceKeys.transport}
              />
            </Grid>
            <TransportSearchResults />
            <Grid item sx={{ mb: { xs: 10, md: 0 } }}></Grid>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          display: {
            xs: "flex",
            lg: "none"
          },
          bottom: "3%",
          left: "50%",
          transform: "translate(-50%, -50%)"
        }}
        position={"fixed"}
        zIndex={1}
        alignContent={"center"}
        backgroundColor={"#fff"}
        width={"100%"}
      >
        <Divider sx={{ borderColor: "#B7BEC7" }} />
        <Box
          sx={{
            padding: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 5,
            width: "100%"
          }}
        >
          <Box display={"flex"} flexDirection={"column"} gap={0.5}>
            <Box display={"flex"} gap={0.5} alignItems={"center"}>
              <Typography
                color="text.primary"
                fontWeight={600}
                fontSize={16}
                lineHeight={"19.36px"}
                letterSpacing="0.005em"
              >
                {currencyFormatter(
                  parseFloat(
                    transportData?.transport?.costs?.totalAmount
                  ).toFixed(2)
                )}
              </Typography>
              <Box display={"flex"} onClick={openMobileOrderBox}>
                <InfoOutlined fontSize="small" />
              </Box>
            </Box>
            <Typography
              color="text.secondary"
              fontWeight={400}
              fontSize={14}
              lineHeight={"20px"}
            >
              {labels.totalAmountText}
            </Typography>
          </Box>
          <StyledBookNowButton variant="contained" onClick={onBookOrder}>
            {labels.bookNowButtonText}
          </StyledBookNowButton>
        </Box>
      </Box>
    </Box>
  );

  return <Box>{transporter}</Box>;
};

export default DetailedTransporter;
