import { getCustomerFormData } from "../../../sales/services";
import { queryKeys } from "../../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchCustomerFormData = (customerId) => {
  const queryCustomerFormData = useQuery(
    [queryKeys.queryFetchCustomerFormData],
    () => getCustomerFormData(),
    {
      enabled: !Boolean(customerId),
      refetchOnWindowFocus: false
    }
  );

  return queryCustomerFormData;
};
