import { getQuotesSubtotal } from "../../../sales/services/index";
import { queryKeys } from "../../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchQuotesSubtotal = (params) => {
  const queryQuoteSubtotal = useQuery(
    [queryKeys.queryFetchQuotesSubtotal, JSON.stringify(params)],
    () => getQuotesSubtotal(params),
    {
      refetchOnWindowFocus: false
    }
  );

  return queryQuoteSubtotal;
};
