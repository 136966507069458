import { queryKeys } from "../../../constants/queryKeys";
import { getPRById } from "../../../purchase/services";
import { useQuery } from "@tanstack/react-query";

export const useFetchPurchaseReturnOrderById = (
  orderId,
  returnId,
  manageOrderType
) => {
  const returnById = useQuery(
    [queryKeys.queryFetchPurchaseReturnById, returnId],
    () => getPRById(orderId, returnId),
    {
      enabled: manageOrderType && Boolean(orderId) && Boolean(returnId),
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return returnById;
};
