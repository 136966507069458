import React, { useRef, useEffect } from "react";
import { Box, Button, Grid } from "@mui/material";
import Inventory from "../../assets/Inventory_erp.webp";
import Register from "../../assets/Register_erp.webp";
import Vendor from "../../assets/Vendor_erp.webp";
import SaleOrder from "../../assets/SaleOrder_erp.webp";
import Performance from "../../assets/Performance_erp.webp";
import Finance from "../../assets/Finance_erp.webp";
import ProductFeatureItem from "./ProductFeatureItem";
import { SectionHeader } from "./SolutionsSection";
import { home_labels } from "../../constants";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import appRoutes from "../../constants/appRoutes";

const product = [
  {
    label: home_labels.Inventory,
    subtext: home_labels.InventorySubTextonly,
    image: Inventory,
    padding: "13.75px",
    id: "Inventory"
  },
  {
    label: home_labels.Register,
    subtext: home_labels.RegisterSubTextonly,
    image: Register,
    padding: "13.75px",
    id: "Register"
  },
  {
    label: home_labels.Vendor,
    subtext: home_labels.VendorSubTextonly,
    image: Vendor,
    padding: "13.75px",
    id: "Vendor"
  },
  {
    label: home_labels.SaleOrder,
    subtext: home_labels.SaleOrderSubTextonly,
    image: SaleOrder,
    padding: "13.75px",
    id: "SaleOrder"
  },
  {
    label: home_labels.Performance,
    subtext: home_labels.PerformanceSubTextonly,
    image: Performance,
    padding: "195px",
    id: "Performance"
  },
  {
    label: home_labels.Finance,
    subtext: home_labels.FinanceSubTextonly,
    image: Finance,
    padding: "270px",
    id: "Finance"
  }
];

const ERPSection = () => {
  const swiperRef = useRef(null);
  const containerRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const targetElement = containerRef.current; // Store current value of containerRef

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && entry.intersectionRatio >= 0.4) {
            // When 40% or more of the swiper is visible, start autoplay
            if (swiperRef.current) swiperRef.current.autoplay.start();
          } else {
            // When less than 40% is visible, stop autoplay
            if (swiperRef.current) swiperRef.current.autoplay.stop();
          }
        });
      },
      {
        threshold: 0.4 // Trigger when 40% of the Swiper is in view
      }
    );

    if (targetElement) {
      observer.observe(targetElement);
    }

    return () => {
      if (targetElement) {
        observer.unobserve(targetElement); // Clean up the observer with the stored targetElement
      }
    };
  }, []);

  return (
    <Box
      id="erp-section"
      ref={containerRef}
      display="flex"
      flexDirection="column"
      textAlign="center"
      sx={{
        background: "#F7F8FA",
        mt: {
          xs: "20px",
          md: "40px"
        },
        padding: {
          xs: "0 20px",
          md: "5px 65px"
        },
        gap: { xs: 0, md: 2 }
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        sx={{
          position: "relative"
        }}
      >
        <Box>
          <SectionHeader
            title={home_labels.WarexERP}
            props={{ fontWeight: { xs: 400, md: 700 } }}
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            right: 0,
            paddingTop: { xs: "10px", md: 0 }
          }}
        >
          <Button
            variant="contained"
            sx={{
              width: { xs: "80px", md: "150px" },
              padding: { xs: "0px" },
              height: { xs: "30px", md: "60px" },
              fontSize: { xs: "10px", md: "17px" },
              lineHeight: { xs: "12px", md: "20px" },
              borderRadius: "4px",
              border: "1px 0px 0px 0px",
              opacity: "0.99px"
            }}
            onClick={() => navigate(appRoutes.erp)}
          >
            {"Know More"}
          </Button>
        </Box>
      </Box>
      <Box>
        <Swiper
          slidesPerView={isMobile ? 1 : 3}
          spaceBetween={30}
          speed={1000}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true
          }}
          loop={true}
          pagination={{
            clickable: true,
            
          }}
          modules={[Autoplay, Pagination]}
          className="mySwiper-2"
          onSwiper={(swiper) => (swiperRef.current = swiper)}
        >
          {product.map((item, index) => (
            <SwiperSlide key={item.label}>
              <Grid item xs={12} md={4} style={{ marginRight: "16px" }}>
                <ProductFeatureItem {...item} />
              </Grid>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  );
};

export default ERPSection;
