import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";
import { RemoveCircleOutline } from "@mui/icons-material";
import { Link } from "react-router-dom";

export const ValueLabelText = ({ value, label }) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      gap={1}
    >
      <Typography fontSize={14} lineHeight={"16.94px"} fontWeight={400}>
        {value}
      </Typography>
      <Typography
        fontSize={8}
        lineHeight={"9.68px"}
        fontWeight={400}
        color={"text.disabled"}
      >
        {label}
      </Typography>
    </Box>
  );
};

export const MobilePOTable = (row) => {
  const { remove } = row;
  const onRemoveClick = (index) => {
    remove(index);
  };
  return (
    <Grid container spacing={"20px"}>
      <Grid item xs={12} container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={8}>
          {row?.skuCode ? (
            <Box display={"flex"} gap={1} alignItems={"center"}>
              {row?.image?.thumbUrl && (
                <img
                  src={row?.image?.thumbUrl}
                  alt={row?.brandName ?? "--"}
                  style={{
                    objectFit: "contain",
                    maxWidth: "50px",
                    maxHeight: "50px"
                  }}
                />
              )}
              <Typography
                fontSize={12}
                fontWeight={600}
                color={"primary"}
                component={Link}
              >
                {row?.skuCode}
              </Typography>
            </Box>
          ) : (
            row.item
          )}
        </Grid>
        <Grid
          item
          xs={4}
          display={"flex"}
          justifyContent={"right"}
          alignItems={"flex-start"}
        >
          {!row?.isEditable && Boolean(row?.orderStatus) ? (
            <ValueLabelText label={"Qty"} value={row.quantity} />
          ) : (
            (row?.orderStatus === "" || row?.orderStatus === "Draft") && (
              <IconButton onClick={() => onRemoveClick(row?.index)}>
                <RemoveCircleOutline color="error" />
              </IconButton>
            )
          )}
        </Grid>
        <Grid
          item
          xs={4}
          display={"flex"}
          justifyContent={"left"}
          alignItems={"flex-start"}
        >
          <Typography
            fontSize={12}
            fontWeight={400}
            lineHeight={"14.52px"}
            color={"text.disabled"}
            pl={1}
          >
            {row?.unit?.name}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {(row?.orderStatus === "Draft" && !row.isEditable) ||
          (row?.orderStatus !== "" && row?.orderStatus !== "Draft") ? (
            <ValueLabelText label={"Rate"} value={row.rate} />
          ) : (
            row.rate
          )}
        </Grid>
        <Grid
          item
          xs={4}
          display={"flex"}
          justifyContent={"right"}
          alignItems={"flex-start"}
        >
          {!row?.isEditable && Boolean(row?.orderStatus) ? (
            <ValueLabelText label={"Subtotal"} value={row.amount} />
          ) : (
            row.quantity
          )}
        </Grid>
        {!row?.isEditable && Boolean(row?.orderStatus) && (
          <>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid
              item
              xs={4}
              display={"flex"}
              justifyContent={"left"}
              alignItems={"flex-start"}
            >
              <ValueLabelText
                label={row?.gstType ? "CGST" : "IGST"}
                value={row?.gstType ? row.cgst : row.igst}
              />
            </Grid>
            <Grid
              item
              xs={4}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {row?.gstType && (
                <ValueLabelText label={"SGST"} value={row.sgst} />
              )}
            </Grid>
            <Grid
              item
              xs={4}
              display={"flex"}
              justifyContent={"right"}
              alignItems={"flex-start"}
            >
              <ValueLabelText label={"Total"} value={row.total} />
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};
