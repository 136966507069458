import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import { useForm } from "react-hook-form";
import { currencyOnlyFormatter } from "../../../shared/utils";
import moment from "moment";
import CustomTable from "../../../shared/customTable";
import { AccountBalanceWallet } from "@mui/icons-material";
import { useCustomDialog } from "../../../shared/customDialog";
import RecordTransaction from "./RecordTransaction";
import { useCallback, useEffect, useMemo } from "react";
import { enqueueSnackbar } from "notistack";
import { useCreateMRRInvoicePayment } from "../../../query-hooks/ClientOrderInvoicePayments/useCreateMRRInvoicePayment";
import { yupResolver } from "@hookform/resolvers/yup";
import { paymentSchema } from "./ARTable";
import DoughnutChart from "./DoughnutChart";
import { MobilePaymentsTable } from "./MobilePaymentsTable";

const getColumns = () => {
  return [
    {
      Header: "Invoice Date",
      accessor: "invoiceDate"
    },
    {
      Header: "Invoice No.",
      accessor: "invoiceNo",
      Cell: ({ value }) => {
        return (
          <Typography
            fontSize={14}
            color={"primary"}
            sx={{ textDecoration: "underline", cursor: "pointer" }}
          >
            {value}
          </Typography>
        );
      }
    },
    {
      Header: "MR Date",
      accessor: "mrDate"
    },
    {
      Header: "Due Date",
      accessor: "dueDate"
    },
    {
      Header: "Inv Amount",
      accessor: "invAmount"
    },
    {
      Header: "Vendor Name",
      accessor: "vendorName",
      width: 200,
      Cell: ({ row }) => {
        return (
          <Box display={"flex"} flexDirection={"column"} textAlign={"center"}>
            <Typography>{row.original.name}</Typography>
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "14px",
                lineHeight: "20px",
                color: "text.title"
              }}
            >
              {row.original.mobile}
            </Typography>
          </Box>
        );
      }
    },
    {
      Header: "Amount Paid",
      accessor: "amountPaid"
    },
    {
      Header: "Due",
      accessor: "due"
    },
    {
      Header: "Over Due",
      accessor: "overDue"
    },
    {
      Header: "Record",
      accessor: "record"
    }
  ];
};

const getRows = (rows = [], onAction) => {
  return rows.map((item, index) => ({
    invId: item?._id,
    invType: "Paid",
    invoiceDate: moment(item?.docDate).format("DD-MM-YYYY"),
    invoiceNo: item?.code ?? item?.customCode,
    status: item?.status?.name,
    mrDate: moment(item?.relatedDocument?.docDate).format("DD-MM-YYYY"),
    dueDate: "",
    invAmount: currencyOnlyFormatter(parseFloat(item?.totalAmount).toFixed(2)),
    name: item?.vendor?.companyName,
    mobile: item?.vendor?.contact?.mobile,
    amountPaid: item?.totalPaidAmount
      ? currencyOnlyFormatter(parseFloat(item?.totalPaidAmount).toFixed(2))
      : "",
    due: "",
    isOverDue: false,
    record: (
      <IconButton
        onClick={() => onAction(item)}
        disabled={Boolean(item?.totalPaidAmount)}
      >
        <AccountBalanceWallet
          sx={{
            color: Boolean(item?.totalPaidAmount) ? "text.disabled" : "#F9A90E"
          }}
        />
      </IconButton>
    )
  }));
};

const APTable = ({
  data,
  subTotal,
  isFetchingSubtotals,
  isFetching,
  isFetched,
  ref,
  hasNextPage,
  refetch,
  isChecked,
  setParams,
  onViewClick
}) => {
  const chart_data = isFetchingSubtotals
    ? []
    : [
        {
          name: "Paid",
          value: +parseFloat(subTotal?.totalPaidAmount).toFixed(2),
          color: "#62B2FD"
        },
        {
          name: "Due",
          value:
            Boolean(subTotal?.totalAmount) && Boolean(subTotal?.totalPaidAmount)
              ? +parseFloat(
                  +subTotal?.totalAmount - +subTotal?.totalPaidAmount
                ).toFixed(2)
              : 0,
          color: "#9BDFC4"
        },
        { name: "Over Due", value: 0, color: "#F99BAB" }
      ];

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(paymentSchema)
  });
  const { showDialog, hideDialog } = useCustomDialog();
  const { mutate, isError, error, isLoading } = useCreateMRRInvoicePayment();

  const onSave = useCallback(
    (data, invId) => {
      mutate(
        {
          invoiceId: invId,
          data: {
            paymentDate: moment(data["date"]).format("YYYY-MM-DD"),
            utrNo: data["utr"],
            amount: +data["amount"]
          }
        },
        {
          onSuccess: (res) => {
            hideDialog();
            refetch();
            enqueueSnackbar("Payment Record Created!", {
              variant: "success"
            });
          }
        }
      );
    },
    [mutate, hideDialog, refetch]
  );

  const onAction = useCallback(
    (row) => {
      showDialog({
        component: (
          <RecordTransaction
            text={"Record the amount paid to the Vendor"}
            row={row}
            hideDialog={hideDialog}
            control={control}
            submit={() => handleSubmit(onSave)(row?._id)}
            isLoading={isLoading}
          />
        ),
        closeIcon: false
      });
    },
    [showDialog, hideDialog, control, isLoading, handleSubmit, onSave]
  );

  const payableTable = useMemo(
    () => (
      <CustomTable
        columns={getColumns()}
        data={
          isFetched
            ? data?.pages?.flatMap((page) =>
                getRows(page?.invoices, onAction)
              ) ?? []
            : []
        }
        isLoading={!isFetched}
        mobileComponent={MobilePaymentsTable}
      />
    ),
    [data?.pages, isFetched, onAction]
  );

  useEffect(
    () => {
      reset();
      setParams(null);
    }, // eslint-disable-next-line
    [isChecked, reset]
  );

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(error?.response?.data?.message, {
        variant: "error"
      });
    }
  }, [error?.response?.data?.message, isError]);

  return (
    <Box display={"flex"} flexDirection={"column"} gap={2}>
      <Grid item xs={12} sx={{ padding: { xs: "0 15px", md: "0 0 0 32px" } }}>
        <DoughnutChart
          data={chart_data}
          Title={"Accounts Payables"}
          isLoading={isFetching}
          onApply={onViewClick}
          setParams={setParams}
        />
      </Grid>
      <Box sx={{ padding: { xs: "0 15px", md: 0 } }}>{payableTable}</Box>
      {hasNextPage && (
        <Grid
          item
          xs={12}
          ref={ref}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress />
        </Grid>
      )}
    </Box>
  );
};

export default APTable;
