import { queryKeys } from "../../constants/queryKeys";
import { getGpFormData } from "../../inventory/services";
import { useQuery } from "@tanstack/react-query";

export const useFetchGPFormData = (orderId, allowAccess) => {
  const GpFormData = useQuery(
    [queryKeys.queryFetchGpFormData, orderId, allowAccess],
    () => getGpFormData(orderId),
    {
      enabled: Boolean(orderId) && allowAccess,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return GpFormData;
};
