import React, { useEffect, useState } from "react";
import { useCreateSku } from "../../../query-hooks/Sku/useCreateSku";
import { enqueueSnackbar } from "notistack";
import { sku_filterKeys } from "../../../constants/formKeys";
import SkuDetailsForm from "./SkuDetailsForm";
import { inventory_table_labels } from "../../../constants";
import { useFetchSkuFormData } from "../../../query-hooks/Sku/useFetchSkuFormData";

const AddNewSKU = ({ hideDrawer, defaultValues = null, refetch }) => {
  const [beginningStock, setBeginningStock] = useState([]);
  const { data: formData, isFetching: isFormFetching } = useFetchSkuFormData();
  const { mutate, isError, error, isLoading } = useCreateSku();

  const handleAddSku = (data) => {
    const skuData = new FormData();
    skuData.append("skuCode", data[sku_filterKeys.sku]);
    skuData.append("productName", data[sku_filterKeys.productName]);
    data[sku_filterKeys.brandName] &&
      skuData.append("brandName", data[sku_filterKeys.brandName]);
    data[sku_filterKeys.categoryName] &&
      skuData.append("categoryName", data[sku_filterKeys.categoryName]);
    skuData.append("unit", data[sku_filterKeys.units]);
    skuData.append("gstRegime", data[sku_filterKeys.gst]);
    data[sku_filterKeys.hsn] && skuData.append("hsn", data[sku_filterKeys.hsn]);
    data[sku_filterKeys.upc] && skuData.append("upc", data[sku_filterKeys.upc]);
    skuData.append("rate", data[sku_filterKeys.listPrice]);
    skuData.append("minStockLevel", data[sku_filterKeys.minimumStockLevel]);
    beginningStock?.length &&
      beginningStock?.forEach((item, index) => {
        skuData.append(`currStockLevel[${index}][warehouse]`, item?._id);
        skuData.append(`currStockLevel[${index}][quantity]`, item?.quantity);
      });
    data[sku_filterKeys.imageUpload] &&
      skuData.append("image", data[sku_filterKeys.imageUpload]);
    data[sku_filterKeys.attribute1] &&
      skuData.append("customAttributes[0][label]", sku_filterKeys.attribute1);
    data[sku_filterKeys.attribute1] &&
      skuData.append(
        "customAttributes[0][value]",
        data[sku_filterKeys.attribute1]
      );
    data[sku_filterKeys.attribute2] &&
      skuData.append("customAttributes[1][label]", sku_filterKeys.attribute2);
    data[sku_filterKeys.attribute2] &&
      skuData.append(
        "customAttributes[1][value]",
        data[sku_filterKeys.attribute2]
      );
    data[sku_filterKeys.attribute3] &&
      skuData.append("customAttributes[2][label]", sku_filterKeys.attribute3);
    data[sku_filterKeys.attribute3] &&
      skuData.append(
        "customAttributes[2][value]",
        data[sku_filterKeys.attribute3]
      );
    mutate(
      {
        data: skuData
      },
      {
        onSuccess: () => {
          hideDrawer();
          enqueueSnackbar("Sku created!", { variant: "success" });
          refetch();
        }
      }
    );
  };

  useEffect(() => {
    if (isError)
      enqueueSnackbar(error?.response?.data?.message, { variant: "error" });
  }, [error?.response?.data?.message, isError]);

  return (
    <SkuDetailsForm
      title={inventory_table_labels.addNewSku}
      isLoading={isLoading}
      onSubmit={handleAddSku}
      defaultValues={defaultValues}
      refetch={refetch}
      beginningStock={beginningStock}
      setBeginningStock={setBeginningStock}
      formData={formData}
      isFormFetching={isFormFetching}
    />
  );
};

export default AddNewSKU;
