import { client } from "../../shared/api/client";

export const getMrrInCreateFormData = async (orderId) => {
  return await client
    .get(`/clients/orders/${orderId}/mrrs/invoices/form-data`)
    .then((res) => res.data.data);
};

export const getDCsInvoicesFormData = async (orderId) => {
  return await client
    .get(
      `/clients/orders/${orderId}/delivery-challans/invoices/payments/form-data`
    )
    .then((res) => res.data.data);
};

export const getDCInCreateFormData = async (orderId) => {
  return await client
    .get(`/clients/orders/${orderId}/delivery-challans/invoices/form-data`)
    .then((res) => res.data.data);
};

export const getDCInUpdateFormData = async (dcInvoiceId) => {
  return await client
    .get(`/clients/orders/delivery-challans/invoices/${dcInvoiceId}/form-data`)
    .then((res) => res.data.data);
};

export const getDCInvById = async (dcInvoiceId) => {
  return await client
    .get(`/clients/orders/invoices/${dcInvoiceId}`)
    .then((res) => res.data.data);
};

export const createMrrInvoice = async (data, mrrId) => {
  return await client.post(`/clients/orders/mrrs/${mrrId}/invoices`, data);
};

export const createDCInvoice = async (data, dcId) => {
  return await client.post(
    `/clients/orders/delivery-challans/${dcId}/invoices`,
    data
  );
};

export const uploadDCInvoice = async (data, dcId) => {
  return await client.post(
    `/clients/orders/delivery-challans/${dcId}/invoices/upload`,
    data
  );
};

export const saveDCInvoice = async (data, dcId) => {
  return await client.post(
    `/clients/orders/delivery-challans/${dcId}/invoices/save`,
    data
  );
};

export const updateDCInvoiceDraft = async (data, invoiceId) => {
  return await client.put(
    `/clients/orders/delivery-challans/invoices/${invoiceId}/save`,
    data
  );
};

export const submitDCInvoice = async (data, invoiceId) => {
  return await client.put(
    `/clients/orders/delivery-challans/invoices/${invoiceId}/submit`,
    data
  );
};

export const deleteDCInvoiceDraft = async (invoiceId) => {
  return await client.delete(
    `/clients/orders/delivery-challans/invoices/${invoiceId}`
  );
};

export const createMrrInvoicePayment = async (data, invoiceId) => {
  return await client.post(
    `/clients/orders/mrrs/invoices/${invoiceId}/payments`,
    data
  );
};

export const createDCInvoicePayment = async (data, invoiceId) => {
  return await client.post(
    `/clients/orders/delivery-challans/invoices/${invoiceId}/payments`,
    data
  );
};

export const approveDCInvoice = async (data, invoiceId) => {
  return await client.put(
    `/clients/orders/delivery-challans/invoices/${invoiceId}/payments/approve`,
    data
  );
};

export const getInvoiceFilters = async (orderType) => {
  return await client
    .get(`/clients/finance/orders/filters`, {
      params: { "filters[orderType]": orderType }
    })
    .then((res) => res.data.data);
};

export const getInvoices = async (params, orderType, page = 1) => {
  return await client
    .get(`/clients/orders/invoices`, {
      params: { ...params, "filters[docType] ": orderType, page: page }
    })
    .then((res) => res.data.data);
};

export const getPaymentSubtotal = async (params, orderType) => {
  return await client
    .get(`/clients/orders/invoices/sub-totals`, {
      params: { ...params, "filters[docType]": orderType }
    })
    .then((res) => res.data.data);
};

export const getInvoiceSubtotal = async (params, orderType) => {
  return await client
    .get(`/clients/finance/orders/sub-totals`, {
      params: { ...params, "filters[orderType]": orderType }
    })
    .then((res) => res.data.data);
};

export const getFinanceOrders = async (params, orderType, page = 1) => {
  return await client
    .get(`/clients/finance/orders`, {
      params: { ...params, "filters[orderType]": orderType, page: page }
    })
    .then((res) => res.data.data);
};

export const getDebitNoteCreateFormData = async (orderId) => {
  return await client
    .get(`/clients/orders/${orderId}/delivery-challans/debit-notes/form-data`)
    .then((res) => res.data.data);
};

export const getDebitNoteUpdateFormData = async (dnId) => {
  return await client
    .get(`/clients/orders/delivery-challans/debit-notes/${dnId}/form-data`)
    .then((res) => res.data.data);
};

export const getDebitNoteById = async (dnId) => {
  return await client
    .get(`/clients/orders/delivery-challans/debit-notes/${dnId}`)
    .then((res) => res.data.data);
};

export const createDebitNote = async (data, dcId) => {
  return await client.post(
    `/clients/orders/delivery-challans/${dcId}/debit-notes`,
    data
  );
};

export const uploadDebitNote = async (data, dcId) => {
  return await client.post(
    `/clients/orders/delivery-challans/${dcId}/debit-notes/upload`,
    data
  );
};

export const saveDebitNote = async (data, dcId) => {
  return await client.post(
    `/clients/orders/delivery-challans/${dcId}/debit-notes/save`,
    data
  );
};

export const updateDebitNoteDraft = async (data, dnId) => {
  return await client.put(
    `/clients/orders/delivery-challans/debit-notes/${dnId}/save`,
    data
  );
};

export const submitDebitNote = async (data, dnId) => {
  return await client.put(
    `/clients/orders/delivery-challans/debit-notes/${dnId}/submit`,
    data
  );
};

export const deleteDebitNoteDraft = async (dnId) => {
  return await client.delete(
    `/clients/orders/delivery-challans/debit-notes/${dnId}`
  );
};

export const getCreditNoteCreateFormData = async (orderId) => {
  return await client
    .get(`/clients/orders/${orderId}/mrrs/credit-notes/form-data`)
    .then((res) => res.data.data);
};

export const getCreditNoteUpdateFormData = async (cnId) => {
  return await client
    .get(`/clients/orders/mrrs/credit-notes/${cnId}/form-data`)
    .then((res) => res.data.data);
};

export const getCreditNoteById = async (cnId) => {
  return await client
    .get(`/clients/orders/mrrs/credit-notes/${cnId}`)
    .then((res) => res.data.data);
};

export const createCreditNote = async (data, mrrId) => {
  return await client.post(`/clients/orders/mrrs/${mrrId}/credit-notes`, data);
};

export const uploadCreditNote = async (data, mrrId) => {
  return await client.post(
    `/clients/orders/mrrs/${mrrId}/credit-notes/upload`,
    data
  );
};

export const saveCreditNote = async (data, mrrId) => {
  return await client.post(
    `/clients/orders/mrrs/${mrrId}/credit-notes/save`,
    data
  );
};

export const updateCreditNoteDraft = async (data, cnId) => {
  return await client.put(
    `/clients/orders/mrrs/credit-notes/${cnId}/save`,
    data
  );
};

export const submitCreditNote = async (data, cnId) => {
  return await client.put(
    `/clients/orders/mrrs/credit-notes/${cnId}/submit`,
    data
  );
};

export const deleteCreditNoteDraft = async (cnId) => {
  return await client.delete(`/clients/orders/mrrs/credit-notes/${cnId}`);
};
