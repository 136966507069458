import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography
} from "@mui/material";

import { Close } from "@mui/icons-material";
// import { Link } from "react-router-dom"; 
import { OrderStatusKeys } from "../../../constants/formKeys";
import OrderTrackStepper from "./OrderTrackStepper";
import moment from "moment";
import { order_table_labels } from "../../../constants";
import styled from "@emotion/styled";
import { useGetOrderDetails } from "../../../query-hooks/Orders/useGetOrderDetails";

const StyledBox = styled(Box)(({ theme }) => ({
  border: "0.4px solid " + theme.palette.success.dark,
  borderRadius: 8,
  backgroundColor: theme.palette.success.background,
  height: 64,
  width: "100%",
  fontWeight: 600,
  fontSize: 16,
  lineHeight: "19px"
}));

export const steps = [
  {
    label: [OrderStatusKeys.VehicleAssigned],
    isCompleted: true,
    description: `For each ad campaign that you create, you can control how much
                  you're willing to spend on clicks and conversions, which networks
                  and geographical locations you want your ads to show on, and more.`
  },
  {
    label: [OrderStatusKeys.InTransit],
    isCompleted: true,
    description:
      "An ad group contains one or more ads which target a shared set of keywords."
  },
  {
    label: [OrderStatusKeys.ReceivedAtFacility],
    isCompleted: false,
    description: `Try out different ad text to see what brings in the most customers,
                  and learn how to enhance your ads using features like ad extensions.
                  If you run into any problems with your ads, find out how to tell if
                  they're running and how to resolve approval issues.`
  },
  {
    label: [OrderStatusKeys.Stored],
    isCompleted: false,
    description: `Try out different ad text to see what brings in the most customers,
                  and learn how to enhance your ads using features like ad extensions.
                  If you run into any problems with your ads, find out how to tell if
                  they're running and how to resolve approval issues.`
  }
];

const OrderDetailText = ({ header, name, trx, address }) => {
  return (
    <>
      <Grid item xs={4} lg={3}>
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 600,
            lineHeight: "normal",
            whiteSpace: "nowrap"
          }}
        >
          {`${header} :`}
        </Typography>
      </Grid>
      <Grid item xs={8} lg={9}>
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 700,
            lineHeight: "normal"
          }}
        >
          {name}
        </Typography>
        <Typography
          pt={1}
          sx={{
            fontSize: 13,
            fontWeight: 500,
            lineHeight: "18px"
          }}
        >
          {trx}
        </Typography>
        <Typography
          pt={1}
          color={"text.secondary"}
          sx={{
            fontSize: 13,
            fontWeight: 400,
            lineHeight: "18px"
          }}
        >
          {address}
        </Typography>
      </Grid>
    </>
  );
};

const TrackOrder = ({ row, hideDrawer }) => {
  const { data: orderData } = useGetOrderDetails(row.orderId);

  const dynamicSteps = orderData?.customerStatusHistories?.map((history) => {
    return {
      label: [history?.status?.displayName],
      isCompleted: !!history?.createdAt, // true if createdAt exists
      description:
        history?.status?.name === "VehicleAssigned" ? (
          <>
            Vehicle no -{" "}
            <strong>
              {orderData?.order?.transportOrder?.vehicle?.regNo || "--"}
            </strong>
            {"\n"}
            Driver Number:{" "}
            <strong>
              {orderData?.order?.transportOrder?.driver
                ? `+91 - ${orderData?.order?.transportOrder?.driver?.mobile} (${orderData?.order?.transportOrder?.driver?.name})`
                : "--"}
            </strong>
          </>
        ) : (
          ""
        ),
      createdAt: history?.createdAt || null // null if createdAt doesn't exist
    };
  });

  return (
    <Box display={"flex"} flexDirection={"column"}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={5}
        sx={{
          padding: {
            xs: 2,
            md: "60px 40px 30px 40px"
          }
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography fontSize={24} fontWeight={500}>
            {order_table_labels.trackOrder}
          </Typography>
          <IconButton onClick={hideDrawer}>
            <Close />
          </IconButton>
        </Box>
        <Grid container spacing={"30px"}>
          <Grid
            item
            xs={8}
            lg={9}
            display={"flex"}
            flexDirection={"column"}
            gap={"6px"}
          >
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 600,
                lineHeight: "16px"
              }}
            >
              {`Order ID : ${
                orderData?.order?.warehouseOrder?.code ||
                orderData?.order?.transportOrder?.code
              }`}
            </Typography>
            <Typography
              color={"text.secondary"}
              sx={{
                fontSize: 14,
                fontWeight: 400,
                lineHeight: "20px"
              }}
            >
              {`Order date : ${moment(
                orderData?.order?.recentOrderItem?.createdAt
              ).format("DD MMMM YYYY")}`}
            </Typography>
          </Grid>
          <Grid item xs={4} lg={3}>
            <Button
              variant="link"
              // to={`/explore/listings/${row.orderId}`}
              // component={Link}
              sx={{
                fontWeight: 600,
                fontSize: 13,
                textTransform: "capitalize"
              }}
            >
              {order_table_labels.viewDetails}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <StyledBox>
              <Grid
                container
                justifyContent={"center"}
                alignContent={"center"}
                height={"100%"}
              >
                {`Pickup on : ${moment(
                  orderData?.order?.recentOrderItem?.inboundDate
                ).format("Do MMMM YYYY")}`}
              </Grid>
            </StyledBox>
          </Grid>
          <Grid item xs={12}>
            <OrderTrackStepper detailList={dynamicSteps} />
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ borderColor: "#B7BEC7", p: 1 }} />
          </Grid>
          <OrderDetailText
            header={"Shipping to"}
            name={
              Boolean(orderData?.order?.warehouseOrder)
                ? `${orderData?.order?.warehouseOrder?.partner?.name} & ${orderData?.order?.warehouseOrder?.partner?.partnerId?.code}`
                : Boolean(orderData?.order?.transportOrder)
                ? `${orderData?.order?.searchForm?.destination?.address}`
                : "--"
            }
            trx={"Transaction Order ID : --"}
            address={
              Boolean(orderData?.order?.warehouseOrder)
                ? `${orderData?.order?.warehouseOrder?.partner?.partnerId?.address?.area}, ${orderData?.order?.warehouseOrder?.partner?.partnerId?.address?.pincode}`
                : Boolean(orderData?.order?.transportOrder)
                ? `${orderData?.order?.searchForm?.destination?.address}`
                : "--"
            }
          />
          <OrderDetailText
            header={"Shipping via"}
            name={
              Boolean(orderData?.order?.transportOrder)
                ? `${orderData?.order?.transportOrder?.partner?.name}`
                : "--"
            }
            trx={"Transaction Order ID : --"}
          />
        </Grid>
      </Box>
    </Box>
  );
};

export default TrackOrder;
