import { Box, Divider, Typography, Grid } from "@mui/material";
import React from "react";
import SkuPerformanceTable from "../components/SkuPerformanceTable";
import BarChartCard from "../components/BarChartCard";
import OrderTypeCards from "../components/OrderTypeCards";

const Dashboard = () => {
  return (
    <>
      <Box
        sx={{
          background: { xs: "#EEF1F4", md: "unset" },
          display: { xs: "flex", md: "block" },
          minHeight: { xs: "84px", md: "unset" },
          mb: 1,
          border: { xs: "0px 0px 1px 0px solid #B7BEC7" },
          alignItems: { xs: "center", md: "unset" }
        }}
      >
        <Typography
          component={"h4"}
          sx={{
            fontWeight: { xs: 700, md: 500 },
            fontSize: { xs: "16px", md: "20px" },
            lineHeight: { xs: "19.36px", md: "24.3px" },
            color: { xs: "#6097FF", md: "#0F2744" },
            letterSpacing: "0.002em",
            padding: { xs: "20px", md: 0 }
          }}
        >
          {"Dashboard"}
        </Typography>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={1}
        sx={{ padding: { xs: "0 20px", md: "0 40px 0 0" }, mb: 9 }}
      >
        <Divider
          sx={{
            border: "0.7px solid #B7BEC7",
            display: { xs: "none", md: "flex" }
          }}
        />
        <OrderTypeCards />
        <Divider
          sx={{
            border: "0.7px solid #B7BEC7",
            mt: { xs: 1, md: 6 },
            mb: { xs: 4, md: 4 }
          }}
        />
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={5}>
            <SkuPerformanceTable />
          </Grid>
          <Grid item xs={12} sm={12} md={7}>
            <BarChartCard />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Dashboard;
