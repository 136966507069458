import { useLocation, useNavigate } from "react-router-dom";

import appRoutes from "../../constants/appRoutes";
import { useState } from "react";

export const useTabState = () => {
  const [activeTab, setActiveTab] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  const handleTabChange = (index) => {
    if (location.pathname !== appRoutes.explore.listings) {
      navigate(appRoutes.explore.listings);
    }
    setActiveTab(index);
  };

  return { activeTab, handleTabChange };
};
