import { queryKeys } from "../../../constants/queryKeys";
import { getCustomerById } from "../../../sales/services";
import { useQuery } from "@tanstack/react-query";

export const useFetchCustomerById = (customerId) => {
  const customerById = useQuery(
    [queryKeys.queryFetchCustomerById, customerId],
    () => getCustomerById(customerId),
    {
      enabled: Boolean(customerId),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return customerById;
};
