import { queryKeys } from "../../../constants/queryKeys";
import { getStores } from "../../../sales/services";
import { useQuery } from "@tanstack/react-query";

export const useFetchShipToAddress = (customerId, billingId) => {
  const stores = useQuery(
    [queryKeys.queryFetchStores, customerId, billingId],
    () => getStores(customerId, billingId),
    {
      enabled: Boolean(customerId) && Boolean(billingId),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return stores;
};
