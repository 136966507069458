import { queryKeys } from "../../../constants/queryKeys";
import { getSalesOrderById } from "../../../sales/services";
import { useQuery } from "@tanstack/react-query";

export const useFetchSalesOrderById = (
  salesOrderId,
  returnId,
  manageOrderType
) => {
  const salesOrderById = useQuery(
    [queryKeys.queryFetchSalesOrderById, salesOrderId],
    () => getSalesOrderById(salesOrderId),
    {
      enabled: manageOrderType && Boolean(salesOrderId) && !Boolean(returnId),
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return salesOrderById;
};
