import { getRfq } from "../../customer/services";
import { queryKeys } from "../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useGetRfqHook = (inquiryId, rfqId, flag = false) => {
  const queryGetRfq = useQuery(
    [queryKeys.queryGetRfq, inquiryId, rfqId, flag],
    () => getRfq(inquiryId, rfqId),
    {
      enabled: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false
    }
  );

  return queryGetRfq;
};
