import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import { CancelOutlined, KeyboardArrowRight } from "@mui/icons-material";
import { labels, order_table_labels } from "../../../../constants";

import AddIcon from "@mui/icons-material/Add";
import DropDown from "../../../../shared/formElements/DropDown";
import { Fragment } from "react";
import { PackingNotesFormKeys } from "../../../../constants/formKeys";
import QRCodeBlurPlaceholder from "../../../../assets/images/QRCodeBlurPlaceholder.svg";
import QRMobilePlaceholder from "../../../../assets/images/MobileQrPlaceholder.svg";
import TextInput from "../../../../shared/formElements/TextInput";
import { isMobileDevice } from "../../../../App";
import moment from "moment";

const HeaderFieldLabelAndValue = ({ label, value }) => {
  return (
    <>
      <Typography
        color={"text.secondary"}
        sx={{
          display: { xs: "none", md: "block" },
          fontSize: 14,
          fontWeight: 400,
          lineHeight: "20px"
        }}
      >
        {label}
      </Typography>
      <Typography
        color={{ xs: "text.secondary", md: "text.primary" }}
        sx={{
          display: { xs: "none", md: "block" },
          pt: 1,
          fontSize: 14,
          fontWeight: 600,
          lineHeight: "16.94px"
        }}
      >
        {value}
      </Typography>
    </>
  );
};

const MobileExpandedCard = ({ name, material, size }) => {
  return (
    <Grid item xs={12} container spacing={9}>
      <Grid item xs={6}>
        <Box display={"flex"} flexDirection={"column"}>
          <Typography
            fontSize={13}
            fontWeight={500}
            lineHeight={"18px"}
            whiteSpace={"nowrap"}
          >
            {name}
          </Typography>
          <Box display={"flex"} gap={"4px"} flexWrap={"wrap"}>
            <Typography
              color={"text.disabled"}
              fontSize={13}
              lineHeight={"18px"}
            >
              Material :
            </Typography>
            <Typography fontSize={13} lineHeight={"18px"} fontWeight={500}>
              {material}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box display={"flex"} flexDirection={"column"}>
          <Typography fontSize={13} fontWeight={500} lineHeight={"18px"}>
            {size}
          </Typography>
          <Typography color={"text.disabled"} fontSize={13} lineHeight={"18px"}>
            Size / Quantity
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export const MobileCollapseCard = ({ name, material, size }) => {
  return (
    <Grid item xs={12} container spacing={9}>
      <Grid item xs={6}>
        <Box display={"flex"} flexDirection={"column"} gap={2}>
          <Typography
            color={"text.secondary"}
            fontSize={13}
            fontWeight={500}
            lineHeight={"18px"}
          >
            ID 12345
          </Typography>
          <Box display={"flex"} flexDirection={"column"} gap={"1px"}>
            <Typography
              fontWeight={500}
              fontSize={13}
              lineHeight={"18px"}
              whiteSpace={"nowrap"}
            >
              {name}
            </Typography>
            <Typography
              fontSize={13}
              lineHeight={"18px"}
              fontWeight={500}
              color={"text.disabled"}
            >
              {`Material : ${material}`}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box display={"flex"} flexDirection={"column"} gap={2}>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography
              color={"text.secondary"}
              fontSize={13}
              fontWeight={500}
              lineHeight={"18px"}
              whiteSpace={"nowrap"}
            >
              13 March 2023
            </Typography>
            <KeyboardArrowRight color="primary" />
          </Box>
          <Box>
            <Typography fontSize={13} fontWeight={500} lineHeight={"18px"}>
              {size}
            </Typography>
            <Typography
              color={"text.disabled"}
              fontSize={13}
              lineHeight={"18px"}
            >
              Size
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export const AccordionHeaderFieldsAndLabels = ({
  isOpen,
  isEdit,
  control,
  index,
  packagingNotesWatch,
  removePackagingNote,
  packagingFormData,
  isPackagingFormDataFetching
}) => {
  return (
    <Fragment>
      {isMobileDevice && !isEdit && isOpen && (
        <MobileExpandedCard
          name={packagingNotesWatch[index][PackingNotesFormKeys.PackageName]}
          material={
            packagingNotesWatch[index][PackingNotesFormKeys.PackagingType]
          }
          size={
            packagingNotesWatch[index][PackingNotesFormKeys.PackageDimension]
          }
        />
      )}
      {isMobileDevice && !isEdit && !isOpen && (
        <MobileCollapseCard
          name={packagingNotesWatch[index][PackingNotesFormKeys.PackageName]}
          material={
            packagingNotesWatch[index][PackingNotesFormKeys.PackagingType]
          }
          size={
            packagingNotesWatch[index][PackingNotesFormKeys.PackageDimension]
          }
        />
      )}

      <>
        {isEdit ? (
          <Grid item xs={12} md={3}>
            <TextInput
              control={control}
              name={`packagingNotes.${index}.${PackingNotesFormKeys.PackageName}`}
              label={order_table_labels.packageNameLabel}
              placeholder={order_table_labels.addPackageNamePlaceholder}
            />
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            md={3}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <HeaderFieldLabelAndValue
              label={order_table_labels.packageNameLabel}
              value={
                packagingNotesWatch[index][PackingNotesFormKeys.PackageName]
              }
            />
          </Grid>
        )}

        {isEdit ? (
          <Grid item xs={12} md={3}>
            <DropDown
              control={control}
              name={`packagingNotes.${index}.${PackingNotesFormKeys.MaterialCategory}`}
              label={order_table_labels.materialCategoryLabel}
              placeholder={order_table_labels.materialCategoryLabel}
              options={packagingFormData?.materialCategories}
              isLoading={isPackagingFormDataFetching}
            />
          </Grid>
        ) : (
          <></>
        )}

        {isEdit ? (
          <Grid item xs={12} md={3}>
            <DropDown
              control={control}
              name={`packagingNotes.${index}.${PackingNotesFormKeys.PackagingType}`}
              label={order_table_labels.packingMaterialLabel}
              placeholder={order_table_labels.packingMaterialLabel}
              options={packagingFormData?.packagingTypes}
              isLoading={isPackagingFormDataFetching}
            />
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            md={3}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <HeaderFieldLabelAndValue
              label={order_table_labels.packingMaterialLabel}
              value={
                packagingNotesWatch[index][PackingNotesFormKeys.PackagingType]
              }
            />
          </Grid>
        )}

        {isEdit ? (
          <Grid
            item
            xs={12}
            md={3}
            display={"flex"}
            // gap={2}
            alignItems={"flex-end"}
          >
            <Box width={"100%"}>
              <DropDown
                control={control}
                name={`packagingNotes.${index}.${PackingNotesFormKeys.PackageDimension}`}
                label={order_table_labels.packageSizeLabel}
                placeholder={order_table_labels.packageSizeLabel}
                options={packagingFormData?.packageDimensions}
                isLoading={isPackagingFormDataFetching}
              />
            </Box>
            {isEdit && removePackagingNote && (
              <IconButton
                onClick={() => removePackagingNote(index)}
                color="error"
                title="Remove packaging note"
                sx={{
                  display: {
                    xs: "none",
                    md: "flex"
                  }
                }}
              >
                <CancelOutlined />
              </IconButton>
            )}
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            md={3}
            gap={2}
            alignItems={"center"}
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            <Box width={"100%"}>
              <HeaderFieldLabelAndValue
                label={order_table_labels.packageNameLabel}
                value={
                  packagingNotesWatch[index][
                    PackingNotesFormKeys.PackageDimension
                  ]
                }
              />
            </Box>
          </Grid>
        )}
      </>
    </Fragment>
  );
};

export const ItemBody = ({
  isEdit,
  control,
  watch,
  itemFields,
  appendItem,
  removeItem,
  index,
  packagingFormData,
  isPackagingFormDataFetching
}) => {
  const MaterialCategoryWatch = watch(
    `packagingNotes.${index}.${PackingNotesFormKeys.MaterialCategory}`
  );
  return (
    <Box
      position={"relative"}
      sx={{
        borderRight: "0.7px dashed #B7BEC7",
        overflow: "hidden",
        height: "100%",
        padding: {
          xs: "24px 16px",
          md: 3
        }
      }}
    >
      <Grid container spacing={"27px"}>
        <Grid item xs={12} container spacing={3}>
          {itemFields.map((obj, itemsIndex) => (
            <Grid container item xs={12} key={obj.id} spacing={"27px"}>
              <Grid
                item
                xs={1}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{
                  display: {
                    xs: "none",
                    md: "flex"
                  }
                }}
              >
                <Divider
                  orientation="vertical"
                  sx={{
                    borderColor: "#B7BEC7",
                    position: "absolute",
                    top: 0
                  }}
                />
                <Divider
                  orientation="horizontal"
                  sx={{ borderColor: "#B7BEC7", width: 24, marginLeft: "24px" }}
                />
              </Grid>
              <Grid
                item
                xs={1}
                sx={{
                  display: {
                    xs: "block",
                    md: "none"
                  }
                }}
              >
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 400,
                    lineHeight: "16.94px"
                  }}
                  color={"text.secondary"}
                >
                  {`1.${itemsIndex + 1}`}
                </Typography>
              </Grid>
              <Grid item xs={11} container spacing={isEdit ? "12px" : "20px"}>
                <Grid item xs={isMobileDevice && !isEdit ? 6 : 12} md={5}>
                  <Box display={"flex"} gap={2} alignItems={"center"}>
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontWeight: 400,
                        lineHeight: "16.94px",
                        display: { xs: "none", md: "block" }
                      }}
                      color={"text.secondary"}
                    >
                      {`1.${itemsIndex + 1}`}
                    </Typography>
                    {isEdit ? (
                      <TextInput
                        control={control}
                        name={`packagingNotes.${index}.items.${itemsIndex}.name`}
                        placeholder={order_table_labels.itemNamePlaceholder}
                      />
                    ) : (
                      <Typography
                        color={
                          isMobileDevice ? "text.primary" : "text.secondary"
                        }
                        sx={{
                          fontSize: 14,
                          fontWeight: 400,
                          lineHeight: "20px"
                        }}
                      >
                        {obj[PackingNotesFormKeys.ItemName]}
                      </Typography>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={isMobileDevice && !isEdit ? 6 : 12} md={3}>
                  {isEdit ? (
                    <DropDown
                      control={control}
                      name={`packagingNotes.${index}.items.${itemsIndex}.materialSubCategory`}
                      placeholder={order_table_labels.categoryPlaceholder}
                      options={
                        MaterialCategoryWatch
                          ? Object.values(
                              packagingFormData?.materialCategories.find(
                                (item) => item.name === MaterialCategoryWatch
                              )?.subCategories
                            )
                          : []
                      }
                      isLoading={isPackagingFormDataFetching}
                    />
                  ) : (
                    <Typography
                      color={"text.secondary"}
                      sx={{
                        fontSize: 14,
                        fontWeight: 400,
                        lineHeight: "20px"
                      }}
                    >
                      {obj[PackingNotesFormKeys.MaterialSubCategory]}
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  xs={isMobileDevice && !isEdit ? 6 : 12}
                  md={4}
                  display={"flex"}
                  gap={2}
                  alignItems={"flex-start"}
                >
                  <Box width={"100%"}>
                    {isEdit ? (
                      <TextInput
                        control={control}
                        name={`packagingNotes.${index}.items.${itemsIndex}.quantity`}
                        placeholder={order_table_labels.quantityPlaceholder}
                      />
                    ) : (
                      <Typography
                        color={"text.secondary"}
                        sx={{
                          fontSize: 14,
                          fontWeight: 400,
                          lineHeight: "20px"
                        }}
                      >
                        {obj[PackingNotesFormKeys.Quantity]}
                      </Typography>
                    )}
                  </Box>
                  {isEdit && (
                    <IconButton
                      onClick={() => removeItem(itemsIndex)}
                      color="error"
                      title="Remove packaging item"
                      sx={{
                        display: {
                          xs: "none",
                          md: "flex"
                        }
                      }}
                    >
                      <CancelOutlined />
                    </IconButton>
                  )}
                </Grid>
                {itemFields.length !== itemsIndex + 1 && !isEdit && (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      padding: {
                        md: "0px !important"
                      },
                      display: {
                        xs: "none",
                        md: "block"
                      }
                    }}
                  >
                    <Divider
                      sx={{
                        position: "absolute",
                        width: "100%",
                        left: "5%",
                        marginTop: "12px"
                      }}
                    />
                  </Grid>
                )}
              </Grid>
              {itemFields.length !== itemsIndex + 1 && !isEdit && (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: { xs: "block", md: "none" }
                  }}
                >
                  <Divider
                    sx={{ position: "absolute", width: "100%", left: 0 }}
                  />
                </Grid>
              )}
            </Grid>
          ))}
        </Grid>
        {isEdit && (
          <>
            <Grid item xs={1}></Grid>
            <Grid item xs={11}>
              <Button
                variant="link"
                onClick={() =>
                  appendItem({
                    name: "",
                    materialSubCategory: "",
                    quantity: ""
                  })
                }
                sx={{ ml: { xs: 0, md: "30px" } }}
              >
                <AddIcon />
                {order_table_labels.addMoreItems}
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export const AccordionHeaderQRCard = ({ isOpen, orderDetails }) => {
  return (
    <>
      <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
        <Box display={"flex"} gap="4px">
          <Typography fontSize={14} fontWeight={600} lineHeight={"normal"}>
            {`${order_table_labels.orderNo} :`}
          </Typography>
          <Typography
            fontSize={13}
            lineHeight={"18px"}
            color={"text.secondary"}
          >
            {orderDetails?.code}
          </Typography>
        </Box>
        <Box display={"flex"} gap="4px">
          <Typography fontSize={14} fontWeight={600} lineHeight={"normal"}>
            {`${order_table_labels.orderDate} :`}
          </Typography>
          <Typography
            fontSize={13}
            lineHeight={"18px"}
            color={"text.secondary"}
          >
            {moment(orderDetails.createdAt).format("DD-MM-YYYY")}
          </Typography>
        </Box>
        {isOpen && (
          <Divider
            sx={{
              border: "0.7px solid #B7BEC7",
              marginTop: "7px"
            }}
          />
        )}
      </Box>
    </>
  );
};

export const AccordionMobileHeaderQRCard = ({
  isEdit,
  orderDetails,
  qrImage
}) => {
  return (
    <Box display={"flex"} gap={2} justifyContent={"space-between"}>
      <Box display={"flex"} flexDirection={"column"} gap={"13px"}>
        <Box display={"flex"} gap="4px">
          <Typography fontSize={14} fontWeight={600} lineHeight={"normal"}>
            {`${order_table_labels.orderNo} :`}
          </Typography>
          <Typography
            fontSize={13}
            lineHeight={"18px"}
            color={"text.secondary"}
          >
            {orderDetails?.code}
          </Typography>
        </Box>
        <Box display={"flex"} gap="4px">
          <Typography fontSize={14} fontWeight={600} lineHeight={"normal"}>
            {`${order_table_labels.orderDate} :`}
          </Typography>
          <Typography
            fontSize={13}
            lineHeight={"18px"}
            color={"text.secondary"}
          >
            {moment(orderDetails.createdAt).format("DD-MM-YYYY")}
          </Typography>
        </Box>
        <Box display={"flex"} gap="4px">
          <Typography fontSize={14} fontWeight={600} lineHeight={"normal"}>
            {`${labels.inboundDateLabelText} :`}
          </Typography>
          <Typography
            fontSize={13}
            lineHeight={"18px"}
            color={"text.secondary"}
          >
            {moment(orderDetails.inboundDate).format("DD-MM-YYYY")}
          </Typography>
        </Box>
        <Box display={"flex"} gap="4px">
          <Typography fontSize={14} fontWeight={600} lineHeight={"normal"}>
            {`${labels.outboundDateLabelText} :`}
          </Typography>
          <Typography
            fontSize={13}
            lineHeight={"18px"}
            color={"text.secondary"}
          >
            {moment(orderDetails.outboundDate).format("DD-MM-YYYY")}
          </Typography>
        </Box>
      </Box>
      <Box display={"flex"}>
        {isEdit ? (
          <img
            src={QRMobilePlaceholder}
            alt={order_table_labels.qrCodePlaceholderLabel}
          />
        ) : (
          <img
            src={qrImage?.fileUrl}
            alt={qrImage?.originalName}
            height={100}
            width={100}
          />
          // <QRCode
          //   style={{
          //     height: 100,
          //     width: 100
          //   }}
          //   value="http://demo.warex.in/"
          //   viewBox={`0 0 256 256`}
          // />
        )}
      </Box>
    </Box>
  );
};

export const QRCardBody = ({ isEdit, orderDetails, qrImage }) => {
  return (
    <Box sx={isMobileDevice && { position: "relative", bottom: "5px" }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
            <Box display={"flex"} gap="4px">
              <Typography fontSize={14} fontWeight={600} lineHeight={"normal"}>
                {`${labels.inboundDateLabelText} :`}
              </Typography>
              <Typography
                fontSize={13}
                lineHeight={"18px"}
                color={"text.secondary"}
              >
                {moment(orderDetails.inboundDate).format("DD MMMM YYYY")}
              </Typography>
            </Box>
            <Box display={"flex"} gap="4px">
              <Typography fontSize={14} fontWeight={600} lineHeight={"normal"}>
                {`${labels.outboundDateLabelText} :`}
              </Typography>
              <Typography
                fontSize={13}
                lineHeight={"18px"}
                color={"text.secondary"}
              >
                {moment(orderDetails.outboundDate).format("DD MMMM YYYY")}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "auto",
              backgroundImage: qrImage?.fileUrl
                ? `url(${qrImage.fileUrl})`
                : `url(${QRCodeBlurPlaceholder})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: { xs: "unset", lg: 170 },
              height: { xs: 100, lg: 170 },
              boxSizing: "content-box",
              padding: !isEdit ? "24px 0" : 0
            }}
          >
            {/* {!isEdit && (
              // <QRCode
              //   style={{
              //     height: isMobileDevice ? 100 : 175,
              //     width: isMobileDevice ? 100 : 175
              //   }}
              //   value="http://demo.warex.in/"
              //   viewBox={`0 0 256 256`}
              // />
              <img
                src={qrImage.fileUrl}
                alt={qrImage.originalName}
                height={100}
                width={100}
              />
            )} */}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
