import { getPackingNotesCreateFormData } from "../../customer/services";
import { keys } from "./keys";
import { useQuery } from "@tanstack/react-query";

export const useGetCreateFormData = (orderId) => {
  const createFormDataQuery = useQuery(
    [keys.queryGetCreateFormData, orderId],
    () => getPackingNotesCreateFormData(orderId),
    {
      enabled: Boolean(orderId),
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true
    }
  );

  return createFormDataQuery;
};
