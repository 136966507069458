import { Box, Divider, Grid, Typography } from "@mui/material";
import { StyledChip } from "../../shared/UiElements/StatusChip";
import { currencyOnlyFormatter, stringCapitalization } from "../../shared/utils";
import moment from "moment";
import { ValueLabelText } from "../../purchase/components/Purchase/MobilePOTable";

export const MobileSubscriptionTable = (row) => {
  return (
    <Grid container spacing={"20px"}>
      <Grid item xs={12} container rowSpacing={2}>
        <Grid item xs={4} display={"flex"} justifyContent={"left"}>
          <Typography
            sx={{
              fontSize: 14,
              lineHeight: "18px",
              fontWeight: 500,
              color: "#2773FF"
            }}
          >
            {row.subId}
          </Typography>
        </Grid>
        <Grid item xs={4} display={"flex"} justifyContent={"center"}>
          <ValueLabelText
            label={"Start Date"}
            value={moment(row.startDate).format("DD-MM-YYYY")}
          />
        </Grid>
        <Grid item xs={4} display={"flex"} justifyContent={"right"}>
          <ValueLabelText
            label={"End Date"}
            value={moment(row.endDate).format("DD-MM-YYYY")}
          />
        </Grid>
        <Grid item xs={12} container rowSpacing={0.5} mt={1}>
          <Grid item xs={4} display={"flex"} justifyContent={"left"}>
            <ValueLabelText
              label={"Sub Amt"}
              value={currencyOnlyFormatter(
                parseFloat(row.amountPaid).toFixed(2)
              )}
            />
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"center"}>
            <ValueLabelText label={"Trans. Ref."} value={row.referenceNo} />
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"right"}>
            <ValueLabelText
              label={"Payment Date"}
              value={moment(row.dateOfPayment).format("DD-MM-YYYY")}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
        <Grid item xs={6} display={"flex"} justifyContent={"left"}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={0.5}
            alignItems={"flex-start"}
          >
            <StyledChip
              label={stringCapitalization(row.status)}
              variant="text"
            />
            <Typography
              fontSize={12}
              lineHeight={"16px"}
              color={"text.disabled"}
            >
              Status
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} display={"flex"} justifyContent={"right"}>
          <ValueLabelText label={"Users"} value={row.numberOfUsers} />
        </Grid>
      </Grid>
    </Grid>
  );
};
