import { submitSalesReturnDraft } from "../../../sales/services";
import { useMutation } from "@tanstack/react-query";

export const useSubmitSalesReturnDraft = () => {
  const submitSalesReturnById = useMutation(
    ({ data, salesOrderId, salesReturnId }) =>
      submitSalesReturnDraft(data, salesOrderId, salesReturnId)
  );
  return submitSalesReturnById;
};
