import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import {
  currencyFormatter,
  dateTimeFormatter,
  stringCapitalization
} from "../../../shared/utils";

import { StyleBorderedGrid } from "../CompleteOrder";
import { StyledChip } from "../../../shared/UiElements/StatusChip";
import { invoice_and_docs_labels } from "../../../constants";

const LedgerDetails = ({
  header,
  subHeader1,
  subHeader2,
  headerAmt,
  subHeader1Amt,
  subHeader2Amt,
  subHeader1Date,
  subHeader2Date,
  subHeaderTid
}) => {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography
            py={1}
            pb={2}
            sx={{
              fontSize: 16,
              fontWeight: 500,
              lineHeight: "20px",
              whiteSpace: "nowrap"
            }}
          >
            {header}
          </Typography>
          <Typography
            py={1}
            color={"text.secondary"}
            sx={{
              fontSize: 14,
              fontWeight: 400,
              lineHeight: "20px",
              whiteSpace: "nowrap"
            }}
          >
            {subHeader1}
          </Typography>
          <Typography
            py={1}
            color={"text.secondary"}
            sx={{
              fontSize: 14,
              fontWeight: 400,
              lineHeight: "20px",
              whiteSpace: "nowrap"
            }}
          >
            {subHeader2}
          </Typography>
        </Grid>
        <Grid item xs={4} textAlign={"center"}>
          <Typography
            py={1}
            pb={2}
            sx={{
              fontSize: 14,
              fontWeight: 700,
              lineHeight: "20px"
            }}
          >
            {currencyFormatter(headerAmt)}
          </Typography>
          <Typography
            py={1}
            color={"text.secondary"}
            sx={{
              fontSize: 14,
              fontWeight: 400,
              lineHeight: "20px"
            }}
          >
            {currencyFormatter(subHeader1Amt)}
          </Typography>
          <Typography
            py={1}
            color={"text.secondary"}
            sx={{
              fontSize: 14,
              fontWeight: 400,
              lineHeight: "20px"
            }}
          >
            {subHeader2Amt ? currencyFormatter(subHeader2Amt) : subHeaderTid}
          </Typography>
        </Grid>
        <Grid item xs={4} textAlign={"center"}>
          <Typography
            py={1}
            pt={6}
            color={"text.secondary"}
            sx={{
              fontSize: 14,
              fontWeight: 400,
              lineHeight: "20px"
            }}
          >
            {dateTimeFormatter(subHeader1Date)}
          </Typography>
          <Typography
            py={1}
            color={"text.secondary"}
            sx={{
              fontSize: 14,
              fontWeight: 400,
              lineHeight: "20px"
            }}
          >
            {dateTimeFormatter(subHeader2Date)}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const PayNow = () => {
  return (
    <Box
      sx={{
        p: 2,
        pt: 0,
        pl: 0
      }}
    >
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item xs={4}>
          <Typography
            color={"text.secondary"}
            sx={{
              fontSize: 14,
              fontWeight: 400,
              lineHeight: "20px"
            }}
          >
            {invoice_and_docs_labels.toBePaid}
          </Typography>
        </Grid>
        <Grid item xs={4} textAlign={"center"}>
          <Typography
            color={"error"}
            sx={{
              fontSize: 14,
              fontWeight: 400,
              lineHeight: "20px"
            }}
          >
            {currencyFormatter(15000)}
          </Typography>
        </Grid>
        <Grid item xs={4} textAlign={"end"}>
          <Button
            variant="contained"
            sx={{ whiteSpace: "nowrap", height: 40, width: 128 }}
          >
            {invoice_and_docs_labels.payNow}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

const LedgerDoc = () => {
  return (
    <StyleBorderedGrid
      container
      spacing={3}
      alignItems={"center"}
      margin={0}
      width={"100%"}
    >
      <Grid item xs={6}>
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 500,
            lineHeight: "20px",
            color: "text.secondary",
            letterSpacing: "0.5%"
          }}
        >
          {invoice_and_docs_labels.paymentLedger}
        </Typography>
      </Grid>
      <Grid item xs={6} textAlign={"end"} pr={2}>
        <StyledChip label={stringCapitalization("Partial")} />
      </Grid>
      <Grid item xs={12} sx={{ background: "#F7F8FA", mt: 2 }}>
        <PayNow />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          paddingLeft: { xs: "0px !important" },
          paddingTop: "0px !important"
        }}
      >
        <Divider
          sx={{
            background: "#D9D9D9",
            border: "1px dashed #2773FF",
            borderRadius: "0.5px"
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <LedgerDetails
          header={invoice_and_docs_labels.invoiceDetails}
          subHeader1={invoice_and_docs_labels.warehouseInvoice}
          subHeader2={invoice_and_docs_labels.transporterInvoice}
          headerAmt={"80000"}
          subHeader1Amt={"50000"}
          subHeader2Amt={30000}
          subHeader1Date={"01 April 2023"}
          subHeader2Date={"3 Jun 2023"}
        />
      </Grid>
      <Grid item xs={12} pr={2}>
        <Divider
          sx={{
            border: "0.4px solid #B7BEC7",
            borderRadius: "0.5px"
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <LedgerDetails
          header={invoice_and_docs_labels.orderDetails}
          subHeader1={invoice_and_docs_labels.warehouseOrder}
          subHeader2={invoice_and_docs_labels.transporterOrder}
          headerAmt={"65000"}
          subHeader1Amt={"50000"}
          subHeader2Amt={15000}
          subHeader1Date={"10 April 2023"}
          subHeader2Date={"23 Jun 2023"}
        />
      </Grid>
      <Grid item xs={12} pr={2}>
        <Divider
          sx={{
            border: "0.4px solid #B7BEC7",
            borderRadius: "0.5px"
          }}
        />
      </Grid>
      <Grid item xs={12} marginBottom={3}>
        <LedgerDetails
          header={invoice_and_docs_labels.yourPayments}
          subHeader1={invoice_and_docs_labels.paymentAmount}
          subHeader2={invoice_and_docs_labels.transactionID}
          headerAmt={"55000"}
          subHeader1Amt={"64000"}
          subHeaderTid={"XXXXX1234"}
          subHeader1Date={"10 April 2023"}
        />
      </Grid>
    </StyleBorderedGrid>
  );
};

export default LedgerDoc;
