import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid
} from "@mui/material";
import {
  AccordionHeaderFieldsAndLabels,
  AccordionHeaderQRCard,
  AccordionMobileHeaderQRCard,
  ItemBody,
  QRCardBody
} from "./SubComponents";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";

import { PackingNotesFormKeys } from "../../../../constants/formKeys";
import { forwardRef } from "react";
import { isMobileDevice } from "../../../../App";
import { primaryBackground } from "../../../../shared/theme/globalTheme";
import styled from "@emotion/styled";
import { useFieldArray } from "react-hook-form";

const StyledAccordion = styled((props) => (
  <Accordion {...props} disableGutters />
))(() => ({
  minHeight: 100,
  height: "100%",
  background: "#FFFFFF",
  border: "0.7px solid #B7BEC7",
  borderRadius: "8px !important",
  boxShadow: "none",
  overflow: "hidden",
  "&:not(:last-child)": {
    borderBottom: 0
  },
  "&:before": {
    display: "none"
  },
  ".Mui-focusVisible": {
    backgroundColor: "transparent !important"
  }
}));

const StyledAccordionSummary = styled((props) => (
  <AccordionSummary {...props} />
))(({ theme }) => ({
  padding: 0,
  "& .MuiAccordionSummary-content": {
    margin: 0
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    margin: 0
  }
}));

export const AccordionCard = forwardRef((props, ref) => {
  const { item, isEdit, expanded, handleChange, control, index } = props;

  const {
    fields: itemFields,
    append: appendItem,
    remove: removeItem
  } = useFieldArray({
    control,
    name: `packagingNotes.${index}.${PackingNotesFormKeys.Items}`
  });

  const isOpen = expanded === item;

  return (
    <div ref={isOpen ? ref : null}>
      <StyledAccordion
        expanded={isOpen}
        onChange={handleChange(item)}
        elevation={0}
        sx={{
          boxShadow: {
            xs: "0px 8px 24px 0px rgba(21, 21, 22, 0.10)",
            md: "none"
          }
        }}
      >
        <StyledAccordionSummary
          aria-controls={`${item}-content`}
          id={`${item}-header`}
        >
          <Grid container spacing={3}>
            {isOpen && (
              <Grid
                item
                xs={12}
                sx={{
                  display: {
                    xs: "flex",
                    md: "none"
                  }
                }}
                alignItems={"center"}
              >
                <Box sx={{ padding: "24px 16px 0 16px" }} width={"100%"}>
                  <AccordionMobileHeaderQRCard
                    isEdit={isEdit}
                    orderDetails={props.orderDetails}
                    qrImage={props.qrImage}
                  />
                </Box>
              </Grid>
            )}
            <Grid item xs={12} md={9}>
              <Box
                sx={{
                  background: isOpen && primaryBackground,
                  borderRight: "0.7px dashed #B7BEC7",
                  height: "100%",
                  padding: {
                    xs: "20px 16px",
                    md: 3
                  }
                }}
              >
                <Grid container spacing={"27px"}>
                  <Grid
                    item
                    xs={1}
                    justifyContent={"center"}
                    sx={{
                      display: {
                        xs: "none",
                        md: "flex"
                      }
                    }}
                  >
                    {isOpen ? (
                      <RemoveCircleOutline sx={{ color: "#607088" }} />
                    ) : (
                      <AddCircleOutline sx={{ color: "#607088" }} />
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={isMobileDevice ? 12 : 11}
                    container
                    spacing={isMobileDevice ? 2 : "8px"}
                  >
                    <AccordionHeaderFieldsAndLabels
                      isOpen={isOpen}
                      {...props}
                    />
                    {/* <MobileCollapseCard /> */}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                display: {
                  xs: "none",
                  md: "flex"
                }
              }}
              alignItems={"center"}
            >
              <Box sx={{ padding: "24px 24px 24px 0" }} width={"100%"}>
                <AccordionHeaderQRCard
                  isOpen={isOpen}
                  orderDetails={props.orderDetails}
                />
              </Box>
            </Grid>
          </Grid>
        </StyledAccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={9}>
              <ItemBody
                {...props}
                itemFields={itemFields}
                appendItem={appendItem}
                removeItem={removeItem}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                display: { xs: "none", md: "block" }
              }}
            >
              <Box sx={{ padding: "0 24px 0 0" }}>
                <QRCardBody
                  isEdit={isEdit}
                  orderDetails={props.orderDetails}
                  qrImage={props.qrImage}
                />
              </Box>
            </Grid>
          </Grid>
        </AccordionDetails>
      </StyledAccordion>
    </div>
  );
});
