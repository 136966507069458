import { queryKeys } from "../../constants/queryKeys";
import { getTransport } from "../../inventory/services";
import { useQuery } from "@tanstack/react-query";

export const useFetchAssetTransport = () => {
  const Transport = useQuery(
    [queryKeys.queryFetchAssetTransport],
    () => getTransport(),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return Transport;
};
