import { useEffect, useState } from "react";

const useInViewSections = (sectionIds) => {
  const [activeSection, setActiveSection] = useState(null);

  useEffect(() => {
    const handleIntersection = (entries) => {
      let intersectingSection = null;
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          intersectingSection = entry.target.id;
        }
      });
      setActiveSection(intersectingSection);
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    });

    sectionIds.forEach((id) => {
      const element = document.getElementById(id.toLowerCase());
      if (element) {
        observer.observe(element);
      }
    });

    return () => {
      sectionIds.forEach((id) => {
        const element = document.getElementById(id.toLowerCase());
        if (element) {
          observer.unobserve(element);
        }
      });
    };
  }, [sectionIds]);

  return activeSection;
};

export default useInViewSections;
