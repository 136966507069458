import axios from "axios";
import { getToken } from "../utils/getToken";

export const client = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL
});

client.interceptors.request.use(
  async (config) => {
    // Set the Authorization header with the Amplify ID token
    const token = await getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
