import { queryKeys } from "../../constants/queryKeys";
import { getMrrFormData } from "../../inventory/services";
import { useQuery } from "@tanstack/react-query";

export const useFetchMRRFormData = (orderId, allowAccess) => {
  const MrrFormData = useQuery(
    [queryKeys.queryFetchMrrFormData, orderId, allowAccess],
    () => getMrrFormData(orderId),
    {
      enabled: allowAccess && Boolean(orderId),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return MrrFormData;
};
