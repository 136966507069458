import {
  Box,
  Button,
  FormHelperText,
  Grid,
  Menu,
  MenuItem,
  Typography,
  styled
} from "@mui/material";
import {
  CloudUploadOutlined,
  MoreHorizOutlined,
  Verified
} from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";

import { Controller } from "react-hook-form";

const StyledPreviewBox = styled(Box)(() => ({
  textAlign: "center",
  overflow: "hidden",
  height: "100%",
  borderRadius: "8px",
  maxHeight: 112,
  position: "relative",
  cursor: "pointer",
  "& > img": {
    borderRadius: "8px",
    height: "100%",
    width: "100%",
    objectFit: "cover"
  }
}));

const StyleMoreIcon = styled((props) => <Box {...props} id="more-button" />)(
  ({ theme }) => ({
    background: theme.palette.text.primary,
    position: "absolute",
    top: "8px",
    right: "8px",
    borderRadius: "50%",
    height: 20,
    width: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "4px",
    "& > svg": {
      color: "white",
      fontSize: 20
    }
  })
);

const moveToPosition = (files, fromIndex, toIndex) => {
  const filesCopy = [...files];
  var element = filesCopy[fromIndex];
  filesCopy.splice(fromIndex, 1);
  filesCopy.splice(toIndex, 0, element);

  return filesCopy;
};

export function ImageUpload({
  name,
  control,
  isMultiple = false,
  setValue,
  compact = false,
  showTextOnly = false,
  isProfile = false,
  uploadButtonText,
  minimumUploads = 5,
  acceptFileType = ".jpg,.png",
  disabled = false
}) {
  const filePickerRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [file, setFile] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentFileIndex, setCurrentFileIndex] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event, fileIndex = null) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setCurrentFileIndex(fileIndex);
  };
  const handleClose = () => {
    setAnchorEl(null); // Reset currentFileIndex to null when closing the menu

    setCurrentFileIndex(null);

    // Clear file state if the deleted file was a single file upload
    if (!isMultiple && currentFileIndex !== null) {
      setFile("");
      setValue(name, "");
    }
  };

  const convertToBase64 = (file) => {
    return URL.createObjectURL(file);
  };

  useEffect(() => {
    if (
      !control._fields[name]?._f?.value ||
      control._fields[name]?._f?.value.length === 0
    ) {
      if (isMultiple) {
        setFiles([]);
        setValue(name, []);
      } else {
        setFile("");
        setValue(name, "");
      }
    } else {
      if (isMultiple) {
        setFiles((prevFiles) => [
          ...prevFiles,
          ...control._fields[name]._f.value
        ]);
        setValue(name, [...control._fields[name]._f.value]);
      } else {
        setFile(control._fields[name]._f.value);
        setValue(name, control._fields[name]._f.value);
      }
    }
  }, [control, control._fields, isMultiple, name, setValue]);

  const handleUploadFiles = (chosenFiles, field) => {
    const uploaded = [...files];
    chosenFiles.forEach((file) => {
      uploaded.push(file);
    });
    setFiles(uploaded);
    field.onChange(uploaded);
  };

  const handleUploadSingleFile = (file, field) => {
    setFile(file);
    field.onChange(file);
  };

  const moveImageUp = (index) => {
    if (index === 0) return;

    const updatedFilesArray = moveToPosition(files, index, index - 1);
    setFiles(updatedFilesArray);
    setValue(name, updatedFilesArray);
  };

  const moveImageDown = (index) => {
    if (index === files.length - 1) return;

    const updatedFilesArray = moveToPosition(files, index, index + 1);
    setFiles(updatedFilesArray);
    setValue(name, updatedFilesArray);
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={isMultiple ? [] : ""}
      render={({ field, fieldState: { error } }) => (
        <>
          <input
            ref={filePickerRef}
            style={{ display: "none" }}
            type="file"
            multiple={isMultiple}
            accept={acceptFileType}
            key={isMultiple ? files.length : Boolean(file)}
            disabled={disabled}
            onChange={(e) => {
              const target = e.target;

              if (!target.files[0]) {
                return;
              }

              if (
                target.files &&
                isMultiple &&
                target.files.length <= minimumUploads
              ) {
                const chosenFiles = Array.prototype.slice.call(target.files);
                handleUploadFiles(chosenFiles, field);
              }

              if (target.files && !isMultiple && target.files.length === 1) {
                const chosenFile = target.files[0];
                handleUploadSingleFile(chosenFile, field);
              }
            }}
          />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {file && !isMultiple ? (
                <Grid item xs={12} md={3}>
                  <StyledPreviewBox>
                    {file?.type === "application/pdf" || showTextOnly ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%",
                          width: "100%",
                          gap: 2
                        }}
                      >
                        <Verified sx={{ color: "primary.main" }} />
                        <Button
                          variant="link"
                          disableRipple
                          sx={{ fontWeight: 600, height: 30 }}
                        >
                          {file?.name}
                        </Button>
                      </Box>
                    ) : (
                      <img src={convertToBase64(file)} alt="Preview" />
                    )}
                    <StyleMoreIcon>
                      <MoreHorizOutlined onClick={handleClick} />
                    </StyleMoreIcon>
                  </StyledPreviewBox>
                </Grid>
              ) : files.length > 0 && isMultiple ? (
                <Grid container spacing={2}>
                  {files.map(
                    (fileUrl, index) =>
                      index !== minimumUploads && (
                        <Grid
                          item
                          xs={12}
                          md={showTextOnly ? 12 : 4}
                          xl={showTextOnly ? 12 : 3}
                          key={index}
                        >
                          <StyledPreviewBox>
                            {fileUrl?.type === "application/pdf" ||
                            showTextOnly ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "left",
                                  height: "100%",
                                  width: "100%",
                                  gap: 2
                                }}
                              >
                                <Verified sx={{ color: "primary.main" }} />
                                <Button
                                  variant="link"
                                  disableRipple
                                  sx={{ fontWeight: 600, height: 30 }}
                                >
                                  {fileUrl?.name}
                                </Button>
                              </Box>
                            ) : (
                              <img
                                src={convertToBase64(fileUrl)}
                                alt="Preview"
                              />
                            )}
                            <StyleMoreIcon
                              onClick={(e) => handleClick(e, index)}
                            >
                              <MoreHorizOutlined />
                            </StyleMoreIcon>
                          </StyledPreviewBox>
                        </Grid>
                      )
                  )}
                  {files.length !== minimumUploads && (
                    <Grid
                      item
                      xs={12}
                      md={showTextOnly ? 12 : 4}
                      alignItems="center"
                    >
                      {showTextOnly ? (
                        <Box
                          sx={{
                            border: "1px dashed #4C4C4C;",
                            height: compact ? 61 : 209,
                            borderRadius: 2,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: compact ? "row" : "column"
                          }}
                        >
                          <CloudUploadOutlined color="#000000" />
                          <Button
                            variant="text"
                            onClick={() => filePickerRef.current?.click()}
                            sx={{
                              fontWeight: 600,
                              textTransform: "none",
                              height: 39,
                              fontSize: 14,
                              marginLeft: 1
                            }}
                          >
                            {uploadButtonText}
                          </Button>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            height: 112,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            borderRadius: 2
                          }}
                        >
                          <CloudUploadOutlined />
                          <Button
                            variant="text"
                            onClick={() => filePickerRef.current?.click()}
                            sx={{
                              fontWeight: 600,
                              textTransform: "none",
                              height: 39
                            }}
                          >
                            Upload more
                          </Button>
                        </Box>
                      )}
                    </Grid>
                  )}
                </Grid>
              ) : isProfile ? (
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  flexDirection={"column"}
                  textAlign={"center"}
                  sx={{
                    background: file ? "#fff" : "#F3F5F9",
                    alignItems: "center",
                    minHeight: { xs: 150, md: 209 }
                  }}
                >
                  <Typography
                    fontSize={14}
                    lineHeight={"20px"}
                    marginBottom={"20px"}
                  >
                    Drag & drop file here or,
                  </Typography>
                  <CloudUploadOutlined color="#000000" />
                  <Button
                    variant="text"
                    onClick={() => filePickerRef.current?.click()}
                    disabled={disabled}
                    sx={{
                      fontWeight: 600,
                      textTransform: "none",
                      height: 39,
                      fontSize: 14,
                      marginLeft: 1
                    }}
                  >
                    {uploadButtonText}
                  </Button>
                </Box>
              ) : (
                <>
                  {!showTextOnly && (
                    <Typography
                      fontSize={14}
                      lineHeight={"20px"}
                      marginBottom={"20px"}
                    >
                      Only .jpg and .png files (upto 5MB each)
                    </Typography>
                  )}
                  <Box
                    sx={{
                      border: "1px dashed #4C4C4C;",
                      height: compact ? 61 : 209,
                      borderRadius: 2,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: compact ? "row" : "column"
                    }}
                  >
                    {compact ? (
                      <>
                        <CloudUploadOutlined color="#000000" />
                        <Button
                          variant="text"
                          onClick={() => filePickerRef.current?.click()}
                          sx={{
                            fontWeight: 600,
                            textTransform: "none",
                            height: 39,
                            fontSize: 14,
                            marginLeft: 1
                          }}
                        >
                          {uploadButtonText}
                        </Button>
                      </>
                    ) : (
                      <>
                        <CloudUploadOutlined color="#000000" />
                        {!isProfile && (
                          <>
                            <Typography
                              color="text.secondary"
                              fontSize={14}
                              marginTop={"20px"}
                            >
                              Upload upto 05 photos
                            </Typography>
                            <Typography
                              color="text.secondary"
                              fontSize={14}
                              marginTop={1}
                            >
                              Drag & drop files here or,
                            </Typography>
                          </>
                        )}
                        <Button
                          variant="text"
                          onClick={() => filePickerRef.current?.click()}
                          sx={{
                            fontWeight: 600,
                            textTransform: "none",
                            height: 39,
                            marginTop: 2
                          }}
                        >
                          {uploadButtonText}
                        </Button>
                      </>
                    )}
                  </Box>
                </>
              )}

              {error && <FormHelperText error>{error.message}</FormHelperText>}
            </Grid>
          </Grid>
          <Menu
            id="more-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "more-button"
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            sx={{
              "& > .MuiPaper-root": {
                boxShadow: "none",
                filter: "drop-shadow(0px 8px 24px rgba(21, 21, 22, 0.1))",
                width: 130
              },
              "& > .MuiPaper-root > ul": {
                padding: 0
              },
              "& > .MuiPaper-root > ul > li": {
                padding: "10px 12px",
                color: "#607088",
                borderRadius: 0
              }
            }}
          >
            {currentFileIndex !== null && (
              <MenuItem
                onClick={() => {
                  moveImageUp(currentFileIndex);
                  handleClose();
                }}
              >
                Move up
              </MenuItem>
            )}
            {currentFileIndex !== null && (
              <MenuItem
                onClick={() => {
                  moveImageDown(currentFileIndex);
                  handleClose();
                }}
              >
                Move down
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                if (isMultiple) {
                  const updatedFiles = [...files];
                  updatedFiles.splice(currentFileIndex, 1);
                  setFiles(updatedFiles);
                  setValue(name, updatedFiles);
                  handleClose();
                } else {
                  setFile("");
                  setValue(name, file);
                  handleClose();
                }
              }}
            >
              Delete
            </MenuItem>
          </Menu>
        </>
      )}
    />
  );
}
