import { deleteSubUser } from "../../settings/services";
import { useMutation } from "@tanstack/react-query";

export const useDeleteSubUser = () => {
  const deleteUser = useMutation(({ clientId, subUserId }) =>
    deleteSubUser(clientId, subUserId)
  );

  return deleteUser;
};
