import { KeyboardDoubleArrowRight, WhatsApp } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useCustomDialog } from "../customDialog";
import { useContext } from "react";
import { gtag_report_conversion } from "../utils/conversionUtils";
import { AppContext } from "../context/auth-context";
import Login from "./Login";

const FloatingWidgets = () => {
  const navigate = useNavigate();
  const { showDialog } = useCustomDialog();
  const { currentUser } = useContext(AppContext);

  const handleConversion = () => {
    // Call Google Analytics conversion tracking
    gtag_report_conversion();

    // Open dialog box
    Boolean(currentUser)
      ? navigate("/dashboard")
      : showDialog({
          component: <Login />,
          size: "xs",
          backdropOff: true,
          closeIcon: true
        });
  };

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        zIndex: 3
      }}
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"right"}
        component={"a"}
        href="https://wa.me/919059588686?text=What%20are%20your%20services?"
        target="_blank"
        rel="noopener noreferrer"
        sx={{ textDecoration: "none" }}
        gap={1}
      >
        <Typography
          sx={{
            fontSize: { xs: 12, md: 13 },
            lineHeight: "18px",
            fontWeight: 700,
            color: "#607088"
          }}
        >
          Chat with us
        </Typography>
        <WhatsApp
          sx={{
            fontSize: { xs: 30, md: 40 },
            backgroundColor: "transparent",
            color: "#25D366"
          }}
        />
      </Box>
      <Button
        variant="contained"
        sx={{
          color: "#FFFFFF",
          borderRadius: "50px",
          fontSize: { xs: 12, md: 13 },
          height: { xs: "30px", md: "38px" },
          lineHeight: "18px",
          fontWeight: 700,
          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
          backgroundColor: "#F9A90E"
        }}
        onClick={handleConversion}
      >
        {"Start Free Trial"}
        <KeyboardDoubleArrowRight
          sx={{ color: "#2773FF", fontSize: 16, ml: 0.5 }}
        />
      </Button>
    </Box>
  );
};

export default FloatingWidgets;
