import { getCreateTransportFormData } from "../../inventory/services";
import { queryKeys } from "../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchCreateTransportFormData = () => {
  const queryCreateTransportFormData = useQuery(
    [queryKeys.queryFetchCreateTransportFormData],
    () => getCreateTransportFormData(),
    {
      refetchOnWindowFocus: false
    }
  );

  return queryCreateTransportFormData;
};
