import { getVendorFilters } from "../../purchase/services/index";
import { queryKeys } from "../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchVendorFilters = () => {
  const queryVendorFilters = useQuery(
    [queryKeys.queryFetchVendorFilters],
    () => getVendorFilters(),
    {
      refetchOnWindowFocus: false
    }
  );

  return queryVendorFilters;
};
