import { queryKeys } from "../../constants/queryKeys";
import { getSubUsers } from "../../settings/services";
import { useQuery } from "@tanstack/react-query";

export const useFetchAllSubUsers = (clientId) => {
  const SubUsers = useQuery(
    [queryKeys.queryFetchSubUsers, clientId],
    () => getSubUsers(clientId),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return SubUsers;
};
