import { Box, Button, CircularProgress, Grid, IconButton } from "@mui/material";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CustomTable from "../../shared/customTable";
import { CustomTooltip } from "../../shared/theme/globalTheme";
import Filter from "../../purchase/components/Filter";
import Ledger from "../../assets/Ledger.webp";
import { StyledChip } from "../../shared/UiElements/StatusChip";
import { currencyOnlyFormatter, dropdownOptions } from "../../shared/utils";
import moment from "moment";
import { quotes_filterKeys } from "../../constants/formKeys";
import { quotes_labels } from "../../constants";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { getCustomCell } from "../../purchase/pages/PurchaseOrders";
import appRoutes from "../../constants/appRoutes";
import { useInView } from "react-intersection-observer";
import { useFetchQuotes } from "../../query-hooks/ClientSales/SalesQuote/useFetchQuotes";
import { useEffect, useMemo, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { useFetchQuotesFilters } from "../../query-hooks/ClientSales/SalesQuote/useFetchQuotesFilters";
import { useFetchQuotesSubtotal } from "../../query-hooks/ClientSales/SalesQuote/useFetchQuotesSubtotal";
import { CustomTypo, SubtotalTypo } from "../../purchase/pages/Vendors";
import { useDrawer } from "../../shared/customSideDrawer";
import DropDown from "../../shared/formElements/DropDown";
import { Search, TuneOutlined } from "@mui/icons-material";
import { MobileQuotes } from "../components/Quotes/MobileQuotes";

const getColumns = () => {
  return [
    {
      Header: "Doc. No",
      accessor: "docNo",
      width: 130,
      Cell: ({ row, value }) => {
        return getCustomCell(
          value,
          "",
          `${appRoutes.sales.main}/${appRoutes.sales.quotes}/${row.original.quoteId}`
        );
      },
      show: false
    },
    {
      Header: "Date",
      accessor: "date"
    },
    {
      Header: "Customer",
      accessor: "customer"
    },
    {
      Header: "Salesman",
      accessor: "salesMan"
    },
    {
      Header: "Quote Amt",
      accessor: "quoteAmt"
    },
    {
      Header: "Status",
      accessor: "quoteProgress",
      Cell: ({ value }) => {
        return <StyledChip label={value} />;
      }
    },
    {
      Header: "ChangeDate",
      accessor: "changeDate"
    },
    {
      Header: "Sales Order",
      accessor: "salesId",
      Cell: ({ row, value }) => {
        return (
          value &&
          getCustomCell(
            row?.original?.salesOrder,
            "",
            row?.original?.quoteProgress !== "Sale Order Cancelled"
              ? `/Order/Sale-Order/${value}`
              : ``
          )
        );
      },
      show: false
    },
    {
      Header: "Order Amt",
      accessor: "orderAmt"
    }
  ];
};

const getRows = (rows = []) => {
  return rows.map((item, index) => {
    return {
      quoteId: item._id,
      docNo: item.code ?? "QO-Draft",
      date: moment(item.orderDate).format("DD-MM-YYYY"),
      customer: item.vendorData.companyName,
      salesMan: item.salesPerson.name,
      quoteAmt: currencyOnlyFormatter(parseFloat(item.totalAmount).toFixed(2)),
      quoteProgress: item.status.displayName,
      changeDate: item?.relatedOrder?.orderDate
        ? moment(item?.relatedOrder?.orderDate).format("DD-MM-YYYY")
        : "",
      salesOrder: item?.relatedOrder?.code ?? "",
      salesId: item?.relatedOrder?.order ?? "",
      orderAmt: item?.relatedOrder?.totalAmount
        ? currencyOnlyFormatter(
            parseFloat(item?.relatedOrder?.totalAmount).toFixed(2)
          )
        : ""
    };
  });
};

const Quotes = () => {
  const [params, setParams] = useState();
  const { control, reset, handleSubmit } = useForm();
  const navigate = useNavigate();
  const { showDrawer, hideDrawer } = useDrawer();

  const { data: filterData, isFetching: isFilterFetching } =
    useFetchQuotesFilters();

  const { data: subTotalAmount, isFetching: isFetchingSubtotals } =
    useFetchQuotesSubtotal(params);

  const { ref, inView } = useInView();
  const {
    data,
    isFetching,
    isFetched,
    hasNextPage,
    fetchNextPage,
    refetch,
    isError,
    error
  } = useFetchQuotes(params);

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, inView]);

  const quoteTable = useMemo(
    () => (
      <CustomTable
        columns={getColumns()}
        data={
          isFetched
            ? data?.pages?.flatMap((page) => getRows(page?.quotations)) ?? []
            : []
        }
        isLoading={!isFetched}
        mobileComponent={MobileQuotes}
      />
    ),
    [data?.pages, isFetched]
  );

  const onViewClick = (data) => {
    const filterData = {
      ...{
        ...(data["status"] !== ""
          ? {
              [`filters[status]`]: data["status"]
            }
          : {})
      },
      ...{
        ...(data["customer"] !== ""
          ? {
              [`filters[customer]`]: data["customer"]
            }
          : {})
      },
      ...{
        ...(data["salesman"] !== ""
          ? {
              [`filters[salesPerson]`]: data["salesman"]
            }
          : {})
      },
      ...{
        ...(data?.date[0] !== null
          ? {
              [`filters[fromDate]`]: moment(data?.date[0]).format("YYYY-MM-DD")
            }
          : {})
      },
      ...{
        ...(data?.date[1] !== null
          ? {
              [`filters[toDate]`]: moment(data?.date[1]).format("YYYY-MM-DD")
            }
          : {})
      }
    };
    setParams(filterData);
    hideDrawer();
  };

  const onClear = () => {
    reset({
      warehouse: "",
      customer: "",
      status: "",
      date: ""
    });
    setParams(null);
  };

  const FilterComponent = (
    <Box>
      <Filter
        control={control}
        onApply={handleSubmit(onViewClick)}
        onClear={onClear}
        isFetching={isFilterFetching}
        isLoading={isFetching}
        filters={[
          {
            type: "DropDown",
            name: quotes_filterKeys.status,
            label: quotes_labels.status,
            unSelectText: "Select Status",
            options: filterData?.statuses,
            filterCount: filterData?.statuses?.length
          },
          {
            type: "DateInput",
            name: quotes_filterKeys.date,
            label: quotes_labels.date,
            placeholder: "Pick Dates",
            selectsRange: true
          },
          {
            type: "DropDown",
            name: quotes_filterKeys.customer,
            label: quotes_labels.customer,
            unSelectText: "Select Customer",
            allowSearch: true,
            options: dropdownOptions(
              filterData?.customers,
              "_id",
              "companyName"
            ),
            filterCount: filterData?.customers?.length
          },
          {
            type: "DropDown",
            name: quotes_filterKeys.salesman,
            label: quotes_labels.salesman,
            unSelectText: "Select Salesman",
            options: dropdownOptions(filterData?.salesPersons, "_id", "name"),
            filterCount: filterData?.salesPersons?.length
          }
        ]}
      />
    </Box>
  );

  useEffect(() => {
    if (params) {
      refetch();
    }
  }, [params, refetch]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(error?.response?.data?.message, {
        variant: "error"
      });
    }
  }, [error?.response?.data?.message, isError]);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      sx={{
        mb: { xs: 9, md: 0 }
      }}
    >
      <Box
        flexDirection={"column"}
        gap={2}
        sx={{
          display: { xs: "none", md: "flex" },
          position: "sticky",
          top: "64px",
          zIndex: 1000,
          background: "#fff",
          padding: "16px 0"
        }}
      >
        <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
          <Button
            variant="link"
            disableRipple
            onClick={() => {
              navigate("/sales/quotes/create-quotes");
            }}
          >
            <AddOutlinedIcon />
            {quotes_labels.createquotes}
          </Button>
        </Grid>
        <Grid item xs={12}>
          {FilterComponent}
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              border: "0.6px solid #2773FF",
              borderRadius: "8px",
              padding: "30px 20px 15px 40px",
              justifyContent: "space-between",
              alignItems: "center",
              textAlign: "center",
              gap: 4
            }}
          >
            {isFetchingSubtotals ? (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                width={"100%"}
              >
                <CircularProgress color="primary" />
              </Box>
            ) : (
              <>
                <Box display={"flex"}>
                  <CustomTooltip title={"Ledger"} placement="top">
                    <IconButton>
                      <img
                        src={Ledger}
                        className="pull-right cursor-field"
                        alt={"value"}
                      />
                    </IconButton>
                  </CustomTooltip>
                </Box>
                <Box
                  display={"flex"}
                  sx={{
                    justifyContent: "right",
                    alignItems: "center",
                    textAlign: "center",
                    gap: 4
                  }}
                >
                  <CustomTypo color="primary">{"Subtotal :"}</CustomTypo>
                  {SubtotalTypo("--", "Quote Amt")}
                </Box>
                {SubtotalTypo("--", "Order Amt")}
              </>
            )}
          </Box>
        </Grid>
      </Box>
      <Grid
        container
        rowSpacing={2}
        sx={{
          display: { xs: "flex", md: "none" },
          alignItems: "center",
          position: "sticky",
          top: "60px",
          zIndex: 1,
          background: "#fff",
          padding: "10px 20px"
        }}
      >
        <Grid
          item
          xs={12}
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={1}
        >
          <Button
            variant="link"
            disableRipple
            onClick={() => {
              navigate("/sales/quotes/create-quotes");
            }}
          >
            {quotes_labels.createquotesMobile}
          </Button>
        </Grid>
        <Grid item xs={10}>
          <DropDown
            control={control}
            name={"QO_id"}
            placeholder={"Search Order"}
            startIcon={Search}
            options={[]}
            allowSearch
            availUnselect
            unSelectText="Select Order"
            sx={{ borderRadius: 54 }}
          />
        </Grid>
        <Grid item xs={2} display={"flex"} justifyContent={"right"}>
          <IconButton
            size="small"
            sx={{
              backgroundColor: "#F0F2F5"
            }}
            onClick={() => {
              showDrawer({
                title: "Filters",
                height: "fit-content",
                component: FilterComponent
              });
            }}
          >
            <TuneOutlined color="primary" fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
      <Box sx={{ padding: { xs: "0 15px", md: 0 } }}>{quoteTable}</Box>
      {hasNextPage && (
        <Grid
          item
          xs={12}
          ref={ref}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress />
        </Grid>
      )}
    </Box>
  );
};

export default Quotes;
