import { Grid, IconButton } from "@mui/material";
import React from "react";
import { CardText } from "../../../inventory/components/SKU/MobileItemLedger";
import AddTrucks from "../../../assets/icons/AddTrucks.svg";
import { BorderColorOutlined } from "@mui/icons-material";
import { useDrawer } from "../../../shared/customSideDrawer";
import MobileAddTruck from "./MobileAddTruck";

const MobileTruckCard = (row) => {
  const { refetch } = row;
  const { showDrawer, hideDrawer } = useDrawer();

  const handleEdit = () => {
    showDrawer({
      title: "Edit Truck",
      height: "fit-content",
      component: (
        <MobileAddTruck row={row} hideDrawer={hideDrawer} refetch={refetch} />
      )
    });
  };

  return (
    <Grid container>
      <Grid
        item
        xs={3}
        sx={{ display: "flex", justifyContent: "left", alignItems: "center" }}
      >
        <img src={AddTrucks} alt="Trucks" />
      </Grid>
      <Grid
        item
        xs={6}
        container
        rowSpacing={4}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "left"
          }}
        >
          <CardText>{row.truckRegNo}</CardText>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "left"
          }}
        >
          <CardText>{row.truckMake}</CardText>
        </Grid>
      </Grid>
      <Grid
        item
        xs={3}
        container
        rowSpacing={4}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "right"
          }}
        >
          <IconButton onClick={handleEdit}>
            <BorderColorOutlined sx={{ fontSize: "18px", color: "#F9A90E" }} />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "right"
          }}
        >
          <CardText>{row.truckType}</CardText>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MobileTruckCard;
