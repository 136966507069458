import { deleteSRDraft } from "../../../sales/services";
import { useMutation } from "@tanstack/react-query";

export const useDeleteSRDraft = () => {
  const deleteDraft = useMutation(({ salesOrderId, salesReturnId }) =>
    deleteSRDraft(salesOrderId, salesReturnId)
  );

  return deleteDraft;
};
