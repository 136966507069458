import styled from "@emotion/styled/macro";
import {
  ArrowDownwardOutlined,
  ArrowUpwardOutlined
} from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

export const CardText = styled(Typography)(({ props }) => ({
  fontSize: 14,
  fontFamily: "Inter",
  lineHeight: "20px",
  ...props
}));

export const MobileItemLedger = (row) => {
  return (
    <Box display={"flex"} flexDirection={"column"} gap={4}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <CardText>{row.date}</CardText>
        <CardText>{row.code}</CardText>
        <CardText sx={{ fontWeight: 700, color: "#2773FF" }}>
          {row.endingStock}
        </CardText>
      </Box>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <CardText>{row.beginningStock}</CardText>
        <CardText>
          <Box display={"flex"} alignItems={"center"} gap={0.5}>
            {row.qtyIn}
            <ArrowDownwardOutlined
              sx={{ fontSize: "14px", color: "#F9A90E" }}
            />
          </Box>
        </CardText>
        <CardText>
          <Box display={"flex"} alignItems={"center"} gap={0.5}>
            {row.qtyOut}
            <ArrowUpwardOutlined sx={{ fontSize: "14px", color: "#46BD7D" }} />
          </Box>
        </CardText>
        <CardText sx={{ fontWeight: 700, color: "#BBCADF" }}>
          {row.qtySalesOrders}
        </CardText>
      </Box>
    </Box>
  );
};
