import { Box, Grid } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import BillingAddress from "../components/Customers/BillingAddress";
import { CustomTabs } from "../../shared/customTab/CustomTabs";
import CustomerDetailsForm from "../components/Customers/CustomerDetailsForm";
import { useParams } from "react-router-dom";
import { useFetchBillingAddresses } from "../../query-hooks/ClientsCustomer/CustomerBillingAddress/useFetchBillingAddress";
import { useFieldArray, useForm } from "react-hook-form";

const AddCustomers = () => {
  const { control } = useForm();
  const [addAddressTab, setAddAddressTab] = useState(0);
  const handleTabChange = (index) => {
    setAddAddressTab(index);
  };
  const { customerId } = useParams();

  const { fields, append } = useFieldArray({
    control,
    name: "billings"
  });

  const { data: billingAddresses, isFetching } =
    useFetchBillingAddresses(customerId);

  const addressTabs = useCallback(
    (billingId = null) => {
      return <BillingAddress customerId={customerId} billingId={billingId} />;
    },
    [customerId]
  );

  const addExistingBillings = useCallback(
    (data) => {
      data?.length > 0 &&
        data?.forEach((item, index) => {
          append({
            label: `Address ${index + 1}`,
            Component: addressTabs(item?._id)
          });
        });
      append({
        label: `Add New`,
        Component: addressTabs(null)
      });
    },
    [append, addressTabs]
  );

  useEffect(() => {
    if (!isFetching) {
      addExistingBillings(billingAddresses?.billings);
    }
  }, [addExistingBillings, isFetching, billingAddresses?.billings]);

  const tabs = useMemo(() => fields, [fields]);

  const Billings = (
    <CustomTabs
      tabs={tabs}
      activeTab={addAddressTab}
      onChange={handleTabChange}
    />
  );

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      sx={{ gap: { xs: 0, md: 6 } }}
      paddingBottom={"70px"}
    >
      <CustomerDetailsForm customerId={customerId} />
      {Boolean(customerId) && (
        <Grid item xs={12}>
          {Billings}
        </Grid>
      )}
    </Box>
  );
};

export default AddCustomers;
