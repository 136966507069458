import { getCustomerRetrieves } from "../../customer/services";
import { queryKeys } from "../../constants/queryKeys";
import { useInfiniteQuery } from "@tanstack/react-query";

export const useFetchRetrieves = () => {
  const queryRetrieves = useInfiniteQuery(
    [queryKeys.queryFetchRetrieves],
    ({ pageParam = 1 }) => getCustomerRetrieves(pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage =
          lastPage.retrieves.length === lastPage.pagination.limit
            ? allPages.length + 1
            : undefined;

        return nextPage;
      }
    }
  );

  return queryRetrieves;
};
