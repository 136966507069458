import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { ValueLabelText } from "../../../purchase/components/Purchase/MobilePOTable";

const MobileDCTable = (row) => {
  return (
    <Grid container rowSpacing={2} padding={"10px 10px 5px 10px"}>
      <Grid item xs={8}>
        <Box display={"flex"} alignItems={"center"} gap={1}>
          {row?.image?.thumbUrl && (
            <img
              src={row?.image?.thumbUrl}
              alt={row?.brandName}
              style={{
                maxWidth: "50px",
                maxHeight: "50px"
              }}
            />
          )}
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: 600,
              textDecoration: "underline",
              color: "#2773FF",
              whiteSpace: "pre-wrap"
            }}
          >
            {row?.skuCode}
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs={4}
        display={"flex"}
        justifyContent={"right"}
        alignItems={"center"}
      >
        <ValueLabelText label={"Qty"} value={row.quantity} />
      </Grid>
      <Grid
        item
        xs={4}
        display={"flex"}
        justifyContent={"left"}
        alignItems={"center"}
      >
        <Typography
          fontSize={12}
          fontWeight={400}
          lineHeight={"14.52px"}
          color={"text.secondary"}
        >
          {row?.unit?.name}
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <ValueLabelText label={"Price"} value={row.rate} />
      </Grid>
      <Grid
        item
        xs={4}
        display={"flex"}
        justifyContent={"right"}
        alignItems={"center"}
      >
        <ValueLabelText label={"Subtotal"} value={row.amount} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid
        item
        xs={4}
        display={"flex"}
        justifyContent={"left"}
        alignItems={"center"}
      >
        <ValueLabelText
          label={row.gstType ? "CGST" : "IGST"}
          value={row.gstType ? row.cgst : row.igst}
        />
      </Grid>
      <Grid
        item
        xs={4}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        {row.gstType ? <ValueLabelText label={"SGST"} value={row.sgst} /> : ""}
      </Grid>
      <Grid
        item
        xs={4}
        display={"flex"}
        justifyContent={"right"}
        alignItems={"center"}
      >
        <ValueLabelText label={"Total"} value={row.total} />
      </Grid>
    </Grid>
  );
};

export default MobileDCTable;
