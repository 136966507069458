import {
  Box,
  Typography,
  Divider,
  AccordionSummary,
  AccordionDetails,
  Accordion
} from "@mui/material";
import styled from "@emotion/styled";
import { ExpandMore } from "@mui/icons-material";
import ContactUsSection from "../components/ContactUsSection";
import Footer from "../components/Footer";
import { contactUsFormKeys } from "../../constants/formKeys";
import { home_labels } from "../../constants";
import { useForm } from "react-hook-form";
import { useEffect } from "react";

const askedQuestions = [
  {
    question: "What does Warex offer ?",
    answer:
      "Warex hosts a SAAS based marketplace of warehouses and transporters. Customers can access our network of partnered warehouses and transporters for all their storage and logistic needs. Platform also offers inventory management and product distribution solutions."
  },
  {
    question: "What kind of customers can use the platform?",
    answer:
      "Warex aims to become a One-Stop Supply Chain Solution to all SMEs. Our Customers range from manufacturers, Distributors, Dealers, Stockists and Retailers from several industries including FMCG, Apparel, Footwear, FMCD, Packed F&B, Pharma, Publishing, Industrial goods etc."
  },
  {
    question: "Are there any charges for using the platform?",
    answer:
      "As of now, our platform is free to use as an introductory stage. We will charge a small platform fee on each transaction in future. So, we advise customers to avail this early bird offer and make transactions free of charge."
  },
  {
    question: "How do I book your services?",
    answer:
      "You can simply search for a warehouse in an area of your choice and/ or search a truck to transport your goods. You can select a partner of your choice and book the service. Once the payment is done through our payment gateway, your order is booked and the partner is at your disposal for service."
  },
  {
    question: "What if I donot like the prices listed by your partners?",
    answer:
      "Warex hosts a bidding platform where you can send RFQs (Request for Quote) to any 3 selected partners. Our partners will actively bid on your inquiry providing the best price advantage to you."
  },
  {
    question: "What is the process after order booking?",
    answer:
      "You can start using our packing note feature and prepare a detailed packing note so that you can ship, retrieve and distribute any/ all packages in future. Further you will receive continuous updates and notifications on pickup, truck/ driver assigned, material received at facility, Stored, MRR, Gatepass etc.."
  },
  {
    question: "Do you provide insurance?",
    answer:
      "We encourage our customers to insure all their goods and we will help them in finding the right insurance agent for this purpose. Some of our partners also facilitate an insurance against natural disasters, fire and theft. It is advised that we proceed with insurance on case-case basis."
  },
  {
    question: "Is my material/ goods safe with you?",
    answer:
      "We enable all our customers to find the right kind of warehouse or truck. We onboard all our partners after carefully assessing their operations and infrastructure. Partners also gave written assurance on the safety, storage and handling of your material."
  },
  {
    question: "How does the payment happen?",
    answer:
      "For transport orders, payment for the trip must happen in advance through our payment gateway. For warehouse orders, depending upon the storage duration (in months), monthly recurring payments must be made. "
  },
  {
    question: "I am looking for a service outside my city. Can you support?",
    answer:
      "At present, our services are available in both Hyderabad and Bengaluru cities while we are also working on adding more cities to the platform. "
  }
];

const StyledQuestionText = styled(Typography)(({ props, theme }) => ({
  ...props,
  color: theme.palette.text.secondary,
  lineHeight: "150%",
  letterSpacing: "-0.22px",
  textAlign: "justify"
}));

const Faqs = () => {
  const { control } = useForm({
    defaultValues: {
      [contactUsFormKeys.name]: "",
      [contactUsFormKeys.number]: "",
      [contactUsFormKeys.email]: "",
      [contactUsFormKeys.subject]: "",
      [contactUsFormKeys.description]: ""
    }
  });

  useEffect(() => {
    window.scroll({
      left: 0,
      top: 0,
      behavior: "smooth"
    });
  }, []);

  return (
    <Box>
      <Box>
        <Typography
          sx={{
            fontSize: { xs: "20px", md: "32px" },
            fontWeight: 600,
            lineHeight: "64px",
            letterSpacing: "-0.64px",
            color: "text.title",
            textAlign: "center",
            mb: {
              xs: "7px",
              md: "19px"
            }
          }}
        >
          FAQs
        </Typography>
      </Box>
      <Box
        sx={{
          marginX: {
            xs: "22px",
            md: "150px"
          },
          mb: {
            xs: 5,
            md: 20
          }
        }}
      >
        {askedQuestions.map((item, index) => (
          <Box>
            <Accordion elevation={0}>
              <AccordionSummary
                expandIcon={
                  <ExpandMore
                    sx={{
                      height: { xs: "25px", md: "30px" },
                      width: { xs: "25px", md: "30px" }
                    }}
                    color="text.secondary"
                  />
                }
              >
                <StyledQuestionText
                  key={index}
                  fontWeight={700}
                  sx={{
                    fontSize: {
                      xs: 12,
                      md: 22
                    }
                  }}
                >
                  {item.question}
                </StyledQuestionText>
              </AccordionSummary>
              <AccordionDetails sx={{ mt: { xs: -1, md: -2 } }}>
                <StyledQuestionText
                  key={index}
                  fontWeight={400}
                  sx={{
                    fontSize: {
                      xs: 12,
                      md: 22
                    }
                  }}
                >
                  {item.answer}
                </StyledQuestionText>
              </AccordionDetails>
            </Accordion>
            <Divider
              sx={{
                background: {
                  xs: "#0F2744",
                  md: "#000"
                },
                marginX: {
                  xs: 2,
                  md: 3
                }
              }}
            />
          </Box>
        ))}
      </Box>
      <ContactUsSection
        control={control}
        title={home_labels.partnerWithUsTitleText}
      />
      <Footer />
    </Box>
  );
};
export default Faqs;
