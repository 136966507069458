import { deleteQuoteDraft } from "../../../sales/services";
import { useMutation } from "@tanstack/react-query";

export const useDeleteQuoteDraft = () => {
  const deleteDraft = useMutation(({ orderId }) =>
    deleteQuoteDraft(orderId)
  );

  return deleteDraft;
};
