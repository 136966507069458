import { getPOFilters } from "../../../purchase/services";
import { queryKeys } from "../../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchPOFilters = () => {
  const queryPOFilters = useQuery(
    [queryKeys.queryFetchPOFilters],
    () => getPOFilters(),
    {
      refetchOnWindowFocus: false
    }
  );

  return queryPOFilters;
};
