import { getUpdateWarehouseFormData } from "../../inventory/services";
import { queryKeys } from "../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchUpdateWarehouseFormData = (warehouseId) => {
  const queryUpdateWarehouseFormData = useQuery(
    [queryKeys.queryFetchUpdateWarehouseFormData,warehouseId],
    () => getUpdateWarehouseFormData(warehouseId),
    {
        enabled: Boolean(warehouseId),
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchInterval: false,
      }
  );

  return queryUpdateWarehouseFormData;
};
