import { client } from "../../shared/api/client";

export const getVendorFilters = async () => {
  return await client
    .get(`/clients/vendors/filters`)
    .then((res) => res.data.data);
};

export const getVendorsSubtotal = async (params) => {
  return await client
    .get(`/clients/vendors/sub-totals`, { params: { ...params } })
    .then((res) => res.data.data);
};

export const getVendors = async (params, page = 1) => {
  return await client
    .get(`/clients/vendors`, { params: { ...params, page: page } })
    .then((res) => res.data.data);
};

export const getVendorById = async (vendorId) => {
  return await client
    .get(`/clients/vendors/${vendorId}`)
    .then((res) => res.data.data);
};

export const getVendorFormData = async () => {
  return await client
    .get("/clients/vendors/form-data")
    .then((res) => res.data.data);
};

export const getUpdateVendorFormData = async (vendorId) => {
  return await client
    .get(`/clients/vendors/${vendorId}/form-data`)
    .then((res) => res.data.data);
};

export const createVendor = async (data) => {
  return await client.post(`/clients/vendors`, data);
};

export const updateVendor = async (data, vendorId) => {
  return await client.put(`/clients/vendors/${vendorId}`, data);
};

export const getPOFilters = async () => {
  return await client
    .get(`/clients/purchase-orders/filters`)
    .then((res) => res.data.data);
};

export const getPOSubtotal = async (params) => {
  return await client
    .get(`/clients/purchase-orders/sub-totals`, { params: { ...params } })
    .then((res) => res.data.data);
};

export const getPurchaseOrders = async (params, page = 1) => {
  return await client
    .get(`/clients/purchase-orders`, { params: { ...params, page: page } })
    .then((res) => res.data.data);
};

export const getPurchaseOrderById = async (purchaseOrderId) => {
  return await client
    .get(`/clients/purchase-orders/${purchaseOrderId}`)
    .then((res) => res.data.data);
};

export const getPOTransactions = async (orderId) => {
  return await client
    .get(`/clients/purchase-orders/${orderId}/transactions`)
    .then((res) => res.data.data);
};

export const getPurchaseOrderFormData = async () => {
  return await client
    .get("/clients/purchase-orders/form-data")
    .then((res) => res.data.data);
};

export const getUpdatePurchaseOrderFormData = async (purchaseOrderId) => {
  return await client
    .get(`/clients/purchase-orders/${purchaseOrderId}/form-data`)
    .then((res) => res.data.data);
};

export const saveDraftPurchaseOrder = async (data) => {
  return await client.post(`/clients/purchase-orders/save`, data);
};

export const createPurchaseOrder = async (data) => {
  return await client.post(`/clients/purchase-orders`, data);
};

export const updatePurchaseOrderDraft = async (data, purchaseOrderId) => {
  return await client.put(
    `/clients/purchase-orders/${purchaseOrderId}/save`,
    data
  );
};

export const updatePurchaseOrder = async (data, purchaseOrderId) => {
  return await client.put(`/clients/purchase-orders/${purchaseOrderId}`, data);
};

export const cancelPurchaseOrder = async (purchaseOrderId) => {
  return await client.put(
    `/clients/purchase-orders/${purchaseOrderId}/cancel`,
    {}
  );
};

export const submitPurchaseOrderDraft = async (data, purchaseOrderId) => {
  return await client.put(
    `/clients/purchase-orders/${purchaseOrderId}/submit`,
    data
  );
};

export const deletePODraft = async (purchaseOrderId) => {
  return await client.delete(`/clients/purchase-orders/${purchaseOrderId}`);
};

export const getPOListFormData = async () => {
  return await client
    .get(`/clients/purchase-orders/returns/form-data`)
    .then((res) => res.data.data);
};

export const getPRById = async (orderId, returnId) => {
  return await client
    .get(`clients/purchase-orders/${orderId}/returns/${returnId}`)
    .then((res) => res.data.data);
};

export const getPurchaseReturnFormData = async (orderId) => {
  return await client
    .get(`/clients/purchase-orders/${orderId}/returns/form-data`)
    .then((res) => res.data.data);
};

export const getUpdatePurchaseReturnFormData = async (orderId, returnId) => {
  return await client
    .get(`/clients/purchase-orders/${orderId}/returns/${returnId}/form-data`)
    .then((res) => res.data.data);
};

export const saveDraftPurchaseReturn = async (data, orderId) => {
  return await client.post(
    `/clients/purchase-orders/${orderId}/returns/save`,
    data
  );
};

export const createPurchaseReturn = async (data, orderId) => {
  return await client.post(`/clients/purchase-orders/${orderId}/returns`, data);
};

export const updatePurchaseReturn = async (data, orderId, returnId) => {
  return await client.put(
    `/clients/purchase-orders/${orderId}/returns/${returnId}`,
    data
  );
};

export const cancelPurchaseReturn = async (orderId, returnId) => {
  return await client.put(
    `/clients/purchase-orders/${orderId}/returns/${returnId}/cancel`,
    {}
  );
};

export const updatePurchaseReturnDraft = async (
  data,
  purchaseOrderId,
  purchaseReturnId
) => {
  return await client.put(
    `/clients/purchase-orders/${purchaseOrderId}/returns/${purchaseReturnId}/save`,
    data
  );
};

export const submitPurchaseReturnDraft = async (
  data,
  purchaseOrderId,
  purchaseReturnId
) => {
  return await client.put(
    `/clients/purchase-orders/${purchaseOrderId}/returns/${purchaseReturnId}/submit`,
    data
  );
};

export const deletePRDraft = async (purchaseOrderId, purchaseReturnId) => {
  return await client.delete(
    `/clients/purchase-orders/${purchaseOrderId}/returns/${purchaseReturnId}`
  );
};

export const getStockTransfers = async (page = 1) => {
  return await client
    .get(`/clients/stock-transfers?page=${page}`)
    .then((res) => res.data.data);
};

export const getStockTransferById = async (orderId) => {
  return await client
    .get(`/clients/stock-transfers/${orderId}`)
    .then((res) => res.data.data);
};

export const getStockTransferFormData = async () => {
  return await client
    .get("/clients/stock-transfers/form-data")
    .then((res) => res.data.data);
};

export const getStockTransferSKUs = async (params) => {
  return await client
    .get("/clients/stock-transfers/form-data", {
      params: { ...params }
    })
    .then((res) => res.data.data);
};

export const getUpdateStockTransferFormData = async (orderId) => {
  return await client
    .get(`/clients/stock-transfers/${orderId}/form-data`)
    .then((res) => res.data.data);
};

export const saveDraftStockTransfer = async (data) => {
  return await client.post(`/clients/stock-transfers/save`, data);
};

export const createStockTransfer = async (data) => {
  return await client.post(`/clients/stock-transfers`, data);
};

export const updateStockTransferDraft = async (data, orderId) => {
  return await client.put(`/clients/stock-transfers/${orderId}/save`, data);
};

export const submitStockTransferDraft = async (data, orderId) => {
  return await client.put(`/clients/stock-transfers/${orderId}/submit`, data);
};

export const deleteSTDraft = async (orderId) => {
  return await client.delete(`/clients/stock-transfers/${orderId}`);
};

export const cancelStockTransfer = async (orderId) => {
  return await client.put(`/clients/stock-transfers/${orderId}/cancel`, {});
};

export const getStockAdjustments = async (page = 1) => {
  return await client
    .get(`/clients/stock-adjustments?page=${page}`)
    .then((res) => res.data.data);
};

export const getStockAdjustmentSKUs = async (params) => {
  return await client
    .get("/clients/stock-adjustments/form-data", {
      params: { ...params }
    })
    .then((res) => res.data.data);
};

export const getStockAdjustmentById = async (orderId) => {
  return await client
    .get(`/clients/stock-adjustments/${orderId}`)
    .then((res) => res.data.data);
};

export const getStockAdjustmentFormData = async () => {
  return await client
    .get("/clients/stock-adjustments/form-data")
    .then((res) => res.data.data);
};

export const getUpdateStockAdjustmentFormData = async (orderId) => {
  return await client
    .get(`/clients/stock-adjustments/${orderId}/form-data`)
    .then((res) => res.data.data);
};

export const saveDraftStockAdjustment = async (data) => {
  return await client.post(`/clients/stock-adjustments/save`, data);
};

export const createStockAdjustment = async (data) => {
  return await client.post(`/clients/stock-adjustments`, data);
};

export const updateStockAdjustmentDraft = async (data, orderId) => {
  return await client.put(`/clients/stock-adjustments/${orderId}/save`, data);
};

export const submitStockAdjustmentDraft = async (data, orderId) => {
  return await client.put(`/clients/stock-adjustments/${orderId}/submit`, data);
};

export const deleteSADraft = async (orderId) => {
  return await client.delete(`/clients/stock-adjustments/${orderId}`);
};

export const cancelStockAdjustment = async (orderId) => {
  return await client.put(`/clients/stock-adjustments/${orderId}/cancel`, {});
};

export const approveStockAdjustment = async (orderId) => {
  return await client.put(`/clients/stock-adjustments/${orderId}/approve`, {});
};
