import { Box, Button, Typography } from "@mui/material";

import { CalendarTodayOutlined, CheckCircle } from "@mui/icons-material";
import { useState } from "react";
import DateInput from "../../../shared/formElements/DateInput";
import TextInput from "../../../shared/formElements/TextInput";
import { currencyOnlyFormatter } from "../../../shared/utils";
import moment from "moment";

const RecordTransaction = ({ row, text, hideDialog, submit, control }) => {
  const [onEdit, setOnEdit] = useState(true);
  const onEditClick = () => {
    setOnEdit((prev) => !prev);
  };
  return (
    <Box
      padding={1}
      display={"flex"}
      flexDirection={"column"}
      gap={3}
      textAlign={"center"}
      alignItems={"center"}
    >
      <CheckCircle
        color="success"
        sx={{
          height: 60,
          width: 60
        }}
      />
      <Typography
        fontSize={16}
        lineHeight={"20px"}
        fontWeight={400}
        letterSpacing={"0.002em"}
      >
        {text}
      </Typography>
      <Box
        display={"flex"}
        gap={"10px"}
        width={"100%"}
        justifyContent={"space-around"}
      >
        <Typography>{moment(row?.docDate).format("DD-MM-YYYY")}</Typography>
        <Typography
          fontSize={14}
          color={"primary"}
          sx={{ textDecoration: "underline", cursor: "pointer" }}
        >
          {row?.code || row?.customCode}
        </Typography>
        <Typography>
          {currencyOnlyFormatter(parseFloat(row?.totalAmount).toFixed(2))}
        </Typography>
      </Box>
      <Box display={"flex"} gap={"10px"} width={"100%"}>
        <DateInput
          control={control}
          name={"date"}
          placeholder={"Pick Date"}
          endIcon={CalendarTodayOutlined}
          minDate={"2000-01-01"}
          disabled={onEdit}
          popperPlacement="right"
        />
        <TextInput
          control={control}
          name={"utr"}
          placeholder={"UTR: xxxx"}
          disabled={onEdit}
        />
        <TextInput
          control={control}
          name={"amount"}
          placeholder={"xx,xxx"}
          disabled={onEdit}
        />
      </Box>
      <Box display={"flex"} gap={"10px"} width={"100%"}>
        {onEdit ? (
          <Button
            fullWidth
            variant="contained"
            sx={{
              height: { xs: 48, md: 64 },
              fontWeight: 500,
              fontSize: 15,
              marginTop: "20px",
              whiteSpace: "nowrap"
            }}
            onClick={onEditClick}
          >
            {"Edit"}
          </Button>
        ) : (
          <>
            <Button
              fullWidth
              variant="soft"
              sx={{
                height: { xs: 48, md: 64 },
                fontWeight: 500,
                fontSize: 15,
                marginTop: "20px",
                whiteSpace: "nowrap"
              }}
              onClick={hideDialog}
            >
              {"Cancel"}
            </Button>
            <Button
              fullWidth
              variant="contained"
              sx={{
                height: { xs: 48, md: 64 },
                fontWeight: 500,
                fontSize: 15,
                marginTop: "20px",
                whiteSpace: "nowrap"
              }}
              onClick={submit}
            >
              {"Save"}
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};

export default RecordTransaction;
