export const initGA = () => {
  const trackingId = process.env.REACT_APP_GA_TRACKING_ID;

  if (!trackingId) {
    console.error("Google Analytics Tracking ID not found");
    return;
  }

  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }

  gtag("js", new Date());
  gtag("config", trackingId);
};

// Load the Google Analytics script
export const loadGAScript = () => {
  const script = document.createElement("script");
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_TRACKING_ID}`;
  document.head.appendChild(script);
};
