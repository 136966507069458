import { getOrderDetails } from "../../customer/services";
import { queryKeys } from "../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useGetOrderDetails = (orderId) => {
  const queryOrderDetails = useQuery(
    [queryKeys.queryFetchOrderDetails, orderId],
    () => getOrderDetails(orderId),
    {
      enabled: Boolean(orderId),
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true
    }
  );

  return queryOrderDetails;
};
