import { Box } from "@mui/material";
import React from "react";
import AssetsMobile from "./AssetsMobile";
import Assets from "./Assets";

const AssetsPage = () => {
  return (
    <>
      <Box sx={{ display: { xs: "none", lg: "block" } }}>
        <Assets />
      </Box>
      <Box sx={{ display: { xs: "block", lg: "none" } }}>
        <AssetsMobile />
      </Box>
    </>
  );
};

export default AssetsPage;
