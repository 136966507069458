import { Box, Button, Grid, Typography } from "@mui/material";

import { CheckCircle } from "@mui/icons-material";
import CustomTable from "../../../shared/customTable";
import TextInput from "../../../shared/formElements/TextInput";
import { useFieldArray, useForm } from "react-hook-form";
import { useEffect } from "react";
import { CardText } from "./MobileItemLedger";

const getColumns = () => {
  return [
    {
      Header: "WH-Code",
      accessor: "code",
      disableSortBy: true
    },
    {
      Header: "Quantity",
      accessor: "quantity",
      disableSortBy: true
    }
  ];
};

const getRows = (rows = [], control, isEditEnabled) => {
  return rows.map((item, index) => {
    return {
      whId: item._id,
      code: `${item.code}`,
      quantity: (
        <TextInput
          control={control}
          name={`WHStock.${index}.quantity`}
          disabled={isEditEnabled}
        />
      )
    };
  });
};

const MobileWarehouse = (row) => {
  return (
    <Grid container columnSpacing={1} display={"flex"} alignItems={"center"}>
      <Grid item xs={7}>
        <CardText>{row.code}</CardText>
      </Grid>
      <Grid item xs={5}>
        {row.quantity}
      </Grid>
    </Grid>
  );
};

const StockLevelByWarehouseForm = ({
  beginningStock,
  setBeginningStock,
  hideDialog,
  isEditEnabled,
  warehouses
}) => {
  const { control, handleSubmit } = useForm();

  const { fields, append } = useFieldArray({
    control,
    name: "WHStock"
  });

  useEffect(() => {
    if (Boolean(warehouses)) {
      warehouses?.forEach((item, index) => {
        append({
          _id: item._id,
          quantity: beginningStock?.length
            ? (beginningStock.find((obj) => obj[`_id`] === item._id) || {})
                .quantity ?? 0
            : 0,
          code: item.code
        });
      });
    }
  }, [warehouses, append, beginningStock]);

  const onAddClick = (data) => {
    const stocks = data["WHStock"]?.filter((item) => +item.quantity !== 0);
    setBeginningStock(stocks);
    hideDialog();
  };
  return (
    <Box
      padding={1}
      display={"flex"}
      flexDirection={"column"}
      gap={"12px"}
      textAlign={"center"}
      alignItems={"center"}
    >
      <CheckCircle
        color="success"
        sx={{
          display: { xs: "none", md: "flex" },
          height: 60,
          width: 60
        }}
      />
      <Typography
        sx={{
          fontSize: { xs: 16, md: 20 },
          lineHeight: { xs: "19.36px", md: "24px" },
          fontWeight: 500,
          letterSpacing: "0.002em"
        }}
      >
        {"Add Beginning Stock in each of your warehouses."}
      </Typography>
      <Grid
        container
        sx={{
          height: "50px",
          display: { xs: "flex", md: "none" },
          alignItems: "center",
          background: "#BBCADF"
        }}
      >
        <Grid item xs={7}>
          <CardText sx={{ fontWeight: 600 }}>{"WH-Code"}</CardText>
        </Grid>
        <Grid item xs={5}>
          <CardText sx={{ fontWeight: 600 }}>{"Quantity"}</CardText>
        </Grid>
      </Grid>
      <CustomTable
        columns={getColumns()}
        data={warehouses ? getRows(fields, control, isEditEnabled) ?? [] : []}
        mobileComponent={MobileWarehouse}
      />
      {!isEditEnabled && (
        <Box
          width={"100%"}
          sx={{
            display: "flex",
            background: { xs: "#F0F2F5", md: "unset" },
            justifyContent: "center",
            alignItems: "center",
            minHeight: "72px"
          }}
        >
          <Button
            variant="contained"
            sx={{ width: "80px", height: "40px" }}
            onClick={handleSubmit(onAddClick)}
          >
            {"Add"}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default StockLevelByWarehouseForm;
