import { Drawer, IconButton, Toolbar, createTheme } from "@mui/material";

import { styled } from "@mui/material/styles";

export const headerTheme = createTheme({
  palette: {
    text: {
      primary: "#0F2744",
      secondary: "#607088",
      disabled: "#95A1B0"
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: "#FFFFFF",
          boxShadow: "0px 8px 24px rgba(21, 21, 22, 0.04)",
          fontSize: "20px"
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: "60px",
          "@media (min-width: 600px)": {
            minHeight: "72px"
          }
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          height: "34px",
          width: "34px",
          border: "1px solid #E6EAEE",
          cursor: "pointer",
          background: "#FFFFFF",
          "& > img": {
            height: "auto",
            width: "auto"
          },
          "@media (min-width: 600px)": {
            height: "38px",
            width: "38px"
          }
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: "20px",
          color: theme.palette.text.secondary
        })
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow: "0px 2px 5px rgba(1, 16, 34, 0.1)",
          borderRadius: "8px"
        },
        list: {
          padding: "12px"
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          "& > p": {
            fontSize: 14,
            lineHeight: "21px",
            color: "#0F0F0F"
          }
        }
      }
    }
  }
});

export const StyledToolBar = styled(({ token, ...props }) => (
  <Toolbar
    {...props}
    sx={{
      display: "flex"
    }}
  />
))(({ token, theme }) => ({
  [theme.breakpoints.up("sm")]: {
    "& > .MuiTypography-root": {
      display: token ? "none" : "block"
    },
    "& > .MuiBox-root:first-of-type": {
      display: "flex",
      flexGrow: 1,
      justifySelf: "end"
    }
  }
}));

export const StyledMobileIconButton = styled(IconButton)(() => ({
  height: "34px",
  width: "34px",
  border: "none",
  cursor: "pointer",
  background: "transparent"
}));

export const StyledMobileDrawer = styled((props) => (
  <Drawer {...props} elevation={0} hideBackdrop anchor={"right"} />
))(({ theme }) => ({
  "& > .MuiDrawer-paper": {
    padding: "28px 25px",
    backgroundColor: theme.palette.text.primary,
    width: 282,
    opacity: 0.98
  }
}));
