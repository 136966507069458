import {
  fetchWarehouseSearchFormData,
  fetchWarehouseSearchFormDataPublic
} from "../../shared/api/searchFormData";

import { AppContext } from "../../shared/context/auth-context";
import { queryKeys } from "../../constants/queryKeys";
import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";

export const useWarehouseSearchFormDataHook = (isEnabled = true) => {
  const { currentUser } = useContext(AppContext);
  const searchFormDataQuery = useQuery(
    [queryKeys.queryFetchWarehouseSearchFormData],
    currentUser
      ? () => fetchWarehouseSearchFormData()
      : fetchWarehouseSearchFormDataPublic,
    {
      initialData: {
        materialCategories: [],
        packagingTypes: [],
        packageDimensions: [],
        storageTypes: [],
        additionalServices: []
      },
      refetchOnWindowFocus: false,
      enabled: Boolean(isEnabled)
    }
  );

  return searchFormDataQuery;
};
