import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { PrimaryText, SecondaryText } from "../../inventory/pages/Performance";
import React, { useMemo, useState } from "react";

import BrandLogo from "../../assets/bru.webp";
import CustomTable from "../../shared/customTable";
import { CustomTabs } from "../../shared/customTab/CustomTabs";
import { Link } from "react-router-dom";
import OrderReport from "../../purchase/components/OrderReport";
import TextArea from "../../shared/formElements/TextArea";
import { purchase_labels } from "../../constants";
import { BorderColorOutlined } from "@mui/icons-material";
import {
  TotalBreakup,
  reportLabel1,
  reportLabel2
} from "../../purchase/pages/CreateOrder";

const getColumns = () => {
  return [
    {
      Header: "S.No",
      accessor: "sNo",
      width: 90
    },
    {
      Header: "Item/ SKU",
      accessor: "item",
      width: 200,
      Cell: ({ row, value }) => {
        return row.original.productCode ? (
          <Box display={"flex"} flexDirection={"column"} gap={1}>
            <Box display={"flex"} gap={1} alignItems={"center"}>
              <img
                src={BrandLogo}
                alt={row.original.brand}
                height={52}
                width={50}
                style={{ objectFit: "contain" }}
              />
              <Typography
                fontSize={12}
                fontWeight={600}
                color={"primary"}
                component={Link}
              >
                {row.original.productCode}
              </Typography>
            </Box>
            <Typography fontSize={13} color={"text.secondary"}>
              {`HSN : ${row.original.hsnCode}`}
            </Typography>
          </Box>
        ) : (
          value
        );
      }
    },
    {
      Header: "Product",
      accessor: "product",
      width: 156,
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          row.original.product && (
            <Box display={"flex"} flexDirection={"column"}>
              <PrimaryText>{`Product: ${row.original.product}`}</PrimaryText>
              <SecondaryText>{`Category: ${row.original.category}`}</SecondaryText>
              <SecondaryText>{`Brand: ${row.original.brand}`}</SecondaryText>
            </Box>
          )
        );
      }
    },
    {
      Header: "UOM",
      accessor: "uom",
      width: 72,
      disableSortBy: true
    },
    {
      Header: "List Price",
      accessor: "listPrice",
      width: 96,
      disableSortBy: true
    },
    {
      Header: "Quantity",
      accessor: "quantity",
      width: 120,
      disableSortBy: true
    },
    {
      Header: "Disc.",
      accessor: "disc",
      width: 120,
      disableSortBy: true
    },
    {
      Header: "Off. Price",
      accessor: "offPrice",
      width: 96,
      disableSortBy: true,
      Cell: ({ value }) => {
        return (
          value && (
            <PrimaryText>{`${parseFloat(value).toFixed(2)}`}</PrimaryText>
          )
        );
      }
    },
    {
      Header: "Amount",
      accessor: "amount",
      width: 85,
      disableSortBy: true,
      Cell: ({ value }) => {
        return (
          value && (
            <PrimaryText>{`${parseFloat(value).toFixed(2)}`}</PrimaryText>
          )
        );
      }
    },
    {
      Header: "CGST",
      accessor: "cgst",
      width: 62,
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          row.original.cgst && (
            <Box display={"flex"} flexDirection={"column"} gap={2}>
              <PrimaryText>
                {parseFloat(row.original.cgst).toFixed(2)}
              </PrimaryText>
              <SecondaryText>{`(${row.original.cgstPer}%)`}</SecondaryText>
            </Box>
          )
        );
      }
    },
    {
      Header: "SGST",
      accessor: "sgst",
      width: 66,
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          row.original.sgst && (
            <Box display={"flex"} flexDirection={"column"} gap={2}>
              <PrimaryText>
                {parseFloat(row.original.sgst).toFixed(2)}
              </PrimaryText>
              <SecondaryText>{`(${row.original.sgstPer}%)`}</SecondaryText>
            </Box>
          )
        );
      }
    },
    {
      Header: "IGST",
      accessor: "igst",
      width: 60,
      disableSortBy: true
    },
    {
      Header: "Total",
      accessor: "total",
      width: 105,
      disableSortBy: true,
      Cell: ({ value }) => {
        return (
          value && (
            <PrimaryText>{`${parseFloat(value).toFixed(2)}`}</PrimaryText>
          )
        );
      }
    }
  ];
};

const getRows = (rows) => {
  return rows.map((row, index) => ({
    sNo: index + 1,
    ...row
  }));
};

const CreditNoteTab = ({ fields, control }) => {
  const [creditNoteTab, setDebitNoteTab] = useState(0);
  const handleTabChange = (index) => {
    setDebitNoteTab(index);
  };

  const CreditNote = useMemo(() => <>CN-2</>, []);

  const creditNoteFeild = useMemo(
    () => (
      <OrderReport
        title={"Credit Note"}
        buyerTitle={"Buyer"}
        shippingTitle={"Shipto"}
        sellerTitle={"Seller"}
        orderInfo={{
          creditNoteNo: "123456",
          cnDate: "17-03-2023",
          invoiceNo: "123456",
          invoiceDate: "17-03-2023"
        }}
        sellerInfo={{
          customerName: "Raunak Agarwal",
          companyName: "Razorpay India Pvt Ltd",
          address:
            "SJR Cyber Laskar, Hosur Rd, Adugodi, Bengaluru, Karnataka 560030",
          gst: "GSTIN : 36axsdtygv1z"
        }}
        buyerInfo={{
          customerName: "Raunak Agarwal",
          companyName: "Razorpay India Pvt Ltd",
          address:
            "SJR Cyber Laskar, Hosur Rd, Adugodi, Bengaluru, Karnataka 560030",
          gst: "GSTIN : 36axsdtygv1z"
        }}
        shipInfo={{
          customerName: "Raunak Agarwal",
          companyName: "Razorpay India Pvt Ltd",
          address:
            "SJR Cyber Laskar, Hosur Rd, Adugodi, Bengaluru, Karnataka 560030"
        }}
      >
        <CustomTable columns={getColumns()} data={getRows(fields)} />
        <Grid container spacing={2}>
          <Grid item xs={12} lg={9}>
            <Box display={"flex"} flexDirection={"column"} gap={3}>
              <Box display={"flex"} gap={5}>
                {reportLabel1(
                  purchase_labels.paymentTermsText,
                  "Due 30 days from receipt"
                )}
                {reportLabel1(purchase_labels.shipping, "Extra")}
              </Box>
              <Box maxWidth={516}>
                <TextArea
                  control={control}
                  name={"otherItemsAndConditionsLabel"}
                  label={purchase_labels.otherItemsAndConditionsLabel}
                />
              </Box>
              <Box display={"flex"} flexDirection={"column"} gap={2.5}>
                {reportLabel2(
                  purchase_labels.amountInWordsLabel,
                  "Rupees Three Thousands Only"
                )}
                {reportLabel2(purchase_labels.preparedByLabel, "Ajay Kumar")}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3}>
            <TotalBreakup subTotal={2500} cgst={500} sgst={500} total={3000} />
          </Grid>
        </Grid>
      </OrderReport>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const DUMMY_ITEMS = [
    {
      label: `CN-1`,
      Component: creditNoteFeild
    },
    {
      label: `CN-2`,
      Component: CreditNote
    }
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const tabs = useMemo(() => DUMMY_ITEMS, [CreditNote, creditNoteFeild]);

  return (
    <CustomTabs
      tabs={tabs}
      activeTab={creditNoteTab}
      onChange={handleTabChange}
      headerControls={
        <Box display={"flex"} justifyContent={"flex-end"} gap={4}>
          <IconButton>
            <BorderColorOutlined color="warning" />
          </IconButton>
          <Box display={"flex"} gap={2}>
            <Button variant="link" disableRipple sx={{ width: "78px" }}>
              Save Draft
            </Button>
            <Button variant="link" disableRipple>
              Submit
            </Button>
          </Box>
        </Box>
      }
    />
  );
};

export default CreditNoteTab;
