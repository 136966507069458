import { client } from "../../shared/api/client";

export const getCustomerFilters = async () => {
  return await client
    .get(`/clients/customers/filters`)
    .then((res) => res.data.data);
};

export const getCustomersSubtotal = async (params) => {
  return await client
    .get(`/clients/customers/sub-totals`, { params: { ...params } })
    .then((res) => res.data.data);
};

export const getCustomers = async (params, page = 1) => {
  return await client
    .get(`/clients/customers`, { params: { ...params, page: page } })
    .then((res) => res.data.data);
};

export const getCustomerById = async (customerId) => {
  return await client
    .get(`/clients/customers/${customerId}`)
    .then((res) => res.data.data);
};

export const getCustomerFormData = async () => {
  return await client
    .get("/clients/customers/form-data")
    .then((res) => res.data.data);
};

export const getUpdateCustomerFormData = async (customerId) => {
  return await client
    .get(`/clients/customers/${customerId}/form-data`)
    .then((res) => res.data.data);
};

export const createCustomer = async (data) => {
  return await client.post(`/clients/customers`, data);
};

export const updateCustomer = async (data, customerId) => {
  return await client.put(`/clients/customers/${customerId}`, data);
};

export const getBillingAddresses = async (customerId) => {
  return await client
    .get(`/clients/customers/${customerId}/billings`)
    .then((res) => res.data.data);
};

export const getBillingById = async (customerId, billingId) => {
  return await client
    .get(`/clients/customers/${customerId}/billings/${billingId}`)
    .then((res) => res.data.data);
};

export const getBillingFormData = async (customerId) => {
  return await client
    .get(`/clients/customers/${customerId}/billings/form-data`)
    .then((res) => res.data.data);
};

export const getUpdateBillingFormData = async (customerId, billingId) => {
  return await client
    .get(`/clients/customers/${customerId}/billings/${billingId}/form-data`)
    .then((res) => res.data.data);
};

export const createBillingAddress = async (data, customerId) => {
  return await client.post(`/clients/customers/${customerId}/billings`, data);
};

export const updateBilling = async (data, customerId, billingId) => {
  return await client.put(
    `/clients/customers/${customerId}/billings/${billingId}`,
    data
  );
};

export const getStores = async (customerId, billingId) => {
  return await client
    .get(`/clients/customers/${customerId}/billings/${billingId}/stores`)
    .then((res) => res.data.data);
};

export const getStoreById = async (customerId, billingId, storeId) => {
  return await client
    .get(
      `/clients/customers/${customerId}/billings/${billingId}/stores/${storeId}`
    )
    .then((res) => res.data.data);
};

export const getStoreFormData = async (customerId, billingId) => {
  return await client
    .get(
      `/clients/customers/${customerId}/billings/${billingId}/stores/form-data`
    )
    .then((res) => res.data.data);
};

export const getUpdateStoreFormData = async (
  customerId,
  billingId,
  storeId
) => {
  return await client
    .get(
      `/clients/customers/${customerId}/billings/${billingId}/stores/${storeId}/form-data`
    )
    .then((res) => res.data.data);
};

export const createStore = async (data, customerId, billingId) => {
  return await client.post(
    `/clients/customers/${customerId}/billings/${billingId}/stores`,
    data
  );
};

export const updateStore = async (data, customerId, billingId, storeId) => {
  return await client.put(
    `/clients/customers/${customerId}/billings/${billingId}/stores/${storeId}`,
    data
  );
};

export const getSalesPersons = async () => {
  return await client
    .get(`/clients/sales-persons`)
    .then((res) => res.data.data);
};

export const createSalesPerson = async (data) => {
  return await client.post(`/clients/sales-persons`, data);
};

export const updateSalesPerson = async (data, salesPersonId) => {
  return await client.put(`/clients/sales-persons/${salesPersonId}`, data);
};

export const getSOFilters = async () => {
  return await client
    .get(`/clients/sale-orders/filters`)
    .then((res) => res.data.data);
};

export const getSOSubtotal = async (params) => {
  return await client
    .get(`/clients/sale-orders/sub-totals`, { params: { ...params } })
    .then((res) => res.data.data);
};

export const getSalesOrders = async (params, page = 1) => {
  return await client
    .get(`/clients/sale-orders`, { params: { ...params, page: page } })
    .then((res) => res.data.data);
};

export const getSalesOrderById = async (salesOrderId) => {
  return await client
    .get(`/clients/sale-orders/${salesOrderId}`)
    .then((res) => res.data.data);
};

export const getSOTransactions = async (orderId) => {
  return await client
    .get(`/clients/sale-orders/${orderId}/transactions`)
    .then((res) => res.data.data);
};

export const getSalesOrderFormData = async () => {
  return await client
    .get(`/clients/sale-orders/form-data`)
    .then((res) => res.data.data);
};

export const getCustomerBillingByCustomerId = async (customerId) => {
  return await client
    .get(`/clients/sale-orders/form-data?customerId=${customerId}`)
    .then((res) => res.data.data);
};

export const getUpdateSalesOrderFormData = async (salesOrderId) => {
  return await client
    .get(`/clients/sale-orders/${salesOrderId}/form-data`)
    .then((res) => res.data.data);
};

export const saveDraftSalesOrder = async (data) => {
  return await client.post(`/clients/sale-orders/save`, data);
};

export const createSalesOrder = async (data) => {
  return await client.post(`/clients/sale-orders`, data);
};

export const updateSalesOrder = async (data, orderId) => {
  return await client.put(`/clients/sale-orders/${orderId}`, data);
};

export const cancelSalesOrder = async (orderId) => {
  return await client.put(`/clients/sale-orders/${orderId}/cancel`, {});
};

export const updateSalesOrderDraft = async (data, salesOrderId) => {
  return await client.put(`/clients/sale-orders/${salesOrderId}/save`, data);
};

export const submitSalesOrderDraft = async (data, salesOrderId) => {
  return await client.put(`/clients/sale-orders/${salesOrderId}/submit`, data);
};

export const deleteSODraft = async (salesOrderId) => {
  return await client.delete(`/clients/sale-orders/${salesOrderId}`);
};

export const getSOListFormData = async () => {
  return await client
    .get(`/clients/sale-orders/returns/form-data`)
    .then((res) => res.data.data);
};

export const getSRById = async (orderId, returnId) => {
  return await client
    .get(`clients/sale-orders/${orderId}/returns/${returnId}`)
    .then((res) => res.data.data);
};

export const getSalesReturnFormData = async (orderId) => {
  return await client
    .get(`/clients/sale-orders/${orderId}/returns/form-data`)
    .then((res) => res.data.data);
};

export const getUpdateSalesReturnFormData = async (orderId, returnId) => {
  return await client
    .get(`/clients/sale-orders/${orderId}/returns/${returnId}/form-data`)
    .then((res) => res.data.data);
};

export const saveDraftSalesReturn = async (data, orderId) => {
  return await client.post(
    `/clients/sale-orders/${orderId}/returns/save`,
    data
  );
};

export const createSalesReturn = async (data, orderId) => {
  return await client.post(`/clients/sale-orders/${orderId}/returns`, data);
};

export const updateSalesReturn = async (data, orderId, returnId) => {
  return await client.put(
    `/clients/sale-orders/${orderId}/returns/${returnId}`,
    data
  );
};

export const cancelSalesReturn = async (orderId, returnId) => {
  return await client.put(
    `/clients/sale-orders/${orderId}/returns/${returnId}/cancel`,
    {}
  );
};

export const updateSalesReturnDraft = async (
  data,
  salesOrderId,
  salesReturnId
) => {
  return await client.put(
    `/clients/sale-orders/${salesOrderId}/returns/${salesReturnId}/save`,
    data
  );
};

export const submitSalesReturnDraft = async (
  data,
  salesOrderId,
  salesReturnId
) => {
  return await client.put(
    `/clients/sale-orders/${salesOrderId}/returns/${salesReturnId}/submit`,
    data
  );
};

export const deleteSRDraft = async (salesOrderId, salesReturnId) => {
  return await client.delete(
    `/clients/sale-orders/${salesOrderId}/returns/${salesReturnId}`
  );
};

export const getQuotesSubtotal = async (params) => {
  return await client
    .get(`/clients/sale-quotations/sub-totals`, { params: { ...params } })
    .then((res) => res.data.data);
};

export const getQuotesFilters = async () => {
  return await client
    .get(`/clients/sale-quotations/filters`)
    .then((res) => res.data.data);
};

export const getQuotes = async (params, page = 1) => {
  return await client
    .get(`/clients/sale-quotations`, { params: { ...params, page: page } })
    .then((res) => res.data.data);
};

export const getQuoteById = async (orderId) => {
  return await client
    .get(`/clients/sale-quotations/${orderId}`)
    .then((res) => res.data.data);
};

export const getQuoteFormData = async () => {
  return await client
    .get(`/clients/sale-quotations/form-data`)
    .then((res) => res.data.data);
};

export const getQuoteCustomerBillingByCustomerId = async (customerId) => {
  return await client
    .get(`/clients/sale-quotations/form-data?customerId=${customerId}`)
    .then((res) => res.data.data);
};

export const getUpdateQuoteFormData = async (orderId) => {
  return await client
    .get(`/clients/sale-quotations/${orderId}/form-data`)
    .then((res) => res.data.data);
};

export const saveDraftQuote = async (data) => {
  return await client.post(`/clients/sale-quotations/save`, data);
};

export const createQuote = async (data) => {
  return await client.post(`/clients/sale-quotations`, data);
};

export const updateQuote = async (data, orderId) => {
  return await client.put(`/clients/sale-quotations/${orderId}`, data);
};

export const lostQuote = async (orderId) => {
  return await client.put(`/clients/sale-quotations/${orderId}/lost`, {});
};

export const convertQuote = async (orderId, data) => {
  return await client.put(`/clients/sale-quotations/${orderId}/convert`, data);
};

export const updateQuoteDraft = async (data, orderId) => {
  return await client.put(`/clients/sale-quotations/${orderId}/save`, data);
};

export const submitQuoteDraft = async (data, orderId) => {
  return await client.put(`/clients/sale-quotations/${orderId}/submit`, data);
};

export const deleteQuoteDraft = async (orderId) => {
  return await client.delete(`/clients/sale-quotations/${orderId}`);
};
