import * as yup from "yup";

import { Close } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import { useForm } from "react-hook-form";
import TextInput from "../../../shared/formElements/TextInput";
import { HeaderText } from "../../../inventory/components/SKU/SkuDetailsForm";
import styled from "@emotion/styled";
import { Fragment, useEffect, useState } from "react";
import CheckBoxGroup from "../../../shared/formElements/CheckBoxGroup";
import { useFetchSalesPerson } from "../../../query-hooks/ClientAllocation/SalesPerson/useFetchSalesPerson";
import { error_msg } from "../../../constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCreateSalesPerson } from "../../../query-hooks/ClientAllocation/SalesPerson/useCreateSalesPerson";
import { useUpdateSalesPerson } from "../../../query-hooks/ClientAllocation/SalesPerson/useUpdateSalesPerson";
import { enqueueSnackbar } from "notistack";

const schema = yup.object({
  name: yup.string().required(error_msg.required),
  mobile: yup
    .string()
    .required(error_msg.required)
    .matches(/^[0-9]+$/, error_msg.onlyDigits)
    .min(10, error_msg.enterLeast10Digits)
    .max(10, error_msg.enterOnly10Digits),
  status: yup
    .array()
    .of(yup.string())
    .min(1, error_msg.required)
    .required(error_msg.required)
});

const ColumnHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: 16,
  fontFamily: "Inter",
  lineHeight: "20px",
  fontWeight: 400
}));

const ManageSalesRep = ({ hideDrawer }) => {
  const [isEdit, setIsEdit] = useState(true);
  const {
    control,
    setValue,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });
  const handleEdit = () => {
    setIsEdit((prev) => !prev);
  };

  const { data, isFetching, refetch } = useFetchSalesPerson();

  const {
    mutate: updateSalesPerson,
    isError: isUpdateError,
    error: updateError
  } = useUpdateSalesPerson();

  const {
    mutate: createSalesPerson,
    isError: isCreateError,
    error: createError
  } = useCreateSalesPerson();

  useEffect(() => {
    if (isCreateError) {
      enqueueSnackbar(createError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isUpdateError)
      enqueueSnackbar(updateError?.response?.data?.message, {
        variant: "error"
      });
  }, [
    createError?.response?.data?.message,
    updateError?.response?.data?.message,
    isUpdateError,
    isCreateError
  ]);

  const onAddClick = (data) => {
    createSalesPerson(
      {
        data: {
          name: data["name"],
          mobile: data["mobile"],
          isActive: data["status"][0] ? true : false
        }
      },
      {
        onSuccess: (res) => {
          reset();
          refetch();
          setIsEdit(false);
          enqueueSnackbar("Sales Rep Created!", {
            variant: "success"
          });
        }
      }
    );
  };

  const handleStatusChange = (isActive, salesPersonId) => {
    updateSalesPerson(
      {
        data: {
          isActive: Boolean(isActive === -1)
        },
        salesPersonId: salesPersonId
      },
      {
        onSuccess: (res) => {
          refetch();
          enqueueSnackbar("Status Updated!", {
            variant: "success"
          });
        }
      }
    );
  };

  return isFetching ? (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isFetching}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : (
    <Box display={"flex"} flexDirection={"column"}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        sx={{
          padding: { xs: "15px", md: "30px 40px 30px 40px" },
          gap: { xs: 0, md: 5 },
          mb: { xs: 20, md: 0 }
        }}
      >
        <Box
          sx={{ display: { xs: "none", md: "flex" } }}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <HeaderText>{"Manage your Sales Representatives"}</HeaderText>
          <IconButton onClick={hideDrawer}>
            <Close />
          </IconButton>
        </Box>
        <Grid container rowSpacing={1}>
          <Grid item xs={12} container px={2}>
            <Grid item xs={2}>
              <ColumnHeader>{"S.No"}</ColumnHeader>
            </Grid>
            <Grid item xs={4}>
              <ColumnHeader>{"Name"}</ColumnHeader>
            </Grid>
            <Grid item xs={4}>
              <ColumnHeader>{"Mobile"}</ColumnHeader>
            </Grid>
            <Grid item xs={2}>
              <ColumnHeader>{"Active"}</ColumnHeader>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ border: "1px solid #B7BEC7" }} />
          </Grid>
          {!isFetching &&
            data?.salesPersons?.map((item, index) => (
              <Grid container key={index}>
                <Grid item xs={12} container px={2} mt={1}>
                  <Grid item xs={2} display={"flex"} alignItems={"center"}>
                    <ColumnHeader>{`${index + 1}.`}</ColumnHeader>
                  </Grid>
                  <Grid item xs={4} display={"flex"} alignItems={"center"}>
                    <ColumnHeader>{item.name}</ColumnHeader>
                  </Grid>
                  <Grid item xs={4} display={"flex"} alignItems={"center"}>
                    <ColumnHeader>{item.mobile}</ColumnHeader>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    display={"flex"}
                    justifyContent={"right"}
                    alignItems={"flex-start"}
                  >
                    <Box
                      sx={{
                        "& label > span > span, & label > span > span::before":
                          {
                            height: {
                              md: 16
                            },
                            width: { md: 16 }
                          },
                        "& label > span:last-child": {
                          fontSize: { md: 12 },
                          lineHeight: "16px"
                        }
                      }}
                    >
                      <CheckBoxGroup
                        control={control}
                        name={"active"}
                        options={[
                          {
                            name: "yes"
                          }
                        ]}
                        disabled={isEdit}
                        setValue={setValue}
                        defaultValues={item?.isActive ? ["yes"] : []}
                        errors={errors}
                        onChange={(value) =>
                          handleStatusChange(value, item._id)
                        }
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12} mt={1}>
                  <Divider sx={{ border: "1px solid #B7BEC7" }} />
                </Grid>
              </Grid>
            ))}
          {!isEdit && (
            <>
              <Grid item xs={12} container px={2}>
                <Grid item xs={2} display={"flex"} alignItems={"center"}>
                  <ColumnHeader>{`${
                    !isFetching && data?.salesPersons?.length
                      ? data?.salesPersons?.length + 1
                      : 1
                  }.`}</ColumnHeader>
                </Grid>
                <Grid item xs={4} display={"flex"} alignItems={"center"}>
                  <TextInput
                    control={control}
                    name={"name"}
                    placeholder={"name"}
                    disabled={isEdit}
                    sx={{ maxHeight: "32px", maxWidth: "100px" }}
                  />
                </Grid>
                <Grid item xs={4} display={"flex"} alignItems={"center"}>
                  <TextInput
                    control={control}
                    name={"mobile"}
                    placeholder={"mobile"}
                    disabled={isEdit}
                    sx={{ maxHeight: "32px", maxWidth: "100px" }}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  display={"flex"}
                  justifyContent={"right"}
                  alignItems={"flex-start"}
                >
                  <Box
                    sx={{
                      "& label > span > span, & label > span > span::before": {
                        height: {
                          md: 16
                        },
                        width: { md: 16 }
                      },
                      "& label > span:last-child": {
                        fontSize: { md: 12 },
                        lineHeight: "16px"
                      }
                    }}
                  >
                    <CheckBoxGroup
                      control={control}
                      name={"status"}
                      options={[
                        {
                          name: "yes"
                        }
                      ]}
                      disabled={isEdit}
                      setValue={setValue}
                      errors={errors}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ border: "1px solid #B7BEC7" }} />
              </Grid>
            </>
          )}
        </Grid>
      </Box>
      <Box
        display={"flex"}
        sx={{ position: "absolute", bottom: 0, left: 0, right: 0 }}
      >
        {isEdit ? (
          <Button
            variant="contained"
            sx={{
              fontWeight: 500,
              fontSize: "24px",
              width: "100%",
              height: 81,
              borderRadius: 0
            }}
            onClick={handleEdit}
          >
            {"Edit"}
          </Button>
        ) : (
          <Button
            variant="contained"
            sx={{
              fontWeight: 500,
              fontSize: "24px",
              width: "100%",
              height: 81,
              borderRadius: 0
            }}
            onClick={handleSubmit(onAddClick)}
          >
            {"Save"}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ManageSalesRep;
