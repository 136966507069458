import * as yup from "yup";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import { useContext, useEffect, useState } from "react";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DropDown from "../../shared/formElements/DropDown";
import { ProfileFormKeys } from "../../constants/formKeys";
import TextInput from "../../shared/formElements/TextInput";
import { error_msg, profile_labels } from "../../constants";
// import styled from "@emotion/styled";
import { useFetchProfileQuery } from "../../query-hooks/Profile/useFetchProfileQuery";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFetchUpdateBusinessFormData } from "../../query-hooks/Profile/useFetchUpdateBusinessFormData";
import { dropdownOptions } from "../../shared/utils";
import { useUpdateProfile } from "../../query-hooks/Profile/useUpdateProfile";
import { enqueueSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { useUpdateBusiness } from "../../query-hooks/Profile/useUpdateBusiness";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { AccordianInfo } from "../../warehouse/components/MobileOrdersPage";
import { ImageUpload } from "../../shared/formElements/ImageUpload";
import { AppContext } from "../../shared/context/auth-context";

const proflieSchema = yup.object({
  [ProfileFormKeys.FirstName]: yup.string().required(error_msg.required),
  [ProfileFormKeys.LastName]: yup.string().required(error_msg.required),
  [ProfileFormKeys.Email]: yup.string().required(error_msg.required)
});

const businessSchema = yup.object({
  [ProfileFormKeys.NatureOfBusiness]: yup.string().required(error_msg.required),
  [ProfileFormKeys.Materials]: yup
    .array()
    .of(yup.string())
    .min(1, "Please select at least 1 material")
    .required(error_msg.required),
  [ProfileFormKeys.Brands]: yup.string().required(error_msg.required),
  [ProfileFormKeys.annualTurnover]: yup.string().required(error_msg.required),
  [ProfileFormKeys.FirmsName]: yup.string().required(error_msg.required),
  [ProfileFormKeys.GSTIN]: yup.string().required(error_msg.required),
  [ProfileFormKeys.Street]: yup.string().required(error_msg.required),
  [ProfileFormKeys.City]: yup.string().required(error_msg.required),
  [ProfileFormKeys.State]: yup.string().required(error_msg.required),
  [ProfileFormKeys.ZipCode]: yup.string().required(error_msg.required),
  [ProfileFormKeys.Area]: yup.string().required(error_msg.required)
  // [ProfileFormKeys.Logo]: yup
  //   .mixed()
  //   .required("Logo is required")
  //   .test(
  //     "is-single-image",
  //     `${profile_labels.uploadLogo}`,
  //     (value) => Boolean(value) && value
  //   )
});

// const StyledBoxCard = styled(Box)(({ theme }) => ({
//   padding: "20px 16px",
//   backgroundColor: "#F7F8FA",
//   height: 180,
//   width: "100%",
//   borderRadius: "4px",
//   maxWidth: 280,
//   display: "flex",
//   flexDirection: "column",
//   gap: 16,
//   justifyContent: "space-between"
// }));

// const AddressBox = ({ title, address }) => {
//   return (
//     <Box>
//       <Typography fontSize={14} fontWeight={500}>
//         {title}
//       </Typography>
//       <Typography fontSize={14} color={"text.secondary"} lineHeight={"20px"}>
//         {address}
//       </Typography>
//     </Box>
//   );
// };

const VerifiedPhone = () => {
  return (
    <Box display={"flex"} alignItems={"center"} px={2}>
      <CheckCircleOutlineIcon
        fontSize="16"
        sx={{ mr: "4px", cursor: "pointer", color: "#0F8B6C" }}
      />
      <Typography
        color={"#0F8B6C"}
        fontWeight={400}
        fontSize={12}
        lineHeight={"16px"}
        whiteSpace={"nowrap"}
      >
        {profile_labels.verified}
      </Typography>
    </Box>
  );
};

const ProfileInput = ({ control, isEdit, hideLabel = false }) => {
  return (
    <>
      <Grid item xs={6}>
        <TextInput
          control={control}
          name={ProfileFormKeys.FirstName}
          label={!hideLabel && profile_labels.firstNameLabelText}
          placeholder={profile_labels.firstNamePlaceholderText}
          disabled={isEdit}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          control={control}
          name={ProfileFormKeys.LastName}
          label={!hideLabel && profile_labels.lastNameLabelText}
          placeholder={profile_labels.lastNamePlaceholderText}
          disabled={isEdit}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          control={control}
          name={ProfileFormKeys.Phone}
          label={!hideLabel && profile_labels.phoneLabelText}
          placeholder={profile_labels.phonePlaceholderText}
          endIcon={!hideLabel && VerifiedPhone}
          disabled
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          control={control}
          name={ProfileFormKeys.Email}
          label={!hideLabel && profile_labels.emailLabelText}
          placeholder={profile_labels.emailPlaceholderText}
          disabled={isEdit}
        />
      </Grid>
    </>
  );
};

const BusinessInput = ({
  control,
  isEdit,
  isFormDataFetching,
  role,
  formData,
  profileData,
  setValue,
  hideLabel = false
}) => {
  return (
    <>
      <Grid item xs={12} md={6}>
        <TextInput
          control={control}
          name={ProfileFormKeys.FirmsName}
          label={!hideLabel && profile_labels.firmsNameLabelText}
          placeholder={profile_labels.firmsNamePlaceholderText}
          disabled={isEdit}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput
          control={control}
          name={ProfileFormKeys.GSTIN}
          label={!hideLabel && profile_labels.gstinLabelText}
          placeholder={profile_labels.gstinPlaceholderText}
          disabled={isEdit}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          control={control}
          name={ProfileFormKeys.Street}
          label={!hideLabel && profile_labels.streetAddressLabelText}
          placeholder={profile_labels.streetAddressPlaceholderText}
          disabled={isEdit}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          control={control}
          name={ProfileFormKeys.Area}
          label={!hideLabel && profile_labels.areaPlaceholderText}
          placeholder={profile_labels.areaPlaceholderText}
          disabled={isEdit}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          control={control}
          name={ProfileFormKeys.City}
          label={!hideLabel && profile_labels.cityPlaceholderText}
          placeholder={profile_labels.cityPlaceholderText}
          disabled={isEdit}
        />
      </Grid>
      <Grid item xs={6}>
        <DropDown
          control={control}
          name={ProfileFormKeys.State}
          label={!hideLabel && profile_labels.stateLabelText}
          placeholder={profile_labels.stateLabelText}
          isLoading={isFormDataFetching}
          options={
            role === "CustomerAdmin"
              ? dropdownOptions(formData?.states, "name", "name")
              : dropdownOptions([profileData?.billing?.address?.state])
          }
          disabled={isEdit}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          control={control}
          name={ProfileFormKeys.ZipCode}
          label={!hideLabel && profile_labels.zipCodePlaceholderText}
          placeholder={profile_labels.zipCodePlaceholderText}
          disabled={isEdit}
        />
      </Grid>
      <Grid item xs={12}>
        <DropDown
          name={ProfileFormKeys.NatureOfBusiness}
          control={control}
          placeholder={profile_labels.natureOfBusinessPlaceholderText}
          label={!hideLabel && profile_labels.natureOfBusinessLabelText}
          isLoading={isFormDataFetching}
          options={
            role === "CustomerAdmin"
              ? dropdownOptions(formData?.businessNatures)
              : dropdownOptions([profileData?.businessNature])
          }
          disabled={isEdit}
        />
      </Grid>
      <Grid item xs={12}>
        <DropDown
          multiple
          name={ProfileFormKeys.Materials}
          control={control}
          placeholder={profile_labels.MaterialsLabelText}
          label={!hideLabel && profile_labels.MaterialsLabelText}
          isLoading={isFormDataFetching}
          options={
            role === "CustomerAdmin"
              ? formData?.materials
              : dropdownOptions(profileData?.materials, "name", "displayName")
          }
          setValue={setValue}
          disabled={isEdit}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          control={control}
          name={ProfileFormKeys.Brands}
          label={!hideLabel && profile_labels.brandsLabelText}
          placeholder={profile_labels.brandsPlaceholderText}
          disabled={isEdit}
        />
      </Grid>
      <Grid item xs={12}>
        <DropDown
          control={control}
          name={ProfileFormKeys.annualTurnover}
          label={!hideLabel && profile_labels.annualTurnoverLabelText}
          isLoading={isFormDataFetching}
          options={
            role === "CustomerAdmin"
              ? dropdownOptions(formData?.annualTurnovers)
              : dropdownOptions([profileData?.annualTurnover])
          }
          disabled={isEdit}
        />
      </Grid>
    </>
  );
};

const LogoInput = ({
  control,
  setValue,
  isEdit,
  showImg,
  profileData,
  handleShowImg
}) => {
  return (
    <>
      {showImg && profileData?.logo?.thumbUrl ? (
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={2}
          sx={{ alignItems: { xs: "center", md: "left" } }}
        >
          <img
            src={profileData?.logo?.thumbUrl}
            alt={profileData?.logo?.originalName}
            style={{
              width: "150px",
              height: "150px",
              objectFit: "contain"
            }}
          />
          {!isEdit && (
            <Button variant="link" onClick={handleShowImg}>
              Edit Logo
            </Button>
          )}
        </Box>
      ) : (
        <Box
          display={"flex"}
          sx={{
            minHeight: { xs: "150px", md: "250px" },
            minWidth: { xs: "190px", md: "250px" },
            justifyContent: { xs: "center", md: "left" }
          }}
        >
          <ImageUpload
            control={control}
            name={profile_labels.uploadLogo}
            setValue={setValue}
            uploadButtonText={"Upload Logo"}
            acceptFileType=".jpg, .jpeg, .png"
            isProfile
            disabled={isEdit}
          />
        </Box>
      )}
    </>
  );
};

const ProfileForm = ({ refetch, profileData, isProfileFetching }) => {
  const [isEdit, setIsEdit] = useState(true);

  const { control, setValue, handleSubmit, reset } = useForm({
    resolver: yupResolver(proflieSchema)
  });

  useEffect(() => {
    if (!isProfileFetching) {
      setValue(ProfileFormKeys.Phone, profileData?.data?.user?.mobile);
      setValue(ProfileFormKeys.Email, profileData?.data?.user?.email);
      setValue(ProfileFormKeys.FirstName, profileData?.data?.user?.firstName);
      setValue(ProfileFormKeys.LastName, profileData?.data?.user?.lastName);
    }
    // eslint-disable-next-line
  }, [isProfileFetching, setValue]);

  const onCancel = () => {
    reset();
    if (!isProfileFetching) {
      setValue(ProfileFormKeys.Phone, profileData?.data?.user?.mobile);
      setValue(ProfileFormKeys.Email, profileData?.data?.user?.email);
      setValue(ProfileFormKeys.FirstName, profileData?.data?.user?.firstName);
      setValue(ProfileFormKeys.LastName, profileData?.data?.user?.lastName);
    }
  };

  const onEdit = () => {
    setIsEdit((prev) => !prev);
    onCancel();
  };

  const { mutate, isLoading, isError, error } = useUpdateProfile();

  const onSaveProfile = (data) => {
    const ProfileData = {
      firstName: data[ProfileFormKeys.FirstName],
      lastName: data[ProfileFormKeys.LastName],
      email: data[ProfileFormKeys.Email]
    };
    mutate(
      { data: ProfileData },
      {
        onSuccess: (res) => {
          refetch();
          enqueueSnackbar("Profile Updated!", { variant: "success" });
          onEdit();
        }
      }
    );
  };

  useEffect(() => {
    if (isError)
      enqueueSnackbar(error?.response?.data?.message, {
        variant: "error"
      });
  }, [error?.response?.data?.message, isError]);

  return (
    <>
      <Box
        sx={{ display: { xs: "none", md: "flex" } }}
        flexDirection={"column"}
        gap={2}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"flex-end"}
        >
          <Typography
            sx={{
              fontSize: 20,
              fontWeight: 500,
              letterSpacing: "0.04px"
            }}
          >
            {profile_labels.personalDetails}
          </Typography>
          <Box display={"flex"} gap={1}>
            {isEdit ? (
              <Button
                variant="contained"
                sx={{ height: 48, width: 140 }}
                onClick={onEdit}
              >
                {profile_labels.edit}
              </Button>
            ) : (
              <>
                <Button
                  variant="soft"
                  sx={{ height: 48, width: 140 }}
                  onClick={onEdit}
                >
                  {profile_labels.cancel}
                </Button>
                <Button
                  variant="contained"
                  sx={{ height: 48, width: 140 }}
                  component={LoadingButton}
                  loading={isLoading}
                  onClick={handleSubmit(onSaveProfile)}
                >
                  {profile_labels.saveChanges}
                </Button>
              </>
            )}
          </Box>
        </Box>
        <Divider sx={{ borderColor: "#B7BEC7" }} />
        <Grid container spacing={"20px"} maxWidth={573}>
          <ProfileInput control={control} isEdit={isEdit} />
        </Grid>
      </Box>
      <Grid
        item
        xs={12}
        px={"15px"}
        sx={{ display: { xs: "flex", md: "none" } }}
      >
        <AccordianInfo title={"User Profile"} hideDivider defaultExpanded>
          <Grid container rowSpacing={2} columnSpacing={4}>
            <ProfileInput control={control} isEdit={isEdit} hideLabel />
            {isEdit ? (
              <Grid
                item
                xs={12}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Button
                  variant="contained"
                  sx={{ height: 36, width: 100 }}
                  onClick={onEdit}
                >
                  {profile_labels.edit}
                </Button>
              </Grid>
            ) : (
              <>
                <Grid
                  item
                  xs={6}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"right"}
                >
                  <Button
                    variant="soft"
                    sx={{ height: 36, width: 100 }}
                    onClick={onEdit}
                  >
                    {profile_labels.cancel}
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={6}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"left"}
                >
                  <Button
                    variant="contained"
                    sx={{ height: 36, width: 100 }}
                    component={LoadingButton}
                    loading={isLoading}
                    onClick={handleSubmit(onSaveProfile)}
                  >
                    {"Save"}
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </AccordianInfo>
      </Grid>
    </>
  );
};

const BusinessAddressForm = ({
  refetch,
  profileData,
  isProfileFetching,
  role
}) => {
  const [isEdit, setIsEdit] = useState(true);
  const [showImg, setShowImg] = useState(true);

  const handleShowImg = () => {
    setShowImg((prev) => !prev);
  };

  const { control, setValue, handleSubmit, reset } = useForm({
    resolver: yupResolver(businessSchema)
  });

  const { data: formData, isFetching: isFormDataFetching } =
    useFetchUpdateBusinessFormData(role);
  const { mutate, isLoading, isError, error } = useUpdateBusiness();

  useEffect(() => {
    if (!isProfileFetching) {
      setValue(ProfileFormKeys.FirmsName, profileData?.companyName);
      setValue(ProfileFormKeys.GSTIN, profileData?.billing?.gstNo);
      setValue(ProfileFormKeys.Street, profileData?.billing?.address?.address);
      setValue(ProfileFormKeys.Area, profileData?.billing?.address?.area);
      setValue(ProfileFormKeys.City, profileData?.billing?.address?.city);
      setValue(ProfileFormKeys.State, profileData?.billing?.address?.state);
      setValue(ProfileFormKeys.ZipCode, profileData?.billing?.address?.pincode);
      setValue(ProfileFormKeys.NatureOfBusiness, profileData?.businessNature);
      setValue(
        ProfileFormKeys.Materials,
        profileData?.materials?.map((item) => item.name)
      );
      setValue(ProfileFormKeys.Brands, profileData?.brands.join(", "));
      setValue(ProfileFormKeys.annualTurnover, profileData?.annualTurnover);
    }
    // eslint-disable-next-line
  }, [isProfileFetching, setValue]);

  const onCancel = () => {
    reset();
    !showImg && setShowImg(true);
    if (!isProfileFetching) {
      setValue(ProfileFormKeys.FirmsName, profileData?.companyName);
      setValue(ProfileFormKeys.GSTIN, profileData?.billing?.gstNo);
      setValue(ProfileFormKeys.Street, profileData?.billing?.address?.address);
      setValue(ProfileFormKeys.Area, profileData?.billing?.address?.area);
      setValue(ProfileFormKeys.City, profileData?.billing?.address?.city);
      setValue(ProfileFormKeys.State, profileData?.billing?.address?.state);
      setValue(ProfileFormKeys.ZipCode, profileData?.billing?.address?.pincode);
      setValue(ProfileFormKeys.NatureOfBusiness, profileData?.businessNature);
      setValue(
        ProfileFormKeys.Materials,
        profileData?.materials?.map((item) => item.name)
      );
      setValue(ProfileFormKeys.Brands, profileData?.brands.join(", "));
      setValue(ProfileFormKeys.annualTurnover, profileData?.annualTurnover);
    }
  };

  const onEdit = () => {
    setIsEdit((prev) => !prev);
    onCancel();
  };

  const onSaveBussinessAddress = (data) => {
    const ProfileData = new FormData();
    ProfileData.append("companyName", data[ProfileFormKeys.FirmsName]);
    ProfileData.append(
      "billing[address][address]",
      data[ProfileFormKeys.Street]
    );
    ProfileData.append("billing[address][area]", data[ProfileFormKeys.Area]);
    ProfileData.append("billing[address][city]", data[ProfileFormKeys.City]);
    ProfileData.append("billing[address][state]", data[ProfileFormKeys.State]);
    ProfileData.append(
      "billing[address][pincode]",
      data[ProfileFormKeys.ZipCode]
    );
    ProfileData.append("billing[gstNo]", data[ProfileFormKeys.GSTIN]);
    ProfileData.append(
      "businessNature",
      data[ProfileFormKeys.NatureOfBusiness]
    );
    ProfileData.append("annualTurnover", data[ProfileFormKeys.annualTurnover]);
    data[ProfileFormKeys.Materials]?.forEach((item, index) => {
      ProfileData.append(`materials[${index}]`, item);
    });
    const wordsArray = data[ProfileFormKeys.Brands]?.split(",");
    wordsArray?.forEach((item, index) => {
      ProfileData.append(`brands[${index}]`, item);
    });
    data[ProfileFormKeys.Logo] &&
      ProfileData.append("logo", data[ProfileFormKeys.Logo]);
    mutate(
      { data: ProfileData },
      {
        onSuccess: (res) => {
          refetch();
          enqueueSnackbar("Business Details Updated!", { variant: "success" });
          onEdit();
        }
      }
    );
  };

  useEffect(() => {
    if (isError)
      enqueueSnackbar(error?.response?.data?.message, {
        variant: "error"
      });
  }, [error?.response?.data?.message, isError]);

  return (
    <>
      <Box
        sx={{ display: { xs: "none", md: "flex" } }}
        flexDirection={"column"}
        gap={2}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"flex-end"}
        >
          <Typography
            sx={{
              fontSize: 20,
              fontWeight: 500,
              letterSpacing: "0.04px"
            }}
          >
            {profile_labels.businessAddress}
          </Typography>
          {role === "CustomerAdmin" && (
            <Box display={"flex"} gap={1}>
              {isEdit ? (
                <Button
                  variant="contained"
                  sx={{ height: 48, width: 140 }}
                  onClick={onEdit}
                >
                  {profile_labels.edit}
                </Button>
              ) : (
                <>
                  <Button
                    variant="soft"
                    sx={{ height: 48, width: 140 }}
                    onClick={onEdit}
                  >
                    {profile_labels.cancel}
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ height: 48, width: 140 }}
                    component={LoadingButton}
                    loading={isLoading}
                    onClick={handleSubmit(onSaveBussinessAddress)}
                  >
                    {profile_labels.saveChanges}
                  </Button>
                </>
              )}
            </Box>
          )}
        </Box>
        <Divider sx={{ borderColor: "#B7BEC7" }} />
        <Box display={"flex"} gap={4}>
          <Grid container spacing={"20px"} maxWidth={573}>
            <BusinessInput
              control={control}
              formData={formData}
              isEdit={isEdit}
              isFormDataFetching={isFormDataFetching}
              profileData={profileData}
              role={role}
              setValue={setValue}
            />
          </Grid>
          <Box
            display={"flex"}
            width={"240px"}
            height={"240px"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            flexDirection={"column"}
          >
            <LogoInput
              control={control}
              handleShowImg={handleShowImg}
              isEdit={isEdit}
              profileData={profileData}
              setValue={setValue}
              showImg={showImg}
            />
          </Box>
        </Box>
      </Box>
      <Grid
        item
        xs={12}
        px={"15px"}
        sx={{ display: { xs: "flex", md: "none" }, mb: 10 }}
      >
        <AccordianInfo title={"Business Profile"} defaultExpanded>
          <Grid container rowSpacing={2} columnSpacing={4}>
            <Grid
              item
              xs={12}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              minHeight={"150px"}
            >
              <LogoInput
                control={control}
                handleShowImg={handleShowImg}
                isEdit={isEdit}
                profileData={profileData}
                setValue={setValue}
                showImg={showImg}
              />
            </Grid>
            <BusinessInput
              control={control}
              formData={formData}
              isEdit={isEdit}
              isFormDataFetching={isFormDataFetching}
              profileData={profileData}
              role={role}
              setValue={setValue}
              hideLabel
            />
            {role === "CustomerAdmin" &&
              (isEdit ? (
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Button
                    variant="contained"
                    sx={{ height: 36, width: 100 }}
                    onClick={onEdit}
                  >
                    {profile_labels.edit}
                  </Button>
                </Grid>
              ) : (
                <>
                  <Grid
                    item
                    xs={6}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"right"}
                  >
                    <Button
                      variant="soft"
                      sx={{ height: 36, width: 100 }}
                      onClick={onEdit}
                    >
                      {profile_labels.cancel}
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"left"}
                  >
                    <Button
                      variant="contained"
                      sx={{ height: 36, width: 100 }}
                      component={LoadingButton}
                      loading={isLoading}
                      onClick={handleSubmit(onSaveBussinessAddress)}
                    >
                      {"Save"}
                    </Button>
                  </Grid>
                </>
              ))}
          </Grid>
        </AccordianInfo>
      </Grid>
    </>
  );
};

// const SavedAddresses = () => {
//   return (
//     <Box display={"flex"} flexDirection={"column"} gap={"15px"} maxWidth={573}>
//       <Typography fontWeight={600} letterSpacing={"0.08px"}>
//         {profile_labels.otherSavedAddress}
//       </Typography>
//       <Grid container spacing={2}>
//         <Grid item xs={12} md={6}>
//           <StyledBoxCard>
//             <AddressBox
//               title={"Address 01"}
//               address={
//                 "Flatsphere.design Anagha Enclave, 23 Main Road, Electronic City"
//               }
//             />
//             <Button variant="soft" fullWidth sx={{ height: 44 }}>
//               {profile_labels.setAsDefault}
//             </Button>
//           </StyledBoxCard>
//         </Grid>
//         <Grid item xs={12} md={6}>
//           <StyledBoxCard>
//             <AddressBox
//               title={"Address 02"}
//               address={
//                 "Flatsphere.design Anagha Enclave, 23 Main Road, Electronic City"
//               }
//             />
//             <Button variant="soft" fullWidth sx={{ height: 44 }}>
//               {profile_labels.setAsDefault}
//             </Button>
//           </StyledBoxCard>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

const Profile = () => {
  const { updateProfileData } = useContext(AppContext);
  const navigate = useNavigate();
  const {
    data: profileData,
    isFetching: isProfileFetching,
    refetch
  } = useFetchProfileQuery();

  useEffect(() => {
    if (!isProfileFetching) {
      updateProfileData(profileData?.data);
    }
  }, [isProfileFetching, profileData?.data, updateProfileData]);

  return (
    <Box
      maxWidth={875}
      flexDirection={"column"}
      sx={{
        display: "flex",
        padding: {
          xs: 0,
          md: "24px 0 24px 0"
        },
        margin: { xs: 0, md: "auto" },
        gap: { xs: 1, md: 5 }
      }}
    >
      <Box
        sx={{
          display: { xs: "none", md: "flex" }
        }}
      >
        <IconButton
          size="small"
          onClick={() => {
            navigate("/dashboard");
          }}
          sx={{ marginRight: 1, marginLeft: -1 }}
        >
          <ArrowBack fontSize="small" />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: { xs: "flex", md: "none" },
          alignItems: "center",
          padding: "6px",
          background: "#EEF1F4",
          gap: 2
        }}
      >
        <IconButton
          onClick={() => {
            navigate("/dashboard");
          }}
        >
          <ArrowBack />
        </IconButton>
        <Typography
          sx={{
            fontSize: "16px",
            lineHeight: "19.36px",
            fontWeight: 700,
            color: "#2773FF"
          }}
        >
          {"Profile"}
        </Typography>
      </Box>
      <ProfileForm
        profileData={profileData}
        isProfileFetching={isProfileFetching}
        refetch={refetch}
      />
      <BusinessAddressForm
        profileData={profileData?.data?.user?.client}
        role={profileData?.data?.user?.role}
        isProfileFetching={isProfileFetching}
        refetch={refetch}
      />
      {/* <SavedAddresses disabled = {isEdit} /> */}
    </Box>
  );
};

export default Profile;
