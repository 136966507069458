import * as yup from "yup";

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import TextArea from "../../../shared/formElements/TextArea";
import { Box, Button, Grid, Typography } from "@mui/material";
import CustomTable from "../../../shared/customTable";
import { createMrrkeys } from "../../../constants/formKeys";
import { createMrr_labels, error_msg } from "../../../constants";
import { currencyOnlyFormatter } from "../../../shared/utils";
import {
  PrimaryText,
  SecondaryText
} from "../../../inventory/pages/Performance";
import TextInput from "../../../shared/formElements/TextInput";
import DropDown from "../../../shared/formElements/DropDown";
import moment from "moment";
import { useFieldArray, useForm } from "react-hook-form";
import { enqueueSnackbar } from "notistack";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCustomDialog } from "../../../shared/customDialog";
import SuccessfulDialog from "../../../shared/UiElements/SuccessfulDialog";
import { useSaveMRR } from "../../../query-hooks/ClientOrderMRR/useSaveMRR";
import { useCreateMRR } from "../../../query-hooks/ClientOrderMRR/useCreateMRR";
import { useSubmitMRRDraft } from "../../../query-hooks/ClientOrderMRR/useSubmitMRRDraft";
import { useUpdateMRRDraft } from "../../../query-hooks/ClientOrderMRR/useUpdateMRRDraft";
import { useDeleteMRRDraft } from "../../../query-hooks/ClientOrderMRR/useDeleteMRRDraft";
import { CalendarTodayOutlined, Verified } from "@mui/icons-material";
import { useReactToPrint } from "react-to-print";
import { AppContext } from "../../../shared/context/auth-context";
import PrintableTable from "../../../purchase/components/PrintableTable";
import {
  AddressSection,
  LabelTextInput,
  LabelValueText
} from "../../pages/MaterialReceivedReports";
import MobileOrdersPage, { DocInfo } from "../MobileOrdersPage";
import DateInput from "../../../shared/formElements/DateInput";
import MobileMrrTable from "./MobileMrrTable";
import { ImageUpload } from "../../../shared/formElements/ImageUpload";

export const MobileLabelInput = ({
  control,
  name,
  label,
  placeholder = "",
  isDateInput = false,
  isDropDown = false,
  options = []
}) => {
  return (
    <Grid container columnSpacing={1}>
      <Grid
        item
        xs={4}
        display={"flex"}
        justifyContent={"right"}
        alignItems={"center"}
      >
        <Typography fontSize={14} fontWeight={400} lineHeight={"16.94px"}>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        {isDateInput ? (
          <DateInput
            control={control}
            name={name}
            placeholder={placeholder}
            minDate={"2000-01-01"}
            endIcon={CalendarTodayOutlined}
            sx={{ maxHeight: "30px" }}
          />
        ) : isDropDown ? (
          <DropDown
            control={control}
            name={name}
            placeholder={placeholder}
            options={options}
            sx={{ maxHeight: "30px" }}
          />
        ) : (
          <TextInput control={control} name={name} sx={{ maxHeight: "30px" }} />
        )}
      </Grid>
    </Grid>
  );
};

const getColumns = (PlacedStatus) => {
  return [
    {
      Header: "S.No",
      accessor: "sNo",
      width: 82,
      size: 0.5
    },
    {
      Header: "Item/ SKU",
      accessor: "skuItem",
      width: 170,
      size: 2,
      Cell: ({ value }) => {
        return (
          <Box display={"flex"} gap={1} alignItems={"center"}>
            {value?.img && (
              <img
                src={value?.img}
                alt={value.name}
                style={{ width: "50%", maxWidth: "50%" }}
              />
            )}
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 600,
                textDecoration: "underline",
                color: "#2773FF",
                whiteSpace: "pre-wrap"
              }}
            >
              {value.name}
            </Typography>
          </Box>
        );
      }
    },
    {
      Header: "Product",
      accessor: "product",
      width: 190,
      size: 2,
      disableSortBy: true,
      Cell: ({ value }) => {
        return (
          <Box display={"flex"} flexDirection={"column"}>
            <PrimaryText>{`Product: ${value?.product ?? "--"}`}</PrimaryText>
            <SecondaryText>{`Category: ${
              value?.category ?? "--"
            }`}</SecondaryText>
            <SecondaryText>{`Brand: ${value?.brand ?? "--"}`}</SecondaryText>
          </Box>
        );
      }
    },
    {
      Header: "UOM",
      accessor: "uom",
      width: 72,
      size: 1,
      disableSortBy: true
    },
    {
      Header: "Rate (INR)",
      accessor: "rate",
      disableSortBy: true,
      width: 122,
      size: 2,
      Cell: ({ row, value }) => {
        return (
          <Box
            display={"flex"}
            flexDirection={"column"}
            textAlign={"center"}
            gap={2}
          >
            <PrimaryText>
              {currencyOnlyFormatter(parseFloat(value).toFixed(2))}
            </PrimaryText>
            {row.original.incGst && (
              <SecondaryText>{`(Incl. GST)`}</SecondaryText>
            )}
          </Box>
        );
      }
    },
    {
      Header: PlacedStatus ? "Qty Ordered" : "Qty (Received/Ordered)",
      accessor: "qtyOrdered",
      disableSortBy: true,
      size: 1,
      width: PlacedStatus ? 130 : 200,
      Cell: ({ value, row }) => {
        return (
          <Box
            display={"flex"}
            textAlign={"center"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={2}
            width={"100%"}
          >
            <PrimaryText>
              {PlacedStatus ? value : row.original.prevReceived ?? 0}
              {!PlacedStatus && (
                <span
                  style={{
                    color: "#607088",
                    fontSize: 13,
                    fontFamily: "Inter",
                    lineHeight: "18px",
                    fontWeight: 600
                  }}
                >{` / ${value}`}</span>
              )}
            </PrimaryText>
          </Box>
        );
      }
    },
    {
      Header: "Qty Received",
      accessor: "qtyReceived",
      disableSortBy: true,
      size: 1,
      width: 129
    },
    {
      Header: "Qty Rejected",
      accessor: "qtyRejected",
      disableSortBy: true,
      size: 1,
      width: 128
    },
    {
      Header: "Reject Reason",
      accessor: "rejectReason",
      width: 170,
      size: 1.5,
      disableSortBy: true
    }
  ];
};

const getRows = (rows = [], control, MRRStatus, rejectOptions) => {
  return rows.map((item, index) => {
    return {
      sNo: index + 1,
      skuId: item?.sku,
      skuItem: {
        img: item?.skuData?.image?.thumbUrl,
        name: item?.skuData?.skuCode
      },
      product: {
        product: item?.skuData?.productName,
        category: item?.skuData?.categoryName,
        brand: item?.skuData?.brandName
      },
      uom: item?.skuData?.unit?.name,
      incGst: Boolean(item?.isTaxApplicable),
      rate: item?.isTaxApplicable
        ? +item.rate + (+item.skuData?.gstRegime / 100 ?? 0) * +item.rate
        : item.rate,
      qtyOrdered: item?.orderQuantity,
      prevReceived: item?.inQuantity,
      qtyReceived: MRRStatus ? (
        Boolean(MRRStatus === "Placed") ? (
          item?.quantity
        ) : (
          <TextInput
            control={control}
            name={`itemsRecieved.${index}.quantity`}
            type="number"
            sx={{
              maxHeight: { xs: "30px", md: "44px" },
              border: { xs: "1px solid #2773FF", md: "1px solid #B7BEC7" }
            }}
            placeholder={"qty recieved"}
          />
        )
      ) : (
        <TextInput
          control={control}
          name={`itemsRecieved.${index}.newQuantity`}
          type="number"
          sx={{
            maxHeight: { xs: "30px", md: "44px" },
            border: { xs: "1px solid #2773FF", md: "1px solid #B7BEC7" }
          }}
          placeholder={"qty recieved"}
        />
      ),
      qtyRejected: Boolean(MRRStatus === "Placed") ? (
        item?.reject?.quantity
      ) : (
        <TextInput
          control={control}
          name={`itemsRecieved.${index}.reject.quantity`}
          type="number"
          sx={{
            maxHeight: { xs: "30px", md: "44px" },
            border: { xs: "1px solid #F05454", md: "1px solid #B7BEC7" }
          }}
          placeholder={"qty reject"}
        />
      ),
      rejectReason: Boolean(MRRStatus === "Placed") ? (
        item?.reject?.reason?.displayName
      ) : (
        <DropDown
          control={control}
          name={`itemsRecieved.${index}.reject.reason.name`}
          options={rejectOptions}
          placeholder={"select reason"}
          unSelectText={"select reason"}
          availUnselect={true}
          sx={{ maxHeight: { xs: "30px", md: "44px" } }}
        />
      )
    };
  });
};

const MRRSchema = (hasMRRStatus) => {
  const baseSchema = {
    // invoiceNo: yup.string().required(error_msg.required),
    // invoiceDate: yup.string().required(error_msg.required),
    // invoiceAmount: yup.string().required(error_msg.required),
    // truckRegNo: yup.string().required(error_msg.required),
    itemsRecieved: yup.array().of(
      yup.object().shape({
        sku: yup.string().required(error_msg.required),
        ...(hasMRRStatus
          ? {
              quantity: yup
                .number()
                .min(0)
                .typeError(error_msg.required)
                .required(error_msg.required)
            }
          : {
              newQuantity: yup
                .number()
                .min(0)
                .typeError(error_msg.required)
                .required(error_msg.required)
            }),
        reject: yup.object().shape({
          reason: yup.object().when("quantity", {
            is: (v) => Boolean(+v),
            then: (schema) =>
              schema.shape({
                name: yup.string().required(error_msg.required)
              })
          })
        })
      })
    )
  };
  return yup.object(baseSchema);
};

const calculateAcceptedSum = (arrayOfObjects, hasMRRStatus) => {
  return arrayOfObjects.reduce((acc, obj) => {
    const taxSum = obj?.isTaxApplicable ? obj?.skuData?.gstRegime / 100 : 0;
    const acceptedQuantity = hasMRRStatus
      ? +obj.quantity || 0
      : +obj.newQuantity || 0;
    return acc + (+obj.rate + taxSum * +obj.rate) * acceptedQuantity;
  }, 0);
};

const calculateRejectedSum = (arrayOfObjects) => {
  return arrayOfObjects.reduce((acc, obj) => {
    const taxSum = obj?.isTaxApplicable ? obj?.skuData?.gstRegime / 100 : 0;
    const rejectedQuantity = +obj.reject?.quantity || 0;
    return acc + (+obj.rate + taxSum * +obj.rate) * rejectedQuantity;
  }, 0);
};

export const handleDownload = (fileUrl) => {
  const downloadLink = document.createElement("a");
  downloadLink.href = fileUrl;
  downloadLink.download = "downloaded-file.ext";
  downloadLink.style.display = "none";
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const ButtonGroup = ({
  Status,
  onDeleteClick,
  onSaveDraft,
  onSubmitClick
}) => {
  return (
    <>
      {Boolean(Status !== "Placed") && (
        <Grid item xs={12} container rowSpacing={1}>
          {Boolean(Status === "Draft") && (
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="softError"
                onClick={onDeleteClick}
                sx={{ minHeight: "48px" }}
              >
                Delete Draft
              </Button>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="soft"
              onClick={onSaveDraft}
              sx={{ minHeight: "48px" }}
            >
              Save Draft
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              onClick={onSubmitClick}
              sx={{ minHeight: "48px" }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const MrrTable = ({
  orderId,
  rejectOptions,
  MRRStatus,
  tableData,
  orderInfo,
  orderStatus,
  MRRInfo,
  isTaxApplicable,
  refetchMrrs,
  refetchFormData
}) => {
  const [costBreakUp, setCostBreakUp] = useState({
    gross: "",
    deduct: "",
    net: ""
  });

  const { profileData } = useContext(AppContext);
  const { logo } = profileData?.user?.client;

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: MRRInfo?.code
  });

  const { control, watch, setValue, handleSubmit, reset } = useForm({
    resolver: yupResolver(MRRSchema(Boolean(MRRStatus)))
  });

  const { showDialog, hideDialog } = useCustomDialog();

  const {
    mutate: createNewMrr,
    isError: isCreateError,
    error: createError
  } = useCreateMRR();

  const {
    mutate: saveDraftMrr,
    isError: isSaveDraftError,
    error: saveDraftError
  } = useSaveMRR();

  const {
    mutate: updateMRRDraft,
    isError: isUpdateDraftError,
    error: updateDraftError
  } = useUpdateMRRDraft();

  const {
    mutate: submitMRRDraft,
    isError: isSubmitDraftError,
    error: submitDraftError
  } = useSubmitMRRDraft();

  const {
    mutate: deleteMRRDraft,
    isError: isDeleteDraftError,
    error: deleteDraftError
  } = useDeleteMRRDraft();

  const { fields, append } = useFieldArray({
    control,
    name: "itemsRecieved"
  });

  const addItems = useCallback(
    (data) => {
      data?.length &&
        data?.forEach((item, index) => {
          append({ isTaxApplicable: isTaxApplicable, ...item });
        });
    },
    [append, isTaxApplicable]
  );

  useEffect(
    () => {
      if (tableData) {
        addItems(tableData);
      }
    }, // eslint-disable-next-line
    [addItems]
  );

  useEffect(
    () => {
      if (MRRStatus) {
        MRRInfo?.invoice?.invoiceNo &&
          setValue(createMrrkeys.invoiceNo, MRRInfo?.invoice?.invoiceNo);
        MRRInfo?.invoice?.invoiceDate &&
          setValue(
            createMrrkeys.invoiceDate,
            new Date(MRRInfo?.invoice?.invoiceDate)
          );
        MRRInfo?.invoice?.invoiceAmount &&
          setValue(
            createMrrkeys.invoiceAmount,
            MRRInfo?.invoice?.invoiceAmount
          );
        MRRInfo?.vehicle?.regNo &&
          setValue(createMrrkeys.truckRegNo, MRRInfo?.vehicle?.regNo);
        MRRInfo?.remarks && setValue(createMrrkeys.remarks, MRRInfo?.remarks);
      }
    },
    // eslint-disable-next-line
    [MRRStatus]
  );

  const skuWatch = watch("itemsRecieved");

  const onSubmitClick = (data) => {
    showDialog({
      component: (
        <SuccessfulDialog
          text={"Are you sure you want to submit the document ?"}
          subText={"Submitted documents cannot be edited. "}
          btn1Text={"Go to Draft"}
          btn2Text={"Submit"}
          btn1Callback={() => hideDialog()}
          btn2Callback={() => submitMrr(data)}
        />
      )
    });
  };

  const onSaveDraft = (data) => {
    const formData = new FormData();
    data[createMrrkeys.invoiceNo] &&
      formData.append("invoice[invoiceNo]", data[createMrrkeys.invoiceNo]);
    data[createMrrkeys.invoiceDate] &&
      formData.append(
        "invoice[invoiceDate]",
        moment(data[createMrrkeys.invoiceDate]).format("YYYY-MM-DD")
      );
    data[createMrrkeys.invoiceAmount] &&
      formData.append(
        "invoice[invoiceAmount]",
        +data[createMrrkeys.invoiceAmount]
      );
    data[createMrrkeys.truckRegNo] &&
      formData.append("vehicle[regNo]", data[createMrrkeys.truckRegNo]);
    data[createMrrkeys.remarks] &&
      formData.append("remarks", data[createMrrkeys.remarks]);
    const items =
      MRRStatus === "Draft"
        ? data["itemsRecieved"]?.filter((item) => +item.quantity !== 0)
        : data["itemsRecieved"]?.filter((item) => +item.newQuantity !== 0);
    items?.forEach((item, index) => {
      formData.append(`items[${index}][sku]`, item.sku);
      MRRStatus === "Draft"
        ? formData.append(`items[${index}][quantity]`, +item.quantity)
        : formData.append(`items[${index}][quantity]`, +item.newQuantity);
      item?.reject?.quantity &&
        formData.append(
          `items[${index}][reject][quantity]`,
          +item.reject.quantity
        );
      item?.reject?.reason?.name &&
        formData.append(
          `items[${index}][reject][reason]`,
          item.reject.reason?.name
        );
    });
    const files = data["attachments"];
    if (files && files?.length > 0) {
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
    }
    MRRStatus === "Draft"
      ? updateMRRDraft(
          {
            data: formData,
            orderId: orderId,
            mrrId: MRRInfo?._id
          },
          {
            onSuccess: (res) => {
              enqueueSnackbar("MRR Draft Updated!", {
                variant: "success"
              });
              reset();
              refetchMrrs();
            }
          }
        )
      : saveDraftMrr(
          {
            data: formData,
            orderId: orderId
          },
          {
            onSuccess: (res) => {
              enqueueSnackbar("MRR Draft Saved!", {
                variant: "success"
              });
              reset();
              refetchMrrs();
            }
          }
        );
  };

  const submitMrr = (data) => {
    const formData = new FormData();
    data[createMrrkeys.invoiceNo] &&
      formData.append("invoice[invoiceNo]", data[createMrrkeys.invoiceNo]);
    data[createMrrkeys.invoiceDate] &&
      formData.append(
        "invoice[invoiceDate]",
        moment(data[createMrrkeys.invoiceDate]).format("YYYY-MM-DD")
      );
    data[createMrrkeys.invoiceAmount] &&
      formData.append(
        "invoice[invoiceAmount]",
        +data[createMrrkeys.invoiceAmount]
      );
    data[createMrrkeys.truckRegNo] &&
      formData.append("vehicle[regNo]", data[createMrrkeys.truckRegNo]);
    data[createMrrkeys.remarks] &&
      formData.append("remarks", data[createMrrkeys.remarks]);
    const items =
      MRRStatus === "Draft"
        ? data["itemsRecieved"]?.filter((item) => +item.quantity !== 0)
        : data["itemsRecieved"]?.filter((item) => +item.newQuantity !== 0);
    items?.forEach((item, index) => {
      formData.append(`items[${index}][sku]`, item.sku);
      MRRStatus === "Draft"
        ? formData.append(`items[${index}][quantity]`, +item.quantity)
        : formData.append(`items[${index}][quantity]`, +item.newQuantity);
      item?.reject?.quantity &&
        formData.append(
          `items[${index}][reject][quantity]`,
          +item.reject.quantity
        );
      item?.reject?.reason?.name &&
        formData.append(
          `items[${index}][reject][reason]`,
          item.reject.reason?.name
        );
    });
    const files = data["attachments"];
    if (files && files?.length) {
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
    }
    MRRStatus === "Draft"
      ? submitMRRDraft(
          {
            data: formData,
            orderId: orderId,
            mrrId: MRRInfo?._id
          },
          {
            onSuccess: (res) => {
              hideDialog();
              enqueueSnackbar("MRR Created!", {
                variant: "success"
              });
              reset();
              refetchMrrs();
              orderInfo?.orderType?.name === "StockTransfer"
                ? orderInfo?.itemDetails?.outQuantity >
                    orderInfo?.itemDetails?.inQuantity && refetchFormData()
                : orderInfo?.itemDetails?.quantity >
                    orderInfo?.itemDetails?.inQuantity && refetchFormData();
            }
          }
        )
      : createNewMrr(
          {
            data: formData,
            orderId: orderId
          },
          {
            onSuccess: (res) => {
              hideDialog();
              enqueueSnackbar("MRR Created!", {
                variant: "success"
              });
              reset();
              refetchMrrs();
              orderInfo?.orderType?.name === "StockTransfer"
                ? orderInfo?.itemDetails?.outQuantity >
                    orderInfo?.itemDetails?.inQuantity && refetchFormData()
                : orderInfo?.itemDetails?.quantity >
                    orderInfo?.itemDetails?.inQuantity && refetchFormData();
            }
          }
        );
  };

  const onDeleteClick = () => {
    showDialog({
      component: (
        <SuccessfulDialog
          text={"Are you sure you want to Delete this draft ?"}
          subText={"Deleted documents cannot be recovered. "}
          btn1Text={"Go to Draft"}
          btn2Text={"Delete"}
          btn1Callback={() => hideDialog()}
          btn2Callback={() => deleteMrr()}
        />
      )
    });
  };

  const deleteMrr = () => {
    MRRStatus === "Draft" &&
      deleteMRRDraft(
        {
          orderId: orderId,
          mrrId: MRRInfo?._id
        },
        {
          onSuccess: (res) => {
            hideDialog();
            enqueueSnackbar("MRR Draft Deleted!", {
              variant: "success"
            });
            reset();
            refetchMrrs();
          }
        }
      );
  };

  useEffect(() => {
    if (isCreateError) {
      enqueueSnackbar(createError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isSaveDraftError)
      enqueueSnackbar(saveDraftError?.response?.data?.message, {
        variant: "error"
      });
    if (isUpdateDraftError)
      enqueueSnackbar(updateDraftError?.response?.data?.message, {
        variant: "error"
      });
    if (isSubmitDraftError) {
      enqueueSnackbar(submitDraftError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isDeleteDraftError) {
      enqueueSnackbar(deleteDraftError?.response?.data?.message, {
        variant: "error"
      });
    }
  }, [
    createError?.response?.data?.message,
    saveDraftError?.response?.data?.message,
    updateDraftError?.response?.data?.message,
    submitDraftError?.response?.data?.message,
    deleteDraftError?.response?.data?.message,
    isSaveDraftError,
    isCreateError,
    isUpdateDraftError,
    isSubmitDraftError,
    isDeleteDraftError
  ]);

  useEffect(() => {
    if (skuWatch && fields.length) {
      setCostBreakUp({
        gross:
          +calculateAcceptedSum(skuWatch, Boolean(MRRStatus)) +
          +calculateRejectedSum(skuWatch),
        deduct: calculateRejectedSum(skuWatch),
        net: calculateAcceptedSum(skuWatch, Boolean(MRRStatus))
      });
    } // eslint-disable-next-line
  }, [JSON.stringify(skuWatch)]);

  const MrrDoc = ({ children }) => {
    return (
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={6}
        border={"0.7px solid #6097FF"}
        borderRadius={"8px"}
        justifyContent={"center"}
        alignItems={"center"}
        padding={"30px 20px"}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 500,
            lineHeight: "24px",
            textAlign: "center"
          }}
        >
          {"Material Received Report"}
        </Typography>
        <Grid container display={"flex"} justifyContent={"space-between"}>
          <Grid item xs={4} display={"flex"} flexDirection={"column"} gap={6}>
            <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
              <LabelValueText
                label={`${orderInfo?.orderType?.displayName} No :`}
                value={orderInfo?.code}
              />
              <LabelValueText
                label={"Order date :"}
                value={moment(orderInfo?.orderDate).format("DD-MM-YYYY")}
              />
            </Box>
            <Box textAlign={"left"}>
              <AddressSection
                title={"Receiving Address"}
                name={`${orderInfo?.clientData?.warehouse?.code}`}
                addressLine1={`${orderInfo?.clientData?.companyName ?? ""}`}
                addressLine2={`${orderInfo?.clientData?.warehouse?.address?.address}, ${orderInfo?.clientData?.warehouse?.address?.area}, ${orderInfo?.clientData?.warehouse?.address?.city},${orderInfo?.clientData?.warehouse?.address?.state} ${orderInfo?.clientData?.warehouse?.address?.pincode}`}
              />
            </Box>
          </Grid>
          {logo?.thumbUrl && (
            <Grid
              item
              xs={4}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <img src={logo?.thumbUrl} alt="Company Logo" />
            </Grid>
          )}
          <Grid
            item
            xs={4}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"flex-end"}
            alignItems={"flex-end"}
            gap={6}
          >
            <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
              <LabelValueText
                label={"MRR No :"}
                value={MRRInfo?.code ?? "--"}
              />
              <LabelValueText
                label={"MRR date :"}
                value={
                  MRRInfo ? moment(MRRInfo?.docDate).format("DD-MM-YYYY") : "--"
                }
              />
            </Box>
            <Box textAlign={"left"}>
              <AddressSection
                title={"Seller Address"}
                name={`${orderInfo?.vendorData?.name}`}
                addressLine1={`${orderInfo?.vendorData?.companyName ?? ""}`}
                addressLine2={`${orderInfo?.vendorData?.billing?.address?.address}, ${orderInfo?.vendorData?.billing?.address?.area}, ${orderInfo?.vendorData?.billing?.address?.city},${orderInfo?.vendorData?.billing?.address?.state} ${orderInfo?.vendorData?.billing?.address?.pincode}`}
              />
            </Box>
          </Grid>
        </Grid>
        {Boolean(MRRStatus === "Placed") ? (
          <Grid container justifyContent={"space-between"}>
            <Grid item>
              <LabelValueText
                label={createMrr_labels.invoiceNo}
                value={MRRInfo?.invoice?.invoiceNo ?? "--"}
              />
            </Grid>
            <Grid item>
              <LabelValueText
                label={createMrr_labels.invoiceDate}
                value={MRRInfo?.invoice?.invoiceDate ?? "--"}
              />
            </Grid>
            <Grid item>
              <LabelValueText
                label={createMrr_labels.invoiceAmount}
                value={MRRInfo?.invoice?.invoiceAmount ?? "--"}
              />
            </Grid>
            <Grid item>
              <LabelValueText
                label={createMrr_labels.truckRegNo}
                value={MRRInfo?.vehicle?.regNo ?? "--"}
              />
            </Grid>
          </Grid>
        ) : (
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            gap={2}
          >
            <LabelTextInput
              control={control}
              name={createMrrkeys.invoiceNo}
              label={createMrr_labels.invoiceNo}
            />
            <LabelTextInput
              control={control}
              name={createMrrkeys.invoiceDate}
              label={createMrr_labels.invoiceDate}
              isDateInput={true}
            />
            <LabelTextInput
              control={control}
              name={createMrrkeys.invoiceAmount}
              label={createMrr_labels.invoiceAmount}
            />
            <LabelTextInput
              control={control}
              name={createMrrkeys.truckRegNo}
              label={createMrr_labels.truckRegNo}
            />
          </Box>
        )}
        {children}
        <Box
          display={"flex"}
          justifyContent={"space-around"}
          alignItems={"center"}
          width={"100%"}
        >
          <Box width={"500px"}>
            <TextArea
              control={control}
              name={createMrrkeys.remarks}
              label={createMrr_labels.remarks}
              disabled={Boolean(MRRStatus === "Placed")}
            />
          </Box>
          <Box display={"flex"} flexDirection={"column"} gap={3}>
            <LabelValueText
              label={"Gross Amount (INR) :"}
              value={currencyOnlyFormatter(
                parseFloat(costBreakUp.gross).toFixed(2)
              )}
            />
            <LabelValueText
              label={"Deduct Amount (INR) :"}
              value={currencyOnlyFormatter(
                parseFloat(costBreakUp.deduct).toFixed(2)
              )}
            />
            <LabelValueText
              label={"Net Amount (INR) :"}
              value={currencyOnlyFormatter(
                parseFloat(costBreakUp.net).toFixed(2)
              )}
            />
          </Box>
        </Box>
        <Box display={"flex"} width={"100%"}>
          <LabelValueText
            label={"Prepared By:"}
            value={orderInfo?.clientData?.name}
          />
        </Box>
      </Box>
    );
  };

  const mrrTable = useMemo(
    () => (
      <CustomTable
        columns={getColumns(Boolean(MRRStatus === "Placed"))}
        data={getRows(fields, control, MRRStatus, rejectOptions)}
        mobileComponent={MobileMrrTable}
      />
    ),
    [MRRStatus, control, fields, rejectOptions]
  );

  const OtherInfoLabelText = [
    {
      label: createMrr_labels.invoiceNo,
      value: MRRInfo?.invoice?.invoiceNo ?? "--"
    },
    {
      label: createMrr_labels.mobileInvoiceDate,
      value: MRRInfo?.invoice?.invoiceDate ?? "--"
    },
    {
      label: createMrr_labels.invoiceAmount,
      value: MRRInfo?.invoice?.invoiceAmount ?? "--"
    },
    {
      label: createMrr_labels.truckRegNo,
      value: MRRInfo?.vehicle?.regNo ?? "--"
    }
  ];

  const OtherDetailsComponent = Boolean(MRRStatus === "Placed") ? (
    <Grid container rowSpacing={2}>
      {OtherInfoLabelText?.map((item, index) => (
        <DocInfo key={index} label={item.label} value={item.value} />
      ))}
    </Grid>
  ) : (
    <Box display={"flex"} flexDirection={"column"} gap={1}>
      <MobileLabelInput
        control={control}
        name={createMrrkeys.invoiceNo}
        label={createMrr_labels.invoiceNo}
      />
      <MobileLabelInput
        control={control}
        name={createMrrkeys.invoiceDate}
        label={"Invoice Date :"}
        isDateInput={true}
      />
      <MobileLabelInput
        control={control}
        name={createMrrkeys.invoiceAmount}
        label={createMrr_labels.invoiceAmount}
      />
      <MobileLabelInput
        control={control}
        name={createMrrkeys.truckRegNo}
        label={createMrr_labels.truckRegNo}
      />
    </Box>
  );

  const Attachments = (
    <>
      {MRRInfo?.files?.length > 0 && (
        <Box display={"flex"} flexDirection={"column"} gap={2} padding={"10px"}>
          {MRRInfo?.files?.map((file, index) => (
            <Box key={index} display={"flex"} alignItems={"center"} gap={2}>
              <Verified sx={{ color: "primary.main" }} />
              <Button
                variant="link"
                disableRipple
                sx={{ fontWeight: 600 }}
                onClick={() => handleDownload(file.fileUrl)}
              >
                {file.originalName}
              </Button>
            </Box>
          ))}
        </Box>
      )}
      {MRRStatus !== "Placed" && MRRInfo?.files?.length !== 3 && (
        <Grid item xs={12} md={3}>
          <ImageUpload
            control={control}
            name={createMrrkeys.attachments}
            setValue={setValue}
            compact={true}
            uploadButtonText={createMrr_labels.attachment}
            isMultiple={true}
            acceptFileType=".jpg, .jpeg, .png, .pdf"
            minimumUploads={
              MRRInfo?.files?.length ? 3 - MRRInfo?.files?.length : 3
            }
            showTextOnly
          />
        </Grid>
      )}
    </>
  );

  return (
    <>
      {/* Mobile view */}
      <Box sx={{ display: { xs: "block", md: "none" }, px: "15px", mb: 9 }}>
        <MobileOrdersPage
          showOtherDetails
          showAttachments
          Status={MRRStatus}
          title={"Material Received Report"}
          docInfo={[
            {
              label: "MRR No :",
              value: MRRInfo?.code ?? "--"
            },
            {
              label: "MRR date :",
              value: MRRInfo
                ? moment(MRRInfo?.docDate).format("DD-MM-YYYY")
                : "--"
            },
            {
              label: `${orderInfo?.orderType?.displayName} No.:`,
              value: orderInfo?.code
            },
            {
              label: "Order date :",
              value: moment(orderInfo?.orderDate).format("DD-MM-YYYY")
            }
          ]}
          accordianInfo={[
            {
              title: "Seller Address",
              name: `${orderInfo?.vendorData?.name}`,
              addressLine1: `${orderInfo?.vendorData?.companyName ?? ""}`,
              addressLine2: `${orderInfo?.vendorData?.billing?.address?.address}, ${orderInfo?.vendorData?.billing?.address?.area}, ${orderInfo?.vendorData?.billing?.address?.city},${orderInfo?.vendorData?.billing?.address?.state} ${orderInfo?.vendorData?.billing?.address?.pincode}`
            },
            {
              title: "Receiving Address",
              name: `${orderInfo?.clientData?.warehouse?.code}`,
              addressLine1: `${orderInfo?.clientData?.companyName ?? ""}`,
              addressLine2: `${orderInfo?.clientData?.warehouse?.address?.address}, ${orderInfo?.clientData?.warehouse?.address?.area}, ${orderInfo?.clientData?.warehouse?.address?.city},${orderInfo?.clientData?.warehouse?.address?.state} ${orderInfo?.clientData?.warehouse?.address?.pincode}`
            }
          ]}
          otherInfo={OtherDetailsComponent}
          mobileTable={mrrTable}
          Total={[
            {
              label: "Gross Amount :",
              value: currencyOnlyFormatter(
                parseFloat(costBreakUp.gross).toFixed(2)
              )
            },
            {
              label: "Deduct Amount :",
              value: currencyOnlyFormatter(
                parseFloat(costBreakUp.deduct).toFixed(2)
              )
            },
            {
              label: "Net Amount :",
              value: currencyOnlyFormatter(
                parseFloat(costBreakUp.net).toFixed(2)
              )
            }
          ]}
          Remarks={
            <TextArea
              control={control}
              name={createMrrkeys.remarks}
              disabled={Boolean(MRRStatus === "Placed")}
            />
          }
          Attachments={Attachments}
          preparedBy={orderInfo?.clientData?.name}
          ButtonGroup={
            <ButtonGroup
              Status={MRRStatus}
              onDeleteClick={() => onDeleteClick()}
              onSaveDraft={handleSubmit(onSaveDraft)}
              onSubmitClick={handleSubmit(onSubmitClick)}
            />
          }
        />
      </Box>
      {/* Web view */}
      <Box
        sx={{ display: { xs: "none", md: "flex" } }}
        flexDirection={"column"}
        gap={2}
        mt={!Boolean(MRRStatus) ? 0 : -5}
        zIndex={1}
      >
        {MRRStatus === "Placed" ? (
          <Box display={"flex"} justifyContent={"flex-end"}>
            <Button variant="link" disableRipple onClick={handlePrint}>
              Print
            </Button>
          </Box>
        ) : (
          <Box display={"flex"} justifyContent={"flex-end"} gap={2}>
            {MRRStatus === "Draft" && (
              <Button
                variant="link"
                disableRipple
                onClick={() => onDeleteClick()}
                sx={{ color: "error.main" }}
              >
                Delete Draft
              </Button>
            )}
            <Button
              variant="link"
              disableRipple
              onClick={handleSubmit(onSaveDraft)}
            >
              Save Draft
            </Button>
            <Button
              variant="link"
              disableRipple
              onClick={handleSubmit(onSubmitClick)}
            >
              Submit
            </Button>
          </Box>
        )}
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={6}
          border={"0.7px solid #6097FF"}
          borderRadius={"8px"}
          justifyContent={"center"}
          alignItems={"center"}
          padding={"30px 20px"}
        >
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 500,
              lineHeight: "24px",
              textAlign: "center"
            }}
          >
            {"Material Received Report"}
          </Typography>
          <Grid container display={"flex"} justifyContent={"space-between"}>
            <Grid item xs={4} display={"flex"} flexDirection={"column"} gap={6}>
              <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                <LabelValueText
                  label={`${orderInfo?.orderType?.displayName} No :`}
                  value={orderInfo?.code}
                />
                <LabelValueText
                  label={"Order date :"}
                  value={moment(orderInfo?.orderDate).format("DD-MM-YYYY")}
                />
              </Box>
              <Box textAlign={"left"}>
                <AddressSection
                  title={"Receiving Address"}
                  name={`${orderInfo?.clientData?.warehouse?.code}`}
                  addressLine1={`${orderInfo?.clientData?.companyName ?? ""}`}
                  addressLine2={`${orderInfo?.clientData?.warehouse?.address?.address}, ${orderInfo?.clientData?.warehouse?.address?.area}, ${orderInfo?.clientData?.warehouse?.address?.city},${orderInfo?.clientData?.warehouse?.address?.state} ${orderInfo?.clientData?.warehouse?.address?.pincode}`}
                />
              </Box>
            </Grid>
            {logo?.thumbUrl && (
              <Grid
                item
                xs={4}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <img src={logo?.thumbUrl} alt="Company Logo" />
              </Grid>
            )}
            <Grid
              item
              xs={4}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"flex-end"}
              alignItems={"flex-end"}
              gap={6}
            >
              <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                <LabelValueText
                  label={"MRR No :"}
                  value={MRRInfo?.code ?? "--"}
                />
                <LabelValueText
                  label={"MRR date :"}
                  value={
                    MRRInfo
                      ? moment(MRRInfo?.docDate).format("DD-MM-YYYY")
                      : "--"
                  }
                />
              </Box>
              <Box textAlign={"left"}>
                <AddressSection
                  title={"Seller Address"}
                  name={`${orderInfo?.vendorData?.name}`}
                  addressLine1={`${orderInfo?.vendorData?.companyName ?? ""}`}
                  addressLine2={`${orderInfo?.vendorData?.billing?.address?.address}, ${orderInfo?.vendorData?.billing?.address?.area}, ${orderInfo?.vendorData?.billing?.address?.city},${orderInfo?.vendorData?.billing?.address?.state} ${orderInfo?.vendorData?.billing?.address?.pincode}`}
                />
              </Box>
            </Grid>
          </Grid>
          {Boolean(MRRStatus === "Placed") ? (
            <Grid container justifyContent={"space-between"}>
              <Grid item>
                <LabelValueText
                  label={createMrr_labels.invoiceNo}
                  value={MRRInfo?.invoice?.invoiceNo ?? "--"}
                />
              </Grid>
              <Grid item>
                <LabelValueText
                  label={createMrr_labels.invoiceDate}
                  value={MRRInfo?.invoice?.invoiceDate ?? "--"}
                />
              </Grid>
              <Grid item>
                <LabelValueText
                  label={createMrr_labels.invoiceAmount}
                  value={MRRInfo?.invoice?.invoiceAmount ?? "--"}
                />
              </Grid>
              <Grid item>
                <LabelValueText
                  label={createMrr_labels.truckRegNo}
                  value={MRRInfo?.vehicle?.regNo ?? "--"}
                />
              </Grid>
            </Grid>
          ) : (
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={2}
            >
              <LabelTextInput
                control={control}
                name={createMrrkeys.invoiceNo}
                label={createMrr_labels.invoiceNo}
              />
              <LabelTextInput
                control={control}
                name={createMrrkeys.invoiceDate}
                label={createMrr_labels.invoiceDate}
                isDateInput={true}
              />
              <LabelTextInput
                control={control}
                name={createMrrkeys.invoiceAmount}
                label={createMrr_labels.invoiceAmount}
              />
              <LabelTextInput
                control={control}
                name={createMrrkeys.truckRegNo}
                label={createMrr_labels.truckRegNo}
              />
            </Box>
          )}
          <Box display={"flex"} flexDirection={"column"} gap={2} width={"100%"}>
            {mrrTable}
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-around"}
            alignItems={"center"}
            width={"100%"}
          >
            <Box width={"500px"}>
              <TextArea
                control={control}
                name={createMrrkeys.remarks}
                label={createMrr_labels.remarks}
                disabled={Boolean(MRRStatus === "Placed")}
              />
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={3}>
              <LabelValueText
                label={"Gross Amount (INR) :"}
                value={currencyOnlyFormatter(
                  parseFloat(costBreakUp.gross).toFixed(2)
                )}
              />
              <LabelValueText
                label={"Deduct Amount (INR) :"}
                value={currencyOnlyFormatter(
                  parseFloat(costBreakUp.deduct).toFixed(2)
                )}
              />
              <LabelValueText
                label={"Net Amount (INR) :"}
                value={currencyOnlyFormatter(
                  parseFloat(costBreakUp.net).toFixed(2)
                )}
              />
            </Box>
          </Box>
          <Box display={"flex"} width={"100%"}>
            <LabelValueText
              label={"Prepared By:"}
              value={orderInfo?.clientData?.name}
            />
          </Box>
        </Box>
        {MRRStatus === "Placed" && (
          <div style={{ display: "none" }}>
            <Box ref={componentRef} sx={{ padding: "10px" }}>
              <MrrDoc>
                <Grid container>
                  <PrintableTable
                    columns={getColumns(Boolean(MRRStatus === "Placed"))}
                    rows={getRows(fields, control, MRRStatus, rejectOptions)}
                    docType="Mrr"
                  />
                </Grid>
              </MrrDoc>
            </Box>
          </div>
        )}
      </Box>
      <Grid container sx={{ display: { xs: "none", md: "block" } }}>
        {Attachments}
      </Grid>
    </>
  );
};

export default MrrTable;
