import { Box, Typography } from "@mui/material";
import ContactUsSection from "../components/ContactUsSection";
import Footer from "../components/Footer";
import { contactUsFormKeys } from "../../constants/formKeys";
import { home_labels, labels } from "../../constants";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import styled from "@emotion/styled";

export const StyledHeaderText = styled(Typography)((props) => ({
  fontSize: props.isMobile ? "20px" : "36px",
  fontWeight: 700,
  lineHeight: props.isMobile ? "30px" : "54px",
  letterSpacing: props.isMobile ? "-0.48px" : "-0.64px"
}));

export const StyledText = styled(Typography)((props) => ({
  fontSize: props.isMobile ? "14px" : "20px",
  fontWeight: 400,
  lineHeight: props.isMobile ? "21px" : "30px",
  letterSpacing: "-0.01em",
  textAlign: "justify",
  color: "#607088"
}));

const SaaS = () => {
  const { control } = useForm({
    defaultValues: {
      [contactUsFormKeys.name]: "",
      [contactUsFormKeys.number]: "",
      [contactUsFormKeys.email]: "",
      [contactUsFormKeys.subject]: "",
      [contactUsFormKeys.description]: ""
    }
  });

  useEffect(() => {
    window.scroll({
      left: 0,
      top: 0,
      behavior: "smooth"
    });
  }, []);

  return (
    <>
      <Box
        sx={{
          display: { xs: "flex", md: "none" },
          flexDirection: "column",
          padding: "30px 25px 0px 25px",
          gap: 2
        }}
      >
        <StyledHeaderText
          color={"#2773FF"}
          component="h1"
          isMobile={true}
          textAlign={"center"}
        >
          {`${labels.saasHeaderText1} `}
          <span style={{ color: "#F9A90E" }}>{labels.saasHeaderText2}</span>
        </StyledHeaderText>
        <Box display={"flex"} flexDirection={"column"} gap={2}>
          <Box>
            <iframe
              loading="lazy"
              width="320"
              height="240"
              title="Warex-SaaS"
              src="https://www.youtube.com/embed/s3w6fEdlU18?playlist=s3w6fEdlU18&loop=1"
            ></iframe>
          </Box>
          <Box display={"flex"} flexDirection={"column"} gap={2}>
            <StyledText isMobile>
              Warex's Supply Chain As A Service Platform hosts 3 services.
            </StyledText>
            <StyledText isMobile>
              A{" "}
              <span style={{ color: "#F9A90E", fontWeight: "bold" }}>
                MARKETPLACE
              </span>{" "}
              of warehouses and trucks to meet your on-demand logistics
              requirements.
            </StyledText>
            <StyledText isMobile>
              An{" "}
              <span style={{ color: "#F9A90E", fontWeight: "bold" }}>
                ERP SAAS
              </span>{" "}
              with several features including Inventory management, Warehouse
              Operations, Purchases, Sales and Finance activities.
            </StyledText>
            <StyledText isMobile>
              A{" "}
              <span style={{ color: "#F9A90E", fontWeight: "bold" }}>
                CREDIT
              </span>{" "}
              availing service exclusive for our users through which our
              partnered NBFCs can advance cash against your purchase bills and
              sales invoices either by discounting or factoring.
            </StyledText>
          </Box>
        </Box>
        <Typography
          sx={{
            color: "text.secondary",
            fontSize: { xs: "12px", md: "22px" },
            lineHeight: { xs: "18px", md: "33px" },
            letterSpacing: "-1%",
            textAlign: "justify"
          }}
          fontWeight={700}
        >
          {labels.contactUsforDemoText}
        </Typography>
      </Box>
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          flexDirection: "column",
          padding: "30px 70px 0px 80px",
          gap: 2
        }}
      >
        <StyledHeaderText color={"#2773FF"} component="h1">
          {`${labels.saasHeaderText1} `}
          <span style={{ color: "#F9A90E" }}>{labels.saasHeaderText2}</span>
        </StyledHeaderText>
        <Box display={"flex"} gap={2}>
          <Box>
            <iframe
              loading="lazy"
              width="700"
              height="500"
              title="Warex-SaaS-Video"
              src="https://www.youtube.com/embed/s3w6fEdlU18?playlist=s3w6fEdlU18&loop=1"
            ></iframe>
          </Box>
          <Box display={"flex"} flexDirection={"column"} gap={3.5}>
            <StyledText>
              Warex's Supply Chain As A Service Platform hosts 3 services.
            </StyledText>
            <StyledText>
              A{" "}
              <span style={{ color: "#F9A90E", fontWeight: "bold" }}>
                MARKETPLACE
              </span>{" "}
              of warehouses and trucks to meet your on-demand logistics
              requirements.
            </StyledText>
            <StyledText>
              An{" "}
              <span style={{ color: "#F9A90E", fontWeight: "bold" }}>
                ERP SAAS
              </span>{" "}
              with several features including Inventory management, Warehouse
              Operations, Purchases, Sales and Finance activities.
            </StyledText>
            <StyledText>
              A{" "}
              <span style={{ color: "#F9A90E", fontWeight: "bold" }}>
                CREDIT
              </span>{" "}
              availing service exclusive for our users through which our
              partnered NBFCs can advance cash against your purchase bills and
              sales invoices either by discounting or factoring.
            </StyledText>
          </Box>
        </Box>
        <Typography
          sx={{
            color: "text.secondary",
            fontSize: { xs: "12px", md: "22px" },
            lineHeight: { xs: "18px", md: "33px" },
            letterSpacing: "-1%",
            textAlign: "justify"
          }}
          fontWeight={700}
        >
          {labels.contactUsforDemoText}
        </Typography>
      </Box>
      <ContactUsSection
        control={control}
        title={home_labels.partnerWithUsTitleText}
      />
      <Footer />
    </>
  );
};
export default SaaS;
