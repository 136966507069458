import appRoutes from "../../constants/appRoutes";

export const seoContent = {
  [appRoutes.home]: {
    title: "Warex: One Stop Supply Chain Solution",
    description:
      "Supply Chain as a Service platform hosting several solutions to Small Businesses including a Simple ERP, an Easy-to-use Inventory Management, a Marketplace of Warehouses and Trucks and Credit facility to SMEs ."
  },
  [appRoutes.packersAndMovers.hyderabad]: {
    title:
      "House Shifting in Hyderabad - Get free Quotes from Multiple Packers and Movers through Warex",
    description:
      "100% Best and Safe Packers and Movers in Hyderabad at Cheap Prices. House Shifting made easy. Get multiple Quotes from our partners and negotiate online."
  },
  [appRoutes.packersAndMovers.bengaluru]: {
    title:
      "House Shifting in Bengaluru- Get free Quotes from Multiple Packers and Movers through Warex",
    description:
      "100% Best and Safe Packers and Movers in Bengaluru at affordable Prices. Moving Home is hassle free now. Get multiple Quotes from our partners and negotiate online."
  },
  [appRoutes.inventoryMangement]: {
    title:
      "Easy-to-use Inventory Management Software - Designed for Small Businesses",
    description:
      "Inventory management software made easy to use for Small Businesses, Distributors, Dealers, Wholesalers in FMCG, Apparel, Footwear, Food & Beverages, Furniture, Chemicals, Tools & Hardware and Electrical & Home Appliances."
  },
  [appRoutes.erp]: {
    title: "Simple ERP Software designed for Small Businesses",
    description:
      "ERP Software simply designed for Small Businesses, Distributors, Dealers, Wholesalers in FMCG, Apparel, Footwear, Food & Beverages, Furniture, Chemicals, Tools & Hardware and Electrical & Home Appliances."
  },
  [appRoutes.credit]: {
    title: "Credit and Financing facility to Small Businesses",
    description:
      "Warex Provides Credit and financing facility to Small Businesses, Distributors, Dealers, Wholesalers in FMCG, Apparel, Footwear, Food & Beverages, Furniture, Chemicals, Tools & Hardware and Electrical & Home Appliances. Our Partnered NBFCs offer advance cash through Invoice Discounting."
  },
  [appRoutes.marketplace]: {
    title:
      "On Demand Logistics and Pay-per-use Warehouses and Trucks",
    description:
      "Find Warehouses and Find Trucks. Request for multiple quotes from multiple partners and negotiate online for best prices and pay only per use ."
  },
  [appRoutes.saas]: {
    title:
      "Supply Chain as a Service platform for SMEs",
    description:
      "Warex - SCaaS (Supply Chain as a Service) hosts a marketplace, an ERP and Credit facility ."
  },
  [appRoutes.pricing]: {
    title:
      "Pricing Plans for Warex SCaaS",
    description:
      "Warex Standard plan is aimed to encompass at least one user from each of Purchase, Sales, Finance, Inventory and Warehouse Operations. We offer lowest pricing compared to several ERPs and SAAS platforms ."
  }
};
