import { deleteSADraft } from "../../../purchase/services";
import { useMutation } from "@tanstack/react-query";

export const useDeleteSADraft = () => {
  const deleteDraft = useMutation(({ orderId }) =>
    deleteSADraft(orderId)
  );

  return deleteDraft;
};
