import { Box, Divider } from "@mui/material";
import React from "react";
import { TitleText } from "../../warehouse/pages/Assets";
import { StyledChip } from "../../shared/UiElements/StatusChip";
import moment from "moment";
import { currencyOnlyFormatter } from "../../shared/utils";
import CustomTable from "../../shared/customTable";
import { useFetchAllSubscriptions } from "../../query-hooks/ClientSettings/useFetchAllSubscriptions";
import { MobileSubscriptionTable } from "./MobileSubscriptionTable";

const getColumns = () => {
  return [
    {
      Header: "Subscription ID",
      accessor: "subId",
      width: 159,
      disableSortBy: true
    },
    {
      Header: "Start Date",
      accessor: "startDate",
      width: 119,
      disableSortBy: true,
      Cell: ({ value }) => {
        return value && moment(value).format("DD-MM-YYYY");
      }
    },
    {
      Header: "End Date",
      accessor: "endDate",
      width: 119,
      disableSortBy: true,
      Cell: ({ value }) => {
        return value && moment(value).format("DD-MM-YYYY");
      }
    },
    {
      Header: "Amount Paid",
      accessor: "amountPaid",
      width: 120,
      disableSortBy: true,
      Cell: ({ value }) => {
        return value && currencyOnlyFormatter(parseFloat(value).toFixed(2));
      }
    },
    {
      Header: "Reference No.",
      accessor: "referenceNo",
      width: 140,
      disableSortBy: true
    },
    {
      Header: "Date of Payment",
      accessor: "dateOfPayment",
      width: 153,
      disableSortBy: true,
      Cell: ({ value }) => {
        return value && moment(value).format("DD-MM-YYYY");
      }
    },
    {
      Header: "Number of Users",
      accessor: "numberOfUsers",
      width: 154,
      disableSortBy: true
    },
    {
      Header: "Status",
      accessor: "status",
      width: 158,
      disableSortBy: true,
      Cell: ({ value }) => {
        return value && <StyledChip label={value} />;
      }
    }
  ];
};

const getRows = (rows = []) => {
  return rows.map((item, index) => {
    return {
      subId: item.code,
      startDate: item.startDate,
      endDate: item.endDate,
      amountPaid: item.amount,
      referenceNo: item.reference,
      dateOfPayment: item.paymentDate,
      numberOfUsers: item.userCount,
      status: item.status.name
    };
  });
};

const SubscriptionTable = ({ clientId }) => {
  const { data: subscriptionData, isFetching: isFetchingSubscriptions } =
    useFetchAllSubscriptions(clientId);

  return (
    <Box display={"flex"} flexDirection={"column"} gap={2}>
      <Box
        sx={{ display: { xs: "none", md: "flex" } }}
        gap={8}
        justifyContent={"flex-start"}
        alignItems={"center"}
      >
        <TitleText>{"Subscription"}</TitleText>
      </Box>
      <Divider
        sx={{
          border: "0.7px solid #B7BEC7",
          display: { xs: "none", md: "flex" }
        }}
      />
      <Box display={"flex"} sx={{ padding: { xs: "0 15px", md: 0 } }}>
        <CustomTable
          columns={getColumns()}
          data={
            isFetchingSubscriptions
              ? []
              : getRows(subscriptionData?.subscriptions ?? [])
          }
          isLoading={isFetchingSubscriptions}
          mobileComponent={MobileSubscriptionTable}
        />
      </Box>
    </Box>
  );
};

export default SubscriptionTable;
