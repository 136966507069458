import { getCreateSubUserFormData } from "../../settings/services";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../constants/queryKeys";

export const useFetchCreateSubUserFormData = (
  clientId,
  allowCreateFormData
) => {
  const createFormDataQuery = useQuery(
    [queryKeys.queryGetCreateSubUserFormData, clientId, allowCreateFormData],
    () => getCreateSubUserFormData(clientId),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(clientId) && allowCreateFormData
    }
  );
  return createFormDataQuery;
};
