import { queryKeys } from "../../constants/queryKeys";
import { getMrrDocumentsById } from "../../inventory/services";
import { useQuery } from "@tanstack/react-query";

export const useFetchMrrDocuments = (
  orderId,
  MrrId,
  expanded,
  isSaleReturnType,
  isPlaced
) => {
  const Documents = useQuery(
    [
      queryKeys.queryFetchMrrDocument,
      orderId,
      MrrId,
      expanded,
      isSaleReturnType,
      isPlaced
    ],
    () => getMrrDocumentsById(orderId, MrrId),
    {
      enabled:
        isPlaced &&
        isSaleReturnType &&
        expanded &&
        Boolean(orderId) &&
        Boolean(MrrId),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return Documents;
};
