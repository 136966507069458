import { queryKeys } from "../../constants/queryKeys";
import { getWarehouses } from "../../inventory/services";
import { useQuery } from "@tanstack/react-query";

export const useFetchAssetWarehouses = () => {
  const warehouses = useQuery(
    [queryKeys.queryFetchAssetWarehouses],
    () => getWarehouses(),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return warehouses;
};
