import { Box, IconButton, Typography } from "@mui/material";

import BruImage from "../../assets/bru.webp";
import CustomTable from "../../shared/customTable";
import { CustomTooltip } from "../../shared/theme/globalTheme";
import Filter from "../../purchase/components/Filter";
import Ledger from "../../assets/Ledger.webp";
import { currencyFormatter } from "../../shared/utils";
import { inventory_filterKeys } from "../../constants/formKeys";
import { inventory_table_labels } from "../../constants";
import styled from "@emotion/styled";
import { useForm } from "react-hook-form";

export const PrimaryText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: 14,
  fontFamily: "Inter",
  lineHeight: "20px",
  fontWeight: 400
}));

export const SecondaryText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 13,
  fontFamily: "Inter",
  lineHeight: "18px",
  fontWeight: 400
}));

export const BoldText = styled(Typography)(({ props }) => ({
  ...props,
  color: "#2773FF",
  fontSize: 14,
  fontFamily: "Inter",
  lineHeight: "20px"
}));

const dummy_data = [
  {
    img: BruImage,
    skuName: "BRU-COF-INS-100G",
    product: "Instant Mix",
    category: "Coffee",
    Brand: "Bru",
    units: "PCs",
    warehouse: "WH-Medchal",
    currentInventoryText: 18560,
    currentInventorySubText: 232,
    cogInventoryText: 36000,
    cogInventorySubText: 450,
    salesInventoryText: 45000,
    salesInventorySubText: 450,
    allocatedInventoryText: 45000,
    allocatedInventorySubText: 450,
    margin: 20
  },
  {
    img: BruImage,
    skuName: "BRU-COF-INS-100G",
    product: "Instant Mix",
    category: "Coffee",
    Brand: "Bru",
    units: "PCs",
    warehouse: "WH-Medchal",
    currentInventoryText: 18560,
    currentInventorySubText: 232,
    cogInventoryText: 36000,
    cogInventorySubText: 450,
    salesInventoryText: 45000,
    salesInventorySubText: 450,
    allocatedInventoryText: 45000,
    allocatedInventorySubText: 450,
    margin: 20
  },
  {
    img: BruImage,
    skuName: "BRU-COF-INS-100G",
    product: "Instant Mix",
    category: "Coffee",
    Brand: "Bru",
    units: "PCs",
    warehouse: "WH-Medchal",
    currentInventoryText: 18560,
    currentInventorySubText: 232,
    cogInventoryText: 36000,
    cogInventorySubText: 450,
    salesInventoryText: 45000,
    salesInventorySubText: 450,
    allocatedInventoryText: 45000,
    allocatedInventorySubText: 450,
    margin: 20
  },
  {
    img: BruImage,
    skuName: "BRU-COF-INS-100G",
    product: "Instant Mix",
    category: "Coffee",
    Brand: "Bru",
    units: "PCs",
    warehouse: "WH-Medchal",
    currentInventoryText: 18560,
    currentInventorySubText: 232,
    cogInventoryText: 36000,
    cogInventorySubText: 450,
    salesInventoryText: 45000,
    salesInventorySubText: 450,
    allocatedInventoryText: 45000,
    allocatedInventorySubText: 450,
    margin: 20
  }
];

const getColumns = () => {
  return [
    {
      Header: "SKU",
      accessor: "sku",
      Cell: ({ value }) => {
        return (
          <Box display={"flex"} gap={1}>
            <img
              src={value.img}
              alt="bru"
              style={{ width: "50%", maxWidth: "50%" }}
            />
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 600,
                textDecoration: "underline",
                color: "#2773FF",
                whiteSpace: "pre-wrap"
              }}
            >
              {value.name}
            </Typography>
          </Box>
        );
      }
    },
    {
      Header: "Product",
      accessor: "product",
      Cell: ({ value }) => {
        return (
          <Box display={"flex"} flexDirection={"column"}>
            <PrimaryText>{`Product: ${value.product}`}</PrimaryText>
            <SecondaryText>{`Category: ${value.category}`}</SecondaryText>
            <SecondaryText>{`Brand: ${value.brand}`}</SecondaryText>
            <SecondaryText>{`(${value.units})`}</SecondaryText>
          </Box>
        );
      }
    },
    {
      Header: "Warehouse",
      accessor: "warehouse",
      Cell: ({ value }) => {
        return (
          <BoldText
            sx={{
              fontWeight: 400,
              textDecoration: "underline"
            }}
          >
            {value}
          </BoldText>
        );
      }
    },
    {
      Header: "Current Inventory",
      accessor: "currentInventory",
      Cell: ({ value }) => {
        return (
          <Box
            display={"flex"}
            flexDirection={"column"}
            textAlign={"center"}
            gap={2}
          >
            <PrimaryText>{currencyFormatter(value.amount)}</PrimaryText>
            <SecondaryText>{`(${value.amountUnit})`}</SecondaryText>
          </Box>
        );
      }
    },
    {
      Header: "COGS",
      accessor: "cogs",
      Cell: ({ value }) => {
        return (
          <Box
            display={"flex"}
            flexDirection={"column"}
            textAlign={"center"}
            gap={2}
          >
            <PrimaryText>{currencyFormatter(value.amount)}</PrimaryText>
            <SecondaryText>{`(${value.amountUnit})`}</SecondaryText>
          </Box>
        );
      }
    },
    {
      Header: "Sales",
      accessor: "sales",
      Cell: ({ value }) => {
        return (
          <Box
            display={"flex"}
            flexDirection={"column"}
            textAlign={"center"}
            gap={2}
          >
            <PrimaryText>{currencyFormatter(value.amount)}</PrimaryText>
            <SecondaryText>{`(${value.amountUnit})`}</SecondaryText>
          </Box>
        );
      }
    },
    {
      Header: "Allocated",
      accessor: "allocated",
      Cell: ({ value }) => {
        return (
          <Box
            display={"flex"}
            flexDirection={"column"}
            textAlign={"center"}
            gap={2}
          >
            <PrimaryText>{currencyFormatter(value.amount)}</PrimaryText>
            <SecondaryText>{`(${value.amountUnit})`}</SecondaryText>
          </Box>
        );
      }
    },
    {
      Header: "Margin",
      accessor: "margin",
      Cell: ({ value }) => {
        return (
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 700,
              lineHeight: "20px",
              color: "#2773FF"
            }}
          >
            {`${value}%`}
          </Typography>
        );
      }
    },
    {
      Header: "Actions",
      accessor: "actions",
      disableSortBy: true,
      Cell: ({ value }) => {
        return (
          <CustomTooltip title={"Ledger"} placement="top">
            <IconButton>
              <img
                src={Ledger}
                className="pull-right cursor-field"
                alt={value}
              />
            </IconButton>
          </CustomTooltip>
        );
      }
    }
  ];
};

const getRows = (rows = []) => {
  return rows.map((item, index) => {
    return {
      sku: { img: item.img, name: item.skuName },
      product: {
        product: item.product,
        category: item.category,
        brand: item.Brand,
        units: item.units
      },
      warehouse: item.warehouse,
      currentInventory: {
        amount: item.currentInventoryText,
        amountUnit: item.currentInventorySubText
      },
      cogs: {
        amount: item.cogInventoryText,
        amountUnit: item.cogInventorySubText
      },
      sales: {
        amount: item.salesInventoryText,
        amountUnit: item.salesInventorySubText
      },
      allocated: {
        amount: item.allocatedInventoryText,
        amountUnit: item.allocatedInventorySubText
      },
      margin: item.margin
    };
  });
};

const Performance = () => {
  const { control, reset } = useForm();

  return (
    <>
      <Box
        sx={{ display: { xs: "none", lg: "flex" } }}
        flexDirection={"column"}
        gap={2}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={2}
          sx={{
            position: "sticky",
            top: "64px",
            zIndex: 1000,
            background: "#fff",
            padding: "16px 0"
          }}
        >
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 700,
              lineHeight: "24px",
              color: "#FF8A8A"
            }}
          >
            *Feature Coming Soon*
          </Typography>
          <Box>
            <Filter
              control={control}
              onClear={() => reset()}
              filters={[
                {
                  type: "DropDown",
                  name: inventory_filterKeys.brands,
                  label: inventory_table_labels.brands,
                  options: [],
                  filterCount: 15
                },
                {
                  type: "DropDown",
                  name: inventory_filterKeys.category,
                  label: inventory_table_labels.category,
                  options: [],
                  filterCount: 30
                },
                {
                  type: "DropDown",
                  name: inventory_filterKeys.sku,
                  label: inventory_table_labels.sku,
                  options: [],
                  filterCount: 3
                },
                {
                  type: "DropDown",
                  name: inventory_filterKeys.warehouse,
                  label: inventory_table_labels.warehouse,
                  options: [],
                  filterCount: 3
                },
                {
                  type: "DateInput",
                  name: inventory_filterKeys.dates,
                  label: inventory_table_labels.dates,
                  selectsRange: true
                }
              ]}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              border: "0.6px solid #2773FF",
              borderRadius: "8px",
              padding: "8px 20px 0px 100px",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              gap: 10
            }}
          >
            <BoldText sx={{ fontWeight: 700 }}>{"Subtotal :"}</BoldText>
            <Box display={"flex"} flexDirection={"column"} gap={2}>
              <BoldText sx={{ fontWeight: 700 }}>{"₹ 18,560"}</BoldText>
              <BoldText sx={{ fontWeight: 700 }}>{"--"}</BoldText>
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={2}>
              <BoldText sx={{ fontWeight: 700 }}>{"₹ 36,000"}</BoldText>
              <BoldText sx={{ fontWeight: 700 }}>{"--"}</BoldText>
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={2}>
              <BoldText sx={{ fontWeight: 700 }}>{"₹ 45,000"}</BoldText>
              <BoldText sx={{ fontWeight: 700 }}>{"--"}</BoldText>
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={2}>
              <BoldText sx={{ fontWeight: 700 }}>{"₹ 45,000"}</BoldText>
              <BoldText sx={{ fontWeight: 700 }}>{"--"}</BoldText>
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={2}>
              <BoldText sx={{ fontWeight: 700 }}>{"20%"}</BoldText>
            </Box>
          </Box>
        </Box>
        <CustomTable
          columns={getColumns()}
          data={getRows(dummy_data)}
          // mobileComponent={MyOrdersMobileCard}
          // isLoading={!isPastFetched}
        />
      </Box>
      <Box
        sx={{
          display: { xs: "flex", lg: "none" },
          justifyContent: "center",
          pt: "20px"
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 700,
            lineHeight: "24px",
            color: "#FF8A8A"
          }}
        >
          *Feature Coming Soon*
        </Typography>
      </Box>
    </>
  );
};

export default Performance;
