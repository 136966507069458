import { getStockTransferFormData } from "../../../purchase/services";
import { queryKeys } from "../../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchStockTransferFormData = (orderType) => {
  const queryStockTransferFormData = useQuery(
    [queryKeys.queryFetchStockTransferFormData],
    () => getStockTransferFormData(),
    {
      enabled: Boolean(orderType === "stockTransfer"),
      refetchOnWindowFocus: false
    }
  );

  return queryStockTransferFormData;
};
