import { queryKeys } from "../../constants/queryKeys";
import { getGPDocumentsById } from "../../inventory/services";
import { useQuery } from "@tanstack/react-query";

export const useFetchGPDocuments = (
  orderId,
  gatePassId,
  expanded,
  isPlaced
) => {
  const Documents = useQuery(
    [queryKeys.queryFetchDocument, orderId, gatePassId, expanded, isPlaced],
    () => getGPDocumentsById(orderId, gatePassId),
    {
      enabled: isPlaced && expanded && Boolean(orderId) && Boolean(gatePassId),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return Documents;
};
