import { queryKeys } from "../../constants/queryKeys";
import { getGPs } from "../../inventory/services";
import { useQuery } from "@tanstack/react-query";

export const useFetchGPs = (orderId) => {
  const GPs = useQuery(
    [queryKeys.queryFetchGPs, orderId],
    () => getGPs(orderId),
    {
      enabled: Boolean(orderId),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return GPs;
};
