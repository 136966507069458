import * as yup from "yup";
import { Box, Grid, Button } from "@mui/material";
import React, { useEffect } from "react";
import TextInput from "../../../shared/formElements/TextInput";
import { useForm } from "react-hook-form";
import DropDown from "../../../shared/formElements/DropDown";
import { dropdownOptions } from "../../../shared/utils";
import { useFetchCreateTransportFormData } from "../../../query-hooks/TransportAssets/useFetchCreateTransportFormData";
import { useCreateTransport } from "../../../query-hooks/TransportAssets/useCreateTransport";
import { useUpdateTransport } from "../../../query-hooks/TransportAssets/useUpdateTransport";
import { enqueueSnackbar } from "notistack";
import { yupResolver } from "@hookform/resolvers/yup";
import { error_msg } from "../../../constants";
import { LoadingButton } from "@mui/lab";

const schema = yup.object({
  regNo: yup.string().required(error_msg.required),
  truckMake: yup.string().required(error_msg.required),
  truckType: yup.string().required(error_msg.required)
});

const MobileAddTruck = ({ row = null, hideDrawer, refetch }) => {
  const { control, handleSubmit } = useForm({
    defaultValues: Boolean(row)
      ? {
          regNo: row.truckRegNo,
          truckMake: row.truckMake,
          truckType: row.truckType
        }
      : {},
    resolver: yupResolver(schema)
  });

  const { data: formData, isFetching: isFormDataFetching } =
    useFetchCreateTransportFormData();

  const {
    mutate: CreateTransport,
    isLoading: isCreating,
    isError: isTransportError,
    error: transportError
  } = useCreateTransport();

  const {
    mutate: UpdateTransport,
    isLoading: isUpdating,
    isError: isUpdateError,
    error: updateError
  } = useUpdateTransport();

  const onSaveClick = (data) => {
    Boolean(row)
      ? UpdateTransport(
          {
            data: {
              regNo: data.regNo,
              make: data.truckMake,
              vehicleType: data.truckType
            },
            transportId: row.vehicleId
          },
          {
            onSuccess: () => {
              enqueueSnackbar(`Vehicle Updated!`, {
                variant: "success"
              });
              refetch();
              hideDrawer();
            }
          }
        )
      : CreateTransport(
          {
            data: {
              regNo: data.regNo,
              make: data.truckMake,
              vehicleType: data.truckType
            }
          },
          {
            onSuccess: () => {
              enqueueSnackbar(`Vehicle Added!`, {
                variant: "success"
              });
              refetch();
              hideDrawer();
            }
          }
        );
  };

  useEffect(() => {
    if (isTransportError)
      enqueueSnackbar(transportError?.response?.data?.message, {
        variant: "error"
      });
    if (isUpdateError) {
      enqueueSnackbar(updateError?.response?.data?.message, {
        variant: "error"
      });
    }
  }, [
    transportError?.response?.data?.message,
    updateError?.response?.data?.message,
    isTransportError,
    isUpdateError
  ]);

  return (
    <Box display={"flex"} flexDirection={"column"}>
      <Grid container sx={{ padding: "20px" }} rowSpacing={2}>
        <Grid item xs={8}>
          <TextInput
            control={control}
            name={"regNo"}
            label={"Truck Reg. No"}
            mandatoryField
          />
        </Grid>
        <Grid item xs={8}>
          <TextInput
            control={control}
            name={"truckMake"}
            label={"Truck Make"}
            mandatoryField
          />
        </Grid>
        <Grid item xs={8}>
          <DropDown
            control={control}
            name={"truckType"}
            label={"Truck Type"}
            isLoading={isFormDataFetching}
            options={dropdownOptions(formData?.vehicleTypes)}
            mandatoryField
          />
        </Grid>
      </Grid>
      <Grid
        container
        sx={{ display: "flex", background: "#F0F2F5" }}
        justifyContent={"center"}
        alignItems={"center"}
        minHeight={"72px"}
      >
        <Button
          variant="contained"
          sx={{ width: "100px", height: "40px" }}
          component={LoadingButton}
          loading={isCreating || isUpdating}
          onClick={handleSubmit(onSaveClick)}
        >
          {"Save"}
        </Button>
      </Grid>
    </Box>
  );
};

export default MobileAddTruck;
