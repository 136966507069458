import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";

import { BookOrderFormKeys } from "../../../constants/formKeys";
import TextInput from "../../../shared/formElements/TextInput";
import { isMobileDevice } from "../../../App";
import { labels } from "../../../constants";
import { secondaryBackground } from "../../../shared/theme/globalTheme";
import styled from "@emotion/styled";

const StyleGrid = styled((props) => <Grid {...props} />)(({ theme }) => ({
  background: secondaryBackground,
  borderRadius: 4,
  marginBottom: 24
}));

const BillingDetails = ({ control, reset, customerAddress, isFetching }) => {
  const setFieldsData = () => {
    reset({
      [BookOrderFormKeys.FirstName]: customerAddress.firstName,
      [BookOrderFormKeys.LastName]: customerAddress.lastName,
      [BookOrderFormKeys.FirmsName]: customerAddress.companyName,
      [BookOrderFormKeys.FirmsEmail]: customerAddress.email,
      [BookOrderFormKeys.GSTIN]: customerAddress.gstNo,
      [BookOrderFormKeys.Contact]: customerAddress.mobile,
      [BookOrderFormKeys.StreetAddress]: customerAddress.address.address,
      [BookOrderFormKeys.Area]: customerAddress.address.area,
      [BookOrderFormKeys.City]: customerAddress.address.city,
      [BookOrderFormKeys.ZipCode]: customerAddress.address.pincode,
      [BookOrderFormKeys.State]: customerAddress.address.state
    });
  };

  return (
    <Box
      sx={{
        background: {
          md: "#FFFFFF",
          xs: "#EFF2F6"
        },
        border: {
          xs: 0,
          md: "0.4px solid #95A1B0"
        },
        borderRadius: "8px",
        padding: {
          xs: "24px 16px",
          md: "24px 24px 60px 24px"
        }
      }}
    >
      <Grid container spacing={2}>
        {isFetching && (
          <Grid item xs={12} display={"flex"} justifyContent={"center"}>
            <CircularProgress />
          </Grid>
        )}
        {customerAddress && (
          <Grid item xs={12}>
            <StyleGrid container>
              <Grid item xs={12} md={8} p={2}>
                <Typography
                  component="div"
                  color={"text.secondary"}
                  sx={{
                    fontSize: 12,
                    fontWeight: 500,
                    lineHeight: "18px",
                    whiteSpace: "nowrap"
                  }}
                >
                  {labels.savedAddress}
                </Typography>
                <Typography
                  component="div"
                  color={"text.primary"}
                  pt={1}
                  sx={{
                    fontSize: 14,
                    fontWeight: 700,
                    lineHeight: "20px",
                    whiteSpace: "nowrap"
                  }}
                >
                  {customerAddress?.firstName + " " + customerAddress?.lastName}
                </Typography>
                <Typography
                  component="div"
                  color={"text.primary"}
                  sx={{
                    fontSize: 13,
                    fontWeight: 400,
                    lineHeight: "20px",
                    whiteSpace: "initial"
                  }}
                >
                  {customerAddress?.companyName + ", " + customerAddress?.email}
                  <br />
                  {customerAddress?.address?.address +
                    ", " +
                    customerAddress?.address?.area +
                    ", " +
                    customerAddress?.address?.city +
                    ", " +
                    customerAddress?.address?.state +
                    ", " +
                    customerAddress?.address?.pincode}
                  <br />
                  {labels.contactLabelText + " : " + customerAddress.mobile}
                  <br />
                  {labels.gstinLabelText + ": " + customerAddress.gstNo}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                p={2}
                alignSelf={"center"}
                textAlign={isMobileDevice ? "initial" : "right"}
              >
                <Button
                  variant="outlined"
                  sx={{
                    textTransform: "none",
                    fontSize: 13,
                    fontWeight: 600,
                    height: 44
                  }}
                  onClick={setFieldsData}
                >
                  {labels.useThisAddress}
                </Button>
              </Grid>
            </StyleGrid>
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <TextInput
            control={control}
            name={BookOrderFormKeys.FirstName}
            label={labels.firstNameLabelText}
            placeholder={labels.firstNamePlaceholderText}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            control={control}
            name={BookOrderFormKeys.LastName}
            label={labels.lastNameLabelText}
            placeholder={labels.lastNamePlaceholderText}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            control={control}
            name={BookOrderFormKeys.FirmsName}
            label={labels.firmsNameLabelText}
            placeholder={labels.firmsNamePlaceholderText}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            control={control}
            name={BookOrderFormKeys.FirmsEmail}
            label={labels.firmsEmailLabelText}
            placeholder={labels.firmsEmailPlaceholderText}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            control={control}
            name={BookOrderFormKeys.GSTIN}
            label={labels.gstinLabelText}
            placeholder={labels.gstinPlaceholderText}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            control={control}
            name={BookOrderFormKeys.Contact}
            label={labels.contactLabelText}
            placeholder={labels.contactPlaceholderText}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            control={control}
            name={BookOrderFormKeys.StreetAddress}
            label={labels.billingAddressLabelText}
            placeholder={labels.billingAddressPlaceholderText}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInput
            control={control}
            name={BookOrderFormKeys.Area}
            placeholder={labels.areaPlaceholderText}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInput
            control={control}
            name={BookOrderFormKeys.City}
            placeholder={labels.cityPlaceholderText}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInput
            control={control}
            name={BookOrderFormKeys.State}
            placeholder={labels.statePlaceholderText}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInput
            control={control}
            name={BookOrderFormKeys.ZipCode}
            placeholder={labels.zipCodePlaceholderText}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default BillingDetails;
