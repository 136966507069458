import { queryKeys } from "../../../constants/queryKeys";
import { getStockTransferById } from "../../../purchase/services";
import { useQuery } from "@tanstack/react-query";

export const useFetchStockTransferById = (orderType, orderId) => {
  const purchaseOrderById = useQuery(
    [queryKeys.queryFetchStockTransferById, orderId],
    () => getStockTransferById(orderId),
    {
      enabled: Boolean(orderId) && Boolean(orderType === "stockTransfer"),
      refetchOnWindowFocus: false
    }
  );

  return purchaseOrderById;
};
