import { queryKeys } from "../../constants/queryKeys";
import { getDebitNoteUpdateFormData } from "../../finance/services";
import { useQuery } from "@tanstack/react-query";

export const useFetchDebitNoteUpdateFormData = (dnId, orderType, status) => {
  const DebitNoteFormData = useQuery(
    [
      queryKeys.queryFetchDebitNoteUpdateFormData,
      dnId,
      Boolean(orderType === "PurchaseReturn"),
      Boolean(status === "Draft")
    ],
    () => getDebitNoteUpdateFormData(dnId),
    {
      enabled:
        Boolean(dnId) &&
        Boolean(orderType === "PurchaseReturn") &&
        Boolean(status === "Draft"),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return DebitNoteFormData;
};
