import { queryKeys } from "../../constants/queryKeys";
import { getCreditNoteUpdateFormData } from "../../finance/services";
import { useQuery } from "@tanstack/react-query";

export const useFetchCreditNoteUpdateFormData = (cnId, orderType, status) => {
  const CreditNoteFormData = useQuery(
    [
      queryKeys.queryFetchCreditNoteUpdateFormData,
      cnId,
      Boolean(orderType === "SaleReturn"),
      Boolean(status === "Draft")
    ],
    () => getCreditNoteUpdateFormData(cnId),
    {
      enabled:
        Boolean(cnId) &&
        Boolean(orderType === "SaleReturn") &&
        Boolean(status === "Draft"),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return CreditNoteFormData;
};
