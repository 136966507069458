import { deletePRDraft } from "../../../purchase/services";
import { useMutation } from "@tanstack/react-query";

export const useDeletePRDraft = () => {
  const deleteDraft = useMutation(({ purchaseOrderId, purchaseReturnId }) =>
    deletePRDraft(purchaseOrderId, purchaseReturnId)
  );

  return deleteDraft;
};
