import { queryKeys } from "../../constants/queryKeys";
import { getFinanceOrders } from "../../finance/services";
import { useInfiniteQuery } from "@tanstack/react-query";

export const useFetchAllFinanceOrders = (params, orderType) => {
  const orders = useInfiniteQuery(
    [queryKeys.queryFetchFinaceOrders, orderType, Boolean(params)],
    ({ pageParam = 1 }) => getFinanceOrders(params, orderType, pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage =
          lastPage?.orders.length === lastPage?.pagination.limit
            ? allPages.length + 1
            : undefined;

        return nextPage;
      },
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true
    }
  );

  return orders;
};
