import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, useLocation } from "react-router-dom";
import { drawerWidth, drawerWidthProfile } from "./shared/theme/drawerTheme";
import { isMobile, isTablet } from "react-device-detect";
import { PrivateRoutes, publicRoutes } from "./shared/navigation/routes";
import { AppContext } from "./shared/context/auth-context";
import BackNavigation from "./shared/navigation/BackNavigation";
import BottomMobileAppBar from "./shared/layouts/BottomMobileAppBar";
import { CustomDialog } from "./shared/customDialog";
import { CustomSideDrawer } from "./shared/customSideDrawer";
import { ErrorBoundary } from "react-error-boundary";
import Header from "./shared/layouts/Header";
import SEO from "./home/components/SEO";
import SideDrawer from "./shared/layouts/SideDrawer";
import { SnackbarProvider } from "notistack";
import SomethingWentWrong from "./shared/UiElements/SomethingWentWrong";
import appRoutes from "./constants/appRoutes";
import { globalTheme } from "./shared/theme/globalTheme";
import { seoContent } from "./shared/utils/seo-content";
import { useAuth } from "./shared/hooks/useAuth";
import { useCart } from "./shared/hooks/useCart";
import { useCrumb } from "./shared/hooks/useCrumb";
import { useTabState } from "./shared/hooks/useTabState";
import { useProfileData } from "./shared/hooks/useProfileData";
import useGaTracker from "./shared/hooks/useGaTracker";
import { useEffect } from "react";
import { initGA, loadGAScript } from "./google-tag";
import FloatingWidgets from "./shared/layouts/FloatingWidgets";
import { useMobileTabState } from "./shared/hooks/useMobileTabState";
import { useAntSwitchState } from "./shared/hooks/useAntSwitchState";
const defaultSEO = seoContent[appRoutes.home];
export const isMobileDevice = isMobile || isTablet;
function App() {
  const { logout, currentUser } = useAuth();
  const { crumbs, handleCrumbStack, removeFromStack } = useCrumb();
  const { cart, onAddToCart, onRemoveFromCart } = useCart();
  const { activeTab, handleTabChange } = useTabState();
  const { activeMobileTab, handleMobileTabChange } = useMobileTabState();
  const { isSwitchChecked, handleSwitchToggle } = useAntSwitchState();
  const { profileData, updateProfileData } = useProfileData();
  const location = useLocation();
  const currentSEO = seoContent[location.pathname] || defaultSEO;
  const isRoot = [
    "/",
    appRoutes.faqs,
    appRoutes.pricing,
    appRoutes.saas,
    appRoutes.marketplace,
    appRoutes.credit,
    appRoutes.erp,
    appRoutes.inventoryMangement,
    appRoutes.packersAndMovers.hyderabad,
    appRoutes.packersAndMovers.bengaluru
  ].includes(location.pathname);
  const showWidgetsOn = [
    "/",
    appRoutes.saas,
    appRoutes.marketplace,
    appRoutes.credit,
    appRoutes.erp,
    appRoutes.inventoryMangement
  ].includes(location.pathname);
  const isProfilePage = [appRoutes.profile.main].includes(location.pathname);
  const logError = (error, info) => {
    console.error(error);
    console.info(info);
  };
  useGaTracker();
  useEffect(() => {
    loadGAScript();
    initGA();
  }, []);
  return (
    <ErrorBoundary
      FallbackComponent={SomethingWentWrong}
      onReset={(details) => console.log(details)}
      onError={logError}
    >
      <ThemeProvider theme={globalTheme}>
        <AppContext.Provider
          value={{
            profileData,
            updateProfileData,
            logout,
            crumbsStack: crumbs,
            setCrumbStack: handleCrumbStack,
            removeCrumbStack: removeFromStack,
            currentUser,
            cart,
            onAddToCart,
            onRemoveFromCart,
            activeTab,
            handleTabChange,
            activeMobileTab,
            handleMobileTabChange,
            isSwitchChecked,
            handleSwitchToggle
          }}
        >
          <SnackbarProvider>
            <CustomDialog>
              <CustomSideDrawer>
                <SEO
                  title={currentSEO.title}
                  description={currentSEO.description}
                />
                <Box>
                  <CssBaseline />
                  <Header />
                  {currentUser && !isRoot && <SideDrawer />}
                  <Box
                    component="main"
                    sx={{
                      width: {
                        sm:
                          !isRoot && currentUser
                            ? `calc(100% - ${
                                window.location.pathname.includes(
                                  appRoutes.profile.main
                                )
                                  ? drawerWidthProfile
                                  : drawerWidth
                              }px)`
                            : "100%",
                        xs: "100%"
                      },
                      ml: {
                        sm:
                          !isRoot && currentUser
                            ? `${
                                window.location.pathname.includes(
                                  appRoutes.profile.main
                                )
                                  ? drawerWidthProfile
                                  : drawerWidth
                              }px`
                            : "auto",
                        xs: "auto"
                      },
                      padding: {
                        sm: isRoot ? 0 : "24px 40px 0px 40px",
                        xs: "0"
                      },
                      mt: isRoot ? 0 : isMobile ? "60px" : 9
                    }}
                  >
                    {currentUser && !isRoot && <BackNavigation />}
                    <Routes location={window.location.pathname}>
                      {currentUser ? PrivateRoutes(profileData) : publicRoutes}
                    </Routes>
                  </Box>
                  {currentUser && !isRoot && !isProfilePage && (
                    <BottomMobileAppBar />
                  )}
                  {showWidgetsOn && <FloatingWidgets />}
                </Box>
              </CustomSideDrawer>
            </CustomDialog>
          </SnackbarProvider>
        </AppContext.Provider>
      </ThemeProvider>
    </ErrorBoundary>
  );
}
export default App;
