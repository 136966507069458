import * as yup from "yup";
import React, { useEffect } from "react";
import { useFetchCreateSubUserFormData } from "../../query-hooks/ClientSettings/useFetchCreateSubUserFormData";
import { useFetchUpdateSubUserFormData } from "../../query-hooks/ClientSettings/useFetchUpdateSubUserFormData";
import { useCreateSubUsers } from "../../query-hooks/ClientSettings/useCreateSubUsers";
import { useUpdateSubUser } from "../../query-hooks/ClientSettings/useUpdateSubUser";
import { enqueueSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { Button, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import TextInput from "../../shared/formElements/TextInput";
import CheckBoxSelect from "../../shared/formElements/CheckboxSelect";
import DropDown from "../../shared/formElements/DropDown";
import { error_msg } from "../../constants";
import { yupResolver } from "@hookform/resolvers/yup";

const userRoleSchema = (hasSubUserId) => {
  const baseSchema = {
    userName: yup.string().required(error_msg.required),
    mobileNo: yup.string().required(error_msg.required),
    userAccess: yup
      .array()
      .of(yup.string())
      .min(1, "Select atleast one")
      .required(error_msg.required)
  };

  if (hasSubUserId) {
    baseSchema["status"] = yup.string().required(error_msg.required);
  }

  return yup.object(baseSchema);
};

const MobileAddSubUsers = ({
  clientId,
  subUserId = null,
  row,
  allowCreateFormData,
  refetchUsers,
  hideDrawer
}) => {
  const { control, setValue, handleSubmit } = useForm({
    resolver: yupResolver(userRoleSchema(Boolean(subUserId)))
  });

  const { data: createFromData, isFetching: isFetchingCreateFormData } =
    useFetchCreateSubUserFormData(clientId, allowCreateFormData);

  const { data: updateFromData, isFetching: isFetchingUpdateFormData } =
    useFetchUpdateSubUserFormData(clientId, subUserId);

  const {
    mutate: createUser,
    isLoading: isCreating,
    isError: isCreateError,
    error: createError
  } = useCreateSubUsers();

  const {
    mutate: updateUser,
    isLoading: isUpdating,
    isError: isUpdateError,
    error: updateError
  } = useUpdateSubUser();

  useEffect(() => {
    if (Boolean(subUserId) && Boolean(row)) {
      setValue("userName", row?.userName);
      setValue("mobileNo", row?.mobileNo);
      setValue(
        "userAccess",
        row?.userAccess?.map((item) => item)
      );
      setValue("status", row?.status);
    } // eslint-disable-next-line
  }, [JSON.stringify(row)]);

  const onSaveClick = (data) => {
    const formData = {
      firstName: data["userName"],
      ...{
        ...(Boolean(subUserId)
          ? { status: data["status"] }
          : {
              mobile: data["mobileNo"]
            })
      },
      allowedScModules: data["userAccess"]
    };
    Boolean(subUserId)
      ? updateUser(
          {
            clientId: clientId,
            subUserId: subUserId,
            data: { ...formData }
          },
          {
            onSuccess: () => {
              enqueueSnackbar("User Updated!", {
                variant: "success"
              });
              refetchUsers();
              hideDrawer();
            }
          }
        )
      : createUser(
          {
            clientId: clientId,
            data: { ...formData }
          },
          {
            onSuccess: () => {
              enqueueSnackbar("User Added!", {
                variant: "success"
              });
              refetchUsers();
              hideDrawer();
            }
          }
        );
  };

  useEffect(() => {
    if (isCreateError) {
      enqueueSnackbar(createError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isUpdateError) {
      enqueueSnackbar(updateError?.response?.data?.message, {
        variant: "error"
      });
    }
  }, [
    createError?.response?.data?.message,
    updateError?.response?.data?.message,
    isCreateError,
    isUpdateError
  ]);

  return (
    <>
      <Grid container padding={"25px"} mb={4} rowSpacing={2}>
        <Grid item xs={8}>
          <TextInput
            control={control}
            name={`userName`}
            label={"User Name"}
            placeholder={"Enter Name"}
          />
        </Grid>
        <Grid item xs={8}>
          <TextInput
            control={control}
            name={`mobileNo`}
            label={"User Mobile"}
            placeholder={"Enter User's Mobile"}
            disabled={Boolean(subUserId)}
          />
        </Grid>
        <Grid item xs={9}>
          <CheckBoxSelect
            control={control}
            label={"Select Modules"}
            placeholder={"Modules Access"}
            name={`userAccess`}
            defaultValue={[]}
            options={createFromData?.scModules || updateFromData?.scModules}
            isLoading={isFetchingCreateFormData || isFetchingUpdateFormData}
          />
        </Grid>
        {Boolean(subUserId) && (
          <Grid item xs={6}>
            <DropDown
              control={control}
              label={"Select status"}
              name={`status`}
              options={updateFromData?.statuses || []}
              isLoading={isFetchingUpdateFormData}
              placeholder={"User Status"}
            />
          </Grid>
        )}
      </Grid>
      <Grid
        container
        sx={{ display: "flex", background: "#F0F2F5" }}
        justifyContent={"center"}
        alignItems={"center"}
        minHeight={"72px"}
      >
        <Button
          variant="contained"
          sx={{ width: "100px", height: "40px" }}
          component={LoadingButton}
          loading={isCreating || isUpdating}
          onClick={handleSubmit(onSaveClick)}
        >
          {"Save"}
        </Button>
      </Grid>
    </>
  );
};

export default MobileAddSubUsers;
