import { Divider, Box, Typography, Button, Grid } from "@mui/material";
import React from "react";
import DateInput from "../../../shared/formElements/DateInput";
import { CalendarTodayOutlined, Circle } from "@mui/icons-material";
import { Cell, PieChart, Pie } from "recharts";
import { currencyFormatter } from "../../../shared/utils";
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";

const CustomLabel = ({ color, label, value }) => (
  <Grid container>
    <Grid
      item
      xs={5}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"left"}
      gap={1}
    >
      <Circle
        sx={{
          fontSize: 9,
          color: color
        }}
      />
      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "14px",
          textAlign: "left",
          color: "#8C97A7"
        }}
      >
        {label}
      </Typography>
    </Grid>
    <Grid
      item
      xs={7}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"right"}
    >
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: 700,
          lineHeight: "16px",
          textAlign: "right"
        }}
      >
        {currencyFormatter(value)}
      </Typography>
    </Grid>
  </Grid>
);

const TotalAmount = ({ value }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center"
      }}
    >
      <Typography
        sx={{
          fontSize: { xs: "14px", md: "16px" },
          fontWeight: 400,
          lineHeight: "22px",
          color: "#607088"
        }}
      >
        {"Total"}
      </Typography>
      <Typography
        sx={{
          fontSize: { xs: "12px", md: "14px" },
          fontWeight: 700,
          lineHeight: "16px"
        }}
      >
        {currencyFormatter(parseFloat(value).toFixed(2))}
      </Typography>
    </Box>
  );
};

const DoughnutChart = ({ Title, data, onApply, setParams, isLoading }) => {
  const { control, reset, handleSubmit } = useForm();
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        md={6}
        container
        border={"1px solid #E1E7EC"}
        borderRadius={"8px"}
        rowSpacing={1}
      >
        <Grid
          item
          xs={12}
          container
          rowSpacing={2}
          columnSpacing={1}
          sx={{ p: { xs: 1, md: 2 } }}
        >
          <Grid item xs={12}>
            <Typography
              sx={{ fontWeight: 700, fontSize: "18px", lineHeight: "22px" }}
            >
              {Title}
            </Typography>
          </Grid>
          <Grid item xs={3} md={3} sx={{ display: { xs: "none", md: "flex" } }}>
            <DateInput
              control={control}
              name={"fromDate"}
              placeholder={"From"}
              endIcon={CalendarTodayOutlined}
              minDate={"2000-01-01"}
            />
          </Grid>
          <Grid item xs={4} md={4} sx={{ display: { xs: "none", md: "flex" } }}>
            <DateInput
              control={control}
              name={"toDate"}
              placeholder={"To Date"}
              endIcon={CalendarTodayOutlined}
              minDate={"2000-01-01"}
            />
          </Grid>
          <Grid item xs={6} sx={{ display: { xs: "flex", md: "none" } }}>
            <DateInput
              control={control}
              name={"date"}
              placeholder={"From - To Date"}
              endIcon={CalendarTodayOutlined}
              minDate={"2000-01-01"}
              selectsRange
            />
          </Grid>
          <Grid item xs={3} md={2.5}>
            <Button
              variant="contained"
              component={LoadingButton}
              loading={isLoading}
              sx={{ maxHeight: "42px" }}
              onClick={handleSubmit(onApply)}
            >
              {"Apply"}
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="outlined"
              sx={{ maxHeight: "42px" }}
              onClick={() => {
                reset();
                setParams(null);
              }}
            >
              {"Clear"}
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ border: "0.5px solid #E1E7EC" }} />
        </Grid>
        <Grid
          item
          xs={6}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ padding: { xs: "5px", md: 1 } }}
          position={"relative"}
        >
          <PieChart width={200} height={200}>
            <Pie
              dataKey="value"
              data={data}
              cx="50%"
              cy="50%"
              innerRadius={70}
              outerRadius={100}
              startAngle={90}
              endAngle={455}
            >
              {data.map((entry, index) => (
                <Cell key={index} fill={entry.color} />
              ))}
            </Pie>
          </PieChart>
          <TotalAmount
            value={data.reduce((sum, obj) => sum + parseFloat(obj.value), 0)}
          />
        </Grid>
        <Grid
          item
          xs={6}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ padding: { xs: "5px", md: 1 } }}
        >
          <Grid item xs={12} display={"flex"} flexDirection={"column"} gap={2}>
            {data.map((entry, index) => (
              <CustomLabel
                key={index}
                color={entry.color}
                label={entry.name}
                value={entry.value}
              />
            ))}
          </Grid>
          {/* {data.map((entry, index) => (
            <CustomLabel
              key={index}
              color={entry.color}
              label={entry.name}
              value={entry.value}
            />
          ))} */}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DoughnutChart;
