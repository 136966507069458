import { Grid, styled } from "@mui/material";

import ListingCard from "../ListingCard";
import { StyledResponsiveText } from "../../../shared/theme/globalTheme";
import { appServiceKeys } from "../../../constants/formKeys";
import { labels } from "../../../constants";
import { queryKeys } from "../../../constants/queryKeys";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

const StyledHeader = styled((props) => (
  <StyledResponsiveText
    {...props}
    desktop={{
      fontWeight: 600,
      fontSize: 28,
      lineHeight: "33.89px",
      letterSpacing: "-0.002em",
      mb: 3
    }}
    mobile={{
      fontSize: 20,
      fontWeight: 500,
      lineHeight: "24.2px",
      letterSpacing: "0.002em",
      mb: 2
    }}
  >
    {props.children}
  </StyledResponsiveText>
))(({ theme }) => ({}));

const TransportSearchResults = () => {
  const queryClient = useQueryClient();
  const transportData = queryClient.getQueryData([
    queryKeys.queryFetchTransporters,
    true
  ]);

  const { uuid } = useParams();

  return (
    <>
      <Grid item xs={12}>
        <StyledHeader>{labels.moreTransportSearchTitleText}</StyledHeader>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        {transportData?.pages?.[0]?.transports
          .filter((item) => item._id !== uuid)
          .map(
            (item, index) =>
              index < 3 && (
                <Grid item xs={12} md={6} lg={4} key={item.code}>
                  <ListingCard
                    service={appServiceKeys.transport}
                    title={item.code}
                    city={item.address.cluster}
                    state={item.address.city}
                    country={item.address.state}
                    monthlyRental={item.vehicles[0].rate}
                    addonFees={item.costs.serviceCost}
                    totalAmount={item.costs.totalAmount}
                    id={item._id}
                    tranName={
                      item.vehicles[0].make.displayName +
                      " " +
                      item.vehicles[0].model.displayName
                    }
                    loadCap={item.vehicles[0].weightCapacity}
                    heroImage={item.vehicles?.[0].heroImage?.thumbUrl}
                  />
                </Grid>
              )
          )}
      </Grid>
    </>
  );
};

export default TransportSearchResults;
