import { queryKeys } from "../../../constants/queryKeys";
import { getStoreById } from "../../../sales/services";
import { useQuery } from "@tanstack/react-query";

export const useFetchShipToById = (customerId, billingId, storeId) => {
  const storeById = useQuery(
    [queryKeys.queryFetchStoreById, customerId, billingId, storeId],
    () => getStoreById(customerId, billingId, storeId),
    {
      enabled: Boolean(customerId) && Boolean(billingId) && Boolean(storeId),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return storeById;
};
