import { getPurchaseReturnFormData } from "../../../purchase/services";
import { queryKeys } from "../../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchPurchaseReturnFormData = (
  orderId,
  // orderStatus = "",
  orderType
) => {
  const queryPurchaseReturnFormData = useQuery(
    [queryKeys.queryFetchPurchaseReturnFormData, orderId],
    () => getPurchaseReturnFormData(orderId),
    {
      enabled:
        // Boolean(orderStatus !== "Dispatched") &&
        Boolean(orderId) &&
        Boolean(orderType === "purchaseReturns"),
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return queryPurchaseReturnFormData;
};
