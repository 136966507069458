import { Box, Grid, IconButton, Typography } from "@mui/material";

import CustomTable from "../../../shared/customTable";
import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import TextInput from "../../../shared/formElements/TextInput";
import TextArea from "../../../shared/formElements/TextArea";
import { purchase_labels } from "../../../constants";
import {
  TotalBreakup,
  reportLabel1,
  reportLabel2
} from "../../../purchase/pages/CreateOrder";
import {
  calculateGstAmount,
  calculateTotalAmount,
  gstColumn
} from "../../../purchase/components/Purchase/PurchaseOrderTable";
import { ToWords } from "to-words";
import {
  calculateGstPRSubtotal,
  calculatePRSubTotal
} from "../../../purchase/components/Purchase/PurchaseReturnsTable";
import { currencyOnlyFormatter } from "../../../shared/utils";
import PrintableTable from "../../../purchase/components/PrintableTable";
import { BorderColorOutlined } from "@mui/icons-material";
import { MobileSRTable } from "./MobileSRTable";

const getColumns = (gstType) => {
  return [
    {
      Header: "S.No",
      accessor: "sNo",
      width: 90,
      size: 0.5
    },
    {
      Header: "Item/ SKU",
      accessor: "item",
      width: 200,
      size: 1.5,
      Cell: ({ row, value }) => {
        return row.original?.skuCode ? (
          <Box display={"flex"} flexDirection={"column"} gap={1}>
            <Box display={"flex"} gap={1} alignItems={"center"}>
              {row.original?.image?.thumbUrl && (
                <img
                  src={row.original?.image?.thumbUrl}
                  alt={row.original?.brandName ?? "--"}
                  height={52}
                  width={50}
                  style={{ objectFit: "contain" }}
                />
              )}
              <Typography
                fontSize={12}
                fontWeight={600}
                color={"primary"}
                component={Link}
              >
                {row.original?.skuCode}
              </Typography>
            </Box>
            <Typography fontSize={13} color={"text.secondary"}>
              {`HSN : ${row.original?.hsn ?? "--"}`}
            </Typography>
          </Box>
        ) : (
          value
        );
      }
    },
    {
      Header: "Product",
      accessor: "product",
      width: 180,
      size: 1.5,
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          row.original?.productName && (
            <Box display={"flex"} flexDirection={"column"} gap={0.5}>
              <Typography fontSize={14}>{`Product: ${
                row.original?.productName ?? "--"
              }`}</Typography>
              <Typography fontSize={13} color={"text.secondary"}>{`Category: ${
                row.original?.categoryName ?? "--"
              }`}</Typography>
              <Typography fontSize={13} color={"text.secondary"}>{`Brand: ${
                row.original?.brandName ?? "--"
              }`}</Typography>
            </Box>
          )
        );
      }
    },
    {
      Header: "UOM",
      accessor: "uom",
      disableSortBy: true,
      size: 1,
      Cell: ({ row }) => {
        return (
          row.original?.unit && (
            <Typography>{row.original?.unit?.name}</Typography>
          )
        );
      }
    },
    {
      Header: "Price (INR)",
      accessor: "rate",
      size: 1,
      disableSortBy: true
    },
    {
      Header: "Qty Order",
      accessor: "quantity",
      disableSortBy: true,
      size: 1
    },
    {
      Header: "Qty Return",
      accessor: "returnedQuantity",
      disableSortBy: true,
      size: 1
    },
    {
      Header: "Amount",
      accessor: "amount",
      size: 1,
      disableSortBy: true
    },
    ...[
      ...(gstType
        ? [
            {
              Header: "CGST",
              accessor: "cgst",
              size: 1,
              disableSortBy: true,
              Cell: ({ row }) => {
                return gstColumn(
                  row.original.cgst ?? 0,
                  row.original?.gstRegime / 2 ?? 0
                );
              }
            },
            {
              Header: "SGST",
              accessor: "sgst",
              disableSortBy: true,
              size: 1,
              Cell: ({ row }) => {
                return gstColumn(
                  row.original.sgst,
                  row.original?.gstRegime / 2 ?? 0
                );
              }
            }
          ]
        : [
            {
              Header: "IGST",
              accessor: "igst",
              size: 1,
              disableSortBy: true,
              Cell: ({ row }) => {
                return gstColumn(
                  row.original.igst ?? 0,
                  row.original?.gstRegime ?? 0
                );
              }
            }
          ])
    ],
    {
      Header: "Total",
      accessor: "total",
      size: 1.5,
      disableSortBy: true
    }
  ];
};

const getRows = (rows, control, isEditable, watch, applyGst, gstType) => {
  return rows.map((row, index) => ({
    sNo: index + 1,
    ...row,
    isEditable: isEditable,
    gstType: gstType,
    returnedQuantity: isEditable ? (
      <TextInput
        control={control}
        name={`newOrders.${index}.returnedQuantity`}
        type="number"
        placeholder={"return qty"}
      />
    ) : (
      row.returnedQuantity
    ),
    amount:
      watch(`newOrders.${index}.rate`) &&
      watch(`newOrders.${index}.returnedQuantity`)
        ? currencyOnlyFormatter(
            parseFloat(
              +watch(`newOrders.${index}.rate`) *
                +watch(`newOrders.${index}.returnedQuantity`)
            ).toFixed(2)
          )
        : "",
    cgst:
      watch(`newOrders.${index}.rate`) &&
      watch(`newOrders.${index}.returnedQuantity`) &&
      row.gstRegime &&
      applyGst
        ? calculateGstAmount(
            +watch(`newOrders.${index}.rate`),
            +watch(`newOrders.${index}.returnedQuantity`),
            row.gstRegime / 200
          )
        : 0,
    sgst:
      watch(`newOrders.${index}.rate`) &&
      watch(`newOrders.${index}.returnedQuantity`) &&
      row.gstRegime &&
      applyGst
        ? calculateGstAmount(
            +watch(`newOrders.${index}.rate`),
            +watch(`newOrders.${index}.returnedQuantity`),
            row.gstRegime / 200
          )
        : 0,
    igst:
      watch(`newOrders.${index}.rate`) &&
      watch(`newOrders.${index}.returnedQuantity`) &&
      row.gstRegime &&
      applyGst
        ? calculateGstAmount(
            +watch(`newOrders.${index}.rate`),
            +watch(`newOrders.${index}.returnedQuantity`),
            row.gstRegime / 100
          )
        : 0,
    total:
      watch(`newOrders.${index}.rate`) &&
      watch(`newOrders.${index}.returnedQuantity`)
        ? currencyOnlyFormatter(
            applyGst
              ? calculateTotalAmount(
                  +watch(`newOrders.${index}.rate`),
                  +watch(`newOrders.${index}.returnedQuantity`),
                  row.gstRegime / 100 ?? 1
                )
              : parseFloat(
                  +watch(`newOrders.${index}.rate`) *
                    +watch(`newOrders.${index}.returnedQuantity`)
                ).toFixed(2)
          )
        : 0
  }));
};

const SalesReturnsTable = ({
  isPrint = false,
  costBreakUp,
  setCostBreakUp,
  fields,
  control,
  watch,
  isEditable,
  gstType = false,
  applyGst,
  reasonOfReturn,
  orderStatus,
  onEdit,
  preparedBy
}) => {
  const toWords = new ToWords();
  const ordersWatch = watch("newOrders");

  useEffect(() => {
    if (ordersWatch && fields.length > 0) {
      setCostBreakUp({
        gstType: gstType,
        subtotal: calculatePRSubTotal(fields, watch),
        cgst: applyGst ? calculateGstPRSubtotal(fields, watch) / 2 : 0,
        sgst: applyGst ? calculateGstPRSubtotal(fields, watch) / 2 : 0,
        igst: applyGst ? calculateGstPRSubtotal(fields, watch) : 0,
        total: applyGst
          ? +calculatePRSubTotal(fields, watch) +
            +calculateGstPRSubtotal(fields, watch)
          : +calculatePRSubTotal(fields, watch)
      });
    }
    // eslint-disable-next-line
  }, [JSON.stringify(ordersWatch)]);
  return (
    <>
      {isPrint ? (
        <Grid container>
          <PrintableTable
            columns={getColumns(gstType)}
            rows={getRows(fields, control, isEditable, watch, applyGst)}
            docType="SalesReturns"
            gstType={gstType}
          />
        </Grid>
      ) : (
        <>
          {orderStatus === "" && (
            <Grid item xs={12} sx={{ display: { xs: "block", md: "none" } }}>
              <IconButton onClick={onEdit}>
                <BorderColorOutlined color="warning" />
              </IconButton>
            </Grid>
          )}
          <CustomTable
            columns={getColumns(gstType)}
            data={getRows(
              fields,
              control,
              isEditable,
              watch,
              applyGst,
              gstType
            )}
            mobileComponent={MobileSRTable}
          />
        </>
      )}
      <Grid
        container
        spacing={2}
        sx={{ display: { xs: isPrint ? "flex" : "none", md: "flex" } }}
      >
        <Grid item xs={9}>
          <Box display={"flex"} flexDirection={"column"} gap={3}>
            {reportLabel1(purchase_labels.returnReasonLabel, reasonOfReturn)}
            <Box width={"500px"}>
              <TextArea
                control={control}
                name={"otherItemsAndConditionsLabel"}
                label={purchase_labels.otherItemsAndConditionsLabel}
                disabled={
                  Boolean(orderStatus)
                    ? orderStatus === "Draft"
                      ? !isEditable
                      : true
                    : false
                }
              />
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={2.5}>
              {reportLabel2(
                purchase_labels.amountInWordsLabel,
                `${toWords.convert(
                  applyGst
                    ? +calculatePRSubTotal(fields, watch) +
                        +calculateGstPRSubtotal(fields, watch) ?? 0
                    : +calculatePRSubTotal(fields, watch),
                  { currency: true }
                )}`
              )}
              {reportLabel2(
                purchase_labels.preparedByLabel,
                preparedBy ?? "--"
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <TotalBreakup
            gstType={gstType}
            subTotal={costBreakUp.subtotal}
            cgst={costBreakUp.cgst}
            sgst={costBreakUp.sgst}
            igst={costBreakUp.igst}
            total={costBreakUp.total}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SalesReturnsTable;
