import {
  getPublicTransporters,
  getTransporters
} from "../../customer/services";

import { AppContext } from "../../shared/context/auth-context";
import { queryKeys } from "../../constants/queryKeys";
import { useContext } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";

export const useFetchTransporters = (params) => {
  const { currentUser } = useContext(AppContext);

  const queryTransporter = useInfiniteQuery(
    [queryKeys.queryFetchTransporters, Boolean(params)],
    currentUser
      ? ({ pageParam = 1 }) => getTransporters(params, pageParam)
      : () => getPublicTransporters(params),
    {
      enabled: Boolean(params),
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true,
      getNextPageParam: (lastPage, allPages) => {
        const nextPage =
          lastPage.transports.length === lastPage.pagination.limit
            ? allPages.length + 1
            : undefined;

        return nextPage;
      }
    }
  );

  return queryTransporter;
};
