import {
  Box,
  Button,
  Card,
  CardMedia,
  Divider,
  Grid,
  IconButton,
  Typography,
  styled
} from "@mui/material";
import { Close, InfoOutlined } from "@mui/icons-material";

import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import { cartServiceKeys } from "../../constants/formKeys";
import { currencyFormatter } from "../../shared/utils";
import { isMobileDevice } from "../../App";
import { labels } from "../../constants";
import { useDrawer } from "../../shared/customSideDrawer";

const StyledCard = styled(Card)(({ theme }) => ({
  border: "0.4px solid #B7BEC7",
  borderRadius: 8,
  // padding: "12px",
  // height: 146,
  width: "auto",
  boxShadow: "0px 8px 24px rgba(21, 21, 22, 0.1)",
  [theme.breakpoints.up("lg")]: {
    boxShadow: "none"
    // width: 440
  }
}));

const PlaceholderStyledCard = styled(Card)(({ theme }) => ({
  background: "#FFFFFF",
  border: "0.6px dashed #1B51B3",
  borderRadius: 8,
  height: 146,
  width: "auto",
  boxShadow: "0px 8px 24px rgba(21, 21, 22, 0.1)",
  [theme.breakpoints.up("lg")]: {
    boxShadow: "none"
    // width: 440
  }
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  height: 122,
  minWidth: 80,
  borderRadius: 8
}));

const StyledPrimaryText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary
}));

const StyledPrimary = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main
}));

const StyledSecondaryText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 13,
  fontWeight: 400
}));

export default function CartItemCard({
  addContainerPlaceholder = false,
  monthlyRental,
  addonFees,
  totalAmount,
  title,
  city,
  state,
  cluster,
  id,
  placeholderLabel,
  thumbnail,
  onPlaceholderLabelClick,
  onRemoveCartItem,
  type,
  rateUnit
}) {
  const { hideDrawer, toggleBackdrop } = useDrawer();
  return addContainerPlaceholder ? (
    <PlaceholderStyledCard>
      <Grid
        container
        justifyContent={"center"}
        alignContent={"center"}
        height={"100%"}
      >
        <StyledPrimary
          fontSize={13}
          fontWeight={600}
          display={"grid"}
          justifyItems={"center"}
          textTransform={"uppercase"}
          sx={{
            cursor: "pointer"
          }}
          onClick={() => {
            isMobileDevice ? hideDrawer() : toggleBackdrop();
            if (onPlaceholderLabelClick) onPlaceholderLabelClick();
          }}
        >
          <AddIcon />
          {placeholderLabel?.toUpperCase()}
        </StyledPrimary>
      </Grid>
    </PlaceholderStyledCard>
  ) : (
    <StyledCard
      elevation={0}
      sx={{
        padding: {
          xs: "16px 12px",
          md: "12px"
        }
      }}
    >
      <Grid container spacing={2}>
        {thumbnail && (
          <Grid
            item
            xs
            sx={{
              display: {
                xs: "none",
                md: "block"
              }
            }}
          >
            <StyledCardMedia xs={4} image={thumbnail} title={title} />
          </Grid>
        )}

        <Grid item xs={12} md={thumbnail ? 9 : 12}>
          <Grid container>
            <Grid
              item
              xs={8}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
            >
              <StyledPrimaryText
                fontWeight={600}
                letterSpacing="0.005em"
                sx={{
                  fontSize: {
                    xs: 13,
                    md: 16
                  },
                  lineHeight: {
                    xs: "18px",
                    md: "19.36px"
                  },
                  marginBottom: {
                    xs: 0,
                    md: "4px"
                  }
                }}
              >
                {title}
              </StyledPrimaryText>
              <StyledSecondaryText
                lineHeight="18px"
                sx={{
                  display: {
                    xs: "none",
                    md: "block"
                  }
                }}
              >{`${cluster}, ${city}, ${state}`}</StyledSecondaryText>
            </Grid>
            <Grid item xs={4} textAlign={"end"}>
              <Box
                display={"flex"}
                gap={1}
                alignItems={"center"}
                justifyContent={"flex-end"}
              >
                <Button
                  variant="link"
                  onClick={hideDrawer}
                  to={`/explore/listings/details/${type}/${id}`}
                  component={Link}
                  sx={{
                    fontWeight: 600,
                    fontSize: 13,
                    textTransform: "capitalize",
                    whiteSpace: "nowrap",
                    display: {
                      xs: "none",
                      md: "block"
                    }
                  }}
                >
                  {labels.viewDetail}
                </Button>
                <IconButton
                  color="error"
                  size="small"
                  disableRipple
                  onClick={onRemoveCartItem}
                >
                  <Close fontSize="small" />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
          <Box
            display="flex"
            sx={{
              marginTop: {
                xs: 2,
                md: 3
              }
            }}
          >
            <Box display="flex" flexDirection="column" marginRight="12px">
              <StyledPrimaryText
                fontSize={12}
                fontWeight={500}
                marginBottom="4px"
              >
                {`₹${monthlyRental}/${rateUnit}`}
              </StyledPrimaryText>
              <StyledSecondaryText>
                {type === cartServiceKeys.warehouse
                  ? labels.monthlyRentalText
                  : labels.pricekm}
              </StyledSecondaryText>
            </Box>
            <Box display="flex" flexDirection="column" marginRight="12px">
              <Box display="flex" flexDirection="column">
                <StyledPrimaryText
                  fontSize={12}
                  fontWeight={500}
                  marginBottom="4px"
                >
                  {`${currencyFormatter(parseFloat(addonFees).toFixed(0))}`}
                </StyledPrimaryText>
                <StyledSecondaryText>
                  {labels.addonFeesText}
                </StyledSecondaryText>
              </Box>
            </Box>
            <Divider
              orientation="vertical"
              sx={{ height: "auto", mr: "12px" }}
            />
            <Box display="flex" flexDirection="column">
              <Box display="flex">
                <StyledPrimaryText
                  fontSize={14}
                  fontWeight={700}
                  marginBottom="4px"
                  lineHeight="16.94px"
                >
                  {`${currencyFormatter(parseFloat(totalAmount).toFixed(0))}`}
                </StyledPrimaryText>
                <InfoOutlined
                  fontSize="10.5px"
                  sx={{
                    color: "#607088",
                    ml: "3.75px"
                  }}
                />
              </Box>
              <StyledSecondaryText>
                {labels.totalAmountText}
              </StyledSecondaryText>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </StyledCard>
  );
}
