import { getQuotesFilters } from "../../../sales/services";
import { queryKeys } from "../../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchQuotesFilters = () => {
  const queryQuotesFilters = useQuery(
    [queryKeys.queryFetchQuotesFilters],
    () => getQuotesFilters(),
    {
      refetchOnWindowFocus: false
    }
  );

  return queryQuotesFilters;
};
