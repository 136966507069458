import { Box } from "@mui/material";

import { home_labels } from "../../constants";
import { StyledResponsiveText } from "../../shared/theme/globalTheme";

const ERPHeader = () => {
  return (
    <Box
      className="header_group_2"
      display={"flex"}
      flexDirection={"column"}
      sx={{ gap: { xs: "10px", md: "28px" } }}
    >
      <StyledResponsiveText
        component={"h1"}
        color={"text.title"}
        desktop={{
          fontSize: 48,
          fontWeight: 700,
          lineHeight: "64px",
          letterSpacing: "-0.02em"
        }}
        mobile={{
          fontSize: 24,
          fontWeight: 700,
          lineHeight: "40px",
          letterSpacing: "-0.02em"
        }}
        sx={{
          textRendering: "optimizeSpeed"
        }}
      >
        {home_labels.erpTitleText}
      </StyledResponsiveText>
      <StyledResponsiveText
        desktop={{
          fontSize: 20,
          lineHeight: "64px",
          letterSpacing: "-0.02em",
          fontWeight: 400
        }}
        mobile={{
          fontSize: 14,
          lineHeight: "21px",
          letterSpacing: "-0.01em",
          fontWeight: 400
        }}
        sx={{
          color: {
            xs: "text.secondary",
            md: "text.primary"
          },
          textAlign: { xs: "justify", md: "left" },
          textRendering: "optimizeSpeed"
        }}
      >
        {home_labels.erpTitleSubtext}
      </StyledResponsiveText>
    </Box>
  );
};

export default ERPHeader;
