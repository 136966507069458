import { queryKeys } from "../../../constants/queryKeys";
import { getPurchaseOrderById } from "../../../purchase/services";
import { useQuery } from "@tanstack/react-query";

export const useFetchPurchaseOrderById = (
  purchaseOrderId,
  returnId,
  manageOrderType
) => {
  const purchaseOrderById = useQuery(
    [queryKeys.queryFetchPurchaseOrderById, purchaseOrderId],
    () => getPurchaseOrderById(purchaseOrderId),
    {
      enabled:
        manageOrderType &&
        Boolean(purchaseOrderId) &&
        !Boolean(returnId),
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return purchaseOrderById;
};
