import { useCallback, useEffect, useState } from "react";
import { StorageKeys } from "../../constants/storage-keys";
import Storage from "../../shared/utils/Storage";

export const useProfileData = () => {
  const user = Storage.getItem(StorageKeys.PROFILE_DATA);
  const [profileData, setProfileData] = useState(user);

  const updateProfileData = useCallback((item) => {
    setProfileData(item);
  }, []);

  useEffect(
    () => {
      Storage.setItem(StorageKeys.PROFILE_DATA, profileData);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(profileData)]
  );

  return { profileData, updateProfileData };
};
