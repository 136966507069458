import { Box, Typography } from "@mui/material";

import { CustomTabs } from "../../shared/customTab/CustomTabs";
import { NAV_LINK } from "../../shared/layouts/SideDrawer";
import appRoutes from "../../constants/appRoutes";
import { useContext } from "react";
import { AppContext } from "../../shared/context/auth-context";
import Invoices from "./Invoices";
import Payments from "./Payments";
import { AntSwitch } from "../../shared/theme/globalTheme";

const Finance = () => {
  const {
    activeMobileTab,
    handleMobileTabChange,
    isSwitchChecked,
    handleSwitchToggle
  } = useContext(AppContext);

  const componentPath = {
    [appRoutes.finance.invoices]: <Invoices />,
    [appRoutes.finance.payments]: <Payments />
  };

  const MenuItems = NAV_LINK.find(
    (item) => item.name === "Finance"
  )?.children?.map((menu) => {
    return {
      label: menu.name,
      Component: componentPath[menu.to]
    };
  });

  return (
    <Box
      sx={{
        mb: { xs: 9 }
      }}
    >
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        sx={{
          background: "#EEF1F4"
        }}
        alignItems={"center"}
        minHeight={"55px"}
      >
        <Typography
          sx={{
            fontSize: "16px",
            lineHeight: "19.36px",
            fontWeight: 700,
            pl: "22px"
          }}
        >
          {"Finance"}
        </Typography>
        <Box
          display={"flex"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          gap={1}
          mr={1}
        >
          <Typography
            sx={{
              color: isSwitchChecked ? "#C4C4C4" : "#2773FF",
              fontSize: "20px",
              fontWeight: 700,
              lineHeight: "20px"
            }}
          >
            {"AR"}
          </Typography>
          <AntSwitch
            checked={isSwitchChecked}
            onChange={handleSwitchToggle}
            inputProps={{ "aria-label": "toggle switch" }}
          />
          <Typography
            sx={{
              color: isSwitchChecked ? "#F9A90E" : "#D9D9D9",
              fontSize: "20px",
              fontWeight: 700,
              lineHeight: "20px"
            }}
          >
            {"AP"}
          </Typography>
        </Box>
      </Box>
      <CustomTabs
        tabs={MenuItems}
        activeTab={activeMobileTab.Finance}
        onChange={handleMobileTabChange}
        secondaryTab={true}
      />
    </Box>
  );
};

export default Finance;
