import * as yup from "yup";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";

import DropDown from "../../shared/formElements/DropDown";
import SalesOrderTable from "../components/Sales/SalesOrderTable";
import SalesReturnsTable from "../components/Sales/SalesReturnsTable";
import OrderReport from "../../purchase/components/OrderReport";
import {
  CustomFieldRow,
  generateFields,
  generateMobileFields,
  getDraftItems,
  OtherDetailsComp,
  PurchaseButtonGroup,
  shipToAddressData,
  vendorAddressData
} from "../../purchase/pages/CreateOrder";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchSalesOrderFormData } from "../../query-hooks/ClientSales/SalesOrder/useFetchSalesOrderFormData";
import { enqueueSnackbar } from "notistack";
import {
  currencyOnlyFormatter,
  dropdownOptions,
  optionsFormatter
} from "../../shared/utils";
import { useFetchCustomerBillingByCustomerId } from "../../query-hooks/ClientSales/SalesOrder/useFetchCustomerBillingByCustomerId";
import moment from "moment";
import appRoutes from "../../constants/appRoutes";
import SuccessfulDialog from "../../shared/UiElements/SuccessfulDialog";
import { useCreateSalesOrder } from "../../query-hooks/ClientSales/SalesOrder/useCreateSalesOrder";
import { useSaveDraftSalesOrder } from "../../query-hooks/ClientSales/SalesOrder/useSaveDraftSalesOrder";
import { useUpdateSalesOrderDraft } from "../../query-hooks/ClientSales/SalesOrder/useUpdateSalesOrderDraft";
import { useSubmitSalesOrderDraft } from "../../query-hooks/ClientSales/SalesOrder/useSubmitSalesOrderDraft";
import { useDeleteSODraft } from "../../query-hooks/ClientSales/SalesOrder/useDeleteSODraft";
import { useCustomDialog } from "../../shared/customDialog";
import { CustomTabs } from "../../shared/customTab/CustomTabs";
import { error_msg, statusKeys } from "../../constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFetchSalesOrderById } from "../../query-hooks/ClientSales/SalesOrder/useFetchSalesOrderById";
import { useCreateSalesReturn } from "../../query-hooks/ClientSales/SalesReturn/useCreateSalesReturn";
import { useSaveDraftSalesReturn } from "../../query-hooks/ClientSales/SalesReturn/useSaveDraftSalesReturn";
import { useUpdateSalesReturnDraft } from "../../query-hooks/ClientSales/SalesReturn/useUpdateSalesReturnDraft";
import { useSubmitSalesReturnDraft } from "../../query-hooks/ClientSales/SalesReturn/useSubmitSalesReturnDraft";
import { useDeleteSRDraft } from "../../query-hooks/ClientSales/SalesReturn/useDeleteSRDraft";
import { useFetchSOListFormData } from "../../query-hooks/ClientSales/SalesReturn/useFetchSOListFormData";
import { useFetchSalesReturnFormData } from "../../query-hooks/ClientSales/SalesReturn/useFetchSalesReturnFormData";
import { useFetchSalesReturnOrderById } from "../../query-hooks/ClientSales/SalesReturn/useFetchSalesReturnOrderById";
import { useUpdateSalesOrder } from "../../query-hooks/ClientSales/SalesOrder/useUpdateSalesOrder";
import { useCancelSalesOrder } from "../../query-hooks/ClientSales/SalesOrder/useCancelSalesOrder";
import { useUpdateSalesReturn } from "../../query-hooks/ClientSales/SalesReturn/useUpdateSalesReturn";
import { useCancelSalesReturn } from "../../query-hooks/ClientSales/SalesReturn/useCancelSalesReturn";
import HistoryTable from "../../purchase/components/HistoryTable";
import { useReactToPrint } from "react-to-print";
import { useDrawer } from "../../shared/customSideDrawer";
import MobileOrderReport from "../../purchase/components/MobileOrderReport";
import TextArea from "../../shared/formElements/TextArea";
import { isMobileDevice } from "../../App";
import { TuneOutlined } from "@mui/icons-material";

export const billingAddressObject = (data) => {
  if (!Boolean(data)) return {};
  const addressObject = {
    customerName: data?.contact?.name,
    companyName: data?.companyName ?? data?.code,
    address: `${data?.address?.address}, ${data?.address?.area}, ${data?.address?.city}, ${data?.address?.state}, ${data?.address?.pincode}`,
    gst: data?.gstNo ? `GSTIN : ${data?.gstNo}` : ""
  };
  return addressObject;
};

const SOSchema = (createByDraft) => {
  const baseSchema = {
    orderType: yup.string().required(error_msg.required),
    shipping: yup.string().required(error_msg.required),
    advance: yup.string().required(error_msg.required),
    due: yup.string().required(error_msg.required),
    dateOfDelivery: yup.string().required(error_msg.required),
    newOrders: yup.array().of(
      yup.object().shape({
        _id: yup.string().required(error_msg.required),
        disc: yup.string().required(error_msg.required),
        quantity: yup.string().required(error_msg.required)
      })
    )
  };

  if (!createByDraft) {
    baseSchema["salesRepresentative"] = yup
      .string()
      .required(error_msg.required);
    baseSchema["deliveryWarehouse"] = yup.string().required(error_msg.required);
    baseSchema["customerShipTo"] = yup.string().required(error_msg.required);
    baseSchema["customerName"] = yup.string().required(error_msg.required);
    baseSchema["customerBilling"] = yup.string().required(error_msg.required);
  }

  return yup.object(baseSchema);
};

const SRSchema = yup.object({
  reasonForReturn: yup.string().required(error_msg.required),
  newOrders: yup.array().of(
    yup.object().shape({
      _id: yup.string().required(error_msg.required),
      returnedQuantity: yup.string().required(error_msg.required)
    })
  )
});

const CreateSalesOrder = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [updateOrderButton, setUpdateOrderButton] = useState(false);
  const selectedRowsRef = useRef(null);
  const { orderId, manageOrderType, returnId, createReturnId } = useParams();
  const [isEditable, setIsEditable] = useState(false);
  const [isDraft, setIsDraft] = useState(Boolean(orderId));
  const [checkOrderType, setCheckOrderType] = useState(manageOrderType ?? "");
  const [costBreakUp, setCostBreakUp] = useState({
    gstType: false,
    subtotal: "",
    cgst: "",
    sgst: "",
    igst: "",
    total: ""
  });

  const navigate = useNavigate();

  const { control, watch, reset, setValue, handleSubmit } = useForm({
    defaultValues: {
      orderType: manageOrderType ?? "",
      newOrders: []
    },
    resolver: yupResolver(
      Boolean(checkOrderType === "salesReturns") || Boolean(createReturnId)
        ? SRSchema
        : SOSchema(Boolean(orderId))
    )
  });

  const { showDialog, hideDialog } = useCustomDialog();
  const { showDrawer, hideDrawer } = useDrawer();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "newOrders"
  });

  const orderTypeWatch = watch("orderType");

  const customerNameWatch = watch("customerName");

  const { data: CreateOrderForm, isFetching: isCreateOrderFormFetching } =
    useFetchSalesOrderFormData(orderTypeWatch);

  const {
    data: CustomerBillingFormData,
    isFetching: isCustomerBillingFetching
  } = useFetchCustomerBillingByCustomerId(customerNameWatch ?? "");

  const {
    data: SOForm,
    isFetching: isSOFormFetching,
    refetch: refetchSOForm
  } = useFetchSalesOrderById(
    orderId,
    returnId,
    Boolean(manageOrderType === "salesOrder")
  );

  const { data: SOListFormData, isFetching: isSOListFetching } =
    useFetchSOListFormData(orderTypeWatch);

  const orderRefWatch = watch("orderRef");

  const componentRef = useRef();

  const {
    data: CreateReturnFormData,
    isFetching: isReturnFormDataFetching,
    refetch: refetchSRCreateFrom
  } = useFetchSalesReturnFormData(orderId ?? orderRefWatch, orderTypeWatch);

  const {
    data: salesReturnData,
    isFetching: isSalesReturnDataFetching,
    refetch: refetchSRForm
  } = useFetchSalesReturnOrderById(
    orderId,
    returnId,
    Boolean(manageOrderType === "salesReturns")
  );

  const {
    mutate: createSalesOrder,
    isLoading: isCreating,
    isError: isCreateError,
    error: createError
  } = useCreateSalesOrder();

  const {
    mutate: updateSalesOrder,
    isLoading: isUpdating,
    isError: isUpdateError,
    error: updateError
  } = useUpdateSalesOrder();

  const {
    mutate: saveDraftSalesOrder,
    isLoading: isSavingDraft,
    isError: isSaveDraftError,
    error: saveDraftError
  } = useSaveDraftSalesOrder();

  const {
    mutate: updateSalesOrderDraft,
    isLoading: isUpdatingDraft,
    isError: isUpdateDraftError,
    error: updateDraftError
  } = useUpdateSalesOrderDraft();

  const {
    mutate: submitSalesOrderDraft,
    isLoading: isSubmittingDraft,
    isError: isSubmitDraftError,
    error: submitDraftError
  } = useSubmitSalesOrderDraft();

  const {
    mutate: cancelSalesOrder,
    isError: isCancelError,
    error: cancelError
  } = useCancelSalesOrder();

  const {
    mutate: deleteSODraft,
    isError: isDeleteSODraftError,
    error: deleteSODraftError
  } = useDeleteSODraft();

  const {
    mutate: createSalesReturn,
    isLoading: isCreatingSR,
    isError: isCreateSRError,
    error: createSRError
  } = useCreateSalesReturn();

  const {
    mutate: updateSalesReturn,
    isLoading: isUpdatingSR,
    isError: isUpdateSRError,
    error: updateSRError
  } = useUpdateSalesReturn();

  const {
    mutate: saveDraftSalesReturn,
    isLoading: isSavingSRDraft,
    isError: isSaveDraftSRError,
    error: saveDraftSRError
  } = useSaveDraftSalesReturn();

  const {
    mutate: updateSalesReturnDraft,
    isLoading: isUpdatingSRDraft,
    isError: isUpdateDraftSRError,
    error: updateDraftSRError
  } = useUpdateSalesReturnDraft();

  const {
    mutate: submitSalesReturnDraft,
    isLoading: isSubmittingSRDraft,
    isError: isSubmitDraftSRError,
    error: submitDraftSRError
  } = useSubmitSalesReturnDraft();

  const {
    mutate: cancelSalesReturn,
    isError: isCancelSRError,
    error: cancelSRError
  } = useCancelSalesReturn();

  const {
    mutate: deleteSRDraft,
    isError: isDeleteSRDraftError,
    error: deleteSRDraftError
  } = useDeleteSRDraft();

  useEffect(() => {
    if (!isSOFormFetching) {
      Boolean(SOForm?.orderHistories?.length) &&
        setActiveTab(SOForm?.orderHistories?.length);
      setValue("advance", SOForm?.saleOrder?.advancePercent);
      setValue("due", SOForm?.saleOrder?.dueDays);
      setValue("shipping", SOForm?.saleOrder?.shipping?.name);
      setValue("dateOfDelivery", new Date(SOForm?.saleOrder?.deliveryDate));
      SOForm?.saleOrder?.tnc &&
        setValue("otherItemsAndConditionsLabel", SOForm?.saleOrder?.tnc);
      SOForm?.saleOrder?.items?.forEach((item, index) => {
        append({
          _id: item.sku,
          rate: item?.skuData?.rate,
          disc: item?.discount?.percent,
          quantity: item.quantity,
          skuCode: item.skuData.skuCode,
          productName: item?.skuData?.productName ?? "",
          brandName: item?.skuData?.brandName ?? "",
          categoryName: item?.skuData?.categoryName ?? "",
          unit: item?.skuData.unit,
          gstRegime: item?.skuData?.gstRegime ?? 0,
          hsn: item?.skuData?.hsn,
          image: item?.skuData?.image
        });
      });
    }
    // eslint-disable-next-line
  }, [isSOFormFetching]);

  useEffect(() => {
    if (!isSalesReturnDataFetching) {
      Boolean(salesReturnData?.orderHistories?.length) &&
        setActiveTab(salesReturnData?.orderHistories?.length);
      setValue("orderRef", orderId);
      setValue(
        "reasonForReturn",
        salesReturnData?.saleReturn?.returnReason?.name
      );
      salesReturnData?.saleReturn?.tnc &&
        setValue(
          "otherItemsAndConditionsLabel",
          salesReturnData?.saleReturn?.tnc ?? ""
        );
      const saleReturnItems = getDraftItems(
        salesReturnData?.saleReturn?.items ?? [],
        CreateReturnFormData?.saleOrder?.items ?? [],
        Boolean(salesReturnData?.saleReturn?.status?.name === statusKeys.Draft)
      );
      saleReturnItems?.forEach((item, index) => {
        append({
          _id: item.sku,
          rate: item.rate,
          quantity: item.orderQuantity ?? item.quantity,
          returnedQuantity:
            index + 1 > salesReturnData?.saleReturn?.items?.length
              ? 0
              : item.quantity,
          skuCode: item.skuData.skuCode,
          productName: item?.skuData?.productName,
          brandName: item?.skuData?.brandName,
          categoryName: item?.skuData?.categoryName,
          unit: item?.skuData.unit,
          gstRegime: item?.skuData?.gstRegime ?? 0,
          hsn: item?.skuData?.hsn,
          image: item?.skuData?.image
        });
      });
    } // eslint-disable-next-line
  }, [isSalesReturnDataFetching]);

  useEffect(() => {
    if (!Boolean(returnId) && !isReturnFormDataFetching && orderRefWatch) {
      setValue("newOrders", []);
      CreateReturnFormData?.saleOrder?.items?.forEach((item, index) => {
        append({
          _id: item.sku,
          rate: item.rate,
          quantity: item.quantity,
          returnedQuantity: 0,
          skuCode: item.skuData.skuCode,
          productName: item?.skuData?.productName ?? "",
          brandName: item?.skuData?.brandName ?? "",
          categoryName: item?.skuData?.categoryName ?? "",
          unit: item?.skuData.unit,
          gstRegime: item?.skuData?.gstRegime ?? 0,
          hsn: item?.skuData?.hsn ?? "",
          image: item?.skuData?.image
        });
      });
    } // eslint-disable-next-line
  }, [isReturnFormDataFetching, JSON.stringify(orderRefWatch), returnId]);

  const customerBillingWatch = watch("customerBilling");

  const customerShipToWatch = watch("customerShipTo");

  const deliveryDateWatch = watch("dateOfDelivery");

  const paymentTermsWatch = watch(["advance", "due"]);

  const shipping = watch(["shipping"]);

  const reasonOfReturn = watch("reasonForReturn");

  const discWatch = watch(
    `newOrders.${fields.length ? fields.length - 1 : 0}.disc`
  );

  const quantityWatch = watch(
    `newOrders.${fields.length ? fields.length - 1 : 0}.quantity`
  );

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: returnId
      ? salesReturnData?.saleReturn?.code ?? "SR-Draft"
      : SOForm?.saleOrder?.code ?? "SO-Draft"
  });

  const orderTypeFilters = {
    orderType: {
      salesOrder: {
        name: "Sales Order",
        fields: [
          {
            name: "Sales Representative",
            id: "salesRepresentative",
            isDisabled: Boolean(orderId),
            type: "dropdown",
            placeholder: orderId
              ? SOForm?.saleOrder?.salesPerson?.name
              : "Select Sales Rep",
            options: dropdownOptions(
              CreateOrderForm?.salesPersons,
              "_id",
              "name"
            )
          },
          [
            {
              name: "Customer Name",
              id: "customerName",
              isDisabled: Boolean(orderId),
              type: "dropdown",
              placeholder: orderId
                ? SOForm?.saleOrder?.vendorData?.companyName
                : "Select Customer",
              allowSearch: true,
              options: dropdownOptions(
                CreateOrderForm?.customers,
                "_id",
                "companyName"
              )
            },
            {
              name: "Delivery Warehouse",
              id: "deliveryWarehouse",
              isDisabled: Boolean(orderId),
              type: "dropdown",
              placeholder: orderId
                ? SOForm?.saleOrder?.clientData?.warehouse?.code
                : "Select WH-Code",
              options: dropdownOptions(
                CreateOrderForm?.warehouses,
                "_id",
                "code"
              )
            }
          ],
          [
            {
              name: "Customer Billing",
              id: "customerBilling",
              isDisabled: Boolean(orderId),
              type: "dropdown",
              options: isCustomerBillingFetching
                ? []
                : optionsFormatter(
                    CustomerBillingFormData?.customerBillings,
                    "address"
                  ),
              placeholder: orderId ? "Address" : "Select Billing"
            },
            {
              name: "Customer ShipTo ",
              id: "customerShipTo",
              isDisabled: Boolean(orderId),
              type: "dropdown",
              options:
                customerBillingWatch !== "" &&
                customerBillingWatch !== undefined
                  ? dropdownOptions(
                      CustomerBillingFormData?.customerBillings?.find(
                        (obj) => obj["_id"] === customerBillingWatch
                      )?.stores,
                      "_id",
                      "code"
                    )
                  : [],
              placeholder: orderId
                ? SOForm?.saleOrder?.vendorData?.warehouse?.code
                : "Select ST-Code"
            }
          ],
          [
            {
              name: "Date of Delivery",
              id: "dateOfDelivery",
              isDisabled: Boolean(SOForm?.saleOrder?.status?.name)
                ? SOForm?.saleOrder?.status?.name === "Draft"
                  ? !isEditable
                  : true
                : false,
              type: "date",
              placeholder: "Select date"
            },
            {
              name: "Shipping",
              id: "shipping",
              isDisabled: Boolean(SOForm?.saleOrder?.status?.name)
                ? SOForm?.saleOrder?.status?.name === "Draft"
                  ? !isEditable
                  : true
                : false,
              type: "dropdown",
              options: CreateOrderForm?.shippings,
              placeholder: "Select Shipping"
            }
          ],
          {
            name: "Payment Terms",
            id: "paymentTerms",
            isDisabled: Boolean(SOForm?.saleOrder?.status?.name)
              ? SOForm?.saleOrder?.status?.name === "Draft"
                ? !isEditable
                : true
              : false
          }
        ],
        actions:
          !isSOFormFetching &&
          SOForm?.saleOrder?.status?.name === statusKeys.Draft
            ? ["Add", "Remove", "SaveDraft", "Submit", "Reset"]
            : ["Add", "Remove", "SaveDraft", "Submit"],
        selection: true,
        buyerTitle: "Seller",
        shippingTitle: "Shipto",
        sellerTitle: "Buyer",
        orderInfo: {
          salesOrderNo: orderId ? SOForm?.saleOrder?.code ?? "--" : "--",
          orderDate: orderId
            ? moment(SOForm?.saleOrder?.orderDate).format("DD-MM-YYYY")
            : "--",
          deliveryBy: orderId
            ? moment(SOForm?.saleOrder?.deliveryDate).format("DD-MM-YYYY")
            : deliveryDateWatch
            ? moment(deliveryDateWatch).format("DD-MM-YYYY")
            : "--"
        },
        sellerInfo: orderId
          ? vendorAddressData(SOForm?.saleOrder?.vendorData)
          : customerBillingWatch !== "" && customerBillingWatch !== undefined
          ? billingAddressObject({
              ...CreateOrderForm?.customers?.find(
                (item) => item._id === customerNameWatch
              ),
              ...CustomerBillingFormData?.customerBillings?.find(
                (item) => item._id === customerBillingWatch
              )
            })
          : {},
        buyerInfo: {
          customerName: `${CreateOrderForm?.clientAdmin?.firstName} ${CreateOrderForm?.clientAdmin?.lastName}`,
          companyName: `${CreateOrderForm?.client?.companyName}`,
          address: `${CreateOrderForm?.client?.billing?.address?.address}, ${CreateOrderForm?.client?.billing?.address?.area}, ${CreateOrderForm?.client?.billing?.address?.city}, ${CreateOrderForm?.client?.billing?.address?.state}, ${CreateOrderForm?.client?.billing?.address?.pincode}`,
          gst: CreateOrderForm?.client?.billing?.gstNo
            ? `GSTIN : ${CreateOrderForm?.client?.billing?.gstNo}`
            : ""
        },
        shipInfo: orderId
          ? shipToAddressData(SOForm?.saleOrder?.vendorData?.warehouse)
          : customerShipToWatch !== "" && customerShipToWatch !== undefined
          ? billingAddressObject(
              CustomerBillingFormData?.customerBillings
                ?.find((obj) => obj["_id"] === customerBillingWatch)
                ?.stores?.find((obj) => obj["_id"] === customerShipToWatch)
            )
          : {}
      },
      salesReturns: {
        name: "Sales Returns",
        fields: [
          {
            name: "Sales Order Ref",
            id: "orderRef",
            isDisabled: orderId ? true : false,
            type: "dropdown",
            placeholder: "Select order ref",
            allowSearch: true,
            options: isSOListFetching
              ? []
              : dropdownOptions(SOListFormData?.saleOrders, "_id", "code")
          },
          [
            {
              name: "ShipTo: ",
              id: "shipTo",
              isDisabled: true,
              type: "dropdown",
              placeholder: orderRefWatch
                ? CreateReturnFormData?.saleOrder?.vendorData?.warehouse?.code
                : "ST - Code",
              options: []
            },
            {
              name: "Receiving: ",
              id: "receiving",
              isDisabled: true,
              type: "dropdown",
              placeholder: orderRefWatch
                ? CreateReturnFormData?.saleOrder?.clientData?.warehouse?.code
                : "WH - Code",
              options: []
            }
          ],
          {
            name: "Customer Name",
            id: "customerName",
            isDisabled: true,
            type: "dropdown",
            placeholder: orderRefWatch
              ? CreateReturnFormData?.saleOrder?.vendorData?.companyName
              : "Select Customer",
            options: []
          },
          {
            name: "Reason for Return",
            id: "reasonForReturn",
            isDisabled: Boolean(salesReturnData?.saleReturn?.status?.name)
              ? salesReturnData?.saleReturn?.status?.name === "Draft"
                ? !isEditable
                : true
              : false,
            type: "dropdown",
            placeholder: "Select Reason ",
            options: isSOListFetching ? [] : CreateReturnFormData?.returnReasons
          }
        ],
        actions:
          !isSalesReturnDataFetching &&
          salesReturnData?.saleReturn?.status?.name === statusKeys.Draft
            ? ["SaveDraft", "Submit", "Reset"]
            : ["Edit", "SaveDraft", "Submit"],
        selection: false,
        buyerTitle: "Buyer",
        shippingTitle: "Receiving Warehouse",
        sellerTitle: "Seller",
        orderInfo:
          orderRefWatch !== "" && orderRefWatch !== undefined && returnId
            ? {
                SRNo: salesReturnData?.saleReturn?.code ?? "--",
                date: salesReturnData?.saleReturn?.orderDate
                  ? moment(salesReturnData?.saleReturn?.orderDate).format(
                      "DD-MM-YYYY"
                    )
                  : "--",
                salesOrderNo: CreateReturnFormData?.saleOrder?.code ?? "--",
                orderDate: CreateReturnFormData?.saleOrder?.orderDate
                  ? moment(CreateReturnFormData?.saleOrder?.orderDate).format(
                      "DD-MM-YYYY"
                    )
                  : "--"
              }
            : orderRefWatch !== "" &&
              orderRefWatch !== undefined &&
              !isReturnFormDataFetching
            ? {
                SRNo: salesReturnData?.saleReturn?.code ?? "--",
                date: salesReturnData?.saleReturn?.orderDate
                  ? moment(salesReturnData?.saleReturn?.orderDate).format(
                      "DD-MM-YYYY"
                    )
                  : "--",
                salesOrderNo: CreateReturnFormData?.saleOrder?.code ?? "--",
                orderDate: CreateReturnFormData?.saleOrder?.orderDate
                  ? moment(CreateReturnFormData?.saleOrder?.orderDate).format(
                      "DD-MM-YYYY"
                    )
                  : "--"
              }
            : {},
        sellerInfo: returnId
          ? vendorAddressData(salesReturnData?.saleReturn?.clientData)
          : vendorAddressData(CreateReturnFormData?.saleOrder?.clientData),
        buyerInfo: returnId
          ? vendorAddressData(salesReturnData?.saleReturn?.vendorData)
          : vendorAddressData(CreateReturnFormData?.saleOrder?.vendorData),
        shipInfo: returnId
          ? shipToAddressData(
              salesReturnData?.saleReturn?.clientData?.warehouse
            )
          : shipToAddressData(
              CreateReturnFormData?.saleOrder?.clientData?.warehouse
            )
      }
    }
  };

  const getOrderHistory = (item) => {
    switch (orderTypeWatch) {
      case "salesOrder":
        return {
          name: "Sales Order",
          actions: [],
          selection: false,
          buyerTitle: "Seller",
          shippingTitle: "Shipto",
          sellerTitle: "Buyer",
          orderInfo: {
            salesOrderNo: item?.code,
            orderDate: moment(item?.orderDate).format("DD-MM-YYYY"),
            deliveryBy: moment(item?.deliveryDate).format("DD-MM-YYYY")
          },
          sellerInfo: vendorAddressData(item?.vendorData),
          buyerInfo: vendorAddressData(item?.clientData),
          shipInfo: shipToAddressData(item?.vendorData?.warehouse)
        };
      case "salesReturns":
        return {
          name: "Sales Returns",
          actions: [],
          selection: false,
          buyerTitle: "Buyer",
          shippingTitle: "Receiving Warehouse",
          sellerTitle: "Seller",
          orderInfo: {
            SRNo: item?.code,
            date: moment(item?.orderDate).format("DD-MM-YYYY"),
            salesOrderNo: CreateReturnFormData?.saleOrder?.code,
            orderDate: moment(
              CreateReturnFormData?.saleOrder?.orderDate
            ).format("DD-MM-YYYY")
          },
          sellerInfo: vendorAddressData(item?.clientData),
          buyerInfo: vendorAddressData(item?.vendorData),
          shipInfo: shipToAddressData(item?.clientData?.warehouse)
        };
      default:
        return {};
    }
  };

  const getOrderHistoryTable = (item, isPrint = false) => {
    switch (orderTypeWatch) {
      case "salesOrder":
        return (
          <HistoryTable
            isPrint={isPrint}
            data={item?.items ?? []}
            orderType={"SalesOrder"}
            costBreakUp={{
              subtotal: item?.subTotalAmount,
              cgst: item?.taxes?.length > 1 ? item?.taxes[0]?.totalAmount : 0,
              sgst: item?.taxes?.length > 1 ? item?.taxes[1]?.totalAmount : 0,
              igst: item?.taxes?.length === 1 ? item?.taxes[0]?.totalAmount : 0,
              total: item?.totalAmount
            }}
            orderId={orderId}
            gstType={item?.taxes ? item?.taxes?.length > 1 : false}
            tnc={item?.tnc ?? ""}
            paymentTerms={[item?.advancePercent, item?.dueDays]}
            shipping={item?.shipping?.displayName}
            reasonOfReturn={""}
            preparedBy={`${item?.preparedBy?.firstName} ${item?.preparedBy?.lastName}`}
          />
        );
      case "salesReturns":
        return (
          <HistoryTable
            isPrint={isPrint}
            data={item?.items}
            orderType={"SalesReturns"}
            costBreakUp={{
              subtotal: item?.subTotalAmount,
              cgst: item?.taxes?.length > 1 ? item?.taxes[0]?.totalAmount : 0,
              sgst: item?.taxes?.length > 1 ? item?.taxes[1]?.totalAmount : 0,
              igst: item?.taxes?.length === 1 ? item?.taxes[0]?.totalAmount : 0,
              total: item?.totalAmount
            }}
            orderId={orderId}
            gstType={item?.taxes ? item?.taxes?.length > 1 : false}
            tnc={item?.tnc ?? ""}
            paymentTerms={[]}
            shipping={""}
            reasonOfReturn={item?.returnReason?.displayName}
            preparedBy={`${item?.preparedBy?.firstName} ${item?.preparedBy?.lastName}`}
          />
        );
      default:
        return <>Not Found</>;
    }
  };

  const orderHistoriesAllReport = (item) => {
    const orderData = getOrderHistory(item);

    const otherOldPODetails = [
      { label: `Shipping :`, value: item?.shipping?.displayName },
      {
        label: `Payment Terms :`,
        value: `${item?.advancePercent}% Advance. Balance Due ${item?.dueDays} days from receipt`
      }
    ];

    const otherOldPODetailsComp = (
      <Grid container rowSpacing={2}>
        {otherOldPODetails?.map((item, index) => (
          <OtherDetailsComp key={index} label={item.label} value={item.value} />
        ))}
      </Grid>
    );

    const otherOldDetailsPR = (
      <Grid container rowSpacing={2}>
        <OtherDetailsComp
          label={"Return Reason :"}
          value={item?.returnReason?.displayName}
        />
      </Grid>
    );

    return (
      <>
        <Box
          sx={{ display: { xs: "none", md: "flex" } }}
          flexDirection={"column"}
          gap={1}
        >
          <OrderReport
            isDraft={false}
            title={orderData?.name}
            sellerTitle={orderData?.sellerTitle}
            buyerTitle={orderData?.buyerTitle}
            shippingTitle={orderData?.shippingTitle}
            orderInfo={orderData?.orderInfo}
            sellerInfo={orderData?.sellerInfo}
            buyerInfo={orderData?.buyerInfo}
            shipInfo={orderData?.shipInfo}
            actions={orderData?.actions}
          >
            {getOrderHistoryTable(item)}
          </OrderReport>
        </Box>
        <Box
          sx={{ display: { xs: "flex", md: "none" } }}
          flexDirection={"column"}
          gap={1}
        >
          <MobileOrderReport
            status={item?.status?.name}
            otherInfo={
              orderTypeWatch === "salesOrder"
                ? otherOldPODetailsComp
                : otherOldDetailsPR
            }
            mobileTable={getOrderHistoryTable(item)}
            remarksComp={
              <Box
                display={"flex"}
                minWidth={"289px"}
                minHeight={"90px"}
                border={"1px solid #B7BEC7"}
                borderRadius={"8px"}
                padding={"10px"}
              >
                <Typography color={"text.disabled"}>
                  {item?.tnc || ""}
                </Typography>
              </Box>
            }
            Total={[
              {
                label: "Subtotal Amount :",
                value: item?.subTotalAmount
                  ? currencyOnlyFormatter(
                      parseFloat(item?.subTotalAmount).toFixed(2)
                    )
                  : "--"
              },
              ...[
                ...(item?.taxes?.length > 1
                  ? [
                      {
                        label: "CGST :",
                        value: item?.taxes[0]?.totalAmount
                          ? currencyOnlyFormatter(
                              parseFloat(item?.taxes[0]?.totalAmount).toFixed(2)
                            )
                          : "--"
                      },
                      {
                        label: "SGST :",
                        value: item?.taxes[1]?.totalAmount
                          ? currencyOnlyFormatter(
                              parseFloat(item?.taxes[1]?.totalAmount).toFixed(2)
                            )
                          : "--"
                      }
                    ]
                  : [
                      {
                        label: "IGST :",
                        value: item?.taxes[0]?.totalAmount
                          ? currencyOnlyFormatter(
                              parseFloat(item?.taxes[0]?.totalAmount).toFixed(2)
                            )
                          : "--"
                      }
                    ])
              ],
              {
                label: "Total Amount :",
                value: item?.totalAmount
                  ? currencyOnlyFormatter(
                      parseFloat(item?.totalAmount).toFixed(2)
                    )
                  : "--"
              }
            ]}
            ButtonGroup={""}
            preparedBy={`${item?.preparedBy?.firstName} ${item?.preparedBy?.lastName}`}
            title={orderData?.name}
            sellerTitle={orderData?.sellerTitle}
            buyerTitle={orderData?.buyerTitle}
            shippingTitle={orderData?.shippingTitle}
            orderInfo={orderData?.orderInfo}
            sellerInfo={orderData?.sellerInfo}
            buyerInfo={orderData?.buyerInfo}
            shipInfo={orderData?.shipInfo}
          />
        </Box>
        {orderId && (
          <div style={{ display: "none" }}>
            <Box ref={componentRef} sx={{ padding: "10px" }}>
              <Box display={"flex"} flexDirection={"column"} gap={1}>
                <OrderReport
                  isDraft={false}
                  title={orderData?.name}
                  sellerTitle={orderData?.sellerTitle}
                  buyerTitle={orderData?.buyerTitle}
                  shippingTitle={orderData?.shippingTitle}
                  orderInfo={orderData?.orderInfo}
                  sellerInfo={orderData?.sellerInfo}
                  buyerInfo={orderData?.buyerInfo}
                  shipInfo={orderData?.shipInfo}
                  actions={orderData?.actions}
                >
                  {getOrderHistoryTable(item, true)}
                </OrderReport>
              </Box>
            </Box>
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    if (Boolean(createReturnId)) {
      setIsEditable(false);
      setValue("orderType", "salesReturns");
      setValue("orderRef", createReturnId);
      setIsDraft(false);
    }
  }, [setValue, createReturnId]);

  useEffect(() => {
    if (!Boolean(createReturnId)) {
      setIsEditable(false);
      reset((prevValue) => ({
        orderType: orderId ? manageOrderType : prevValue.orderType,
        newOrders: []
      }));
      setActiveTab(0);
      setIsDraft(Boolean(orderId) || Boolean(returnId));
      setCheckOrderType(orderTypeWatch);
    }
  }, [
    reset,
    orderTypeWatch,
    manageOrderType,
    createReturnId,
    orderId,
    returnId
  ]);

  const getTable = (type, isPrint = false) => {
    switch (type) {
      case "Sales Order":
        return (
          <SalesOrderTable
            costBreakUp={costBreakUp}
            setCostBreakUp={setCostBreakUp}
            isPrint={isPrint}
            fields={fields}
            control={control}
            watch={watch}
            append={append}
            remove={remove}
            onAdd={() => {
              if (fields.length < 1 || (discWatch && quantityWatch)) {
                append({ item: "" });
              } else {
                enqueueSnackbar("kindly first fill quantity and Discount", {
                  variant: "error"
                });
              }
            }}
            onRowSelectionChange={(rows) => {
              selectedRowsRef.current = rows;
            }}
            orderId={orderId}
            gstType={
              orderId
                ? Boolean(
                    SOForm?.saleOrder?.clientData?.billing?.address?.state ===
                      SOForm?.saleOrder?.vendorData?.billing?.address?.state
                  )
                : Boolean(
                    CreateOrderForm?.client?.billing?.address?.state ===
                      CustomerBillingFormData?.customerBillings?.find(
                        (obj) => obj["_id"] === customerBillingWatch
                      )?.address?.state
                  )
            }
            options={isCreateOrderFormFetching ? [] : CreateOrderForm}
            orderStatus={SOForm?.saleOrder?.status?.name ?? ""}
            isEditable={
              Boolean(SOForm?.saleOrder?.status?.name) ? isEditable : true
            }
            applyGst={
              orderId
                ? Boolean(SOForm?.saleOrder?.clientData?.billing?.gstNo)
                : Boolean(CreateOrderForm?.client?.billing?.gstNo)
            }
            paymentTerms={paymentTermsWatch}
            shipping={shipping}
            preparedBy={`${CreateOrderForm?.clientAdmin?.firstName} ${CreateOrderForm?.clientAdmin?.lastName}`}
          />
        );
      case "Sales Returns":
        return (
          <SalesReturnsTable
            costBreakUp={costBreakUp}
            setCostBreakUp={setCostBreakUp}
            isPrint={isPrint}
            fields={fields}
            control={control}
            watch={watch}
            isEditable={isEditable}
            gstType={
              returnId
                ? Boolean(
                    salesReturnData?.saleReturn?.vendorData?.billing?.address
                      ?.state ===
                      salesReturnData?.saleReturn?.clientData?.billing?.address
                        ?.state
                  )
                : Boolean(
                    CreateReturnFormData?.saleOrder?.clientData?.billing
                      ?.address?.state ===
                      CreateReturnFormData?.saleOrder?.vendorData?.billing
                        ?.address?.state
                  )
            }
            applyGst={
              orderId
                ? Boolean(
                    salesReturnData?.saleReturn?.clientData?.billing?.gstNo
                  )
                : Boolean(
                    CreateReturnFormData?.saleOrder?.clientData?.billing?.gstNo
                  )
            }
            reasonOfReturn={reasonOfReturn}
            orderStatus={salesReturnData?.saleReturn?.status?.name ?? ""}
            onEdit={() => {
              setIsEditable((prevIsEditable) => !prevIsEditable);
            }}
            preparedBy={`${CreateReturnFormData?.saleOrder?.clientData?.name}`}
          />
        );
      default:
        return <>Not Found</>;
    }
  };

  const getTableFunctions = (type) => {
    switch (type) {
      case "Sales Order": {
        return {
          onAdd: () => {
            if (fields.length < 1 || (discWatch && quantityWatch)) {
              append({ item: "" });
            } else {
              enqueueSnackbar("kindly first fill quantity and Discount", {
                variant: "error"
              });
            }
          },
          onRemove: () => {
            const rowsToRemove = Object.keys(selectedRowsRef.current).filter(
              (key) => selectedRowsRef.current[key]
            );
            remove(rowsToRemove);
          },
          onEdit: () => {
            setIsEditable((prevIsEditable) => !prevIsEditable);
          }
        };
      }
      case "Sales Returns": {
        return {
          onEdit: () => {
            setIsEditable((prevIsEditable) => !prevIsEditable);
          }
        };
      }
      default:
        return <>Not Found</>;
    }
  };

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  const onReset = () => {
    reset();
    if (orderTypeWatch === "salesOrder") {
      setValue("advance", SOForm?.saleOrder?.advancePercent);
      setValue("due", SOForm?.saleOrder?.dueDays);
      setValue("shipping", SOForm?.saleOrder?.shipping?.name);
      setValue("dateOfDelivery", new Date(SOForm?.saleOrder?.deliveryDate));
      SOForm?.saleOrder?.tnc &&
        setValue("otherItemsAndConditionsLabel", SOForm?.saleOrder?.tnc);
      SOForm?.saleOrder?.items?.forEach((item, index) => {
        append({
          _id: item.sku,
          rate: item?.skuData?.rate,
          disc: item?.discount?.percent,
          quantity: item.quantity,
          skuCode: item.skuData.skuCode,
          productName: item?.skuData?.productName ?? "",
          brandName: item?.skuData?.brandName ?? "",
          categoryName: item?.skuData?.categoryName ?? "",
          unit: item?.skuData.unit,
          gstRegime: item?.skuData?.gstRegime ?? 0,
          hsn: item?.skuData?.hsn,
          image: item?.skuData?.image
        });
      });
    }
    if (orderTypeWatch === "salesReturns") {
      setValue("orderRef", orderId);
      setValue(
        "reasonForReturn",
        salesReturnData?.saleReturn?.returnReason?.name
      );
      salesReturnData?.saleReturn?.tnc &&
        setValue(
          "otherItemsAndConditionsLabel",
          salesReturnData?.saleReturn?.tnc ?? ""
        );
      const saleReturnItems = getDraftItems(
        salesReturnData?.saleReturn?.items ?? [],
        CreateReturnFormData?.saleOrder?.items ?? [],
        Boolean(salesReturnData?.saleReturn?.status?.name === statusKeys.Draft)
      );
      saleReturnItems?.forEach((item, index) => {
        append({
          _id: item.sku,
          rate: item.rate,
          quantity: item.orderQuantity ?? item.quantity,
          returnedQuantity:
            index + 1 > salesReturnData?.saleReturn?.items?.length
              ? 0
              : item.quantity,
          skuCode: item.skuData.skuCode,
          productName: item?.skuData?.productName,
          brandName: item?.skuData?.brandName,
          categoryName: item?.skuData?.categoryName,
          unit: item?.skuData.unit,
          gstRegime: item?.skuData?.gstRegime ?? 0,
          hsn: item?.skuData?.hsn,
          image: item?.skuData?.image
        });
      });
    }
  };

  const onModify = () => {
    if (
      (orderTypeWatch === "salesOrder" &&
        SOForm?.saleOrder?.status?.name === statusKeys.Draft) ||
      (orderTypeWatch === "salesReturns" &&
        salesReturnData?.saleReturn?.status?.name === statusKeys.Draft)
    ) {
      setIsDraft((prev) => !prev);
      setIsEditable((prevIsEditable) => !prevIsEditable);
    }
    if (
      (orderTypeWatch === "salesOrder" &&
        SOForm?.saleOrder?.status?.name !== statusKeys.Draft) ||
      (orderTypeWatch === "salesReturns" &&
        salesReturnData?.saleReturn?.status?.name !== statusKeys.Draft)
    ) {
      setIsEditable((prevIsEditable) => !prevIsEditable);
      setUpdateOrderButton((prev) => !prev);
    }
    onReset();
  };

  const onUpdate = (data) => {
    showDialog({
      component: (
        <SuccessfulDialog
          text={"Are you sure you want to Update the Order ?"}
          btn1Text={"Go Back"}
          btn2Text={"Update Order"}
          btn1Callback={() => hideDialog()}
          btn2Callback={() => updateData(data)}
        />
      )
    });
  };

  const updateData = (data) => {
    if (orderTypeWatch === "salesOrder") {
      const formData = {
        items: data["newOrders"]?.map((item) => ({
          sku: item._id,
          quantity: +item.quantity
        }))
      };
      updateSalesOrder(
        {
          data: { ...formData },
          orderId: orderId
        },
        {
          onSuccess: (res) => {
            hideDialog();
            enqueueSnackbar("Sales Order Updated!", {
              variant: "success"
            });
            setIsEditable((prevIsEditable) => !prevIsEditable);
            setUpdateOrderButton((prev) => !prev);
            reset();
            refetchSOForm();
          }
        }
      );
    }
    if (orderTypeWatch === "salesReturns") {
      const prFormData = {
        items: data["newOrders"]?.map((item) => ({
          sku: item._id,
          quantity: item.returnedQuantity
        }))
      };
      updateSalesReturn(
        {
          data: { ...prFormData },
          orderId: orderId,
          returnId: returnId
        },
        {
          onSuccess: (res) => {
            hideDialog();
            enqueueSnackbar("Sales Return Updated!", {
              variant: "success"
            });
            setIsEditable((prevIsEditable) => !prevIsEditable);
            setUpdateOrderButton((prev) => !prev);
            reset();
            refetchSRForm();
          }
        }
      );
    }
  };

  const onCancel = () => {
    showDialog({
      component: (
        <SuccessfulDialog
          text={"Are you sure you want to Cancel the Order ?"}
          btn1Text={"Go Back"}
          btn2Text={"Cancel Order"}
          btn1Callback={() => hideDialog()}
          btn2Callback={() => cancelOrder()}
        />
      )
    });
  };

  const cancelOrder = () => {
    if (orderTypeWatch === "salesOrder") {
      cancelSalesOrder(
        {
          orderId: orderId
        },
        {
          onSuccess: (res) => {
            hideDialog();
            enqueueSnackbar("Sales Order Cancelled!", {
              variant: "success"
            });
            navigate(`${appRoutes.sales.main}/${appRoutes.sales.salesOrders}`);
          }
        }
      );
    }
    if (orderTypeWatch === "salesReturns") {
      cancelSalesReturn(
        {
          orderId: orderId,
          returnId: returnId
        },
        {
          onSuccess: (res) => {
            hideDialog();
            enqueueSnackbar("Sales Return Cancelled!", {
              variant: "success"
            });
            navigate(`${appRoutes.sales.main}/${appRoutes.sales.salesOrders}`);
          }
        }
      );
    }
  };

  const onSaveDraft = (data) => {
    if (orderTypeWatch === "salesOrder") {
      const formData = {
        ...{
          ...(orderId
            ? {}
            : {
                salesPerson: data["salesRepresentative"],
                customerStore: data["customerShipTo"],
                warehouse: data["deliveryWarehouse"]
              })
        },
        deliveryDate: moment(data["dateOfDelivery"]).format("YYYY-MM-DD"),
        shipping: data["shipping"],
        advancePercent: +data["advance"],
        dueDays: +data["due"],
        tnc: data["otherItemsAndConditionsLabel"]
          ? data["otherItemsAndConditionsLabel"]
          : null,
        items: data["newOrders"]?.map((item) => ({
          sku: item._id,
          quantity: +item.quantity,
          discount: {
            percent: +item.disc
          }
        }))
      };
      orderId
        ? updateSalesOrderDraft(
            {
              data: { ...formData },
              salesOrderId: orderId
            },
            {
              onSuccess: (res) => {
                enqueueSnackbar("Sales Draft Updated!", {
                  variant: "success"
                });
                setIsDraft((prev) => !prev);
                setIsEditable((prevIsEditable) => !prevIsEditable);
                reset();
                refetchSOForm();
              }
            }
          )
        : saveDraftSalesOrder(
            {
              data: { ...formData }
            },
            {
              onSuccess: (res) => {
                enqueueSnackbar("Sales Order Draft Saved!", {
                  variant: "success"
                });
                navigate(
                  `${appRoutes.sales.main}/${appRoutes.sales.salesOrders}`
                );
              }
            }
          );
    }
    if (orderTypeWatch === "salesReturns") {
      const items = data["newOrders"]?.filter(
        (item) => +item.returnedQuantity !== 0
      );
      const srFormData = {
        returnReason: data["reasonForReturn"],
        tnc: data["otherItemsAndConditionsLabel"],
        items: items?.map((item) => ({
          sku: item._id,
          quantity: item.returnedQuantity
        }))
      };
      orderId && returnId
        ? updateSalesReturnDraft(
            {
              data: { ...srFormData },
              salesOrderId: orderId,
              salesReturnId: returnId
            },
            {
              onSuccess: (res) => {
                enqueueSnackbar("Sales Return Draft Updated!", {
                  variant: "success"
                });
                setIsDraft((prev) => !prev);
                setIsEditable((prevIsEditable) => !prevIsEditable);
                reset();
                refetchSRForm();
              }
            }
          )
        : saveDraftSalesReturn(
            {
              data: { ...srFormData },
              orderId: orderRefWatch
            },
            {
              onSuccess: (res) => {
                enqueueSnackbar("Sales Return Draft Saved!", {
                  variant: "success"
                });
                navigate(
                  `${appRoutes.sales.main}/${appRoutes.sales.salesOrders}`
                );
              }
            }
          );
    }
  };

  const onSubmit = (data) => {
    showDialog({
      component: (
        <SuccessfulDialog
          text={"Are you sure you want to submit the document ?"}
          subText={"Submitted documents cannot be edited. "}
          btn1Text={"Go to Draft"}
          btn2Text={"Submit"}
          btn1Callback={() => hideDialog()}
          btn2Callback={() => submitData(data)}
        />
      )
    });
  };

  const submitData = (data) => {
    if (orderTypeWatch === "salesOrder") {
      const formData = {
        ...{
          ...(orderId
            ? {}
            : {
                salesPerson: data["salesRepresentative"],
                customerStore: data["customerShipTo"],
                warehouse: data["deliveryWarehouse"]
              })
        },
        deliveryDate: moment(data["dateOfDelivery"]).format("YYYY-MM-DD"),
        shipping: data["shipping"],
        advancePercent: +data["advance"],
        dueDays: +data["due"],
        tnc: data["otherItemsAndConditionsLabel"]
          ? data["otherItemsAndConditionsLabel"]
          : null,
        items: data["newOrders"]?.map((item) => ({
          sku: item._id,
          quantity: +item.quantity,
          discount: {
            percent: +item.disc
          }
        }))
      };
      orderId
        ? submitSalesOrderDraft(
            {
              data: { ...formData },
              salesOrderId: orderId
            },
            {
              onSuccess: (res) => {
                hideDialog();
                enqueueSnackbar("Sales Order created!", {
                  variant: "success"
                });
                setIsDraft((prev) => !prev);
                setIsEditable((prevIsEditable) => !prevIsEditable);
                reset();
                refetchSOForm();
              }
            }
          )
        : createSalesOrder(
            {
              data: { ...formData }
            },
            {
              onSuccess: (res) => {
                hideDialog();
                enqueueSnackbar("Sales Order created!", {
                  variant: "success"
                });
                navigate(
                  `${appRoutes.sales.main}/${appRoutes.sales.salesOrders}`
                );
              }
            }
          );
    }
    if (orderTypeWatch === "salesReturns") {
      const items = data["newOrders"]?.filter(
        (item) => +item.returnedQuantity !== 0
      );
      const srFormData = {
        returnReason: data["reasonForReturn"],
        tnc: data["otherItemsAndConditionsLabel"],
        items: items?.map((item) => ({
          sku: item._id,
          quantity: item.returnedQuantity
        }))
      };
      orderId && returnId
        ? submitSalesReturnDraft(
            {
              data: { ...srFormData },
              salesOrderId: orderId,
              salesReturnId: returnId
            },
            {
              onSuccess: (res) => {
                hideDialog();
                enqueueSnackbar("Sales Return Submitted!", {
                  variant: "success"
                });
                setIsDraft((prev) => !prev);
                setIsEditable((prevIsEditable) => !prevIsEditable);
                reset();
                refetchSRCreateFrom();
                refetchSRForm();
              }
            }
          )
        : createSalesReturn(
            {
              data: { ...srFormData },
              orderId: orderRefWatch
            },
            {
              onSuccess: (res) => {
                hideDialog();
                enqueueSnackbar("Sales Return Draft Saved!", {
                  variant: "success"
                });
                navigate(
                  `${appRoutes.sales.main}/${appRoutes.sales.salesOrders}`
                );
              }
            }
          );
    }
  };

  const onDeleteClick = () => {
    showDialog({
      component: (
        <SuccessfulDialog
          text={"Are you sure you want to Delete this draft ?"}
          subText={"Deleted documents cannot be recovered. "}
          btn1Text={"Go to Draft"}
          btn2Text={"Delete"}
          btn1Callback={() => hideDialog()}
          btn2Callback={() => deleteDraft()}
        />
      )
    });
  };

  const deleteDraft = () => {
    if (orderTypeWatch === "salesOrder") {
      deleteSODraft(
        {
          salesOrderId: orderId
        },
        {
          onSuccess: (res) => {
            hideDialog();
            enqueueSnackbar("SO Draft Deleted!", {
              variant: "success"
            });
            navigate(`${appRoutes.sales.main}/${appRoutes.sales.salesOrders}`);
          }
        }
      );
    }
    if (orderTypeWatch === "salesReturns") {
      deleteSRDraft(
        {
          salesOrderId: orderId,
          salesReturnId: returnId
        },
        {
          onSuccess: (res) => {
            hideDialog();
            enqueueSnackbar("SR Draft Deleted!", {
              variant: "success"
            });
            navigate(`${appRoutes.sales.main}/${appRoutes.sales.salesOrders}`);
          }
        }
      );
    }
  };

  useEffect(() => {
    if (isCreateError) {
      enqueueSnackbar(createError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isUpdateError) {
      enqueueSnackbar(updateError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isUpdateDraftError)
      enqueueSnackbar(updateDraftError?.response?.data?.message, {
        variant: "error"
      });
    if (isSubmitDraftError) {
      enqueueSnackbar(submitDraftError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isSaveDraftError)
      enqueueSnackbar(saveDraftError?.response?.data?.message, {
        variant: "error"
      });
    if (isCreateSRError) {
      enqueueSnackbar(createSRError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isUpdateSRError) {
      enqueueSnackbar(updateSRError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isUpdateDraftSRError)
      enqueueSnackbar(updateDraftSRError?.response?.data?.message, {
        variant: "error"
      });
    if (isSubmitDraftSRError) {
      enqueueSnackbar(submitDraftSRError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isSaveDraftSRError)
      enqueueSnackbar(saveDraftSRError?.response?.data?.message, {
        variant: "error"
      });
    if (isCancelError) {
      enqueueSnackbar(cancelError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isDeleteSODraftError) {
      enqueueSnackbar(deleteSODraftError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isDeleteSRDraftError) {
      enqueueSnackbar(deleteSRDraftError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isCancelSRError) {
      enqueueSnackbar(cancelSRError?.response?.data?.message, {
        variant: "error"
      });
    }
  }, [
    updateDraftError?.response?.data?.message,
    submitDraftError?.response?.data?.message,
    createError?.response?.data?.message,
    updateError?.response?.data?.message,
    saveDraftError?.response?.data?.message,
    updateDraftSRError?.response?.data?.message,
    submitDraftSRError?.response?.data?.message,
    createSRError?.response?.data?.message,
    updateSRError?.response?.data?.message,
    saveDraftSRError?.response?.data?.message,
    cancelError?.response?.data?.message,
    deleteSODraftError?.response?.data?.message,
    deleteSRDraftError?.response?.data?.message,
    cancelSRError?.response?.data?.message,
    isSaveDraftError,
    isUpdateDraftError,
    isSubmitDraftError,
    isCreateError,
    isUpdateError,
    isSaveDraftSRError,
    isUpdateDraftSRError,
    isSubmitDraftSRError,
    isCreateSRError,
    isUpdateSRError,
    isDeleteSODraftError,
    isCancelError,
    isDeleteSRDraftError,
    isCancelSRError
  ]);

  const otherSODetails = [
    { label: `Shipping :`, value: shipping },
    {
      label: `Payment Terms :`,
      value:
        paymentTermsWatch[0] !== "" &&
        paymentTermsWatch[0] !== undefined &&
        paymentTermsWatch[1]
          ? `${paymentTermsWatch[0]}% Advance. Balance Due ${paymentTermsWatch[1]} days from receipt`
          : "--"
    }
  ];

  const otherSODetailsComp = (
    <Grid container rowSpacing={2}>
      {otherSODetails?.map((item, index) => (
        <OtherDetailsComp key={index} label={item.label} value={item.value} />
      ))}
    </Grid>
  );

  const otherDetailsSR = (
    <Grid container rowSpacing={2}>
      <OtherDetailsComp label={"Return Reason :"} value={reasonOfReturn} />
    </Grid>
  );

  const Total = [
    {
      label: "Subtotal Amount :",
      value: costBreakUp?.subtotal
        ? currencyOnlyFormatter(parseFloat(costBreakUp?.subtotal).toFixed(2))
        : "--"
    },
    ...[
      ...(Boolean(costBreakUp?.gstType)
        ? [
            {
              label: "CGST :",
              value: costBreakUp?.cgst
                ? currencyOnlyFormatter(
                    parseFloat(costBreakUp?.cgst).toFixed(2)
                  )
                : "--"
            },
            {
              label: "SGST :",
              value: costBreakUp?.sgst
                ? currencyOnlyFormatter(
                    parseFloat(costBreakUp?.sgst).toFixed(2)
                  )
                : "--"
            }
          ]
        : [
            {
              label: "IGST :",
              value: costBreakUp?.igst
                ? currencyOnlyFormatter(
                    parseFloat(costBreakUp?.igst).toFixed(2)
                  )
                : "--"
            }
          ])
    ],
    {
      label: "Total Amount :",
      value: costBreakUp?.total
        ? currencyOnlyFormatter(parseFloat(costBreakUp?.total).toFixed(2))
        : "--"
    }
  ];

  const orderStatus =
    SOForm?.saleOrder?.status?.name ||
    salesReturnData?.saleReturn?.status?.name ||
    "";

  const RemarksComp = (
    <TextArea
      control={control}
      name={"otherItemsAndConditionsLabel"}
      disabled={
        Boolean(orderStatus)
          ? orderStatus === "Draft"
            ? !isEditable
            : true
          : false
      }
    />
  );

  const DrawerComponent = (
    <Grid container rowSpacing={2}>
      {orderTypeFilters.orderType[orderTypeWatch]?.fields?.map(
        (item, index) => (
          <Grid
            container
            rowSpacing={2}
            padding={"20px 15px 2px 10px"}
            key={index}
          >
            {generateMobileFields(
              item,
              control,
              !isCreateOrderFormFetching && { ...CreateOrderForm }
            )}
          </Grid>
        )
      )}
      <Grid
        xs={12}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        minHeight={"72px"}
        sx={{ background: "#F0F2F5" }}
        mt={2}
      >
        <Button
          variant="contained"
          sx={{ width: "100px", height: "40px" }}
          onClick={hideDrawer}
        >
          {"Next"}
        </Button>
      </Grid>
    </Grid>
  );

  useEffect(
    () => {
      if (
        isMobileDevice &&
        Boolean(orderTypeWatch) &&
        !Boolean(orderId) &&
        ((!isCreateOrderFormFetching && orderTypeWatch === "salesOrder") ||
          (!isSOListFetching && orderTypeWatch === "salesReturns"))
      ) {
        showDrawer({
          title: Boolean(orderId) ? "Manage Order" : "Create Order",
          height: "fit-content",
          sx: { color: "#2773FF", fontWeight: 600 },
          component: DrawerComponent
        });
        if (Boolean(customerBillingWatch) && !isCustomerBillingFetching) {
          showDrawer({
            title: Boolean(orderId) ? "Manage Order" : "Create Order",
            height: "fit-content",
            sx: { color: "#2773FF", fontWeight: 600 },
            component: DrawerComponent
          });
        }
        if (Boolean(orderRefWatch) && !isReturnFormDataFetching) {
          showDrawer({
            title: Boolean(orderId) ? "Manage Order" : "Create Order",
            height: "fit-content",
            sx: { color: "#2773FF", fontWeight: 600 },
            component: DrawerComponent
          });
        }
      }
    },
    // eslint-disable-next-line
    [
      JSON.stringify(orderTypeWatch),
      JSON.stringify(customerBillingWatch),
      orderId,
      isSOListFetching,
      isCreateOrderFormFetching,
      isCustomerBillingFetching,
      isReturnFormDataFetching
    ]
  );

  const inDraftState =
    SOForm?.saleOrder?.status?.name === "Draft" ||
    salesReturnData?.saleReturn?.status?.name === "Draft";

  const showModifyOnOrder =
    Boolean(SOForm?.orderHistories?.length) ||
    Boolean(salesReturnData?.orderHistories?.length)
      ? SOForm?.orderHistories?.length === activeTab ||
        salesReturnData?.orderHistories?.length === activeTab
      : true;

  const inPlacedState =
    SOForm?.saleOrder?.status?.name === "Placed" ||
    salesReturnData?.saleReturn?.status?.name === "Placed";

  const inCompletedState =
    SOForm?.saleOrder?.status?.name === "Dispatched" ||
    SOForm?.saleOrder?.status?.name === "Delivered" ||
    salesReturnData?.saleReturn?.status?.name === "Received";

  const ButtonGroup = (inDraftState || !inCompletedState) &&
    showModifyOnOrder && (
      <PurchaseButtonGroup
        inDraftState={inDraftState}
        inPlacedState={inPlacedState}
        isDraft={isDraft}
        onCancel={onCancel}
        onDeleteClick={onDeleteClick}
        onModify={onModify}
        onSaveDraft={handleSubmit(onSaveDraft)}
        onSubmitClick={handleSubmit(onSubmit)}
        onUpdate={handleSubmit(onUpdate)}
        updateOrderButton={updateOrderButton}
        onEdit={
          getTableFunctions(orderTypeFilters.orderType[orderTypeWatch]?.name)
            .onEdit
        }
      />
    );

  const OrderReportComp = (
    <>
      <Box
        sx={{ display: { xs: "none", md: "flex" } }}
        flexDirection={"column"}
        gap={1}
      >
        <OrderReport
          isDraft={!isDraft}
          title={orderTypeFilters.orderType[orderTypeWatch]?.name}
          sellerTitle={orderTypeFilters.orderType[orderTypeWatch]?.sellerTitle}
          buyerTitle={orderTypeFilters.orderType[orderTypeWatch]?.buyerTitle}
          shippingTitle={
            orderTypeFilters.orderType[orderTypeWatch]?.shippingTitle
          }
          orderInfo={orderTypeFilters.orderType[orderTypeWatch]?.orderInfo}
          sellerInfo={orderTypeFilters.orderType[orderTypeWatch]?.sellerInfo}
          buyerInfo={orderTypeFilters.orderType[orderTypeWatch]?.buyerInfo}
          shipInfo={orderTypeFilters.orderType[orderTypeWatch]?.shipInfo}
          actions={orderTypeFilters.orderType[orderTypeWatch]?.actions}
          onAdd={
            getTableFunctions(orderTypeFilters.orderType[orderTypeWatch]?.name)
              .onAdd
          }
          onRemove={
            getTableFunctions(orderTypeFilters.orderType[orderTypeWatch]?.name)
              .onRemove
          }
          onEdit={
            getTableFunctions(orderTypeFilters.orderType[orderTypeWatch]?.name)
              .onEdit
          }
          onSaveDraft={handleSubmit(onSaveDraft)}
          onSubmit={handleSubmit(onSubmit)}
          onModify={onModify}
        >
          {getTable(orderTypeFilters.orderType[orderTypeWatch]?.name)}
        </OrderReport>
      </Box>
      <Box
        sx={{ display: { xs: "flex", md: "none" } }}
        flexDirection={"column"}
        gap={1}
      >
        <MobileOrderReport
          status={
            SOForm?.saleOrder?.status?.name ||
            salesReturnData?.saleReturn?.status?.name
          }
          otherInfo={
            orderTypeWatch === "salesOrder"
              ? otherSODetailsComp
              : otherDetailsSR
          }
          mobileTable={getTable(
            orderTypeFilters.orderType[orderTypeWatch]?.name
          )}
          remarksComp={RemarksComp}
          Total={Total}
          ButtonGroup={ButtonGroup}
          preparedBy={
            orderTypeWatch === "salesOrder"
              ? `${CreateOrderForm?.clientAdmin?.firstName} ${CreateOrderForm?.clientAdmin?.lastName}`
              : `${CreateReturnFormData?.saleOrder?.clientData?.name}`
          }
          title={orderTypeFilters.orderType[orderTypeWatch]?.name}
          sellerTitle={orderTypeFilters.orderType[orderTypeWatch]?.sellerTitle}
          buyerTitle={orderTypeFilters.orderType[orderTypeWatch]?.buyerTitle}
          shippingTitle={
            orderTypeFilters.orderType[orderTypeWatch]?.shippingTitle
          }
          orderInfo={orderTypeFilters.orderType[orderTypeWatch]?.orderInfo}
          sellerInfo={orderTypeFilters.orderType[orderTypeWatch]?.sellerInfo}
          buyerInfo={orderTypeFilters.orderType[orderTypeWatch]?.buyerInfo}
          shipInfo={orderTypeFilters.orderType[orderTypeWatch]?.shipInfo}
        />
      </Box>
      {orderId && isDraft && (
        <div style={{ display: "none" }}>
          <Box ref={componentRef} sx={{ padding: "10px" }}>
            <Box display={"flex"} flexDirection={"column"} gap={1}>
              <OrderReport
                isDraft={!isDraft}
                title={orderTypeFilters.orderType[orderTypeWatch]?.name}
                sellerTitle={
                  orderTypeFilters.orderType[orderTypeWatch]?.sellerTitle
                }
                buyerTitle={
                  orderTypeFilters.orderType[orderTypeWatch]?.buyerTitle
                }
                shippingTitle={
                  orderTypeFilters.orderType[orderTypeWatch]?.shippingTitle
                }
                orderInfo={
                  orderTypeFilters.orderType[orderTypeWatch]?.orderInfo
                }
                sellerInfo={
                  orderTypeFilters.orderType[orderTypeWatch]?.sellerInfo
                }
                buyerInfo={
                  orderTypeFilters.orderType[orderTypeWatch]?.buyerInfo
                }
                shipInfo={orderTypeFilters.orderType[orderTypeWatch]?.shipInfo}
                actions={orderTypeFilters.orderType[orderTypeWatch]?.actions}
                onAdd={
                  getTableFunctions(
                    orderTypeFilters.orderType[orderTypeWatch]?.name
                  ).onAdd
                }
                onRemove={
                  getTableFunctions(
                    orderTypeFilters.orderType[orderTypeWatch]?.name
                  ).onRemove
                }
                onEdit={
                  getTableFunctions(
                    orderTypeFilters.orderType[orderTypeWatch]?.name
                  ).onEdit
                }
                onSaveDraft={handleSubmit(onSaveDraft)}
                onSubmit={handleSubmit(onSubmit)}
                onModify={onModify}
              >
                {getTable(
                  orderTypeFilters.orderType[orderTypeWatch]?.name,
                  true
                )}
              </OrderReport>
            </Box>
          </Box>
        </div>
      )}
    </>
  );

  const tabs = [].concat(
    ...(!returnId && orderId && Boolean(SOForm?.orderHistories)
      ? SOForm.orderHistories.map((item, index) => ({
          label: `${SOForm.saleOrder.code}/${index + 1}`,
          Component: orderHistoriesAllReport(item)
        }))
      : []),
    ...(returnId && salesReturnData?.orderHistories
      ? salesReturnData.orderHistories.map((item, index) => ({
          label: `${salesReturnData.saleReturn.code}/${index + 1}`,
          Component: orderHistoriesAllReport(item)
        }))
      : []),
    {
      label: returnId
        ? Boolean(salesReturnData?.orderHistories?.length)
          ? `${salesReturnData?.saleReturn?.code}/${
              salesReturnData?.orderHistories?.length + 1
            }`
          : salesReturnData?.saleReturn?.code ?? "Draft"
        : Boolean(SOForm?.orderHistories?.length)
        ? `${SOForm?.saleOrder?.code}/${SOForm?.orderHistories?.length + 1}`
        : SOForm?.saleOrder?.code ?? "Draft",
      Component: OrderReportComp
    }
  );

  return isCreateOrderFormFetching ||
    isCustomerBillingFetching ||
    isSOFormFetching ||
    isSOListFetching ||
    isReturnFormDataFetching ||
    isSalesReturnDataFetching ||
    isCreating ||
    isUpdating ||
    isSavingDraft ||
    isUpdatingDraft ||
    isSubmittingDraft ||
    isCreatingSR ||
    isUpdatingSR ||
    isSavingSRDraft ||
    isUpdatingSRDraft ||
    isSubmittingSRDraft ? (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={
        isCreateOrderFormFetching ||
        isCustomerBillingFetching ||
        isSOFormFetching ||
        isSOListFetching ||
        isReturnFormDataFetching ||
        isSalesReturnDataFetching ||
        isCreating ||
        isUpdating ||
        isSavingDraft ||
        isUpdatingDraft ||
        isSubmittingDraft ||
        isCreatingSR ||
        isUpdatingSR ||
        isSavingSRDraft ||
        isUpdatingSRDraft ||
        isSubmittingSRDraft
      }
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : (
    <Box display={"flex"} flexDirection={"column"}>
      <Grid
        container
        spacing={2}
        sx={{
          display: { xs: "none", md: "block" }
        }}
      >
        <Grid item xs={12}>
          <Typography
            fontSize={20}
            fontWeight={500}
            lineHeight={"24.2px"}
            letterSpacing={"0.2%"}
          >
            {orderId ? "Manage Order" : "Create Order"}
          </Typography>
        </Grid>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12} md={6} lg={9} container spacing={3}>
            <Grid item xs={12} container spacing={3}>
              <CustomFieldRow label={"Order Type"}>
                <DropDown
                  control={control}
                  name={"orderType"}
                  options={[
                    {
                      name: "salesOrder",
                      displayName: "Sales Order"
                    },
                    {
                      name: "salesReturns",
                      displayName: "Sales Returns"
                    }
                  ]}
                  placeholder={"Select order type"}
                  disabled={Boolean(orderId)}
                />
              </CustomFieldRow>
              {orderId &&
                checkOrderType === "salesOrder" &&
                SOForm?.saleOrder?.status?.name !== "Placed" &&
                SOForm?.saleOrder?.status?.name !== "Draft" && (
                  <Box display={"flex"} gap={1} alignItems={"end"} pl={3}>
                    <Button
                      variant="link"
                      disableRipple
                      onClick={() =>
                        navigate(
                          `${appRoutes.sales.main}/${appRoutes.sales.salesOrders}/${appRoutes.sales.createReturn}/${orderId}`
                        )
                      }
                    >
                      {"Create Return Order"}
                    </Button>
                  </Box>
                )}
            </Grid>
            {orderTypeFilters.orderType[orderTypeWatch]?.fields?.map((item) =>
              generateFields(
                item,
                control,
                false,
                !isCreateOrderFormFetching && { ...CreateOrderForm }
              )
            )}
          </Grid>
        </Grid>
      </Grid>
      {orderId &&
        checkOrderType === "salesOrder" &&
        SOForm?.saleOrder?.status?.name !== "Placed" &&
        SOForm?.saleOrder?.status?.name !== "Draft" && (
          <Grid
            item
            xs={12}
            textAlign={"end"}
            sx={{
              display: { xs: "block", md: "none" },
              flexDirection: "column",
              p: { xs: "0 16px 0 0", md: 0 },
              mt: { xs: -4.5, md: 0 },
              mb: { xs: 2, md: 0 },
              ml: { xs: 5, md: 0 }
            }}
            zIndex={1}
          >
            <Button
              variant="link"
              disableRipple
              onClick={() =>
                navigate(
                  `${appRoutes.sales.main}/${appRoutes.sales.salesOrders}/${appRoutes.sales.createReturn}/${orderId}`
                )
              }
            >
              {"Create Return Order"}
            </Button>
          </Grid>
        )}
      <Grid
        container
        rowSpacing={2}
        sx={{
          display: { xs: "flex", md: "none" },
          alignItems: "center",
          padding: "10px 20px"
        }}
      >
        <Grid item xs={8}>
          <DropDown
            control={control}
            name={"orderType"}
            options={[
              {
                name: "salesOrder",
                displayName: "Sales Order"
              },
              {
                name: "salesReturns",
                displayName: "Sales Returns"
              }
            ]}
            placeholder={"Select order type"}
            disabled={Boolean(orderId)}
          />
        </Grid>
        <Grid item xs={4} display={"flex"} justifyContent={"center"}>
          <IconButton
            size="small"
            sx={{
              backgroundColor: "#F0F2F5"
            }}
            disabled={!Boolean(orderTypeWatch)}
            color={"primary"}
            onClick={() => {
              showDrawer({
                title: Boolean(orderId) ? "Manage Order" : "Create Order",
                height: "fit-content",
                sx: { color: "#2773FF", fontWeight: 600 },
                component: DrawerComponent
              });
            }}
          >
            <TuneOutlined color="inherit" fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
      {orderTypeWatch && !orderId && (
        <Grid item xs={12}>
          {OrderReportComp}
        </Grid>
      )}
      {orderTypeWatch && orderId && (
        <Grid item xs={12}>
          <CustomTabs
            tabs={tabs}
            activeTab={activeTab}
            onChange={handleTabChange}
            headerControls={
              !isMobileDevice &&
              ((inDraftState || !inCompletedState) && showModifyOnOrder ? (
                <Box display={"flex"} gap={4}>
                  {!updateOrderButton && isDraft && (
                    <>
                      <Button variant="link" disableRipple onClick={onModify}>
                        Modify
                      </Button>
                      {inPlacedState && (
                        <Button
                          variant="link"
                          disableRipple
                          sx={{ color: "error.main", minWidth: "100px" }}
                          onClick={onCancel}
                        >
                          Cancel Order
                        </Button>
                      )}
                      {inDraftState && (
                        <Button
                          variant="link"
                          disableRipple
                          sx={{ color: "error.main", minWidth: "100px" }}
                          onClick={() => onDeleteClick()}
                        >
                          Delete Draft
                        </Button>
                      )}
                      <Button
                        variant="link"
                        disableRipple
                        onClick={handlePrint}
                      >
                        Print
                      </Button>
                    </>
                  )}
                  {updateOrderButton && (
                    <>
                      <Button
                        variant="link"
                        disableRipple
                        onClick={handleSubmit(onUpdate)}
                        sx={{ minWidth: "100px" }}
                      >
                        Update Order
                      </Button>
                      <Button
                        variant="link"
                        disableRipple
                        onClick={onModify}
                        sx={{ minWidth: "100px", color: "green" }}
                      >
                        Reset
                      </Button>
                    </>
                  )}
                </Box>
              ) : (
                <>
                  <Button variant="link" disableRipple onClick={handlePrint}>
                    Print
                  </Button>
                </>
              ))
            }
          />
        </Grid>
      )}
    </Box>
  );
};

export default CreateSalesOrder;
