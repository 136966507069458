import { queryKeys } from "../../../constants/queryKeys";
import { getSOListFormData } from "../../../sales/services";
import { useQuery } from "@tanstack/react-query";

export const useFetchSOListFormData = (orderType) => {
  const querySOListFormData = useQuery(
    [queryKeys.queryFetchSOListFormData],
    () => getSOListFormData(),
    {
      enabled: Boolean(orderType === "salesReturns"),
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return querySOListFormData;
};
