import React, { useState, useEffect } from "react";
import { Card, CardContent, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from "recharts";
import {
  format,
  differenceInDays,
  subDays,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  addMonths,
  endOfMonth
} from "date-fns";
import DropDown from "../../shared/formElements/DropDown";
import { currencyOnlyFormatter } from "../../shared/utils";
import DateInput from "../../shared/formElements/DateInput";
import { CalendarTodayOutlined } from "@mui/icons-material";

// will use this below function once API is made
// const generateData = (data, interval = "day") => {
//   return data.map((item) => ({
//     date:
//       interval === "week"
//         ? `Week ${Math.ceil((new Date(item.date).getDate() + 1) / 7)}`
//         : format(new Date(item.date), "EEE"),
//     orders: item.orders,
//     dispatches: item.dispatches,
//     received: item.received,
//     collections: item.collections,
//     payments: item.payments,
//     dues: item.dues
//   }));
// };

const getDateInterval = (startDate, endDate) => {
  const diffInDays = differenceInDays(endDate, startDate);

  if (diffInDays <= 7) {
    return "day";
  } else if (diffInDays <= 31) {
    return "week";
  } else {
    return "month";
  }
};

const generateData = (startDate, endDate, interval = "day") => {
  const data = [];
  let currentDate = startDate;
  while (currentDate <= endDate) {
    data.push({
      date:
        interval === "week"
          ? `Week ${Math.ceil((currentDate.getDate() + 1) / 7)}`
          : interval === "month"
          ? format(currentDate, "MMM yyyy")
          : format(currentDate, "EEE"),
      Orders: 0,
      Dispatches: 0,
      Returns: 0,
      Received: 0,
      Collections: 0,
      Payments: 0,
      Overdues: 0,
      Dues: 0
    });
    currentDate =
      interval === "day"
        ? subDays(currentDate, -1)
        : interval === "week"
        ? subDays(currentDate, -7)
        : interval === "month"
        ? (currentDate = startOfMonth(addMonths(currentDate, 1)))
        : null;
  }
  return data;
};

const BarChartCard = () => {
  const { control, watch, setValue } = useForm({
    defaultValues: { orderType: "sales", timePeriod: "thisWeek" }
  });
  const watchTimePeriod = watch("timePeriod");
  const watchOrderType = watch("orderType");
  const watchCustomDateRange = watch("customDateRange");
  const [chartData, setChartData] = useState([]);
  const [customDateRange, setCustomDateRange] = useState([]);

  useEffect(() => {
    if (watchTimePeriod !== "custom") {
      setCustomDateRange([]);
      setValue("customDateRange", null);
    }
  }, [watchTimePeriod, setValue]);

  useEffect(
    () => {
      if (watchCustomDateRange) {
        if (watchCustomDateRange[1] !== null) {
          const interval = getDateInterval(
            watchCustomDateRange[0],
            watchCustomDateRange[1]
          );
          setCustomDateRange([...watchCustomDateRange, interval]);
        }
      }
    },
    // eslint-disable-next-line
    [JSON.stringify(watchCustomDateRange)]
  );

  useEffect(() => {
    const today = new Date();
    let startDate, endDate;

    switch (watchTimePeriod) {
      case "thisWeek":
        startDate = startOfWeek(today, { weekStartsOn: 1 }); // Start week from Monday
        endDate = today;
        setChartData(generateData(startDate, endDate));
        break;
      case "lastWeek":
        startDate = startOfWeek(subDays(today, 7), { weekStartsOn: 1 });
        endDate = endOfWeek(subDays(today, 7), { weekStartsOn: 1 });
        setChartData(generateData(startDate, endDate));
        break;
      case "thisMonth":
        startDate = startOfMonth(today);
        endDate = today;
        setChartData(generateData(startDate, endDate, "week"));
        break;
      case "lastMonth":
        startDate = startOfMonth(subDays(today, 30));
        endDate = endOfMonth(subDays(today, 30));
        setChartData(generateData(startDate, endDate, "week"));
        break;
      case "custom":
        if (customDateRange.length === 3) {
          const [start, end, interval] = customDateRange;
          setChartData(generateData(start, end, interval));
        }
        break;
      default:
        break;
    }
  }, [watchTimePeriod, customDateRange]);

  const getBarDataKeys = (orderType) => {
    switch (orderType) {
      case "sales":
        return ["Orders", "Dispatches", "Returns"];
      case "purchase":
        return ["Orders", "Received", "Returns"];
      case "receivables":
        return ["Dues", "Overdues", "Collections"];
      case "payables":
        return ["Dues", "Overdues", "Payments"];
      default:
        return [""];
    }
  };

  const [bar1DataKey, bar2DataKey, bar3DataKey] =
    getBarDataKeys(watchOrderType);

  return (
    <Card
      sx={{
        boxShadow: "0px 4px 4px 0px #00000040",
        border: "1px solid #B7BEC7",
        width: "100%",
        maxWidth: "800px",
        borderRadius: "8px"
      }}
    >
      <CardContent>
        <Grid
          container
          mb={2}
          sx={{ padding: { xs: "8px 15px 0 15px", md: "10px 10px 0 20px" } }}
        >
          <Grid item xs={7} sm={7} md={6}>
            <DropDown
              control={control}
              name={"orderType"}
              placeholder={"Select Filter"}
              options={[
                { name: "sales", displayName: "Sales" },
                { name: "purchase", displayName: "Purchase" },
                { name: "receivables", displayName: "Receivables" },
                { name: "payables", displayName: "Payables" }
              ]}
            />
          </Grid>
          <Grid item xs={0.5} sm={0.5} md={2}></Grid>
          <Grid item xs={4.5} sm={4.5} md={4}>
            <DropDown
              control={control}
              name={"timePeriod"}
              placeholder={"Select Time-period"}
              options={[
                { name: "thisWeek", displayName: "This Week" },
                { name: "lastWeek", displayName: "Last Week" },
                { name: "thisMonth", displayName: "This Month" },
                { name: "lastMonth", displayName: "Last Month" },
                { name: "custom", displayName: "Custom" }
              ]}
            />
          </Grid>
        </Grid>
        {watchTimePeriod === "custom" && (
          <Grid
            container
            mb={2}
            sx={{ padding: { xs: "8px 15px", md: "10px 20px" } }}
          >
            <Grid item xs={12} md={6}>
              <DateInput
                name="customDateRange"
                control={control}
                selectsRange={true}
                minDate={new Date("2000-01-01")}
                endIcon={CalendarTodayOutlined}
                placeholder="Select date range"
                format="dd-MM-yyyy"
                popperPlacement="bottom-start"
              />
            </Grid>
          </Grid>
        )}
        <ResponsiveContainer width="100%" minHeight={320}>
          <BarChart
            data={chartData}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            barGap={0}
          >
            <CartesianGrid
              stroke="#F3F3F4"
              horizontal={true}
              vertical={false}
            />
            <XAxis
              dataKey="date"
              tick={{ fill: "#607088", fontSize: "10px" }}
              axisLine={false}
              tickLine={false}
            />
            <YAxis
              tick={{ fill: "#607088", fontSize: "10px" }}
              axisLine={false}
              tickLine={false}
            />
            <Tooltip
              formatter={(value) =>
                currencyOnlyFormatter(parseFloat(value).toFixed(0))
              }
              labelFormatter={(label, payload) => {
                const fullDate = payload[0]?.payload?.fullDate;
                return fullDate
                  ? format(new Date(fullDate), "EEEE, MMMM d, yyyy")
                  : label;
              }}
            />
            <Bar dataKey={bar1DataKey} fill="#2773FF" />
            <Bar dataKey={bar2DataKey} fill="#F9A90E" />
            <Bar dataKey={bar3DataKey} fill="#FF8A8A" />
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default BarChartCard;
