import { useCallback, useState } from "react";
import appRoutes from "../../constants/appRoutes";
import { useLocation } from "react-router-dom";

export const useMobileTabState = () => {
  const [activeMobileTab, setActiveMobileTab] = useState({
    Inventory: 0,
    Warehouse: 0,
    Purchase: 0,
    Sales: 0,
    Finance: 0,
    Settings: 0
  });

  const location = useLocation();

  const getServiceType = (location) => {
    const routes = {
      [appRoutes.inventory.main]: "Inventory",
      [appRoutes.warehouse.main]: "Warehouse",
      [appRoutes.purchase.main]: "Purchase",
      [appRoutes.sales.main]: "Sales",
      [appRoutes.finance.main]: "Finance",
      [appRoutes.settings]: "Settings"
    };
    return routes[location];
  };

  const handleMobileTabChange = useCallback(
    (index) => {
      const service = getServiceType(location.pathname);
      setActiveMobileTab((prevIndexes) => ({
        ...prevIndexes,
        [service]: index
      }));
    },
    [location.pathname]
  );

  return { activeMobileTab, handleMobileTabChange };
};
