import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { salesOrder_labels } from "../../constants";

import CustomTable from "../../shared/customTable";
import { CustomTypo, SubtotalTypo } from "../../purchase/pages/Vendors";
import { StyledChip } from "../../shared/UiElements/StatusChip";
import appRoutes from "../../constants/appRoutes";
import { SalesOrders_filterKeys } from "../../constants/formKeys";
import {
  currencyFormatter,
  currencyOnlyFormatter,
  dropdownOptions,
  stringCapitalization
} from "../../shared/utils";
import { useForm } from "react-hook-form";
import Filter from "../../purchase/components/Filter";
import { AddOutlined, Search, TuneOutlined } from "@mui/icons-material";
import Ledger from "../../assets/Ledger.webp";
import { AntSwitch, CustomTooltip } from "../../shared/theme/globalTheme";
import { useDrawer } from "../../shared/customSideDrawer";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { getCustomCell } from "../../purchase/pages/PurchaseOrders";
import { useInView } from "react-intersection-observer";
import { useFetchAllFinanceOrders } from "../../query-hooks/ClientOrderInvoice/useFetchAllFinanceOrders";
import { getDocumentsByType } from "../../purchase/pages/StockTransferOrders";
import moment from "moment";
import SOTransactions from "../../sales/pages/SOTransactions";
import POTransactions from "../../purchase/pages/POTransactions";
import { getRouteByOrderType } from "../../warehouse/pages/Register";
import { useFetchInvoiceFilters } from "../../query-hooks/ClientOrderInvoice/useFetchInvoiceFilters";
import { enqueueSnackbar } from "notistack";
import { useFetchInvoicesSubtotal } from "../../query-hooks/ClientOrderInvoice/useFetchInvoicesSubtotal";
import { AppContext } from "../../shared/context/auth-context";
import DropDown from "../../shared/formElements/DropDown";
import { MobilePurchaseOrders } from "../../purchase/components/Purchase/MobilePurchaseOrders";
import { MobileSalesOrders } from "../../sales/components/Sales/MobileSalesOrders";

const getColumns = (action) => {
  return [
    {
      Header: "Doc. No.",
      accessor: "docNo",
      Cell: ({ row, value }) => {
        return getCustomCell(
          value,
          row.original.orderDate,
          getRouteByOrderType(row.original.orderType, row.original.orderId)
        );
      },
      show: false
    },
    {
      Header: "Amount",
      accessor: "amount"
    },
    {
      Header: "Status",
      accessor: "orderProgress",
      width: 200,
      Cell: ({ row, value }) => {
        return <StyledChip label={stringCapitalization(value)} />;
      }
    },
    {
      Header: "MRR/ GP",
      accessor: "mrr",
      Cell: ({ row, value }) => {
        return value && getCustomCell(value[0], value[1], value[2], value[3]);
      }
    },
    {
      Header: "INV/ Debit Note",
      accessor: "inv",
      width: 200,
      Cell: ({ row, value }) => {
        return value && getCustomCell(value[0], value[1], ``, value[3]);
      }
    },
    {
      Header: "Invoice Value",
      accessor: "invoiceValue"
    },
    {
      Header: "Amount Received/Paid",
      accessor: "amountPaid"
    },
    {
      Header: "Balance",
      accessor: "balance"
    },
    {
      Header: "Avg. Due",
      accessor: "avgDue",
      Cell: ({ row, value }) => {
        return (
          row.original.orderProgress !== "Placed" && (
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
            >
              <Typography fontSize={14}>{value}</Typography>
              <CustomTooltip title={"Ledger"} placement="top">
                <IconButton onClick={() => action(row.original)}>
                  <img
                    src={Ledger}
                    className="pull-right cursor-field"
                    alt={"value"}
                  />
                </IconButton>
              </CustomTooltip>
            </Box>
          )
        );
      }
    }
  ];
};

const getRows = (rows = []) => {
  return rows.map((row, index) => ({
    orderId: row?._id,
    docNo: row?.code,
    orderDate: moment(row?.orderDate).format("DD-MM-YYYY"),
    amount: currencyOnlyFormatter(parseFloat(row?.totalAmount).toFixed(2)),
    orderProgress: row?.status?.name,
    orderType: row?.orderType?.name,
    orderTableType: "Invoice",
    mrr:
      row?.documents?.length > 0
        ? row.orderType.name === "PurchaseOrder"
          ? getDocumentsByType(
              row?.documents?.filter((obj) => obj?.docType?.name === "Mrr") ??
                [],
              "Invoice"
            )
          : getDocumentsByType(
              row?.documents?.filter(
                (obj) => obj?.docType?.name === "GatePass"
              ) ?? [],
              "Invoice"
            )
        : "",
    inv:
      row?.documents?.length > 0
        ? getDocumentsByType(
            row?.documents?.filter(
              (obj) => obj?.docType?.displayName === "Invoice"
            ) ?? []
          )
        : "",
    invoiceValue: row?.totalInvoiceAmount
      ? currencyOnlyFormatter(parseFloat(row?.totalInvoiceAmount).toFixed(2))
      : "",
    amountPaid: row?.totalPaidAmount
      ? currencyOnlyFormatter(parseFloat(row?.totalPaidAmount).toFixed(2))
      : "",
    balance:
      row?.totalInvoiceAmount && row?.totalPaidAmount
        ? currencyOnlyFormatter(
            parseFloat(
              +row?.totalInvoiceAmount - +row?.totalPaidAmount
            ).toFixed(2)
          )
        : "",
    ...{
      ...(row?.returnOrders?.length > 0
        ? {
            subRow: row.returnOrders.map((returnOrder, index) => {
              const getDoc =
                returnOrder?.documents?.length > 0
                  ? row.orderType.name === "PurchaseOrder"
                    ? getDocumentsByType(
                        returnOrder?.documents?.filter(
                          (obj) => obj?.docType?.name === "GatePass"
                        ) ?? [],
                        "Invoice"
                      )
                    : getDocumentsByType(
                        returnOrder?.documents?.filter(
                          (obj) => obj?.docType?.name === "Mrr"
                        ) ?? [],
                        "Invoice"
                      )
                  : "";
              const getNoteDoc =
                returnOrder?.documents?.length > 0
                  ? row.orderType.name === "PurchaseOrder"
                    ? getDocumentsByType(
                        returnOrder?.documents?.filter(
                          (obj) => obj?.docType?.name === "DebitNote"
                        ) ?? []
                      )
                    : getDocumentsByType(
                        returnOrder?.documents?.filter(
                          (obj) => obj?.docType?.name === "CreditNote"
                        ) ?? []
                      )
                  : "";
              return {
                returnId: returnOrder?._id,
                returnCode: returnOrder?.code,
                returnOrderDate: moment(returnOrder?.orderDate).format(
                  "DD-MM-YYYY"
                ),
                returnOrderStatus: returnOrder?.status?.name,
                docNo: getCustomCell(
                  returnOrder?.code ?? "Draft",
                  moment(returnOrder?.orderDate).format("DD-MM-YYYY"),
                  getRouteByOrderType(
                    row.orderType.name === "PurchaseOrder"
                      ? "PurchaseReturn"
                      : "SaleReturn",
                    returnOrder._id,
                    row._id
                  )
                ),
                amount: currencyOnlyFormatter(
                  parseFloat(returnOrder?.totalAmount).toFixed(2)
                ),
                orderProgress: (
                  <StyledChip
                    label={stringCapitalization(returnOrder?.status?.name)}
                  />
                ),
                mrr: getDoc
                  ? getCustomCell(getDoc[0], getDoc[1], getDoc[2], getDoc[3])
                  : "",
                inv: getNoteDoc
                  ? getCustomCell(
                      getNoteDoc[0],
                      getNoteDoc[1],
                      getNoteDoc[2],
                      getNoteDoc[3]
                    )
                  : "",
                invoiceValue: returnOrder?.totalNoteAmount ? (
                  <Typography fontSize={14} color={"#F05454"}>
                    {`(${currencyOnlyFormatter(
                      parseFloat(returnOrder?.totalNoteAmount).toFixed(2)
                    )})`}
                  </Typography>
                ) : (
                  ""
                ),
                amountPaid: "",
                balance: "",
                avgDue: ""
              };
            })
          }
        : {})
    }
  }));
};

const Invoices = () => {
  const { isSwitchChecked, handleSwitchToggle } = useContext(AppContext);
  const [params, setParams] = useState();

  const { control, reset, handleSubmit } = useForm();
  const navigate = useNavigate();
  const { showDrawer, hideDrawer } = useDrawer();

  const onButtonClick = useCallback(
    (row) => {
      showDrawer({
        component:
          row?.orderType === "SaleOrder" ? (
            <SOTransactions hideDrawer={hideDrawer} orderId={row?.orderId} />
          ) : (
            <POTransactions hideDrawer={hideDrawer} orderId={row?.orderId} />
          ),
        closeIcon: false
      });
    },
    [hideDrawer, showDrawer]
  );

  const { data: filterData, isFetching: isFilterFetching } =
    useFetchInvoiceFilters(isSwitchChecked ? "PurchaseOrder" : "SaleOrder");

  const { data: subTotalAmount, isFetching: isFetchingSubtotals } =
    useFetchInvoicesSubtotal(
      params,
      isSwitchChecked ? "PurchaseOrder" : "SaleOrder"
    );

  const { ref, inView } = useInView();
  const {
    data,
    isFetching,
    isFetched,
    hasNextPage,
    fetchNextPage,
    refetch,
    isError,
    error
  } = useFetchAllFinanceOrders(
    params,
    isSwitchChecked ? "PurchaseOrder" : "SaleOrder"
  );

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, inView]);

  const financeTable = useMemo(
    () => (
      <CustomTable
        columns={getColumns(onButtonClick)}
        data={
          isFetched
            ? data?.pages?.flatMap((page) => getRows(page?.orders)) ?? []
            : []
        }
        isLoading={!isFetched}
        mobileComponent={
          isSwitchChecked ? MobilePurchaseOrders : MobileSalesOrders
        }
      />
    ),
    [data?.pages, isFetched, onButtonClick, isSwitchChecked]
  );

  const onViewClick = (data) => {
    const filterData = {
      ...{
        ...(isSwitchChecked
          ? {
              ...{
                ...(data["vendor"] !== ""
                  ? {
                      [`filters[vendor]`]: data["vendor"]
                    }
                  : {})
              }
            }
          : {
              ...{
                ...(data["customer"] !== ""
                  ? {
                      [`filters[customer]`]: data["customer"]
                    }
                  : {})
              },
              ...{
                ...(data["salesman"] !== ""
                  ? {
                      [`filters[salesPerson]`]: data["salesman"]
                    }
                  : {})
              }
            })
      },
      ...{
        ...(data["status"] !== ""
          ? {
              [`filters[status]`]: data["status"]
            }
          : {})
      },
      ...{
        ...(data?.date[0] !== null
          ? {
              [`filters[fromDate]`]: moment(data?.date[0]).format("YYYY-MM-DD")
            }
          : {})
      },
      ...{
        ...(data?.date[1] !== null
          ? {
              [`filters[toDate]`]: moment(data?.date[1]).format("YYYY-MM-DD")
            }
          : {})
      }
    };
    setParams(filterData);
    hideDrawer();
  };

  const onClear = () => {
    reset({
      date: "",
      vendor: "",
      customer: "",
      salesman: "",
      status: ""
    });
    setParams(null);
  };

  const FilterComponent = (
    <Box>
      <Filter
        control={control}
        onApply={handleSubmit(onViewClick)}
        onClear={onClear}
        isFetching={isFilterFetching}
        isLoading={isFetching}
        filters={[
          {
            type: "DateInput",
            name: SalesOrders_filterKeys.date,
            label: salesOrder_labels.date,
            placeholder: "Pick Dates",
            selectsRange: true
          },
          ...[
            ...(isSwitchChecked
              ? [
                  {
                    type: "DropDown",
                    name: SalesOrders_filterKeys.vendor,
                    label: salesOrder_labels.vendor,
                    unSelectText: "Select Vendor",
                    allowSearch: true,
                    options: dropdownOptions(
                      filterData?.vendors,
                      "_id",
                      "companyName"
                    ),
                    filterCount: filterData?.vendors?.length
                  }
                ]
              : [
                  {
                    type: "DropDown",
                    name: SalesOrders_filterKeys.customer,
                    label: salesOrder_labels.customer,
                    unSelectText: "Select Customer",
                    allowSearch: true,
                    options: dropdownOptions(
                      filterData?.customers,
                      "_id",
                      "companyName"
                    ),
                    filterCount: filterData?.customers?.length
                  },
                  {
                    type: "DropDown",
                    name: SalesOrders_filterKeys.salesman,
                    label: salesOrder_labels.salesman,
                    unSelectText: "Select Salesman",
                    options: dropdownOptions(
                      filterData?.salesPersons,
                      "_id",
                      "name"
                    ),
                    filterCount: filterData?.salesPersons?.length
                  }
                ])
          ],
          {
            type: "DropDown",
            name: SalesOrders_filterKeys.status,
            unSelectText: "Select Status",
            label: salesOrder_labels.status,
            options: filterData?.statuses,
            filterCount: filterData?.statuses?.length
          }
        ]}
      />
    </Box>
  );

  useEffect(() => {
    reset();
    setParams(null);
  }, [isSwitchChecked, reset]);

  useEffect(() => {
    if (params) {
      refetch();
    }
  }, [params, refetch]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(error?.response?.data?.message, {
        variant: "error"
      });
    }
  }, [error?.response?.data?.message, isError]);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      sx={{
        mb: { xs: 9, md: 0 }
      }}
    >
      <Box
        flexDirection={"column"}
        gap={2}
        sx={{
          display: { xs: "none", md: "flex" },
          position: "sticky",
          top: "64px",
          zIndex: 1000,
          background: "#fff",
          padding: "16px 0"
        }}
      >
        <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
          <Box
            display={"flex"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            gap={1}
          >
            <Typography
              sx={{
                color: isSwitchChecked ? "#C4C4C4" : "#2773FF",
                fontSize: "20px",
                fontWeight: 700,
                lineHeight: "20px"
              }}
            >
              {"AR"}
            </Typography>
            <AntSwitch
              checked={isSwitchChecked}
              onChange={handleSwitchToggle}
              inputProps={{ "aria-label": "toggle switch" }}
            />
            <Typography
              sx={{
                color: isSwitchChecked ? "#F9A90E" : "#D9D9D9",
                fontSize: "20px",
                fontWeight: 700,
                lineHeight: "20px"
              }}
            >
              {"AP"}
            </Typography>
          </Box>
          <Button
            variant="link"
            disableRipple
            disabled
            onClick={() =>
              navigate(
                `${appRoutes.finance.main}/${appRoutes.finance.invoices}/${appRoutes.finance.createInvoice}`
              )
            }
          >
            <AddOutlined />
            {`create Invoice`}
          </Button>
        </Grid>
        <Grid item xs={12}>
          {FilterComponent}
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              border: "0.6px solid #2773FF",
              borderRadius: 2,
              padding: 3,
              display: "flex",
              gap: 3,
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            {isFetchingSubtotals ? (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                width={"100%"}
              >
                <CircularProgress color="primary" />
              </Box>
            ) : (
              <>
                <Box>
                  <CustomTooltip title={"Ledger"} placement="top">
                    <IconButton>
                      <img
                        src={Ledger}
                        className="pull-right cursor-field"
                        alt={"value"}
                      />
                    </IconButton>
                  </CustomTooltip>
                </Box>
                <CustomTypo color="primary">Subtotal :</CustomTypo>
                <Box display={"flex"} gap={3} alignItems={"center"}>
                  {SubtotalTypo(
                    subTotalAmount?.subTotals?.totalInvoiceAmount
                      ? currencyFormatter(
                          parseFloat(
                            subTotalAmount?.subTotals?.totalInvoiceAmount
                          ).toFixed(2)
                        )
                      : "--",
                    "Invoice Value"
                  )}
                  {SubtotalTypo(
                    subTotalAmount?.subTotals?.totalPaidAmount
                      ? currencyFormatter(
                          parseFloat(
                            subTotalAmount?.subTotals?.totalPaidAmount
                          ).toFixed(2)
                        )
                      : "--",
                    isSwitchChecked ? "Amt Paid" : "Amt Received"
                  )}
                  {SubtotalTypo(
                    !Boolean(subTotalAmount?.subTotals?.totalPaidAmount)
                      ? subTotalAmount?.subTotals?.totalInvoiceAmount
                        ? currencyOnlyFormatter(
                            parseFloat(
                              +subTotalAmount?.subTotals?.totalInvoiceAmount
                            ).toFixed(2)
                          )
                        : "--"
                      : currencyOnlyFormatter(
                          parseFloat(
                            +subTotalAmount?.subTotals?.totalInvoiceAmount -
                              +subTotalAmount?.subTotals?.totalPaidAmount
                          ).toFixed(2)
                        ),
                    "Balance"
                  )}
                </Box>
              </>
            )}
          </Box>
        </Grid>
      </Box>
      <Grid
        container
        rowSpacing={2}
        sx={{
          display: { xs: "flex", md: "none" },
          alignItems: "center",
          position: "sticky",
          top: "60px",
          zIndex: 1,
          background: "#fff",
          padding: "10px 20px"
        }}
      >
        <Grid
          item
          xs={12}
          display={"flex"}
          justifyContent={"right"}
          alignItems={"center"}
        >
          <Button
            variant="link"
            disableRipple
            disabled
            onClick={() =>
              navigate(
                `${appRoutes.finance.main}/${appRoutes.finance.invoices}/${appRoutes.finance.createInvoice}`
              )
            }
          >
            {`+ Invoice`}
          </Button>
        </Grid>
        <Grid item xs={10}>
          <DropDown
            control={control}
            name={"orderId"}
            placeholder={"Search Order"}
            startIcon={Search}
            options={[]}
            allowSearch
            availUnselect
            unSelectText="Select Order"
            sx={{ borderRadius: 54 }}
          />
        </Grid>
        <Grid item xs={2} display={"flex"} justifyContent={"right"}>
          <IconButton
            size="small"
            sx={{
              backgroundColor: "#F0F2F5"
            }}
            onClick={() => {
              showDrawer({
                title: "Filters",
                height: "fit-content",
                component: FilterComponent
              });
            }}
          >
            <TuneOutlined color="primary" fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
      <Box sx={{ padding: { xs: "0 15px", md: 0 } }}>{financeTable}</Box>
      {hasNextPage && (
        <Grid
          item
          xs={12}
          ref={ref}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress />
        </Grid>
      )}
    </Box>
  );
};

export default Invoices;
