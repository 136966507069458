import React, { useEffect, useState, useRef } from "react";
import { GoogleMap, MarkerF } from "@react-google-maps/api";
import { isMobile, isTablet } from "react-device-detect";
import { loadGoogleMapsScript } from "../utils/loadGoogleMapsScript";

const containerStyle = {
  width: "auto",
  height: isMobile || isTablet ? "calc(40vh)" :"calc(100vh - 184px)",
  borderRadius: isMobile || isTablet ? 0 : "8px 8px 0 0",
  border: "0.4px solid #E6EAEE",
  borderBottom: 0
};

const GoogleMapContainer = ({ data }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const mapRef = useRef(null); // Reference to the map

  useEffect(() => {
    loadGoogleMapsScript(() => {
      setIsLoaded(true);
    });
  }, []);

  const onLoad = (map) => {
    if (data && data.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();

      data.forEach((page) => {
        page?.warehouses?.forEach((item) => {
          const location = new window.google.maps.LatLng(
            item.address.location.coordinates[0],
            item.address.location.coordinates[1]
          );
          bounds.extend(location);
        });
      });

      map.fitBounds(bounds); // Adjust the map bounds based on all markers
    }
    mapRef.current = map;
  };

  if (!isLoaded) return <>{"Loading Maps"}</>;

  return (
    <GoogleMap
      onLoad={onLoad} // Set the map when it's loaded
      mapContainerStyle={containerStyle}
      options={{
        mapTypeControl: false,
        fullscreenControl: false
      }}
    >
      {data?.map((page) =>
        page?.warehouses?.map((item, index) => (
          <MarkerF
            title={item.code}
            key={index}
            position={{
              lat: item.address.location.coordinates[0],
              lng: item.address.location.coordinates[1]
            }}
          />
        ))
      )}
    </GoogleMap>
  );
};

export default GoogleMapContainer;
