import { Backdrop, CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import OrderBillingComponent from "../components/OrderBillingComponent";
import { useFetchSalesReturnOrderById } from "../../query-hooks/ClientSales/SalesReturn/useFetchSalesReturnOrderById";
import { useFetchSalesOrderById } from "../../query-hooks/ClientSales/SalesOrder/useFetchSalesOrderById";
import { useEffect, useState } from "react";
import { useFetchPurchaseOrderById } from "../../query-hooks/ClientPurchase/PurchaseOrder/useFetchPurchaseOrderById";
import { useFetchPurchaseReturnOrderById } from "../../query-hooks/ClientPurchase/PurchaseReturn/useFetchPurchaseReturnOrderById";
import { useFetchStockTransferById } from "../../query-hooks/ClientPurchase/StockTransfer/useFetchStockTransferById";

const OrderBillingPage = () => {
  const { orderType, orderId, returnType, returnId } = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const [orderHistories, setOrderHistories] = useState([]);
  const [orderInfo, setOrderInfo] = useState(null);

  const { data: POData, isFetching: isFetchingPO } = useFetchPurchaseOrderById(
    orderId,
    returnId,
    Boolean(orderType === "Purchase-Order")
  );

  const { data: PRData, isFetching: isFetchingPR } =
    useFetchPurchaseReturnOrderById(
      orderId,
      returnId,
      Boolean(returnType === "Purchase-Return")
    );

  useEffect(() => {
    if (!Boolean(returnId) && orderType === "Purchase-Order" && !isFetchingPO) {
      setOrderInfo({ ...POData?.purchaseOrder });
      if (Boolean(POData?.orderHistories?.length)) {
        setOrderHistories(POData?.orderHistories);
        setActiveTab(POData?.orderHistories?.length);
      }
    }
    // eslint-disable-next-line
  }, [isFetchingPO, orderType, returnId]);

  useEffect(() => {
    if (
      Boolean(returnId) &&
      returnType === "Purchase-Return" &&
      !isFetchingPR
    ) {
      setOrderInfo({ ...PRData?.purchaseReturn });
      if (Boolean(PRData?.orderHistories?.length)) {
        setOrderHistories(PRData?.orderHistories);
        setActiveTab(PRData?.orderHistories?.length);
      }
    }
    // eslint-disable-next-line
  }, [isFetchingPR, orderType, returnId]);

  const { data: SOData, isFetching: isFetchingSO } = useFetchSalesOrderById(
    orderId,
    returnId,
    Boolean(orderType === "Sale-Order")
  );

  const { data: SRData, isFetching: isFetchingSR } =
    useFetchSalesReturnOrderById(
      orderId,
      returnId,
      Boolean(returnType === "Sale-Return")
    );

  useEffect(() => {
    if (!Boolean(returnId) && orderType === "Sale-Order" && !isFetchingSO) {
      setOrderInfo({ ...SOData?.saleOrder });
      if (Boolean(SOData?.orderHistories?.length)) {
        setOrderHistories(SOData?.orderHistories);
        setActiveTab(SOData?.orderHistories?.length);
      }
    }
    // eslint-disable-next-line
  }, [isFetchingSO, orderType, returnId]);

  useEffect(() => {
    if (Boolean(returnId) && returnType === "Sale-Return" && !isFetchingSR) {
      setOrderInfo({ ...SRData?.saleReturn });
      if (Boolean(SRData?.orderHistories?.length)) {
        setOrderHistories(SRData?.orderHistories);
        setActiveTab(SRData?.orderHistories?.length);
      }
    }
    // eslint-disable-next-line
  }, [isFetchingSR, orderType, returnId]);

  const { data: STData, isFetching: isFetchingST } = useFetchStockTransferById(
    orderType === "Stock-Transfer" ? "stockTransfer" : "",
    orderId
  );

  useEffect(() => {
    if (!Boolean(returnId) && orderType === "Stock-Transfer" && !isFetchingST) {
      setOrderInfo({ ...STData?.stockTransfer });
    }
    // eslint-disable-next-line
  }, [isFetchingST, orderType, returnId]);

  return isFetchingPO ||
    isFetchingPR ||
    isFetchingSO ||
    isFetchingSR ||
    isFetchingST ? (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={
        isFetchingPO ||
        isFetchingPR ||
        isFetchingSO ||
        isFetchingSR ||
        isFetchingST
      }
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : (
    <OrderBillingComponent
      orderType={Boolean(returnId) ? returnType : orderType}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      tableData={[orderInfo]}
      orderHistories={orderHistories}
      orderCode={orderInfo?.code}
    />
  );
};

export default OrderBillingPage;
