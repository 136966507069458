import React, { useContext } from "react";
import { AppContext } from "../../shared/context/auth-context";
import WebSettings from "./WebSettings";
import MobileSettings from "./MobileSettings";
import { isMobileDevice } from "../../App";

const Settings = () => {
  const { profileData } = useContext(AppContext);
  
  const { _id, subscriptionDetails } = profileData?.user?.client;
  return (
    <>
      {!isMobileDevice ? (
        <WebSettings
          clientId={_id}
          userCount={subscriptionDetails?.userCount}
          allowCreateFormData={Boolean(
            subscriptionDetails?.userCount > subscriptionDetails?.count
          )}
        />
      ) : (
        <MobileSettings
          clientId={_id}
          userCount={subscriptionDetails?.userCount}
          allowCreateFormData={Boolean(
            subscriptionDetails?.userCount > subscriptionDetails?.count
          )}
        />
      )}
    </>
  );
};

export default Settings;
