import { Divider, Grid, Typography } from "@mui/material";
import { getCustomCell } from "../../../purchase/pages/PurchaseOrders";
import { ValueLabelText } from "../../../purchase/components/Purchase/MobilePOTable";

export const MobilePaymentsTable = (row) => {
  return (
    <Grid container spacing={"20px"}>
      <Grid item xs={12} container rowSpacing={2}>
        <Grid item xs={4} display={"flex"} justifyContent={"left"}>
          {getCustomCell(row?.invoiceNo, "", "")}
        </Grid>
        <Grid item xs={4} display={"flex"} justifyContent={"center"}>
          <Typography fontSize={"12px"} lineHeight={"16px"}>
            {row.name}
          </Typography>
        </Grid>
        <Grid item xs={4} display={"flex"} justifyContent={"right"}>
          <Typography
            sx={{ fontSize: 14, lineHeight: "20px", fontWeight: 400 }}
          >
            {row.invoiceDate}
          </Typography>
        </Grid>
        <Grid item xs={4} display={"flex"} justifyContent={"left"}>
          <ValueLabelText label={"Inv Amt"} value={row.invAmount} />
        </Grid>
        <Grid item xs={4} display={"flex"} justifyContent={"center"}>
          <ValueLabelText
            label={row.invType}
            value={
              row.invType === "Received"
                ? row.amountReceived || "--"
                : row.amountPaid || "--"
            }
          />
        </Grid>
        <Grid item xs={4} display={"flex"} justifyContent={"right"}>
          <ValueLabelText label={"Due Date"} value={row.dueDate} />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={4} display={"flex"} justifyContent={"left"}>
          <ValueLabelText
            label={"Due"}
            value={!row.isOverDue ? row.due : "--"}
          />
        </Grid>
        <Grid item xs={4} display={"flex"} justifyContent={"center"}>
          <ValueLabelText
            label={"Over Due"}
            value={row.isOverDue ? row.due : "--"}
          />
        </Grid>
        <Grid item xs={4} display={"flex"} justifyContent={"right"}>
          {row?.record}
        </Grid>
      </Grid>
    </Grid>
  );
};
