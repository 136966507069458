import * as yup from "yup";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";

import { CustomTabs } from "../../shared/customTab/CustomTabs";
import DropDown from "../../shared/formElements/DropDown";
import OrderReport from "../components/OrderReport";
import StockAdjustmentTable from "../components/Purchase/StockAdjustmentTable";
import StockTransferOrderTable from "../components/Purchase/StockTransferOrderTable";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { error_msg, statusKeys } from "../../constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { enqueueSnackbar } from "notistack";
import appRoutes from "../../constants/appRoutes";
import { useCustomDialog } from "../../shared/customDialog";
import SuccessfulDialog from "../../shared/UiElements/SuccessfulDialog";
import {
  CustomFieldRow,
  generateFields,
  generateMobileFields
} from "./CreateOrder";
import { useFetchStockTransferFormData } from "../../query-hooks/ClientPurchase/StockTransfer/useFetchStockTransferFormData";
import { dropdownOptions } from "../../shared/utils";
import { useFetchStockTransferById } from "../../query-hooks/ClientPurchase/StockTransfer/useFetchStockTransferById";
import { useCreateStockTransfer } from "../../query-hooks/ClientPurchase/StockTransfer/useCreateStockTransfer";
import { useSaveDraftStockTransfer } from "../../query-hooks/ClientPurchase/StockTransfer/useSaveStockTransferDraft";
import { useSubmitStockTransferDraft } from "../../query-hooks/ClientPurchase/StockTransfer/useSubmitStockTransferDraft";
import { useUpdateStockTransferDraft } from "../../query-hooks/ClientPurchase/StockTransfer/useUpdateStockTransferDraft";
import { useDeleteSTDraft } from "../../query-hooks/ClientPurchase/StockTransfer/useDeleteSTDraft";
import { useFetchStockAdjustmentFormData } from "../../query-hooks/ClientPurchase/StockAdjustment/useFetchStockAdjustmentFormData";
import { useFetchStockAdjustmentById } from "../../query-hooks/ClientPurchase/StockAdjustment/useFetchStockAdjustmentById";
import { useCreateStockAdjustment } from "../../query-hooks/ClientPurchase/StockAdjustment/useCreateStockAdjustment";
import { useSaveDraftStockAdjustment } from "../../query-hooks/ClientPurchase/StockAdjustment/useSaveStockAdjustmentDraft";
import { useUpdateStockAdjustmentDraft } from "../../query-hooks/ClientPurchase/StockAdjustment/useUpdateStockAdjustmentDraft";
import { useSubmitStockAdjustmentDraft } from "../../query-hooks/ClientPurchase/StockAdjustment/useSubmitStockAdjustmentDraft";
import { useDeleteSADraft } from "../../query-hooks/ClientPurchase/StockAdjustment/useDeleteSADraft";
import { useFetchStockTransferSKUs } from "../../query-hooks/ClientPurchase/StockTransfer/useFetchStockTransferSKUs";
import { useFetchStockAdjustmentSKUs } from "../../query-hooks/ClientPurchase/StockAdjustment/useFetchStockAdjustmentSKUs";
import { useCancelStockAdjustment } from "../../query-hooks/ClientPurchase/StockAdjustment/useCancelStockAdjustment";
import { useCancelStockTransfer } from "../../query-hooks/ClientPurchase/StockTransfer/useCancelStockTransfer";
import { useReactToPrint } from "react-to-print";
import { TuneOutlined, Verified } from "@mui/icons-material";
import { isMobileDevice } from "../../App";
import { useDrawer } from "../../shared/customSideDrawer";
import MobileOrderReport from "../components/MobileOrderReport";
import TextArea from "../../shared/formElements/TextArea";
import { ImageUpload } from "../../shared/formElements/ImageUpload";
import { handleDownload } from "../../warehouse/components/MaterialReceivedReport/MrrTable";

export const addressData = (data) => {
  if (!Boolean(data)) return {};
  const addressObject = {
    customerName: data?.code,
    companyName: "",
    address: `${data?.address?.address}, ${data?.address?.area}, ${data?.address?.city}, ${data?.address?.state}, ${data?.address?.pincode}`
  };
  return addressObject;
};

const StockButtonGroup = ({
  allowEdit,
  allowCancel,
  manageOrderType,
  isDraft,
  onDeleteClick,
  onCancel,
  onModify,
  onSaveDraft,
  onSubmitClick
}) => {
  return (
    <>
      <Grid item xs={12} container rowSpacing={1}>
        {allowEdit && isDraft && (
          <>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="soft"
                onClick={onModify}
                sx={{ minHeight: "48px" }}
              >
                Modify
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="outlined"
                sx={{ minHeight: "48px", color: "error.main" }}
                onClick={onDeleteClick}
              >
                Delete Draft
              </Button>
            </Grid>
          </>
        )}
        {allowCancel && manageOrderType !== "view-only" && (
          <>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="outlined"
                sx={{ minHeight: "48px", color: "error.main" }}
                onClick={onCancel}
              >
                Cancel Order
              </Button>
            </Grid>
          </>
        )}
        {!isDraft && (
          <>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="soft"
                sx={{ minHeight: "48px" }}
                onClick={onSaveDraft}
              >
                Save Draft
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                sx={{ minHeight: "48px" }}
                onClick={onSubmitClick}
              >
                Submit
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

const stockTransferSchema = (createByDraft) => {
  const baseSchema = {
    newOrders: yup.array().of(
      yup.object().shape({
        _id: yup.string().required(error_msg.required),
        toBeTransferred: yup.string().required(error_msg.required),
        reason: yup.string().required(error_msg.required)
      })
    )
  };

  if (!createByDraft) {
    baseSchema["originWarehouse"] = yup.string().required(error_msg.required);
    baseSchema["receivingWarehouse"] = yup
      .string()
      .required(error_msg.required);
  }

  return yup.object(baseSchema);
};

const stockAdjustmentSchema = (createByDraft) => {
  const baseSchema = {
    newOrders: yup.array().of(
      yup.object().shape({
        _id: yup.string().required(error_msg.required),
        actualStockQuantity: yup.string().required(error_msg.required),
        reason: yup.string().required(error_msg.required)
      })
    )
  };

  if (!createByDraft) {
    baseSchema["warehouse"] = yup.string().required(error_msg.required);
  }

  return yup.object(baseSchema);
};

const CreateStockOrder = () => {
  const [activeTab, setActiveTab] = useState(0);
  const selectedRowsRef = useRef(null);
  const { orderId, stockOrderType, manageOrderType } = useParams();
  const [params, setParams] = useState();
  const navigate = useNavigate();
  const [isDraft, setIsDraft] = useState(Boolean(orderId));
  const [checkOrderType, setCheckOrderType] = useState(
    stockOrderType?.replace(/-(.)/g, (_, char) => char.toUpperCase()) ?? ""
  );

  const componentRef = useRef();

  const { control, watch, reset, handleSubmit, setValue } = useForm({
    defaultValues: {
      orderType: checkOrderType ?? "",
      newOrders: []
    },
    resolver: yupResolver(
      checkOrderType === "stockTransfer"
        ? stockTransferSchema(Boolean(orderId))
        : stockAdjustmentSchema(Boolean(orderId))
    )
  });

  const { showDialog, hideDialog } = useCustomDialog();
  const { showDrawer, hideDrawer } = useDrawer();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "newOrders"
  });

  const {
    data: stockTransferData,
    isFetching: isFetchingStockTransferData,
    refetch: refetchSTData
  } = useFetchStockTransferById(checkOrderType, orderId);

  const { data: stockTransferSKUs, isFetching: isFetchingStockTransferSkus } =
    useFetchStockTransferSKUs(checkOrderType, params);

  const { data: stockTransferFormData, isFetching: isFetchingSTFormData } =
    useFetchStockTransferFormData(checkOrderType);

  const {
    mutate: createStockTransfer,
    isLoading: isCreating,
    isError: isCreateError,
    error: createError
  } = useCreateStockTransfer();

  const {
    mutate: saveDraftStockTransfer,
    isLoading: isSavingDraft,
    isError: isSaveDraftError,
    error: saveDraftError
  } = useSaveDraftStockTransfer();

  const {
    mutate: updateStockTransferDraft,
    isLoading: isUpdatingDraft,
    isError: isUpdateDraftError,
    error: updateDraftError
  } = useUpdateStockTransferDraft();

  const {
    mutate: submitStockTransferDraft,
    isLoading: isSubmittingDraft,
    isError: isSubmitDraftError,
    error: submitDraftError
  } = useSubmitStockTransferDraft();

  const {
    mutate: deleteSTDraft,
    isError: isDeleteSTDraftError,
    error: deleteSTDraftError
  } = useDeleteSTDraft();

  const {
    mutate: cancelST,
    isError: isCancelSTError,
    error: cancelSTError
  } = useCancelStockTransfer();

  const {
    data: stockAdjustmentData,
    isFetching: isFetchingStockAdjustmentData,
    refetch: refetchSAData
  } = useFetchStockAdjustmentById(checkOrderType, orderId);

  const {
    data: stockAdjustmentSKUs,
    isFetching: isFetchingStockAdjustmentSkus
  } = useFetchStockAdjustmentSKUs(checkOrderType, params);

  const { data: stockAdjustmentFormData, isFetching: isFetchingSAFormData } =
    useFetchStockAdjustmentFormData(checkOrderType);

  const {
    mutate: createStockAdjustment,
    isLoading: isCreatingSA,
    isError: isCreateSAError,
    error: createSAError
  } = useCreateStockAdjustment();

  const {
    mutate: saveDraftStockAdjustment,
    isLoading: isSavingSADraft,
    isError: isSaveSADraftError,
    error: saveSADraftError
  } = useSaveDraftStockAdjustment();

  const {
    mutate: updateStockAdjustmentDraft,
    isLoading: isUpdatingSADraft,
    isError: isUpdateSADraftError,
    error: updateSADraftError
  } = useUpdateStockAdjustmentDraft();

  const {
    mutate: submitStockAdjustmentDraft,
    isLoading: isSubmittingSADraft,
    isError: isSubmitSADraftError,
    error: submitSADraftError
  } = useSubmitStockAdjustmentDraft();

  const {
    mutate: deleteSADraft,
    isError: isDeleteSADraftError,
    error: deleteSADraftError
  } = useDeleteSADraft();

  const {
    mutate: cancelSA,
    isError: isCancelSAError,
    error: cancelSAError
  } = useCancelStockAdjustment();

  useEffect(() => {
    if (!isFetchingStockTransferData) {
      stockTransferData?.stockTransfer?.tnc &&
        setValue("remarks", stockTransferData?.stockTransfer?.tnc);
      setParams({
        fromWarehouse: stockTransferData?.stockTransfer?.warehouseIds[0],
        toWarehouse: stockTransferData?.stockTransfer?.warehouseIds[1]
      });
      stockTransferData?.stockTransfer?.items?.forEach((item, index) => {
        append({
          _id: item.sku,
          toBeTransferred: item.quantity,
          reason: item.reason.name,
          reasonDisplay: item.reason.displayName,
          skuCode: item.skuData.skuCode,
          productName: item?.skuData?.productName,
          brandName: item?.skuData?.brandName,
          categoryName: item?.skuData?.categoryName,
          unit: item?.skuData.unit,
          hsn: item?.skuData?.hsn,
          image: item?.skuData?.image,
          stock: item?.stock
        });
      });
    } // eslint-disable-next-line
  }, [isFetchingStockTransferData]);

  useEffect(() => {
    if (!isFetchingStockAdjustmentData) {
      stockAdjustmentData?.stockAdjustment?.tnc &&
        setValue("remarks", stockAdjustmentData?.stockAdjustment?.tnc);
      setParams({
        warehouse: stockAdjustmentData?.stockAdjustment?.warehouseIds[0]
      });
      stockAdjustmentData?.stockAdjustment?.items?.forEach((item, index) => {
        const actualQty = item?.stock?.closingQuantity + item.quantity;
        append({
          _id: item.sku,
          actualStockQuantity: actualQty,
          reason: item.reason.name,
          reasonDisplay: item.reason.displayName,
          skuCode: item.skuData.skuCode,
          productName: item?.skuData?.productName,
          brandName: item?.skuData?.brandName,
          categoryName: item?.skuData?.categoryName,
          unit: item?.skuData.unit,
          hsn: item?.skuData?.hsn,
          image: item?.skuData?.image,
          stock: item?.stock
        });
      });
    } // eslint-disable-next-line
  }, [isFetchingStockAdjustmentData]);

  const orderTypeWatch = watch("orderType");

  const originWarehouseWatch = watch("originWarehouse");

  const receivingWarehouseWatch = watch("receivingWarehouse");

  const warehouseWatch = watch("warehouse");

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle:
      orderTypeWatch === "stockTransfer"
        ? stockTransferData?.stockTransfer?.code ?? `ST-Draft`
        : stockAdjustmentData?.stockAdjustment?.code ?? `SA-Draft`
  });

  useEffect(() => {
    if (
      orderTypeWatch === "stockTransfer" &&
      Boolean(originWarehouseWatch) &&
      Boolean(receivingWarehouseWatch)
    ) {
      const apiParams = {
        fromWarehouse: originWarehouseWatch,
        toWarehouse: receivingWarehouseWatch
      };
      setValue("newOrders", []);
      setParams(apiParams);
    }
    if (orderTypeWatch === "stockAdjustments" && Boolean(warehouseWatch)) {
      const apiParams = {
        warehouse: warehouseWatch
      };
      setValue("newOrders", []);
      setParams(apiParams);
    }
  }, [
    setValue,
    orderTypeWatch,
    originWarehouseWatch,
    receivingWarehouseWatch,
    warehouseWatch
  ]);

  const toBeTransferredWatch = watch(
    `newOrders.${fields.length ? fields.length - 1 : 0}.toBeTransferred`
  );

  const actualStockQuantityWatch = watch(
    `newOrders.${fields.length ? fields.length - 1 : 0}.actualStockQuantity`
  );

  const reasonWatch = watch(
    `newOrders.${fields.length ? fields.length - 1 : 0}.reason`
  );

  const orderTypeFilters = {
    orderType: {
      stockTransfer: {
        name: "Stock Transfer Order",
        fields: [
          {
            name: "Origin Warehouse",
            id: "originWarehouse",
            isDisabled: Boolean(orderId),
            type: "dropdown",
            placeholder: orderId
              ? stockTransferFormData?.warehouses?.find(
                  (obj) =>
                    obj["_id"] ===
                    stockTransferData?.stockTransfer?.warehouseIds[0]
                )?.code
              : "Select Origin Warehouse",
            unSelectText: orderId
              ? stockTransferFormData?.warehouses?.find(
                  (obj) =>
                    obj["_id"] ===
                    stockTransferData?.stockTransfer?.warehouseIds[0]
                )?.code
              : "Select Origin Warehouse",
            availUnselect: true,
            options: orderId
              ? []
              : dropdownOptions(
                  stockTransferFormData?.warehouses?.filter(
                    (obj) => obj["_id"] !== receivingWarehouseWatch
                  ),
                  "_id",
                  "code"
                )
          },
          {
            name: "Receiving Warehouse",
            id: "receivingWarehouse",
            isDisabled: Boolean(orderId),
            type: "dropdown",
            placeholder: orderId
              ? stockTransferFormData?.warehouses?.find(
                  (obj) =>
                    obj["_id"] ===
                    stockTransferData?.stockTransfer?.warehouseIds[1]
                )?.code
              : "Select Receiving Warehouse",
            unSelectText: orderId
              ? stockTransferFormData?.warehouses?.find(
                  (obj) =>
                    obj["_id"] ===
                    stockTransferData?.stockTransfer?.warehouseIds[1]
                )?.code
              : "Select Receiving Warehouse",
            availUnselect: true,
            options: orderId
              ? []
              : dropdownOptions(
                  stockTransferFormData?.warehouses?.filter(
                    (obj) => obj["_id"] !== originWarehouseWatch
                  ),
                  "_id",
                  "code"
                )
          }
        ],
        actions:
          !isFetchingStockTransferData &&
          stockTransferData?.stockTransfer?.status?.name === statusKeys.Draft
            ? ["Add", "Remove", "SaveDraft", "Submit", "Reset"]
            : ["Add", "Remove", "SaveDraft", "Submit"],
        selection: true,
        shippingTitle: "Receiving Warehouse",
        buyerTitle: "Origin Warehouse",
        orderInfo: {
          stockTransferOrderNo: orderId
            ? stockTransferData?.stockTransfer?.code
            : "--",
          orderDate:
            orderId && stockTransferData?.stockTransfer?.orderDate
              ? moment(stockTransferData?.stockTransfer?.orderDate).format(
                  "DD-MM-YYYY"
                )
              : "--"
        },
        buyerInfo: orderId
          ? addressData(stockTransferData?.stockTransfer?.clientData?.warehouse)
          : originWarehouseWatch !== "" && originWarehouseWatch !== undefined
          ? addressData(
              stockTransferFormData?.warehouses?.find(
                (obj) => obj["_id"] === originWarehouseWatch
              )
            )
          : {},
        shipInfo: orderId
          ? addressData(stockTransferData?.stockTransfer?.vendorData?.warehouse)
          : receivingWarehouseWatch !== "" &&
            receivingWarehouseWatch !== undefined
          ? addressData(
              stockTransferFormData?.warehouses?.find(
                (obj) => obj["_id"] === receivingWarehouseWatch
              )
            )
          : {}
      },
      stockAdjustments: {
        name: "Stock Adjustment",
        fields: [
          {
            name: "Warehouse",
            id: "warehouse",
            type: "dropdown",
            isDisabled: Boolean(orderId),
            placeholder: orderId
              ? stockAdjustmentFormData?.warehouses?.find(
                  (obj) =>
                    obj["_id"] ===
                    stockAdjustmentData?.stockAdjustment?.warehouseIds[0]
                )?.code
              : "Select Warehouse",
            options: orderId
              ? []
              : dropdownOptions(
                  stockAdjustmentFormData?.warehouses,
                  "_id",
                  "code"
                )
          }
        ],
        actions:
          !isFetchingStockAdjustmentData &&
          stockAdjustmentData?.stockAdjustment?.status?.name ===
            statusKeys.Draft
            ? ["Add", "Remove", "SaveDraft", "Submit", "Reset"]
            : ["Add", "Remove", "SaveDraft", "Submit"],
        selection: true,
        orderInfo: {
          stockAdjustmentNo: orderId
            ? stockAdjustmentData?.stockAdjustment?.code
            : "--",
          orderDate:
            orderId && stockAdjustmentData?.stockAdjustment?.orderDate
              ? moment(stockAdjustmentData?.stockAdjustment?.orderDate).format(
                  "DD-MM-YYYY"
                )
              : "--"
        }
      }
    }
  };

  useEffect(() => {
    reset((prevValue) => ({
      orderType: prevValue.orderType,
      newOrders: []
    }));
    setCheckOrderType(orderTypeWatch);
  }, [reset, orderTypeWatch]);

  const getTable = (type, isPrint = false) => {
    switch (type) {
      case "Stock Transfer Order":
        return (
          <StockTransferOrderTable
            isPrint={isPrint}
            fields={fields}
            control={control}
            watch={watch}
            append={append}
            remove={remove}
            onAdd={() => {
              if (fields.length < 1 || (toBeTransferredWatch && reasonWatch)) {
                append({ item: "" });
              } else {
                enqueueSnackbar("kindly first fill reason and quantity", {
                  variant: "error"
                });
              }
            }}
            onRowSelectionChange={(rows) => {
              selectedRowsRef.current = rows;
            }}
            orderId={orderId}
            options={
              orderId
                ? stockTransferData?.stockTransfer?.status?.name === "Draft"
                  ? stockTransferFormData
                  : []
                : stockTransferFormData
            }
            SKUs={stockTransferSKUs?.skus ?? []}
            status={stockTransferData?.stockTransfer?.status?.name ?? ""}
            isEditable={
              Boolean(stockTransferData?.stockTransfer?.status?.name)
                ? isDraft
                : true
            }
            preparedBy={
              orderId
                ? `${stockTransferData?.stockTransfer?.preparedBy?.firstName} ${stockTransferData?.stockTransfer?.preparedBy?.lastName}`
                : `${stockTransferFormData?.clientAdmin?.firstName} ${stockTransferFormData?.clientAdmin?.lastName}`
            }
          />
        );
      case "Stock Adjustment": {
        return (
          <StockAdjustmentTable
            isPrint={isPrint}
            fields={fields}
            control={control}
            watch={watch}
            append={append}
            remove={remove}
            onAdd={() => {
              if (fields.length < 1 || actualStockQuantityWatch) {
                append({ item: "" });
              } else {
                enqueueSnackbar("kindly first fill quantity", {
                  variant: "error"
                });
              }
            }}
            setValue={setValue}
            onRowSelectionChange={(rows) => {
              selectedRowsRef.current = rows;
            }}
            SKUs={stockAdjustmentSKUs?.skus ?? []}
            orderId={orderId}
            warehouseCode={
              orderId
                ? stockAdjustmentFormData?.warehouses?.find(
                    (obj) =>
                      obj["_id"] ===
                      stockAdjustmentData?.stockAdjustment?.warehouseIds[0]
                  )?.code
                : stockAdjustmentFormData?.warehouses?.find(
                    (obj) => obj["_id"] === warehouseWatch
                  )?.code
            }
            options={
              orderId
                ? stockAdjustmentData?.stockAdjustment?.status?.name === "Draft"
                  ? stockAdjustmentFormData
                  : []
                : stockAdjustmentFormData
            }
            files={
              orderId ? stockAdjustmentData?.stockAdjustment?.files ?? [] : []
            }
            orderStatus={
              stockAdjustmentData?.stockAdjustment?.status?.name ?? ""
            }
            isEditable={
              Boolean(stockAdjustmentData?.stockAdjustment?.status?.name)
                ? isDraft
                : true
            }
            preparedBy={
              orderId
                ? `${stockAdjustmentData?.stockAdjustment?.preparedBy?.firstName} ${stockAdjustmentData?.stockAdjustment?.preparedBy?.lastName}`
                : `${stockAdjustmentFormData?.clientAdmin?.firstName} ${stockAdjustmentFormData?.clientAdmin?.lastName}`
            }
          />
        );
      }
      default:
        return <>Not Found</>;
    }
  };

  const getTableFunctions = (type) => {
    switch (type) {
      case "Stock Transfer Order": {
        return {
          onAdd: () => {
            if (fields.length < 1 || (toBeTransferredWatch && reasonWatch)) {
              append({ item: "" });
            } else {
              enqueueSnackbar("kindly first fill Empty Input Fields", {
                variant: "error"
              });
            }
          },
          onRemove: () => {
            const rowsToRemove = Object.keys(selectedRowsRef.current).filter(
              (key) => selectedRowsRef.current[key]
            );
            remove(rowsToRemove);
          }
        };
      }
      case "Stock Adjustment": {
        return {
          onAdd: () => {
            if (
              fields.length < 1 ||
              (actualStockQuantityWatch && reasonWatch)
            ) {
              append({ item: "" });
            } else {
              enqueueSnackbar("kindly first fill Empty Input Fields", {
                variant: "error"
              });
            }
          },
          onRemove: () => {
            const rowsToRemove = Object.keys(selectedRowsRef.current).filter(
              (key) => selectedRowsRef.current[key]
            );
            remove(rowsToRemove);
          }
        };
      }
      default:
        return <>Not Found</>;
    }
  };

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  const onResetOrder = () => {
    reset();
    if (orderTypeWatch === "stockTransfer") {
      stockTransferData?.stockTransfer?.tnc &&
        setValue("remarks", stockTransferData?.stockTransfer?.tnc);
      stockTransferData?.stockTransfer?.items?.forEach((item, index) => {
        append({
          _id: item?.sku,
          toBeTransferred: item?.quantity,
          reason: item?.reason?.name,
          reasonDisplay: item?.reason?.displayName,
          skuCode: item?.skuData?.skuCode,
          productName: item?.skuData?.productName,
          brandName: item?.skuData?.brandName,
          categoryName: item?.skuData?.categoryName,
          unit: item?.skuData.unit,
          hsn: item?.skuData?.hsn,
          image: item?.skuData?.image,
          stock: item?.stock
        });
      });
    }
    if (orderTypeWatch === "stockAdjustments") {
      stockAdjustmentData?.stockAdjustment?.tnc &&
        setValue("remarks", stockAdjustmentData?.stockAdjustment?.tnc);
      stockAdjustmentData?.stockAdjustment?.items?.forEach((item, index) => {
        const actualQty = item?.stock?.closingQuantity + item.quantity;
        append({
          _id: item?.sku,
          actualStockQuantity: actualQty,
          reason: item?.reason?.name,
          reasonDisplay: item?.reason?.displayName,
          skuCode: item?.skuData?.skuCode,
          productName: item?.skuData?.productName,
          brandName: item?.skuData?.brandName,
          categoryName: item?.skuData?.categoryName,
          unit: item?.skuData.unit,
          hsn: item?.skuData?.hsn,
          image: item?.skuData?.image,
          stock: item?.stock
        });
      });
    }
  };

  const onModify = () => {
    if (
      (orderTypeWatch === "stockTransfer" &&
        stockTransferData?.stockTransfer?.status?.name === statusKeys.Draft) ||
      (orderTypeWatch === "stockAdjustments" &&
        stockAdjustmentData?.stockAdjustment?.status?.name === statusKeys.Draft)
    ) {
      setIsDraft((prev) => !prev);
      onResetOrder();
    }
  };

  const onCancel = () => {
    showDialog({
      component: (
        <SuccessfulDialog
          text={"Are you sure you want to Cancel the Order ?"}
          btn1Text={"Go Back"}
          btn2Text={"Cancel Order"}
          btn1Callback={() => hideDialog()}
          btn2Callback={() => cancelOrder()}
        />
      )
    });
  };

  const cancelOrder = () => {
    if (orderTypeWatch === "stockTransfer") {
      cancelST(
        {
          orderId: orderId
        },
        {
          onSuccess: (res) => {
            hideDialog();
            enqueueSnackbar("Stock Transfer Cancelled!", {
              variant: "success"
            });
            navigate(
              `${appRoutes.purchase.main}/${appRoutes.purchase.stockTransfer}`
            );
          }
        }
      );
    }
    if (orderTypeWatch === "stockAdjustments") {
      cancelSA(
        {
          orderId: orderId
        },
        {
          onSuccess: (res) => {
            hideDialog();
            enqueueSnackbar("Stock Adjustment Cancelled!", {
              variant: "success"
            });
            navigate(
              `${appRoutes.purchase.main}/${appRoutes.purchase.stockAdjustment}`
            );
          }
        }
      );
    }
  };

  const onSaveDraft = (data) => {
    if (orderTypeWatch === "stockTransfer") {
      const formData = {
        ...{
          ...(orderId
            ? {}
            : {
                fromWarehouse: data["originWarehouse"],
                toWarehouse: data["receivingWarehouse"]
              })
        },
        tnc: data["remarks"] ? data["remarks"] : null,
        items: data["newOrders"]?.map((item) => ({
          sku: item._id,
          quantity: +item.toBeTransferred,
          reason: item.reason
        }))
      };
      orderId
        ? updateStockTransferDraft(
            {
              data: { ...formData },
              orderId: orderId
            },
            {
              onSuccess: (res) => {
                enqueueSnackbar("Draft Updated!", {
                  variant: "success"
                });
                setIsDraft((prev) => !prev);
                reset();
                refetchSTData();
              }
            }
          )
        : saveDraftStockTransfer(
            {
              data: { ...formData }
            },
            {
              onSuccess: (res) => {
                enqueueSnackbar("Stock Transfer Draft Saved!", {
                  variant: "success"
                });
                navigate(
                  `${appRoutes.purchase.main}/${appRoutes.purchase.stockTransfer}`
                );
              }
            }
          );
    }
    if (orderTypeWatch === "stockAdjustments") {
      const formData = new FormData();
      !Boolean(orderId) && formData.append("warehouse", data["warehouse"]);
      data["remarks"] && formData.append("tnc", data["remarks"]);
      data["newOrders"]?.forEach((item, index) => {
        formData.append(`items[${index}][sku]`, item._id);
        formData.append(
          `items[${index}][quantity]`,
          +item.actualStockQuantity - +item.stock.closingQuantity
        );
        formData.append(`items[${index}][reason]`, item.reason);
      });
      const files = data["uploadAttachments"];
      if (files && files?.length > 0) {
        for (let i = 0; i < files.length; i++) {
          formData.append("files", files[i]);
        }
      }
      orderId
        ? updateStockAdjustmentDraft(
            {
              data: formData,
              orderId: orderId
            },
            {
              onSuccess: (res) => {
                enqueueSnackbar("Draft Updated!", {
                  variant: "success"
                });
                setIsDraft((prev) => !prev);
                reset();
                refetchSAData();
              }
            }
          )
        : saveDraftStockAdjustment(
            {
              data: formData
            },
            {
              onSuccess: (res) => {
                enqueueSnackbar("Stock Adjustment Draft Saved!", {
                  variant: "success"
                });
                navigate(
                  `${appRoutes.purchase.main}/${appRoutes.purchase.stockAdjustment}`
                );
              }
            }
          );
    }
  };

  const onSubmit = (data) => {
    showDialog({
      component: (
        <SuccessfulDialog
          text={"Are you sure you want to submit the document ?"}
          subText={"Submitted documents cannot be edited. "}
          btn1Text={"Go to Draft"}
          btn2Text={"Submit"}
          btn1Callback={() => hideDialog()}
          btn2Callback={() => submitData(data)}
        />
      )
    });
  };

  const submitData = (data) => {
    if (orderTypeWatch === "stockTransfer") {
      const formData = {
        ...{
          ...(orderId
            ? {}
            : {
                fromWarehouse: data["originWarehouse"],
                toWarehouse: data["receivingWarehouse"]
              })
        },
        tnc: data["remarks"] ? data["remarks"] : null,
        items: data["newOrders"]?.map((item) => ({
          sku: item._id,
          quantity: +item.toBeTransferred,
          reason: item.reason
        }))
      };
      orderId
        ? submitStockTransferDraft(
            {
              data: { ...formData },
              orderId: orderId
            },
            {
              onSuccess: (res) => {
                hideDialog();
                enqueueSnackbar("Stock Transfer created!", {
                  variant: "success"
                });
                reset();
                setIsDraft((prev) => !prev);
                refetchSTData();
              }
            }
          )
        : createStockTransfer(
            {
              data: { ...formData }
            },
            {
              onSuccess: (res) => {
                hideDialog();
                refetchSTData();
                enqueueSnackbar("Stock Transfer created!", {
                  variant: "success"
                });
                navigate(
                  `${appRoutes.purchase.main}/${appRoutes.purchase.stockTransfer}`
                );
              }
            }
          );
    }
    if (orderTypeWatch === "stockAdjustments") {
      const formData = new FormData();
      !Boolean(orderId) && formData.append("warehouse", data["warehouse"]);
      data["remarks"] && formData.append("tnc", data["remarks"]);
      data["newOrders"]?.forEach((item, index) => {
        formData.append(`items[${index}][sku]`, item._id);
        formData.append(
          `items[${index}][quantity]`,
          +item.actualStockQuantity - +item.stock.closingQuantity
        );
        formData.append(`items[${index}][reason]`, item.reason);
      });
      const files = data["uploadAttachments"];
      if (files && files?.length > 0) {
        for (let i = 0; i < files.length; i++) {
          formData.append("files", files[i]);
        }
      }
      orderId
        ? submitStockAdjustmentDraft(
            {
              data: formData,
              orderId: orderId
            },
            {
              onSuccess: (res) => {
                hideDialog();
                enqueueSnackbar("Draft Updated!", {
                  variant: "success"
                });
                reset();
                setIsDraft((prev) => !prev);
                refetchSAData();
              }
            }
          )
        : createStockAdjustment(
            {
              data: formData
            },
            {
              onSuccess: (res) => {
                hideDialog();
                refetchSAData();
                enqueueSnackbar("Stock Adjustment Draft Saved!", {
                  variant: "success"
                });
                navigate(
                  `${appRoutes.purchase.main}/${appRoutes.purchase.stockAdjustment}`
                );
              }
            }
          );
    }
  };

  const onDeleteClick = () => {
    showDialog({
      component: (
        <SuccessfulDialog
          text={"Are you sure you want to Delete this draft ?"}
          subText={"Deleted documents cannot be recovered. "}
          btn1Text={"Go to Draft"}
          btn2Text={"Delete"}
          btn1Callback={() => hideDialog()}
          btn2Callback={() => deleteDraft()}
        />
      )
    });
  };

  const deleteDraft = () => {
    if (orderTypeWatch === "stockTransfer") {
      deleteSTDraft(
        {
          orderId: orderId
        },
        {
          onSuccess: (res) => {
            hideDialog();
            enqueueSnackbar("Draft Deleted!", {
              variant: "success"
            });
            navigate(
              `${appRoutes.purchase.main}/${appRoutes.purchase.stockTransfer}`
            );
          }
        }
      );
    }
    if (orderTypeWatch === "stockAdjustments") {
      deleteSADraft(
        {
          orderId: orderId
        },
        {
          onSuccess: (res) => {
            hideDialog();
            enqueueSnackbar("Draft Deleted!", {
              variant: "success"
            });
            navigate(
              `${appRoutes.purchase.main}/${appRoutes.purchase.stockAdjustment}`
            );
          }
        }
      );
    }
  };

  useEffect(() => {
    if (isCreateError) {
      enqueueSnackbar(createError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isUpdateDraftError)
      enqueueSnackbar(updateDraftError?.response?.data?.message, {
        variant: "error"
      });
    if (isSubmitDraftError) {
      enqueueSnackbar(submitDraftError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isSaveDraftError)
      enqueueSnackbar(saveDraftError?.response?.data?.message, {
        variant: "error"
      });
    if (isDeleteSTDraftError) {
      enqueueSnackbar(deleteSTDraftError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isCancelSTError) {
      enqueueSnackbar(cancelSTError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isCreateSAError) {
      enqueueSnackbar(createSAError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isUpdateSADraftError)
      enqueueSnackbar(updateSADraftError?.response?.data?.message, {
        variant: "error"
      });
    if (isSubmitSADraftError) {
      enqueueSnackbar(submitSADraftError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isSaveSADraftError)
      enqueueSnackbar(saveSADraftError?.response?.data?.message, {
        variant: "error"
      });
    if (isDeleteSADraftError) {
      enqueueSnackbar(deleteSADraftError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isCancelSAError) {
      enqueueSnackbar(cancelSAError?.response?.data?.message, {
        variant: "error"
      });
    }
  }, [
    updateDraftError?.response?.data?.message,
    submitDraftError?.response?.data?.message,
    createError?.response?.data?.message,
    saveDraftError?.response?.data?.message,
    deleteSTDraftError?.response?.data?.message,
    cancelSTError?.response?.data?.message,
    updateSADraftError?.response?.data?.message,
    submitSADraftError?.response?.data?.message,
    createSAError?.response?.data?.message,
    saveSADraftError?.response?.data?.message,
    deleteSADraftError?.response?.data?.message,
    cancelSAError?.response?.data?.message,
    isSaveDraftError,
    isUpdateDraftError,
    isSubmitDraftError,
    isCreateError,
    isDeleteSTDraftError,
    isCancelSTError,
    isSaveSADraftError,
    isUpdateSADraftError,
    isSubmitSADraftError,
    isCreateSAError,
    isDeleteSADraftError,
    isCancelSAError
  ]);

  const allowCancel =
    stockTransferData?.stockTransfer?.status?.name === statusKeys.Placed ||
    stockAdjustmentData?.stockAdjustment?.status?.name === statusKeys.Placed;

  const allowEdit =
    stockTransferData?.stockTransfer?.status?.name === statusKeys.Draft ||
    stockAdjustmentData?.stockAdjustment?.status?.name === statusKeys.Draft;

  const orderStatus =
    stockTransferData?.stockTransfer?.status?.name ||
    stockAdjustmentData?.stockAdjustment?.status?.name ||
    "";

  const isEditable =
    orderTypeWatch === "stockTransfer"
      ? Boolean(stockTransferData?.stockTransfer?.status?.name)
        ? isDraft
        : true
      : Boolean(stockTransferData?.stockTransfer?.status?.name)
      ? isDraft
      : true;

  const RemarksComp = (
    <TextArea
      control={control}
      name={"remarks"}
      disabled={Boolean(orderStatus) ? isEditable : false}
    />
  );

  const ButtonGroup = (
    <StockButtonGroup
      allowEdit={allowEdit}
      allowCancel={allowCancel}
      manageOrderType={manageOrderType}
      isDraft={isDraft}
      onCancel={onCancel}
      onDeleteClick={onDeleteClick}
      onModify={onModify}
      onSaveDraft={handleSubmit(onSaveDraft)}
      onSubmitClick={handleSubmit(onSubmit)}
      onEdit={
        getTableFunctions(orderTypeFilters.orderType[orderTypeWatch]?.name)
          .onEdit
      }
    />
  );

  const files = orderId
    ? stockAdjustmentData?.stockAdjustment?.files ?? []
    : [];

  const Attachments = (
    <>
      {files?.length > 0 ? (
        <Box display={"flex"} flexDirection={"column"} gap={2} padding={"10px"}>
          {files?.map((file, index) => (
            <Box key={index} display={"flex"} alignItems={"center"} gap={2}>
              <Verified sx={{ color: "primary.main" }} />
              <Button
                variant="link"
                disableRipple
                sx={{ fontWeight: 600 }}
                onClick={() => handleDownload(file.fileUrl)}
              >
                {file.originalName}
              </Button>
            </Box>
          ))}
        </Box>
      ) : (
        orderStatus !== "" &&
        orderStatus !== "Draft" && (
          <Box display={"flex"} justifyContent={"center"}>
            <Typography sx={{ fontWeight: 600, color: "text.disabled" }}>
              {"No Attachments"}
            </Typography>
          </Box>
        )
      )}
      {(orderStatus === "" || orderStatus === "Draft") &&
        files?.length !== 3 && (
          <Grid item xs={12} md={3}>
            <ImageUpload
              control={control}
              name={"uploadAttachments"}
              setValue={setValue}
              compact={true}
              uploadButtonText={"Upload Attachments"}
              isMultiple={true}
              acceptFileType=".jpg, .jpeg, .png, .pdf"
              minimumUploads={files?.length ? 3 - files?.length : 3}
              showTextOnly
            />
          </Grid>
        )}
    </>
  );

  const OrderReportComp = (
    <>
      <Box
        sx={{ display: { xs: "none", md: "flex" } }}
        flexDirection={"column"}
        gap={1}
      >
        <OrderReport
          isDraft={!isDraft}
          title={orderTypeFilters.orderType[orderTypeWatch]?.name}
          sellerTitle={orderTypeFilters.orderType[orderTypeWatch]?.sellerTitle}
          buyerTitle={orderTypeFilters.orderType[orderTypeWatch]?.buyerTitle}
          shippingTitle={
            orderTypeFilters.orderType[orderTypeWatch]?.shippingTitle
          }
          orderInfo={orderTypeFilters.orderType[orderTypeWatch]?.orderInfo}
          sellerInfo={orderTypeFilters.orderType[orderTypeWatch]?.sellerInfo}
          buyerInfo={orderTypeFilters.orderType[orderTypeWatch]?.buyerInfo}
          shipInfo={orderTypeFilters.orderType[orderTypeWatch]?.shipInfo}
          actions={orderTypeFilters.orderType[orderTypeWatch]?.actions}
          onAdd={
            getTableFunctions(orderTypeFilters.orderType[orderTypeWatch]?.name)
              .onAdd
          }
          onRemove={
            getTableFunctions(orderTypeFilters.orderType[orderTypeWatch]?.name)
              .onRemove
          }
          onEdit={
            getTableFunctions(orderTypeFilters.orderType[orderTypeWatch]?.name)
              .onEdit
          }
          onSaveDraft={handleSubmit(onSaveDraft)}
          onSubmit={handleSubmit(onSubmit)}
          onModify={onModify}
        >
          {getTable(orderTypeFilters.orderType[orderTypeWatch]?.name)}
        </OrderReport>
      </Box>
      <Box
        sx={{ display: { xs: "flex", md: "none" } }}
        flexDirection={"column"}
        gap={1}
      >
        <MobileOrderReport
          hideTotal
          showAttachments
          showOtherDetails={false}
          status={
            stockTransferData?.stockTransfer?.status?.name ||
            stockAdjustmentData?.stockAdjustment?.status?.name
          }
          otherInfo={""}
          mobileTable={getTable(
            orderTypeFilters.orderType[orderTypeWatch]?.name
          )}
          remarksComp={RemarksComp}
          Total={[]}
          Attachments={Attachments}
          ButtonGroup={ButtonGroup}
          preparedBy={
            orderTypeWatch === "stockTransfer"
              ? orderId
                ? `${stockTransferData?.stockTransfer?.preparedBy?.firstName} ${stockTransferData?.stockTransfer?.preparedBy?.lastName}`
                : `${stockTransferFormData?.clientAdmin?.firstName} ${stockTransferFormData?.clientAdmin?.lastName}`
              : orderId
              ? `${stockAdjustmentData?.stockAdjustment?.preparedBy?.firstName} ${stockAdjustmentData?.stockAdjustment?.preparedBy?.lastName}`
              : `${stockAdjustmentFormData?.clientAdmin?.firstName} ${stockAdjustmentFormData?.clientAdmin?.lastName}`
          }
          title={orderTypeFilters.orderType[orderTypeWatch]?.name}
          sellerTitle={orderTypeFilters.orderType[orderTypeWatch]?.sellerTitle}
          buyerTitle={orderTypeFilters.orderType[orderTypeWatch]?.buyerTitle}
          shippingTitle={
            orderTypeFilters.orderType[orderTypeWatch]?.shippingTitle
          }
          orderInfo={orderTypeFilters.orderType[orderTypeWatch]?.orderInfo}
          sellerInfo={orderTypeFilters.orderType[orderTypeWatch]?.sellerInfo}
          buyerInfo={orderTypeFilters.orderType[orderTypeWatch]?.buyerInfo}
          shipInfo={orderTypeFilters.orderType[orderTypeWatch]?.shipInfo}
        />
      </Box>
    </>
  );

  const tabs = [
    {
      label:
        orderTypeWatch === "stockTransfer"
          ? stockTransferData?.stockTransfer?.code ?? `Draft`
          : stockAdjustmentData?.stockAdjustment?.code ?? `Draft`,
      Component: OrderReportComp
    }
  ];

  const DrawerComponent = (
    <Grid container rowSpacing={2}>
      {orderTypeFilters.orderType[orderTypeWatch]?.fields?.map(
        (item, index) => (
          <Grid
            container
            rowSpacing={2}
            padding={"20px 15px 2px 10px"}
            key={index}
          >
            {generateMobileFields(item, control)}
          </Grid>
        )
      )}
      <Grid
        xs={12}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        minHeight={"72px"}
        sx={{ background: "#F0F2F5" }}
        mt={2}
      >
        <Button
          variant="contained"
          sx={{ width: "100px", height: "40px" }}
          onClick={hideDrawer}
        >
          {"Next"}
        </Button>
      </Grid>
    </Grid>
  );

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(
    () => {
      if (
        isMobileDevice &&
        Boolean(orderTypeWatch) &&
        !Boolean(orderId) &&
        ((!isFetchingSTFormData && orderTypeWatch === "stockTransfer") ||
          (!isFetchingSAFormData && orderTypeWatch === "stockAdjustments"))
      ) {
        showDrawer({
          title: Boolean(orderId) ? "Manage Order" : "Create Order",
          height: "fit-content",
          sx: { color: "#2773FF", fontWeight: 600 },
          component: DrawerComponent
        });
        if (Boolean(originWarehouseWatch) || Boolean(receivingWarehouseWatch)) {
          showDrawer({
            title: Boolean(orderId) ? "Manage Order" : "Create Order",
            height: "fit-content",
            sx: { color: "#2773FF", fontWeight: 600 },
            component: DrawerComponent
          });
        }
      }
    },
    // eslint-disable-next-line
    [
      JSON.stringify(originWarehouseWatch),
      JSON.stringify(receivingWarehouseWatch),
      orderId,
      isFetchingSTFormData,
      isFetchingSAFormData
    ]
  );
  /* eslint-disable react-hooks/exhaustive-deps */

  return isFetchingSTFormData ||
    isFetchingStockTransferSkus ||
    isFetchingStockTransferData ||
    isCreating ||
    isSavingDraft ||
    isUpdatingDraft ||
    isSubmittingDraft ||
    isFetchingSAFormData ||
    isFetchingStockAdjustmentSkus ||
    isFetchingStockAdjustmentData ||
    isCreatingSA ||
    isSavingSADraft ||
    isUpdatingSADraft ||
    isSubmittingSADraft ? (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={
        isFetchingSTFormData ||
        isFetchingStockTransferSkus ||
        isFetchingStockTransferData ||
        isCreating ||
        isSavingDraft ||
        isUpdatingDraft ||
        isSubmittingDraft ||
        isFetchingSAFormData ||
        isFetchingStockAdjustmentSkus ||
        isFetchingStockAdjustmentData ||
        isCreatingSA ||
        isSavingSADraft ||
        isUpdatingSADraft ||
        isSubmittingSADraft
      }
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : (
    <Box>
      <Grid
        container
        spacing={2}
        sx={{
          display: { xs: "none", md: "block" }
        }}
      >
        <Grid item xs={12}>
          <Typography
            fontSize={20}
            fontWeight={500}
            lineHeight={"24.2px"}
            letterSpacing={"0.2%"}
          >
            {orderId ? "Manage Order" : "Create Order"}
          </Typography>
        </Grid>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12} md={6} lg={9} container spacing={3}>
            <Grid item xs={12} container spacing={3}>
              <CustomFieldRow label={"Order Type"} isDisabled={true}>
                <DropDown
                  control={control}
                  name={"orderType"}
                  options={[
                    {
                      name: "stockTransfer",
                      displayName: "Stock Transfer Order"
                    },
                    {
                      name: "stockAdjustments",
                      displayName: "Stock Adjustment"
                    }
                  ]}
                  placeholder={"Select order type"}
                  disabled={true}
                />
              </CustomFieldRow>
            </Grid>
            {orderTypeFilters.orderType[orderTypeWatch]?.fields?.map((item) =>
              generateFields(item, control, false)
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        rowSpacing={2}
        sx={{
          display: { xs: "flex", md: "none" },
          alignItems: "center",
          padding: "10px 20px"
        }}
      >
        <Grid item xs={8}>
          <DropDown
            control={control}
            name={"orderType"}
            options={[
              {
                name: "stockTransfer",
                displayName: "Stock Transfer Order"
              },
              {
                name: "stockAdjustments",
                displayName: "Stock Adjustment"
              }
            ]}
            placeholder={"Select order type"}
            disabled
          />
        </Grid>
        <Grid item xs={4} display={"flex"} justifyContent={"center"}>
          <IconButton
            size="small"
            sx={{
              backgroundColor: "#F0F2F5"
            }}
            disabled={!Boolean(orderTypeWatch)}
            color={"primary"}
            onClick={() => {
              showDrawer({
                title: Boolean(orderId) ? "Manage Order" : "Create Order",
                height: "fit-content",
                sx: { color: "#2773FF", fontWeight: 600 },
                component: DrawerComponent
              });
            }}
          >
            <TuneOutlined color="inherit" fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
      {orderTypeWatch && !orderId && (
        <Grid item xs={12}>
          {OrderReportComp}
        </Grid>
      )}
      {orderTypeWatch && orderId && (
        <Grid item xs={12}>
          <CustomTabs
            tabs={tabs}
            activeTab={activeTab}
            onChange={handleTabChange}
            headerControls={
              !isMobileDevice && (
                <Box display={"flex"} gap={4}>
                  {allowEdit && isDraft && (
                    <>
                      <Button variant="link" disableRipple onClick={onModify}>
                        Modify
                      </Button>
                      <Button
                        variant="link"
                        disableRipple
                        sx={{ color: "error.main", minWidth: "100px" }}
                        onClick={() => onDeleteClick()}
                      >
                        Delete Draft
                      </Button>
                      <Button
                        variant="link"
                        disableRipple
                        onClick={handlePrint}
                      >
                        Print
                      </Button>
                    </>
                  )}
                  {allowCancel ? (
                    <>
                      {manageOrderType !== "view-only" && (
                        <Button
                          variant="link"
                          disableRipple
                          onClick={onCancel}
                          sx={{ color: "error.main", minWidth: "100px" }}
                        >
                          Cancel Order
                        </Button>
                      )}
                      <Button
                        variant="link"
                        disableRipple
                        onClick={handlePrint}
                      >
                        Print
                      </Button>
                    </>
                  ) : (
                    !allowEdit && (
                      <Button
                        variant="link"
                        disableRipple
                        onClick={handlePrint}
                      >
                        Print
                      </Button>
                    )
                  )}
                </Box>
              )
            }
          />
        </Grid>
      )}
      {orderId && isDraft && (
        <div style={{ display: "none" }}>
          <Box ref={componentRef} sx={{ padding: "10px" }}>
            <Box display={"flex"} flexDirection={"column"} gap={1}>
              <OrderReport
                isDraft={!isDraft}
                title={orderTypeFilters.orderType[orderTypeWatch]?.name}
                sellerTitle={
                  orderTypeFilters.orderType[orderTypeWatch]?.sellerTitle
                }
                buyerTitle={
                  orderTypeFilters.orderType[orderTypeWatch]?.buyerTitle
                }
                shippingTitle={
                  orderTypeFilters.orderType[orderTypeWatch]?.shippingTitle
                }
                orderInfo={
                  orderTypeFilters.orderType[orderTypeWatch]?.orderInfo
                }
                sellerInfo={
                  orderTypeFilters.orderType[orderTypeWatch]?.sellerInfo
                }
                buyerInfo={
                  orderTypeFilters.orderType[orderTypeWatch]?.buyerInfo
                }
                shipInfo={orderTypeFilters.orderType[orderTypeWatch]?.shipInfo}
                actions={orderTypeFilters.orderType[orderTypeWatch]?.actions}
                onAdd={
                  getTableFunctions(
                    orderTypeFilters.orderType[orderTypeWatch]?.name
                  ).onAdd
                }
                onRemove={
                  getTableFunctions(
                    orderTypeFilters.orderType[orderTypeWatch]?.name
                  ).onRemove
                }
                onEdit={
                  getTableFunctions(
                    orderTypeFilters.orderType[orderTypeWatch]?.name
                  ).onEdit
                }
                onSaveDraft={handleSubmit(onSaveDraft)}
                onSubmit={handleSubmit(onSubmit)}
                onModify={onModify}
              >
                {getTable(
                  orderTypeFilters.orderType[orderTypeWatch]?.name,
                  true
                )}
              </OrderReport>
            </Box>
          </Box>
        </div>
      )}
    </Box>
  );
};

export default CreateStockOrder;
