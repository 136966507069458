import { Box, Typography } from "@mui/material";

import { CustomTabs } from "../../shared/customTab/CustomTabs";
import { NAV_LINK } from "../../shared/layouts/SideDrawer";
import appRoutes from "../../constants/appRoutes";
import { useContext } from "react";
import { AppContext } from "../../shared/context/auth-context";
import Customers from "./Customers";
import Quotes from "./Quotes";
import SalesOrders from "./SalesOrders";
import Allocation from "./Allocation";

const Sales = () => {
  const { activeMobileTab, handleMobileTabChange } = useContext(AppContext);

  const componentPath = {
    [appRoutes.sales.customers]: <Customers />,
    [appRoutes.sales.quotes]: <Quotes />,
    [appRoutes.sales.salesOrders]: <SalesOrders />,
    [appRoutes.sales.allocation]: <Allocation />
  };

  const MenuItems = NAV_LINK.find(
    (item) => item.name === "Sale"
  )?.children?.map((menu) => {
    return {
      label: menu.name,
      Component: componentPath[menu.to]
    };
  });

  return (
    <Box
      sx={{
        mb: { xs: 9 }
      }}
    >
      <Typography
        sx={{
          padding: "20px 21px 18px 21px",
          background: "#EEF1F4",
          fontSize: "16px",
          lineHeight: "19.36px",
          fontWeight: 700
        }}
      >
        {"Sales"}
      </Typography>
      <CustomTabs
        tabs={MenuItems}
        activeTab={activeMobileTab.Sales}
        onChange={handleMobileTabChange}
        secondaryTab={true}
      />
    </Box>
  );
};

export default Sales;
