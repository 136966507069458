import { getQuoteFormData } from "../../../sales/services";
import { queryKeys } from "../../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchQuoteFormData = () => {
  const queryQuoteFormData = useQuery(
    [queryKeys.queryFetchQuoteFormData],
    () => getQuoteFormData(),
    {
      refetchOnWindowFocus: false,
      refetchInterval:false
    }
  );

  return queryQuoteFormData;
};
