import { Auth } from "aws-amplify";

export const getToken = async () => {
  try {
    const res = await Auth.currentSession();
    const token = res.getIdToken().getJwtToken();
    return token;
  } catch (error) {
    console.error(error);
    return null;
  }
};
