import { useState } from "react";

export const useAntSwitchState = () => {
  const [isSwitchChecked, setIsSwitchChecked] = useState(false);

  const handleSwitchToggle = () => {
    setIsSwitchChecked(!isSwitchChecked);
  };

  return { isSwitchChecked, handleSwitchToggle };
};
