import { getUpdatedTotalByOutboundDate } from "../../customer/services";
import { queryKeys } from "../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchUpdatedTotal = (orderId, outboundDate) => {
  const queryUpdatedTotal = useQuery(
    [queryKeys.queryFetchUpdatedTotal, orderId],
    () => getUpdatedTotalByOutboundDate(orderId, outboundDate),
    {
      enabled: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true
    }
  );

  return queryUpdatedTotal;
};
