import { getQuoteCustomerBillingByCustomerId } from "../../../sales/services";
import { queryKeys } from "../../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchQuoteCustomerBillingByCustomerId = (customerId) => {
  const queryQuoteFormData = useQuery(
    [queryKeys.queryFetchCustomerBillingByCustomerId,customerId],
    () => getQuoteCustomerBillingByCustomerId(customerId),
    {
      enabled: Boolean(customerId),
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return queryQuoteFormData;
};
