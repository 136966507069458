import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";

import InquiryCart from "./InquiryCart";
import { KeyboardArrowRight } from "@mui/icons-material";
import React from "react";
import { StyledChip } from "../../../shared/UiElements/StatusChip";
import moment from "moment";
import { rfqServiceKeys } from "../../../constants/formKeys";
import { stringCapitalization } from "../../../shared/utils";
import { useDrawer } from "../../../shared/customSideDrawer";

export const InquiryMobileCard = (row) => {
  const { showDrawer, hideDrawer } = useDrawer();
  const onViewClick = (inquiryParams, service) => {
    showDrawer({
      component: (
        <InquiryCart
          inquiryDate={inquiryParams.inquiryDate}
          inquiryId={inquiryParams.id}
          rfqId={
            service === rfqServiceKeys.transportRfq && "subRow" in inquiryParams
              ? inquiryParams.subRow[0][service]
              : inquiryParams[service]
          }
          hideDrawer={hideDrawer}
          service={service}
          code={inquiryParams.code}
        />
      ),
      closeIcon: false
    });
  };
  return (
    <Grid container spacing={"20px"}>
      <Grid item xs={12} container>
        <Grid item xs={6}>
          <Typography
            fontSize={13}
            fontWeight={500}
            lineHeight={"18px"}
            color={"text.secondary"}
          >
            {row.code}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography
            fontSize={13}
            fontWeight={500}
            lineHeight={"18px"}
            color={"text.secondary"}
          >
            {moment(row.inquiryDate).format("DD MMMM YYYY")}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} container>
        <Grid item xs={6}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={0.5}
            alignItems={"flex-start"}
          >
            <Typography
              fontSize={13}
              fontWeight={500}
              lineHeight={"18px"}
              color={"text.primary"}
            >
              {row.serviceType}
            </Typography>
            <Typography
              fontSize={12}
              lineHeight={"16px"}
              color={"text.secondary"}
            >
              {row.lastRespondedAt ? moment(row.lastRespondedAt).fromNow() : ""}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"flex-start"}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={0.5}
              alignItems={"flex-start"}
            >
              <StyledChip
                label={stringCapitalization(
                  row.status === "Cancelled" ? row.status : row.responseStatus
                )}
                variant="text"
              />
              <Typography
                fontSize={12}
                lineHeight={"16px"}
                color={"text.disabled"}
              >
                Status
              </Typography>
            </Box>
            <IconButton
              color="primary"
              disableRipple
              sx={{ p: 0 }}
              onClick={() =>
                onViewClick(
                  row,
                  row.serviceType.includes("Warehouse")
                    ? rfqServiceKeys.warehouseRfq
                    : rfqServiceKeys.transportRfq
                )
              }
            >
              <KeyboardArrowRight />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      {row?.subRow?.length &&
        row?.subRow?.map((item, index) => (
          <>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} container>
              <Grid item xs={6}>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  gap={0.5}
                  alignItems={"flex-start"}
                >
                  <Typography
                    fontSize={13}
                    fontWeight={500}
                    lineHeight={"18px"}
                    color={"text.primary"}
                  >
                    {item?.serviceType}
                  </Typography>
                  <Typography
                    fontSize={12}
                    lineHeight={"16px"}
                    color={"text.secondary"}
                  >
                    {item?.lastRespondedAt
                      ? moment(item?.lastRespondedAt).fromNow()
                      : ""}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                >
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    gap={0.5}
                    alignItems={"flex-start"}
                  >
                    <StyledChip
                      label={
                        item?.status === "Cancelled"
                          ? item?.status
                          : item?.responseStatusName
                      }
                      variant="text"
                    />
                    <Typography
                      fontSize={12}
                      lineHeight={"16px"}
                      color={"text.disabled"}
                    >
                      Status
                    </Typography>
                  </Box>
                  <IconButton
                    color="primary"
                    disableRipple
                    sx={{ p: 0 }}
                    onClick={() =>
                      onViewClick(row, rfqServiceKeys.transportRfq)
                    }
                  >
                    <KeyboardArrowRight />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </>
        ))}
    </Grid>
  );
};
