import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { appConstants, order_table_labels } from "../../constants";
import { dateTimeFormatter, stringCapitalization } from "../../shared/utils";

import CustomTable from "../../shared/customTable";
import RetrieveMobileCard from "../components/MyOrders/RetrieveMobileCard";
import { StyledChip } from "../../shared/UiElements/StatusChip";
import { useEffect } from "react";
import { useFetchRetrieves } from "../../query-hooks/Retrieve/useFetchRetrieves";
import { useInView } from "react-intersection-observer";

const getColumns = (onButtonClick) => {
  return [
    {
      Header: "Order ID",
      accessor: "orderId",
      Cell: ({ value }) => {
        return (
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 600
            }}
          >
            {value}
          </Typography>
        );
      }
    },
    {
      Header: "Partner details",
      accessor: "partnerName"
    },
    {
      Header: "No of packages",
      accessor: "packagingNote",
      Cell: ({ value }) => {
        return (
          <p>
            {value.retrieveCount}/<b>{value.totalCount}</b>
          </p>
        );
      }
    },
    {
      Header: "Outbound date",
      accessor: "outboundDate",
      Cell: ({ value }) => {
        return dateTimeFormatter(value, appConstants.fullFormat);
      }
    },
    {
      Header: "Retrieve date",
      accessor: "retrieveDate",
      Cell: ({ value }) => {
        return dateTimeFormatter(value, appConstants.fullFormat);
      }
    },
    {
      Header: "Status",
      accessor: "status",
      width: 180,
      Cell: ({ value }) => {
        return <StyledChip label={stringCapitalization(value)} />;
      }
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ value, cell }) => {
        const row = cell.row.original;
        return (
          <Button
            variant="link"
            disableRipple
            onClick={() => onButtonClick(row?.status)}
            sx={{ minWidth: "auto" }}
          >
            {row?.status === "GatePassRequested"
              ? order_table_labels.view
              : order_table_labels.downloadGatepass}
          </Button>
        );
      }
    }
  ];
};

const getRows = (rows = []) => {
  return rows.map((row) => ({
    id: row?._id,
    orderId: row?.order?.warehouseOrder?.code,
    partnerName: row?.warehouse?.name,
    outboundDate: row?.order?.recentOrderItem?.outboundDate,
    retrieveDate: row?.shippingDate,
    status: row?.status?.name,
    packagingNote: row?.packagingNote
  }));
};

const Retrieve = () => {
  const onButtonClick = (type) => {};

  const { data, isFetched, hasNextPage, fetchNextPage } = useFetchRetrieves();
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, inView]);

  return (
    <Box
      sx={{
        padding: {
          xs: "0 16px 16px 16px",
          md: 0
        }
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <CustomTable
            searchPlaceholderText={order_table_labels.searchPlaceholderText}
            columns={getColumns(onButtonClick)}
            data={
              isFetched
                ? data.pages.flatMap((page) => getRows(page?.retrieves))
                : []
            }
            mobileComponent={RetrieveMobileCard}
            isLoading={!isFetched}
          />
        </Grid>
        {hasNextPage && (
          <Grid
            item
            xs={12}
            ref={ref}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <CircularProgress />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Retrieve;