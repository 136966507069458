import { getUpdatedSkuFormData } from "../../inventory/services/index";
import { queryKeys } from "../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchUpdatedSkuFormData = (skuId) => {
  const querySkuFormData = useQuery(
    [queryKeys.queryFetchUpdatedSkuFormData, skuId],
    () => getUpdatedSkuFormData(skuId),
    {
      enabled: Boolean(skuId),
      refetchOnWindowFocus: false
    }
  );

  return querySkuFormData;
};
