import { Grid } from "@mui/material";
import React from "react";
import DropDown from "../../shared/formElements/DropDown";
import OrderAmountCard from "./OrderAmountCard";
import { useForm } from "react-hook-form";
import DateInput from "../../shared/formElements/DateInput";
import { CalendarTodayOutlined } from "@mui/icons-material";

const dummy_data = [
  {
    Title: "Sales",
    Label1: "Orders",
    Label2: "Dispatches",
    Label3: "Returns",
    Amount1: 0,
    Amount2: 0,
    Amount3: 0
  },
  {
    Title: "Purchases",
    Label1: "Orders",
    Label2: "Received",
    Label3: "Returns",
    Amount1: 0,
    Amount2: 0,
    Amount3: 0
  },
  {
    Title: "Receivables",
    Label1: "Dues",
    Label2: "Overdues",
    Label3: "Collections",
    Amount1: 0,
    Amount2: 0,
    Amount3: 0
  },
  {
    Title: "Payables",
    Label1: "Dues",
    Label2: "Overdues",
    Label3: "Payments",
    Amount1: 0,
    Amount2: 0,
    Amount3: 0
  }
];

const OrderTypeCards = () => {
  const { control, watch } = useForm({
    defaultValues: { filterDate: "today" }
  });
  const watchFilter = watch("filterDate");
  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          xs={watchFilter !== "custom" ? 8 : 4}
          md={watchFilter !== "custom" ? 9.5 : 7}
        ></Grid>
        {watchFilter === "custom" && (
          <Grid item xs={4} md={2.5}>
            <DateInput
              name="customDateRange"
              control={control}
              selectsRange={true}
              minDate={new Date("2000-01-01")}
              endIcon={CalendarTodayOutlined}
              placeholder="Select date range"
              format="dd-MM-yyyy"
              popperPlacement="bottom-start"
            />
          </Grid>
        )}
        <Grid item xs={4} md={2.5} display="flex" justifyContent="flex-end">
          <DropDown
            control={control}
            name={"filterDate"}
            placeholder={"Select Filter"}
            options={[
              { name: "today", displayName: "Today" },
              { name: "Yesterday", displayName: "Yesterday" },
              { name: "thisWeek", displayName: "This Week" },
              { name: "lastWeek", displayName: "Last Week" },
              { name: "thisMonth", displayName: "This Month" },
              { name: "lastMonth", displayName: "Last Month" },
              { name: "custom", displayName: "Custom" }
            ]}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} sx={{ mt: 1 }}>
        {dummy_data.map((item, index) => {
          return (
            <Grid item xs={6} sm={6} md={3} key={index}>
              <OrderAmountCard {...item} />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default OrderTypeCards;
