import { queryKeys } from "../../../constants/queryKeys";
import { getQuoteById } from "../../../sales/services";
import { useQuery } from "@tanstack/react-query";

export const useFetchQuoteById = (orderId) => {
  const quoteById = useQuery(
    [queryKeys.queryFetchQuoteById, orderId],
    () => getQuoteById(orderId),
    {
      enabled: Boolean(orderId),
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return quoteById;
};
