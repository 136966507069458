import { queryKeys } from "../../../constants/queryKeys";
import { getBillingAddresses } from "../../../sales/services";
import { useQuery } from "@tanstack/react-query";

export const useFetchBillingAddresses = (customerId) => {
  const billings = useQuery(
    [queryKeys.queryFetchBillingAddresses,customerId],
    () => getBillingAddresses(customerId),
    {
      enabled: Boolean(customerId),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return billings;
};
