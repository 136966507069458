import { ChevronRightOutlined, ExpandMoreOutlined } from "@mui/icons-material";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ThemeProvider
} from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

import { collapseMenuTheme } from "../theme/drawerTheme";

export function NavLinkItem({
  to,
  from,
  name,
  icon,
  isProfileScreen,
  children
}) {
  const location = useLocation();
  const [subMenuOpen, setSubMenuOpen] = useState(false);

  const Icon = icon;

  const isActive = (link) => {
    return location.pathname.includes(link);
  };

  useEffect(() => {
    if (isActive(to || from)) {
      setSubMenuOpen(true);
    } else {
      setSubMenuOpen(false);
    } // eslint-disable-next-line
  }, [to, from, location.pathname]);

  const handleSubMenuToggle = () => {
    setSubMenuOpen(!subMenuOpen);
  };

  return (
    <>
      <ListItemButton
        {...(from
          ? {}
          : {
              component: RouterLink,
              to: to
            })}
        selected={isActive(to || from)}
        onClick={children ? handleSubMenuToggle : null}
        sx={{ margin: 1, borderRadius: 2, mx: isProfileScreen ? 6 : 1 }}
      >
        {!isProfileScreen && (
          <ListItemIcon>
            <Icon
              sx={{
                color: isActive(to || from) ? "#2773FF" : "#0F2744",
                fontSize: 20
              }}
            />
          </ListItemIcon>
        )}
        <ListItemText primary={name} />
        {Boolean(children) &&
          (subMenuOpen ? <ExpandMoreOutlined /> : <ChevronRightOutlined />)}
      </ListItemButton>
      {children && (
        <ThemeProvider theme={collapseMenuTheme}>
          <Collapse in={subMenuOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {children.map((item) => (
                <ListItemButton
                  key={item.to}
                  component={RouterLink}
                  to={`${from}/${item.to}`}
                  selected={isActive(`${from}/${item.to}`)}
                >
                  <ListItemText primary={item.name} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        </ThemeProvider>
      )}
    </>
  );
}
