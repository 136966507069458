import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";
import { KeyboardArrowRight } from "@mui/icons-material";
import { useDrawer } from "../../../shared/customSideDrawer";
import { useNavigate } from "react-router-dom";
import { getCustomCell } from "../../pages/PurchaseOrders";
import appRoutes from "../../../constants/appRoutes";
import { stringCapitalization } from "../../../shared/utils";
import { StyledChip } from "../../../shared/UiElements/StatusChip";
import { CustomTooltip } from "../../../shared/theme/globalTheme";
import Ledger from "../../../assets/Ledger.webp";
import POTransactions from "../../pages/POTransactions";

export const MobilePurchaseOrders = (row) => {
  const { showDrawer, hideDrawer } = useDrawer();
  const navigate = useNavigate();

  const onLedgerClick = (orderId) => {
    showDrawer({
      height: "fit-content",
      component: <POTransactions hideDrawer={hideDrawer} orderId={orderId} />,
      closeIcon: false
    });
  };

  return (
    <Grid container spacing={"20px"}>
      <Grid item xs={12} container rowSpacing={2}>
        <Grid item xs={6}>
          {getCustomCell(
            row?.docNo,
            "",
            row.orderTableType === "Invoice"
              ? `/Order/Purchase-Order/${row?.orderId}`
              : `${appRoutes.purchase.main}/${appRoutes.purchase.purchaseOrders}/${row?.orderId}/purchaseOrder`
          )}
        </Grid>
        <Grid item xs={6} display={"flex"} justifyContent={"right"}>
          <Typography
            sx={{ fontSize: 14, lineHeight: "20px", fontWeight: 400 }}
          >
            {row.orderDate}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Box display={"flex"} flexDirection={"column"} gap={0.5}>
            <Typography fontSize={14} lineHeight={"16.94px"} fontWeight={400}>
              {row?.amount}
            </Typography>
            <Typography
              fontSize={12}
              lineHeight={"16px"}
              color={"text.disabled"}
            >
              {"PO Value"}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            justifyContent: "right"
          }}
        >
          <IconButton
            color="primary"
            disabled={
              row?.orderProgress === "Draft" || row?.orderProgress === "Placed"
            }
            onClick={() =>
              row?.orderProgress !== "Draft" &&
              row?.orderProgress !== "Placed" &&
              navigate(
                `/${"Document"}/${
                  row.orderTableType
                }/Material-Received-Report/${row?.orderId}`
              )
            }
          >
            <KeyboardArrowRight />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={6}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={0.5}
            alignItems={"flex-start"}
          >
            <StyledChip
              label={stringCapitalization(row.orderProgress)}
              variant="text"
            />
            <Typography
              fontSize={12}
              lineHeight={"16px"}
              color={"text.disabled"}
            >
              Status
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} display={"flex"} justifyContent={"right"}>
          {row?.orderProgress !== "Draft" &&
            row?.orderProgress !== "Placed" && (
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={1}
              >
                <Typography fontSize={14}>
                  {Boolean(row.avgDue) ? row.avgDue : "--"}
                </Typography>
                <CustomTooltip title={"Ledger"} placement="top">
                  <IconButton onClick={() => onLedgerClick(row.orderId)}>
                    <img
                      src={Ledger}
                      className="pull-right cursor-field"
                      alt={"value"}
                    />
                  </IconButton>
                </CustomTooltip>
              </Box>
            )}
        </Grid>
      </Grid>
      {Boolean(row?.subRow?.length) &&
        row?.subRow?.map((item, index) => (
          <Grid item xs={12} container rowSpacing={2} key={index}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6}>
              {getCustomCell(
                item?.returnCode,
                "",
                row.orderTableType === "Invoice"
                  ? `/Order/Purchase-Order/${row?.orderId}/Purchase-Return/${item?.returnId}`
                  : `${appRoutes.purchase.main}/${appRoutes.purchase.purchaseOrders}/${row?.orderId}/purchaseReturns/${item?.returnId}`
              )}
            </Grid>
            <Grid item xs={6} display={"flex"} justifyContent={"right"}>
              <Typography
                sx={{ fontSize: 14, lineHeight: "20px", fontWeight: 400 }}
              >
                {item?.returnOrderDate}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Box display={"flex"} flexDirection={"column"} gap={0.5}>
                <Typography
                  fontSize={14}
                  lineHeight={"16.94px"}
                  fontWeight={400}
                >
                  {item?.amount}
                </Typography>
                <Typography
                  fontSize={12}
                  lineHeight={"16px"}
                  color={"text.disabled"}
                >
                  {"PR Value"}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "right"
              }}
            >
              <IconButton
                color="primary"
                disabled={
                  item?.returnOrderStatus === "Draft" ||
                  item?.returnOrderStatus === "Placed"
                }
                onClick={() =>
                  item?.returnOrderStatus !== "Draft" &&
                  item?.returnOrderStatus !== "Placed" &&
                  navigate(
                    `/${"Document"}/${row.orderTableType}/Gate-Pass/${
                      item?.returnId
                    }`
                  )
                }
              >
                <KeyboardArrowRight />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={0.5}
                alignItems={"flex-start"}
              >
                <StyledChip
                  label={stringCapitalization(item?.returnOrderStatus)}
                  variant="text"
                />
                <Typography
                  fontSize={12}
                  lineHeight={"16px"}
                  color={"text.disabled"}
                >
                  Status
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} display={"flex"} justifyContent={"right"}>
              {row?.orderProgress !== "Draft" &&
                row?.orderProgress !== "Placed" && (
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    gap={1}
                  >
                    <Typography fontSize={14}>
                      {Boolean(item?.avgDue) ? item?.avgDue : "--"}
                    </Typography>
                    <CustomTooltip title={"Ledger"} placement="top">
                      <IconButton onClick={() => onLedgerClick(row.orderId)}>
                        <img
                          src={Ledger}
                          className="pull-right cursor-field"
                          alt={"value"}
                        />
                      </IconButton>
                    </CustomTooltip>
                  </Box>
                )}
            </Grid>
          </Grid>
        ))}
    </Grid>
  );
};
