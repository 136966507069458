import { getTransportByPartnerId } from "../../customer/services";
import { queryKeys } from "../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchTransportByPartnerId = (params, partnerId) => {
  const queryTransportByPartnerId = useQuery(
    [queryKeys.queryFetchTransportByPartnerId, Boolean(params), partnerId],
    () => getTransportByPartnerId(params, partnerId),
    {
      enabled: Boolean(params) && Boolean(partnerId),
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true
    }
  );

  return queryTransportByPartnerId;
};
