import { useEffect, useState } from "react";
import { labels } from "../../constants";
import { Button, Link } from "@mui/material";

export const CountdownTimer = ({ minutes = 1, seconds = 0, resendOTP }) => {
  const [timeLeft, setTimeLeft] = useState({
    minutes: minutes,
    seconds: seconds,
  });
  useEffect(() => {
    const timer =
      timeLeft.minutes === 0 && timeLeft.seconds === 0
        ? undefined
        : setInterval(() => {
            if (timeLeft.seconds === 0) {
              setTimeLeft({ minutes: timeLeft.minutes - 1, seconds: 59 });
            } else {
              setTimeLeft({ ...timeLeft, seconds: timeLeft.seconds - 1 });
            }
          }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  const timeout = timeLeft.minutes || timeLeft.seconds ? true : false;
  return (
    <>
      {timeout ? (
        <>
          {labels.resentOtpIn + " in :"}
          <Link underline="none" px={1}>
            {timeLeft.minutes < 10 ? `0${timeLeft.minutes}` : timeLeft.minutes}:
            {timeLeft.seconds < 10 ? `0${timeLeft.seconds}` : timeLeft.seconds}
          </Link>
        </>
      ) : (
        <Button variant="text" size="small" onClick={resendOTP}>
          {labels.resentOtpIn}
        </Button>
      )}
    </>
  );
};
