import { getInvoices } from "../../finance/services";
import { queryKeys } from "../../constants/queryKeys";
import { useInfiniteQuery } from "@tanstack/react-query";

export const useFetchAllInvoices = (params, orderType) => {
  const getAllInvoices = useInfiniteQuery(
    [queryKeys.queryFetchAllInvoices, orderType, JSON.stringify(params)],
    ({ pageParam = 1 }) => getInvoices(params, orderType, pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage =
          lastPage?.invoices.length === lastPage?.pagination.limit
            ? allPages.length + 1
            : undefined;

        return nextPage;
      },
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true
    }
  );

  return getAllInvoices;
};
