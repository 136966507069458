import React from "react";
import MobileOrdersPage from "../../warehouse/components/MobileOrdersPage";
import { Box } from "@mui/material";
import { labelKeys } from "./label-keys";

const getMobileOrderInfo = (info) =>
  Object.keys(info).map((key) => ({
    label: `${labelKeys[key]} :`,
    value: info[key]
  }));

const getAccordianAddress = (data) => {
  const {
    sellerTitle,
    sellerInfo,
    buyerTitle,
    buyerInfo,
    shippingTitle,
    shipInfo
  } = data;

  const formatInfo = (title, info) => ({
    title: `${title} Address`,
    name: info?.customerName || "",
    addressLine1: info?.companyName || "",
    addressLine2: info?.address || "",
    addressLine3: info?.gst || ""
  });

  const addressArray = [];

  if (sellerTitle) {
    addressArray.push(formatInfo(sellerTitle, sellerInfo));
  }

  if (buyerTitle) {
    addressArray.push(formatInfo(buyerTitle, buyerInfo));
  }

  if (shippingTitle) {
    addressArray.push(formatInfo(shippingTitle, shipInfo));
  }

  return addressArray;
};

const MobileOrderReport = ({
  title,
  status,
  mobileTable,
  remarksComp,
  preparedBy,
  otherInfo,
  Total,
  ButtonGroup,
  orderInfo,
  sellerInfo,
  sellerTitle,
  buyerInfo,
  buyerTitle,
  shippingTitle,
  shipInfo,
  showOtherDetails = true,
  hideTotal = false,
  showAttachments = false,
  Attachments
}) => {
  return (
    <Box sx={{ display: { xs: "block", md: "none" }, px: "15px", mb: 9 }}>
      <MobileOrdersPage
        showOtherDetails={showOtherDetails}
        showAttachments={showAttachments}
        hideTotal={hideTotal}
        Status={status}
        title={title}
        docInfo={getMobileOrderInfo(orderInfo)}
        accordianInfo={getAccordianAddress({
          sellerTitle,
          sellerInfo,
          buyerTitle,
          buyerInfo,
          shippingTitle,
          shipInfo
        })}
        otherInfo={otherInfo}
        mobileTable={mobileTable}
        Attachments={Attachments}
        Total={Total}
        Remarks={remarksComp}
        preparedBy={preparedBy}
        ButtonGroup={ButtonGroup}
      />
    </Box>
  );
};

export default MobileOrderReport;
