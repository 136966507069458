import { Box, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import {
  CustomFieldRow,
  generateFields
} from "../../purchase/pages/CreateOrder";
import DropDown from "../../shared/formElements/DropDown";
import { useFieldArray, useForm } from "react-hook-form";
import MOCK_DATA from "../../sales/mockData.json";
import FormJson from "../pages/CreateInvoiceFormConfig.json";
import InvoiceTab from "../components/InvoiceTab";
import CreditNoteTab from "../components/CreditNoteTab";
import DebitNoteTab from "../components/DebitNoteTab";

const CreateInvoice = () => {
  const { control, watch } = useForm({
    defaultValues: {
      invoiceType: "",
      newInvoice: []
    }
  });

  const { fields, append } = useFieldArray({
    control,
    name: "newInvoice"
  });

  const invoiceTypeWatch = watch("invoiceType");

  useEffect(() => {
    append({ ...MOCK_DATA.orders[0] });
  }, [append]);

  const getTable = (type) => {
    switch (type) {
      case "Invoice":
        return <InvoiceTab fields={fields} control={control}/>;
      case "Credit Note":
        return <CreditNoteTab fields={fields} control={control}/>;
      case "Debit Note":
        return <DebitNoteTab fields={fields} control={control}/>;
      default:
        return <>Not Found</>;
    }
  };

  return (
    <Box
      sx={{
        padding: {
          xs: "0 16px 16px 16px",
          md: 0
        }
      }}
    >
      <Divider sx={{ border: "0.7px solid #B7BEC7"}} />
      <Grid container spacing={2} pt={2}>
        <Grid item xs={12}>
          <Typography
            fontSize={20}
            fontWeight={500}
            lineHeight={"24.2px"}
            letterSpacing={"0.2%"}
          >
            {FormJson.invoiceType[invoiceTypeWatch]?.name === "Invoice"
              ? "Create Invoice"
              : "Create"}
          </Typography>
        </Grid>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12} md={6} lg={9} container spacing={3}>
            <Grid item xs={12} container spacing={3}>
              <CustomFieldRow label={"Doc Type"}>
                <DropDown
                  control={control}
                  name={"invoiceType"}
                  options={FormJson.invoiceTypeOptions}
                  placeholder={"Select Doc type"}
                />
              </CustomFieldRow>
            </Grid>
            {FormJson.invoiceType[invoiceTypeWatch]?.fields?.map((item) =>
              generateFields(item, control)
            )}
          </Grid>
          {invoiceTypeWatch && (
            <Grid item xs={12}>
              <Box display={"flex"} flexDirection={"column"} gap={1} pb={10}>
                {getTable(FormJson.invoiceType[invoiceTypeWatch]?.name)}
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateInvoice;
