import { client } from "../../shared/api/client";

export const getPublicListings = async (params) => {
  return await client
    .get(`/public/partners/search`, {
      params: { ...params }
    })
    .then((res) => res.data.data);
};

export const getListings = async (params, page) => {
  return await client
    .get(`/partners/search`, {
      params: { ...params, page: page }
    })
    .then((res) => res.data.data);
};

export const getPublicWarehouses = async (params) => {
  return await client
    .get(`/public/partners/search/warehouses`, {
      params: { ...params }
    })
    .then((res) => res.data.data);
};

export const getWarehouses = async (params, page) => {
  return await client
    .get(`/partners/search/warehouses`, {
      params: { ...params, page: page }
    })
    .then((res) => res.data.data);
};

export const getPublicTransporters = async (params) => {
  return await client
    .get(`/public/partners/search/transports`, {
      params: { ...params }
    })
    .then((res) => res.data.data);
};

export const getTransporters = async (params, page) => {
  return await client
    .get(`/partners/search/transports`, {
      params: { ...params, page: page }
    })
    .then((res) => res.data.data);
};

export const getActiveOrders = async (page = 1) => {
  return await client
    .get(`/customers/orders/active?page=${page}`)
    .then((res) => res.data.data);
};

export const getPastOrders = async (page = 1) => {
  return await client
    .get(`/customers/orders/past?page=${page}`)
    .then((res) => res.data.data);
};

export const getOrderDetails = async (orderId) => {
  return await client
    .get(`/customers/orders/${orderId}`)
    .then((res) => res.data.data);
};

export const modifyOrderOutBoundDate = async (payload) => {
  return await client.put(`/customers/orders/${payload.orderId}`, {
    outboundDate: payload.outboundDate
  });
};

export const getUpdatedTotalByOutboundDate = async (orderId, outboundDate) => {
  return await client
    .get(`/customers/orders/${orderId}/calculate-modify`, {
      params: {
        outboundDate
      }
    })
    .then((res) => res.data.data);
};

export const getActiveInquiry = async (page = 1) => {
  return await client
    .get(`/customers/inquiries/active?page=${page}`)
    .then((res) => {
      return res.data.data;
    });
};

export const getClosedInquiry = async (page = 1) => {
  return await client
    .get(`/customers/inquiries/closed?page=${page}`)
    .then((res) => {
      return res.data.data;
    });
};

export const getWarehouseByPartnerId = async (params, partnerId) => {
  return await client
    .get(`/partners/search/warehouses/${partnerId}`, {
      params: { ...params }
    })
    .then((res) => res.data.data);
};

export const getTransportByPartnerId = async (params, partnerId) => {
  return await client
    .get(`/partners/search/transports/${partnerId}`, {
      params: { ...params }
    })
    .then((res) => res.data.data);
};

export const getCustomerRetrieves = async (page = 1) => {
  return await client
    .get(`/customers/retrieves?page=${page}`)
    .then((res) => res.data.data);
};

export const getAllDocuments = async (page = 1) => {
  return await client
    .get(`/customers/documents?page=${page}`)
    .then((res) => res.data.data);
};

export const getInvoiceById = async (invoiceId) => {
  return await client
    .get(`/customers/invoices/${invoiceId}`)
    .then((res) => res.data.data);
};

export const createNewInquiry = async (data) => {
  return await client.post(`/customers/inquiries`, { ...data });
};

export const getNotifications = async (page = 1) => {
  return await client
    .get(`/notifications?page=${page}`)
    .then((res) => res.data.data);
};

export const readOneNotification = async (data) => {
  return await client.put(`/notifications/${data.notificationId}/read`, null);
};

export const readAllNotification = async () => {
  return await client.put(`/notifications/read`, null);
};

export const getRfq = async (inquiryId, rfqId) => {
  return await client
    .get(`/customers/inquiries/${inquiryId}/rfqs/${rfqId}`)
    .then((res) => res.data.data);
};

export const getRfqCancelFormData = async (inquiryId, rfqId) => {
  return await client
    .get(`/customers/inquiries/${inquiryId}/rfqs/${rfqId}/cancel/form-data`)
    .then((res) => res.data.data.reasons);
};

export const RfqCancel = async (inquiryId, rfqId, data) => {
  return await client.put(
    `/customers/inquiries/${inquiryId}/rfqs/${rfqId}/cancel`,
    data
  );
};

export const getPartnerDetails = async (partnerId) => {
  return await client.get(`/partners/${partnerId}`).then((res) => res.data);
};

export const createOrder = async (data) => {
  return await client.post(`/customers/orders`, { ...data });
};

export const createInquiryOrder = async (payload) => {
  return await client.post(
    `/customers/inquiries/${payload.inquiryId}/orders`,
    payload.data
  );
};

export const completeOrder = async (payload) => {
  return await client.post(
    `/customers/orders/${payload.orderId}/payments/${payload.paymentId}/complete`,
    {
      status: payload.status,
      ...{ ...(payload.status ? { msg: { ...payload.data } } : {}) }
    }
  );
};

export const getCreateFormData = async (params) => {
  return await client
    .get(`/customers/orders/form-data`, {
      params: { ...params }
    })
    .then((res) => res.data.data);
};

export const getPackingNotesCreateFormData = async (orderId) => {
  return await client
    .get(`/customers/orders/${orderId}/packaging-notes/form-data`)
    .then((res) => res.data.data);
};

export const getAllPackingNotes = async (orderId) => {
  return await client
    .get(`/customers/orders/${orderId}/packaging-notes`)
    .then((res) => res.data.data);
};

export const savePackingNotes = async (payload) => {
  return await client.post(
    `/customers/orders/${payload.orderId}/packaging-notes`,
    payload.data
  );
};

export const retrieveAll = async (payload) => {
  return await client.post("/customers/retrieves", payload);
};
