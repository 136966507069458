// utils/loadGoogleMapsScript.js

let googleMapsScriptLoaded = false;

export const loadGoogleMapsScript = (callback) => {
  if (googleMapsScriptLoaded) {
    callback();
    return;
  }

  const existingScript = document.querySelector("#google-maps");

  if (existingScript) {
    existingScript.addEventListener("load", callback);
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", "google-maps");
  script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&libraries=places`;
  
  script.addEventListener("load", () => {
    googleMapsScriptLoaded = true;
    callback();
  });

  document.head.appendChild(script);
};
