import * as yup from "yup";

import { Button, Grid, IconButton, Typography } from "@mui/material";
import { action_labels, error_msg, labels } from "../../constants";
import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import CartItemCard from "./CartItemCard";
import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Storage from "../../shared/utils/Storage";
import { StorageKeys } from "../../constants/storage-keys";
import SuccessfulDialog from "../../shared/UiElements/SuccessfulDialog";
import TextInput from "../../shared/formElements/TextInput";
import appRoutes from "../../constants/appRoutes";
import { enqueueSnackbar } from "notistack";
import { isMobileDevice } from "../../App";
import moment from "moment";
import { primaryBackground } from "../../shared/theme/globalTheme";
import { rfqServiceKeys, SearchFormKeys } from "../../constants/formKeys";
import { useCreateInquiryHook } from "../../query-hooks/Inquiry/useCreateInquiryHook";
import { useCustomDialog } from "../../shared/customDialog";
import { useDrawer } from "../../shared/customSideDrawer";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import TextArea from "../../shared/formElements/TextArea";
import { useAuth } from "../../shared/hooks/useAuth";
import { vehicleWeights } from "../pages/Explore";

function isObjectEmpty(obj) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false; // Object is not empty
    }
  }
  return true; // Object is empty
}

function areAllObjectsEmpty(arr) {
  return arr.every(isObjectEmpty);
}

const steps = ["Select Warehouses", "Select Transporters"];

const digitRegex = /^[0-9]\d*(\.\d+)?$/;

const completeFormSchema = yup.object({
  [rfqServiceKeys.warehousePriceExpectation]: yup
    .string()
    .required(error_msg.required),
  // .matches(digitRegex, error_msg.onlyDigits),
  [rfqServiceKeys.transportPriceExpectation]: yup
    .string()
    .required(error_msg.required)
  // .matches(digitRegex, error_msg.onlyDigits)
});

const warehouseFormSchema = yup.object({
  [rfqServiceKeys.warehousePriceExpectation]: yup
    .string()
    .required(error_msg.required)
    .matches(digitRegex, error_msg.onlyDigits),
  [SearchFormKeys.ItemDescription]: yup.string().required(error_msg.required)
});

const transportFormSchema = yup.object({
  [rfqServiceKeys.transportPriceExpectation]: yup
    .string()
    .required(error_msg.required)
    .matches(/^[0-9]\d*(\.\d+)?$/, error_msg.onlyDigits),
  [SearchFormKeys.ItemDescription]: yup.string().required(error_msg.required)
});

const HorizontalLinearStepper = ({
  activeStep,
  completedList,
  handleStepClick
}) => {
  return (
    <Box sx={{ width: "100%", mb: 3 }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step
              key={label}
              {...stepProps}
              completed={completedList[index]}
              onClick={() => handleStepClick(index)}
            >
              <StepLabel {...labelProps} sx={{}}>
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};

const SendRFQ = ({
  sendRfqObj,
  service,
  updateTabs,
  resetObject,
  closeDrawer,
  onRemove,
  checkIsLoggedIn
}) => {
  const { currentUser } = useAuth();
  const serviceLocalStorage = Storage.getItem(StorageKeys.serviceLocalStorage);
  const localSearchFormData = Storage.getItem(StorageKeys.SEARCH_FORM_DATA);

  const checkBothService =
    serviceLocalStorage.transport && serviceLocalStorage.warehouse;
  const { hideDrawer } = useDrawer();
  const { showDialog, hideDialog } = useCustomDialog();
  const navigate = useNavigate();
  const [selectedStep, setSelectedStep] = useState(service);
  const [activeStep, setActiveStep] = useState(
    service === rfqServiceKeys.warehouse ? 0 : 1
  );

  const rfqList = [
    ...sendRfqObj[selectedStep],
    ...Array(3 - sendRfqObj[selectedStep]?.length).fill({})
  ];

  const {
    control,
    handleSubmit,
    register,
    unregister,
    trigger,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(
      checkBothService
        ? completeFormSchema
        : serviceLocalStorage.warehouse
        ? warehouseFormSchema
        : transportFormSchema
    )
  });

  const {
    mutate: createInquiry,
    isLoading,
    error,
    isError
  } = useCreateInquiryHook();

  useEffect(() => {
    if (checkBothService) unregister(rfqServiceKeys.transportPriceExpectation);
  }, [checkBothService, unregister]);

  useEffect(() => {
    if (selectedStep === rfqServiceKeys.warehouse) {
      register(rfqServiceKeys.warehousePriceExpectation);
      unregister(rfqServiceKeys.transportPriceExpectation, { keepValue: true });
    } else {
      register(rfqServiceKeys.transportPriceExpectation);
    }
  }, [register, unregister, selectedStep]);

  const handleNext = async () => {
    await trigger(rfqServiceKeys.warehousePriceExpectation).then((isValid) => {
      if (areAllObjectsEmpty(rfqList)) {
        enqueueSnackbar("Please select at least one warehouse", {
          variant: "error"
        });
      }
      if (isValid && !areAllObjectsEmpty(rfqList)) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSelectedStep(rfqServiceKeys.transport);
        updateTabs(activeStep + 1);
      }
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setSelectedStep(rfqServiceKeys.warehouse);
    updateTabs(activeStep - 1);
  };

  const handleStepClick = (selectedStep) => {
    setActiveStep(selectedStep);
    setSelectedStep(
      selectedStep === 0 ? rfqServiceKeys.warehouse : rfqServiceKeys.transport
    );
    updateTabs(selectedStep);
  };

  const handleSendRfqs = (data) => {
    if (areAllObjectsEmpty(rfqList)) {
      enqueueSnackbar("Please select at least one transporter", {
        variant: "error"
      });
    } else if (!currentUser) {
      checkIsLoggedIn();
    } else {
      const searchFormData = {
        partnerType: localSearchFormData.service,
        city: localSearchFormData.city,
        ...{
          ...(checkBothService || serviceLocalStorage.transport
            ? {
                weightCapacityRange:
                  vehicleWeights[localSearchFormData.truckType],
                pickup: {
                  address: localSearchFormData.pickupLocation,
                  location: {
                    coordinates: [
                      ...localSearchFormData.pickupLocationCoordinates
                    ]
                  }
                },
                distance: localSearchFormData.distance
              }
            : {})
        },
        ...{
          ...(serviceLocalStorage.transport && !checkBothService
            ? {
                inboundDate: moment(localSearchFormData.pickUpDate).format(
                  "YYYY-MM-DD"
                )
              }
            : {
                inboundDate: moment(localSearchFormData.inboundDate).format(
                  "YYYY-MM-DD"
                ),
                outboundDate: moment(localSearchFormData.outboundDate).format(
                  "YYYY-MM-DD"
                )
              })
        },
        ...(Boolean(localSearchFormData?.destination) && {
          destination: {
            address: localSearchFormData.destination,
            location: {
              coordinates: [...localSearchFormData.destinationCoordinates]
            }
          }
        }),
        storageType: localSearchFormData.storageType,
        storageQuantity: localSearchFormData.pallets,
        itemDescription: data.itemDescription
      };
      // Add only fields with non-empty values
      const additionalFields = {
        materialCategory: localSearchFormData?.materialDetails,
        packagingType: localSearchFormData?.packagingType,
        packageDimension: localSearchFormData?.dimension,
        packageQuantity: localSearchFormData?.units,
        totalWeight: localSearchFormData?.totalWeight,
        additionalServices: localSearchFormData?.additionalFeature
      };

      for (const [key, value] of Object.entries(additionalFields)) {
        if (value !== undefined && value !== null && value !== "") {
          searchFormData[key] = value;
        }
      }

      createInquiry(
        {
          searchForm: searchFormData,
          ...{
            ...(checkBothService || serviceLocalStorage.warehouse
              ? {
                  warehouseRfq: {
                    customerExpectedPrice: Number(
                      data.warehousePriceExpectation
                    ),
                    partners: sendRfqObj["warehouse"].map((item) => {
                      return {
                        partner: item._id
                      };
                    })
                  }
                }
              : {})
          },
          ...{
            ...(checkBothService || serviceLocalStorage.transport
              ? {
                  transportRfq: {
                    customerExpectedPrice: Number(
                      data.transportPriceExpectation
                    ),
                    partners: sendRfqObj["transport"].map((item) => {
                      return {
                        partner: item._id
                      };
                    })
                  }
                }
              : {})
          }
        },
        {
          onSuccess: (res) => {
            resetObject();
            hideDrawer();
            showDialog({
              component: (
                <SuccessfulDialog
                  text={action_labels.rfqSentToWarehousesSuccess}
                  subText={action_labels.warehousesRequestRespondMsgSuccess}
                  btn1Text={labels.continueWithListing}
                  btn2Text={labels.goToInquiry}
                  btn1Callback={() => hideDialog()}
                  btn2Callback={() => {
                    navigate(appRoutes.inquiry.inquiryListView);
                    hideDialog();
                  }}
                />
              )
            });
          }
        }
      );
    }
  };

  const propsCheck = (item) => {
    return Object.keys(item).length === 0
      ? { addContainerPlaceholder: true }
      : {
          title: item?.code,
          city: item?.address.city,
          state: item?.address.state,
          cluster: item?.address.cluster,
          addonFees: item?.costs.additionalServiceCost,
          totalAmount: item?.costs.totalAmount,
          rateUnit:
            selectedStep === rfqServiceKeys.warehouse
              ? item?.storageTypes[0].rateUnit
              : item?.vehicles[0].rateUnit,
          id: item?._id,
          thumbnail:
            selectedStep === rfqServiceKeys.warehouse
              ? item?.heroImage?.thumbUrl
              : item?.vehicles?.[0]?.heroImage?.thumbUrl,
          ...{
            ...(selectedStep === rfqServiceKeys.warehouse
              ? {
                  monthlyRental: item?.storageTypes[0].rate
                }
              : {
                  monthlyRental: item?.vehicles[0].rate
                })
          }
        };
  };

  useEffect(() => {
    if (isError)
      enqueueSnackbar(error?.response.data.message, { variant: "error" });
  }, [error?.response.data.message, isError]);

  return (
    <Box>
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{
          padding: {
            xs: "25px",
            md: "10px 38px "
          }
        }}
      >
        <Typography
          color={"black"}
          fontSize={20}
          fontWeight={500}
          letterSpacing={"0.04px"}
        >
          {labels.requestForQuotes}
        </Typography>
        <IconButton onClick={closeDrawer}>
          <Close />
        </IconButton>
      </Box>
      <Box
        sx={{
          height: "calc(100vh - 142px)",
          overflowY: "auto",
          padding: {
            xs: 2,
            md: "10px 38px"
          }
        }}
      >
        <Grid container spacing={isMobileDevice ? "10px" : 2}>
          {checkBothService && (
            <Grid item xs={12}>
              <HorizontalLinearStepper
                service={service}
                activeStep={activeStep}
                completedList={[errors.warehouse, errors.transport]}
                handleStepClick={handleStepClick}
              />
            </Grid>
          )}
          {rfqList?.map((item, index) => (
            <Grid item xs={12} key={index}>
              <CartItemCard
                {...propsCheck(item)}
                placeholderLabel={
                  selectedStep === rfqServiceKeys.warehouse
                    ? labels.warehouse
                    : labels.transporter
                }
                onRemoveCartItem={() =>
                  onRemove(
                    selectedStep === rfqServiceKeys.warehouse
                      ? rfqServiceKeys.warehouse
                      : rfqServiceKeys.transport,
                    index
                  )
                }
                type={
                  selectedStep === rfqServiceKeys.warehouse
                    ? rfqServiceKeys.warehouse
                    : rfqServiceKeys.transport
                }
              />
            </Grid>
          ))}
          <Grid
            item
            xs={12}
            sx={{
              marginTop: {
                xs: "20px",
                md: 0
              }
            }}
          >
            {selectedStep === rfqServiceKeys.warehouse && (
              <TextInput
                mandatoryField
                control={control}
                name={rfqServiceKeys.warehousePriceExpectation}
                label={labels.setYourPriceExpectation}
                // placeholder={labels.pricePerSqftLabelText}
                type="number"
              />
            )}
            {selectedStep === rfqServiceKeys.transport && (
              <TextInput
                mandatoryField
                control={control}
                name={rfqServiceKeys.transportPriceExpectation}
                label={labels.setYourPriceExpectation}
                // placeholder={labels.pricePerKmLabelText}
                type="number"
              />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              marginTop: {
                xs: "20px",
                md: 0
              }
            }}
          >
            <TextArea
              mandatoryField
              control={control}
              name={SearchFormKeys.ItemDescription}
              label={labels.itemDescriptionLabelText}
              placeholder={labels.itemDescriptionPlaceholderText}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          padding: {
            xs: "0 16px",
            md: 0
          }
        }}
      >
        {activeStep === 0 && checkBothService ? (
          <Button
            variant="contained"
            type="submit"
            sx={{
              fontWeight: 600,
              fontSize: 13,
              width: activeStep === 0 ? "100%" : "50%",
              height: {
                xs: 48,
                md: 81
              },
              borderRadius: { xs: "8px", md: 0 }
            }}
            onClick={handleNext}
            // disabled={isWarehousePriceExpectationInvalid}
          >
            {labels.next}
          </Button>
        ) : (
          <>
            {checkBothService && (
              <Button
                variant="soft"
                sx={{
                  backgroundColor: primaryBackground,
                  fontWeight: 600,
                  fontSize: 13,
                  width: "50%",
                  height: {
                    xs: 48,
                    md: 81
                  },
                  border: "none",
                  borderRadius: { xs: "8px", md: 0 },
                  display: {
                    xs: "none",
                    md: "initial"
                  }
                }}
                onClick={handleBack}
              >
                {labels.back}
              </Button>
            )}
            <LoadingButton
              variant="contained"
              type="submit"
              sx={{
                fontWeight: 600,
                fontSize: 13,
                width: {
                  xs: "100%",
                  md: checkBothService ? "50%" : "100%"
                },
                height: {
                  xs: 48,
                  md: 81
                },
                borderRadius: { xs: "8px", md: 0 }
              }}
              loading={isLoading}
              onClick={handleSubmit(handleSendRfqs)}
            >
              {labels.sendRFQ}
            </LoadingButton>
          </>
        )}
      </Box>
    </Box>
  );
};

export default SendRFQ;
