import { queryKeys } from "../../../constants/queryKeys";
import { getStockTransfers } from "../../../purchase/services";
import { useInfiniteQuery } from "@tanstack/react-query";

export const useFetchStockTransfers = () => {
  const orders = useInfiniteQuery(
    [queryKeys.queryFetchStockTransfers],
    ({ pageParam = 1 }) => getStockTransfers(pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage =
          lastPage?.stockTransfers.length === lastPage?.pagination.limit
            ? allPages.length + 1
            : undefined;

        return nextPage;
      },
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true
    }
  );

  return orders;
};
