import { deleteDebitNoteDraft } from "../../finance/services";
import { useMutation } from "@tanstack/react-query";

export const useDeleteDebitNoteDraft = () => {
  const deleteDraft = useMutation(({ dnId }) =>
    deleteDebitNoteDraft(dnId)
  );

  return deleteDraft;
};
