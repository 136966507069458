import { getSkuFormData } from "../../inventory/services/index";
import { queryKeys } from "../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchSkuFormData = () => {
  const querySkuFormData = useQuery(
    [queryKeys.queryFetchSkuFormData],
    () => getSkuFormData(),
    {
      refetchOnWindowFocus: false
    }
  );

  return querySkuFormData;
};
