import {
  Grid,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography
} from "@mui/material";
import moment from "moment";

import { useState } from "react";

const OrderTrackStepper = ({ detailList }) => {
  const [activeStep, setActiveStep] = useState(1);

  const handleStepper = (index) => {
    setActiveStep(index);
  };

  return (
    <Stepper activeStep={activeStep} orientation="vertical">
      {detailList?.map((step, index) => (
        <Step
          key={step?.label}
          {...(step?.isCompleted && {
            onClick: () => handleStepper(index),
            sx: { cursor: "pointer" }
          })}
          completed={Boolean(step?.isCompleted)}
        >
          <StepLabel>
            <Grid container spacing={2}>
              <Grid item xs={7} lg={8}>
                <Typography>{step?.label}</Typography>
              </Grid>
              <Grid item xs={5} lg={4}>
                {step?.isCompleted && (
                  <Typography fontWeight={600} fontSize={14} textAlign={"end"}>
                    {moment(step?.createdAt).format("MMM DD, HH:mm")}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </StepLabel>
          <StepContent style={{ whiteSpace: "pre-line" }}>
            <Typography
              color={"text.secondary"}
              sx={{
                fontSize: 14,
                fontWeight: 400,
                lineHeight: "20px"
              }}
            >
              {step?.description}
            </Typography>
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );
};

export default OrderTrackStepper;
