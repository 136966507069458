import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Typography
} from "@mui/material";

import { Close } from "@mui/icons-material";
import InquiryCartCard from "./InquiryCartCard";
import RegretForm from "../../../shared/UiElements/ReasonForm";
import SuccessfulDialog from "../../../shared/UiElements/SuccessfulDialog";
import { action_labels } from "../../../constants";
import { enqueueSnackbar } from "notistack";
import { inquiry_table_labels } from "../../../constants";
import { isMobileDevice } from "../../../App";
import moment from "moment";
import { useCancelRfqHook } from "../../../query-hooks/Inquiry/useCancelRfqHook";
import { useCustomDialog } from "../../../shared/customDialog";
import { useDrawer } from "../../../shared/customSideDrawer";
import { useEffect } from "react";
import { useGetRfqCancelFormDataHook } from "../../../query-hooks/Inquiry/useGetRfqCancelFormDataHook";
import { useGetRfqHook } from "../../../query-hooks/Inquiry/useGetRfqHook";

const InquiryCart = ({
  hideDrawer,
  inquiryId,
  rfqId,
  inquiryDate,
  code,
  service
}) => {
  const { showDialog, hideDialog } = useCustomDialog();
  const { isDrawerOpen } = useDrawer();

  const {
    isError,
    error,
    isFetching,
    data,
    refetch: fetchRfqData
  } = useGetRfqHook(inquiryId, rfqId, isDrawerOpen);

  const {
    data: reasons,
    refetch,
    isFetched,
    isError: isCancelFormError,
    remove,
    isFetching: isCancelFormFetching
  } = useGetRfqCancelFormDataHook(inquiryId, rfqId);

  const {
    mutate: regretInquiry,
    isError: isRegretInquiryError,
    error: regretInquiryError
  } = useCancelRfqHook();

  const onCancelClick = (e) => {
    e.stopPropagation();
    refetch();
  };

  useEffect(() => {
    fetchRfqData();
  }, [fetchRfqData]);

  useEffect(() => {
    if (isFetched && !isCancelFormError && isMobileDevice) {
      remove();
      showDialog({
        component: (
          <RegretForm
            title={inquiry_table_labels.confirmCloseInquiry}
            subText={inquiry_table_labels.confirmCloseInquirySubText}
            btn1Text={action_labels.yesRegretButtonText}
            btn1Callback={(data) => {
              regretInquiry(
                {
                  inquiryId: inquiryId,
                  rfqId: rfqId,
                  data: {
                    reason: data.regretReason,
                    ...{
                      ...(data.otherReason
                        ? { otherReason: data.otherReason }
                        : {})
                    }
                  }
                },
                {
                  onSuccess: () => {
                    showDialog({
                      component: (
                        <SuccessfulDialog
                          text={action_labels.yourInquiryCancelled}
                          subText={
                            action_labels.customerRequestInquiryResponseTime
                          }
                          btn2Text={inquiry_table_labels.close}
                          btn2Callback={() => {
                            hideDialog();
                            hideDrawer();
                          }}
                        />
                      )
                    });
                  }
                }
              );
            }}
            btn2Text={inquiry_table_labels.cancel}
            btn2Callback={() => hideDialog()}
            reasons={reasons}
          />
        )
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched, isCancelFormError]);

  useEffect(() => {
    if (isError || isRegretInquiryError)
      enqueueSnackbar(
        error?.response.data.message ??
          regretInquiryError?.response.data.message,
        { variant: "error" }
      );
  }, [
    error?.response.data.message,
    isError,
    isRegretInquiryError,
    regretInquiryError?.response.data.message
  ]);

  return (
    <Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isCancelFormFetching}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        sx={{
          display: {
            xs: "flex",
            md: "none"
          },
          padding: "25px 20px",
          background: "#1B51B3",
          justifyContent: "space-between",
          alignItems: "flex-start"
        }}
      >
        <Box display={"flex"} flexDirection={"column"} gap={1}>
          <Typography fontSize={14} fontWeight={600} color={"white"}>
            {`${inquiry_table_labels.inquiryId} : ${code}`}
          </Typography>
          <Typography fontSize={14} lineHeight={"20px"} color={"white"}>
            {`${inquiry_table_labels.dated} : ${moment(inquiryDate).format(
              "DD MMMM YYYY"
            )}`}
          </Typography>
        </Box>
        {data?.rfq?.status?.name === "Cancelled" ||
        data?.rfq?.status?.name === "Regretted" ? (
          <></>
        ) : (
          <Box display={"flex"}>
            <Button
              variant="text"
              sx={{
                fontSize: 13,
                fontWeight: 600,
                color: "white",
                textDecorationLine: "underline",
                "&:hover": {
                  backgroundColor: "transparent"
                },
                "&:active": {
                  backgroundColor: "transparent"
                }
              }}
              onClick={onCancelClick}
            >
              {"Close Inquiry"}
            </Button>
          </Box>
        )}
        <IconButton sx={{ color: "white" }} onClick={hideDrawer}>
          <Close />
        </IconButton>
      </Box>
      <Box
        padding={"40px 40px 0 40px"}
        sx={{
          display: {
            xs: "none",
            md: "flex"
          }
        }}
        flexDirection={"column"}
        gap={2}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"flex-start"}
        >
          <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 500,
                letterSpacing: "0.04px"
              }}
            >
              {`${inquiry_table_labels.inquiryId} : ${code}`}
            </Typography>
            <Typography
              sx={{
                color: "text.secondary",
                fontSize: 14,
                fontWeight: 400,
                lineHeight: "20px"
              }}
            >
              {`${inquiry_table_labels.dated} : ${moment(inquiryDate).format(
                "DD MMMM YYYY"
              )}`}
            </Typography>
          </Box>
          <IconButton onClick={hideDrawer}>
            <Close />
          </IconButton>
        </Box>
        <Divider sx={{ borderColor: "#B7BEC7" }} />
      </Box>
      <Box
        sx={{
          p: {
            xs: 2,
            md: "24px 40px 40px 40px"
          }
        }}
      >
        {isFetching ? (
          <Box display={"flex"} justifyContent={"center"}>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  color: "text.secondary",
                  fontSize: 14,
                  fontWeight: 400,
                  lineHeight: "20px",
                  textAlign: "center",
                  my: {
                    xs: 1,
                    md: 2
                  },
                  display: "flex",
                  gap: 0.5,
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                {inquiry_table_labels.proposedPrice} :
                <Typography
                  component={"span"}
                  sx={{
                    color: "text.secondary",
                    fontSize: 16,
                    fontWeight: 600,
                    lineHeight: "20px",
                    letterSpacing: "0.08px"
                  }}
                >
                  {data ? `₹${data?.rfq?.customerExpectedPrice}` : 0}
                </Typography>
              </Typography>
            </Grid>
            {data?.rfq?.partners.map((item, index) => (
              <Grid item xs={12} key={index}>
                <InquiryCartCard
                  {...item}
                  yourPrice={data.customerExpectedPrice}
                  service={service}
                  inquiryId={inquiryId}
                  searchForm={data?.searchForm}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default InquiryCart;
