import styled from "@emotion/styled";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography
} from "@mui/material";
import WhiteWarehouse from "../../../assets/icons/WhiteWarehouse.svg";

const StyledCard = styled(Card)(({ props }) => ({
  ...props,
  padding: "12px 18px",
  cursor: "pointer",
  minWidth: "140px",
  minHeight: "140px",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: 0,
  gap: 3
}));

const StyledText = styled(Typography)(() => ({
  color: "#FFFFFF",
  fontSize: 14,
  fontFamily: "Inter",
  fontWeight: 600,
  lineHeight: "17px"
}));

const StyledButton = styled(Button)(() => ({
  color: "#FFFFFF",
  fontSize: 14,
  fontFamily: "Inter",
  lineHeight: "17px",
  fontWeight: 700,
  borderRadius: 0,
  borderColor: "#FFFFFF"
}));

const MobileWarehouseCard = ({ id, index, code, onEditClick, ...props }) => {
  return (
    <StyledCard {...props} elevation={1}>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 3
        }}
      >
        <img src={WhiteWarehouse} alt="Warehouse" />
        <StyledText>{code}</StyledText>
      </CardContent>
      <CardActions
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <StyledButton
          variant="outlined"
          sx={{ width: "40px", height: "30px" }}
          onClick={onEditClick}
        >
          {"Edit"}
        </StyledButton>
      </CardActions>
    </StyledCard>
  );
};

export default MobileWarehouseCard;
