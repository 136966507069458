import { Box, Grid, Typography } from "@mui/material";

const DetailsGrid = ({ gridData }) => {
  return (
    <Grid container spacing={3}>
      {gridData?.map((item, index) => (
        <Grid
          item
          xs={6}
          md={item.icon ? 4 : 2}
          display="flex"
          alignItems="flex-start"
          key={index}
        >
          {item.icon && item.icon}
          <Box display={"flex"} flexDirection={"column"} gap={1}>
            <Typography fontSize={14} lineHeight="20px" color="text.secondary">
              {item.label}
            </Typography>
            <Typography
              fontSize={14}
              lineHeight="19px"
              letterSpacing="0.005em"
              fontWeight={600}
            >
              {item.value}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default DetailsGrid;
