import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { useFetchCreditNoteCreateFormData } from "../../../query-hooks/ClientOrderCreditNote/useFetchCreditNoteCreateFormData";
import { useFetchCreditNoteUpdateFormData } from "../../../query-hooks/ClientOrderCreditNote/useFetchCreditNoteUpdateFormData";
import MrrCNTable from "./MrrCNTable";
import moment from "moment";
import { currencyOnlyFormatter } from "../../../shared/utils";
import ManualInvoiceUpload from "../../../finance/components/ManualInvoiceUpload";
// import { useFetchCreditNoteById } from "../../query-hooks/ClientOrderInvoice/useFetchCreditNoteById";

const CreateCreditNote = ({
  orderId,
  MrrId,
  invId,
  orderType,
  orderInfo,
  InvoiceData,
  InvCreateAllowed,
  refectDocs
}) => {
  // const { data: CreditNoteData, isFetching: isFetchingInvoice } =
  //   useFetchCreditNoteById(invId, orderType);

  const { data: CreateCreditNoteFormData, isFetching: isFetchingFormData } =
    useFetchCreditNoteCreateFormData(orderId, invId, orderType);

  const {
    data: UpdatedCreditNoteFormData,
    isFetching: isFetchingUpdateFormData
  } = useFetchCreditNoteUpdateFormData(
    invId,
    orderType,
    InvoiceData?.status?.name
  );

  const createTableData = CreateCreditNoteFormData?.mrrs?.find(
    (item) => item?._id === MrrId
  );

  const tableData = invId ? InvoiceData : createTableData;
  const advOptions = invId
    ? UpdatedCreditNoteFormData?.advancePercents
    : CreateCreditNoteFormData?.advancePercents;
  const dueOptions = invId
    ? UpdatedCreditNoteFormData?.dueDays
    : CreateCreditNoteFormData?.dueDays;

  return isFetchingFormData || isFetchingUpdateFormData ? (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isFetchingFormData || isFetchingUpdateFormData}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : Boolean(InvoiceData?.file) ? (
    <ManualInvoiceUpload
      InvInfo={{
        file: InvoiceData?.file,
        code: InvoiceData?.customCode,
        date: moment(InvoiceData?.docDate).format("DD-MM-YYYY"),
        amount: currencyOnlyFormatter(
          parseFloat(InvoiceData?.totalAmount).toFixed(2)
        ),
        advance: InvoiceData?.advancePercent,
        due: InvoiceData?.dueDays
      }}
      Title={orderType === "SaleOrder" ? "Invoice" : "Debit Note"}
    />
  ) : (
    <MrrCNTable
      MrrId={MrrId}
      cnId={invId}
      orderId={orderId}
      orderType={orderType}
      InvoiceStatus={InvoiceData?.status?.name}
      orderData={orderInfo}
      tableData={tableData}
      advOptions={advOptions}
      dueOptions={dueOptions}
      InvCreateAllowed={InvCreateAllowed}
      refectDocs={refectDocs}
    />
  );
};

export default CreateCreditNote;
