import { Box, Divider, Grid, Typography } from "@mui/material";
import { dateTimeFormatter, stringCapitalization } from "../../../shared/utils";
import { labels, order_table_labels } from "../../../constants";

import { ActionIconButtons } from "./ActionButtons";
import { StyledChip } from "../../../shared/UiElements/StatusChip";

const RetrieveMobileCard = ({
  orderId,
  outboundDate,
  retrieveDate,
  status,
  partnerName
}) => {
  return (
    <Grid container spacing={"17px"}>
      <Grid item xs={12} container>
        <Grid item xs={6}>
          <Typography fontSize={13} fontWeight={500} lineHeight={"18px"}>
            {partnerName}
          </Typography>
        </Grid>
        <Grid item xs={6} textAlign={"end"}>
          <Typography
            color={"text.secondary"}
            fontSize={13}
            fontWeight={500}
            lineHeight={"18px"}
          >{`ID ${orderId}`}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} container marginTop={"3px"}>
        <Grid
          item
          xs={9}
          display={"flex"}
          gap={"12px"}
          alignItems={"flex-start"}
          justifyContent={"space-between"}
        >
          <Box display={"flex"} flexDirection={"column"} gap={"4px"}>
            <Typography fontSize={13} fontWeight={500} lineHeight={"18px"}>
              {dateTimeFormatter(outboundDate)}
            </Typography>
            <Typography
              fontSize={12}
              fontWeight={400}
              lineHeight={"16px"}
              color={"text.secondary"}
              textAlign={"end"}
            >
              {labels.outboundDateLabelText}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={3} display={"flex"} justifyContent={"flex-end"}>
          <Box display={"flex"} flexDirection={"column"}>
            <Typography
              fontSize={13}
              fontWeight={500}
              lineHeight={"18px"}
              textAlign={"end"}
            >
              {dateTimeFormatter(retrieveDate)}
            </Typography>
            <Typography
              fontSize={12}
              fontWeight={400}
              lineHeight={"16px"}
              color={"text.secondary"}
              textAlign={"end"}
            >
              {order_table_labels.retrieveDate}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} container marginTop={"6px"}>
        <Grid item xs={6}>
          <Typography fontSize={12} lineHeight={"16px"}>
            <StyledChip label={status} variant="filled" />
          </Typography>
          <Typography
            mt={1}
            fontSize={12}
            lineHeight={"16px"}
            color={"text.secondary"}
          >
            {order_table_labels.status}
          </Typography>
        </Grid>
        <Grid item xs={6} display={"flex"} justifyContent={"flex-end"}>
          <ActionIconButtons
            orderId={orderId}
            status={stringCapitalization(status)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RetrieveMobileCard;