import { getRfqCancelFormData } from "../../customer/services";
import { queryKeys } from "../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useGetRfqCancelFormDataHook = (inquiryId, rfqId) => {
  const queryGetRfqCancelFormData = useQuery(
    [queryKeys.queryGetRfqCancelFormData, inquiryId, rfqId],
    () => getRfqCancelFormData(inquiryId, rfqId),
    {
      enabled: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false
    }
  );

  return queryGetRfqCancelFormData;
};
