import * as yup from "yup";

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import {
  CancelOutlined,
  EditOutlined,
  PrintOutlined
} from "@mui/icons-material";
import { inquiry_table_labels, order_table_labels } from "../../../constants";
import { useEffect, useRef, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";

import { AccordionCard } from "./PackingNotes/AccordionCard";
import AddIcon from "@mui/icons-material/Add";
import { LoadingButton } from "@mui/lab";
import { PackingNotesFormKeys } from "../../../constants/formKeys";
import { enqueueSnackbar } from "notistack";
import { successStrings } from "../../../constants/errorStrings";
import { useGetAllPackingNotes } from "../../../query-hooks/PackagingNotes/useGetAllPackingNotes";
import { useGetCreateFormData } from "../../../query-hooks/PackagingNotes/useGetCreateFormData";
import { useGetOrderDetails } from "../../../query-hooks/Orders/useGetOrderDetails";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { useSavePackingNotes } from "../../../query-hooks/PackagingNotes/useSavePackingNotes";
import { yupResolver } from "@hookform/resolvers/yup";

export const packingNotesSchema = yup.object({
  packagingNotes: yup
    .array()
    .of(
      yup.object().shape({
        [PackingNotesFormKeys.PackageName]: yup
          .string()
          .required("Package name is required"),
        [PackingNotesFormKeys.MaterialCategory]: yup
          .string()
          .required("Category is required"),
        [PackingNotesFormKeys.PackagingType]: yup
          .string()
          .required("Packing type is required"),
        [PackingNotesFormKeys.PackageDimension]: yup
          .string()
          .required("Package dimension is required"),
        [PackingNotesFormKeys.Items]: yup
          .array()
          .of(
            yup.object({
              [PackingNotesFormKeys.ItemName]: yup
                .string()
                .required("Item name is required"),
              [PackingNotesFormKeys.MaterialSubCategory]: yup
                .string()
                .required("SubCategory is required"),
              [PackingNotesFormKeys.Quantity]: yup
                .string()
                .required("Quantity is required")
            })
          )
          .required()
      })
    )
    .required("Add at least one packaging note")
});

export const defaultPackagingNote = {
  [PackingNotesFormKeys.PackageName]: "",
  [PackingNotesFormKeys.MaterialCategory]: "",
  [PackingNotesFormKeys.PackagingType]: "",
  [PackingNotesFormKeys.PackageDimension]: "",
  [PackingNotesFormKeys.Items]: [
    {
      [PackingNotesFormKeys.ItemName]: "",
      [PackingNotesFormKeys.MaterialSubCategory]: "",
      [PackingNotesFormKeys.Quantity]: ""
    }
  ]
};

const PackingNotes = () => {
  const printRef = useRef();
  const { uuid } = useParams();

  const [expanded, setExpanded] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const {
    data: packagingNotesData,
    isFetching,
    isError,
    error,
    refetch,
    isFetched
  } = useGetAllPackingNotes(uuid);

  const { control, handleSubmit, watch, reset } = useForm({
    defaultValues: {
      packagingNotes: []
    },
    resolver: yupResolver(packingNotesSchema)
  });

  const packagingNotesWatch = watch("packagingNotes");

  const {
    fields: packingNotesFields,
    append: appendPackagingNote,
    remove: removePackagingNote
  } = useFieldArray({
    control,
    name: "packagingNotes"
  });

  const { data: packagingFormData, isFetching: isPackagingFormDataFetching } =
    useGetCreateFormData(uuid);
  const { data: orderData, isFetching: isOrderDetailsFetching } =
    useGetOrderDetails(uuid);

  const orderDetails = !Boolean(orderData?.order?.warehouseOrder)
    ? {
        code: orderData?.order?.transportOrder.code,
        ...orderData?.order?.recentOrderItem
      }
    : {
        code: orderData?.order?.warehouseOrder.code,
        ...orderData?.order?.recentOrderItem
      };

  const { mutate, isLoading } = useSavePackingNotes();

  useEffect(() => {
    if (packagingNotesData?.packagingNotes.length > 0) {
      setIsEdit(false);
    } else {
      setIsEdit(true);
    }
  }, [packagingNotesData?.packagingNotes.length]);

  useEffect(() => {
    if (isFetched) {
      reset({
        packagingNotes: packagingNotesData?.packagingNotes?.map((item) => ({
          [PackingNotesFormKeys.PackageName]: item.name,
          [PackingNotesFormKeys.PackagingType]: item.packagingType.name,
          [PackingNotesFormKeys.PackageDimension]: item.packageDimension.name,
          [PackingNotesFormKeys.Items]: item.items.map((subItem) => ({
            [PackingNotesFormKeys.ItemName]: subItem.name,
            [PackingNotesFormKeys.MaterialSubCategory]:
              subItem.materialSubCategory.name,
            [PackingNotesFormKeys.Quantity]: subItem.quantity
          }))
        }))
      });
    }
    if (packagingNotesData?.packagingNotes?.length < 1) {
      appendPackagingNote(defaultPackagingNote);
    }
  }, [
    appendPackagingNote,
    isFetched,
    packagingNotesData?.packagingNotes,
    reset
  ]);

  const handlePrint = useReactToPrint({
    content: () => printRef.current
  });

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const onSubmit = (data) => {
    mutate(
      {
        data: {
          packagingNotes: data.packagingNotes?.map(
            ({ materialCategory, ...rest }) => rest
          )
        },
        orderId: uuid
      },
      {
        onSuccess: () => {
          setIsEdit(false);
          refetch();
          enqueueSnackbar(successStrings.success_message_packing_notes, {
            variant: "success"
          });
        },
        onError: (error) =>
          enqueueSnackbar(error?.response.data.message, {
            variant: "error"
          })
      }
    );
  };

  useEffect(() => {
    if (isError)
      enqueueSnackbar(error?.response.data.message, { variant: "error" });
  }, [error?.response.data.message, isError]);

  const packagingNotesFields = packingNotesFields.map((item, index) => (
    <Grid item xs={12} key={item.id}>
      <AccordionCard
        isEdit={isEdit}
        control={control}
        watch={watch}
        item={item.id}
        handleChange={handleChange}
        expanded={expanded}
        index={index}
        packagingNotesWatch={packagingNotesWatch}
        removePackagingNote={removePackagingNote}
        ref={printRef}
        packagingFormData={packagingFormData}
        isPackagingFormDataFetching={isPackagingFormDataFetching}
        orderDetails={orderDetails}
        qrImage={packagingNotesData?.packagingNotes?.[index]?.qrImage}
      />
    </Grid>
  ));

  return (
    <Box
      sx={{
        padding: {
          xs: "40px 16px",
          md: "16px 0 24px 0"
        }
      }}
    >
      {isFetching || isOrderDetailsFetching ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isFetching || isOrderDetailsFetching}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
          {packagingNotesData?.packagingNotes.length > 0 && (
            <Box
              position={"absolute"}
              right={16}
              marginTop={"-41px"}
              gap={"7px"}
              zIndex={1}
              sx={{
                display: {
                  xs: "flex",
                  md: "none"
                },
                alignItems: "center"
              }}
            >
              <IconButton
                onClick={handlePrint}
                sx={{ background: "#EEF1F4", padding: "6px" }}
              >
                <PrintOutlined color="primary" sx={{ fontSize: 16 }} />
              </IconButton>
              {isEdit ? (
                <IconButton
                  onClick={() => setIsEdit(false)}
                  sx={{ background: "#EEF1F4", padding: "6px" }}
                >
                  <CancelOutlined color="primary" sx={{ fontSize: 16 }} />
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => setIsEdit(true)}
                  sx={{ background: "#EEF1F4", padding: "6px" }}
                >
                  <EditOutlined color="primary" sx={{ fontSize: 16 }} />
                </IconButton>
              )}
            </Box>
          )}

          <Grid container spacing={5}>
            <Grid
              item
              xs={12}
              sx={{
                display: {
                  xs: "none",
                  md: "block"
                }
              }}
            >
              <Box display={"flex"} gap={"14px"} flexDirection={"column"}>
                <Box
                  display={"flex"}
                  alignItems={"flex-end"}
                  justifyContent={"space-between"}
                >
                  <Typography
                    sx={{
                      fontSize: 20,
                      fontWeight: 500,
                      letterSpacing: "0.04px",
                      lineHeight: "normal"
                    }}
                  >
                    {order_table_labels.packingNotes}
                  </Typography>

                  <Box
                    sx={{
                      display: {
                        xs: "none",
                        md: "flex"
                      },
                      gap: 1
                    }}
                  >
                    {packagingNotesData?.packagingNotes.length > 0 && (
                      <>
                        <Button
                          variant="soft"
                          sx={{ height: 48 }}
                          onClick={handlePrint}
                          startIcon={<PrintOutlined />}
                        >
                          {order_table_labels.print}
                        </Button>
                        {isEdit ? (
                          <Button
                            sx={{ height: 48 }}
                            onClick={() => setIsEdit(false)}
                            variant="soft"
                          >
                            {inquiry_table_labels.cancel}
                          </Button>
                        ) : (
                          <Button
                            variant="soft"
                            sx={{ height: 48 }}
                            onClick={() => setIsEdit(true)}
                            startIcon={<EditOutlined />}
                          >
                            {order_table_labels.editNote}
                          </Button>
                        )}
                      </>
                    )}
                  </Box>
                </Box>
                <Divider sx={{ border: "0.7px solid #B7BEC7" }} />
              </Box>
            </Grid>
            <Grid item xs={12} container spacing={"22px"}>
              {packagingNotesFields}
            </Grid>
            {isEdit && (
              <Grid item xs={12}>
                <Box
                  display={"flex"}
                  sx={{
                    flexDirection: {
                      xs: "column",
                      md: "row"
                    },
                    gap: {
                      xs: 1,
                      md: 2
                    }
                  }}
                >
                  <Button
                    variant="soft"
                    sx={{ height: 64 }}
                    onClick={() => appendPackagingNote(defaultPackagingNote)}
                  >
                    <AddIcon sx={{ display: { xs: "none", md: "block" } }} />
                    {order_table_labels.addMorePackages}
                  </Button>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    sx={{ height: 64 }}
                    disabled={packagingNotesWatch?.length < 1}
                    onClick={handleSubmit(onSubmit)}
                    loading={isLoading}
                  >
                    {order_table_labels.savePackingNote}
                  </LoadingButton>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default PackingNotes;
