import { queryKeys } from "../../constants/queryKeys";
import { getVendorById } from "../../purchase/services";
import { useQuery } from "@tanstack/react-query";

export const useFetchVendorById = (vendorId) => {
  const VendorById = useQuery(
    [queryKeys.queryFetchVendorById, vendorId],
    () => getVendorById(vendorId),
    {
      enabled: Boolean(vendorId),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return VendorById;
};
