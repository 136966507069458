import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import MobileWarehouseCard from "./MobileWarehouseCard";
import { useDrawer } from "../../../shared/customSideDrawer";
import AddWarehouses from "./AddWarehouses";

const MobileWarehouseTab = ({ data, isFetching, refetch }) => {
  const { showDrawer, hideDrawer } = useDrawer();

  const handleEdit = (id = null) => {
    showDrawer({
      title: Boolean(id) ? "Edit Warehouse" : "Add Warehouse",
      height: "fit-content",
      component: (
        <AddWarehouses
          refetch={refetch}
          warehouseId={id}
          isMobile={true}
          hideDrawer={hideDrawer}
        />
      )
    });
  };

  return isFetching ? (
    <Grid container>
      <CircularProgress color="inherit" />
    </Grid>
  ) : (
    <Box
      display={"flex"}
      flexDirection={"column"}
      padding={"20px"}
      gap={2}
      mt={-2}
    >
      <Box display={"flex"} justifyContent={"flex-end"} alignItems={"center"}>
        <Button variant="link" disableRipple onClick={() => handleEdit(null)}>
          {"+ Add"}
        </Button>
      </Box>
      {data?.warehouses.length > 0 ? (
        <Grid container columnSpacing={6} rowSpacing={3}>
          {data?.warehouses?.map((item, index) => (
            <Grid item key={index} xs={6}>
              <MobileWarehouseCard
                index={index}
                code={item?.code}
                id={item?._id}
                onEditClick={() => handleEdit(item?._id)}
                sx={{
                  background: "#6097FF"
                }}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box display={"flex"} justifyContent={"center"}>
          <Typography sx={{ fontWeight: 600, color: "text.disabled" }}>
            {"No Warehouses Added"}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default MobileWarehouseTab;
