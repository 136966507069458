import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";
import { RemoveCircleOutline } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { ValueLabelText } from "./MobilePOTable";

export const MobileSATable = (row) => {
  const { remove } = row;
  const onRemoveClick = (index) => {
    remove(index);
  };
  return (
    <Grid container spacing={"20px"}>
      <Grid item xs={12} container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={8}>
          {row?.skuCode ? (
            <Box display={"flex"} gap={1} alignItems={"center"}>
              {row?.image?.thumbUrl && (
                <img
                  src={row?.image?.thumbUrl}
                  alt={row?.brandName ?? "--"}
                  style={{
                    objectFit: "contain",
                    maxWidth: "50px",
                    maxHeight: "50px"
                  }}
                />
              )}
              <Typography
                fontSize={12}
                fontWeight={600}
                color={"primary"}
                component={Link}
              >
                {row?.skuCode}
              </Typography>
            </Box>
          ) : (
            row.item
          )}
        </Grid>
        <Grid
          item
          xs={4}
          display={"flex"}
          justifyContent={"right"}
          alignItems={"flex-start"}
        >
          {(row?.status === "" || !row?.isEditable) && (
            <IconButton onClick={() => onRemoveClick(row?.index)}>
              <RemoveCircleOutline color="error" />
            </IconButton>
          )}
        </Grid>
        <Grid
          item
          xs={4}
          display={"flex"}
          justifyContent={"left"}
          alignItems={"flex-start"}
        >
          <Typography
            fontSize={12}
            fontWeight={400}
            lineHeight={"14.52px"}
            color={"text.disabled"}
            pl={1}
          >
            {row?.unit?.name}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <ValueLabelText
            label={"Ideal Qty"}
            value={row?.stock?.closingQuantity || "--"}
          />
        </Grid>
        <Grid
          item
          xs={4}
          display={"flex"}
          justifyContent={"right"}
          alignItems={"flex-start"}
        >
          {row?.isEditable && Boolean(row?.status) ? (
            <ValueLabelText
              label={"Actual Qty"}
              value={row?.actualStockQuantity || "--"}
            />
          ) : (
            row?.actualStockQuantity
          )}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={5}>
          {row?.isEditable && Boolean(row?.status) ? (
            <ValueLabelText label={"Reason"} value={row?.reason || "--"} />
          ) : (
            row?.reason
          )}
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid
          item
          xs={4}
          display={"flex"}
          justifyContent={"right"}
          alignItems={"flex-start"}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={1}
          >
            <Typography
              fontSize={14}
              lineHeight={"16.94px"}
              fontWeight={600}
              color={"#2773FF"}
            >
              {row?.change || "--"}
            </Typography>
            <Typography
              fontSize={8}
              lineHeight={"9.68px"}
              fontWeight={400}
              color={"text.disabled"}
            >
              {"Change"}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
