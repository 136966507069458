import { getPaymentSubtotal } from "../../finance/services/index";
import { queryKeys } from "../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchPaymentsSubtotal = (params, orderType) => {
  const queryInvoiceSubtotal = useQuery(
    [queryKeys.queryFetchPaymentSubtotal, orderType, JSON.stringify(params)],
    () => getPaymentSubtotal(params, orderType),
    {
      refetchOnWindowFocus: false
    }
  );

  return queryInvoiceSubtotal;
};
