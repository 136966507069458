import { queryKeys } from "../../../constants/queryKeys";
import { getSOTransactions } from "../../../sales/services";
import { useQuery } from "@tanstack/react-query";

export const useFetchSOTransactionsById = (orderId) => {
  const transactions = useQuery(
    [queryKeys.queryFetchSOTransactionsById, orderId],
    () => getSOTransactions(orderId),
    {
      enabled: Boolean(orderId),
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return transactions;
};
 