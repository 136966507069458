import { getVendorsSubtotal } from "../../purchase/services/index";
import { queryKeys } from "../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchVendorsSubtotal = (params) => {
  const queryVendorSubtotal = useQuery(
    [queryKeys.queryFetchVendorsSubtotal, JSON.stringify(params)],
    () => getVendorsSubtotal(params),
    {
      refetchOnWindowFocus: false
    }
  );

  return queryVendorSubtotal;
};
