import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography
} from "@mui/material";

import { Close } from "@mui/icons-material";
// import { StyledChip } from "../../shared/UiElements/StatusChip";
import moment from "moment";
import { currencyOnlyFormatter } from "../../shared/utils";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { isMobileDevice } from "../../App";

const TransactionLedger = ({
  hideDrawer,
  HeaderLabels,
  orderInfo,
  InvoiceInfo = [],
  NoteInfo = []
}) => {
  const calculateTotal = (data = [], key) => {
    return data?.length
      ? currencyOnlyFormatter(
          parseFloat(
            data.reduce((acc, obj) => acc + (obj[key] ?? 0), 0)
          ).toFixed(2)
        )
      : `0.00`;
  };

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `${orderInfo?.code} Transactions`
  });

  return (
    <Box
      ref={componentRef}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"space-between"}
      height={"100%"}
    >
      <Box display={"flex"} flexDirection={"column"}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={5}
          sx={{
            padding: {
              xs: 2,
              md: "60px 40px 30px 40px"
            }
          }}
        >
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography
              fontSize={20}
              fontWeight={500}
              color={"primary"}
              lineHeight={"24.2px"}
              letterSpacing={"0.2%"}
            >
              {HeaderLabels.Title}
            </Typography>
            <Box display={"flex"} gap={4} alignItems={"center"}>
              {/* <StyledChip label={""} /> */}
              {!isMobileDevice && (
                <Button variant="outlined" onClick={handlePrint}>
                  {"Print Ledger"}
                </Button>
              )}
              <IconButton onClick={hideDrawer}>
                <Close />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            padding: {
              xs: 2,
              md: "30px 40px"
            }
          }}
          display={"flex"}
          flexDirection={"column"}
          gap={4}
        >
          <Grid container spacing={1}>
            <Grid item xs={5}>
              <Typography fontWeight={500} fontSize={20} letterSpacing={"0.2%"}>
                Date
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography fontWeight={500} fontSize={20} letterSpacing={"0.2%"}>
                Doc. No.
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography fontWeight={500} fontSize={20} letterSpacing={"0.2%"}>
                Amount
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ mb: 1, borderColor: "#B7BEC7" }} />
            </Grid>
            <Grid item xs={5}>
              <Typography fontSize={14} letterSpacing={"0.2%"}>
                {moment(orderInfo?.orderDate).format("DD-MM-YYYY")}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography fontSize={14} letterSpacing={"0.2%"}>
                {orderInfo?.code}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography fontWeight={700} fontSize={14} letterSpacing={"0.2%"}>
                {currencyOnlyFormatter(
                  parseFloat(orderInfo?.totalAmount).toFixed(2)
                )}
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{ borderColor: "#B7BEC7" }} />
          <Grid container spacing={1}>
            <Grid item xs={5} display={"flex"} flexDirection={"column"} gap={1}>
              <Typography fontWeight={700}>{"Invoice Total"}</Typography>
              <Typography fontWeight={700} color={"text.secondary"}>
                {HeaderLabels.AmountLabel}
              </Typography>
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={3} display={"flex"} flexDirection={"column"} gap={1}>
              <Typography fontWeight={700}>
                {calculateTotal(InvoiceInfo, "totalAmount")}
              </Typography>
              <Typography fontWeight={700} color={"text.secondary"}>
                {`(${calculateTotal(InvoiceInfo, "totalPaidAmount")})`}
              </Typography>
            </Grid>
            {InvoiceInfo?.length > 0 ? (
              InvoiceInfo?.map((item, index) => (
                <>
                  <Grid
                    container
                    mt={2}
                    display={"flex"}
                    alignItems={"flex-end"}
                  >
                    <Grid item xs={0.5} fontSize={14}>
                      {`${index + 1}.`}
                    </Grid>
                    <Grid item xs={4.5} fontSize={14}>
                      {moment(item?.docDate).format("DD-MM-YYYY")}
                    </Grid>
                    <Grid item xs={4} fontSize={14}>
                      {item?.code ?? item?.customCode}
                    </Grid>
                    <Grid item xs={3} fontSize={14}>
                      {currencyOnlyFormatter(
                        parseFloat(item?.totalAmount).toFixed(2)
                      )}
                    </Grid>
                  </Grid>
                  {item?.payments?.length > 0 && (
                    <Grid
                      container
                      mt={1}
                      display={"flex"}
                      alignItems={"flex-end"}
                    >
                      <Grid item xs={0.5}></Grid>
                      <Grid item xs={4.5} fontSize={14}>
                        {`(${moment(item?.payments[0]?.paymentDate).format(
                          "DD-MM-YYYY"
                        )})`}
                      </Grid>
                      <Grid item xs={4} fontSize={14}>
                        {`(${item?.payments[0]?.utrNo})`}
                      </Grid>
                      <Grid item xs={3} fontSize={14}>
                        {`(${currencyOnlyFormatter(
                          parseFloat(item?.payments[0]?.amount).toFixed(2)
                        )})`}
                      </Grid>
                    </Grid>
                  )}
                </>
              ))
            ) : (
              <Grid item xs={12} textAlign={"center"} color={"text.secondary"}>
                {"No Invoices"}
              </Grid>
            )}
          </Grid>
          <Divider sx={{ borderColor: "#B7BEC7" }} />
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Typography
                fontWeight={700}
              >{`${HeaderLabels.NoteLabel} Total`}</Typography>
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={3}>
              <Typography fontWeight={700} fontSize={14} color={"error"}>
                {`(${calculateTotal(NoteInfo, "totalAmount")})`}
              </Typography>
            </Grid>
            {NoteInfo?.length > 0 ? (
              NoteInfo?.map((item, index) => (
                <>
                  <Grid item xs={5} fontSize={14}>
                    {moment(item?.docDate).format("DD-MM-YYYY")}
                  </Grid>
                  <Grid item xs={4} fontSize={14}>
                    {item?.code ?? item?.customCode}
                  </Grid>
                  <Grid item xs={3} fontSize={14}>
                    {`(${currencyOnlyFormatter(
                      parseFloat(item?.totalAmount).toFixed(2)
                    )})`}
                  </Grid>
                </>
              ))
            ) : (
              <Grid item xs={12} textAlign={"center"} color={"text.secondary"}>
                {`No ${HeaderLabels.NoteLabel}`}
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default TransactionLedger;
