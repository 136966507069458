import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { HeaderText } from "./SkuDetailsForm";
import DropDown from "../../../shared/formElements/DropDown";
import DateInput from "../../../shared/formElements/DateInput";
import { CalendarTodayOutlined, TuneOutlined } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { PrimaryText, SecondaryText } from "../../pages/Performance";
import CustomTable from "../../../shared/customTable";
import { Link, useParams } from "react-router-dom";
import { useFetchItemLedgers } from "../../../query-hooks/Sku/useFetchItemLedgers";
import { useInView } from "react-intersection-observer";
import { useFetchAssetWarehouses } from "../../../query-hooks/WarehouseAssets/useFetchAssetWarehouses";
import { dropdownOptions } from "../../../shared/utils";
import moment from "moment";
import { LoadingButton } from "@mui/lab";
import { useDrawer } from "../../../shared/customSideDrawer";
import { MobileItemLedger } from "./MobileItemLedger";

const getColumns = () => {
  return [
    {
      Header: "Date",
      accessor: "date",
      disableSortBy: true
    },
    {
      Header: "Warehouse",
      accessor: "code",
      disableSortBy: true
    },
    {
      Header: "Beginning Stock",
      accessor: "beginningStock",
      disableSortBy: true
    },
    {
      Header: "Qty IN",
      accessor: "qtyIn",
      disableSortBy: true
    },
    {
      Header: "Qty OUT",
      accessor: "qtyOut",
      disableSortBy: true
    },
    {
      Header: "Ending Stock",
      accessor: "endingStock",
      disableSortBy: true
    },
    {
      Header: "Qty in Sales Orders",
      accessor: "qtySalesOrders",
      width: 163,
      disableSortBy: true
    }
  ];
};

const getRows = (rows = []) => {
  return rows.map((item, index) => {
    return {
      code: item?.warehouse?.code,
      date: item?.date ? moment(new Date(item?.date)).format("DD-MM-YYYY") : "",
      beginningStock: item?.openingQuantity,
      qtyIn: item?.inQuantity,
      qtyOut: item?.outQuantity,
      endingStock: item?.closingQuantity,
      qtySalesOrders: item?.orderOutQuantity
    };
  });
};

const ItemLedger = ({ skuId }) => {
  const { Id } = useParams();
  const LedgerId = skuId || Id;
  const { control, handleSubmit, watch } = useForm();
  const [params, setParams] = useState();
  const { showDrawer, hideDrawer } = useDrawer();
  const { ref, inView } = useInView();
  const { data: warehouseOptions, isFetching: isFetchingWarehouses } =
    useFetchAssetWarehouses();
  const { data, isFetching, isFetched, hasNextPage, fetchNextPage, refetch } =
    useFetchItemLedgers(LedgerId, params);

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, inView]);

  const ledgerTable = useMemo(
    () => (
      <CustomTable
        columns={getColumns()}
        data={
          isFetched
            ? data?.pages?.flatMap((page) => getRows(page?.ledgers)) ?? []
            : []
        }
        isLoading={!isFetched}
        mobileComponent={MobileItemLedger}
      />
    ),
    [data?.pages, isFetched]
  );

  const onViewClick = (data) => {
    const filterData = {
      ...{
        ...(data["fromDate"]
          ? {
              [`filters[fromDate]`]: moment(data["fromDate"]).format(
                "YYYY-MM-DD"
              )
            }
          : {})
      },
      ...{
        ...(data["toDate"]
          ? { [`filters[toDate]`]: moment(data["toDate"]).format("YYYY-MM-DD") }
          : {})
      },
      ...{
        ...(data["warehouse"] !== ""
          ? {
              [`filters[warehouse]`]: data["warehouse"]
            }
          : {})
      }
    };
    setParams(filterData);
  };

  useEffect(() => {
    if (params) {
      refetch();
    }
  }, [params, refetch]);

  const fromDateWatch = watch("fromDate");

  const LedgerFilter = (
    <>
      <Grid
        container
        columnSpacing={2}
        display={"flex"}
        sx={{
          padding: { xs: "20px", md: 0 },
          justifyContent: { xs: "unset", md: "flex-end" },
          alignItems: { xs: "unset", md: "flex-end" }
        }}
      >
        <Grid item xs={6} md={2}>
          <DateInput
            control={control}
            name={"fromDate"}
            label={"From Date"}
            placeholder={"Pick Dates"}
            endIcon={CalendarTodayOutlined}
            minDate={"2001-01-01"}
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <DateInput
            control={control}
            name={"toDate"}
            label={"To Date"}
            placeholder={"Pick Dates"}
            endIcon={CalendarTodayOutlined}
            minDate={
              new Date(new Date(fromDateWatch).valueOf() + 1000 * 3600 * 24)
            }
          />
        </Grid>
        <Grid item xs={7} md={2.5} sx={{ mt: { xs: 2, md: 0 } }}>
          <DropDown
            control={control}
            availUnselect={true}
            name={"warehouse"}
            label={"Warehouse"}
            placeholder={"Select Warehouse"}
            unSelectText={"Select Warehouse"}
            options={
              isFetchingWarehouses
                ? []
                : dropdownOptions(warehouseOptions?.warehouses, "_id", "code")
            }
            isLoading={isFetchingWarehouses}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={5.5}
          sx={{ display: { xs: "none", md: "flex" } }}
        >
          <Button
            variant="contained"
            component={LoadingButton}
            loading={isFetching}
            onClick={handleSubmit(onViewClick)}
            sx={{ height: "45px" }}
          >
            {"View"}
          </Button>
        </Grid>
      </Grid>
      <Box
        width={"100%"}
        sx={{
          display: { xs: "flex", md: "none" },
          background: "#F0F2F5",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "72px"
        }}
      >
        <Button
          variant="contained"
          component={LoadingButton}
          loading={isFetching}
          onClick={() => {
            handleSubmit(onViewClick)();
            hideDrawer();
          }}
          sx={{ height: "40px", width: "100px" }}
        >
          {"View"}
        </Button>
      </Box>
    </>
  );

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      sx={{ padding: { xs: "15px", md: "0 0 0 40px" }, mb: { xs: 9, md: 0 } }}
    >
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          flexDirection: "column",
          gap: 2
        }}
      >
        <HeaderText>{"Item Ledger"}</HeaderText>
        {LedgerFilter}
        <Box
          display={"flex"}
          gap={3}
          justifyContent={"flex-start"}
          alignItems={"center"}
        >
          <Box display={"flex"} gap={1} alignItems={"center"}>
            {data?.pages[0]?.sku?.image?.thumbUrl && (
              <img
                src={data?.pages[0]?.sku?.image?.thumbUrl}
                alt={data?.pages[0]?.sku?.productName}
                height={52}
                width={50}
                style={{ objectFit: "contain" }}
              />
            )}
            <Typography
              fontSize={12}
              fontWeight={600}
              color={"primary"}
              component={Link}
            >
              {data?.pages[0]?.sku?.skuCode}
            </Typography>
          </Box>
          <PrimaryText>{`Product: ${
            data?.pages[0]?.sku?.productName ?? "--"
          }`}</PrimaryText>
          <SecondaryText>{`Brand: ${
            data?.pages[0]?.sku?.brandName ?? "--"
          }`}</SecondaryText>
          <SecondaryText>{`Category: ${
            data?.pages[0]?.sku?.categoryName ?? "--"
          }`}</SecondaryText>
          <SecondaryText>{`UOM: ${
            data?.pages[0]?.sku?.unit?.displayName ?? "--"
          }`}</SecondaryText>
        </Box>
      </Box>
      <Box
        sx={{
          display: { xs: "flex", md: "none" },
          flexDirection: "column",
          gap: 2,
          padding: "0 5px"
        }}
      >
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography
            sx={{
              fontSize: "16px",
              lineHeight: "19.36px",
              fontWeight: 700,
              color: "#2773FF"
            }}
          >
            {"Item Ledger"}
          </Typography>
          <IconButton
            size="small"
            sx={{
              backgroundColor: "#F0F2F5"
            }}
            onClick={() => {
              showDrawer({
                title: "Filter Ledger View",
                height: "fit-content",
                component: LedgerFilter
              });
            }}
          >
            <TuneOutlined color="primary" fontSize="small" />
          </IconButton>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box display={"flex"} gap={1} alignItems={"center"}>
            {data?.pages[0]?.sku?.image?.thumbUrl && (
              <img
                src={data?.pages[0]?.sku?.image?.thumbUrl}
                alt={data?.pages[0]?.sku?.productName}
                height={52}
                width={50}
                style={{ objectFit: "contain" }}
              />
            )}
            <Typography
              fontSize={12}
              fontWeight={600}
              color={"primary"}
              component={Link}
            >
              {data?.pages[0]?.sku?.skuCode}
            </Typography>
          </Box>
          <SecondaryText>{`UOM: ${
            data?.pages[0]?.sku?.unit?.displayName ?? "--"
          }`}</SecondaryText>
        </Box>
      </Box>
      {ledgerTable}
      {hasNextPage && (
        <Grid
          item
          xs={12}
          ref={ref}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress />
        </Grid>
      )}
    </Box>
  );
};

export default ItemLedger;
