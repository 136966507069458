import { getUpdateCustomerFormData } from "../../../sales/services";
import { queryKeys } from "../../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchUpdateCustomerFormData = (customerId) => {
  const queryUpdateCustomerFormData = useQuery(
    [queryKeys.queryFetchUpdateCustomerFormData,customerId],
    () => getUpdateCustomerFormData(customerId),
    {
        enabled: Boolean(customerId),
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchInterval: false,
      }
  );

  return queryUpdateCustomerFormData;
};
