import { updateSalesReturnDraft } from "../../../sales/services";
import { useMutation } from "@tanstack/react-query";

export const useUpdateSalesReturnDraft = () => {
  const updateSalesReturnById = useMutation(
    ({ data, salesOrderId, salesReturnId }) =>
      updateSalesReturnDraft(data, salesOrderId, salesReturnId)
  );
  return updateSalesReturnById;
};
