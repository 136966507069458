export const reverseGeocode = async (lat, lng) => {
  if (!window.google || !window.google.maps || !window.google.maps.Geocoder) {
    throw new Error("Google Maps JavaScript API is not loaded.");
  }
  const geocoder = new window.google.maps.Geocoder();
  return new Promise((resolve, reject) => {
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === "OK" && results[0]) {
        resolve(results[0].formatted_address);
      } else {
        reject(status);
      }
    });
  });
};
