import { Box, Divider, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export const VendorsMobileTable = (row) => {
  return (
    <Grid container spacing={"5px"}>
      <Grid
        item
        xs={12}
        container
        sx={{ display: "flex", alignItems: "center" }}
        rowSpacing={2}
      >
        <Grid item xs={6} display={"flex"} alignItems={"center"}>
          <Typography
            fontSize={14}
            lineHeight={"18px"}
            fontWeight={700}
            color={"#2773FF"}
            component={Link}
            to={row.to}
            sx={{ textDecoration: "none" }}
          >
            {row?.vendorCompanyName}
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"right"}
        >
          <Typography fontSize={13} lineHeight={"15.73px"} color={"#2773FF"}>
            {row.mobileNo}
          </Typography>
        </Grid>
        <Grid item xs={6} display={"flex"} alignItems={"center"}>
          <Typography fontSize={14} lineHeight={"16.94px"}>
            {row?.pocName}
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          display={"flex"}
          justifyContent={"right"}
          alignItems={"center"}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={0.5}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography
              fontSize={14}
              lineHeight={"16.94px"}
              fontWeight={700}
              color={"#F9A90E"}
            >
              {Boolean(row?.balance) ? row?.balance : "--"}
            </Typography>
            <Typography
              fontSize={12}
              lineHeight={"16px"}
              color={"text.disabled"}
            >
              {"Balance"}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={4}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={0.5}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography
              fontSize={13}
              lineHeight={"15.73px"}
              fontWeight={400}
              color={"#2773FF"}
            >
              {Boolean(row?.invoiceValue) ? row?.invoiceValue : "--"}
            </Typography>
            <Typography
              fontSize={12}
              lineHeight={"16px"}
              color={"text.disabled"}
            >
              {"Invoice Value"}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={0.5}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography fontSize={13} lineHeight={"15.73px"} fontWeight={400}>
              {Boolean(row?.amountPaid) ? row?.amountPaid : "--"}
            </Typography>
            <Typography
              fontSize={12}
              lineHeight={"16px"}
              color={"text.disabled"}
            >
              {"Amount Paid"}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={0.5}
            justifyContent={"right"}
            alignItems={"center"}
          >
            <Typography fontSize={13} lineHeight={"15.73px"} fontWeight={400}>
              {Boolean(row?.averageDue) ? row?.averageDue : "--"}
            </Typography>
            <Typography
              fontSize={12}
              lineHeight={"16px"}
              color={"text.disabled"}
            >
              {"Avg. Due"}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
