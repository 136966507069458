import { client } from "../client";

export const fetchWarehouseSearchFormDataPublic = async () => {
  return await client
    .get("/public/partners/search/warehouses/form-data")
    .then((response) => response.data.data);
};

export const fetchWarehouseSearchFormData = async () => {
  return await client
    .get("/partners/search/warehouses/form-data")
    .then((response) => response.data.data);
};

export const fetchTransportSearchFormDataPublic = async () => {
  return await client
    .get("/public/partners/search/transports/form-data")
    .then((response) => response.data.data);
};

export const fetchTransportSearchFormData = async () => {
  return await client
    .get("/partners/search/transports/form-data")
    .then((response) => response.data.data);
};

export const getProfile = async () => {
  return await client.get("/profile").then((res) => res.data);
};

export const getUpdateBusinessFormData = async () => {
  return await client.get("/clients/business/form-data").then((res) => res.data.data);
};

export const updateProfile = async (data) => {
  return await client.put(`/customers/profile`, data);
};

export const updateBusiness = async (data) => {
  return await client.put(`/clients/business`, data);
};

export const signup = async (token) => {
  return await client
    .post("/customers/sign-up", null, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then((res) => res.data);
};
