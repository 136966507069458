import { Box, Grid, IconButton, Typography } from "@mui/material";

import CustomTable from "../../../shared/customTable";
import DropDown from "../../../shared/formElements/DropDown";
import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import TextArea from "../../../shared/formElements/TextArea";
import TextInput from "../../../shared/formElements/TextInput";
import { purchase_labels } from "../../../constants";
import { reportLabel2 } from "../../pages/CreateOrder";
import { SkusById } from "./PurchaseOrderTable";
import { dropdownOptions } from "../../../shared/utils";
import PrintableTable from "../PrintableTable";
import { AddCircleOutline } from "@mui/icons-material";
import { MobileSTTable } from "./MobileSTTable";

const getColumns = (isEditable, status) => {
  return [
    ...[
      ...(status === "" || !isEditable
        ? [
            {
              Header: "",
              id: "selection",
              width: 50
            }
          ]
        : [])
    ],
    {
      Header: "S.No",
      accessor: "sNo",
      width: 90,
      size: 0.5
    },
    {
      Header: "Item/ SKU",
      accessor: "item",
      width: 200,
      size: 2,
      Cell: ({ row, value }) => {
        return row.original?.skuCode ? (
          <Box display={"flex"} flexDirection={"column"} gap={1}>
            <Box display={"flex"} gap={1} alignItems={"center"}>
              {row.original?.image?.thumbUrl && (
                <img
                  src={row.original?.image?.thumbUrl}
                  alt={row.original?.brandName ?? "--"}
                  height={52}
                  width={50}
                  style={{ objectFit: "contain" }}
                />
              )}
              <Typography
                fontSize={12}
                fontWeight={600}
                color={"primary"}
                component={Link}
              >
                {row.original?.skuCode}
              </Typography>
            </Box>
            <Typography fontSize={13} color={"text.secondary"}>
              {`HSN : ${row.original?.hsn ?? "--"}`}
            </Typography>
          </Box>
        ) : (
          value
        );
      }
    },
    {
      Header: "Product",
      accessor: "product",
      width: 180,
      size: 2,
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          row.original?.productName && (
            <Box display={"flex"} flexDirection={"column"} gap={0.5}>
              <Typography fontSize={14}>{`Product: ${
                row.original?.productName ?? "--"
              }`}</Typography>
              <Typography fontSize={13} color={"text.secondary"}>{`Category: ${
                row.original?.categoryName ?? "--"
              }`}</Typography>
              <Typography fontSize={13} color={"text.secondary"}>{`Brand: ${
                row.original?.brandName ?? "--"
              }`}</Typography>
            </Box>
          )
        );
      }
    },
    {
      Header: "UOM",
      accessor: "uom",
      disableSortBy: true,
      width: 80,
      size: 1,
      Cell: ({ row }) => {
        return (
          row.original?.unit && (
            <Typography>{row.original?.unit?.name}</Typography>
          )
        );
      }
    },
    {
      Header: "Stock at Origin",
      accessor: "stockOrigin",
      disableSortBy: true,
      width: 135,
      size: 2,
      Cell: ({ row }) => {
        return row.original?.skuCode
          ? row.original?.stock?.origin?.closingQuantity
          : "";
      }
    },
    {
      Header: "Stock at Receiving",
      accessor: "stockReceiving",
      disableSortBy: true,
      width: 160,
      size: 1.5,
      Cell: ({ row }) => {
        return row.original?.skuCode
          ? row.original?.stock?.receiving?.closingQuantity
          : "";
      }
    },
    {
      Header: "To be transferred",
      accessor: "toBeTransferred",
      disableSortBy: true,
      width: 150,
      size: 1.5
    },
    {
      Header: "Reason",
      accessor: "reason",
      disableSortBy: true,
      width: 170,
      size: 1.5
    }
  ];
};

const getRows = (
  rows,
  control,
  isEditable,
  skuOptions = [],
  reasonsOptions = [],
  status,
  remove
) => {
  return rows.map((row, index) => ({
    index: index,
    sNo: index + 1,
    isEditable: isEditable,
    status: status,
    ...row,
    item: !row.item && (
      <DropDown
        control={control}
        name={`newOrders.${index}.item`}
        options={dropdownOptions(skuOptions, "_id", "skuCode")}
        placeholder={
          Boolean(skuOptions?.length) ? "Select SKU" : "Select Warehouses"
        }
        unSelectText={
          Boolean(skuOptions?.length) ? "Select SKU" : "Select Warehouses"
        }
        availUnselect={!Boolean(skuOptions?.length)}
        allowSearch
      />
    ),
    toBeTransferred:
      isEditable && Boolean(status) ? (
        row.toBeTransferred
      ) : (
        <TextInput
          control={control}
          name={`newOrders.${index}.toBeTransferred`}
          type="number"
          placeholder={"qty"}
        />
      ),
    reason:
      isEditable && Boolean(status) ? (
        row.reasonDisplay
      ) : (
        <DropDown
          control={control}
          name={`newOrders.${index}.reason`}
          options={reasonsOptions}
          placeholder={"Select Reason"}
        />
      ),
    remove: remove
  }));
};

const StockTransferOrderTable = ({
  isPrint = false,
  fields,
  control,
  watch,
  append,
  remove,
  onRowSelectionChange,
  orderId,
  options,
  SKUs,
  status,
  isEditable,
  onAdd,
  preparedBy
}) => {
  const skuWatch = watch(
    `newOrders.${fields.length ? fields.length - 1 : 0}.item`
  );

  useEffect(() => {
    if (skuWatch && fields.length > 0) {
      const skuData = SkusById(SKUs, skuWatch);
      remove(fields.length - 1);
      append({ ...skuData[0] });
    }
  }, [skuWatch, fields.length, remove, append, SKUs]);

  return (
    <>
      {isPrint ? (
        <Grid container>
          <PrintableTable
            columns={getColumns(isEditable, status)}
            rows={getRows(
              fields,
              control,
              isEditable,
              SKUs,
              options?.reasons,
              status
            )}
            docType="StockTransfer"
          />
        </Grid>
      ) : (
        <>
          {(status === "" || (status === "Draft" && !isEditable)) && (
            <Grid item xs={12} sx={{ display: { xs: "block", md: "none" } }}>
              <IconButton onClick={onAdd}>
                <AddCircleOutline color="primary" />
              </IconButton>
            </Grid>
          )}
          <CustomTable
            columns={getColumns(isEditable, status)}
            data={getRows(
              fields,
              control,
              isEditable,
              SKUs,
              options?.reasons,
              status,
              remove
            )}
            onRowSelectionChange={onRowSelectionChange}
            mobileComponent={MobileSTTable}
          />
        </>
      )}
      <Grid
        container
        spacing={2}
        sx={{ display: { xs: isPrint ? "flex" : "none", md: "flex" } }}
      >
        <Grid item xs={12}>
          <Box display={"flex"} flexDirection={"column"} gap={6}>
            <Box width={"500px"}>
              <TextArea
                control={control}
                name={"remarks"}
                label={purchase_labels.remarksLabel}
                disabled={Boolean(status) ? isEditable : false}
              />
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={2.5}>
              {reportLabel2(purchase_labels.preparedByLabel, preparedBy)}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default StockTransferOrderTable;
