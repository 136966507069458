import CompleteOrder from "../components/CompleteOrder";

const BookOrder = (props) => {
  return (
    <>
      <CompleteOrder />
    </>
  );
};

export default BookOrder;
