import React, { useMemo, useState } from "react";
import { CustomTabs } from "../../../shared/customTab/CustomTabs";
import { Backdrop, Card, CircularProgress } from "@mui/material";
import { useFetchMrrDocuments } from "../../../query-hooks/ClientOrderMRR/useFetchMrrDocuments";
import MrrTable from "./MrrTable";
import CreateCreditNote from "./CreateCreditNote";
import { getIdsByDocType } from "../GatePass/GatePassTabs";
import { statusKeys } from "../../../constants";
import { isMobileDevice } from "../../../App";

const getDraftItems = (mrrItems, formDataItems) => {
  const skuSet = new Set(mrrItems?.map((item) => item?.sku));
  const filterFormDataItems = formDataItems?.filter(
    (item) => !skuSet.has(item.sku)
  );
  filterFormDataItems?.forEach((obj) => {
    obj.quantity = 0;
  });
  return [...mrrItems, ...filterFormDataItems];
};

const MrrTabs = ({
  orderId,
  MrrId,
  expanded,
  isSaleReturnType,
  MrrData,
  FormData,
  rejectOptions,
  orderInfo,
  orderStatus,
  isTaxApplicable,
  refetchMrrs,
  refetchFormData,
  CNCreateAllowed
}) => {
  const [MrrTab, setMrrTab] = useState(0);

  const handleTabChange = (index) => {
    setMrrTab(index);
  };

  const {
    data: Documents,
    isFetching: isFetchingDocs,
    refetch: refectDocs
  } = useFetchMrrDocuments(
    orderId,
    MrrId,
    expanded,
    isSaleReturnType,
    MrrData?.status?.name === "Placed"
  );

  const draftItems = useMemo(() => {
    return getDraftItems(MrrData?.items ?? [], FormData?.order?.items ?? []);
  }, [MrrData, FormData]);

  const createMrr = (
    <MrrTable
      orderId={orderId}
      rejectOptions={rejectOptions}
      MRRStatus={MrrData?.status?.name}
      tableData={
        MrrData?.status?.name === "Draft" ? draftItems : MrrData?.items
      }
      orderInfo={orderInfo}
      orderStatus={orderStatus}
      MRRInfo={MrrData}
      isTaxApplicable={isTaxApplicable}
      refetchMrrs={refetchMrrs}
      refetchFormData={refetchFormData}
    />
  );

  const InvoiceData = getIdsByDocType(Documents?.documents, "Credit Note");

  const isInvoicePlaced = InvoiceData
    ? Boolean(InvoiceData?.status?.name !== statusKeys.Draft)
    : false;

  const invoice = (
    <CreateCreditNote
      orderId={orderId}
      MrrId={MrrId}
      invId={InvoiceData?._id}
      orderType={Documents?.order?.orderType?.name}
      orderInfo={Documents?.order}
      InvoiceData={InvoiceData}
      InvCreateAllowed={CNCreateAllowed}
      refectDocs={refectDocs}
    />
  );

  const tabs = [
    {
      label: MrrData?.code ?? "Draft",
      Component: createMrr
    },
    ...(isInvoicePlaced ||
    (isSaleReturnType && CNCreateAllowed && MrrData?.status?.name === "Placed")
      ? [
          {
            label: `Credit Note`,
            Component: invoice
          }
        ]
      : [])
  ];

  return isFetchingDocs ? (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isFetchingDocs}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : isInvoicePlaced ||
    (isSaleReturnType &&
      CNCreateAllowed &&
      MrrData?.status?.name === "Placed") ? (
    <Card
      sx={{
        p: { xs: 0, md: 1 }
      }}
    >
      <CustomTabs
        tabs={tabs}
        activeTab={MrrTab}
        onChange={handleTabChange}
        fixTab={isMobileDevice}
      />
    </Card>
  ) : (
    createMrr
  );
};

export default MrrTabs;
