import { queryKeys } from "../../constants/queryKeys";
import { getDCFormData } from "../../inventory/services";
import { useQuery } from "@tanstack/react-query";

export const useFetchDCFormData = (dcId, gatePassId) => {
  const DCFormData = useQuery(
    [queryKeys.queryFetchDCFormData, gatePassId, Boolean(dcId)],
    () => getDCFormData(gatePassId),
    {
      enabled: !Boolean(dcId) && Boolean(gatePassId),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return DCFormData;
};
