import { deleteDCInvoiceDraft } from "../../finance/services";
import { useMutation } from "@tanstack/react-query";

export const useDeleteDCInvoiceDraft = () => {
  const deleteDraft = useMutation(({ invoiceId }) =>
    deleteDCInvoiceDraft(invoiceId)
  );

  return deleteDraft;
};
