import { Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import { currencyOnlyFormatter } from "../../shared/utils";

const OrderAmountCard = (props) => {
  const { Title, Label1, Label2, Amount1, Amount2 } = props;
  return (
    <Card
      sx={{
        display: "block",
        boxShadow: "2px 4px 9px 0px #00000040",
        background: "#F0F2F5",
        padding: "12px 15px",
        gap: "0px",
        borderRadius: "10px",
        justifyContent: "space-between"
      }}
    >
      <CardContent>
        <Typography
          sx={{
            fontSize: { xs: "14px", md: "15px" },
            fontWeight: 400,
            lineHeight: { xs: "21px", md: "22.5px" },
            textAlign: "left",
            color: "#0F2744"
          }}
        >
          {Title}
        </Typography>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            width={"100%"}
          >
            <Typography
              sx={{
                fontSize: { xs: "10px", md: "15px" },
                fontWeight: 400,
                lineHeight: { xs: "15px", md: "22.5px" },
                color: "#607088"
              }}
            >
              {Label1}
            </Typography>
            <Typography
              sx={{
                color: "#2773FF",
                fontSize: { xs: "16px", md: "36px" },
                fontWeight: 400,
                lineHeight: { xs: "24px", md: "54px" }
              }}
            >
              {currencyOnlyFormatter(parseFloat(Amount1).toFixed(0))}
            </Typography>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            width={"100%"}
          >
            <Typography
              sx={{
                fontSize: { xs: "10px", md: "15px" },
                fontWeight: 400,
                lineHeight: { xs: "15px", md: "22.5px" },
                color: "#607088"
              }}
            >
              {Label2}
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "12px", md: "32px" },
                fontWeight: 400,
                lineHeight: { xs: "18px", md: "48px" },
                color: "#FF8A8A"
              }}
            >
              {currencyOnlyFormatter(parseFloat(Amount2).toFixed(0))}
            </Typography>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            width={"100%"}
          >
            <Typography
              sx={{
                fontSize: { xs: "10px", md: "15px" },
                fontWeight: 400,
                lineHeight: { xs: "15px", md: "22.5px" },
                color: "#607088"
              }}
            >
              {Label2}
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "12px", md: "32px" },
                fontWeight: 400,
                lineHeight: { xs: "18px", md: "48px" },
                color: "#F9A90E"
              }}
            >
              {currencyOnlyFormatter(parseFloat(Amount2).toFixed(0))}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default OrderAmountCard;
