import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { purchase_labels } from "../../constants";

import CustomTable from "../../shared/customTable";
import { StyledChip } from "../../shared/UiElements/StatusChip";
import appRoutes from "../../constants/appRoutes";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { getCustomCell } from "./PurchaseOrders";
import SuccessfulDialog from "../../shared/UiElements/SuccessfulDialog";
import { useCustomDialog } from "../../shared/customDialog";
import { useFetchStockAdjustments } from "../../query-hooks/ClientPurchase/StockAdjustment/useFetchStockAdjustments";
import { useInView } from "react-intersection-observer";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import { useApproveStockAdjustment } from "../../query-hooks/ClientPurchase/StockAdjustment/useApproveStockAdjustment";
import { useCancelStockAdjustment } from "../../query-hooks/ClientPurchase/StockAdjustment/useCancelStockAdjustment";
import { AppContext } from "../../shared/context/auth-context";
import { MobileAdjustmentOrders } from "../components/Purchase/MobileAdjustmentOrders";
import { ArrowBack } from "@mui/icons-material";

const getColumns = (onAction, CustomerAdmin) => {
  return [
    {
      Header: "Doc. No.",
      accessor: "docNo",
      Cell: ({ row, value }) => {
        return getCustomCell(
          value,
          "",
          `${appRoutes.purchase.main}/${appRoutes.purchase.stockAdjustment}/${row.original.SA_id}/manage-order`
        );
      },
      show: false
    },
    {
      Header: "Adjustment. Date",
      accessor: "adjustmentDate",
      width: 170
    },
    {
      Header: "Warehouse",
      accessor: "warehouse",
      width: 184
    },
    {
      Header: "Prepared By",
      accessor: "preparedBy"
    },
    {
      Header: "Approved By",
      accessor: "approvedBy",
      width: 154
    },
    {
      Header: "Status",
      accessor: "orderProgress",
      width: 184,
      Cell: ({ row, value }) => {
        return (
          <StyledChip
            label={value}
            onClick={() =>
              value === "Placed" &&
              CustomerAdmin &&
              onAction(row.original.SA_id)
            }
          />
        );
      }
    }
  ];
};

const getRows = (rows = [], isAdmin, onAction) => {
  return rows.map((row, index) => ({
    SA_id: row?._id,
    docNo: row?.code ?? "SA-Draft",
    adjustmentDate: moment(row?.orderDate).format("DD-MM-YYYY"),
    warehouse: row?.clientData?.warehouse?.code,
    preparedBy: `${row?.preparedBy?.firstName} ${
      row?.preparedBy?.lastName ?? ""
    }`,
    approvedBy: row?.approvedBy
      ? `${row?.approvedBy?.firstName} ${row?.approvedBy?.lastName}`
      : "",
    orderProgress: row?.status?.name,
    isAdmin: isAdmin,
    onAction: onAction
  }));
};

const StockAdjustMentsOrders = () => {
  const navigate = useNavigate();
  const { showDialog, hideDialog } = useCustomDialog();
  const { ref, inView } = useInView();
  const { profileData } = useContext(AppContext);
  const { data, isFetched, hasNextPage, fetchNextPage, refetch } =
    useFetchStockAdjustments();

  const {
    mutate: cancelSA,
    isError: isCancelSAError,
    error: cancelSAError
  } = useCancelStockAdjustment();

  const {
    mutate: approveSA,
    isError: isApproveSAError,
    error: approveSAError
  } = useApproveStockAdjustment();

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, inView]);

  const onApprove = useCallback(
    (data) => {
      approveSA(
        {
          orderId: data
        },
        {
          onSuccess: (res) => {
            hideDialog();
            refetch();
            enqueueSnackbar("Stock Transfer Approved!", {
              variant: "success"
            });
          }
        }
      );
    },
    [approveSA, hideDialog, refetch]
  );

  const onReject = useCallback(
    (data) => {
      cancelSA(
        {
          orderId: data
        },
        {
          onSuccess: (res) => {
            hideDialog();
            refetch();
            enqueueSnackbar("Stock Transfer Rejected!", {
              variant: "success"
            });
          }
        }
      );
    },
    [cancelSA, hideDialog, refetch]
  );

  const onAction = useCallback(
    (row) => {
      showDialog({
        component: (
          <SuccessfulDialog
            text={
              "I have verified the Stock Adjustment Report and physically checked the stock count."
            }
            btn1Text={"Approve"}
            btn2Text={"Reject"}
            btn1Callback={() => onApprove(row)}
            btn2Callback={() => onReject(row)}
            btn1Variant={"contained"}
            btn2Variant={"softError"}
          />
        )
      });
    },
    [onApprove, onReject, showDialog]
  );

  const stockAdjustmentDashboard = useMemo(
    () => (
      <CustomTable
        columns={getColumns(
          onAction,
          Boolean(profileData?.user?.role === "CustomerAdmin")
        )}
        data={
          isFetched
            ? data?.pages?.flatMap((page) =>
                getRows(
                  page?.stockAdjustments,
                  Boolean(profileData?.user?.role === "CustomerAdmin"),
                  onAction
                )
              ) ?? []
            : []
        }
        isLoading={!isFetched}
        mobileComponent={MobileAdjustmentOrders}
      />
    ),
    [data?.pages, isFetched, onAction, profileData?.user?.role]
  );

  useEffect(() => {
    if (isCancelSAError) {
      enqueueSnackbar(cancelSAError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isApproveSAError) {
      enqueueSnackbar(approveSAError?.response?.data?.message, {
        variant: "error"
      });
    }
  }, [
    approveSAError?.response?.data?.message,
    isApproveSAError,
    cancelSAError?.response?.data?.message,
    isCancelSAError
  ]);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      sx={{
        mb: { xs: 9, md: 0 }
      }}
    >
      <Box
        sx={{
          background: "#EEF1F4",
          display: { xs: "flex", md: "none" },
          minHeight: "80px",
          justifyContent: "flex-start",
          alignItems: "center",
          borderBottom: "1px solid #B7BEC7",
          boxShadow: "0px 8px 24px 0px #1515160A",
          pl: "30px",
          gap: 1
        }}
      >
        <Box>
          <IconButton
            onClick={() => {
              navigate(`${appRoutes.purchase.main}`);
            }}
            sx={{ marginRight: 1, marginLeft: -1 }}
          >
            <ArrowBack />
          </IconButton>
        </Box>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "19.36px",
            letterSpacing: "0.002em"
          }}
        >
          {"Stock Adjustments"}
        </Typography>
      </Box>
      <Grid
        item
        xs={12}
        sx={{
          display: { xs: "flex", md: "none" },
          justifyContent: "right",
          alignItems: "center",
          pr: "40px"
        }}
      >
        <Button
          variant="link"
          disableRipple
          onClick={() =>
            navigate(
              `${appRoutes.purchase.main}/${appRoutes.purchase.stockAdjustment}/${appRoutes.purchase.createOrder}`
            )
          }
          sx={{ fontWeight: 700 }}
        >
          {purchase_labels.createMobileAdjustmentButton}
        </Button>
      </Grid>
      <Grid container spacing={2} sx={{ display: { xs: "none", md: "block" } }}>
        <Grid item xs={12}>
          <IconButton
            onClick={() => {
              navigate(
                `${appRoutes.purchase.main}/${appRoutes.purchase.purchaseOrders}`
              );
            }}
            sx={{ marginRight: 1, marginLeft: -1 }}
          >
            <ArrowBack />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          container
          display={"flex"}
          justifyContent={"space-between"}
        >
          <Grid item>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 400,
                lineHeight: "20px",
                textAlign: "left"
              }}
            >
              {"STOCK ADJUSTMENTS"}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="link"
              disableRipple
              onClick={() =>
                navigate(
                  `${appRoutes.purchase.main}/${appRoutes.purchase.stockAdjustment}/${appRoutes.purchase.createOrder}`
                )
              }
            >
              {purchase_labels.createAdjustmentButton}
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ border: "0.7px solid #B7BEC7" }} />
        </Grid>
      </Grid>
      <Box sx={{ padding: { xs: "0 15px", md: 0 } }}>
        {stockAdjustmentDashboard}
      </Box>
      {hasNextPage && (
        <Grid
          item
          xs={12}
          ref={ref}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress />
        </Grid>
      )}
    </Box>
  );
};

export default StockAdjustMentsOrders;
