import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import Joyride from "react-joyride";

function CustomTooltip(props) {
  const {
    continuous,
    primaryProps,
    skipProps,
    step,
    tooltipProps,
    index,
    size
  } = props;
  return (
    <Grid
      container
      height={90}
      width={200}
      sx={{ background: "#fff" }}
      p={1}
      borderRadius={2}
      {...tooltipProps}
    >
      <Grid
        item
        xs={12}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Typography
          sx={{
            fontSize: 12,
            lineHeight: "14px",
            color: step.styles.options.textColor,
            textAlign: "center"
          }}
        >
          {step.content}
        </Typography>
      </Grid>
      <Grid
        item
        xs={5.5}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Button
          variant="outlined"
          sx={{
            fontSize: 12,
            lineHeight: "14px",
            color: step.styles.options.primaryColor,
            borderColor: step.styles.options.primaryColor,
            height: 28
          }}
          {...skipProps}
        >
          {"SKIP"}
        </Button>
      </Grid>
      <Grid
        item
        xs={6.5}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        {continuous && (
          <Button
            variant="contained"
            sx={{
              fontSize: 12,
              lineHeight: "14px",
              backgroundColor: step.styles.options.primaryColor,
              height: 28
            }}
            {...primaryProps}
          >
            {index === size - 1 ? "Finish" : primaryProps.title}
          </Button>
        )}
      </Grid>
    </Grid>
  );
}

const CustomTour = ({
  run = true,
  steps = [],
  stepIndex,
  isWarehouse,
  handleJoyrideCallback = () => {}
}) => {
  return (
    <Joyride
      continuous
      tooltipComponent={CustomTooltip}
      callback={handleJoyrideCallback}
      run={run}
      steps={steps}
      stepIndex={stepIndex}
      hideCloseButton
      hideBackButton
      showSkipButton
      showProgress
      disableOverlayClose
      disableScrolling
      // spotlightClicks
      styles={{
        options: {
          primaryColor: isWarehouse ? "#2773FF" : "#F9A90E",
          textColor: isWarehouse ? "#F9A90E" : "#2773FF",
          width: 200,
          height: 300,
          zIndex: 9999
        }
        // spotlight: {
        //   pointerEvents: "auto"
        // }
      }}
    />
  );
};

export default CustomTour;
