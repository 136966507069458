import { deleteSODraft } from "../../../sales/services";
import { useMutation } from "@tanstack/react-query";

export const useDeleteSODraft = () => {
  const deleteDraft = useMutation(({ salesOrderId }) =>
    deleteSODraft(salesOrderId)
  );

  return deleteDraft;
};
