import { Auth, Hub } from "aws-amplify";
import { useCallback, useEffect, useState } from "react";

import Storage from "../utils/Storage";

export const getCurrentUser = async () => {
  try {
    return await Auth.currentAuthenticatedUser();
  } catch {
    // currentAuthenticatedUser throws an Error if not signed in
    return null;
  }
};

export const useAuth = () => {
  // const [token, setToken] = useState(false);
  // const [idToken, setIdToken] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  // const login = useCallback((token, idToken) => {
  //   return Storage.getItem(StorageKeys.ID_TOKEN);
  // }, []);

  // useEffect(() => {
  //   const checkTokenRefresh = async () => {
  //     await Auth.currentSession()
  //       .then((res) => {
  //         // setToken(res.getAccessToken().getJwtToken());
  //         // setIdToken(res.getIdToken().getJwtToken());

  //         Storage.setItem(
  //           StorageKeys.ACCESS_TOKEN,
  //           res.getAccessToken().getJwtToken()
  //         );
  //         Storage.setItem(StorageKeys.ID_TOKEN, res.getIdToken().getJwtToken());
  //       })
  //       .catch((err) => console.error(err));
  //   };

  //   checkTokenRefresh();
  // }, []);

  useEffect(() => {
    const updateUser = async () => {
      setCurrentUser(await getCurrentUser());
    };
    const listener = Hub.listen("auth", updateUser); // listen for login/signup events
    updateUser(); // check manually the first time because we won't get a Hub event
    // return () => Hub.remove("auth", updateUser);
    return () => listener();
  }, []);

  // const setLoginToken = useCallback((token, idToken) => {
  //   setToken(token);
  //   setIdToken(idToken);
  //   Storage.setItem(StorageKeys.ACCESS_TOKEN, token);
  //   Storage.setItem(StorageKeys.ID_TOKEN, idToken);
  // }, []);

  const logout = useCallback(() => {
    Auth.signOut();
    // setToken(null);
    // setIdToken(null);
    Storage.clear();
  }, []);

  // useEffect(() => {
  //   const accessToken = Storage.getItem(StorageKeys.ACCESS_TOKEN);
  //   const idToken = Storage.getItem(StorageKeys.ID_TOKEN);
  //   if (accessToken && idToken) {
  //     setLoginToken(accessToken, idToken);
  //   }
  // }, [setLoginToken]);

  return { logout, currentUser };
};
