import { Box, Button, Grid } from "@mui/material";
import { add_customer_fiterKeys } from "../../../constants/formKeys";
import { add_customer_labels } from "../../../constants";
import TextInput from "../../../shared/formElements/TextInput";
import AutoCompleteMapField from "../../../shared/formElements/AutoCompleteMapField";
import DropDown from "../../../shared/formElements/DropDown";
import { useEffect } from "react";
import { useFetchShipToById } from "../../../query-hooks/ClientsCustomer/CustomerShipToAddress/useFetchShipToById";
import { useUpdateShipToAddress } from "../../../query-hooks/ClientsCustomer/CustomerShipToAddress/useUpdateShipToAddress";
import { useCreateShipToAddress } from "../../../query-hooks/ClientsCustomer/CustomerShipToAddress/useCreateShipToAddress";
import { enqueueSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { reverseGeocode } from "../../../shared/utils/reverseGeocode";

const AddStoreForm = ({
  control,
  setValue,
  watch,
  handleSubmit,
  customerId,
  billingId,
  storeId,
  isFormDataFetching,
  options,
  setShowShipToForm,
  handleCardClick,
  refetchStores,
  hideDrawer,
  hideLabel = false
}) => {
  const {
    data: storeIdData,
    isFetching: isFetchingStore,
    refetch: refetchStoreById
  } = useFetchShipToById(customerId, billingId, storeId);

  const {
    mutate: createStore,
    isLoading: isCreating,
    isError: isCreateError,
    error: createError
  } = useCreateShipToAddress();

  const {
    mutate: updateStore,
    isLoading: isUpdating,
    isError: isUpdateError,
    error: updateError
  } = useUpdateShipToAddress();

  const area = watch(add_customer_fiterKeys.shipToArea);

  useEffect(() => {
    if (area && !storeId) {
      setValue(add_customer_fiterKeys.codeArea, area.slice(0, 6).toUpperCase());
    }
  }, [storeId, area, setValue]);

  useEffect(() => {
    if (!isFetchingStore) {
      const store = storeIdData?.store?.address;
      const contact = storeIdData?.store?.contact;
      Boolean(storeId) &&
        reverseGeocode(
          store?.location?.coordinates[0],
          store?.location?.coordinates[1]
        )
          .then((address) => {
            setValue(add_customer_fiterKeys.mapstore, address);
          })
          .catch((error) =>
            enqueueSnackbar(`Reverse geocoding failed: ${error}`, {
              variant: "success"
            })
          );
      setValue(add_customer_fiterKeys.shipToAddress, store?.address);
      setValue(add_customer_fiterKeys.shipToArea, store?.area);
      setValue(add_customer_fiterKeys.shipToCity, store?.city);
      setValue(add_customer_fiterKeys.shipToState, store?.state);
      setValue(add_customer_fiterKeys.shipToZipCode, store?.pincode);
      setValue(add_customer_fiterKeys.lat, store?.location?.coordinates[0]);
      setValue(add_customer_fiterKeys.lng, store?.location?.coordinates[1]);
      setValue(add_customer_fiterKeys.pointofContact, contact?.name);
      setValue(add_customer_fiterKeys.mobileNo, contact?.mobile);
      setValue(
        add_customer_fiterKeys.codeArea,
        storeIdData?.store?.code?.length > 3
          ? storeIdData?.store?.code?.substring(3)
          : ""
      );
    }
    if (!storeId) {
      setValue(add_customer_fiterKeys.mapstore, "");
      setValue(add_customer_fiterKeys.shipToAddress, "");
      setValue(add_customer_fiterKeys.shipToArea, "");
      setValue(add_customer_fiterKeys.shipToCity, "");
      setValue(add_customer_fiterKeys.shipToState, "");
      setValue(add_customer_fiterKeys.shipToZipCode, "");
      setValue(add_customer_fiterKeys.lat, "");
      setValue(add_customer_fiterKeys.lng, "");
      setValue(add_customer_fiterKeys.pointofContact, "");
      setValue(add_customer_fiterKeys.mobileNo, "");
      setValue(add_customer_fiterKeys.codeArea, "");
    }
    // eslint-disable-next-line
  }, [isFetchingStore, storeId, setValue]);

  const onSaveClick = (data) => {
    const StoreData = {
      address: {
        address: data[add_customer_fiterKeys.shipToAddress],
        area: data[add_customer_fiterKeys.shipToArea],
        city: data[add_customer_fiterKeys.shipToCity],
        state: data[add_customer_fiterKeys.shipToState],
        pincode: +data[add_customer_fiterKeys.shipToZipCode],
        location: {
          coordinates: [
            +data[add_customer_fiterKeys.lat],
            +data[add_customer_fiterKeys.lng]
          ]
        }
      },
      code: `ST-${data[add_customer_fiterKeys.codeArea]}`,
      contact: {
        name: data[add_customer_fiterKeys.pointofContact],
        mobile: data[add_customer_fiterKeys.mobileNo]
      }
    };
    storeId
      ? updateStore(
          {
            data: { ...StoreData },
            customerId: customerId,
            billingId: billingId,
            storeId: storeId
          },
          {
            onSuccess: () => {
              refetchStoreById();
              handleCardClick(null);
              setShowShipToForm(false);
              enqueueSnackbar(`Store Updated!`, {
                variant: "success"
              });
              refetchStores();
              hideDrawer();
            }
          }
        )
      : createStore(
          {
            data: { ...StoreData },
            customerId: customerId,
            billingId: billingId
          },
          {
            onSuccess: () => {
              setShowShipToForm(false);
              enqueueSnackbar(`Store Created!`, {
                variant: "success"
              });
              refetchStores();
              hideDrawer();
            }
          }
        );
  };

  useEffect(() => {
    if (isCreateError)
      enqueueSnackbar(createError?.response?.data?.message, {
        variant: "error"
      });
    if (isUpdateError)
      enqueueSnackbar(updateError?.response?.data?.message, {
        variant: "error"
      });
  }, [
    updateError?.response?.data?.message,
    createError?.response?.data?.message,
    isUpdateError,
    isCreateError
  ]);

  const DetailForm = (
    <Grid item xs={12} container spacing={2} p={hideLabel && "15px 30px"}>
      <Grid item xs={12}>
        <AutoCompleteMapField
          control={control}
          name={add_customer_fiterKeys.mapstore}
          label={!hideLabel && add_customer_labels.mapstore}
          placeholder={add_customer_labels.mapstore}
          mandatoryField
          reset={(address, location) => {
            setValue(add_customer_fiterKeys.mapstore, address);
            setValue(add_customer_fiterKeys.lat, location.lat);
            setValue(add_customer_fiterKeys.lng, location.lng);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          control={control}
          name={add_customer_fiterKeys.shipToAddress}
          placeholder={"Address"}
          mandatoryField
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          control={control}
          name={add_customer_fiterKeys.shipToArea}
          placeholder={add_customer_labels.area}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          control={control}
          name={add_customer_fiterKeys.shipToCity}
          placeholder={add_customer_labels.city}
        />
      </Grid>
      <Grid item xs={6}>
        <DropDown
          control={control}
          name={add_customer_fiterKeys.shipToState}
          placeholder={add_customer_labels.state}
          isLoading={isFormDataFetching}
          options={options}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          control={control}
          name={add_customer_fiterKeys.shipToZipCode}
          placeholder={add_customer_labels.code}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          control={control}
          name={add_customer_fiterKeys.pointofContact}
          placeholder={add_customer_labels.pointofContact}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          control={control}
          name={add_customer_fiterKeys.mobileNo}
          placeholder={add_customer_labels.mobileNo}
        />
      </Grid>
      <Grid item xs={12} md={6} container>
        <Grid item xs={2.5} md={4}>
          <Box
            sx={{
              border: "1px solid #B7BEC7",
              borderRadius: "8px",
              padding: "10px 16px 10px 16px",
              fontWeight: 500
            }}
          >
            {"ST"}
          </Box>
        </Grid>
        <Grid item xs={9.5} md={8}>
          <TextInput
            control={control}
            name={add_customer_fiterKeys.codeArea}
            placeholder={add_customer_labels.codeArea}
          />
        </Grid>
      </Grid>
      {billingId && (
        <Grid
          item
          xs={6}
          sx={{ display: { xs: "none", md: "flex" } }}
          justifyContent={"right"}
          alignItems={"center"}
        >
          <Button
            variant="text"
            component={LoadingButton}
            loading={isCreating || isUpdating}
            onClick={handleSubmit(onSaveClick)}
          >
            {"Save"}
          </Button>
        </Grid>
      )}
    </Grid>
  );

  return (
    <>
      {DetailForm}
      {billingId && (
        <Grid
          container
          sx={{ display: { xs: "flex", md: "none" }, background: "#F0F2F5" }}
          justifyContent={"center"}
          alignItems={"center"}
          minHeight={"72px"}
        >
          <Button
            variant="contained"
            sx={{ width: "100px", height: "40px" }}
            component={LoadingButton}
            loading={isCreating || isUpdating}
            onClick={handleSubmit(onSaveClick)}
          >
            {"Save"}
          </Button>
        </Grid>
      )}
    </>
  );
};

export default AddStoreForm;
