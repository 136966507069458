import { Box, Typography } from "@mui/material";

import Bengaluru from "../../assets/bengaluru.webp";
import BengaluruMobile from "../../assets/Bengaluru_mobile.webp";
import Footer from "../components/Footer";
import HomeButtons from "../components/HomeButtons";
import React, { useEffect } from "react";
import SolutionsSection from "../components/SolutionsSection";
import { packersMovers_label } from "../../constants";
import styled from "@emotion/styled";

const StyledHeaderText = styled(Typography)((props) => ({
  fontSize: props.isMobile ? "24px" : "32px",
  fontWeight: 700,
  lineHeight: props.isMobile ? "40px" : "64px",
  letterSpacing: props.isMobile ? "-0.48px" : "-0.64px"
}));

const StyledTextDescription = styled(Typography)((props) => ({
  fontSize: "22px",
  fontWeight: props.title ? 700 : 400,
  lineHeight: "33px",
  letterSpacing: "-0.22px"
}));

const MoverPackersBenguluru = () => {
  useEffect(() => {
    window.scroll({
      left: 0,
      top: 0,
      behavior: "smooth"
    });
  }, []);
  return (
    <>
      <Box
        sx={{
          padding: "38px 85px 20px 100px",
          display: { xs: "none", md: "flex" },
          flexDirection: "column"
        }}
        gap={4}
      >
        <StyledHeaderText color={"#F9A90E"} component="h1">
          {`${packersMovers_label.titleText} `}
          <span style={{ color: "#2773FF" }}>
            {packersMovers_label.titleBengaluru}
          </span>
        </StyledHeaderText>
        <Box display={"flex"} flexDirection={"column"} gap={20.5}>
          <Box
            position={"relative"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <img
              src={Bengaluru}
              alt={"Bengaluru"}
              style={{
                maxWidth: "100%",
                width: "100%"
              }}
            />
            <Box
              sx={{
                position: "absolute",
                bottom: -125
              }}
            >
              <HomeButtons
                isLanding={false}
                ButtonText1={packersMovers_label.storeButtonText}
                ButtonText2={packersMovers_label.shiftButtonText}
                ButtonSubText1={packersMovers_label.storeButtonSubText}
                ButtonSubText2={packersMovers_label.shiftButtonSubText}
              />
            </Box>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            textAlign={"justify"}
            gap={2}
          >
            <Box>
              <StyledTextDescription
                color={"#2773FF"}
                display={"inline"}
                title={true}
              >
                {"SHIFT "}
              </StyledTextDescription>
              <StyledTextDescription
                color={"text.secondary"}
                display={"inline"}
              >
                {packersMovers_label.bengaluruShiftDescription}
                <b>{"avail best prices "}</b>{" "}
                {packersMovers_label.commonShiftDescriptionText1}
              </StyledTextDescription>
            </Box>
            <Box>
              <StyledTextDescription
                color={"#F9A90E"}
                display={"inline"}
                title={true}
              >
                {"STORE "}
              </StyledTextDescription>
              <StyledTextDescription
                color={"text.secondary"}
                display={"inline"}
              >
                {packersMovers_label.storeDescription}
                <b>{"pay as you go. "}</b>
              </StyledTextDescription>
            </Box>
          </Box>
        </Box>
        <Box display={"flex"} flexDirection={"row"} gap={"41px"}>
          <Box>
            <StyledTextDescription
              color={"text.secondary"}
              textAlign={"justify"}
              title={false}
            >
              {packersMovers_label.secondaryDescription}
            </StyledTextDescription>
          </Box>
          <Box>
            <iframe
              loading="lazy"
              width="450"
              height="250"
              title="Warex-Video"
              src="https://www.youtube.com/embed/avXCghyXXU8?playlist=avXCghyXXU8&loop=1"
            ></iframe>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{ display: { xs: "flex", md: "none" } }}
        flexDirection={"column"}
        width={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={4}
      >
        <StyledHeaderText
          padding={"0px 32px !important"}
          color={"#F9A90E"}
          isMobile={true}
          textAlign={"center"}
          component="h1"
        >
          {`${packersMovers_label.titleText} `}
          <span style={{ color: "#2773FF" }}>
            {packersMovers_label.titleBengaluru}
          </span>
        </StyledHeaderText>
        <Box display={"flex"} flexDirection={"column"} gap={20}>
          <Box
            position={"relative"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <img
              src={BengaluruMobile}
              alt={"Bengaluru"}
              style={{
                maxWidth: "100%",
                width: "100%"
              }}
            />
            <Box
              sx={{
                position: "absolute",
                bottom: -105
              }}
            >
              <HomeButtons
                isLanding={false}
                ButtonText1={packersMovers_label.storeButtonText}
                ButtonText2={packersMovers_label.shiftButtonText}
                ButtonSubText1={packersMovers_label.storeButtonSubText}
                ButtonSubText2={packersMovers_label.shiftButtonSubText}
              />
            </Box>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            textAlign={"justify"}
            gap={2}
            padding={"0px 20px"}
          >
            <Box>
              <StyledTextDescription
                color={"#2773FF"}
                display={"inline"}
                title={true}
              >
                {"SHIFT "}
              </StyledTextDescription>
              <StyledTextDescription
                color={"text.secondary"}
                display={"inline"}
              >
                {packersMovers_label.bengaluruShiftDescription}
                <b>{"avail best prices "}</b>{" "}
                {packersMovers_label.commonShiftDescriptionText1}
              </StyledTextDescription>
            </Box>
            <Box>
              <StyledTextDescription
                color={"#F9A90E"}
                display={"inline"}
                title={true}
              >
                {"STORE "}
              </StyledTextDescription>
              <StyledTextDescription
                color={"text.secondary"}
                display={"inline"}
              >
                {packersMovers_label.storeDescription}
                <b>{"pay as you go. "}</b>
              </StyledTextDescription>
            </Box>
          </Box>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={"11px"}
          alignItems={"center"}
        >
          <Box>
            <iframe
              loading="lazy"
              width="320"
              height="240"
              title="Warex-Video"
              src="https://www.youtube.com/embed/avXCghyXXU8?playlist=avXCghyXXU8&loop=1"
            ></iframe>
          </Box>
          <Box padding={"0px 20px"}>
            <StyledTextDescription
              color={"text.disabled"}
              textAlign={"justify"}
              title={false}
            >
              {packersMovers_label.secondaryDescription}
            </StyledTextDescription>
          </Box>
        </Box>
      </Box>
      <SolutionsSection sx={{ pb: { xs: "37px", md: "57px" } }} />
      <Footer />
    </>
  );
};

export default MoverPackersBenguluru;
