import { deleteCreditNoteDraft } from "../../finance/services";
import { useMutation } from "@tanstack/react-query";

export const useDeleteCreditNoteDraft = () => {
  const deleteDraft = useMutation(({ cnId }) =>
    deleteCreditNoteDraft(cnId)
  );

  return deleteDraft;
};
