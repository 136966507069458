import { Box, Button, Grid, IconButton, Typography } from "@mui/material";

import { AppContext } from "../../shared/context/auth-context";
import CartItemCard from "./CartItemCard";
import { Close } from "@mui/icons-material";
import Storage from "../../shared/utils/Storage";
import { StorageKeys } from "../../constants/storage-keys";
import appRoutes from "../../constants/appRoutes";
import { appServiceKeys } from "../../constants/formKeys";
import { cartServiceKeys } from "../../constants/formKeys";
import { labels } from "../../constants";
import { useContext } from "react";
import { useDrawer } from "../../shared/customSideDrawer";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../shared/hooks/useAuth";

const Cart = ({ updateTabs, onRemove, checkIsLoggedIn }) => {
  const { currentUser } = useAuth();
  const { hideDrawer } = useDrawer();
  const { cart, setCrumbStack } = useContext(AppContext);
  const navigate = useNavigate();
  const service = Storage.getItem(StorageKeys.serviceLocalStorage);
  const searchFormParams = Storage.getItem(StorageKeys.SEARCH_FORM_PARAMS);

  const propsCheck = (item, type) => {
    return Object.keys(item ?? {}).length === 0
      ? { addContainerPlaceholder: true }
      : {
          title: item?.code,
          city: item?.address.city,
          state: item?.address.state,
          cluster: item?.address.cluster,
          addonFees: item?.costs.additionalServiceCost,
          totalAmount: item?.costs.totalAmount,
          id: item?._id,
          rateUnit:
            type === cartServiceKeys.warehouse
              ? item?.storageTypes[0].rateUnit
              : item?.vehicles[0].rateUnit,
          thumbnail:
            type === cartServiceKeys.warehouse
              ? item?.heroImage?.thumbUrl
              : item?.vehicles?.[0]?.heroImage?.thumbUrl,
          ...{
            ...(type === cartServiceKeys.warehouse
              ? {
                  monthlyRental: item?.storageTypes[0].rate
                }
              : {
                  monthlyRental: item?.vehicles?.[0]?.rate
                })
          }
        };
  };

  const handleButtonClick = () => {
    let redirectionURL = appRoutes.completeOrder.serviceBookOrderRedirection;
    if (Object.keys(cart[cartServiceKeys.warehouse] ?? {}).length !== 0) {
      redirectionURL += `${appServiceKeys.warehouse}/${
        cart[cartServiceKeys.warehouse]._id
      }/`;
    }
    if (Object.keys(cart[cartServiceKeys.transport] ?? {}).length !== 0) {
      redirectionURL += `${appServiceKeys.transport}/${
        cart[cartServiceKeys.transport]._id
      }`;
    } else {
      redirectionURL = redirectionURL.substring(0, redirectionURL.length - 1);
    }
    if (currentUser) {
      navigate(redirectionURL);
      setCrumbStack(redirectionURL, labels.completeYourOrder);
      hideDrawer();
    } else {
      checkIsLoggedIn();
    }
  };

  const getButtonLabel = () => {
    if (service.warehouse && service.transport) {
      if (Object.keys(cart[cartServiceKeys.warehouse] ?? {}).length === 0) {
        return labels.continueWithoutWarehouse;
      } else if (
        Object.keys(cart[cartServiceKeys.transport] ?? {}).length === 0
      ) {
        return labels.continueWithoutTransporter;
      } else return labels.continue;
    } else return labels.continue;
  };
  return (
    <Box>
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{
          padding: {
            xs: 2,
            md: "38px 38px 4px 38px"
          }
        }}
      >
        <Typography
          color={"black"}
          fontSize={20}
          fontWeight={500}
          letterSpacing={"0.04px"}
        >
          {labels.Cart}
        </Typography>
        <IconButton onClick={hideDrawer}>
          <Close />
        </IconButton>
      </Box>
      <Box
        sx={{
          height: "calc(100vh - 162px)",
          overflowY: "auto",
          padding: {
            xs: 2,
            md: "38px"
          }
        }}
      >
        <Grid container spacing={2}>
          {service.warehouse && searchFormParams && (
            <Grid item xs={12}>
              <CartItemCard
                {...propsCheck(
                  cart[cartServiceKeys.warehouse],
                  cartServiceKeys.warehouse
                )}
                placeholderLabel={labels.add + " " + labels.warehouse}
                onPlaceholderLabelClick={() => {
                  updateTabs(0);
                }}
                onRemoveCartItem={() => onRemove(cartServiceKeys.warehouse)}
                type={cartServiceKeys.warehouse}
              />
            </Grid>
          )}
          {service.transport && searchFormParams && (
            <Grid item xs={12}>
              <CartItemCard
                {...propsCheck(
                  cart[cartServiceKeys.transport],
                  cartServiceKeys.transport
                )}
                placeholderLabel={labels.add + " " + labels.transporter}
                onPlaceholderLabelClick={() => {
                  updateTabs(1);
                }}
                onRemoveCartItem={() => onRemove(cartServiceKeys.transport)}
                type={cartServiceKeys.transport}
              />
            </Grid>
          )}
          <Grid
            style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}
            sx={{
              my: {
                xs: 2,
                md: 0
              },
              px: {
                xs: 3,
                md: 0
              }
            }}
          >
            <Button
              variant="contained"
              sx={{
                fontWeight: 600,
                fontSize: 13,
                width: {
                  xs: "100%"
                },
                height: {
                  xs: 48,
                  md: 81
                },
                borderRadius: { xs: "8px", md: 0 }
              }}
              onClick={handleButtonClick}
              disabled={
                !cart[cartServiceKeys.warehouse] &&
                !cart[cartServiceKeys.transport]
              }
            >
              {getButtonLabel()}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Cart;
