import { Box, Container, IconButton, Typography, styled } from "@mui/material";
import {
  DraftsOutlined,
  FacebookOutlined,
  LinkedIn,
  PhoneInTalkOutlined,
  Twitter,
  YouTube,
} from "@mui/icons-material";

import { home_labels } from "../../constants";
import { isMobileDevice } from "../../App";

export const socialLinks = [
  {
    label: "Facebook",
    icon: FacebookOutlined,
  },
  {
    label: "Twitter",
    icon: Twitter,
  },
  {
    label: "YouTube",
    icon: YouTube,
    to:"https://www.youtube.com/@Warex2023"
  },
  {
    label: "Linkedin",
    icon: LinkedIn,
    to:"https://www.linkedin.com/company/ware-ex/"
  },
];

const StyledBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  padding: "18px",
  borderLeft: "1px solid #F0F2F5",
  borderRight: "1px solid #F0F2F5",
  "&:nth-of-type(odd)": {
    borderRight: 0,
  },
  "& > svg": {
    fontSize: 16,
  },
  "& > p": {
    marginLeft: "12px",
  },
  "& > button": {
    padding: 0,
    border: 0,
    height: "1rem",
    width: "1rem",
  },
  "& > button > svg": {
    color: "black",
    fontSize: 16,
  },
}));

const ContactComp = () => {
  return (
    <StyledBox>
      <PhoneInTalkOutlined />
      <Typography
        color={"text.secondary"}
        sx={{
          fontSize: {
            xs: 10,
            md: 12,
          },
          lineHeight: {
            xs: "14px",
            md: "16px",
          },
        }}
      >
        {home_labels.contactNumberText}
      </Typography>
    </StyledBox>
  );
};

const EmailComp = () => {
  return (
    <StyledBox>
      <DraftsOutlined />
      <Typography
        color={"text.secondary"}
        sx={{
          fontSize: {
            xs: 10,
            md: 12,
          },
          lineHeight: {
            xs: "14px",
            md: "16px",
          },
        }}
      >
        {home_labels.contactEmailText}
      </Typography>
    </StyledBox>
  );
};

export const DesktopSocialBar = () => {
  return (
    <Container
      sx={{
        padding: {
          sm: "0 95px",
          xs: "0",
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: isMobileDevice ? "1px solid #F0F2F5" : "0",
      }}
      maxWidth="xl"
    >
      <Box display={"flex"} alignItems={"center"}>
        <ContactComp />
        <EmailComp />
      </Box>
      <Box
        alignItems={"center"}
        sx={{
          display: {
            xs: "none",
            md: "flex",
          },
        }}
      >
        {socialLinks.map(({ icon: Icon, label,to }) => (
          <StyledBox key={label}>
            <IconButton onClick={() => Boolean(to) && window.open(to, "_blank")}>
              <Icon />
            </IconButton>
          </StyledBox>
        ))}
      </Box>
    </Container>
  );
};

export const MobileSocialBar = () => {
  return (
    <Box
      sx={{
        display: "flex",
        borderBottom: "1px solid #F0F2F5",
        "& > div:last-child": { borderRight: 0 },
      }}
    >
      <ContactComp />
      <EmailComp />
    </Box>
  );
};
