import { createTheme } from "@mui/material";
import { globalTheme } from "./globalTheme";

export const draggableDrawerTheme = createTheme({
  ...globalTheme,
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          "&.MuiModal-backdrop": {
            opacity: "0.75 !important",
            background: "#000000",
          },
        },
      },
    },
  },
});
