import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { currencyFormatter, stringCapitalization } from "../../shared/utils";
import { useCallback, useEffect, useMemo, useState } from "react";

import { ActionIconButtons } from "../components/MyOrders/ActionButtons";
import CustomTable from "../../shared/customTable";
import MyOrdersMobileCard from "../components/MyOrders/MyOrdersMobileCard";
import { CustomTabs as OrderTabs } from "../../shared/customTab/CustomTabs";
import { StyledChip } from "../../shared/UiElements/StatusChip";
import TrackOrder from "../components/MyOrders/TrackOrder";
import { isMobileDevice } from "../../App";
import moment from "moment";
import { order_table_labels } from "../../constants";
import { useDrawer } from "../../shared/customSideDrawer";
import { useFetchActiveOrders } from "../../query-hooks/Orders/useFetchActiveOrders";
import { useFetchPastOrders } from "../../query-hooks/Orders/useFetchPastOrders";
import { useInView } from "react-intersection-observer";

const PartnerCell = ({ partnerName, shippingName }) => (
  <Box display={"flex"} flexDirection={"column"} gap={"4px"}>
    <Typography
      sx={{
        fontSize: 14,
        lineHeight: "20px"
      }}
    >
      {`${partnerName} partner`}
    </Typography>
    <Typography
      color={"text.secondary"}
      sx={{
        fontSize: 13,
        lineHeight: "18px",
        whiteSpace: "pre-wrap"
      }}
    >
      {shippingName}
    </Typography>
  </Box>
);

const getColumns = (onChipClick) => {
  return [
    {
      Header: "Order ID",
      accessor: "id",
      Cell: ({ value }) => {
        return (
          <Typography fontSize={14} fontWeight={600}>
            {value}
          </Typography>
        );
      }
    },
    {
      Header: "Order date",
      accessor: "createdAt"
    },
    {
      Header: "Partner details",
      accessor: "details",
      width: 210
    },
    {
      Header: "Amount",
      accessor: "amount"
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row, value }) => {
        return (
          <StyledChip
            label={stringCapitalization(value)}
            onClick={() => onChipClick(row.original)}
          />
        );
      }
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ row, value }) => {
        return (
          <Box display={"flex"} gap={"12px"}>
            <ActionIconButtons
              orderId={row.original.orderId}
              type={row?.original?.type}
              status={stringCapitalization(row?.original.status)}
              orderCode={row.original.id}
              orderDate={row.original.createdAt}
            />
          </Box>
        );
      }
    }
  ];
};

const getRows = (rows = []) => {
  const hasActiveTransportOrder = rows.map((key) =>
    key.hasOwnProperty("transportOrder")
  );
  return rows.map((item, index) => {
    const isOnlyTransportOrder = !Boolean(item.warehouseOrder);
    let mainRow;
    if (isOnlyTransportOrder) {
      mainRow = {
        id: (
          <Typography fontSize={14} fontWeight={600}>
            {item?.transportOrder?.code}
          </Typography>
        ),
        type: "transport",
        amount: currencyFormatter(
          parseFloat(item.recentOrderItem.transportCharges.totalAmount).toFixed(
            0
          )
        ),
        details: (
          <PartnerCell
            partnerName={item.transportOrder.partner.partnerType.displayName}
            shippingName={item.transportOrder.partner.name}
          />
        )
      };
    } else {
      mainRow = {
        id: item.warehouseOrder.code,
        type: "warehouse",
        amount: currencyFormatter(
          parseFloat(item.recentOrderItem.warehouseCharges.totalAmount).toFixed(
            0
          )
        ),
        details: (
          <PartnerCell
            partnerName={item.warehouseOrder.partner.partnerType.displayName}
            shippingName={item.warehouseOrder.partner.name}
          />
        )
      };
    }
    return {
      ...item,
      ...mainRow,
      orderId: item._id,
      createdAt: moment(item.createdAt).format("DD MMMM YYYY"),
      status: item.customerStatus.name,
      ...{
        ...(hasActiveTransportOrder[index] && !isOnlyTransportOrder
          ? {
              subRow: [{
                id: (
                  <Typography fontSize={14} fontWeight={600}>
                    {item?.transportOrder?.code}
                  </Typography>
                ),
                details: (
                  <PartnerCell
                    partnerName={
                      item.transportOrder.partner.partnerType.displayName
                    }
                    shippingName={item.transportOrder.partner.name}
                  />
                ),
                type: "transport",
                amount: currencyFormatter(
                  parseFloat(
                    item.recentOrderItem.transportCharges.totalAmount
                  ).toFixed(0)
                )
              }]
            }
          : {})
      }
    };
  });
};

const MyOrders = () => {
  const { showDrawer, hideDrawer } = useDrawer();

  const [activeOrderTab, setActiveOrderTab] = useState(0);

  const { ref: activeRef, inView: inActiveView } = useInView();
  const { ref: pastRef, inView: inPastView } = useInView();

  const handleTabChange = (index) => {
    setActiveOrderTab(index);
  };

  const onChipClick = useCallback(
    (row) => {
      showDrawer({
        component: <TrackOrder row={row} hideDrawer={hideDrawer} />,
        closeIcon: false
      });
    },
    [hideDrawer, showDrawer]
  );

  const {
    data: activeData,
    isFetched: isActiveFetched,
    hasNextPage: hasNextActiveOrderPage,
    fetchNextPage: fetchNextActiveOrderPage
  } = useFetchActiveOrders();
  const {
    data: pastData,
    isFetched: isPastFetched,
    hasNextPage: hasNextPastOrderPage,
    fetchNextPage: fetchNextPastOrderPage
  } = useFetchPastOrders();

  const ActiveOrders = useMemo(
    () => (
      <CustomTable
        searchPlaceholderText={order_table_labels.searchPlaceholderText}
        columns={getColumns(onChipClick)}
        data={
          isActiveFetched
            ? activeData?.pages?.flatMap((page) => getRows(page?.orders)) ?? []
            : []
        }
        mobileComponent={MyOrdersMobileCard}
        isLoading={!isActiveFetched}
      />
    ),
    [activeData?.pages, isActiveFetched, onChipClick]
  );
  const PastOrders = useMemo(
    () => (
      <CustomTable
        searchPlaceholderText={order_table_labels.searchPlaceholderText}
        columns={getColumns(onChipClick)}
        data={
          isPastFetched
            ? pastData?.pages?.flatMap((page) => getRows(page?.orders)) ?? []
            : []
        }
        mobileComponent={MyOrdersMobileCard}
        isLoading={!isPastFetched}
      />
    ),
    [isPastFetched, onChipClick, pastData?.pages]
  );

  const tabs = useMemo(
    () => [
      {
        label: `Active Orders (${activeData?.pages?.[0].pagination.rows || 0})`,
        Component: ActiveOrders
      },
      {
        label: `Past Orders (${pastData?.pages?.[0].pagination.rows || 0})`,
        Component: PastOrders
      }
    ],
    [ActiveOrders, PastOrders, activeData?.pages, pastData?.pages]
  );

  useEffect(() => {
    if (inActiveView && hasNextActiveOrderPage) {
      fetchNextActiveOrderPage();
    }
    if (inPastView && hasNextPastOrderPage) {
      fetchNextPastOrderPage();
    }
  }, [
    fetchNextActiveOrderPage,
    fetchNextPastOrderPage,
    hasNextActiveOrderPage,
    hasNextPastOrderPage,
    inActiveView,
    inPastView
  ]);

  const OrderTabsMemo = useMemo(
    () => (
      <OrderTabs
        tabs={tabs}
        activeTab={activeOrderTab}
        onChange={handleTabChange}
        nested={isMobileDevice ? true : false}
      />
    ),
    [activeOrderTab, tabs]
  );

  return (
    <Box
      sx={{
        padding: {
          xs: "0 16px 16px 16px",
          md: 0
        }
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {OrderTabsMemo}
        </Grid>
        {hasNextActiveOrderPage && activeOrderTab === 0 && (
          <Grid
            item
            xs={12}
            ref={activeRef}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <CircularProgress />
          </Grid>
        )}
        {hasNextPastOrderPage && activeOrderTab === 1 && (
          <Grid
            item
            xs={12}
            ref={pastRef}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <CircularProgress />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default MyOrders;
