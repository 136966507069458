import { getStockAdjustmentFormData } from "../../../purchase/services";
import { queryKeys } from "../../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchStockAdjustmentFormData = (orderType) => {
  const queryStockAdjustmentFormData = useQuery(
    [queryKeys.queryFetchStockAdjustmentFormData],
    () => getStockAdjustmentFormData(),
    {
      enabled: Boolean(orderType === "stockAdjustments"),
      refetchOnWindowFocus: false
    }
  );

  return queryStockAdjustmentFormData;
};
