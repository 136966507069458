// conversionUtils.js

export const gtag_report_conversion = (url) => {
  window.gtag("event", "conversion", {
    send_to: `${process.env.REACT_APP_GA_TRACKING_ID}/${process.env.REACT_APP_EVENT_TRACKING_ID}`,
    event_callback: function () {
      console.log("Conversion tracked successfully.");
    }
  });
};
