import { createContext, useContext, useState } from "react";

import { CustomDrawer } from "./CustomDrawer";

const initialState = {
  showDrawer: () => {},
  hideDrawer: () => {},
  toggleBackdrop: () => {},
  store: {},
  isDrawerOpen: false
};

const CustomDrawerContext = createContext(initialState);

export const useDrawer = () => useContext(CustomDrawerContext);

export const CustomSideDrawer = ({ children }) => {
  const [store, setStore] = useState();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { drawerProps } = store || {};
  const showDrawer = (drawerProps = {}) => {
    setStore((prevState) => {
      return {
        ...store,
        drawerProps: {
          ...drawerProps,
          hideBackdrop: prevState?.drawerProps?.hideBackdrop
        }
      };
    });
    setIsDrawerOpen(true);
  };
  const hideDrawer = () => {
    setStore({
      ...store,
      drawerProps: {}
    });
    setIsDrawerOpen(false);
  };
  const toggleBackdrop = () => {
    setStore((prevState) => {
      return {
        ...store,
        drawerProps: {
          ...drawerProps,
          hideBackdrop: true
        }
      };
    });
  };
  const renderComponent = () => {
    const DrawerComponent = CustomDrawer;
    if (!drawerProps?.component || !DrawerComponent) {
      return null;
    }
    return <DrawerComponent id="global-side-drawer" {...drawerProps} />;
  };
  return (
    <CustomDrawerContext.Provider
      value={{ store, showDrawer, hideDrawer, toggleBackdrop, isDrawerOpen }}
    >
      {renderComponent()}
      {children}
    </CustomDrawerContext.Provider>
  );
};
