import * as yup from "yup";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import DropDown from "../../shared/formElements/DropDown";
import { useFieldArray, useForm } from "react-hook-form";
import { error_msg, statusKeys } from "../../constants";
import OrderReport from "../../purchase/components/OrderReport";
import { useNavigate, useParams } from "react-router-dom";
import {
  CustomFieldRow,
  generateFields,
  generateMobileFields,
  OtherDetailsComp,
  PurchaseButtonGroup,
  shipToAddressData,
  vendorAddressData
} from "../../purchase/pages/CreateOrder";
import {
  currencyOnlyFormatter,
  dropdownOptions,
  optionsFormatter
} from "../../shared/utils";
import moment from "moment";
import { billingAddressObject } from "./CreateSalesOrder";
import { useFetchQuoteFormData } from "../../query-hooks/ClientSales/SalesQuote/useFetchQuoteFormData";
import { useFetchQuoteCustomerBillingByCustomerId } from "../../query-hooks/ClientSales/SalesQuote/useFetchQuoteCustomerBillingByCustomerId";
import { useFetchQuoteById } from "../../query-hooks/ClientSales/SalesQuote/useFetchQuoteById";
import { enqueueSnackbar } from "notistack";
import SalesQuotationTable from "../components/Quotes/SalesQuotationTable";
import { useCreateQuote } from "../../query-hooks/ClientSales/SalesQuote/useCreateQuote";
import { useUpdateQuote } from "../../query-hooks/ClientSales/SalesQuote/useUpdateQuote";
import { useSaveDraftQuote } from "../../query-hooks/ClientSales/SalesQuote/useSaveDraftQuote";
import { useUpdateQuoteDraft } from "../../query-hooks/ClientSales/SalesQuote/useUpdateQuoteDraft";
import { useSubmitQuoteDraft } from "../../query-hooks/ClientSales/SalesQuote/useSubmitQuoteDraft";
import { useConvertQuote } from "../../query-hooks/ClientSales/SalesQuote/useConvertQuote";
import { useLostQuote } from "../../query-hooks/ClientSales/SalesQuote/useLostQuote";
import { useDeleteQuoteDraft } from "../../query-hooks/ClientSales/SalesQuote/useDeleteQuoteDraft";
import appRoutes from "../../constants/appRoutes";
import { useCustomDialog } from "../../shared/customDialog";
import SuccessfulDialog from "../../shared/UiElements/SuccessfulDialog";
import { yupResolver } from "@hookform/resolvers/yup";
import MiniDialogBoxForms from "../../shared/UiElements/MiniDialogBoxForms";
import { useFetchAssetWarehouses } from "../../query-hooks/WarehouseAssets/useFetchAssetWarehouses";
import { CustomTabs } from "../../shared/customTab/CustomTabs";
import HistoryTable from "../../purchase/components/HistoryTable";
import { useReactToPrint } from "react-to-print";
import { useDrawer } from "../../shared/customSideDrawer";
import MobileOrderReport from "../../purchase/components/MobileOrderReport";
import TextArea from "../../shared/formElements/TextArea";
import { isMobileDevice } from "../../App";
import { TuneOutlined } from "@mui/icons-material";

const SelectDeliveryWarehouse = (control, options) => {
  return (
    <DropDown
      control={control}
      name={"deliveryWarehouse"}
      placeholder={"select warehouse"}
      options={options}
    />
  );
};

const warehouseSelectSchema = yup.object({
  deliveryWarehouse: yup.string().required(error_msg.required)
});

const Schema = (createByDraft) => {
  const baseSchema = {
    shipping: yup.string().required(error_msg.required),
    advance: yup.string().required(error_msg.required),
    due: yup.string().required(error_msg.required),
    dateOfDelivery: yup.string().required(error_msg.required),
    newOrders: yup.array().of(
      yup.object().shape({
        _id: yup.string().required(error_msg.required),
        disc: yup.string().required(error_msg.required),
        quantity: yup.string().required(error_msg.required)
      })
    )
  };

  if (!createByDraft) {
    baseSchema["salesRepresentative"] = yup
      .string()
      .required(error_msg.required);
    baseSchema["customerShipTo"] = yup.string().required(error_msg.required);
    baseSchema["customerName"] = yup.string().required(error_msg.required);
    baseSchema["customerBilling"] = yup.string().required(error_msg.required);
  }

  return yup.object(baseSchema);
};

const CreateQuotes = () => {
  const [activeTab, setActiveTab] = useState(0);
  const selectedRowsRef = useRef(null);
  const { orderId } = useParams();
  const [isDraft, setIsDraft] = useState(Boolean(orderId));
  const [isEditable, setIsEditable] = useState(false);
  const [updateOrderButton, setUpdateOrderButton] = useState(false);
  const [costBreakUp, setCostBreakUp] = useState({
    gstType: false,
    subtotal: "",
    cgst: "",
    sgst: "",
    igst: "",
    total: ""
  });

  const navigate = useNavigate();

  const { showDialog, hideDialog } = useCustomDialog();
  const { showDrawer, hideDrawer } = useDrawer();

  const { control, watch, setValue, reset, handleSubmit } = useForm({
    defaultValues: {
      newOrders: []
    },
    resolver: yupResolver(Schema(Boolean(orderId)))
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "newOrders"
  });

  const customerNameWatch = watch("customerName");

  const componentRef = useRef();

  const { data: CreateOrderForm, isFetching: isCreateOrderFormFetching } =
    useFetchQuoteFormData();

  const { data: warehouseOptions } = useFetchAssetWarehouses();

  const {
    data: CustomerBillingFormData,
    isFetching: isCustomerBillingFetching
  } = useFetchQuoteCustomerBillingByCustomerId(customerNameWatch ?? "");

  const {
    data: QuoteData,
    isFetching: isQuoteDataFetching,
    refetch: refetchQuote
  } = useFetchQuoteById(orderId);

  useEffect(() => {
    if (orderId && !isQuoteDataFetching) {
      QuoteData?.orderHistories?.length &&
        setActiveTab(QuoteData?.orderHistories?.length);
      setValue("advance", QuoteData?.quotation?.advancePercent);
      setValue("due", QuoteData?.quotation?.dueDays);
      setValue("shipping", QuoteData?.quotation?.shipping?.name);
      setValue("dateOfDelivery", new Date(QuoteData?.quotation?.deliveryDate));
      QuoteData?.quotation?.tnc &&
        setValue("otherItemsAndConditionsLabel", QuoteData?.quotation?.tnc);
      QuoteData?.quotation?.items?.forEach((item, index) => {
        append({
          _id: item.sku,
          rate: item?.skuData?.rate,
          disc: item?.discount?.percent,
          quantity: item.quantity,
          skuCode: item.skuData.skuCode,
          productName: item?.skuData?.productName ?? "",
          brandName: item?.skuData?.brandName ?? "",
          categoryName: item?.skuData?.categoryName ?? "",
          unit: item?.skuData.unit,
          gstRegime: item?.skuData?.gstRegime ?? 0,
          hsn: item?.skuData?.hsn,
          image: item?.skuData?.image
        });
      });
    }
    // eslint-disable-next-line
  }, [isQuoteDataFetching, orderId]);

  const {
    mutate: createQuote,
    isLoading: isCreating,
    isError: isCreateError,
    error: createError
  } = useCreateQuote();

  const {
    mutate: updateQuotation,
    isLoading: isUpdating,
    isError: isUpdateError,
    error: updateError
  } = useUpdateQuote();

  const {
    mutate: saveDraftQuote,
    isLoading: isSavingDraft,
    isError: isSaveDraftError,
    error: saveDraftError
  } = useSaveDraftQuote();

  const {
    mutate: updateQuoteDraft,
    isLoading: isUpdatingDraft,
    isError: isUpdateDraftError,
    error: updateDraftError
  } = useUpdateQuoteDraft();

  const {
    mutate: submitQuoteDraft,
    isLoading: isSubmittingDraft,
    isError: isSubmitDraftError,
    error: submitDraftError
  } = useSubmitQuoteDraft();

  const {
    mutate: convertQuote,
    isLoading: isConverting,
    isError: isConvertError,
    error: convertError
  } = useConvertQuote();

  const {
    mutate: lostQuote,
    isError: isLostError,
    error: lostError
  } = useLostQuote();

  const {
    mutate: deleteQuoteDraft,
    isError: isDeleteQuoteDraftError,
    error: deleteQuoteDraftError
  } = useDeleteQuoteDraft();

  const customerBillingWatch = watch("customerBilling");

  const customerShipToWatch = watch("customerShipTo");

  const deliveryDateWatch = watch("dateOfDelivery");

  const paymentTerms = watch(["advance", "due"]);

  const shipping = watch(["shipping"]);

  const discWatch = watch(
    `newOrders.${fields.length ? fields.length - 1 : 0}.disc`
  );

  const quantityWatch = watch(
    `newOrders.${fields.length ? fields.length - 1 : 0}.quantity`
  );

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: QuoteData?.quotation?.code ?? "QO-Draft"
  });

  const quoteData = {
    name: "Quotation",
    fields: [
      {
        name: "Sales Representative",
        id: "salesRepresentative",
        isDisabled: Boolean(orderId),
        type: "dropdown",
        placeholder: orderId
          ? QuoteData?.quotation?.salesPerson?.name
          : "Select Sales Rep",
        options: dropdownOptions(CreateOrderForm?.salesPersons, "_id", "name")
      },
      {
        name: "Customer Name",
        id: "customerName",
        isDisabled: Boolean(orderId),
        type: "dropdown",
        placeholder: orderId
          ? QuoteData?.quotation?.vendorData?.companyName
          : "Select Customer",
        allowSearch: true,
        options: dropdownOptions(
          CreateOrderForm?.customers,
          "_id",
          "companyName"
        )
      },
      [
        {
          name: "Customer Billing",
          id: "customerBilling",
          isDisabled: Boolean(orderId),
          type: "dropdown",
          options: isCustomerBillingFetching
            ? []
            : optionsFormatter(
                CustomerBillingFormData?.customerBillings,
                "address"
              ),
          isLoading: isCustomerBillingFetching,
          placeholder: orderId ? "Address" : "Select Billing"
        },
        {
          name: "Customer ShipTo ",
          id: "customerShipTo",
          isDisabled: Boolean(orderId),
          type: "dropdown",
          options:
            customerBillingWatch !== "" && customerBillingWatch !== undefined
              ? dropdownOptions(
                  CustomerBillingFormData?.customerBillings?.find(
                    (obj) => obj["_id"] === customerBillingWatch
                  )?.stores,
                  "_id",
                  "code"
                )
              : [],
          isLoading: isCustomerBillingFetching,
          placeholder: orderId
            ? QuoteData?.quotation?.vendorData?.warehouse?.code
            : "Select ST-Code"
        }
      ],
      [
        {
          name: "Date of Delivery",
          id: "dateOfDelivery",
          isDisabled: Boolean(QuoteData?.quotation?.status?.name)
            ? QuoteData?.quotation?.status?.name === "Draft"
              ? !isEditable
              : true
            : false,
          type: "date",
          placeholder: "Select date"
        },
        {
          name: "Shipping",
          id: "shipping",
          isDisabled: Boolean(QuoteData?.quotation?.status?.name)
            ? QuoteData?.quotation?.status?.name === "Draft"
              ? !isEditable
              : true
            : false,
          type: "dropdown",
          options: CreateOrderForm?.shippings,
          placeholder: "Select Shipping"
        }
      ],
      {
        name: "Payment Terms",
        id: "paymentTerms",
        isDisabled: Boolean(QuoteData?.quotation?.status?.name)
          ? QuoteData?.quotation?.status?.name === "Draft"
            ? !isEditable
            : true
          : false
      }
    ],
    actions:
      QuoteData?.quotation?.status?.name === statusKeys.Draft
        ? ["Add", "Remove", "SaveDraft", "Submit", "Reset"]
        : ["Add", "Remove", "SaveDraft", "Submit"],
    selection: true,
    buyerTitle: "Seller",
    shippingTitle: "Shipto",
    sellerTitle: "Buyer",
    orderInfo: {
      quoteNo: orderId ? QuoteData?.quotation?.code ?? "--" : "--",
      orderDate: orderId
        ? moment(QuoteData?.quotation?.orderDate).format("DD-MM-YYYY")
        : "--",
      edd: orderId
        ? moment(QuoteData?.quotation?.deliveryDate).format("DD-MM-YYYY")
        : deliveryDateWatch
        ? moment(deliveryDateWatch).format("DD-MM-YYYY")
        : "--"
    },
    sellerInfo: orderId
      ? vendorAddressData(QuoteData?.quotation?.vendorData)
      : customerBillingWatch !== "" && customerBillingWatch !== undefined
      ? billingAddressObject({
          ...CreateOrderForm?.customers?.find(
            (item) => item._id === customerNameWatch
          ),
          ...CustomerBillingFormData?.customerBillings?.find(
            (item) => item._id === customerBillingWatch
          )
        })
      : {},
    buyerInfo: {
      customerName: `${CreateOrderForm?.clientAdmin?.firstName} ${CreateOrderForm?.clientAdmin?.lastName}`,
      companyName: `${CreateOrderForm?.client?.companyName}`,
      address: `${CreateOrderForm?.client?.billing?.address?.address}, ${CreateOrderForm?.client?.billing?.address?.area}, ${CreateOrderForm?.client?.billing?.address?.city}, ${CreateOrderForm?.client?.billing?.address?.state}, ${CreateOrderForm?.client?.billing?.address?.pincode}`,
      gst: CreateOrderForm?.client?.billing?.gstNo
        ? `GSTIN : ${CreateOrderForm?.client?.billing?.gstNo}`
        : ""
    },
    shipInfo: orderId
      ? shipToAddressData(QuoteData?.quotation?.vendorData?.warehouse)
      : customerShipToWatch !== "" && customerShipToWatch !== undefined
      ? billingAddressObject(
          CustomerBillingFormData?.customerBillings
            ?.find((obj) => obj["_id"] === customerBillingWatch)
            ?.stores?.find((obj) => obj["_id"] === customerShipToWatch)
        )
      : {}
  };

  const getOrderHistory = (item) => {
    return {
      name: "Quotation",
      actions: [],
      selection: false,
      buyerTitle: "Seller",
      shippingTitle: "Shipto",
      sellerTitle: "Buyer",
      orderInfo: {
        quoteNo: item?.code,
        orderDate: moment(item?.orderDate).format("DD-MM-YYYY"),
        edd: moment(item?.deliveryDate).format("DD-MM-YYYY")
      },
      sellerInfo: vendorAddressData(item?.vendorData),
      buyerInfo: vendorAddressData(item?.clientData),
      shipInfo: shipToAddressData(item?.vendorData?.warehouse)
    };
  };

  const orderHistoriesAllReport = (item) => {
    const orderData = getOrderHistory(item);

    const otherOldQODetails = [
      { label: `Shipping :`, value: item?.shipping?.displayName },
      {
        label: `Payment Terms :`,
        value: `${item?.advancePercent}% Advance. Balance Due ${item?.dueDays} days from receipt`
      }
    ];

    const otherOldQODetailsComp = (
      <Grid container rowSpacing={2}>
        {otherOldQODetails?.map((item, index) => (
          <OtherDetailsComp key={index} label={item.label} value={item.value} />
        ))}
      </Grid>
    );

    return (
      <>
        <Box
          sx={{ display: { xs: "none", md: "flex" } }}
          flexDirection={"column"}
          gap={1}
        >
          <OrderReport
            isDraft={false}
            title={orderData?.name}
            sellerTitle={orderData?.sellerTitle}
            buyerTitle={orderData?.buyerTitle}
            shippingTitle={orderData?.shippingTitle}
            orderInfo={orderData?.orderInfo}
            sellerInfo={orderData?.sellerInfo}
            buyerInfo={orderData?.buyerInfo}
            shipInfo={orderData?.shipInfo}
            actions={orderData?.actions}
          >
            <HistoryTable
              data={item?.items ?? []}
              orderType={"SalesOrder"}
              costBreakUp={{
                subtotal: item?.subTotalAmount,
                cgst: item?.taxes?.length > 1 ? item?.taxes[0]?.totalAmount : 0,
                sgst: item?.taxes?.length > 1 ? item?.taxes[1]?.totalAmount : 0,
                igst:
                  item?.taxes?.length === 1 ? item?.taxes[0]?.totalAmount : 0,
                total: item?.totalAmount
              }}
              orderId={orderId}
              gstType={item?.taxes ? item?.taxes?.length > 1 : false}
              tnc={item?.tnc ?? ""}
              paymentTerms={[item?.advancePercent, item?.dueDays]}
              shipping={item?.shipping?.displayName}
              reasonOfReturn={""}
              preparedBy={`${item?.preparedBy?.firstName} ${item?.preparedBy?.lastName}`}
            />
          </OrderReport>
        </Box>
        <Box
          sx={{ display: { xs: "flex", md: "none" } }}
          flexDirection={"column"}
          gap={1}
        >
          <MobileOrderReport
            status={item?.status?.name}
            otherInfo={otherOldQODetailsComp}
            mobileTable={
              <HistoryTable
                data={item?.items ?? []}
                orderType={"SalesOrder"}
                costBreakUp={{
                  subtotal: item?.subTotalAmount,
                  cgst:
                    item?.taxes?.length > 1 ? item?.taxes[0]?.totalAmount : 0,
                  sgst:
                    item?.taxes?.length > 1 ? item?.taxes[1]?.totalAmount : 0,
                  igst:
                    item?.taxes?.length === 1 ? item?.taxes[0]?.totalAmount : 0,
                  total: item?.totalAmount
                }}
                orderId={orderId}
                gstType={item?.taxes ? item?.taxes?.length > 1 : false}
                tnc={item?.tnc ?? ""}
                paymentTerms={[item?.advancePercent, item?.dueDays]}
                shipping={item?.shipping?.displayName}
                reasonOfReturn={""}
                preparedBy={`${item?.preparedBy?.firstName} ${item?.preparedBy?.lastName}`}
              />
            }
            remarksComp={
              <Box
                display={"flex"}
                minWidth={"289px"}
                minHeight={"90px"}
                border={"1px solid #B7BEC7"}
                borderRadius={"8px"}
                padding={"10px"}
              >
                <Typography color={"text.disabled"}>
                  {item?.tnc || ""}
                </Typography>
              </Box>
            }
            Total={[
              {
                label: "Subtotal Amount :",
                value: item?.subTotalAmount
                  ? currencyOnlyFormatter(
                      parseFloat(item?.subTotalAmount).toFixed(2)
                    )
                  : "--"
              },
              ...[
                ...(item?.taxes?.length > 1
                  ? [
                      {
                        label: "CGST :",
                        value: item?.taxes[0]?.totalAmount
                          ? currencyOnlyFormatter(
                              parseFloat(item?.taxes[0]?.totalAmount).toFixed(2)
                            )
                          : "--"
                      },
                      {
                        label: "SGST :",
                        value: item?.taxes[1]?.totalAmount
                          ? currencyOnlyFormatter(
                              parseFloat(item?.taxes[1]?.totalAmount).toFixed(2)
                            )
                          : "--"
                      }
                    ]
                  : [
                      {
                        label: "IGST :",
                        value: item?.taxes[0]?.totalAmount
                          ? currencyOnlyFormatter(
                              parseFloat(item?.taxes[0]?.totalAmount).toFixed(2)
                            )
                          : "--"
                      }
                    ])
              ],
              {
                label: "Total Amount :",
                value: item?.totalAmount
                  ? currencyOnlyFormatter(
                      parseFloat(item?.totalAmount).toFixed(2)
                    )
                  : "--"
              }
            ]}
            ButtonGroup={""}
            preparedBy={`${item?.preparedBy?.firstName} ${item?.preparedBy?.lastName}`}
            title={orderData?.name}
            sellerTitle={orderData?.sellerTitle}
            buyerTitle={orderData?.buyerTitle}
            shippingTitle={orderData?.shippingTitle}
            orderInfo={orderData?.orderInfo}
            sellerInfo={orderData?.sellerInfo}
            buyerInfo={orderData?.buyerInfo}
            shipInfo={orderData?.shipInfo}
          />
        </Box>
        {orderId && (
          <div style={{ display: "none" }}>
            <Box ref={componentRef} sx={{ padding: "10px" }}>
              <Box display={"flex"} flexDirection={"column"} gap={1}>
                <OrderReport
                  isDraft={false}
                  title={orderData?.name}
                  sellerTitle={orderData?.sellerTitle}
                  buyerTitle={orderData?.buyerTitle}
                  shippingTitle={orderData?.shippingTitle}
                  orderInfo={orderData?.orderInfo}
                  sellerInfo={orderData?.sellerInfo}
                  buyerInfo={orderData?.buyerInfo}
                  shipInfo={orderData?.shipInfo}
                  actions={orderData?.actions}
                >
                  <HistoryTable
                    isPrint={true}
                    data={item?.items ?? []}
                    orderType={"SalesOrder"}
                    costBreakUp={{
                      subtotal: item?.subTotalAmount,
                      cgst:
                        item?.taxes?.length > 1
                          ? item?.taxes[0]?.totalAmount
                          : 0,
                      sgst:
                        item?.taxes?.length > 1
                          ? item?.taxes[1]?.totalAmount
                          : 0,
                      igst:
                        item?.taxes?.length === 1
                          ? item?.taxes[0]?.totalAmount
                          : 0,
                      total: item?.totalAmount
                    }}
                    orderId={orderId}
                    gstType={item?.taxes ? item?.taxes?.length > 1 : false}
                    tnc={item?.tnc ?? ""}
                    paymentTerms={[item?.advancePercent, item?.dueDays]}
                    shipping={item?.shipping?.displayName}
                    reasonOfReturn={""}
                    preparedBy={`${item?.preparedBy?.firstName} ${item?.preparedBy?.lastName}`}
                  />
                </OrderReport>
              </Box>
            </Box>
          </div>
        )}
      </>
    );
  };

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  const onReset = () => {
    reset();
    setValue("advance", QuoteData?.quotation?.advancePercent);
    setValue("due", QuoteData?.quotation?.dueDays);
    setValue("shipping", QuoteData?.quotation?.shipping?.name);
    setValue("dateOfDelivery", new Date(QuoteData?.quotation?.deliveryDate));
    QuoteData?.quotation?.tnc &&
      setValue("otherItemsAndConditionsLabel", QuoteData?.quotation?.tnc);
    QuoteData?.quotation?.items?.forEach((item, index) => {
      append({
        _id: item.sku,
        rate: item?.skuData?.rate,
        disc: item?.discount?.percent,
        quantity: item.quantity,
        skuCode: item.skuData.skuCode,
        productName: item?.skuData?.productName ?? "",
        brandName: item?.skuData?.brandName ?? "",
        categoryName: item?.skuData?.categoryName ?? "",
        unit: item?.skuData.unit,
        gstRegime: item?.skuData?.gstRegime ?? 0,
        hsn: item?.skuData?.hsn,
        image: item?.skuData?.image
      });
    });
  };

  const onModify = () => {
    if (QuoteData?.quotation?.status?.name === statusKeys.Draft) {
      setIsDraft((prev) => !prev);
      setIsEditable((prevIsEditable) => !prevIsEditable);
    }
    if (QuoteData?.quotation?.status?.name === statusKeys.Placed) {
      setIsEditable((prevIsEditable) => !prevIsEditable);
      setUpdateOrderButton((prev) => !prev);
    }
    onReset();
  };

  const onConvert = () => {
    showDialog({
      component: (
        <MiniDialogBoxForms
          text={"Select Delivery Warehouse for placing this Sales Order"}
          btnText={"Submit"}
          btnCallback={convertToSalesData}
          childComponent={SelectDeliveryWarehouse}
          schema={warehouseSelectSchema}
          options={
            dropdownOptions(warehouseOptions?.warehouses, "_id", "code") ?? []
          }
        />
      )
    });
  };

  const convertToSalesData = (data) => {
    const formData = {
      warehouse: data["deliveryWarehouse"]
    };
    convertQuote(
      {
        data: { ...formData },
        orderId: orderId
      },
      {
        onSuccess: (res) => {
          hideDialog();
          enqueueSnackbar("Sales order Created!", {
            variant: "success"
          });
          navigate(`${appRoutes.sales.main}/${appRoutes.sales.salesOrders}`);
        }
      }
    );
  };

  const onUpdate = (data) => {
    showDialog({
      component: (
        <SuccessfulDialog
          text={"Are you sure you want to Update the Quotation ?"}
          btn1Text={"Go Back"}
          btn2Text={"Update Quotation"}
          btn1Callback={() => hideDialog()}
          btn2Callback={() => updateData(data)}
        />
      )
    });
  };

  const updateData = (data) => {
    const formData = {
      items: data["newOrders"]?.map((item) => ({
        sku: item._id,
        quantity: +item.quantity,
        discount: {
          percent: +item.disc
        }
      }))
    };
    updateQuotation(
      {
        data: { ...formData },
        orderId: orderId
      },
      {
        onSuccess: (res) => {
          hideDialog();
          enqueueSnackbar("Sales Quotation Updated!", {
            variant: "success"
          });
          setIsEditable((prevIsEditable) => !prevIsEditable);
          setUpdateOrderButton((prev) => !prev);
          reset();
          refetchQuote();
        }
      }
    );
  };

  const onLost = () => {
    showDialog({
      component: (
        <SuccessfulDialog
          text={"Are you sure you want to Mark this Quotation as Lost ?"}
          btn1Text={"Go Back"}
          btn2Text={"Mark Lost"}
          btn1Callback={() => hideDialog()}
          btn2Callback={() => markQuoteLost()}
        />
      )
    });
  };

  const markQuoteLost = () => {
    lostQuote(
      {
        orderId: orderId
      },
      {
        onSuccess: (res) => {
          hideDialog();
          enqueueSnackbar("Quatation Lost", {
            variant: "success"
          });
          reset();
          refetchQuote();
        }
      }
    );
  };

  const onSaveDraft = (data) => {
    const formData = {
      ...{
        ...(orderId
          ? {}
          : {
              salesPerson: data["salesRepresentative"],
              customerStore: data["customerShipTo"]
            })
      },
      deliveryDate: moment(data["dateOfDelivery"]).format("YYYY-MM-DD"),
      shipping: data["shipping"],
      advancePercent: +data["advance"],
      dueDays: +data["due"],
      tnc: data["otherItemsAndConditionsLabel"]
        ? data["otherItemsAndConditionsLabel"]
        : null,
      items: data["newOrders"]?.map((item) => ({
        sku: item._id,
        quantity: +item.quantity,
        discount: {
          percent: +item.disc
        }
      }))
    };
    orderId
      ? updateQuoteDraft(
          {
            data: { ...formData },
            orderId: orderId
          },
          {
            onSuccess: (res) => {
              enqueueSnackbar("Draft Updated!", {
                variant: "success"
              });
              setIsDraft((prev) => !prev);
              setIsEditable((prevIsEditable) => !prevIsEditable);
              reset();
              refetchQuote();
            }
          }
        )
      : saveDraftQuote(
          {
            data: { ...formData }
          },
          {
            onSuccess: (res) => {
              enqueueSnackbar("Quotation Draft Saved!", {
                variant: "success"
              });
              navigate(`${appRoutes.sales.main}/${appRoutes.sales.quotes}`);
            }
          }
        );
  };

  const onSubmit = (data) => {
    showDialog({
      component: (
        <SuccessfulDialog
          text={"Are you sure you want to submit the document ?"}
          subText={"Submitted documents cannot be edited. "}
          btn1Text={"Go to Draft"}
          btn2Text={"Submit"}
          btn1Callback={() => hideDialog()}
          btn2Callback={() => submitData(data)}
        />
      )
    });
  };

  const submitData = (data) => {
    const formData = {
      ...{
        ...(orderId
          ? {}
          : {
              salesPerson: data["salesRepresentative"],
              customerStore: data["customerShipTo"]
            })
      },
      deliveryDate: moment(data["dateOfDelivery"]).format("YYYY-MM-DD"),
      shipping: data["shipping"],
      advancePercent: +data["advance"],
      dueDays: +data["due"],
      tnc: data["otherItemsAndConditionsLabel"]
        ? data["otherItemsAndConditionsLabel"]
        : null,
      items: data["newOrders"]?.map((item) => ({
        sku: item._id,
        quantity: +item.quantity,
        discount: {
          percent: +item.disc
        }
      }))
    };
    orderId
      ? submitQuoteDraft(
          {
            data: { ...formData },
            orderId: orderId
          },
          {
            onSuccess: (res) => {
              hideDialog();
              enqueueSnackbar("Sales Quotation created!", {
                variant: "success"
              });
              setIsDraft((prev) => !prev);
              setIsEditable((prevIsEditable) => !prevIsEditable);
              reset();
              refetchQuote();
            }
          }
        )
      : createQuote(
          {
            data: { ...formData }
          },
          {
            onSuccess: (res) => {
              hideDialog();
              enqueueSnackbar("Sales Quoatation created!", {
                variant: "success"
              });
              navigate(`${appRoutes.sales.main}/${appRoutes.sales.quotes}`);
            }
          }
        );
  };

  const onDeleteClick = () => {
    showDialog({
      component: (
        <SuccessfulDialog
          text={"Are you sure you want to Delete this draft ?"}
          subText={"Deleted documents cannot be recovered. "}
          btn1Text={"Go to Draft"}
          btn2Text={"Delete"}
          btn1Callback={() => hideDialog()}
          btn2Callback={() => deleteDraft()}
        />
      )
    });
  };

  const deleteDraft = () => {
    deleteQuoteDraft(
      {
        orderId: orderId
      },
      {
        onSuccess: (res) => {
          hideDialog();
          enqueueSnackbar("Draft Deleted!", {
            variant: "success"
          });
          navigate(`${appRoutes.sales.main}/${appRoutes.sales.quotes}`);
        }
      }
    );
  };

  useEffect(() => {
    if (isCreateError) {
      enqueueSnackbar(createError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isUpdateError) {
      enqueueSnackbar(updateError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isUpdateDraftError)
      enqueueSnackbar(updateDraftError?.response?.data?.message, {
        variant: "error"
      });
    if (isSubmitDraftError) {
      enqueueSnackbar(submitDraftError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isSaveDraftError)
      enqueueSnackbar(saveDraftError?.response?.data?.message, {
        variant: "error"
      });
    if (isConvertError) {
      enqueueSnackbar(convertError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isLostError) {
      enqueueSnackbar(lostError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isDeleteQuoteDraftError) {
      enqueueSnackbar(deleteQuoteDraftError?.response?.data?.message, {
        variant: "error"
      });
    }
  }, [
    updateDraftError?.response?.data?.message,
    submitDraftError?.response?.data?.message,
    createError?.response?.data?.message,
    updateError?.response?.data?.message,
    saveDraftError?.response?.data?.message,
    lostError?.response?.data?.message,
    convertError?.response?.data?.message,
    deleteQuoteDraftError?.response?.data?.message,
    isSaveDraftError,
    isUpdateDraftError,
    isSubmitDraftError,
    isCreateError,
    isUpdateError,
    isDeleteQuoteDraftError,
    isLostError,
    isConvertError
  ]);

  const otherQODetails = [
    { label: `Shipping :`, value: shipping },
    {
      label: `Payment Terms :`,
      value:
        paymentTerms[0] !== "" &&
        paymentTerms[0] !== undefined &&
        paymentTerms[1]
          ? `${paymentTerms[0]}% Advance. Balance Due ${paymentTerms[1]} days from receipt`
          : "--"
    }
  ];

  const otherQODetailsComp = (
    <Grid container rowSpacing={2}>
      {otherQODetails?.map((item, index) => (
        <OtherDetailsComp key={index} label={item.label} value={item.value} />
      ))}
    </Grid>
  );

  const Total = [
    {
      label: "Subtotal Amount :",
      value: costBreakUp?.subtotal
        ? currencyOnlyFormatter(parseFloat(costBreakUp?.subtotal).toFixed(2))
        : "--"
    },
    ...[
      ...(Boolean(costBreakUp?.gstType)
        ? [
            {
              label: "CGST :",
              value: costBreakUp?.cgst
                ? currencyOnlyFormatter(
                    parseFloat(costBreakUp?.cgst).toFixed(2)
                  )
                : "--"
            },
            {
              label: "SGST :",
              value: costBreakUp?.sgst
                ? currencyOnlyFormatter(
                    parseFloat(costBreakUp?.sgst).toFixed(2)
                  )
                : "--"
            }
          ]
        : [
            {
              label: "IGST :",
              value: costBreakUp?.igst
                ? currencyOnlyFormatter(
                    parseFloat(costBreakUp?.igst).toFixed(2)
                  )
                : "--"
            }
          ])
    ],
    {
      label: "Total Amount :",
      value: costBreakUp?.total
        ? currencyOnlyFormatter(parseFloat(costBreakUp?.total).toFixed(2))
        : "--"
    }
  ];

  const orderStatus = QuoteData?.quotation?.status?.name || "";

  const RemarksComp = (
    <TextArea
      control={control}
      name={"otherItemsAndConditionsLabel"}
      disabled={
        Boolean(orderStatus)
          ? orderStatus === "Draft"
            ? !isEditable
            : true
          : false
      }
    />
  );

  const DrawerComponent = (
    <Grid container rowSpacing={2}>
      {quoteData?.fields?.map((item, index) => (
        <Grid
          container
          rowSpacing={2}
          padding={"20px 15px 2px 10px"}
          key={index}
        >
          {generateMobileFields(
            item,
            control,
            !isCreateOrderFormFetching && { ...CreateOrderForm }
          )}
        </Grid>
      ))}
      <Grid
        xs={12}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        minHeight={"72px"}
        sx={{ background: "#F0F2F5" }}
        mt={2}
      >
        <Button
          variant="contained"
          sx={{ width: "100px", height: "40px" }}
          onClick={hideDrawer}
        >
          {"Next"}
        </Button>
      </Grid>
    </Grid>
  );

  useEffect(
    () => {
      if (isMobileDevice && !Boolean(orderId) && !isCreateOrderFormFetching) {
        showDrawer({
          title: Boolean(orderId) ? "Manage Order" : "Create Order",
          height: "fit-content",
          sx: { color: "#2773FF", fontWeight: 600 },
          component: DrawerComponent
        });
        if (Boolean(customerBillingWatch) && !isCustomerBillingFetching) {
          showDrawer({
            title: Boolean(orderId) ? "Manage Order" : "Create Order",
            height: "fit-content",
            sx: { color: "#2773FF", fontWeight: 600 },
            component: DrawerComponent
          });
        }
      }
    },
    // eslint-disable-next-line
    [
      JSON.stringify(customerBillingWatch),
      orderId,
      isCreateOrderFormFetching,
      isCustomerBillingFetching
    ]
  );

  const allowEdit = ["Draft", "Placed"];

  const showModifyOnOrder = Boolean(QuoteData?.orderHistories?.length)
    ? QuoteData?.orderHistories?.length === activeTab
    : true;

  const inDraftState = QuoteData?.quotation?.status?.name === "Draft";

  const inPlacedState = QuoteData?.quotation?.status?.name === "Placed";

  const inCompletedState =
    QuoteData?.quotation?.status?.name === "Lost" ||
    QuoteData?.quotation?.status?.name === "Converted" ||
    QuoteData?.quotation?.status?.name === "SoCancelled";

  const ButtonGroup = (inDraftState || !inCompletedState) &&
    showModifyOnOrder && (
      <PurchaseButtonGroup
        inDraftState={inDraftState}
        inPlacedState={inPlacedState}
        isDraft={isDraft}
        onCancel={onLost}
        onDeleteClick={onDeleteClick}
        onModify={onModify}
        onSaveDraft={handleSubmit(onSaveDraft)}
        onSubmitClick={handleSubmit(onSubmit)}
        onUpdate={handleSubmit(onUpdate)}
        updateOrderButton={updateOrderButton}
      />
    );

  const OrderReportComp = (
    <>
      <Box
        sx={{ display: { xs: "none", md: "flex" } }}
        flexDirection={"column"}
        gap={1}
      >
        <OrderReport
          isDraft={!isDraft}
          title={quoteData?.name}
          sellerTitle={quoteData?.sellerTitle}
          buyerTitle={quoteData?.buyerTitle}
          shippingTitle={quoteData?.shippingTitle}
          orderInfo={quoteData?.orderInfo}
          sellerInfo={quoteData?.sellerInfo}
          buyerInfo={quoteData?.buyerInfo}
          shipInfo={quoteData?.shipInfo}
          actions={quoteData?.actions}
          onAdd={() => {
            if (fields.length < 1 || (discWatch && quantityWatch)) {
              append({ item: "" });
            } else {
              enqueueSnackbar("kindly first fill quantity and Discount", {
                variant: "error"
              });
            }
          }}
          onRemove={() => {
            const rowsToRemove = Object.keys(selectedRowsRef.current).filter(
              (key) => selectedRowsRef.current[key]
            );
            remove(rowsToRemove);
          }}
          onSaveDraft={handleSubmit(onSaveDraft)}
          onSubmit={handleSubmit(onSubmit)}
          onModify={onModify}
        >
          <SalesQuotationTable
            costBreakUp={costBreakUp}
            setCostBreakUp={setCostBreakUp}
            fields={fields}
            control={control}
            watch={watch}
            append={append}
            remove={remove}
            onAdd={() => {
              if (fields.length < 1 || (discWatch && quantityWatch)) {
                append({ item: "" });
              } else {
                enqueueSnackbar("kindly first fill quantity and Discount", {
                  variant: "error"
                });
              }
            }}
            onRowSelectionChange={(rows) => {
              selectedRowsRef.current = rows;
            }}
            orderId={orderId}
            gstType={
              orderId
                ? Boolean(
                    QuoteData?.quotation?.clientData?.billing?.address
                      ?.state ===
                      QuoteData?.quotation?.vendorData?.billing?.address?.state
                  )
                : Boolean(
                    CreateOrderForm?.client?.billing?.address?.state ===
                      CustomerBillingFormData?.customerBillings?.find(
                        (obj) => obj["_id"] === customerBillingWatch
                      )?.address?.state
                  )
            }
            options={isCreateOrderFormFetching ? [] : CreateOrderForm}
            orderStatus={QuoteData?.quotation?.status?.name ?? ""}
            isEditable={
              Boolean(QuoteData?.quotation?.status?.name) ? isEditable : true
            }
            applyGst={
              orderId
                ? Boolean(QuoteData?.quotation?.clientData?.billing?.gstNo)
                : Boolean(CreateOrderForm?.client?.billing?.gstNo)
            }
            paymentTerms={paymentTerms}
            shipping={shipping}
            preparedBy={`${CreateOrderForm?.clientAdmin?.firstName} ${CreateOrderForm?.clientAdmin?.lastName}`}
          />
        </OrderReport>
      </Box>
      <Box
        sx={{ display: { xs: "flex", md: "none" } }}
        flexDirection={"column"}
        gap={1}
      >
        <MobileOrderReport
          status={orderStatus}
          otherInfo={otherQODetailsComp}
          mobileTable={
            <SalesQuotationTable
              costBreakUp={costBreakUp}
              setCostBreakUp={setCostBreakUp}
              fields={fields}
              control={control}
              watch={watch}
              append={append}
              remove={remove}
              onAdd={() => {
                if (fields.length < 1 || (discWatch && quantityWatch)) {
                  append({ item: "" });
                } else {
                  enqueueSnackbar("kindly first fill quantity and Discount", {
                    variant: "error"
                  });
                }
              }}
              onRowSelectionChange={(rows) => {
                selectedRowsRef.current = rows;
              }}
              orderId={orderId}
              gstType={
                orderId
                  ? Boolean(
                      QuoteData?.quotation?.clientData?.billing?.address
                        ?.state ===
                        QuoteData?.quotation?.vendorData?.billing?.address
                          ?.state
                    )
                  : Boolean(
                      CreateOrderForm?.client?.billing?.address?.state ===
                        CustomerBillingFormData?.customerBillings?.find(
                          (obj) => obj["_id"] === customerBillingWatch
                        )?.address?.state
                    )
              }
              options={isCreateOrderFormFetching ? [] : CreateOrderForm}
              orderStatus={QuoteData?.quotation?.status?.name ?? ""}
              isEditable={
                Boolean(QuoteData?.quotation?.status?.name) ? isEditable : true
              }
              applyGst={
                orderId
                  ? Boolean(QuoteData?.quotation?.clientData?.billing?.gstNo)
                  : Boolean(CreateOrderForm?.client?.billing?.gstNo)
              }
              paymentTerms={paymentTerms}
              shipping={shipping}
              preparedBy={`${CreateOrderForm?.clientAdmin?.firstName} ${CreateOrderForm?.clientAdmin?.lastName}`}
            />
          }
          remarksComp={RemarksComp}
          Total={Total}
          ButtonGroup={ButtonGroup}
          preparedBy={`${CreateOrderForm?.clientAdmin?.firstName} ${CreateOrderForm?.clientAdmin?.lastName}`}
          title={quoteData?.name}
          sellerTitle={quoteData?.sellerTitle}
          buyerTitle={quoteData?.buyerTitle}
          shippingTitle={quoteData?.shippingTitle}
          orderInfo={quoteData?.orderInfo}
          sellerInfo={quoteData?.sellerInfo}
          buyerInfo={quoteData?.buyerInfo}
          shipInfo={quoteData?.shipInfo}
        />
      </Box>
      {orderId && isDraft && (
        <div style={{ display: "none" }}>
          <Box ref={componentRef} sx={{ padding: "10px" }}>
            <Box display={"flex"} flexDirection={"column"} gap={1}>
              <OrderReport
                costBreakUp={costBreakUp}
                setCostBreakUp={setCostBreakUp}
                isDraft={!isDraft}
                title={quoteData?.name}
                sellerTitle={quoteData?.sellerTitle}
                buyerTitle={quoteData?.buyerTitle}
                shippingTitle={quoteData?.shippingTitle}
                orderInfo={quoteData?.orderInfo}
                sellerInfo={quoteData?.sellerInfo}
                buyerInfo={quoteData?.buyerInfo}
                shipInfo={quoteData?.shipInfo}
                actions={quoteData?.actions}
                onAdd={() => {
                  if (fields.length < 1 || (discWatch && quantityWatch)) {
                    append({ item: "" });
                  } else {
                    enqueueSnackbar("kindly first fill quantity and Discount", {
                      variant: "error"
                    });
                  }
                }}
                onRemove={() => {
                  const rowsToRemove = Object.keys(
                    selectedRowsRef.current
                  ).filter((key) => selectedRowsRef.current[key]);
                  remove(rowsToRemove);
                }}
                onSaveDraft={handleSubmit(onSaveDraft)}
                onSubmit={handleSubmit(onSubmit)}
                onModify={onModify}
              >
                <SalesQuotationTable
                  costBreakUp={costBreakUp}
                  setCostBreakUp={setCostBreakUp}
                  isPrint={true}
                  fields={fields}
                  control={control}
                  watch={watch}
                  append={append}
                  remove={remove}
                  onRowSelectionChange={(rows) => {
                    selectedRowsRef.current = rows;
                  }}
                  orderId={orderId}
                  gstType={
                    orderId
                      ? Boolean(
                          QuoteData?.quotation?.clientData?.billing?.address
                            ?.state ===
                            QuoteData?.quotation?.vendorData?.billing?.address
                              ?.state
                        )
                      : Boolean(
                          CreateOrderForm?.client?.billing?.address?.state ===
                            CustomerBillingFormData?.customerBillings?.find(
                              (obj) => obj["_id"] === customerBillingWatch
                            )?.address?.state
                        )
                  }
                  options={isCreateOrderFormFetching ? [] : CreateOrderForm}
                  orderStatus={QuoteData?.quotation?.status?.name ?? ""}
                  isEditable={
                    Boolean(QuoteData?.quotation?.status?.name)
                      ? isEditable
                      : true
                  }
                  applyGst={
                    orderId
                      ? Boolean(
                          QuoteData?.quotation?.clientData?.billing?.gstNo
                        )
                      : Boolean(CreateOrderForm?.client?.billing?.gstNo)
                  }
                  paymentTerms={paymentTerms}
                  shipping={shipping}
                  preparedBy={`${CreateOrderForm?.clientAdmin?.firstName} ${CreateOrderForm?.clientAdmin?.lastName}`}
                />
              </OrderReport>
            </Box>
          </Box>
        </div>
      )}
    </>
  );

  const tabs = [].concat(
    ...(orderId && Boolean(QuoteData?.orderHistories)
      ? QuoteData.orderHistories.map((item, index) => ({
          label: `${QuoteData.quotation.code}/${index + 1}`,
          Component: orderHistoriesAllReport(item)
        }))
      : []),
    {
      label: Boolean(QuoteData?.orderHistories?.length)
        ? `${QuoteData?.quotation?.code}/${
            QuoteData?.orderHistories?.length + 1
          }`
        : QuoteData?.quotation?.code ?? "Draft",
      Component: OrderReportComp
    }
  );

  return isCreateOrderFormFetching ||
    isQuoteDataFetching ||
    isConverting ||
    isCreating ||
    isUpdating ||
    isSavingDraft ||
    isUpdatingDraft ||
    isSubmittingDraft ? (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={
        isCreateOrderFormFetching ||
        isQuoteDataFetching ||
        isCreating ||
        isConverting ||
        isUpdating ||
        isSavingDraft ||
        isUpdatingDraft ||
        isSubmittingDraft
      }
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : (
    <Box Box display={"flex"} flexDirection={"column"}>
      {orderId &&
        Boolean(QuoteData?.quotation?.status?.name === statusKeys.Placed) && (
          <Box
            display={"flex"}
            justifyContent={"end"}
            alignItems={"center"}
            columnGap={4}
            sx={{
              mt: { xs: -4.5, md: 0 },
              ml: { xs: 5, md: 0 },
              mb: { xs: 2, md: 0 },
              pr: { xs: 2, md: 0 }
            }}
            zIndex={1}
          >
            <Button variant="link" disableRipple onClick={onConvert}>
              {"Convert to Sales"}
            </Button>
            <Button
              variant="link"
              sx={{ color: "#F9A90E" }}
              disableRipple
              onClick={onLost}
            >
              {"Lost"}
            </Button>
          </Box>
        )}
      <Divider />
      <Grid
        container
        spacing={2}
        sx={{
          display: { xs: "none", md: "block" }
        }}
      >
        <Grid item xs={12}>
          <Typography
            fontSize={20}
            fontWeight={500}
            lineHeight={"24.2px"}
            letterSpacing={"0.2%"}
          >
            {orderId ? "Manage Quote" : "Create Quote"}
          </Typography>
        </Grid>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12} md={6} lg={9} container spacing={3}>
            <Grid item xs={12} container spacing={3}>
              <CustomFieldRow label={"Order Type"} isDisabled>
                <DropDown
                  control={control}
                  name={"quoteType"}
                  options={[
                    {
                      name: "Quote",
                      displayName: "Quote"
                    }
                  ]}
                  placeholder={"Quote"}
                  disabled={true}
                />
              </CustomFieldRow>
              {orderId &&
                Boolean(
                  QuoteData?.quotation?.status?.name === statusKeys.Placed
                ) && (
                  <Box display={"flex"} gap={1} alignItems={"end"} pl={3}>
                    <Button variant="link" disableRipple onClick={onConvert}>
                      {"Convert to Sales"}
                    </Button>
                    <Button
                      variant="link"
                      sx={{ color: "#F9A90E" }}
                      disableRipple
                      onClick={onLost}
                    >
                      {"Lost"}
                    </Button>
                  </Box>
                )}
            </Grid>
            {quoteData?.fields?.map((item) =>
              generateFields(
                item,
                control,
                false,
                !isCreateOrderFormFetching && { ...CreateOrderForm }
              )
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        rowSpacing={2}
        sx={{
          display: { xs: "flex", md: "none" },
          alignItems: "center",
          padding: "10px 20px"
        }}
      >
        <Grid item xs={8}>
          <DropDown
            control={control}
            name={"quoteType"}
            options={[
              {
                name: "Quote",
                displayName: "Quote"
              }
            ]}
            placeholder={"Quote"}
            disabled
          />
        </Grid>
        <Grid item xs={4} display={"flex"} justifyContent={"center"}>
          <IconButton
            size="small"
            sx={{
              backgroundColor: "#F0F2F5"
            }}
            color={"primary"}
            onClick={() => {
              showDrawer({
                title: Boolean(orderId) ? "Manage Order" : "Create Order",
                height: "fit-content",
                sx: { color: "#2773FF", fontWeight: 600 },
                component: DrawerComponent
              });
            }}
          >
            <TuneOutlined color="inherit" fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
      {!orderId && (
        <Grid item xs={12}>
          {OrderReportComp}
        </Grid>
      )}
      {orderId && (
        <Grid item xs={12}>
          <CustomTabs
            tabs={tabs}
            activeTab={activeTab}
            onChange={handleTabChange}
            headerControls={
              !isMobileDevice &&
              (allowEdit.includes(QuoteData?.quotation?.status?.name) &&
              showModifyOnOrder ? (
                <Box display={"flex"} justifyContent={"flex-end"} gap={2}>
                  {!updateOrderButton && isDraft && (
                    <>
                      <Button variant="link" disableRipple onClick={onModify}>
                        Modify
                      </Button>
                      {QuoteData?.quotation?.status?.name === "Draft" && (
                        <Button
                          variant="link"
                          disableRipple
                          sx={{ color: "error.main", minWidth: "100px" }}
                          onClick={() => onDeleteClick()}
                        >
                          Delete Draft
                        </Button>
                      )}
                      <Button
                        variant="link"
                        disableRipple
                        onClick={handlePrint}
                      >
                        Print
                      </Button>
                    </>
                  )}
                  {updateOrderButton && (
                    <>
                      <Button
                        variant="link"
                        disableRipple
                        onClick={handleSubmit(onUpdate)}
                        sx={{ minWidth: "100px" }}
                      >
                        Update Quote
                      </Button>
                      <Button
                        variant="link"
                        disableRipple
                        onClick={onModify}
                        sx={{ color: "green" }}
                      >
                        Reset
                      </Button>
                    </>
                  )}
                </Box>
              ) : (
                <Button variant="link" disableRipple onClick={handlePrint}>
                  Print
                </Button>
              ))
            }
          />
        </Grid>
      )}
    </Box>
  );
};

export default CreateQuotes;
