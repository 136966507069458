import { getBillingFormData } from "../../../sales/services";
import { queryKeys } from "../../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchBillingFormData = (customerId) => {
  const queryBillingFormData = useQuery(
    [queryKeys.queryFetchBillingFormData,customerId],
    () => getBillingFormData(customerId),
    {
      enabled: Boolean(customerId),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return queryBillingFormData;
};
