import { getPastOrders } from "../../customer/services";
import { queryKeys } from "../../constants/queryKeys";
import { useInfiniteQuery } from "@tanstack/react-query";

export const useFetchPastOrders = () => {
  const queryPastOrders = useInfiniteQuery(
    [queryKeys.queryFetchPastOrders],
    ({ pageParam = 1 }) => getPastOrders(pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage =
          lastPage?.orders.length === lastPage?.pagination.limit
            ? allPages.length + 1
            : undefined;

        return nextPage;
      },
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true
    }
  );

  return queryPastOrders;
};
