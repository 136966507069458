import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";
import { KeyboardArrowRight } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { getCustomCell } from "../../pages/PurchaseOrders";
import appRoutes from "../../../constants/appRoutes";
import { stringCapitalization } from "../../../shared/utils";
import { StyledChip } from "../../../shared/UiElements/StatusChip";

export const MobileTransferOrders = (row) => {
  const navigate = useNavigate();

  return (
    <Grid container spacing={"20px"}>
      <Grid item xs={12} container rowSpacing={2}>
        <Grid item xs={6}>
          {getCustomCell(
            row?.docNo,
            "",
            `${appRoutes.purchase.main}/${appRoutes.purchase.stockTransfer}/${row.ST_id}/manage-order`
          )}
        </Grid>
        <Grid item xs={6} display={"flex"} justifyContent={"right"}>
          <Typography
            sx={{ fontSize: 14, lineHeight: "20px", fontWeight: 400 }}
          >
            {row.orderDate}
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            alignItems: "center"
          }}
        >
          <StyledChip
            label={stringCapitalization(row.statusOrigin)}
            variant="text"
          />
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            display: "flex",
            justifyContent: "right",
            alignItems: "center"
          }}
        >
          <Typography fontSize={12} lineHeight={"14.52px"}>
            {row.originWarehouse}
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            display: "flex",
            justifyContent: "right",
            alignItems: "center"
          }}
        >
          <IconButton
            color="primary"
            disabled={
              row?.statusOrigin === "Draft" || row?.statusOrigin === "Placed"
            }
            onClick={() =>
              row?.statusOrigin !== "Draft" &&
              row?.statusOrigin !== "Placed" &&
              navigate(
                `/${"Document"}/${"Stock-Transfer"}/Gate-Pass/${row?.ST_id}`
              )
            }
          >
            <KeyboardArrowRight />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={6}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={0.5}
            justifyContent={"flex-end"}
            alignItems={"flex-start"}
          >
            <StyledChip
              label={stringCapitalization(row.statusReceiving)}
              variant="text"
            />
            <Typography
              fontSize={12}
              lineHeight={"16px"}
              color={"text.disabled"}
              pl={"20px"}
            >
              Status
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            display: "flex",
            justifyContent: "right",
            alignItems: "center"
          }}
        >
          <Typography fontSize={12} lineHeight={"14.52px"}>
            {row.receivingWH}
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            display: "flex",
            justifyContent: "right",
            alignItems: "center"
          }}
        >
          <IconButton
            color="primary"
            disabled={
              row?.statusReceiving === "Draft" ||
              row?.statusReceiving === "Placed"
            }
            onClick={() =>
              row?.statusReceiving !== "Draft" &&
              row?.statusReceiving !== "Placed" &&
              navigate(
                `/${"Document"}/${"Stock-Transfer"}/Material-Received-Report/${
                  row?.ST_id
                }`
              )
            }
          >
            <KeyboardArrowRight />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
