import { Box, Button, Typography } from "@mui/material";

import { CheckCircle } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const MiniDialogBoxForms = ({
  text,
  btnText,
  btnCallback,
  childComponent,
  schema,
  options
}) => {
  const { control, handleSubmit } = useForm({ resolver: yupResolver(schema) });
  return (
    <Box
      padding={1}
      display={"flex"}
      flexDirection={"column"}
      gap={"12px"}
      textAlign={"center"}
      alignItems={"center"}
    >
      <CheckCircle
        color="success"
        sx={{
          height: 60,
          width: 60
        }}
      />
      <Typography
        fontSize={20}
        lineHeight={"24px"}
        fontWeight={500}
        letterSpacing={"0.002em"}
      >
        {text}
      </Typography>
      {childComponent(control, options)}
      <Button
        fullWidth
        variant={"contained"}
        sx={{
          height: { xs: 48, lg: 64 },
          fontWeight: 500,
          fontSize: 15,
          marginTop: "20px",
          whiteSpace: "nowrap"
        }}
        onClick={handleSubmit(btnCallback)}
      >
        {btnText}
      </Button>
    </Box>
  );
};

export default MiniDialogBoxForms;
