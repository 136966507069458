import { queryKeys } from "../../../constants/queryKeys";
import { getPOListFormData } from "../../../purchase/services";
import { useQuery } from "@tanstack/react-query";

export const useFetchPOListFormData = (orderType) => {
  const queryPOListFormData = useQuery(
    [queryKeys.queryFetchPOListFormData],
    () => getPOListFormData(),
    {
      enabled: Boolean(orderType === "purchaseReturns"),
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return queryPOListFormData;
};
