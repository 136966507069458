import { getPurchaseOrderFormData } from "../../../purchase/services";
import { queryKeys } from "../../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchPurchseOrderFormData = (orderType) => {
  const queryPurchaseOrderFormData = useQuery(
    [queryKeys.queryFetchPurchaseOrderFormData],
    () => getPurchaseOrderFormData(),
    {
      enabled: Boolean(orderType === "purchaseOrder"),
      refetchOnWindowFocus: false
    }
  );

  return queryPurchaseOrderFormData;
};
