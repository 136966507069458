import { queryKeys } from "../../constants/queryKeys";
import { getAssetWarehouseById } from "../../inventory/services";
import { useQuery } from "@tanstack/react-query";

export const useFetchAssetWarehouseById = (warehouseId) => {
  const warehouseById = useQuery(
    [queryKeys.queryFetchAssetWarehouseById, warehouseId],
    () => getAssetWarehouseById(warehouseId),
    {
      enabled: Boolean(warehouseId),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return warehouseById;
};
