import { Box, Grid, Typography } from "@mui/material";
import { TotalBreakup, reportLabel1, reportLabel2 } from "../pages/CreateOrder";

import CustomTable from "../../shared/customTable";
import { Link } from "react-router-dom";
import TextArea from "../../shared/formElements/TextArea";
import { purchase_labels } from "../../constants";
import { ToWords } from "to-words";
import { useForm } from "react-hook-form";
import { currencyOnlyFormatter } from "../../shared/utils";
import { useMemo } from "react";
import PrintableTable from "./PrintableTable";
import { gstColumn } from "./Purchase/PurchaseOrderTable";
import { MobilePOTable } from "./Purchase/MobilePOTable";
import { MobilePRTable } from "./Purchase/MobilePRTable";
import { MobileSOTable } from "../../sales/components/Sales/MobileSOTable";
import { MobileSRTable } from "../../sales/components/Sales/MobileSRTable";
import { MobileSTTable } from "./Purchase/MobileSTTable";

const getColumnsByOrderType = (orderType, gstType) => {
  switch (orderType) {
    case "PurchaseOrder":
      return [
        {
          Header: "S.No",
          accessor: "sNo",
          width: 90,
          size: 0.5
        },
        {
          Header: "Item/ SKU",
          accessor: "item",
          width: 200,
          size: 2,
          Cell: ({ row, value }) => {
            return row.original?.skuCode ? (
              <Box display={"flex"} flexDirection={"column"} gap={1}>
                <Box display={"flex"} gap={1} alignItems={"center"}>
                  {row.original?.image?.thumbUrl && (
                    <img
                      src={row.original?.image?.thumbUrl}
                      alt={row.original?.brandName ?? "--"}
                      height={52}
                      width={50}
                      style={{ objectFit: "contain" }}
                    />
                  )}
                  <Typography
                    fontSize={12}
                    fontWeight={600}
                    color={"primary"}
                    component={Link}
                  >
                    {row.original?.skuCode}
                  </Typography>
                </Box>
                <Typography fontSize={13} color={"text.secondary"}>
                  {`HSN : ${row.original?.hsn ?? "--"}`}
                </Typography>
              </Box>
            ) : (
              value
            );
          }
        },
        {
          Header: "Product",
          accessor: "product",
          width: 180,
          size: 1.5,
          disableSortBy: true,
          Cell: ({ row }) => {
            return (
              row.original?.productName && (
                <Box display={"flex"} flexDirection={"column"} gap={0.5}>
                  <Typography fontSize={14}>{`Product: ${
                    row.original?.productName ?? "--"
                  }`}</Typography>
                  <Typography
                    fontSize={13}
                    color={"text.secondary"}
                  >{`Category: ${
                    row.original?.categoryName ?? "--"
                  }`}</Typography>
                  <Typography fontSize={13} color={"text.secondary"}>{`Brand: ${
                    row.original?.brandName ?? "--"
                  }`}</Typography>
                </Box>
              )
            );
          }
        },
        {
          Header: "UOM",
          accessor: "uom",
          disableSortBy: true,
          size: 1,
          Cell: ({ row }) => {
            return (
              row.original?.unit && (
                <Typography>{row.original?.unit?.name}</Typography>
              )
            );
          }
        },
        {
          Header: "Rate (INR)",
          accessor: "rate",
          size: 1,
          disableSortBy: true
        },
        {
          Header: "Quantity",
          accessor: "quantity",
          size: 1,
          disableSortBy: true
        },
        {
          Header: "Amount",
          accessor: "amount",
          size: 1.5,
          disableSortBy: true
        },
        ...[
          ...(gstType
            ? [
                {
                  Header: "CGST",
                  accessor: "cgst",
                  size: 1,
                  disableSortBy: true,
                  Cell: ({ row }) => {
                    return gstColumn(
                      row.original.cgst ?? 0,
                      row.original?.gstRegime / 2 ?? 0
                    );
                  }
                },
                {
                  Header: "SGST",
                  accessor: "sgst",
                  disableSortBy: true,
                  size: 1,
                  Cell: ({ row }) => {
                    return gstColumn(
                      row.original.sgst,
                      row.original?.gstRegime / 2 ?? 0
                    );
                  }
                }
              ]
            : [
                {
                  Header: "IGST",
                  accessor: "igst",
                  size: 1,
                  disableSortBy: true,
                  Cell: ({ row }) => {
                    return gstColumn(
                      row.original.igst ?? 0,
                      row.original?.gstRegime ?? 0
                    );
                  }
                }
              ])
        ],
        {
          Header: "Total",
          accessor: "total",
          size: 1.5,
          disableSortBy: true
        }
      ];
    case "PurchaseReturn":
      return [
        {
          Header: "S.No",
          accessor: "sNo",
          width: 90,
          size: 0.5
        },
        {
          Header: "Item/ SKU",
          accessor: "item",
          width: 200,
          size: 1.5,
          Cell: ({ row, value }) => {
            return row.original?.skuCode ? (
              <Box display={"flex"} flexDirection={"column"} gap={1}>
                <Box display={"flex"} gap={1} alignItems={"center"}>
                  {row.original?.image?.thumbUrl && (
                    <img
                      src={row.original?.image?.thumbUrl}
                      alt={row.original?.brandName ?? "--"}
                      height={52}
                      width={50}
                      style={{ objectFit: "contain" }}
                    />
                  )}
                  <Typography
                    fontSize={12}
                    fontWeight={600}
                    color={"primary"}
                    component={Link}
                  >
                    {row.original?.skuCode}
                  </Typography>
                </Box>
                <Typography fontSize={13} color={"text.secondary"}>
                  {`HSN : ${row.original?.hsn ?? "--"}`}
                </Typography>
              </Box>
            ) : (
              value
            );
          }
        },
        {
          Header: "Product",
          accessor: "product",
          width: 180,
          size: 1.5,
          disableSortBy: true,
          Cell: ({ row }) => {
            return (
              row.original?.productName && (
                <Box display={"flex"} flexDirection={"column"} gap={0.5}>
                  <Typography fontSize={14}>{`Product: ${
                    row.original?.productName ?? "--"
                  }`}</Typography>
                  <Typography
                    fontSize={13}
                    color={"text.secondary"}
                  >{`Category: ${
                    row.original?.categoryName ?? "--"
                  }`}</Typography>
                  <Typography fontSize={13} color={"text.secondary"}>{`Brand: ${
                    row.original?.brandName ?? "--"
                  }`}</Typography>
                </Box>
              )
            );
          }
        },
        {
          Header: "UOM",
          accessor: "uom",
          disableSortBy: true,
          size: 1,
          Cell: ({ row }) => {
            return (
              row.original?.unit && (
                <Typography>{row.original?.unit?.name}</Typography>
              )
            );
          }
        },
        {
          Header: "Rate (INR)",
          accessor: "rate",
          size: 1,
          disableSortBy: true
        },
        {
          Header: "Qty Order",
          accessor: "quantity",
          disableSortBy: true,
          size: 1
        },
        {
          Header: "Qty Return",
          accessor: "returnedQuantity",
          disableSortBy: true,
          size: 1
        },
        {
          Header: "Amount",
          accessor: "amount",
          size: 1,
          disableSortBy: true
        },
        ...[
          ...(gstType
            ? [
                {
                  Header: "CGST",
                  accessor: "cgst",
                  size: 1,
                  disableSortBy: true,
                  Cell: ({ row }) => {
                    return gstColumn(
                      row.original.cgst ?? 0,
                      row.original?.gstRegime / 2 ?? 0
                    );
                  }
                },
                {
                  Header: "SGST",
                  accessor: "sgst",
                  disableSortBy: true,
                  size: 1,
                  Cell: ({ row }) => {
                    return gstColumn(
                      row.original.sgst,
                      row.original?.gstRegime / 2 ?? 0
                    );
                  }
                }
              ]
            : [
                {
                  Header: "IGST",
                  accessor: "igst",
                  size: 1,
                  disableSortBy: true,
                  Cell: ({ row }) => {
                    return gstColumn(
                      row.original.igst ?? 0,
                      row.original?.gstRegime ?? 0
                    );
                  }
                }
              ])
        ],
        {
          Header: "Total",
          accessor: "total",
          size: 1.5,
          disableSortBy: true
        }
      ];
    case "SalesOrder":
      return [
        {
          Header: "S.No",
          accessor: "sNo",
          width: 90,
          size: 0.5
        },
        {
          Header: "Item/ SKU",
          accessor: "item",
          width: 200,
          size: 1.5,
          Cell: ({ row, value }) => {
            return row.original?.skuCode ? (
              <Box display={"flex"} flexDirection={"column"} gap={1}>
                <Box display={"flex"} gap={1} alignItems={"center"}>
                  {row.original?.image?.thumbUrl && (
                    <img
                      src={row.original?.image?.thumbUrl}
                      alt={row.original?.brandName ?? "--"}
                      height={52}
                      width={50}
                      style={{ objectFit: "contain" }}
                    />
                  )}
                  <Typography
                    fontSize={12}
                    fontWeight={600}
                    color={"primary"}
                    component={Link}
                  >
                    {row.original?.skuCode}
                  </Typography>
                </Box>
                <Typography fontSize={13} color={"text.secondary"}>
                  {`HSN : ${row.original?.hsn ?? "--"}`}
                </Typography>
              </Box>
            ) : (
              value
            );
          }
        },
        {
          Header: "Product",
          accessor: "product",
          width: 180,
          size: 1.5,
          disableSortBy: true,
          Cell: ({ row }) => {
            return (
              row.original?.productName && (
                <Box display={"flex"} flexDirection={"column"} gap={0.5}>
                  <Typography fontSize={14}>{`Product: ${
                    row.original?.productName ?? "--"
                  }`}</Typography>
                  <Typography
                    fontSize={13}
                    color={"text.secondary"}
                  >{`Category: ${
                    row.original?.categoryName ?? "--"
                  }`}</Typography>
                  <Typography fontSize={13} color={"text.secondary"}>{`Brand: ${
                    row.original?.brandName ?? "--"
                  }`}</Typography>
                </Box>
              )
            );
          }
        },
        {
          Header: "UOM",
          accessor: "uom",
          disableSortBy: true,
          size: 0.5,
          Cell: ({ row }) => {
            return (
              row.original?.unit && (
                <Typography>{row.original?.unit?.name}</Typography>
              )
            );
          }
        },
        {
          Header: "List Price",
          accessor: "rate",
          width: 96,
          size: 1,
          disableSortBy: true
        },
        {
          Header: "Quantity",
          accessor: "quantity",
          width: 120,
          size: 1,
          disableSortBy: true
        },
        {
          Header: "Disc.",
          accessor: "disc",
          width: 120,
          size: 1,
          disableSortBy: true
        },
        {
          Header: "Off. Price",
          accessor: "offPrice",
          width: 96,
          size: 1,
          disableSortBy: true
        },
        {
          Header: "Amount",
          accessor: "amount",
          size: 1,
          disableSortBy: true
        },
        ...[
          ...(gstType
            ? [
                {
                  Header: "CGST",
                  accessor: "cgst",
                  size: 1,
                  disableSortBy: true,
                  Cell: ({ row }) => {
                    return gstColumn(
                      row.original.cgst ?? 0,
                      row.original?.gstRegime / 2 ?? 0
                    );
                  }
                },
                {
                  Header: "SGST",
                  accessor: "sgst",
                  disableSortBy: true,
                  size: 1,
                  Cell: ({ row }) => {
                    return gstColumn(
                      row.original.sgst,
                      row.original?.gstRegime / 2 ?? 0
                    );
                  }
                }
              ]
            : [
                {
                  Header: "IGST",
                  accessor: "igst",
                  size: 1,
                  disableSortBy: true,
                  Cell: ({ row }) => {
                    return gstColumn(
                      row.original.igst ?? 0,
                      row.original?.gstRegime ?? 0
                    );
                  }
                }
              ])
        ],
        {
          Header: "Total",
          accessor: "total",
          size: 1,
          disableSortBy: true
        }
      ];
    case "SalesReturns":
      return [
        {
          Header: "S.No",
          accessor: "sNo",
          width: 90,
          size: 0.5
        },
        {
          Header: "Item/ SKU",
          accessor: "item",
          width: 200,
          size: 1.5,
          Cell: ({ row, value }) => {
            return row.original?.skuCode ? (
              <Box display={"flex"} flexDirection={"column"} gap={1}>
                <Box display={"flex"} gap={1} alignItems={"center"}>
                  {row.original?.image?.thumbUrl && (
                    <img
                      src={row.original?.image?.thumbUrl}
                      alt={row.original?.brandName ?? "--"}
                      height={52}
                      width={50}
                      style={{ objectFit: "contain" }}
                    />
                  )}
                  <Typography
                    fontSize={12}
                    fontWeight={600}
                    color={"primary"}
                    component={Link}
                  >
                    {row.original?.skuCode}
                  </Typography>
                </Box>
                <Typography fontSize={13} color={"text.secondary"}>
                  {`HSN : ${row.original?.hsn ?? "--"}`}
                </Typography>
              </Box>
            ) : (
              value
            );
          }
        },
        {
          Header: "Product",
          accessor: "product",
          width: 180,
          size: 1.5,
          disableSortBy: true,
          Cell: ({ row }) => {
            return (
              row.original?.productName && (
                <Box display={"flex"} flexDirection={"column"} gap={0.5}>
                  <Typography fontSize={14}>{`Product: ${
                    row.original?.productName ?? "--"
                  }`}</Typography>
                  <Typography
                    fontSize={13}
                    color={"text.secondary"}
                  >{`Category: ${
                    row.original?.categoryName ?? "--"
                  }`}</Typography>
                  <Typography fontSize={13} color={"text.secondary"}>{`Brand: ${
                    row.original?.brandName ?? "--"
                  }`}</Typography>
                </Box>
              )
            );
          }
        },
        {
          Header: "UOM",
          accessor: "uom",
          disableSortBy: true,
          size: 1,
          Cell: ({ row }) => {
            return (
              row.original?.unit && (
                <Typography>{row.original?.unit?.name}</Typography>
              )
            );
          }
        },
        {
          Header: "Price (INR)",
          accessor: "rate",
          size: 1,
          disableSortBy: true
        },
        {
          Header: "Qty Order",
          accessor: "quantity",
          disableSortBy: true,
          size: 1
        },
        {
          Header: "Qty Return",
          accessor: "returnedQuantity",
          disableSortBy: true,
          size: 1
        },
        {
          Header: "Amount",
          accessor: "amount",
          size: 1,
          disableSortBy: true
        },
        ...[
          ...(gstType
            ? [
                {
                  Header: "CGST",
                  accessor: "cgst",
                  size: 1,
                  disableSortBy: true,
                  Cell: ({ row }) => {
                    return gstColumn(
                      row.original.cgst ?? 0,
                      row.original?.gstRegime / 2 ?? 0
                    );
                  }
                },
                {
                  Header: "SGST",
                  accessor: "sgst",
                  disableSortBy: true,
                  size: 1,
                  Cell: ({ row }) => {
                    return gstColumn(
                      row.original.sgst,
                      row.original?.gstRegime / 2 ?? 0
                    );
                  }
                }
              ]
            : [
                {
                  Header: "IGST",
                  accessor: "igst",
                  size: 1,
                  disableSortBy: true,
                  Cell: ({ row }) => {
                    return gstColumn(
                      row.original.igst ?? 0,
                      row.original?.gstRegime ?? 0
                    );
                  }
                }
              ])
        ],
        {
          Header: "Total",
          accessor: "total",
          size: 1.5,
          disableSortBy: true
        }
      ];
    case "StockTransfer":
      return [
        {
          Header: "S.No",
          accessor: "sNo",
          width: 90,
          size: 0.5
        },
        {
          Header: "Item/ SKU",
          accessor: "item",
          width: 200,
          size: 2,
          Cell: ({ row, value }) => {
            return row.original?.skuCode ? (
              <Box display={"flex"} flexDirection={"column"} gap={1}>
                <Box display={"flex"} gap={1} alignItems={"center"}>
                  {row.original?.image?.thumbUrl && (
                    <img
                      src={row.original?.image?.thumbUrl}
                      alt={row.original?.brandName ?? "--"}
                      height={52}
                      width={50}
                      style={{ objectFit: "contain" }}
                    />
                  )}
                  <Typography
                    fontSize={12}
                    fontWeight={600}
                    color={"primary"}
                    component={Link}
                  >
                    {row.original?.skuCode}
                  </Typography>
                </Box>
                <Typography fontSize={13} color={"text.secondary"}>
                  {`HSN : ${row.original?.hsn ?? "--"}`}
                </Typography>
              </Box>
            ) : (
              value
            );
          }
        },
        {
          Header: "Product",
          accessor: "product",
          width: 180,
          size: 2,
          disableSortBy: true,
          Cell: ({ row }) => {
            return (
              row.original?.productName && (
                <Box display={"flex"} flexDirection={"column"} gap={0.5}>
                  <Typography fontSize={14}>{`Product: ${
                    row.original?.productName ?? "--"
                  }`}</Typography>
                  <Typography
                    fontSize={13}
                    color={"text.secondary"}
                  >{`Category: ${
                    row.original?.categoryName ?? "--"
                  }`}</Typography>
                  <Typography fontSize={13} color={"text.secondary"}>{`Brand: ${
                    row.original?.brandName ?? "--"
                  }`}</Typography>
                </Box>
              )
            );
          }
        },
        {
          Header: "UOM",
          accessor: "uom",
          disableSortBy: true,
          width: 80,
          size: 1,
          Cell: ({ row }) => {
            return (
              row.original?.unit && (
                <Typography>{row.original?.unit?.name}</Typography>
              )
            );
          }
        },
        {
          Header: "Stock at Origin",
          accessor: "stockOrigin",
          disableSortBy: true,
          width: 135,
          size: 2,
          Cell: ({ row }) => {
            return row.original?.skuCode
              ? row.original?.stock?.origin?.closingQuantity
              : "";
          }
        },
        {
          Header: "Stock at Receiving",
          accessor: "stockReceiving",
          disableSortBy: true,
          width: 160,
          size: 1.5,
          Cell: ({ row }) => {
            return row.original?.skuCode
              ? row.original?.stock?.receiving?.closingQuantity
              : "";
          }
        },
        {
          Header: "To be transferred",
          accessor: "toBeTransferred",
          disableSortBy: true,
          width: 150,
          size: 1.5
        },
        {
          Header: "Reason",
          accessor: "reason",
          disableSortBy: true,
          width: 170,
          size: 1.5
        }
      ];
    default:
      return [];
  }
};

const getDataByOrderType = (orderType, data = [], gstType) => {
  switch (orderType) {
    case "PurchaseOrder":
      return data.map((item, index) => ({
        sNo: index + 1,
        isEditable: false,
        orderStatus: true,
        gstType: gstType,
        _id: item.sku,
        rate: item.rate,
        quantity: item.quantity,
        skuCode: item.skuData.skuCode,
        productName: item?.skuData?.productName,
        brandName: item?.skuData?.brandName,
        categoryName: item?.skuData?.categoryName,
        unit: item?.skuData.unit,
        gstRegime: item?.skuData?.gstRegime ?? 0,
        hsn: item?.skuData?.hsn,
        image: item?.skuData?.image,
        cgst:
          item?.taxes?.length > 1
            ? currencyOnlyFormatter(
                parseFloat(item?.taxes[0]?.totalAmount).toFixed(2)
              )
            : 0,
        sgst:
          item?.taxes?.length > 1
            ? currencyOnlyFormatter(
                parseFloat(item?.taxes[1]?.totalAmount).toFixed(2)
              )
            : 0,
        igst:
          item?.taxes?.length === 1
            ? currencyOnlyFormatter(
                parseFloat(item?.taxes[0]?.totalAmount).toFixed(2)
              )
            : 0,
        amount: currencyOnlyFormatter(
          parseFloat(item?.subTotalAmount).toFixed(2)
        ),
        total: currencyOnlyFormatter(parseFloat(item?.totalAmount).toFixed(2))
      }));
    case "PurchaseReturn":
      return data.map((item, index) => ({
        sNo: index + 1,
        _id: item.sku,
        rate: item.rate,
        quantity: item.orderQuantity,
        returnedQuantity: item.quantity,
        skuCode: item.skuData.skuCode,
        productName: item?.skuData?.productName,
        brandName: item?.skuData?.brandName,
        categoryName: item?.skuData?.categoryName,
        unit: item?.skuData.unit,
        gstRegime: item?.skuData?.gstRegime ?? 0,
        hsn: item?.skuData?.hsn,
        image: item?.skuData?.image,
        cgst:
          item?.taxes?.length > 1
            ? currencyOnlyFormatter(
                parseFloat(item?.taxes[0]?.totalAmount).toFixed(2)
              )
            : 0,
        sgst:
          item?.taxes?.length > 1
            ? currencyOnlyFormatter(
                parseFloat(item?.taxes[1]?.totalAmount).toFixed(2)
              )
            : 0,
        igst:
          item?.taxes?.length === 1
            ? currencyOnlyFormatter(
                parseFloat(item?.taxes[0]?.totalAmount).toFixed(2)
              )
            : 0,
        amount: currencyOnlyFormatter(
          parseFloat(item?.subTotalAmount).toFixed(2)
        ),
        total: currencyOnlyFormatter(parseFloat(item?.totalAmount).toFixed(2))
      }));
    case "SalesOrder":
      return data.map((item, index) => ({
        sNo: index + 1,
        isEditable: false,
        orderStatus: true,
        gstType: gstType,
        _id: item.sku,
        rate: item?.skuData?.rate,
        disc: `${item?.discount?.percent} %`,
        offPrice: parseFloat(item?.rate).toFixed(2),
        quantity: item.quantity,
        skuCode: item.skuData.skuCode,
        productName: item?.skuData?.productName,
        brandName: item?.skuData?.brandName,
        categoryName: item?.skuData?.categoryName,
        unit: item?.skuData.unit,
        gstRegime: item?.skuData?.gstRegime ?? 0,
        hsn: item?.skuData?.hsn,
        image: item?.skuData?.image,
        cgst:
          item?.taxes?.length > 1
            ? currencyOnlyFormatter(
                parseFloat(item?.taxes[0]?.totalAmount).toFixed(2)
              )
            : 0,
        sgst:
          item?.taxes?.length > 1
            ? currencyOnlyFormatter(
                parseFloat(item?.taxes[1]?.totalAmount).toFixed(2)
              )
            : 0,
        igst:
          item?.taxes?.length === 1
            ? currencyOnlyFormatter(
                parseFloat(item?.taxes[0]?.totalAmount).toFixed(2)
              )
            : 0,
        amount: currencyOnlyFormatter(
          parseFloat(item?.subTotalAmount).toFixed(2)
        ),
        total: currencyOnlyFormatter(parseFloat(item?.totalAmount).toFixed(2))
      }));
    case "SalesReturns":
      return data.map((item, index) => ({
        sNo: index + 1,
        _id: item.sku,
        rate: item.rate,
        quantity: item.orderQuantity,
        returnedQuantity: item.quantity,
        skuCode: item.skuData.skuCode,
        productName: item?.skuData?.productName,
        brandName: item?.skuData?.brandName,
        categoryName: item?.skuData?.categoryName,
        unit: item?.skuData.unit,
        gstRegime: item?.skuData?.gstRegime ?? 0,
        hsn: item?.skuData?.hsn,
        image: item?.skuData?.image,
        cgst:
          item?.taxes?.length > 1
            ? currencyOnlyFormatter(
                parseFloat(item?.taxes[0]?.totalAmount).toFixed(2)
              )
            : 0,
        sgst:
          item?.taxes?.length > 1
            ? currencyOnlyFormatter(
                parseFloat(item?.taxes[1]?.totalAmount).toFixed(2)
              )
            : 0,
        igst:
          item?.taxes?.length === 1
            ? currencyOnlyFormatter(
                parseFloat(item?.taxes[0]?.totalAmount).toFixed(2)
              )
            : 0,
        amount: currencyOnlyFormatter(
          parseFloat(item?.subTotalAmount).toFixed(2)
        ),
        total: currencyOnlyFormatter(parseFloat(item?.totalAmount).toFixed(2))
      }));
    case "StockTransfer":
      return data.map((item, index) => ({
        sNo: index + 1,
        isEditable: true,
        status: true,
        _id: item.sku,
        toBeTransferred: item.quantity,
        reason: item.reason.displayName,
        skuCode: item.skuData.skuCode,
        productName: item?.skuData?.productName,
        brandName: item?.skuData?.brandName,
        categoryName: item?.skuData?.categoryName,
        unit: item?.skuData.unit,
        hsn: item?.skuData?.hsn,
        image: item?.skuData?.image,
        stock: item?.stock
      }));
    default:
      return [];
  }
};

const getMobileComponent = (orderType) => {
  switch (orderType) {
    case "PurchaseOrder":
      return MobilePOTable;
    case "PurchaseReturn":
      return MobilePRTable;
    case "SalesOrder":
      return MobileSOTable;
    case "SalesReturns":
      return MobileSRTable;
    case "StockTransfer":
      return MobileSTTable;
    default:
      return [];
  }
};

const HistoryTable = ({
  isPrint = false,
  data = [],
  orderType,
  costBreakUp,
  orderId,
  gstType = false,
  tnc = "",
  paymentTerms,
  shipping,
  reasonOfReturn = "",
  preparedBy
}) => {
  const toWords = new ToWords();
  const { control } = useForm({ defaultValues: { otherTNC: tnc } });

  const historyTable = useMemo(
    () => (
      <CustomTable
        columns={getColumnsByOrderType(orderType, gstType)}
        data={getDataByOrderType(orderType, data, gstType)}
        mobileComponent={getMobileComponent(orderType)}
      />
    ),
    [data, gstType, orderType]
  );

  return (
    <>
      {isPrint ? (
        <>
          <Grid container>
            <PrintableTable
              columns={getColumnsByOrderType(orderType, gstType)}
              rows={getDataByOrderType(orderType, data)}
              docType={orderType}
              gstType={gstType}
            />
          </Grid>
        </>
      ) : (
        historyTable
      )}
      <Grid
        container
        spacing={2}
        sx={{ display: { xs: isPrint ? "flex" : "none", md: "flex" } }}
      >
        <Grid item xs={9}>
          <Box display={"flex"} flexDirection={"column"} gap={3}>
            {orderType !== "StockTransfer" &&
              (reasonOfReturn ? (
                reportLabel1(purchase_labels.returnReasonLabel, reasonOfReturn)
              ) : (
                <Box display={"flex"} gap={5}>
                  {reportLabel1(
                    purchase_labels.paymentTermsText,
                    `${paymentTerms[0]}% Advance. Balance Due ${paymentTerms[1]} days from receipt`
                  )}
                  {reportLabel1(purchase_labels.shipping, shipping ?? "--")}
                </Box>
              ))}
            <Box width={"500px"}>
              <TextArea
                control={control}
                name={"otherTNC"}
                label={purchase_labels.otherItemsAndConditionsLabel}
                disabled={true}
              />
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={2.5}>
              {orderType !== "StockTransfer" &&
                reportLabel2(
                  purchase_labels.amountInWordsLabel,
                  `${toWords.convert(costBreakUp?.total ?? 0, {
                    currency: true
                  })}`
                )}
              {reportLabel2(
                purchase_labels.preparedByLabel,
                preparedBy ?? "--"
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={3}>
          {orderType !== "StockTransfer" && (
            <TotalBreakup
              gstType={gstType}
              subTotal={costBreakUp.subtotal}
              cgst={costBreakUp.cgst}
              sgst={costBreakUp.sgst}
              igst={costBreakUp.igst}
              total={costBreakUp.total}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default HistoryTable;
