import { getAllPackingNotes } from "../../customer/services";
import { keys } from "./keys";
import { useQuery } from "@tanstack/react-query";

export const useGetAllPackingNotes = (orderId) => {
  const packingNotesQuery = useQuery(
    [keys.queryGetAllPackingNotes, orderId],
    () => getAllPackingNotes(orderId),
    {
      enabled: Boolean(orderId),
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true
    }
  );

  return packingNotesQuery;
};
