import { getVendorFormData } from "../../purchase/services";
import { queryKeys } from "../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchVendorFormData = (vendorId) => {
  const queryVendorFormData = useQuery(
    [queryKeys.queryFetchVendorFormData],
    () => getVendorFormData(),
    {
      enabled: !Boolean(vendorId),
      refetchOnWindowFocus: false
    }
  );

  return queryVendorFormData;
};
