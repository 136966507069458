import { queryKeys } from "../../../constants/queryKeys";
import { getBillingById } from "../../../sales/services";
import { useQuery } from "@tanstack/react-query";

export const useFetchBillingById = (customerId,billingId) => {
  const customerById = useQuery(
    [queryKeys.queryFetchBillingById, customerId,billingId],
    () => getBillingById(customerId,billingId),
    {
      enabled: Boolean(customerId) && Boolean(billingId),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return customerById;
};
