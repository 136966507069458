import React, { useCallback, useEffect } from "react";
import { useFetchAllSubUsers } from "../../query-hooks/ClientSettings/useFetchAllSubUsers";
import { useDeleteSubUser } from "../../query-hooks/ClientSettings/useDeleteSubUser";
import { Button, Grid, Typography } from "@mui/material";
import CustomTable from "../../shared/customTable";
import { enqueueSnackbar } from "notistack";
import { useDrawer } from "../../shared/customSideDrawer";
import MobileAddSubUsers from "./MobileAddSubUsers";
import { useCustomDialog } from "../../shared/customDialog";
import SuccessfulDialog from "../../shared/UiElements/SuccessfulDialog";
import { MobileSubUsersCard } from "./MobileSubUsersCard";

const getColumns = () => {
  return [
    {
      Header: "User Name",
      accessor: "userName"
    },
    {
      Header: "Mobile No.",
      accessor: "mobileNo"
    },
    {
      Header: "User Access",
      accessor: "userAccess"
    },
    {
      Header: "Status",
      accessor: "status"
    },
    {
      Header: "Action",
      accessor: "actions"
    }
  ];
};

const getRows = (rows = [], handleEdit, onDeleteClick) => {
  return rows?.map((item, index) => {
    return {
      _id: item?._id,
      userName: item.firstName,
      mobileNo: item.mobile,
      userAccess: item.allowedScModules.map((item) => item.name),
      status: item.status.name,
      handleEdit: handleEdit,
      onDeleteClick: onDeleteClick
    };
  });
};

const MobileUserRoles = ({ clientId, userCount, allowCreateFormData }) => {
  const { showDrawer, hideDrawer } = useDrawer();
  const { showDialog, hideDialog } = useCustomDialog();

  const {
    data: allSubUsersData,
    isFetching: isFetchingSubUsers,
    refetch: refetchUsers
  } = useFetchAllSubUsers(clientId);

  const {
    mutate: deleteSubUser,
    isError: isDeleteUserError,
    error: deleteUserError
  } = useDeleteSubUser();

  const deleteUser = useCallback(
    (subUserId) => {
      deleteSubUser(
        {
          clientId: clientId,
          subUserId: subUserId
        },
        {
          onSuccess: (res) => {
            hideDialog();
            enqueueSnackbar("User Deleted!", {
              variant: "success"
            });
            refetchUsers();
          }
        }
      );
    },
    [clientId, hideDialog, refetchUsers, deleteSubUser]
  );

  const handleEdit = useCallback(
    (row = null) => {
      showDrawer({
        title: Boolean(row?._id) ? "Edit User" : "Add User",
        height: "fit-content",
        closeIcon: true,
        component: (
          <MobileAddSubUsers
            clientId={clientId}
            subUserId={row?._id || null}
            row={row}
            allowCreateFormData={
              Boolean(row?._id) ? false : allowCreateFormData
            }
            refetchUsers={refetchUsers}
            hideDrawer={hideDrawer}
          />
        )
      });
    },
    [allowCreateFormData, clientId, refetchUsers, showDrawer, hideDrawer]
  );

  const onDeleteClick = useCallback(
    (row) => {
      showDialog({
        component: (
          <SuccessfulDialog
            text={`Are you sure you want to delete the User - ${row?.userName}. This will permanently remove his access.`}
            btn1Text={"Confirm"}
            btn2Text={"Cancel"}
            btn1Callback={() => deleteUser(row?._id)}
            btn2Callback={() => hideDialog()}
            btn1Variant={"contained"}
            btn2Variant={"softError"}
          />
        )
      });
    },
    [deleteUser, hideDialog, showDialog]
  );

  useEffect(() => {
    if (isDeleteUserError) {
      enqueueSnackbar(deleteUserError?.response?.data?.message, {
        variant: "error"
      });
    }
  }, [deleteUserError?.response?.data?.message, isDeleteUserError]);

  return (
    <Grid container p={"15px"} rowSpacing={2}>
      <Grid
        item
        xs={6}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"left"}
        pl={"15px"}
      >
        <Typography
          fontSize={14}
          lineHeight={"16.94px"}
          fontWeight={700}
          color={"#2773FF"}
        >{`${
          allSubUsersData?.users?.length || 0
        }/${userCount} Users`}</Typography>
      </Grid>
      <Grid
        item
        xs={6}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"right"}
        pr={"15px"}
      >
        <Button
          variant="link"
          sx={{ fontWeight: 700 }}
          disabled={!allowCreateFormData}
          onClick={() => handleEdit(null)}
        >
          {"+ Add User"}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <CustomTable
          columns={getColumns()}
          data={
            isFetchingSubUsers
              ? []
              : getRows(allSubUsersData?.users, handleEdit, onDeleteClick)
          }
          isLoading={isFetchingSubUsers}
          mobileComponent={MobileSubUsersCard}
        />
      </Grid>
    </Grid>
  );
};

export default MobileUserRoles;
