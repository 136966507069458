import { Box } from "@mui/material";
import React from "react";
import LandingHeader from "./LandingHeader";
import HomeButtons from "./HomeButtons";
import { home_labels } from "../../constants";
import LandingBanner from "./LandingBanner";

const MarketplaceSliderComponent = () => {
  return (
    <>
      <Box
        sx={{
          padding: "0 90px !important",
          display: {
            xs: "none",
            md: "flex",
          },
        }}
      >
        <Box
          sx={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
            gap: "30px",
          }}
        >
          <LandingHeader />
          <HomeButtons
            isLanding={true}
            ButtonText1={home_labels.findWarehouseButtonText}
            ButtonText2={home_labels.findTruckButtonText}
            ButtonSubText1={home_labels.whatAreYouLookingForText}
          />
        </Box>
        <Box
          sx={{
            width: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LandingBanner />
        </Box>
      </Box>
      <Box
        sx={{
          display: {
            xs: "flex",
            md: "none",
          },
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          padding: "0 20px !important",
        }}
      >
        <LandingHeader />
        <LandingBanner />
        <HomeButtons
          isLanding={true}
          ButtonText1={home_labels.findWarehouseButtonText}
          ButtonText2={home_labels.findTruckButtonText}
          ButtonSubText1={home_labels.whatAreYouLookingForText}
        />
      </Box>
    </>
  );
};

export default MarketplaceSliderComponent;
