import { getWarehouseByPartnerId } from "../../customer/services";
import { queryKeys } from "../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchWarehouseByPartnerId = (params, partnerId) => {
  const queryWarehouseByPartnerId = useQuery(
    [queryKeys.queryFetchWarehouseByPartnerId, Boolean(params), partnerId],
    () => getWarehouseByPartnerId(params, partnerId),
    {
      enabled: Boolean(params) && Boolean(partnerId),
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true
    }
  );

  return queryWarehouseByPartnerId;
};
