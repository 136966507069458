import { Box, Button } from "@mui/material";
import { forwardRef, useRef } from "react";

import ServiceOrderDetails from "../CompleteOrder/ServiceOrderDetails";
import { appServiceKeys } from "../../../constants/formKeys";
import html2canvas from "html2canvas";
import { invoice_and_docs_labels } from "../../../constants";
import { isMobileDevice } from "../../../App";
import jsPDF from "jspdf";
import { useReactToPrint } from "react-to-print";

const ComponentToPrintDownload = forwardRef((props, ref) => {
  return (
    <div ref={ref} style={{ padding: "5px" }}>
      <ServiceOrderDetails type={appServiceKeys.transport} />
    </div>
  );
});

const PackingNotesDoc = () => {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  const handleDownloadPdf = async () => {
    const element = componentRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/png");

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = isMobileDevice ? 210 : pdf.internal.pageSize.getWidth();
    const pdfHeight = isMobileDevice
      ? 250
      : (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("PackingNotesDoc.pdf");
  };

  return (
    <Box>
      <Box textAlign={"end"} py={1}>
        <Button variant="text" onClick={handlePrint}>
          {invoice_and_docs_labels.print}
        </Button>
        <Button variant="text" sx={{ ml: 2 }} onClick={handleDownloadPdf}>
          {invoice_and_docs_labels.download}
        </Button>
      </Box>
      <ComponentToPrintDownload ref={componentRef} />
    </Box>
  );
};

export default PackingNotesDoc;
