import { Box, Typography } from "@mui/material";

import { CustomTabs } from "../../shared/customTab/CustomTabs";
import { useContext } from "react";
import { AppContext } from "../../shared/context/auth-context";
import SubscriptionTable from "../components/SubscriptionTable";
import MobileUserRoles from "../components/MobileUserRoles";

const MobileSettings = ({ clientId, userCount, allowCreateFormData }) => {
  const { activeMobileTab, handleMobileTabChange } = useContext(AppContext);

  const tabs = [
    {
      label: "Subscriptions",
      Component: <SubscriptionTable clientId={clientId} />
    },
    {
      label: "User Roles",
      Component: (
        <MobileUserRoles
          clientId={clientId}
          userCount={userCount}
          allowCreateFormData={allowCreateFormData}
        />
      )
    }
  ];

  return (
    <Box
      sx={{
        mb: { xs: 9 }
      }}
    >
      <Typography
        sx={{
          padding: "20px 21px 18px 21px",
          background: "#EEF1F4",
          fontSize: "16px",
          lineHeight: "19.36px",
          fontWeight: 700
        }}
      >
        {"Settings"}
      </Typography>
      <CustomTabs
        tabs={tabs}
        activeTab={activeMobileTab.Settings}
        onChange={handleMobileTabChange}
        secondaryTab={true}
      />
    </Box>
  );
};

export default MobileSettings;
