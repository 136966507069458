import { getUpdateBusinessFormData } from "../../shared/api/searchFormData";
import { queryKeys } from "../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchUpdateBusinessFormData = (role) => {
  const queryBusiness = useQuery(
    [queryKeys.queryGetUpdateBusinessFormData],
    () => getUpdateBusinessFormData(),
    {
      enabled: role === "CustomerAdmin",
      refetchOnWindowFocus: false
    }
  );

  return queryBusiness;
};
