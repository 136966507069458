import { queryKeys } from "../../constants/queryKeys";
import { getDCInUpdateFormData } from "../../finance/services";
import { useQuery } from "@tanstack/react-query";

export const useFetchDCInUpdateFormData = (dcInvoiceId, orderType, status) => {
  const DCInFormData = useQuery(
    [
      queryKeys.queryFetchDCInUpdateFormData,
      dcInvoiceId,
      Boolean(orderType === "SaleOrder"),
      Boolean(status === "Draft")
    ],
    () => getDCInUpdateFormData(dcInvoiceId),
    {
      enabled:
        Boolean(dcInvoiceId) &&
        Boolean(orderType === "SaleOrder") &&
        Boolean(status === "Draft"),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return DCInFormData;
};
