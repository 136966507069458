import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  styled
} from "@mui/material";
import { inventory_table_labels, purchase_labels } from "../../constants";

import ActionButton from "../../assets/icons/ActionButton.svg";
import CustomTable from "../../shared/customTable";
import Filter from "../components/Filter";
import appRoutes from "../../constants/appRoutes";
import { inventory_filterKeys } from "../../constants/formKeys";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { useFetchVendors } from "../../query-hooks/Vendors/useFetchVendors";
import { useEffect, useMemo, useState } from "react";
import {
  currencyFormatter,
  currencyOnlyFormatter,
  dropdownOptions
} from "../../shared/utils";
import { useFetchVendorFilters } from "../../query-hooks/Vendors/useFetchVendorFilters";
import { enqueueSnackbar } from "notistack";
import { useFetchVendorsSubtotal } from "../../query-hooks/Vendors/useFetchVendorsSubtotal";
import DropDown from "../../shared/formElements/DropDown";
import { Search } from "@mui/icons-material";
import { isMobile, isTablet } from "react-device-detect";
import { VendorsMobileTable } from "../components/Vendors/VendorsMobileTable";

const getColumns = (onButtonClick) => {
  return [
    {
      Header: "Vendor Company Name",
      accessor: "vendorCompanyName",
      width: 250,
      Cell: ({ value, row }) => {
        return (
          <Typography
            fontSize={15}
            fontWeight={600}
            color={"primary"}
            component={Link}
            to={row.original.to}
          >
            {value}
          </Typography>
        );
      }
    },
    {
      Header: "POC Name",
      accessor: "pocName"
    },
    {
      Header: "Mobile No.",
      accessor: "mobileNo"
    },
    {
      Header: "Email Id",
      accessor: "email"
    },
    {
      Header: "Invoice Value",
      accessor: "invoiceValue"
    },
    {
      Header: "Amount Paid",
      accessor: "amountPaid"
    },
    {
      Header: "Balance",
      accessor: "balance"
    },
    {
      Header: "Avg. Due",
      accessor: "averageDue"
    },
    {
      Header: "Actions",
      id: "action",
      Cell: ({ value }) => {
        return (
          value && (
            <Box onClick={onButtonClick} sx={{ cursor: "pointer" }}>
              <img src={ActionButton} alt="" />
            </Box>
          )
        );
      }
    }
  ];
};

const getRows = (rows = []) => {
  return rows.map((row) => ({
    vendorId: row?._id,
    to: `${appRoutes.purchase.main}/${appRoutes.purchase.vendors}/${row?._id}`,
    vendorCompanyName: row?.companyName,
    pocName: row?.contact?.name,
    mobileNo: row?.contact?.mobile,
    email: row?.contact?.email,
    invoiceValue: row?.totalInvoiceAmount
      ? currencyOnlyFormatter(parseFloat(row?.totalInvoiceAmount).toFixed(2))
      : "",
    amountPaid: row?.totalPaidAmount
      ? currencyOnlyFormatter(parseFloat(row?.totalPaidAmount).toFixed(2))
      : "",
    balance: !Boolean(row?.totalPaidAmount)
      ? row?.totalInvoiceAmount
        ? currencyOnlyFormatter(parseFloat(+row?.totalInvoiceAmount).toFixed(2))
        : ""
      : currencyOnlyFormatter(
          parseFloat(+row?.totalInvoiceAmount - +row?.totalPaidAmount).toFixed(
            2
          )
        ),
    averageDue: "",
    action: ""
  }));
};

export const CustomTypo = styled(Typography)`
  font-weight: 700;
  line-height: 19.36px;
`;

export const SubtotalTypo = (value, label) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={1}
      alignItems={"center"}
    >
      <CustomTypo color="primary">{value}</CustomTypo>
      <Typography
        sx={{ fontWeight: 400, lineHeight: "14px", fontSize: "12px" }}
      >
        {`(${label})`}
      </Typography>
    </Box>
  );
};

const Vendors = () => {
  const [params, setParams] = useState();
  const { control, watch, reset, handleSubmit } = useForm();

  const navigate = useNavigate();

  const VendorWatch = watch(inventory_filterKeys.vendorName);

  const { data: filterData, isFetching: isFilterFetching } =
    useFetchVendorFilters();

  const { data: subTotalAmount, isFetching: isFetchingSubtotals } =
    useFetchVendorsSubtotal(params);

  const { ref, inView } = useInView();
  const {
    data,
    isFetching,
    isFetched,
    hasNextPage,
    fetchNextPage,
    refetch,
    isError,
    error
  } = useFetchVendors(params);

  const vendorTable = useMemo(
    () => (
      <CustomTable
        columns={getColumns()}
        data={
          isFetched
            ? data?.pages?.flatMap((page) => getRows(page?.vendors)) ?? []
            : []
        }
        isLoading={!isFetched}
        mobileComponent={VendorsMobileTable}
      />
    ),
    // eslint-disable-next-line
    [data?.pages, isFetched]
  );

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, inView]);

  const onViewClick = (data) => {
    const filterData = {
      ...{
        ...(data["vendorName"] !== ""
          ? {
              [`filters[vendor]`]: data["vendorName"]
            }
          : {})
      }
    };
    setParams(filterData);
  };

  useEffect(() => {
    if ((isMobile || isTablet) && VendorWatch) {
      const filterData = {
        ...{
          ...(VendorWatch !== "" && VendorWatch !== undefined
            ? {
                [`filters[vendor]`]: VendorWatch
              }
            : {})
        }
      };
      setParams(filterData);
    } else if ((isMobile || isTablet) && !Boolean(VendorWatch)) {
      setParams(null);
    } // eslint-disable-next-line
  }, [JSON.stringify(VendorWatch)]);

  useEffect(() => {
    if (params) {
      refetch();
    }
  }, [params, refetch]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(error?.response?.data?.message, {
        variant: "error"
      });
    }
  }, [error?.response?.data?.message, isError]);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      sx={{
        mb: { xs: 9, md: 0 }
      }}
    >
      <Box
        flexDirection={"column"}
        gap={2}
        sx={{
          display: { xs: "none", md: "flex" },
          position: "sticky",
          top: "64px",
          zIndex: 1000,
          background: "#fff",
          padding: "16px 0"
        }}
      >
        <Grid item xs={12} textAlign={"end"}>
          <Button
            variant="link"
            disableRipple
            onClick={() =>
              navigate(
                `${appRoutes.purchase.main}/${appRoutes.purchase.vendors}/${appRoutes.purchase.addVendor}`
              )
            }
          >
            {purchase_labels.addNewVendorButton}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Filter
              control={control}
              onApply={handleSubmit(onViewClick)}
              onClear={() => {
                reset();
                setParams(null);
              }}
              isFetching={isFilterFetching}
              isLoading={isFetching}
              filters={[
                {
                  type: "DropDown",
                  name: inventory_filterKeys.vendorName,
                  label: inventory_table_labels.vendorName,
                  unSelectText: "Select Vendor",
                  allowSearch: true,
                  options: dropdownOptions(
                    filterData?.vendors,
                    "_id",
                    "companyName"
                  ),
                  filterCount: filterData?.vendors?.length
                }
              ]}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              border: "0.6px solid #2773FF",
              borderRadius: 2,
              padding: 4,
              display: "flex",
              gap: 3,
              justifyContent: "flex-end"
            }}
          >
            {isFetchingSubtotals ? (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                width={"100%"}
              >
                <CircularProgress color="primary" />
              </Box>
            ) : (
              <>
                <CustomTypo color="primary">Subtotal :</CustomTypo>
                {SubtotalTypo(
                  subTotalAmount?.subTotals?.totalInvoiceAmount
                    ? currencyFormatter(
                        parseFloat(
                          subTotalAmount?.subTotals?.totalInvoiceAmount
                        ).toFixed(2)
                      )
                    : "--",
                  "Invoice Value"
                )}
                {SubtotalTypo(
                  subTotalAmount?.subTotals?.totalPaidAmount
                    ? currencyFormatter(
                        parseFloat(
                          subTotalAmount?.subTotals?.totalPaidAmount
                        ).toFixed(2)
                      )
                    : "--",
                  "Amount Paid"
                )}
                {SubtotalTypo(
                  !Boolean(subTotalAmount?.subTotals?.totalPaidAmount)
                    ? subTotalAmount?.subTotals?.totalInvoiceAmount
                      ? currencyOnlyFormatter(
                          parseFloat(
                            +subTotalAmount?.subTotals?.totalInvoiceAmount
                          ).toFixed(2)
                        )
                      : "--"
                    : currencyOnlyFormatter(
                        parseFloat(
                          +subTotalAmount?.subTotals?.totalInvoiceAmount -
                            +subTotalAmount?.subTotals?.totalPaidAmount
                        ).toFixed(2)
                      ),
                  "Balance"
                )}
                {SubtotalTypo(
                  subTotalAmount?.subTotals?.avgDueDays ?? "--",
                  "Avg. Due"
                )}
              </>
            )}
          </Box>
        </Grid>
      </Box>
      <Grid
        container
        spacing={2}
        sx={{
          display: { xs: "flex", lg: "none" },
          alignItems: "center",
          position: "sticky",
          top: "60px",
          zIndex: 1,
          background: "#fff",
          padding: "10px 20px"
        }}
      >
        <Grid item xs={8}>
          <DropDown
            control={control}
            name={inventory_filterKeys.vendorName}
            placeholder={"Search Vendor"}
            startIcon={Search}
            options={dropdownOptions(filterData?.vendors, "_id", "companyName")}
            allowSearch
            availUnselect
            unSelectText="Select Vendor"
            sx={{ borderRadius: 54 }}
          />
        </Grid>
        <Grid item xs={4} display={"flex"} justifyContent={"right"}>
          <Button
            variant="link"
            disableRipple
            sx={{
              fontSize: "13px",
              lineHeight: "16px",
              fontWeight: 700
            }}
            onClick={() =>
              navigate(
                `${appRoutes.purchase.main}/${appRoutes.purchase.vendors}/${appRoutes.purchase.addVendor}`
              )
            }
          >
            {purchase_labels.addVendor}
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ padding: { xs: "0 15px", md: 0 } }}>{vendorTable}</Box>
      {hasNextPage && (
        <Grid
          item
          xs={12}
          ref={ref}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress />
        </Grid>
      )}
    </Box>
  );
};

export default Vendors;
