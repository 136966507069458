import { Box, Divider, Grid, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import { SearchFormKeys, appServiceKeys } from "../../../constants/formKeys";
import { appConstants, labels } from "../../../constants";
import {
  currencyFormatter,
  dateTimeFormatter,
  stringCapitalization
} from "../../../shared/utils";

import AppLogo from "../../../assets/Warex.svg";
import { CustomTabs } from "../../../shared/customTab/CustomTabs";
import Storage from "../../../shared/utils/Storage";
import { StorageKeys } from "../../../constants/storage-keys";
import { StyleBorderedGrid } from ".";
import { ToWords } from "to-words";
import { isMobileDevice } from "../../../App";
import moment from "moment";
import { primaryBackground } from "../../../shared/theme/globalTheme";
import styled from "@emotion/styled";

const HeaderTypography = styled((props) => <Typography {...props} />)(
  ({ theme }) => ({
    color: theme.palette.primary.dark,
    fontSize: 9,
    fontWeight: 600,
    lineHeight: "11px",
    whiteSpace: "initial",
    [theme.breakpoints.up("lg")]: { fontSize: 16, lineHeight: "20px" }
  })
);

const HeaderGrid = styled((props) => <Grid {...props} />)(({ theme }) => ({
  background: primaryBackground
}));

const TableHeaderTypography = styled((props) => (
  <Typography
    {...props}
    textAlign={props?.textAlign ? props.textAlign : "left"}
  />
))(({ theme }) => ({
  color: theme.palette.primary,
  padding: 12,
  fontSize: 7,
  fontWeight: 600,
  lineHeight: "9px",
  whiteSpace: "nowrap",
  [theme.breakpoints.up("lg")]: { fontSize: 14, lineHeight: "18px" },
  "&:first-of-type": {
    [theme.breakpoints.up("xs")]: {
      paddingLeft: 16,
      paddingRight: 16
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 24,
      paddingRight: 24
    }
  }
}));

const TableDataTypography = styled((props) => (
  <Typography
    {...props}
    textAlign={props?.textAlign ? props.textAlign : "left"}
  />
))(({ theme }) => ({
  color: theme.palette.primary,
  padding: 12,
  fontSize: 7,
  fontWeight: 400,
  lineHeight: "11px",
  whiteSpace: "initial",
  [theme.breakpoints.up("lg")]: { fontSize: 14, lineHeight: "20px" },
  "&:first-of-type": {
    [theme.breakpoints.up("xs")]: {
      paddingLeft: 16,
      paddingRight: 16
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 24,
      paddingRight: 24
    }
  }
}));

const AddressDateCard = ({
  isDateCard = false,
  header = "",
  orderNo = " - ",
  orderDate = "",
  inboundDate = "",
  outboundDate = "",
  pickUpTime = "",
  pickUpDate = "",
  firstName = "",
  lastName = "",
  firmsName = "",
  streetAddress = "",
  city = "",
  country = "",
  zipCode = "",
  contact = "",
  gstin = ""
}) => {
  return (
    <Box>
      {isDateCard ? (
        <Typography
          component="div"
          color={"text.primary"}
          sx={{
            fontSize: { xs: 7, lg: 14 },
            fontWeight: 600,
            lineHeight: { xs: "11px", lg: "20px" },
            whiteSpace: "initial"
          }}
        >
          {labels.orderNo + ". " + orderNo}
          {orderDate ? (
            <>
              <br />
              {labels.orderDate +
                ": " +
                dateTimeFormatter(orderDate, appConstants.orderDateFormat)}
            </>
          ) : (
            ""
          )}
          {inboundDate ? (
            <>
              <br />
              {labels.inboundDateLabelText +
                ": " +
                dateTimeFormatter(inboundDate, appConstants.orderDateFormat)}
            </>
          ) : (
            ""
          )}
          {outboundDate ? (
            <>
              <br />
              {labels.outboundDateLabelText +
                ": " +
                dateTimeFormatter(outboundDate, appConstants.orderDateFormat)}
            </>
          ) : (
            ""
          )}
          {pickUpDate ? (
            <>
              <br />
              {labels.pickupDateLabelText +
                ": " +
                dateTimeFormatter(pickUpDate, appConstants.orderDateFormat)}
            </>
          ) : (
            ""
          )}
          {pickUpTime ? (
            <>
              <br />
              {labels.pickupTimeApproxLabelText +
                ": " +
                dateTimeFormatter(pickUpTime, appConstants.timeFormat)}
            </>
          ) : (
            ""
          )}
        </Typography>
      ) : (
        <Box display={"flex"} flexDirection={"column"} gap={1} maxWidth={200}>
          <HeaderTypography component="div">{header}</HeaderTypography>
          {(firstName || lastName) && (
            <Typography
              component="div"
              color={"text.primary"}
              sx={{
                fontSize: { xs: 7, lg: 14 },
                fontWeight: 600,
                lineHeight: { xs: "11px", lg: "20px" },
                whiteSpace: "wrap"
              }}
            >
              {firstName + " " + lastName}
            </Typography>
          )}
          {firmsName && (
            <Typography
              component="div"
              color={"text.secondary"}
              sx={{
                fontSize: { xs: 6, lg: 13 },
                fontWeight: 400,
                lineHeight: { xs: "10px", lg: "18px" },
                whiteSpace: "initial"
              }}
            >
              {firmsName}
            </Typography>
          )}
          <Typography
            component="div"
            color={"text.secondary"}
            sx={{
              fontSize: { xs: 6, lg: 13 },
              fontWeight: 400,
              lineHeight: { xs: "10px", lg: "18px" },
              whiteSpace: "initial"
            }}
          >
            {streetAddress +
              (city && ", " + city) +
              (country && ", " + country) +
              (zipCode && ", " + zipCode)}
            <br />
            {contact}
          </Typography>
          {gstin && (
            <Typography
              component="div"
              color={"text.secondary"}
              sx={{
                fontSize: { xs: 6, lg: 13 },
                fontWeight: 400,
                lineHeight: { xs: "10px", lg: "18px" },
                whiteSpace: "wrap"
              }}
            >
              {labels.gstinLabelText + " : " + gstin}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

const DetailsTable = ({
  isTransport = false,
  detailsList = [],
  totalAmount,
  gstAmount,
  gstRegime
}) => {
  const toWords = new ToWords();

  return (
    <Box>
      <HeaderGrid container>
        <Grid item xs={1.1}>
          <TableHeaderTypography>Item no.</TableHeaderTypography>
        </Grid>
        <Grid item xs={3}>
          <TableHeaderTypography>Item description</TableHeaderTypography>
        </Grid>
        <Grid item xs={1}>
          <TableHeaderTypography>UOM</TableHeaderTypography>
        </Grid>
        <Grid item xs={1.5}>
          {!isTransport && (
            <TableHeaderTypography>Duration</TableHeaderTypography>
          )}
        </Grid>
        <Grid item xs={1}>
          <TableHeaderTypography>Qty</TableHeaderTypography>
        </Grid>
        <Grid item xs={2.2}>
          <TableHeaderTypography>Rate</TableHeaderTypography>
        </Grid>
        <Grid item xs={2.2}>
          <TableHeaderTypography textAlign="end">Amount</TableHeaderTypography>
        </Grid>
      </HeaderGrid>
      <Grid container>
        {detailsList.map((item, index) => {
          return (
            <Fragment key={index}>
              <Grid item xs={1.1}>
                <TableDataTypography>{item.itemNo}</TableDataTypography>
              </Grid>
              <Grid item xs={3}>
                <TableDataTypography>{item.itemDesc}</TableDataTypography>
              </Grid>
              <Grid item xs={1}>
                <TableDataTypography>{item.uom}</TableDataTypography>
              </Grid>
              <Grid item xs={1.5}>
                {!isTransport && (
                  <TableDataTypography>{item.duration}</TableDataTypography>
                )}
              </Grid>
              <Grid item xs={1}>
                <TableDataTypography>{item.quantity}</TableDataTypography>
              </Grid>
              <Grid item xs={2.2}>
                <TableDataTypography>
                  {item.rate &&
                    currencyFormatter(parseFloat(item.rate).toFixed(2))}
                </TableDataTypography>
              </Grid>
              <Grid item xs={2.2}>
                <TableDataTypography textAlign="end">
                  {currencyFormatter(parseFloat(item.totalAmount).toFixed(2))}
                </TableDataTypography>
              </Grid>
            </Fragment>
          );
        })}
      </Grid>
      <Grid container>
        <Grid item xs={9.8}>
          <TableDataTypography textAlign="end">
            {`${labels.gstAt} ${gstRegime}%`}
          </TableDataTypography>
        </Grid>
        <Grid item xs={2.2}>
          <TableDataTypography textAlign="end">
            {currencyFormatter(parseFloat(gstAmount).toFixed(2))}
          </TableDataTypography>
        </Grid>
        <Grid item xs={12} textAlign={"-webkit-center"}>
          <Divider
            sx={{
              width: "95%",
              borderBottomWidth: "0.4px",
              borderRadius: "0.5px",
              my: 1
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <TableDataTypography textAlign="start">
            {`${labels.inWord} : `}
          </TableDataTypography>
        </Grid>
        <Grid item xs={6}>
          <TableDataTypography textAlign="start">
            {toWords.convert(totalAmount ?? 0, { currency: true })}
          </TableDataTypography>
        </Grid>
        <Grid item xs={4}>
          <TableHeaderTypography textAlign="end">
            {labels.total + " :  "}
            {currencyFormatter(parseFloat(totalAmount).toFixed(2))}
          </TableHeaderTypography>
        </Grid>
      </Grid>
    </Box>
  );
};

const ServiceOrderDetails = ({
  isInquiry,
  customerInfo,
  type,
  cartItem,
  orderFormData
}) => {
  const service = Storage.getItem(StorageKeys.serviceLocalStorage);
  const [activeTab, setActiveTab] = useState(0);
  const FORM_DATA = Storage.getItem(StorageKeys.SEARCH_FORM_DATA);
  const FORM_PARAMS = Storage.getItem(StorageKeys.SEARCH_FORM_PARAMS);

  const timeDiff = isInquiry
    ? moment(cartItem?.warehouse?.searchForm?.outboundDate).diff(
        moment(cartItem?.warehouse?.searchForm?.inboundDate),
        "days"
      )
    : moment(FORM_PARAMS["searchForm[outboundDate]"]).diff(
        moment(FORM_DATA["searchForm[inboundDate]"]),
        "days"
      );

  const formatDate = (date) => moment(date).format("YYYY-MM-DD");

  const dateDiff = (timeDiff + 1) / 30;

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  const warehouse = (
    <Grid item xs={12}>
      <StyleBorderedGrid container>
        <Grid item xs={12}>
          <Grid
            container
            sx={{
              backgroundImage: `url(${AppLogo}) `,
              backgroundRepeat: "no-repeat ",
              backgroundPosition: "center",
              backgroundSize: isMobileDevice ? "50px" : "100px",
              padding: {
                xs: "24px 16px",
                lg: 3
              }
            }}
          >
            <Grid item xs={6}>
              <AddressDateCard
                header={labels.customerOrBuyer}
                firstName={customerInfo?.firstName}
                lastName={customerInfo?.lastName}
                firmsName={customerInfo?.firmsName}
                streetAddress={customerInfo?.streetAddress}
                city={customerInfo?.city}
                country={customerInfo?.country}
                zipCode={customerInfo?.zipCode}
                contact={customerInfo?.contact}
                gstin={customerInfo?.gstin}
              />
            </Grid>
            <Grid item xs={6} textAlign={"end"}>
              <AddressDateCard
                isDateCard={true}
                orderDate={""}
                inboundDate={formatDate(
                  isInquiry
                    ? cartItem?.warehouse?.searchForm?.inboundDate
                    : FORM_DATA.inboundDate
                )}
                outboundDate={formatDate(
                  isInquiry
                    ? cartItem?.warehouse?.searchForm?.outboundDate
                    : FORM_DATA.outboundDate
                )}
              />
            </Grid>
            <Grid item xs={6} mt={{ lg: 4 }}>
              <AddressDateCard
                header={labels.storageFacility}
                firstName={cartItem?.warehouse?.code}
                streetAddress={cartItem?.warehouse?.address?.area}
                city={cartItem?.warehouse?.address?.city}
                country={cartItem?.warehouse?.address?.country}
                zipCode={cartItem?.warehouse?.address?.pincode}
                gstin={orderFormData?.warehouse?.gstNo}
              />
            </Grid>
            <Grid
              item
              xs={6}
              mt={{ lg: 4 }}
              textAlign={"end"}
              display={"flex"}
              justifyContent={"flex-end"}
            >
              <AddressDateCard
                header={labels.serviceProvider}
                firstName={orderFormData?.warehouse?.name}
                streetAddress={orderFormData?.warehouse?.address.area}
                city={orderFormData?.warehouse?.address.city}
                country={orderFormData?.warehouse?.address.country}
                zipCode={orderFormData?.warehouse?.address.pincode}
                gstin={orderFormData?.warehouse?.gstNo}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} mt={5}></Grid>
        <Grid item xs={12}>
          <DetailsTable
            detailsList={[
              {
                itemNo: 1,
                itemDesc: `Storage services Material : ${
                  isInquiry
                    ? cartItem?.warehouse?.searchForm?.materialCategory
                        ?.displayName || "--"
                    : FORM_DATA[SearchFormKeys.MaterialDetails]
                } HSN Code : 99679`,
                uom: isInquiry
                  ? `${cartItem?.warehouse?.service?.rateUnit}`
                  : `${FORM_DATA[SearchFormKeys.StorageType]}`,
                duration: parseInt(dateDiff * 100) / 100,
                quantity: isInquiry
                  ? cartItem?.warehouse?.service?.quantity
                  : FORM_DATA[SearchFormKeys.pallets],
                rate: isInquiry
                  ? cartItem?.warehouse?.service?.rate
                  : cartItem?.warehouse?.storageTypes?.[0].rate ||
                    orderFormData?.warehouse?.storageTypes?.[0].rate,
                totalAmount: isInquiry
                  ? cartItem?.warehouse?.costs?.serviceCost
                  : cartItem?.warehouse?.costs?.serviceCost ||
                    orderFormData?.warehouse?.costs?.serviceCost
              },
              {
                itemNo: 2,
                itemDesc: `Additional Costs Material Handling`,
                uom: "",
                duration: "",
                quantity: "",
                rate: "",
                totalAmount: isInquiry
                  ? cartItem?.warehouse?.costs?.additionalServiceCost
                  : orderFormData?.warehouse?.costs?.additionalServiceCost
              },
              {
                itemNo: "",
                itemDesc: `Sub Total`,
                uom: "",
                duration: "",
                quantity: "",
                rate: "",
                totalAmount: isInquiry
                  ? cartItem?.warehouse?.costs?.subTotalAmount
                  : orderFormData?.warehouse?.costs?.subTotalAmount
              }
            ]}
            gstRegime={
              isInquiry
                ? cartItem?.warehouse?.service?.gstRegime
                : orderFormData?.warehouse?.gstRegime
            }
            gstAmount={
              isInquiry
                ? cartItem?.warehouse?.costs?.taxCost
                : orderFormData?.warehouse?.costs?.taxCost
            }
            totalAmount={
              isInquiry
                ? cartItem?.warehouse?.costs?.totalAmount
                : orderFormData?.warehouse?.costs?.totalAmount
            }
          />
        </Grid>
      </StyleBorderedGrid>
    </Grid>
  );

  const transporter = (
    <Grid item xs={12}>
      <StyleBorderedGrid container>
        <Grid item xs={12}>
          <Grid
            container
            sx={{
              backgroundImage: `url(${AppLogo}) `,
              backgroundRepeat: "no-repeat ",
              backgroundPosition: "center",
              backgroundSize: isMobileDevice ? "50px" : "100px",
              padding: {
                xs: "24px 16px",
                lg: 3
              }
            }}
          >
            <Grid item xs={6}>
              <AddressDateCard
                header={labels.customerOrBuyer}
                firstName={customerInfo?.firstName}
                lastName={customerInfo?.lastName}
                firmsName={customerInfo?.firmsName}
                streetAddress={customerInfo?.streetAddress}
                city={customerInfo?.city}
                country={customerInfo?.country}
                zipCode={customerInfo?.zipCode}
                contact={customerInfo?.contact}
                gstin={customerInfo?.gstin}
              />
            </Grid>
            <Grid item xs={6} textAlign={"end"}>
              <AddressDateCard
                isDateCard={true}
                orderDate={""}
                pickUpDate={customerInfo?.pickUpDate}
                pickUpTime={customerInfo?.pickUpTime}
              />
            </Grid>
            <Grid item xs={6} mt={{ lg: 4 }}>
              {/* <AddressDateCard
                header={labels.storageFacility}
                firstName={cartItem?.transport?.code}
                // lastName={address.lastName}
                // firmsName={address.firmsName}
                streetAddress={cartItem?.transport?.address?.area}
                city={cartItem?.transport?.address?.city}
                country={cartItem?.transport?.address?.country}
                zipCode={cartItem?.transport?.address?.pincode}
                // contact={address.contact}
                gstin={orderFormData?.transport?.gstNo}
              /> */}
            </Grid>
            <Grid
              item
              xs={6}
              mt={{ lg: 4 }}
              textAlign={"end"}
              display={"flex"}
              justifyContent={"flex-end"}
            >
              <AddressDateCard
                header={labels.serviceProvider}
                firstName={orderFormData?.transport?.name}
                // lastName={orderFormData?.transport.address.lastName}
                // firmsName={address.firmsName}
                streetAddress={orderFormData?.transport?.address.area}
                city={orderFormData?.transport?.address.city}
                country={orderFormData?.transport?.address.country}
                zipCode={orderFormData?.transport?.address.pincode}
                // contact={address.contact}
                gstin={orderFormData?.transport?.gstNo}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} mt={5}></Grid>
        <Grid item xs={12}>
          <DetailsTable
            isTransport
            detailsList={[
              {
                itemNo: 1,
                itemDesc: `Transport services Material : ${
                  isInquiry
                    ? cartItem?.transport?.searchForm?.materialCategory
                        ?.displayName
                    : FORM_DATA[SearchFormKeys.MaterialDetails]
                } \nHSN Code : 99679`,
                uom: isInquiry
                  ? `${cartItem?.transport?.service?.rateUnit}`
                  : `${cartItem?.transport?.vehicles?.[0].rateUnit}`,
                duration: 0,
                quantity: isInquiry
                  ? cartItem?.transport?.searchForm?.distance
                  : FORM_DATA.distance?.toFixed(2),
                rate: isInquiry
                  ? cartItem?.transport?.service?.rate
                  : cartItem?.transport?.vehicles?.[0].rate,
                totalAmount: cartItem?.transport?.costs?.serviceCost
              },
              {
                itemNo: 2,
                itemDesc: `Additional Costs Material Handling`,
                uom: "",
                duration: "",
                quantity: "",
                rate: "",
                totalAmount: isInquiry
                  ? cartItem?.transport?.costs?.additionalServiceCost
                  : orderFormData?.transport?.costs?.additionalServiceCost
              },
              {
                itemNo: "",
                itemDesc: `Sub Total`,
                uom: "",
                duration: "",
                quantity: "",
                rate: "",
                totalAmount: isInquiry
                  ? cartItem?.transport?.costs?.subTotalAmount
                  : orderFormData?.transport?.costs?.subTotalAmount
              }
            ]}
            gstRegime={
              isInquiry
                ? cartItem?.transport?.service?.gstRegime
                : orderFormData?.transport?.gstRegime
            }
            gstAmount={
              isInquiry
                ? cartItem?.transport?.costs?.taxCost
                : orderFormData?.transport?.costs?.taxCost
            }
            totalAmount={
              isInquiry
                ? cartItem?.transport?.costs?.totalAmount
                : orderFormData?.transport?.costs?.totalAmount
            }
          />
        </Grid>
      </StyleBorderedGrid>
    </Grid>
  );

  const components = {
    warehouse,
    transport: transporter
  };
  const tabs =
    service &&
    ["warehouse", "transport"]
      .map((property) =>
        service[property]
          ? {
              label:
                property === "transport"
                  ? labels.transporter
                  : stringCapitalization(property),
              Component: components[property]
            }
          : null
      )
      .filter(Boolean);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {type === appServiceKeys.warehouse ? (
            warehouse
          ) : type === appServiceKeys.transport ? (
            transporter
          ) : (
            <CustomTabs
              tabs={tabs}
              activeTab={activeTab}
              onChange={handleTabChange}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ServiceOrderDetails;
