import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  CircularProgress,
  IconButton
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { purchase_labels } from "../../constants";
import { useInView } from "react-intersection-observer";
import CustomTable from "../../shared/customTable";
import { StyledChip } from "../../shared/UiElements/StatusChip";
import appRoutes from "../../constants/appRoutes";
import { stringCapitalization } from "../../shared/utils";
import { useEffect, useMemo } from "react";
import { getCustomCell } from "./PurchaseOrders";
import { useFetchStockTransfers } from "../../query-hooks/ClientPurchase/StockTransfer/useFetchStockTransfers";
import moment from "moment";
import { MobileTransferOrders } from "../components/Purchase/MobileTransferOrders";
import { ArrowBack } from "@mui/icons-material";

export const getDocumentsByType = (docs = [], Page = "") => {
  if (!Boolean(docs?.length)) return;
  const lastObject = docs.length > 0 ? docs[docs.length - 1] : {};
  const {
    code,
    customCode,
    docDate = "",
    order = "",
    invoiceNo,
    docType
  } = lastObject;
  const getRoute = (docType) => {
    const routesBytype = {
      Mrr: `/${"Document"}/${Page}/Material-Received-Report/${order}`,
      GatePass: `/${"Document"}/${Page}/Gate-Pass/${order}`
    };
    return routesBytype[docType];
  };
  return [
    code ?? customCode ?? invoiceNo,
    moment(docDate).format("DD-MM-YYYY"),
    getRoute(docType.name),
    docs?.length ? docs?.length - 1 : 0
  ];
};

const getColumns = () => {
  return [
    {
      Header: "Doc. No.",
      accessor: "docNo",
      Cell: ({ row, value }) => {
        return getCustomCell(
          value,
          row.original.orderDate,
          `${appRoutes.purchase.main}/${appRoutes.purchase.stockTransfer}/${row.original.ST_id}/manage-order`
        );
      },
      show: false
    },
    {
      Header: "Origin Warehouse",
      accessor: "originWarehouse",
      width: 180
    },
    {
      Header: "Status at Origin",
      accessor: "statusOrigin",
      width: 184,
      Cell: ({ row, value }) => {
        return <StyledChip label={stringCapitalization(value)} />;
      }
    },
    {
      Header: "Gate Pass",
      accessor: "gatePass",
      Cell: ({ value }) => {
        return value && getCustomCell(value[0], value[1], value[2], value[3]);
      }
    },
    {
      Header: "Receiving WH",
      accessor: "receivingWH",
      width: 154
    },
    {
      Header: "Status at Receiving",
      accessor: "statusReceiving",
      width: 184,
      Cell: ({ row, value }) => {
        return value && <StyledChip label={value} />;
      }
    },
    {
      Header: "MRR",
      accessor: "mrr",
      Cell: ({ value }) => {
        return value && getCustomCell(value[0], value[1], value[2], value[3]);
      }
    }
  ];
};

const getRows = (rows = []) => {
  return rows.map((row) => ({
    ST_id: row?._id,
    docNo: row?.code ?? "ST-Draft",
    orderDate: moment(row?.orderDate).format("DD-MM-YYYY"),
    originWarehouse: row?.clientData?.warehouse?.code,
    statusOrigin: row?.status?.name,
    gatePass:
      row?.documents?.length > 0
        ? getDocumentsByType(
            row?.documents?.filter(
              (obj) => obj?.docType?.name === "GatePass"
            ) ?? [],
            "Stock-Transfer"
          )
        : "",
    receivingWH: row?.vendorData?.warehouse?.code,
    statusReceiving: row?.toStatus?.name,
    mrr:
      row?.documents?.length > 0
        ? getDocumentsByType(
            row?.documents?.filter((obj) => obj?.docType?.name === "Mrr") ?? [],
            "Stock-Transfer"
          )
        : ""
  }));
};

const StockTransferOrders = () => {
  const navigate = useNavigate();

  const { ref, inView } = useInView();
  const { data, isFetched, hasNextPage, fetchNextPage } =
    useFetchStockTransfers();

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, inView]);

  const stockTransferDashboard = useMemo(
    () => (
      <CustomTable
        columns={getColumns()}
        data={
          isFetched
            ? data?.pages?.flatMap((page) => getRows(page?.stockTransfers)) ??
              []
            : []
        }
        isLoading={!isFetched}
        mobileComponent={MobileTransferOrders}
      />
    ),
    [data?.pages, isFetched]
  );

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      sx={{
        mb: { xs: 9, md: 0 }
      }}
    >
      <Box
        sx={{
          background: "#EEF1F4",
          display: { xs: "flex", md: "none" },
          minHeight: "80px",
          justifyContent: "flex-start",
          alignItems: "center",
          borderBottom: "1px solid #B7BEC7",
          boxShadow: "0px 8px 24px 0px #1515160A",
          pl: "30px",
          gap: 1
        }}
      >
        <Box>
          <IconButton
            onClick={() => {
              navigate(`${appRoutes.purchase.main}`);
            }}
            sx={{ marginRight: 1, marginLeft: -1 }}
          >
            <ArrowBack />
          </IconButton>
        </Box>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "19.36px",
            letterSpacing: "0.002em"
          }}
        >
          {"Stock Transfers"}
        </Typography>
      </Box>
      <Grid
        item
        xs={12}
        sx={{
          display: { xs: "flex", md: "none" },
          justifyContent: "right",
          alignItems: "center",
          pr: "40px"
        }}
      >
        <Button
          variant="link"
          disableRipple
          onClick={() =>
            navigate(
              `${appRoutes.purchase.main}/${appRoutes.purchase.stockTransfer}/${appRoutes.purchase.createOrder}`
            )
          }
          sx={{ fontWeight: 700 }}
        >
          {purchase_labels.createMobileTransferOrderButton}
        </Button>
      </Grid>
      <Grid container spacing={2} sx={{ display: { xs: "none", md: "block" } }}>
        <Grid item xs={12}>
          <IconButton
            onClick={() => {
              navigate(
                `${appRoutes.purchase.main}/${appRoutes.purchase.purchaseOrders}`
              );
            }}
            sx={{ marginRight: 1, marginLeft: -1 }}
          >
            <ArrowBack />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          container
          display={"flex"}
          justifyContent={"space-between"}
        >
          <Grid item>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 400,
                lineHeight: "20px",
                textAlign: "left"
              }}
            >
              {"STOCK TRANSFER ORDERS"}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="link"
              disableRipple
              onClick={() =>
                navigate(
                  `${appRoutes.purchase.main}/${appRoutes.purchase.stockTransfer}/${appRoutes.purchase.createOrder}`
                )
              }
            >
              {purchase_labels.createTransferOrderButton}
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ border: "0.7px solid #B7BEC7" }} />
        </Grid>
      </Grid>
      <Box sx={{ padding: { xs: "0 15px", md: 0 } }}>
        {stockTransferDashboard}
      </Box>
      {hasNextPage && (
        <Grid
          item
          xs={12}
          ref={ref}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress />
        </Grid>
      )}
    </Box>
  );
};

export default StockTransferOrders;
