import React, { useRef, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import ApparelImage1 from "../../assets/Clothing.webp";
import FoodImage1 from "../../assets/fmcg.webp";
import furnitureImage1 from "../../assets/furniture.webp";
import ApparelImage2 from "../../assets/Clothing-desktop.webp";
import FoodImage2 from "../../assets/fcmg-desktop.webp";
import furnitureImage2 from "../../assets/furniture-desktop.webp";
import IndustryCardItem from "./IndustryCardItem";
import { SectionHeader } from "./SolutionsSection";
import { home_labels } from "../../constants";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination } from "swiper/modules";
import { isMobile } from "react-device-detect";

const industries = [
  {
    label: home_labels["FMCG/F&B"],
    image1: FoodImage1,
    image2: FoodImage2,
    id: "food-and-fmcg"
  },
  {
    label: home_labels["apparel&TextilesText"],
    image1: ApparelImage1,
    image2: ApparelImage2,
    id: "apparel"
  },
  {
    label: home_labels.Furniture,
    image1: furnitureImage1,
    image2: furnitureImage2,
    id: "furniture"
  }
];

const IndustriesSection = () => {
  const swiperRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const targetElement = containerRef.current; // Store current value of containerRef

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && entry.intersectionRatio >= 0.4) {
            // When 40% or more of the swiper is visible, start autoplay
            if (swiperRef.current) swiperRef.current.autoplay.start();
          } else {
            // When less than 40% is visible, stop autoplay
            if (swiperRef.current) swiperRef.current.autoplay.stop();
          }
        });
      },
      {
        threshold: 0.4 // Trigger when 40% of the Swiper is in view
      }
    );

    if (targetElement) {
      observer.observe(targetElement);
    }

    return () => {
      if (targetElement) {
        observer.unobserve(targetElement); // Clean up the observer with the stored targetElement
      }
    };
  }, []);

  return (
    <Box
      id="industries"
      ref={containerRef}
      sx={{
        mt: {
          xs: "20px",
          md: "40px"
        },
        padding: {
          xs: "20px",
          md: "5px 65px"
        },
        gap: { xs: 1, md: 2 }
      }}
      textAlign={"center"}
    >
      <SectionHeader title={home_labels.industriesServedText} />
      {isMobile ? (
        <Box>
          <Swiper
            slidesPerView={isMobile ? 1 : 3}
            spaceBetween={30}
            speed={1000}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            }}
            loop={true}
            pagination={{
              clickable: true,
              
            }}
            modules={[Autoplay, Pagination]}
            className="mySwiper-3"
            onSwiper={(swiper) => (swiperRef.current = swiper)}
          >
            {industries.map((item, index) => (
              <SwiperSlide key={item.label}>
                <Grid
                  item
                  xs={12}
                  key={item.label}
                  style={{ marginRight: "16px" }}
                >
                  <IndustryCardItem {...item} />
                </Grid>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      ) : (
        <Grid container spacing={2}>
          {industries.map((item) => (
            <Grid item md={4} key={item.label}>
              <IndustryCardItem {...item} />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default IndustriesSection;
