import { queryKeys } from "../../../constants/queryKeys";
import { getStockAdjustmentById } from "../../../purchase/services";
import { useQuery } from "@tanstack/react-query";

export const useFetchStockAdjustmentById = (orderType, orderId) => {
  const purchaseOrderById = useQuery(
    [queryKeys.queryFetchStockAdjustmentById, orderId],
    () => getStockAdjustmentById(orderId),
    {
      enabled: Boolean(orderId) && Boolean(orderType === "stockAdjustments"),
      refetchOnWindowFocus: false
    }
  );

  return purchaseOrderById;
};
