import { updatePurchaseReturnDraft } from "../../../purchase/services";
import { useMutation } from "@tanstack/react-query";

export const useUpdatePurchaseReturnDraft = () => {
  const updatePurchaseReturnById = useMutation(
    ({ data, purchaseOrderId, purchaseReturnId }) =>
      updatePurchaseReturnDraft(data, purchaseOrderId, purchaseReturnId)
  );
  return updatePurchaseReturnById;
};
