import "./customTable.css";

import { Box, Checkbox, CircularProgress, Divider, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { StyledArrowDown, StyledArrowUp } from "../theme/globalTheme";
import {
  useExpanded,
  useFlexLayout,
  useRowSelect,
  useSortBy,
  useTable
} from "react-table";

import SearchIcon from "@mui/icons-material/Search";
import { TableFieldKeys } from "../../constants/formKeys";
import TextInput from "../formElements/TextInput";
import { useForm } from "react-hook-form";

const CustomTable = ({
  columns,
  data,
  renderRowSubComponent,
  searchPlaceholderText,
  mobileComponent: MobileComponent,
  isLoading,
  onRowSelectionChange,
  onSetHiddenColumns
}) => {
  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    state: { expanded, selectedRowIds },
    setHiddenColumns
  } = useTable(
    {
      columns,
      data
    },
    useSortBy,
    useExpanded,
    useFlexLayout,
    useRowSelect
  );

  const { control } = useForm({
    defaultValues: { [TableFieldKeys.search]: "" }
  });

  useEffect(() => {
    if (onRowSelectionChange) onRowSelectionChange({ ...selectedRowIds });
  }, [onRowSelectionChange, selectedRowIds]);

  useEffect(() => {
    if (onSetHiddenColumns) setHiddenColumns(onSetHiddenColumns);
  }, [onSetHiddenColumns, setHiddenColumns]);

  return (
    <Box {...getTableProps()} className="custom-table">
      {searchPlaceholderText && (
        <Grid container justifyContent={"end"} mb={2}>
          <Grid item xs={12} lg={5}>
            <TextInput
              startIcon={
                <SearchIcon
                  sx={{ ml: 2, fontSize: 25, color: "text.disabled" }}
                />
              }
              control={control}
              name={TableFieldKeys.search}
              placeholder={searchPlaceholderText}
              sx={{ borderRadius: 54 }}
            />
          </Grid>
        </Grid>
      )}
      <Box
        className="custom-table-container"
        sx={{
          display: {
            xs: "none",
            lg: "block"
          }
        }}
      >
        <Box className="custom-table-head">
          {headerGroups.map((headerGroup) => (
            <Box
              key={headerGroup.id}
              {...headerGroup.getHeaderGroupProps()}
              className="custom-table-tr"
            >
              {headerGroup.headers.map((column) => (
                <Box
                  key={column.id}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="custom-table-th"
                >
                  {column.render("Header")}
                  <Box
                    component={"span"}
                    display="inline-flex"
                    flexDirection="column"
                    alignItems={"center"}
                    marginLeft={1}
                    sx={{ verticalAlign: "middle" }}
                  >
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <StyledArrowUp />
                      ) : (
                        <StyledArrowDown />
                      )
                    ) : (
                      column.id !== "expander" &&
                      column.canSort && (
                        <>
                          <StyledArrowUp />
                          <StyledArrowDown />
                        </>
                      )
                    )}
                  </Box>
                </Box>
              ))}
            </Box>
          ))}
        </Box>
        <Box className="custom-table-body">
          {isLoading && (
            <Box className={`custom-table-tr`}>
              <Box
                className="custom-table-td"
                colSpan={visibleColumns.length}
                display={"flex"}
                justifyContent={"center"}
              >
                <CircularProgress />
              </Box>
            </Box>
          )}
          {!isLoading && data.length < 1 && (
            <Box className={`custom-table-tr`}>
              <Box
                colSpan={visibleColumns.length}
                className="custom-table-td"
                justifyContent={"center"}
                display={"flex"}
              >
                No records found
              </Box>
            </Box>
          )}
          {!isLoading &&
            rows.map((row, i) => {
              prepareRow(row);
              return (
                <React.Fragment key={i}>
                  <Box
                    className={`custom-table-tr ${
                      expanded[i] ? "tr-expanded" : ""
                    } ${row.original.subRow ? "tr-subRow" : ""}`}
                    key={row.id}
                    {...row.getRowProps()}
                    sx={{
                      backgroundColor: i % 2 !== 0 ? "#f3f5f9" : "#fff"
                    }}
                  >
                    {row.cells.map((cell, index) => {
                      return (
                        <Box
                          key={`${cell.column.id}-${index}`}
                          {...cell.getCellProps()}
                          className="custom-table-td"
                        >
                          {cell.column.id === "selection" ? (
                            <Checkbox
                              {...row.getToggleRowSelectedProps()}
                              color="primary"
                            />
                          ) : (
                            // Render cell content
                            cell.render("Cell")
                          )}
                        </Box>
                      );
                    })}
                  </Box>
                  {row.original.subRow && (
                    <Box
                      className="custom-sub-row"
                      overflow={"hidden"}
                      sx={{
                        backgroundColor: i % 2 !== 0 ? "#f3f5f9" : "#fff"
                      }}
                    >
                      {row.original.subRow.map((sub, j) => {
                        return (
                          <Box
                            colSpan={visibleColumns.length}
                            overflow={"hidden"}
                          >
                            <Divider sx={{ mt: 1, borderColor: "#B7BEC7" }} />
                            <Box
                              className="custom-table-tr"
                              {...row.getRowProps()}
                            >
                              {row.cells.map((cell, index) => {
                                return cell?.row?.original?.subRow[
                                  j
                                ]?.hasOwnProperty(cell?.column?.id) ? (
                                  <Box
                                    key={`${cell.column.id}${index}`}
                                    {...cell.getCellProps()}
                                    className="custom-table-td"
                                  >
                                    {
                                      cell?.row?.original?.subRow[j][
                                        cell?.column?.id
                                      ]
                                    }
                                  </Box>
                                ) : (
                                  <Box
                                    key={cell.column.id}
                                    {...cell.getCellProps()}
                                    className="custom-table-td"
                                  ></Box>
                                );
                              })}
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  )}
                  {renderRowSubComponent && row.isExpanded ? (
                    <Box className="custom-expanded-row">
                      <Box colSpan={visibleColumns.length}>
                        {renderRowSubComponent({ row })}
                      </Box>
                    </Box>
                  ) : null}
                </React.Fragment>
              );
            })}
        </Box>
      </Box>
      <Box
        className="custom-mobile-table"
        sx={{
          display: {
            xs: "flex",
            lg: "none"
          },
          flexDirection: "column",
          gap: "10px"
        }}
      >
        {isLoading && (
          <Box display={"flex"} justifyContent={"center"}>
            <CircularProgress />
          </Box>
        )}
        {!isLoading &&
          MobileComponent &&
          data?.map((item, index) => (
            <Box
              key={index}
              sx={{
                border: "0.4px solid #B7BEC7",
                boxShadow: "0px 8px 24px rgba(21, 21, 22, 0.1)",
                borderRadius: "8px",
                padding: "20px 16px",
                position: "relative"
              }}
            >
              <MobileComponent {...item} />
            </Box>
          ))}
        {!isLoading && data.length < 1 && (
          <Box justifyContent={"center"} display={"flex"}>
            No records found
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CustomTable;
