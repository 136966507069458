import { Box, Typography } from "@mui/material";
import {
  StorageKeys,
  searchFormType as formTypes
} from "../../constants/storage-keys";
import { useContext, useEffect, useState } from "react";

import { AppContext } from "../../shared/context/auth-context";
import Storage from "../../shared/utils/Storage";
import { StyledHomButton } from "./LandingBanner";
import { cartServiceKeys } from "../../constants/formKeys";
import { useAuth } from "../../shared/hooks/useAuth";
import { useNavigate } from "react-router-dom";

const HomeButtons = (props) => {
  const {
    ButtonText1,
    ButtonText2,
    ButtonSubText1,
    ButtonSubText2,
    isLanding
  } = props;
  const searchFormTypeLocal = Storage.getItem(StorageKeys.SEARCH_FORM_TYPE);

  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { onRemoveFromCart } = useContext(AppContext);
  const [searchFormType, setSearchFormType] = useState(
    searchFormTypeLocal ?? ""
  );

  useEffect(() => {
    Storage.setItem(StorageKeys.SEARCH_FORM_TYPE, searchFormType);
    if (currentUser && searchFormTypeLocal !== searchFormType) {
      Storage.removeItem(StorageKeys.SEARCH_FORM_DATA);
      Storage.removeItem(StorageKeys.SEARCH_FORM_PARAMS);
      onRemoveFromCart(cartServiceKeys.warehouse);
      onRemoveFromCart(cartServiceKeys.transport);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFormType, currentUser]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: 1
      }}
    >
      <Box
        sx={{
          opacity: 1,
          background: "#2773FF",
          borderRadius: 1,
          overflow: "hidden"
        }}
      >
        <StyledHomButton
          bgColor="#F9A90E"
          isLanding={isLanding}
          onClick={() => {
            setSearchFormType(formTypes.findByWarehouseForm);
            navigate("/explore");
          }}
        >
          {ButtonText1}
        </StyledHomButton>
        <StyledHomButton
          bgColor="#1E65EA"
          isLanding={isLanding}
          onClick={() => {
            setSearchFormType(formTypes.findByTransportForm);
            navigate("/explore");
          }}
        >
          {ButtonText2}
        </StyledHomButton>
      </Box>
      <Box display={"flex"} padding={"0px 60px !important"} gap={8}>
        <Typography
          sx={{
            fontSize: {
              xs: 14,
              md: 16
            },
            lineHeight: {
              xs: "21px",
              md: "24px"
            },
            letterSpacing: "-0.02em"
          }}
          color={"text.disabled"}
        >
          {ButtonSubText1}
        </Typography>
        {ButtonSubText2 && (
          <Typography
            sx={{
              fontSize: {
                xs: 14,
                md: 16
              },
              lineHeight: {
                xs: "21px",
                md: "24px"
              },
              letterSpacing: "-0.02em"
            }}
            color={"text.disabled"}
          >
            {ButtonSubText2}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default HomeButtons;
