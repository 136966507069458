import React, { useEffect, useRef, useState } from "react";
import TextArea from "../../../shared/formElements/TextArea";
import { Box, Button, Grid, Typography } from "@mui/material";
import CustomTable from "../../../shared/customTable";
import { useFieldArray, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import {
  MobilePaymentTermsField,
  OtherDetailsComp,
  TotalBreakup,
  reportLabel1,
  reportLabel2,
  shipToAddressData,
  vendorAddressData
} from "../../../purchase/pages/CreateOrder";
import { purchase_labels } from "../../../constants";
import OrderReport from "../../../purchase/components/OrderReport";
import { gstColumn } from "../../../purchase/components/Purchase/PurchaseOrderTable";
import { enqueueSnackbar } from "notistack";
import SuccessfulDialog from "../../../shared/UiElements/SuccessfulDialog";
import { useCustomDialog } from "../../../shared/customDialog";
import { currencyOnlyFormatter, dropdownOptions } from "../../../shared/utils";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { ToWords } from "to-words";
import { useCreateCreditNote } from "../../../query-hooks/ClientOrderCreditNote/useCreateCreditNote";
import { useUploadCreditNote } from "../../../query-hooks/ClientOrderCreditNote/useUploadCreditNote";
import { useSaveCreditNote } from "../../../query-hooks/ClientOrderCreditNote/useSaveCreditNote";
import { useUpdateCreditNoteDraft } from "../../../query-hooks/ClientOrderCreditNote/useUpdateCreditNoteDraft";
import { useSubmitCreditNoteDraft } from "../../../query-hooks/ClientOrderCreditNote/useSubmitCreditNoteDraft";
import { useDeleteCreditNoteDraft } from "../../../query-hooks/ClientOrderCreditNote/useDeleteCreditNoteDraft";
import {
  InvoiceButtonGroup,
  InvoiceSchema,
  PaymentTermsFieldRow
} from "../GatePass/GPInvoiceTable";
import PrintableTable from "../../../purchase/components/PrintableTable";
import { useReactToPrint } from "react-to-print";
import { isMobileDevice } from "../../../App";
import MobileDCTable from "../GatePass/MobileDCTable";
import MobileOrderReport from "../../../purchase/components/MobileOrderReport";
import ManualInvoiceUpload from "../../../finance/components/ManualInvoiceUpload";

const getColumns = (gstType) => {
  return [
    {
      Header: "S.No",
      accessor: "sNo",
      width: 90,
      size: 0.5
    },
    {
      Header: "Item/ SKU",
      accessor: "item",
      width: 200,
      size: 2,
      Cell: ({ row, value }) => {
        return row.original?.skuCode ? (
          <Box display={"flex"} flexDirection={"column"} gap={1}>
            <Box display={"flex"} gap={1} alignItems={"center"}>
              {row.original?.image?.thumbUrl && (
                <img
                  src={row.original?.image?.thumbUrl}
                  alt={row.original?.brandName ?? "--"}
                  height={52}
                  width={50}
                  style={{ objectFit: "contain" }}
                />
              )}
              <Typography
                fontSize={12}
                fontWeight={600}
                color={"primary"}
                component={Link}
              >
                {row.original?.skuCode}
              </Typography>
            </Box>
            <Typography fontSize={13} color={"text.secondary"}>
              {`HSN : ${row.original?.hsn ?? "--"}`}
            </Typography>
          </Box>
        ) : (
          value
        );
      }
    },
    {
      Header: "Product",
      accessor: "product",
      width: 180,
      size: 1.5,
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          row.original?.productName && (
            <Box display={"flex"} flexDirection={"column"} gap={0.5}>
              <Typography fontSize={14}>{`Product: ${
                row.original?.productName ?? "--"
              }`}</Typography>
              <Typography fontSize={13} color={"text.secondary"}>{`Category: ${
                row.original?.categoryName ?? "--"
              }`}</Typography>
              <Typography fontSize={13} color={"text.secondary"}>{`Brand: ${
                row.original?.brandName ?? "--"
              }`}</Typography>
            </Box>
          )
        );
      }
    },
    {
      Header: "UOM",
      accessor: "uom",
      disableSortBy: true,
      size: 0.5,
      Cell: ({ row }) => {
        return (
          row.original?.unit && (
            <Typography>{row.original?.unit?.name}</Typography>
          )
        );
      }
    },
    {
      Header: "Price",
      accessor: "rate",
      size: 1,
      disableSortBy: true
    },
    {
      Header: "Quantity",
      accessor: "quantity",
      size: 1,
      disableSortBy: true
    },
    {
      Header: "Amount",
      accessor: "amount",
      size: 1.5,
      disableSortBy: true
    },
    ...[
      ...(gstType
        ? [
            {
              Header: "CGST",
              accessor: "cgst",
              disableSortBy: true,
              size: 1,
              Cell: ({ row }) => {
                return gstColumn(
                  row.original.cgst ?? 0,
                  row.original?.gstRegime ? row.original?.gstRegime / 2 : 0
                );
              }
            },
            {
              Header: "SGST",
              accessor: "sgst",
              disableSortBy: true,
              size: 1,
              Cell: ({ row }) => {
                return gstColumn(
                  row.original.sgst,
                  row.original?.gstRegime ? row.original?.gstRegime / 2 : 0
                );
              }
            }
          ]
        : [
            {
              Header: "IGST",
              accessor: "igst",
              disableSortBy: true,
              size: 1,
              Cell: ({ row }) => {
                return gstColumn(
                  row.original.igst ?? 0,
                  row.original?.gstRegime ? row.original?.gstRegime : 0
                );
              }
            }
          ])
    ],
    {
      Header: "Total",
      accessor: "total",
      size: 2,
      disableSortBy: true
    }
  ];
};

const getRows = (rows = [], gstType) => {
  return rows.map((row, index) => ({
    sNo: index + 1,
    ...row,
    gstType: gstType
  }));
};

const MrrCNTable = ({
  MrrId,
  cnId,
  orderId,
  InvoiceStatus,
  orderData,
  tableData,
  advOptions,
  dueOptions,
  InvCreateAllowed,
  refectDocs
}) => {
  const [showManual, setShowManual] = useState(!Boolean(cnId));
  const toWords = new ToWords();

  const {
    control,
    setValue,
    watch,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(InvoiceSchema(showManual))
  });

  const { showDialog, hideDialog } = useCustomDialog();

  const { fields, append } = useFieldArray({
    control,
    name: "invoices"
  });

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: tableData?.code
  });

  const paymentTerms = watch(["advance", "due"]);

  useEffect(() => {
    if (Boolean(tableData)) {
      setValue("advance", tableData?.advancePercent);
      setValue("due", tableData?.dueDays);
      tableData?.items?.forEach((item, index) => {
        append({
          rate: item.rate,
          quantity: item.quantity,
          skuCode: item.skuData.skuCode,
          productName: item?.skuData?.productName,
          brandName: item?.skuData?.brandName,
          categoryName: item?.skuData?.categoryName,
          unit: item?.skuData.unit,
          gstRegime: item?.taxes[0]?.totalAmount
            ? item?.skuData?.gstRegime ?? 0
            : 0,
          hsn: item?.skuData?.hsn,
          image: item?.skuData?.image,
          amount: currencyOnlyFormatter(
            parseFloat(item?.subTotalAmount).toFixed(2)
          ),
          cgst: currencyOnlyFormatter(
            parseFloat(item?.taxes[0]?.totalAmount ?? 0).toFixed(2)
          ),
          sgst: currencyOnlyFormatter(
            parseFloat(item?.taxes[1]?.totalAmount ?? 0).toFixed(2)
          ),
          igst: currencyOnlyFormatter(
            parseFloat(item?.taxes[0]?.totalAmount ?? 0).toFixed(2)
          ),
          total: currencyOnlyFormatter(parseFloat(item?.totalAmount).toFixed(2))
        });
      });
    } // eslint-disable-next-line
  }, [JSON.stringify(tableData)]);

  const {
    mutate: createCreditNote,
    isError: isCreateDNError,
    error: createDNError
  } = useCreateCreditNote();

  const {
    mutate: uploadCreditNote,
    isError: isUploadDNError,
    error: uploadDNError
  } = useUploadCreditNote();

  const {
    mutate: saveCreditNoteDraft,
    isError: isSaveDNDraftError,
    error: saveDNDraftError
  } = useSaveCreditNote();

  const {
    mutate: updateCreditNoteDraft,
    isError: isUpdateDNDraftError,
    error: updateDNDraftError
  } = useUpdateCreditNoteDraft();

  const {
    mutate: submitCreditNoteDraft,
    isError: isSubmitDNDraftError,
    error: submitDNDraftError
  } = useSubmitCreditNoteDraft();

  const {
    mutate: deleteCreditNoteDraft,
    isError: isDeleteDNDraftError,
    error: deleteDNDraftError
  } = useDeleteCreditNoteDraft();

  const onSaveDraft = (data) => {
    const dcData = {
      advancePercent: data["advance"],
      dueDays: data["due"]
    };
    cnId
      ? updateCreditNoteDraft(
          {
            data: { ...dcData },
            cnId: cnId
          },
          {
            onSuccess: (res) => {
              hideDialog();
              enqueueSnackbar("Credit Note Draft Updated!", {
                variant: "success"
              });
              reset();
              refectDocs();
            }
          }
        )
      : saveCreditNoteDraft(
          {
            data: { ...dcData },
            mrrId: MrrId
          },
          {
            onSuccess: (res) => {
              enqueueSnackbar("Credit Note Draft Saved!", {
                variant: "success"
              });
              reset();
              refectDocs();
            }
          }
        );
  };

  const submitData = (data) => {
    if (showManual) {
      const InvData = new FormData();
      InvData.append("advancePercent", data["advance"]);
      InvData.append("dueDays", data["due"]);
      InvData.append("customCode", data["invoiceId"]);
      InvData.append(
        "docDate",
        moment(data["invoiceDate"]).format("YYYY-MM-DD")
      );
      InvData.append("totalAmount", data["invoiceAmount"]);
      InvData.append("file", data["manualInvoice"]);
      uploadCreditNote(
        {
          data: InvData,
          mrrId: MrrId
        },
        {
          onSuccess: (res) => {
            hideDialog();
            enqueueSnackbar("Credit Note Created!", {
              variant: "success"
            });
            reset();
            refectDocs();
          }
        }
      );
    } else {
      const dcData = {
        advancePercent: data["advance"],
        dueDays: data["due"]
      };
      cnId
        ? submitCreditNoteDraft(
            {
              data: { ...dcData },
              cnId: cnId
            },
            {
              onSuccess: (res) => {
                hideDialog();
                enqueueSnackbar("Credit Note Created!", {
                  variant: "success"
                });
                reset();
                refectDocs();
              }
            }
          )
        : createCreditNote(
            {
              data: { ...dcData },
              mrrId: MrrId
            },
            {
              onSuccess: (res) => {
                hideDialog();
                enqueueSnackbar("Credit Note Created!", {
                  variant: "success"
                });
                reset();
                refectDocs();
              }
            }
          );
    }
  };

  const onSubmitClick = (data) => {
    showDialog({
      component: (
        <SuccessfulDialog
          text={"Are you sure you want to submit the document ?"}
          subText={"Submitted documents cannot be edited. "}
          btn1Text={"Go to Draft"}
          btn2Text={"Submit"}
          btn1Callback={() => hideDialog()}
          btn2Callback={() => submitData(data)}
        />
      )
    });
  };

  const deleteDraft = () => {
    deleteCreditNoteDraft(
      {
        cnId: cnId
      },
      {
        onSuccess: (res) => {
          hideDialog();
          enqueueSnackbar("Draft Deleted!", {
            variant: "success"
          });
          reset();
          refectDocs();
        }
      }
    );
  };

  const onDeleteClick = () => {
    showDialog({
      component: (
        <SuccessfulDialog
          text={"Are you sure you want to Delete this draft ?"}
          subText={"Deleted documents cannot be recovered. "}
          btn1Text={"Go to Draft"}
          btn2Text={"Delete"}
          btn1Callback={() => hideDialog()}
          btn2Callback={() => deleteDraft()}
        />
      )
    });
  };

  useEffect(() => {
    if (isCreateDNError) {
      enqueueSnackbar(createDNError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isUploadDNError) {
      enqueueSnackbar(uploadDNError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isSaveDNDraftError) {
      enqueueSnackbar(saveDNDraftError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isUpdateDNDraftError) {
      enqueueSnackbar(updateDNDraftError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isSubmitDNDraftError) {
      enqueueSnackbar(submitDNDraftError?.response?.data?.message, {
        variant: "error"
      });
    }
    if (isDeleteDNDraftError) {
      enqueueSnackbar(deleteDNDraftError?.response?.data?.message, {
        variant: "error"
      });
    }
  }, [
    createDNError?.response?.data?.message,
    uploadDNError?.response?.data?.message,
    saveDNDraftError?.response?.data?.message,
    updateDNDraftError?.response?.data?.message,
    submitDNDraftError?.response?.data?.message,
    deleteDNDraftError?.response?.data?.message,
    isSaveDNDraftError,
    isCreateDNError,
    isUploadDNError,
    isUpdateDNDraftError,
    isSubmitDNDraftError,
    isDeleteDNDraftError
  ]);

  const otherDetails = [
    {
      label: `Payment Terms :`,
      value:
        paymentTerms[0] !== "" &&
        paymentTerms[0] !== undefined &&
        paymentTerms[1]
          ? `${paymentTerms[0]}% Advance. Balance Due ${paymentTerms[1]} days from receipt`
          : "--"
    }
  ];

  const otherDetailsComp = (
    <Grid container rowSpacing={2}>
      {otherDetails?.map((item, index) => (
        <OtherDetailsComp key={index} label={item.label} value={item.value} />
      ))}
    </Grid>
  );

  const CreditNoteDoc = ({ children }) => {
    return (
      <>
        <Box
          sx={{ display: { xs: "none", md: "flex" } }}
          flexDirection={"column"}
          gap={1}
        >
          <OrderReport
            title={"Credit Note"}
            buyerTitle={"Buyer"}
            shippingTitle={"Shipto"}
            sellerTitle={"Seller"}
            orderInfo={{
              creditNoteNo: InvoiceStatus
                ? InvoiceStatus === "Draft"
                  ? "Draft"
                  : tableData?.code
                : "--",
              cnDate: InvoiceStatus
                ? moment(tableData?.docDate).format("DD-MM-YYYY")
                : "--"
            }}
            sellerInfo={vendorAddressData(orderData?.clientData)}
            buyerInfo={vendorAddressData(orderData?.vendorData)}
            shipInfo={shipToAddressData(orderData?.vendorData?.warehouse)}
          >
            {children}
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <Box display={"flex"} flexDirection={"column"} gap={3}>
                  <Box display={"flex"} gap={5}>
                    {reportLabel1(
                      purchase_labels.paymentTermsText,
                      paymentTerms[0] !== "" &&
                        paymentTerms[0] !== undefined &&
                        paymentTerms[1]
                        ? `${paymentTerms[0]}% Advance. Balance Due ${paymentTerms[1]} days from receipt`
                        : "--"
                    )}
                  </Box>
                  <Box width={"500px"}>
                    <TextArea
                      control={control}
                      name={"otherItemsAndConditionsLabel"}
                      label={purchase_labels.otherItemsAndConditionsLabel}
                      disabled
                    />
                  </Box>
                  <Box display={"flex"} flexDirection={"column"} gap={2.5}>
                    {reportLabel2(
                      purchase_labels.amountInWordsLabel,
                      `${toWords.convert(tableData?.totalAmount ?? 0, {
                        currency: true
                      })}`
                    )}
                    {reportLabel2(
                      purchase_labels.preparedByLabel,
                      InvoiceStatus
                        ? `${tableData?.preparedBy?.firstName} ${tableData?.preparedBy?.lastName}`
                        : "--"
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <TotalBreakup
                  gstType={
                    Boolean(tableData?.taxes?.length)
                      ? tableData?.taxes?.length > 1
                      : false
                  }
                  subTotal={tableData?.subTotalAmount ?? 0}
                  cgst={tableData?.taxes ? tableData?.taxes[0]?.totalAmount : 0}
                  sgst={tableData?.taxes ? tableData?.taxes[1]?.totalAmount : 0}
                  igst={tableData?.taxes ? tableData?.taxes[0]?.totalAmount : 0}
                  total={tableData?.totalAmount ?? 0}
                />
              </Grid>
            </Grid>
          </OrderReport>
        </Box>
        <Box
          sx={{ display: { xs: "flex", md: "none" } }}
          flexDirection={"column"}
          gap={1}
        >
          <MobileOrderReport
            otherInfo={otherDetailsComp}
            mobileTable={children}
            remarksComp={
              <Box
                display={"flex"}
                minWidth={"289px"}
                minHeight={"90px"}
                border={"1px solid #B7BEC7"}
                borderRadius={"8px"}
                padding={"10px"}
              ></Box>
            }
            Total={[
              {
                label: "Subtotal Amount :",
                value: tableData?.subTotalAmount
                  ? currencyOnlyFormatter(
                      parseFloat(tableData?.subTotalAmount).toFixed(2)
                    )
                  : "--"
              },
              ...[
                ...(Boolean(
                  Boolean(tableData?.taxes?.length)
                    ? tableData?.taxes?.length > 1
                    : false
                )
                  ? [
                      {
                        label: "CGST :",
                        value: tableData?.taxes[0]?.totalAmount
                          ? currencyOnlyFormatter(
                              parseFloat(
                                tableData?.taxes[0]?.totalAmount
                              ).toFixed(2)
                            )
                          : "--"
                      },
                      {
                        label: "SGST :",
                        value: tableData?.taxes[1]?.totalAmount
                          ? currencyOnlyFormatter(
                              parseFloat(
                                tableData?.taxes[1]?.totalAmount
                              ).toFixed(2)
                            )
                          : "--"
                      }
                    ]
                  : [
                      {
                        label: "IGST :",
                        value: tableData?.taxes[0]?.totalAmount
                          ? currencyOnlyFormatter(
                              parseFloat(
                                tableData?.taxes[0]?.totalAmount
                              ).toFixed(2)
                            )
                          : "--"
                      }
                    ])
              ],
              {
                label: "Total Amount :",
                value: tableData?.totalAmount
                  ? currencyOnlyFormatter(
                      parseFloat(tableData?.totalAmount).toFixed(2)
                    )
                  : "--"
              }
            ]}
            ButtonGroup={
              !showManual &&
              (!Boolean(InvoiceStatus) || InvoiceStatus === "Draft") && (
                <InvoiceButtonGroup
                  InvoiceStatus={InvoiceStatus}
                  onDeleteClick={onDeleteClick}
                  onSaveDraft={handleSubmit(onSaveDraft)}
                  onSubmitClick={handleSubmit(onSubmitClick)}
                  submitButtonText={"Submit CN"}
                />
              )
            }
            preparedBy={
              InvoiceStatus
                ? `${tableData?.preparedBy?.firstName} ${tableData?.preparedBy?.lastName}`
                : "--"
            }
            title={"Credit Note"}
            buyerTitle={"Buyer"}
            shippingTitle={"Shipto"}
            sellerTitle={"Seller"}
            orderInfo={{
              creditNoteNo: InvoiceStatus
                ? InvoiceStatus === "Draft"
                  ? "Draft"
                  : tableData?.code
                : "--",
              cnDate: InvoiceStatus
                ? moment(tableData?.docDate).format("DD-MM-YYYY")
                : "--"
            }}
            sellerInfo={vendorAddressData(orderData?.clientData)}
            buyerInfo={vendorAddressData(orderData?.vendorData)}
            shipInfo={shipToAddressData(orderData?.vendorData?.warehouse)}
          />
        </Box>
        <div style={{ display: "none" }}>
          <Box ref={componentRef} sx={{ padding: "10px" }}>
            <OrderReport
              title={"Credit Note"}
              buyerTitle={"Buyer"}
              shippingTitle={"Shipto"}
              sellerTitle={"Seller"}
              orderInfo={{
                creditNoteNo: InvoiceStatus
                  ? InvoiceStatus === "Draft"
                    ? "Draft"
                    : tableData?.code
                  : "--",
                cnDate: InvoiceStatus
                  ? moment(tableData?.docDate).format("DD-MM-YYYY")
                  : "--"
              }}
              sellerInfo={vendorAddressData(orderData?.clientData)}
              buyerInfo={vendorAddressData(orderData?.vendorData)}
              shipInfo={shipToAddressData(orderData?.vendorData?.warehouse)}
            >
              <Grid container>
                <PrintableTable
                  columns={getColumns(
                    Boolean(tableData?.taxes?.length)
                      ? tableData?.taxes?.length > 1
                      : false
                  )}
                  rows={getRows(fields)}
                  docType="DeliveryChallan"
                  gstType={
                    Boolean(tableData?.taxes?.length)
                      ? tableData?.taxes?.length > 1
                      : false
                  }
                />
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={9}>
                  <Box display={"flex"} flexDirection={"column"} gap={3}>
                    <Box display={"flex"} gap={5}>
                      {reportLabel1(
                        purchase_labels.paymentTermsText,
                        paymentTerms[0] !== "" &&
                          paymentTerms[0] !== undefined &&
                          paymentTerms[1]
                          ? `${paymentTerms[0]}% Advance. Balance Due ${paymentTerms[1]} days from receipt`
                          : "--"
                      )}
                    </Box>
                    <Box width={"500px"}>
                      <TextArea
                        control={control}
                        name={"otherItemsAndConditionsLabel"}
                        label={purchase_labels.otherItemsAndConditionsLabel}
                        disabled
                      />
                    </Box>
                    <Box display={"flex"} flexDirection={"column"} gap={2.5}>
                      {reportLabel2(
                        purchase_labels.amountInWordsLabel,
                        `${toWords.convert(tableData?.totalAmount ?? 0, {
                          currency: true
                        })}`
                      )}
                      {reportLabel2(
                        purchase_labels.preparedByLabel,
                        InvoiceStatus
                          ? `${tableData?.preparedBy?.firstName} ${tableData?.preparedBy?.lastName}`
                          : "--"
                      )}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <TotalBreakup
                    gstType={
                      Boolean(tableData?.taxes?.length)
                        ? tableData?.taxes?.length > 1
                        : false
                    }
                    subTotal={tableData?.subTotalAmount ?? 0}
                    cgst={
                      tableData?.taxes ? tableData?.taxes[0]?.totalAmount : 0
                    }
                    sgst={
                      tableData?.taxes ? tableData?.taxes[1]?.totalAmount : 0
                    }
                    igst={
                      tableData?.taxes ? tableData?.taxes[0]?.totalAmount : 0
                    }
                    total={tableData?.totalAmount ?? 0}
                  />
                </Grid>
              </Grid>
            </OrderReport>
          </Box>
        </div>
      </>
    );
  };

  return (
    <Box display={"flex"} flexDirection={"column"} gap={1}>
      {InvCreateAllowed && (
        <Box display={"flex"}>
          {(!Boolean(InvoiceStatus) || InvoiceStatus === "Draft") && (
            <>
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                <PaymentTermsFieldRow
                  label={"Payment Terms"}
                  control={control}
                  disabled={false}
                  advanceOptions={advOptions}
                  dueOptions={dueOptions}
                />
              </Box>
              <Box sx={{ display: { xs: "flex", md: "none" } }} pl={2}>
                <MobilePaymentTermsField
                  label={"Payment Terms"}
                  control={control}
                  disabled={false}
                  advanceOptions={dropdownOptions(advOptions)}
                  dueOptions={dropdownOptions(dueOptions)}
                />
              </Box>
            </>
          )}
          {!showManual &&
            (!Boolean(InvoiceStatus) || InvoiceStatus === "Draft") && (
              <Box
                sx={{ display: { xs: "none", md: "flex" } }}
                justifyContent={"flex-end"}
                gap={1}
              >
                {InvoiceStatus === "Draft" && (
                  <Button
                    variant="link"
                    disableRipple
                    onClick={() => onDeleteClick()}
                    sx={{ color: "error.main", width: "110px" }}
                  >
                    Delete Draft
                  </Button>
                )}
                <Button
                  variant="link"
                  disableRipple
                  onClick={handleSubmit(onSaveDraft)}
                  sx={{ width: "90px" }}
                >
                  Save Draft
                </Button>
                <Button
                  variant="link"
                  disableRipple
                  onClick={handleSubmit(onSubmitClick)}
                  sx={{ width: "110px" }}
                >
                  Submit CN
                </Button>
              </Box>
            )}
        </Box>
      )}
      {!showManual && InvoiceStatus === "Placed" && !isMobileDevice && (
        <Box display={"flex"} justifyContent={"flex-end"} mt={-5}>
          <Button variant="link" disableRipple onClick={handlePrint}>
            Print
          </Button>
        </Box>
      )}
      {showManual ? (
        <Box
          boxShadow={"0px 8px 24px 0px #1515161A"}
          sx={{
            borderRadius: { xs: "8px", md: "unset" }
          }}
        >
          <ManualInvoiceUpload
            control={control}
            setValue={setValue}
            setShowManual={setShowManual}
            onSubmit={handleSubmit(onSubmitClick)}
            errors={errors}
            Title={"Credit Note"}
          />
        </Box>
      ) : (
        <>
          <CreditNoteDoc>
            <CustomTable
              columns={getColumns(
                Boolean(tableData?.taxes?.length)
                  ? tableData?.taxes?.length > 1
                  : false
              )}
              data={getRows(
                fields,
                Boolean(
                  Boolean(tableData?.taxes?.length)
                    ? tableData?.taxes?.length > 1
                    : false
                )
              )}
              mobileComponent={MobileDCTable}
            />
          </CreditNoteDoc>
        </>
      )}
    </Box>
  );
};

export default MrrCNTable;
