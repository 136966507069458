import { getSOFilters } from "../../../sales/services";
import { queryKeys } from "../../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchSOFilters = () => {
  const querySOFilters = useQuery(
    [queryKeys.queryFetchSOFilters],
    () => getSOFilters(),
    {
      refetchOnWindowFocus: false
    }
  );

  return querySOFilters;
};
