import { getCustomerBillingByCustomerId } from "../../../sales/services";
import { queryKeys } from "../../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchCustomerBillingByCustomerId = (customerId) => {
  const querySalesOrderFormData = useQuery(
    [queryKeys.queryFetchCustomerBillingByCustomerId,customerId],
    () => getCustomerBillingByCustomerId(customerId),
    {
      enabled: Boolean(customerId),
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return querySalesOrderFormData;
};
