import { getInvoiceFilters } from "../../finance/services";
import { queryKeys } from "../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchInvoiceFilters = (orderType) => {
  const queryInvoiceFilters = useQuery(
    [queryKeys.queryFetchInvoiceFilters, orderType],
    () => getInvoiceFilters(orderType),
    {
      refetchOnWindowFocus: false
    }
  );

  return queryInvoiceFilters;
};
