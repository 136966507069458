import * as yup from "yup";

import { Box, Button, Typography } from "@mui/material";

import { LoadingButton } from "@mui/lab";
import RadioButtonGroup from "../formElements/RadioButtonGroup";
import TextInput from "../formElements/TextInput";
import { labels } from "../../constants";
import { regretFormKeys } from "../../constants/formKeys";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const regretReasonSchema = yup
  .object({
    regretReason: yup.string().required("Please select a regret reason"),
    otherReason: yup.string().when("regretReason", (regretReason, schema) => {
      return regretReason[0] === "Other"
        ? schema.required("Please enter other regret reason")
        : schema.notRequired();
    })
  })
  .required();

const RegretForm = ({
  title,
  subText,
  btn1Text,
  btn2Text,
  btn1Callback,
  btn2Callback,
  reasons,
  isBtn1Loading = false
}) => {
  const { control, watch, handleSubmit } = useForm({
    resolver: yupResolver(regretReasonSchema)
  });
  const reason = watch(regretFormKeys.regretReason);

  const handleBtn1Callback = (data) => {
    btn1Callback(data);
  };

  return (
    <Box padding={"32px 24px"}>
      <Typography
        fontSize={20}
        fontWeight={500}
        letterSpacing={"0.002em"}
        lineHeight={"24px"}
        marginBottom={"20px"}
      >
        {title}
      </Typography>
      <Typography
        fontSize={14}
        lineHeight={"20px"}
        color={"text.secondary"}
        marginBottom={5}
      >
        {subText}
      </Typography>
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={"4px"}
        marginBottom={5}
      >
        <RadioButtonGroup
          control={control}
          name={regretFormKeys.regretReason}
          options={
            reasons
              ? reasons?.map((item) => {
                  return {
                    label: item.displayName,
                    value: item.name
                  };
                })
              : []
          }
          flexDirection="column"
        />
        {reason === "Other" && (
          <Box marginLeft={4}>
            <TextInput
              control={control}
              name={regretFormKeys.otherReason}
              placeholder={labels.otherReasonPlaceholderText}
            />
          </Box>
        )}
      </Box>
      <Box display={"flex"} flexDirection={"column"} gap={1}>
        <Button
          component={LoadingButton}
          loading={isBtn1Loading}
          variant="contained"
          color="error"
          fullWidth
          sx={{ height: { xs: 48, lg: 64 }, fontWeight: 600, fontSize: 15 }}
          onClick={handleSubmit(handleBtn1Callback)}
        >
          {btn1Text}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          sx={{ height: { xs: 48, lg: 64 }, fontWeight: 600, fontSize: 15 }}
          onClick={btn2Callback}
        >
          {btn2Text}
        </Button>
      </Box>
    </Box>
  );
};

export default RegretForm;
