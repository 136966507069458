import { queryKeys } from "../../../constants/queryKeys";
import { getSRById } from "../../../sales/services";
import { useQuery } from "@tanstack/react-query";

export const useFetchSalesReturnOrderById = (
  orderId,
  returnId,
  manageOrderType
) => {
  const returnById = useQuery(
    [queryKeys.queryFetchSalesReturnById, returnId],
    () => getSRById(orderId, returnId),
    {
      enabled: manageOrderType && Boolean(orderId) && Boolean(returnId),
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return returnById;
};
