import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import { StyledChip } from "../../shared/UiElements/StatusChip";
import { BorderColorOutlined, CloseRounded } from "@mui/icons-material";

export const MobileSubUsersCard = (row) => {
  const { handleEdit, onDeleteClick } = row;
  const Modules = ["Inventory", "Warehouse", "Purchase", "Sale", "Finance"];

  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={6} display={"flex"} justifyContent={"left"}>
        <Typography
          sx={{
            fontSize: 14,
            lineHeight: "18px",
            fontWeight: 500
          }}
        >
          {row.userName}
        </Typography>
      </Grid>
      <Grid item xs={6} display={"flex"} justifyContent={"right"}>
        <Typography
          sx={{
            fontSize: 13,
            lineHeight: "15.73px",
            fontWeight: 400,
            color: "#2773FF"
          }}
        >
          {row.mobileNo}
        </Typography>
      </Grid>
      <Grid item xs={12} container rowSpacing={1}>
        {Modules.map((module, index) => (
          <Grid
            item
            xs={4}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography
              fontSize={12}
              lineHeight={"16px"}
              fontWeight={row?.userAccess.includes(module) ? 700 : 400}
              color={row?.userAccess.includes(module) ? "#F9A90E" : "#95A1B0"}
            >
              {module}
            </Typography>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      <Grid item xs={6} display={"flex"} justifyContent={"left"}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={0.5}
          alignItems={"flex-start"}
        >
          <StyledChip label={row.status} variant="text" />
          <Typography fontSize={12} lineHeight={"16px"} color={"text.disabled"}>
            Status
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs={6}
        display={"flex"}
        justifyContent={"right"}
        alignItems={"center"}
        gap={1}
      >
        <IconButton onClick={() => handleEdit(row)}>
          <BorderColorOutlined sx={{ fontSize: "20px", color: "#F9A90E" }} />
        </IconButton>
        <IconButton
          sx={{ background: "#FEEEEE", padding: "3px" }}
          onClick={() => onDeleteClick(row)}
        >
          <CloseRounded
            sx={{ fontSize: "18px", color: "#F05454", fontWeight: 700 }}
          />
        </IconButton>
      </Grid>
    </Grid>
  );
};
