import styled from "@emotion/styled";
import { Box, Button, Divider, Typography } from "@mui/material";
import { Assets_label } from "../../constants";
import AddWarehouse from "../../assets/icons/AddWarehouse.svg";
import { AddOutlined } from "@mui/icons-material";
import { useState } from "react";
import { useFetchAssetWarehouses } from "../../query-hooks/WarehouseAssets/useFetchAssetWarehouses";
import WarehouseCard from "../components/WarehouseAssets/WarehouseCard";
import AddWarehouses from "../components/WarehouseAssets/AddWarehouses";
import TrucksTable from "../components/WarehouseAssets/TrucksTable";

export const TitleText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: 20,
  fontFamily: "Inter",
  lineHeight: "24.2px",
  fontWeight: 500
}));

const Assets = () => {
  const [warehouseId, setWarehouseId] = useState(null);
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const { data, refetch } = useFetchAssetWarehouses();
  const arrayLength = data?.warehouses.length > 0;
  const [showAddWarehouse, setShowAddWarehouse] = useState(false);

  const handleAddWarehouse = () => {
    setShowAddWarehouse(true);
    setWarehouseId(null);
    setSelectedCardIndex(null);
  };

  const handleCardClick = (index) => {
    setSelectedCardIndex(index);
  };

  return (
    <Box display={"flex"} flexDirection={"column"} gap={4} padding={"40px"}>
      <Box display={"flex"} flexDirection={"column"} gap={2}>
        <Box
          display={"flex"}
          gap={2}
          justifyContent={"space-between"}
          alignItems={"flex-end"}
        >
          <Box
            display={"flex"}
            gap={2}
            justifyContent={"left"}
            alignItems={"flex-end"}
          >
            <img src={AddWarehouse} alt="Warehouse" />
            <TitleText>{Assets_label.addWarehouses}</TitleText>
          </Box>
          {arrayLength && (
            <Button
              variant="link"
              whiteSpace="nowrap"
              disableRipple
              onClick={handleAddWarehouse}
            >
              <AddOutlined /> {Assets_label.addNewWarehouse}
            </Button>
          )}
        </Box>
        {arrayLength && (
          <Box
            display={"flex"}
            border={"0.6px solid #027AFF"}
            borderRadius={"8px"}
            padding={"15px 25px"}
            gap={2}
            sx={{ overflowX: "auto" }}
          >
            {data?.warehouses?.map((item, index) => (
              <WarehouseCard
                key={index}
                index={index}
                code={`${item?.code}`}
                formId={item?._id}
                address={`${item?.address?.city}, ${item?.address?.state}`}
                selectedCardIndex={selectedCardIndex}
                handleCardClick={handleCardClick}
                setFormId={setWarehouseId}
                setShowForm={setShowAddWarehouse}
                sx={{
                  background:
                    selectedCardIndex === index ? "#607088 " : "#6097FF",
                  position: selectedCardIndex === index ? "relative" : "static",
                  top: selectedCardIndex === index ? -8 : 0
                }}
              />
            ))}
          </Box>
        )}
        {(!arrayLength || showAddWarehouse) && (
          <Box
            display={"flex"}
            border={"0.6px solid #027AFF"}
            borderRadius={"8px"}
          >
            <AddWarehouses
              warehouseId={warehouseId}
              setShowAddWarehouse={setShowAddWarehouse}
              handleCardClick={handleCardClick}
              refetch={refetch}
            />
          </Box>
        )}
      </Box>
      <Divider sx={{ border: "1px solid #027AFF" }} />
      <TrucksTable />
    </Box>
  );
};

export default Assets;
