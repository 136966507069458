import { getActiveInquiry } from "../../customer/services";
import { queryKeys } from "../../constants/queryKeys";
import { useInfiniteQuery } from "@tanstack/react-query";

export const useFetchActiveInquiry = () => {
  const queryActiveInquiry = useInfiniteQuery(
    [queryKeys.queryFetchActiveInquiry],
    ({ pageParam = 1 }) => getActiveInquiry(pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage =
          lastPage?.inquiries.length === lastPage?.pagination.limit
            ? allPages.length + 1
            : undefined;

        return nextPage;
      },
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true
    }
  );

  return queryActiveInquiry;
};
