import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { Box, Button, FormHelperText, Typography } from "@mui/material";
import { CloudUploadOutlined } from "@mui/icons-material";

const SkuImageUpload = ({
  control,
  name,
  uploadButtonText,
  setValue,
  defaultValue = null,
  isEditEnabled = false,
  invoiceType = false
}) => {
  const [filePreview, setFilePreview] = useState(null);
  const [error, setError] = useState("");

  const handleFileChange = (file) => {
    setFilePreview(file);
  };

  const convertToBase64 = (file) => {
    return URL.createObjectURL(file);
  };

  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      textAlign={"center"}
      width={"100%"}
      height={"100%"}
      sx={{
        background: !invoiceType && filePreview ? "#fff" : "#F3F5F9"
      }}
    >
      {filePreview ? (
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={2}
        >
          {filePreview.type.startsWith("image/") ? (
            <img
              src={convertToBase64(filePreview)}
              alt="Preview"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover"
              }}
            />
          ) : (
            <embed
              src={convertToBase64(filePreview)}
              type="application/pdf"
              height={400}
            />
          )}
          {!isEditEnabled && (
            <Button
              variant="link"
              disableRipple
              sx={{ fontWeight: 600 }}
              onClick={() => {
                setValue(name, null);
                setFilePreview(null);
                setError("");
              }}
            >
              {"Remove File"}
            </Button>
          )}
        </Box>
      ) : (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
        >
          <Controller
            name={name}
            control={control}
            render={({ field: { value, onChange } }) => (
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={4}
                justifyContent={"center"}
                alignItems={"center"}
                py={"10px"}
                onDrop={(e) => {
                  e.preventDefault();
                  const allowFileTypes = invoiceType
                    ? [
                        "image/jpeg",
                        "image/jpg",
                        "image/png",
                        "application/pdf"
                      ]
                    : ["image/jpeg", "image/jpg", "image/png"];
                  const fileData = e.dataTransfer.files[0];
                  if (!allowFileTypes.includes(fileData.type)) {
                    setError(
                      invoiceType
                        ? "Only Image or Pdf allowed"
                        : "Only Image allowed"
                    );
                    return;
                  }
                  if (e.dataTransfer.files.length > 1) {
                    setError("Only one File allowed");
                    return;
                  }
                  setError("");
                  onChange(fileData);
                  handleFileChange(fileData);
                }}
                onDragOver={(e) => !isEditEnabled && e.preventDefault()}
              >
                <input
                  value={value?.fileName}
                  id="fileInput"
                  type="file"
                  accept={
                    invoiceType
                      ? ".jpg, .jpeg, .png, .pdf"
                      : ".jpg, .jpeg, .png"
                  }
                  style={{ display: "none" }}
                  disabled={isEditEnabled}
                  onChange={(e) => {
                    onChange(e.target.files[0]);
                    handleFileChange(e.target.files[0]);
                  }}
                />
                <Typography fontSize={14} lineHeight={"20px"} fontWeight={400}>
                  Drag & drop file here or,
                </Typography>
                <CloudUploadOutlined />
                <label htmlFor="fileInput">
                  <Button
                    variant="link"
                    disableRipple
                    sx={{ fontWeight: 600 }}
                    component={"span"}
                    disabled={isEditEnabled}
                  >
                    {uploadButtonText}
                  </Button>
                </label>
              </Box>
            )}
          />
          {error && <FormHelperText error>{error}</FormHelperText>}
        </Box>
      )}
    </Box>
  );
};

export default SkuImageUpload;
