import { Button, IconButton } from "@mui/material";
import {
  OrderStatusKeys,
  RetrieveStatusKeys
} from "../../../constants/formKeys";

import { AppContext } from "../../../shared/context/auth-context";
import CANCEL from "../../../assets/icons/Close.svg";
import { CustomTooltip } from "../../../shared/theme/globalTheme";
import MODIFY from "../../../assets/icons/Edit.svg";
import PACKAGING_NOTE from "../../../assets/icons/PackingNote.svg";
import RETRIEVE from "../../../assets/icons/Retrieve.svg";
import appRoutes from "../../../constants/appRoutes";
import { isMobileDevice } from "../../../App";
import { order_table_labels } from "../../../constants";
import styled from "@emotion/styled";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

export const OrderStyledIconButton = styled((props) => (
  <IconButton
    {...props}
    sx={{
      // mx: 0.7,
      height: "28px",
      width: "28px",
      border: "1px solid #E6EAEE",
      cursor: "pointer",
      background: props.isClose ? "#FEEEEE" : "#EEF1F4",
      ":hover": {
        background: "#D4E3FF80"
      },
      "& > img": {
        height: "auto",
        width: "auto"
      },
      "@media (min-width: 600px)": {
        height: "28px",
        width: "28px"
      }
    }}
  />
))();

export const ActionIconButtons = ({
  status,
  type = "default",
  orderId,
  orderCode,
  orderDate
}) => {
  const navigate = useNavigate();
  const auth = useContext(AppContext);

  const ActionObject = {
    view: {
      label: order_table_labels.view,
      callback: () => {}
    },
    downloadGatepass: {
      label: order_table_labels.downloadGatepass,
      callback: () => {}
    },
    package_note: {
      icon: PACKAGING_NOTE,
      tooltip: order_table_labels.createPackingNotes,
      callback: () => {
        navigate(
          `${appRoutes.orders.main}/${appRoutes.orders.myOrdersListView}/${orderId}${appRoutes.orders.myOrdersCreatePackingNotesRedirect}`
        );
        auth.setCrumbStack(
          `${appRoutes.orders.main}/${appRoutes.orders.myOrdersListView}/${orderId}${appRoutes.orders.myOrdersCreatePackingNotesRedirect}`,
          isMobileDevice ? order_table_labels.createPackingNotes : ""
        );
        window.scroll({
          left: 0,
          top: 0,
          behavior: "smooth"
        });
      }
    },
    modifyOrder: {
      icon: MODIFY,
      tooltip: order_table_labels.modifyOrder,
      callback: () => {
        navigate(
          `${appRoutes.orders.main}/${appRoutes.orders.myOrdersListView}/${orderId}`
        );
        auth.setCrumbStack(
          `${appRoutes.orders.main}/${appRoutes.orders.myOrdersListView}/${orderId}`,
          isMobileDevice ? order_table_labels.orderDetails : ""
        );
        window.scroll({
          left: 0,
          top: 0,
          behavior: "smooth"
        });
      }
    },
    cancel: {
      icon: CANCEL,
      tooltip: order_table_labels.cancelOrder,
      callback: () => {}
    },
    retrieve: {
      icon: RETRIEVE,
      tooltip: order_table_labels.retrieveOrder,
      callback: () => {
        navigate(appRoutes.orders.myOrdersRetrieveOrdersRedirect + orderId);
        auth.setCrumbStack(
          appRoutes.orders.myOrdersRetrieveOrdersRedirect + orderId,
          isMobileDevice ? order_table_labels.retrieveOrders : ""
        );
        window.scroll({
          left: 0,
          top: 0,
          behavior: "smooth"
        });
      }
    }
  };

  const iconObject = {
    default: {
      [RetrieveStatusKeys.GatePassRequested]: [ActionObject.view],
      [RetrieveStatusKeys.GatePassPrepared]: [ActionObject.downloadGatepass]
    },
    warehouse: {
      [OrderStatusKeys.Completed]: [ActionObject.view],
      [OrderStatusKeys.ToBePicked]: [
        ActionObject.package_note,
        ActionObject.modifyOrder,
        ActionObject.cancel
      ],
      [OrderStatusKeys.Cancelled]: [ActionObject.view],
      [OrderStatusKeys.InTransit]: [ActionObject.modifyOrder],
      [OrderStatusKeys.ReceivedAtFacility]: [ActionObject.modifyOrder],
      [OrderStatusKeys.Stored]: [
        ActionObject.modifyOrder,
        ActionObject.retrieve
      ],
      [OrderStatusKeys.PartiallyRetrieved]: [ActionObject.retrieve]
    },
    transport: {
      [OrderStatusKeys.Completed]: [ActionObject.view],
      [OrderStatusKeys.ToBePicked]: [ActionObject.cancel],
      [OrderStatusKeys.Cancelled]: [ActionObject.view],
      [OrderStatusKeys.VehicleAssigned]: [],
      [OrderStatusKeys.InTransit]: []
    }
  };

  const returnArray =
    type &&
    iconObject[type][status]?.map((actions, index) => {
      const data = iconObject[type][status] && iconObject[type][status][index];
      let buttonComponent = "";
      if (data?.label) {
        buttonComponent = (
          <Button
            variant="link"
            onClick={data?.callback}
            disableRipple
            key={index}
            sx={{ whiteSpace: "nowrap" }}
          >
            {data?.label}
          </Button>
        );
      } else {
        buttonComponent = (
          <OrderStyledIconButton onClick={data?.callback} key={index}>
            <CustomTooltip title={data?.tooltip} placement="top">
              <img
                src={data?.icon}
                className="pull-right cursor-field"
                alt={data?.tooltip}
              />
            </CustomTooltip>
          </OrderStyledIconButton>
        );
      }
      return buttonComponent;
    });
  return returnArray;
};
