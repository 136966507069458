import { queryKeys } from "../../../constants/queryKeys";
import { getPOTransactions } from "../../../purchase/services";
import { useQuery } from "@tanstack/react-query";

export const useFetchPOTransactionsById = (orderId) => {
  const transactions = useQuery(
    [queryKeys.queryFetchPOTransactionsById, orderId],
    () => getPOTransactions(orderId),
    {
      enabled: Boolean(orderId),
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return transactions;
};
 