import { Box } from "@mui/material";

import { home_labels } from "../../constants";
import { StyledResponsiveText } from "../../shared/theme/globalTheme";

const LandingHeader = () => {
  return (
    <Box
      className="header_group_1"
      display={"flex"}
      flexDirection={"column"}
      sx={{ gap: { xs: "10px", md: "28px" } }}
    >
      <StyledResponsiveText
        component={"h1"}
        color={"text.title"}
        desktop={{
          fontSize: 70,
          fontWeight: 700,
          lineHeight: "104px",
          letterSpacing: "-1.4px"
        }}
        mobile={{
          fontSize: 24,
          fontWeight: 700,
          lineHeight: "40px",
          letterSpacing: "-0.48px"
        }}
        sx={{
          textRendering: "optimizeSpeed"
        }}
      >
        {home_labels.titleText}
      </StyledResponsiveText>
      <StyledResponsiveText
        desktop={{
          fontSize: 20,
          lineHeight: "64px",
          letterSpacing: "-0.4px",
          fontWeight: 400
        }}
        mobile={{
          fontSize: 14,
          lineHeight: "21px",
          letterSpacing: "-0.14px",
          fontWeight: 400
        }}
        sx={{
          color: {
            xs: "text.secondary",
            md: "text.primary"
          },
          mt: "10px",
          textAlign: "justify",
          textRendering: "optimizeSpeed"
        }}
      >
        {home_labels.titleSubtext}
      </StyledResponsiveText>
    </Box>
  );
};

export default LandingHeader;
