import { getAllDocuments } from "../../customer/services";
import { queryKeys } from "../../constants/queryKeys";
import { useInfiniteQuery } from "@tanstack/react-query";

export const useFetchDocuments = () => {
  const queryDocuments = useInfiniteQuery(
    [queryKeys.queryFetchDocuments],
    ({ pageParam = 1 }) => getAllDocuments(pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage =
          lastPage.orders.length === lastPage.pagination.limit
            ? allPages.length + 1
            : undefined;

        return nextPage;
      }
    }
  );

  return queryDocuments;
};
