import { Chip } from "@mui/material";
import { Circle } from "@mui/icons-material";
import { statusKeys } from "../../constants";
import { stringCapitalization } from "../utils";

const SuccessCode = {
  color: "success.dark",
  backgroundColor: "success.background"
};

const WarningCode = {
  color: "warning.dark",
  backgroundColor: "warning.background"
};

const ErrorCode = {
  color: "error.dark",
  backgroundColor: "error.background"
};

const TransitCode = {
  color: "transit.dark",
  backgroundColor: "transit.background"
};

const ReceivedCode = {
  color: "received.dark",
  backgroundColor: "received.background"
};

export const StyledChip = ({ label, variant = "outlined", ...props }) => {
  const statusCode = {
    [statusKeys.Completed]: SuccessCode,
    [statusKeys.OrderBooked]: SuccessCode,
    [statusKeys.ToBePickedUp]: WarningCode,
    [statusKeys.ToBePicked]: WarningCode,
    [statusKeys.Cancelled]: ErrorCode,
    [statusKeys.VehicleAssigned]: ErrorCode,
    [statusKeys.InTransit]: TransitCode,
    [statusKeys.ReceivedAtFacility]: ReceivedCode,
    [statusKeys.Stored]: SuccessCode,
    [statusKeys.PartiallyRetrieved]: SuccessCode,
    [statusKeys.Paid]: SuccessCode,
    [statusKeys.Partial]: ReceivedCode,
    [statusKeys.Submitted]: ReceivedCode,
    [statusKeys.PartialReceived]: ReceivedCode,
    [statusKeys.Adjusted]: SuccessCode,
    [statusKeys.Draft]: WarningCode,
    [statusKeys.InvoicePending]: WarningCode,
    [statusKeys.Responded]: SuccessCode,
    [statusKeys.Regret]: ErrorCode,
    [statusKeys.Pending]: WarningCode,
    [statusKeys.QuoteSent]: SuccessCode,
    [statusKeys.YetToBePicked]: WarningCode,
    [statusKeys.Received]: SuccessCode,
    [statusKeys.SubmittedForReview]: WarningCode,
    [statusKeys.GatePassRequested]: WarningCode,
    [statusKeys.GatePassPrepared]: SuccessCode,
    [statusKeys.Placed]: ErrorCode,
    [statusKeys.PartiallyReceived]: ReceivedCode,
    [statusKeys.Dispatched]: SuccessCode,
    [statusKeys.PartiallyDispatched]: ReceivedCode,
    [statusKeys.PartialDispatched]: ReceivedCode,
    [statusKeys.PartialDelivered]: ReceivedCode,
    [statusKeys.Lost]: ErrorCode,
    [statusKeys.Expired]: ErrorCode,
    [statusKeys.SoCancelled]: ErrorCode,
    [statusKeys.Converted]: SuccessCode,
    [statusKeys.Delivered]: SuccessCode,
    [statusKeys.QuotesSent]: ReceivedCode,
    [statusKeys.Approved]: ReceivedCode,
    [statusKeys.Active]: SuccessCode,
    [statusKeys.Generated]: SuccessCode,
    [statusKeys.InActive]: ErrorCode,
  };

  return (
    <Chip
      {...props}
      sx={{
        color: statusCode[label]?.color,
        backgroundColor: ["outlined", "standard"].includes(variant)
          ? statusCode[label]?.backgroundColor
          : "transparent",
        border: variant === "outlined" ? "0.4px solid" : 0,
        borderColor: statusCode[label]?.color,
        fontSize: 13,
        fontWeight: 500,
        lineHeight: "18px",
        paddingLeft: ["outlined", "standard"].includes(variant) ? "7px" : 0,
        height: ["outlined", "standard"].includes(variant) ? 32 : "auto",
        ".MuiChip-icon": {
          color: statusCode[label]?.color
        },
        ".MuiChip-label": {
          textOverflow: ["outlined", "standard"].includes(variant) && "unset",
          whiteSpace: ["text"].includes(variant) && "normal",
          wordWrap: ["text"].includes(variant) && "break-word",
          display: ["text"].includes(variant) && "block"
        },
        "& > svg": {
          marginLeft: variant === "outlined" ? "5px" : "0px !important"
        }
      }}
      icon={
        <Circle
          sx={{
            fontSize: 12
          }}
        />
      }
      label={stringCapitalization(
        label?.replace(/([a-z0-9])([A-Z])/g, "$1 $2").toLowerCase()
      )}
    />
  );
};
