import { getStockTransferSKUs } from "../../../purchase/services";
import { queryKeys } from "../../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchStockTransferSKUs = (orderType, params) => {
  const queryStockTransferSKUs = useQuery(
    [queryKeys.queryFetchStockTransferSKUs, JSON.stringify(params)],
    () => getStockTransferSKUs(params),
    {
      enabled: Boolean(params) && Boolean(orderType === "stockTransfer"),
      refetchOnWindowFocus: false
    }
  );

  return queryStockTransferSKUs;
};
