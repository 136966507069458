import { queryKeys } from "../../constants/queryKeys";
import { getCreditNoteCreateFormData } from "../../finance/services";
import { useQuery } from "@tanstack/react-query";

export const useFetchCreditNoteCreateFormData = (orderId, invId, orderType) => {
  const CreditNoteFormData = useQuery(
    [queryKeys.queryFetchCreditNoteCreateFormData, orderId, Boolean(invId)],
    () => getCreditNoteCreateFormData(orderId),
    {
      enabled:
        !Boolean(invId) &&
        Boolean(orderId) &&
        Boolean(orderType === "SaleReturn"),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return CreditNoteFormData;
};
