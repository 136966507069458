import { getSkuExcelTemplate } from "../../inventory/services/index";
import { queryKeys } from "../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchSampleSkuTemplate = () => {
  const querySkuExcelTemplate = useQuery(
    [queryKeys.queryFetchSkuExcelTemplate],
    () => getSkuExcelTemplate(),
    {
      refetchOnWindowFocus: false
    }
  );

  return querySkuExcelTemplate;
};
