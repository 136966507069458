import { Box } from "@mui/material";
import React from "react";
import SubscriptionTable from "../components/SubscriptionTable";
import UserRolesTable from "../components/UserRolesTable";

const WebSettings = ({ clientId, userCount, allowCreateFormData }) => {
  return (
    <Box display={"flex"} flexDirection={"column"} gap={10}>
      <SubscriptionTable clientId={clientId} />
      <UserRolesTable
        clientId={clientId}
        userCount={userCount}
        allowCreateFormData={allowCreateFormData}
      />
    </Box>
  );
};

export default WebSettings;
