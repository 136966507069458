import { getStockAdjustmentSKUs } from "../../../purchase/services";
import { queryKeys } from "../../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchStockAdjustmentSKUs = (orderType, params) => {
  const queryStockAdjustmentSKUs = useQuery(
    [queryKeys.queryFetchStockAdjustmentSKUs, JSON.stringify(params)],
    () => getStockAdjustmentSKUs(params),
    {
      enabled: Boolean(params) && Boolean(orderType === "stockAdjustments"),
      refetchOnWindowFocus: false
    }
  );

  return queryStockAdjustmentSKUs;
};
