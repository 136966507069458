import * as yup from "yup";

import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import TextInput from "../../../shared/formElements/TextInput";
import { add_customer_labels, error_msg } from "../../../constants";
import { add_customer_fiterKeys } from "../../../constants/formKeys";
import DropDown from "../../../shared/formElements/DropDown";
import { useContext, useEffect, useState } from "react";
import { useFetchCustomerFormData } from "../../../query-hooks/ClientsCustomer/CustomerDetails/useFetchCustomerFormData";
import { dropdownOptions } from "../../../shared/utils";
import { useCreateCustomer } from "../../../query-hooks/ClientsCustomer/CustomerDetails/useCreateCustomer";
import { useUpdateCustomer } from "../../../query-hooks/ClientsCustomer/CustomerDetails/useUpdateCustomer";
import { enqueueSnackbar } from "notistack";
import { ImageUpload } from "../../../shared/formElements/ImageUpload";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { useLocation, useNavigate } from "react-router-dom";
import appRoutes from "../../../constants/appRoutes";
import { useFetchUpdateCustomerFormData } from "../../../query-hooks/ClientsCustomer/CustomerDetails/useFetchUpdateCustomerFormData";
import { useFetchCustomerById } from "../../../query-hooks/ClientsCustomer/CustomerDetails/useFetchCustomerById";
import { AccordianInfo } from "../../../warehouse/components/MobileOrdersPage";
import { StyledBox } from "../../../purchase/pages/AddVendor";
import { DrawerButton } from "../../../inventory/components/SKU/SkuDetailsForm";
import { AppContext } from "../../../shared/context/auth-context";

const createSchema = yup.object({
  [add_customer_fiterKeys.customerCompanyName]: yup
    .string()
    .required(error_msg.required),
  [add_customer_fiterKeys.contactName]: yup
    .string()
    .required(error_msg.required),
  [add_customer_fiterKeys.contact]: yup.string().required(error_msg.required),
  [add_customer_fiterKeys.bankName]: yup.string().required(error_msg.required),
  [add_customer_fiterKeys.accountType]: yup
    .string()
    .required(error_msg.required),
  [add_customer_fiterKeys.accountName]: yup
    .string()
    .required(error_msg.required),
  [add_customer_fiterKeys.accountNumber]: yup
    .string()
    .required(error_msg.required),
  [add_customer_fiterKeys.IFSC]: yup.string().required(error_msg.required),
  [add_customer_fiterKeys.PAN]: yup
    .string()
    .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Enter a correct PAN number")
    .required(error_msg.required)
});

const updateSchema = yup.object({
  [add_customer_fiterKeys.customerCompanyName]: yup
    .string()
    .required(error_msg.required),
  [add_customer_fiterKeys.contactName]: yup
    .string()
    .required(error_msg.required),
  [add_customer_fiterKeys.contact]: yup.string().required(error_msg.required)
});

const CustomerDetails = ({ hideLabel = false, control, isEdit }) => {
  return (
    <>
      <Grid item xs={12} md={3}>
        <TextInput
          mandatoryField
          control={control}
          name={add_customer_fiterKeys.customerCompanyName}
          label={hideLabel ? "" : add_customer_labels.customerCompanyName}
          placeholder={add_customer_labels.customerCompanyName}
          disabled={isEdit}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <TextInput
          mandatoryField
          control={control}
          name={add_customer_fiterKeys.contactName}
          label={hideLabel ? "" : add_customer_labels.contactName}
          placeholder={add_customer_labels.contactName}
          disabled={isEdit}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <TextInput
          mandatoryField={true}
          control={control}
          name={add_customer_fiterKeys.contact}
          label={hideLabel ? "" : add_customer_labels.contact}
          placeholder={add_customer_labels.contact}
          disabled={isEdit}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <TextInput
          control={control}
          name={add_customer_fiterKeys.emailId}
          label={hideLabel ? "" : add_customer_labels.emailId}
          placeholder={add_customer_fiterKeys.emailId}
          disabled={isEdit}
        />
      </Grid>
    </>
  );
};

const KYC = ({
  control,
  setValue,
  isFormDataFetching,
  isUpdateFormDataFetching,
  formData,
  updateFormData,
  disabled
}) => {
  return (
    <>
      <Grid item xs={6}>
        <DropDown
          control={control}
          name={add_customer_fiterKeys.accountType}
          placeholder={add_customer_labels.accountType}
          options={
            disabled
              ? dropdownOptions(updateFormData?.bankAccountTypes)
              : dropdownOptions(formData?.bankAccountTypes)
          }
          isLoading={isFormDataFetching || isUpdateFormDataFetching}
          disabled={disabled}
          mandatoryField
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          control={control}
          name={add_customer_fiterKeys.bankName}
          placeholder={add_customer_labels.bankName}
          disabled={disabled}
          mandatoryField
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          control={control}
          name={add_customer_fiterKeys.accountName}
          placeholder={add_customer_labels.accountName}
          disabled={disabled}
          mandatoryField
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          control={control}
          name={add_customer_fiterKeys.accountNumber}
          placeholder={add_customer_labels.accountNumber}
          disabled={disabled}
          mandatoryField
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          control={control}
          name={add_customer_fiterKeys.IFSC}
          placeholder={add_customer_labels.IFSC}
          disabled={disabled}
          mandatoryField
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          control={control}
          name={add_customer_fiterKeys.PAN}
          placeholder={add_customer_labels.PAN}
          disabled={disabled}
          mandatoryField
        />
      </Grid>
      {!disabled && (
        <Grid item xs={12}>
          <ImageUpload
            control={control}
            name={add_customer_fiterKeys.cancelledCheck}
            uploadButtonText={add_customer_labels.cancelledCheck}
            compact={true}
            setValue={setValue}
          />
        </Grid>
      )}
    </>
  );
};

const CustomerDetailsForm = ({ customerId }) => {
  const [isEdit, setIsEdit] = useState(Boolean(customerId));
  const navigate = useNavigate();
  const location = useLocation();
  const { removeCrumbStack } = useContext(AppContext);
  const { control, setValue, handleSubmit } = useForm({
    resolver: yupResolver(customerId ? updateSchema : createSchema)
  });

  const handleEdit = () => {
    setIsEdit((prev) => !prev);
  };

  const { data: formData, isFetching: isFormDataFetching } =
    useFetchCustomerFormData(customerId);

  const { data: updateFormData, isFetching: isUpdateFormDataFetching } =
    useFetchUpdateCustomerFormData(customerId);

  const { data: customerData, isFetched: isCustomerFetched } =
    useFetchCustomerById(customerId);

  useEffect(() => {
    if (isCustomerFetched) {
      setValue(
        add_customer_fiterKeys.customerCompanyName,
        customerData?.customer?.companyName
      );
      setValue(
        add_customer_fiterKeys.contactName,
        customerData?.customer?.contact?.name
      );
      setValue(
        add_customer_fiterKeys.contact,
        customerData?.customer?.contact?.mobile
      );
      customerData?.customer?.contact?.email &&
        setValue(
          add_customer_fiterKeys.emailId,
          customerData?.customer?.contact?.email
        );
    }
    // eslint-disable-next-line
  }, [isCustomerFetched]);

  const {
    mutate: createCustomer,
    isLoading: isCreating,
    isError: isCreateError,
    error: createError
  } = useCreateCustomer();

  const {
    mutate: updateCustomer,
    isLoading: isUpdating,
    isError: isUpdateError,
    error: updateError
  } = useUpdateCustomer();

  const onSaveClick = (data) => {
    const formData = new FormData();
    formData.append(
      "companyName",
      data[add_customer_fiterKeys.customerCompanyName]
    );
    formData.append("contact[name]", data[add_customer_fiterKeys.contactName]);
    formData.append("contact[mobile]", data[add_customer_fiterKeys.contact]);
    data[add_customer_fiterKeys.emailId] &&
      formData.append("contact[email]", data[add_customer_fiterKeys.emailId]);
    formData.append("pan", data[add_customer_fiterKeys.PAN]);
    formData.append(
      "bankDetails[accountType]",
      data[add_customer_fiterKeys.accountType]
    );
    formData.append(
      "bankDetails[bankName]",
      data[add_customer_fiterKeys.bankName]
    );
    formData.append(
      "bankDetails[beneficiaryName]",
      data[add_customer_fiterKeys.accountName]
    );
    formData.append(
      "bankDetails[accountNumber]",
      data[add_customer_fiterKeys.accountNumber]
    );
    formData.append("bankDetails[ifsc]", data[add_customer_fiterKeys.IFSC]);
    data[add_customer_fiterKeys.cancelledCheck] &&
      formData.append(
        "chequeImage",
        data[add_customer_fiterKeys.cancelledCheck]
      );
    customerId
      ? updateCustomer(
          {
            data: formData,
            customerId: customerId
          },
          {
            onSuccess: () => {
              enqueueSnackbar("Customer Details Updated!", {
                variant: "success"
              });
            }
          }
        )
      : createCustomer(
          {
            data: formData
          },
          {
            onSuccess: (res) => {
              removeCrumbStack(location?.pathname);
              navigate(
                `${appRoutes.sales.main}/${appRoutes.sales.customers}/${res?.data?.data?.customer?._id}`
              );
              window.location.reload();
              enqueueSnackbar("Customer created!", { variant: "success" });
            }
          }
        );
  };

  useEffect(() => {
    if (isCreateError)
      enqueueSnackbar(createError?.response?.data?.message, {
        variant: "error"
      });
    if (isUpdateError)
      enqueueSnackbar(updateError?.response?.data?.message, {
        variant: "error"
      });
  }, [
    updateError?.response?.data?.message,
    createError?.response?.data?.message,
    isUpdateError,
    isCreateError
  ]);

  return (
    <Box>
      <Box
        sx={{
          display: { xs: "none", md: "block" }
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography fontWeight={500} fontSize={20} lineHeight={"24.2px"}>
              {add_customer_labels.customerDetailsLabel}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <StyledBox>
              <Grid container spacing={4}>
                <CustomerDetails control={control} isEdit={isEdit} />
                <Grid item xs={6}>
                  <StyledBox display={"flex"} flexDirection={"column"} gap={2}>
                    <Typography
                      sx={{
                        fontSize: 16,
                        lineHeight: "19px",
                        fontWeight: 500
                      }}
                    >
                      {"KYC and Bank Account Details "}
                    </Typography>
                    <Grid container rowSpacing={2} columnSpacing={5}>
                      <KYC
                        control={control}
                        setValue={setValue}
                        isFormDataFetching={isFormDataFetching}
                        isFetchingUpdateFormData={isUpdateFormDataFetching}
                        formData={formData}
                        updateFormData={updateFormData}
                        disabled={Boolean(customerId)}
                      />
                    </Grid>
                  </StyledBox>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box
                  display={"flex"}
                  gap={1}
                  justifyContent={"right"}
                  alignContent={"center"}
                >
                  {isEdit ? (
                    <Button variant="text" onClick={handleEdit}>
                      {"Edit"}
                    </Button>
                  ) : (
                    <Button
                      component={LoadingButton}
                      loading={isCreating || isUpdating}
                      variant="text"
                      onClick={handleSubmit(onSaveClick)}
                    >
                      {"Save"}
                    </Button>
                  )}
                </Box>
              </Grid>
            </StyledBox>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: { xs: "flex", md: "none" }, alignItems: "center" }}>
        <Grid item xs={12} sx={{ mt: -6, ml: "50px" }} zIndex={1}>
          <Typography
            fontWeight={700}
            fontSize={16}
            lineHeight={"19.36px"}
            color={"#2773FF"}
          >
            {customerId
              ? add_customer_labels.customerDetailsLabel
              : add_customer_labels.addCustomerLabel}
          </Typography>
        </Grid>
      </Box>
      <Grid
        container
        sx={{ display: { xs: "block", md: "none" } }}
        rowSpacing={1}
        px={"15px"}
        mb={!Boolean(customerId) ? 15 : 0}
      >
        <Grid item xs={12}>
          <AccordianInfo
            title={"Customer Details"}
            hideDivider
            defaultExpanded={!Boolean(customerId)}
          >
            <Grid container rowSpacing={2} columnSpacing={4}>
              <CustomerDetails control={control} hideLabel isEdit={isEdit} />
            </Grid>
          </AccordianInfo>
        </Grid>
        <Grid item xs={12}>
          <AccordianInfo
            title={"KYC Details"}
            defaultExpanded={!Boolean(customerId)}
          >
            <Grid container rowSpacing={2} columnSpacing={4}>
              <KYC
                control={control}
                setValue={setValue}
                isFormDataFetching={isFormDataFetching}
                isFetchingUpdateFormData={isUpdateFormDataFetching}
                formData={formData}
                updateFormData={updateFormData}
                disabled={Boolean(customerId)}
              />
            </Grid>
          </AccordianInfo>
          <Divider sx={{ border: "0.4px solid #B7BEC7", mx: "15px" }} />
        </Grid>
      </Grid>
      {!Boolean(customerId) && (
        <Grid
          container
          sx={{
            display: { xs: "flex", md: "none" },
            position: "fixed",
            bottom: "70px",
            width: "100%",
            zIndex: 1
          }}
        >
          <DrawerButton
            component={LoadingButton}
            loading={isCreating || isUpdating}
            onClick={handleSubmit(onSaveClick)}
          >
            {"Save & Next"}
          </DrawerButton>
        </Grid>
      )}
    </Box>
  );
};

export default CustomerDetailsForm;
