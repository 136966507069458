import React, { useState } from "react";
import { CustomTabs } from "../../shared/customTab/CustomTabs";
import MobileWarehouseTab from "../components/WarehouseAssets/MobileWarehouseTab";
import { useFetchAssetWarehouses } from "../../query-hooks/WarehouseAssets/useFetchAssetWarehouses";
import { isMobile, isTablet } from "react-device-detect";
import { useFetchAssetTransport } from "../../query-hooks/TransportAssets/useFetchAssetTransport";
import MobileTruckTab from "../components/WarehouseAssets/MobileTruckTab";

const AssetsMobile = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  const {
    data: warehouseData,
    isFetching: isFetchingWarehouses,
    refetch: refetchWarehouses
  } = useFetchAssetWarehouses();

  const {
    data: vehiclesData,
    isFetching: isVehicleFetching,
    refetch: refetchVehicles
  } = useFetchAssetTransport();

  const MenuItems = [
    {
      label: `Warehouses (${warehouseData?.warehouses.length || 0})`,
      Component: MobileWarehouseTab({
        data: warehouseData,
        isFetching: isFetchingWarehouses,
        refetchWarehouses: refetchWarehouses
      })
    },
    {
      label: `Trucks (${vehiclesData?.vehicles.length || 0})`,
      Component: MobileTruckTab({
        data: vehiclesData,
        isFetching: isVehicleFetching,
        refetch: refetchVehicles
      })
    }
  ];

  return (
    <CustomTabs
      tabs={MenuItems}
      activeTab={activeTab}
      onChange={handleTabChange}
      nested={true}
      backgroundColor={(isMobile || isTablet) && "none"}
    />
  );
};

export default AssetsMobile;
