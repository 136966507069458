import { Box, ThemeProvider, Typography } from "@mui/material";

import { Error } from "@mui/icons-material";
import { StyledButton } from "../formElements/StyledButton";
import { globalTheme } from "../theme/globalTheme";

const SomethingWentWrong = ({ error, resetErrorBoundary }) => {
  return (
    <ThemeProvider theme={globalTheme}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={3}
        alignItems={"center"}
        height={"100vh"}
        justifyContent={"center"}
      >
        <Error color="error" sx={{ fontSize: 64 }} />
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={1}
          alignItems={"center"}
        >
          <Typography fontSize={20} letterSpacing={"0.04px"} fontWeight={500}>
            Something went wrong
          </Typography>
          <Typography component={"pre"} fontSize={14} color={"text.secondary"}>
            {error.message}
          </Typography>
        </Box>
        <StyledButton
          variant="contained"
          disableElevation
          onClick={resetErrorBoundary}
          sx={{ textTransform: "none", height: "auto" }}
        >
          Try again
        </StyledButton>
      </Box>
    </ThemeProvider>
  );
};

export default SomethingWentWrong;
