import styled from "@emotion/styled";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography
} from "@mui/material";
import WhiteWarehouse from "../../../assets/icons/WhiteWarehouse.svg";

const StyledCard = styled(Card)(({ props }) => ({
  ...props,
  padding: "12px 18px",
  cursor: "pointer",
  minWidth: "200px",
  minHeight: "200px",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: 0,
  gap: 3
}));

const StyledText = styled(Typography)(({ props }) => ({
  ...props,
  color: "#FFFFFF",
  fontSize: 14,
  fontFamily: "Inter",
  lineHeight: "20px"
}));

const StyledButton = styled(Button)(({ props }) => ({
  color: "#FFFFFF",
  fontSize: 14,
  fontFamily: "Inter",
  lineHeight: "17px",
  fontWeight: 700,
  borderRadius: 0,
  borderColor: "#FFFFFF"
}));

const WarehouseCard = ({
  index,
  code,
  formId,
  address,
  setFormId,
  setShowForm,
  selectedCardIndex,
  handleCardClick,
  ...props
}) => {
  return (
    <StyledCard {...props} elevation={selectedCardIndex === index ? 5 : 1}>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 3
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 2
          }}
        >
          <img src={WhiteWarehouse} alt="Warehouse" />
          <StyledText sx={{ fontWeight: 400 }}>{address}</StyledText>
        </Box>
        <StyledText sx={{ fontWeight: 600 }}>{code}</StyledText>
      </CardContent>
      <CardActions
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        {selectedCardIndex === index ? (
          <StyledButton
            variant="outlined"
            sx={{ width: "59px", height: "39px" }}
            onClick={() => {
              handleCardClick(null);
              setFormId(null);
              setShowForm(false);
            }}
          >
            {"Cancel"}
          </StyledButton>
        ) : (
          <StyledButton
            variant="outlined"
            sx={{ width: "59px", height: "39px" }}
            onClick={() => {
              handleCardClick(index);
              setFormId(formId);
              setShowForm(true);
            }}
          >
            {"Edit"}
          </StyledButton>
        )}
      </CardActions>
    </StyledCard>
  );
};

export default WarehouseCard;
