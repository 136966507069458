import { NavLinkItem } from "./NavLinkItem";

export function NavLinkList({ links = [], isProfileScreen = false }) {
  return (
    <>
      {links.map((link) => (
        <NavLinkItem
          key={link.name}
          {...link}
          isProfileScreen={isProfileScreen}
        />
      ))}
    </>
  );
}
