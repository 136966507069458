import React from "react";
import { useFetchPOTransactionsById } from "../../query-hooks/ClientPurchase/PurchaseOrder/useFetchPOTransactionsById";
import { CircularProgress, Grid } from "@mui/material";
import TransactionLedger from "./TransactionLedger";

const POTransactions = ({ orderId, hideDrawer }) => {
  const { data, isFetching } = useFetchPOTransactionsById(orderId);
  const HeaderLabels = {
    Title: "PO Transactions",
    AmountLabel: "(Paid Total)",
    NoteLabel: "Debit Note"
  };
  return isFetching ? (
    <Grid
      item
      xs={12}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      height={"100%"}
    >
      <CircularProgress />
    </Grid>
  ) : (
    <TransactionLedger
      HeaderLabels={HeaderLabels}
      hideDrawer={hideDrawer}
      orderInfo={data?.purchaseOrder}
      InvoiceInfo={data?.invoices}
      NoteInfo={data?.debitNotes}
    />
  );
};

export default POTransactions;
