import { getPOSubtotal } from "../../../purchase/services/index";
import { queryKeys } from "../../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchPOSubtotal = (params) => {
  const queryPOSubtotal = useQuery(
    [queryKeys.queryFetchPOSubtotal, JSON.stringify(params)],
    () => getPOSubtotal(params),
    {
      refetchOnWindowFocus: false
    }
  );

  return queryPOSubtotal;
};
