import React, { useEffect } from "react";
import SkuDetailsForm from "./SkuDetailsForm";
import { inventory_table_labels } from "../../../constants";
import { enqueueSnackbar } from "notistack";
import { useFetchSkuById } from "../../../query-hooks/Sku/useFetchSkuById";
import { useState } from "react";
import { useUpdateSkuById } from "../../../query-hooks/Sku/useUpdateSkuById";
import { sku_filterKeys } from "../../../constants/formKeys";
import { useFetchUpdatedSkuFormData } from "../../../query-hooks/Sku/useFetchUpdatedSkuFormData";

const EditSkuById = ({ hideDrawer, skuId, refetch }) => {
  const [beginningStock, setBeginningStock] = useState([]);

  const { data, isFetching, refetch: refetchSKUById } = useFetchSkuById(skuId);

  const { mutate, isError, error, isLoading } = useUpdateSkuById();

  const { data: updatedFormData, isFetching: isUpdatedFormFetching } =
    useFetchUpdatedSkuFormData(skuId);

  const handleEditSku = (data) => {
    const skuData = new FormData();
    skuData.append("skuCode", data[sku_filterKeys.sku]);
    skuData.append("productName", data[sku_filterKeys.productName]);
    data[sku_filterKeys.brandName] &&
      skuData.append("brandName", data[sku_filterKeys.brandName]);
    data[sku_filterKeys.categoryName] &&
      skuData.append("categoryName", data[sku_filterKeys.categoryName]);
    skuData.append("unit", data[sku_filterKeys.units]);
    skuData.append("gstRegime", data[sku_filterKeys.gst]);
    data[sku_filterKeys.hsn] && skuData.append("hsn", data[sku_filterKeys.hsn]);
    data[sku_filterKeys.upc] && skuData.append("upc", data[sku_filterKeys.upc]);
    skuData.append("rate", data[sku_filterKeys.listPrice]);
    skuData.append("minStockLevel", data[sku_filterKeys.minimumStockLevel]);
    data[sku_filterKeys.imageUpload] &&
      skuData.append("image", data[sku_filterKeys.imageUpload]);
    data[sku_filterKeys.attribute1] &&
      skuData.append("customAttributes[0][label]", sku_filterKeys.attribute1);
    data[sku_filterKeys.attribute1] &&
      skuData.append(
        "customAttributes[0][value]",
        data[sku_filterKeys.attribute1]
      );
    data[sku_filterKeys.attribute2] &&
      skuData.append("customAttributes[1][label]", sku_filterKeys.attribute2);
    data[sku_filterKeys.attribute2] &&
      skuData.append(
        "customAttributes[1][value]",
        data[sku_filterKeys.attribute2]
      );
    data[sku_filterKeys.attribute3] &&
      skuData.append("customAttributes[2][label]", sku_filterKeys.attribute3);
    data[sku_filterKeys.attribute3] &&
      skuData.append(
        "customAttributes[2][value]",
        data[sku_filterKeys.attribute3]
      );
    mutate(
      {
        skuId: skuId,
        data: skuData
      },
      {
        onSuccess: () => {
          refetchSKUById();
          enqueueSnackbar("Sku Updated!", { variant: "success" });
          refetch();
          hideDrawer();
        }
      }
    );
  };

  useEffect(() => {
    if (isError)
      enqueueSnackbar(error?.response.data.message, { variant: "error" });
  }, [error?.response.data.message, isError]);

  return (
    <SkuDetailsForm
      title={inventory_table_labels.cloneSku}
      skuId={skuId}
      isLoading={isLoading}
      onSubmit={handleEditSku}
      cloneSku={true}
      defaultValues={data?.sku}
      isFetching={isFetching}
      refetch={refetch}
      beginningStock={beginningStock}
      setBeginningStock={setBeginningStock}
      formData={updatedFormData}
      isFormFetching={isUpdatedFormFetching}
    />
  );
};

export default EditSkuById;
