import { deleteGpDraft } from "../../inventory/services";
import { useMutation } from "@tanstack/react-query";

export const useDeleteGPDraft = () => {
  const deleteDraft = useMutation(({ orderId, gatepassId }) =>
    deleteGpDraft(orderId, gatepassId)
  );

  return deleteDraft;
};
