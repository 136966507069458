import { Box } from "@mui/material";
import AboutUsSection from "../components/AboutUsSection";
import ContactUsSection from "../components/ContactUsSection";
import Footer from "../components/Footer";
import IndustriesSection from "../components/IndustriesSection";
import ERPSection from "../components/ERPSection";
import MarketplaceSection from "../components/MarketplaceSection";
import SolutionsSection from "../components/SolutionsSection";
import { home_labels } from "../../constants";
import CreditSection from "../components/CreditSection";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { useEffect, useRef, useState } from "react";
import MarketplaceSliderComponent from "../components/MarketplaceSliderComponent";
import ERPSliderComponent from "../components/ERPSliderComponent";
import "./index.css";

const Landing = () => {
  const swiperRef = useRef(null);
  const containerRef = useRef(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false); 

  useEffect(() => {
    const targetElement = containerRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (
            entry.isIntersecting &&
            entry.intersectionRatio >= 0.4 &&
            !isVideoPlaying
          ) {
            if (swiperRef.current) swiperRef.current.autoplay.start();
          } else {
            if (swiperRef.current) swiperRef.current.autoplay.stop();
          }
        });
      },
      { threshold: 0.4 }
    );
    if (targetElement) {
      observer.observe(targetElement);
    }
    return () => {
      if (targetElement) {
        observer.unobserve(targetElement);
      }
    };
  }, [isVideoPlaying]); 

  const handleVideoPlayPause = (isPlaying) => {
    setIsVideoPlaying(isPlaying);
    if (swiperRef.current) {
      if (isPlaying) {
        swiperRef.current.autoplay.stop();
      } else {
        swiperRef.current.autoplay.start();
      }
    }
  };

  return (
    <Box
      sx={{
        mt: { xs: "17px", md: "81px" },
        position: "relative",
        overflow: "hidden"
      }}
    >
      <Box
        id={"Landing-Header"}
        ref={containerRef}
        style={{ position: "relative" }}
      >
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          speed={1000}
          autoplay={{
            delay: 5000,
            disableOnInteraction: !1,
            pauseOnMouseEnter: !0
          }}
          loop={!0}
          pagination={{ clickable: !0 }}
          navigation={!0}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
          onSwiper={(swiper) => (swiperRef.current = swiper)}
        >
          <SwiperSlide>
            <ERPSliderComponent onVideoPlayPause={handleVideoPlayPause} />
          </SwiperSlide>
          <SwiperSlide>
            <MarketplaceSliderComponent />
          </SwiperSlide>
        </Swiper>
      </Box>
      <SolutionsSection />
      <MarketplaceSection />
      <ERPSection />
      <CreditSection />
      <IndustriesSection />
      <AboutUsSection />
      <ContactUsSection title={home_labels.contactUsTitleText} />
      <Footer />
    </Box>
  );
};

export default Landing;
