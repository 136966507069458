import { queryKeys } from "../../../constants/queryKeys";
import { getSalesPersons } from "../../../sales/services";
import { useQuery } from "@tanstack/react-query";

export const useFetchSalesPerson = () => {
  const salesPersons = useQuery(
    [queryKeys.queryFetchSalesPersons],
    () => getSalesPersons(),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return salesPersons;
};
