import React from "react";
import GPInvoiceTable from "./GPInvoiceTable";
import { useFetchDCInCreateFormData } from "../../../query-hooks/ClientOrderInvoice/useFetchDCInCreateFormData";
import { useFetchDCInUpdateFormData } from "../../../query-hooks/ClientOrderInvoice/useFetchDCInUpdateFormData";
import { Backdrop, CircularProgress } from "@mui/material";
import moment from "moment";
import { currencyOnlyFormatter } from "../../../shared/utils";
import ManualInvoiceUpload from "../../../finance/components/ManualInvoiceUpload";
// import { useFetchDCInvoiceById } from "../../query-hooks/ClientOrderInvoice/useFetchDCInvoiceById";

const CreateInvoiceTable = ({
  orderId,
  dcId,
  invId,
  orderType,
  deliveredDate,
  orderInfo,
  InvoiceData,
  InvCreateAllowed,
  refectDocs
}) => {
  // const { data: DCInvoiceData, isFetching: isFetchingInvoice } =
  //   useFetchDCInvoiceById(invId, orderType);

  const { data: CreateDCInvoiceFormData, isFetching: isFetchingFormData } =
    useFetchDCInCreateFormData(orderId, invId, orderType);

  const {
    data: UpdatedDCInvoiceFormData,
    isFetching: isFetchingUpdateFormData
  } = useFetchDCInUpdateFormData(invId, orderType, InvoiceData?.status?.name);

  const createTableData = CreateDCInvoiceFormData?.deliveryChallans?.find(
    (item) => item?._id === dcId
  );

  const tableData = invId ? InvoiceData : createTableData;
  const advOptions = invId
    ? UpdatedDCInvoiceFormData?.advancePercents
    : CreateDCInvoiceFormData?.advancePercents;
  const dueOptions = invId
    ? UpdatedDCInvoiceFormData?.dueDays
    : CreateDCInvoiceFormData?.dueDays;

  return isFetchingFormData || isFetchingUpdateFormData ? (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isFetchingFormData || isFetchingUpdateFormData}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : Boolean(InvoiceData?.file) ? (
    <ManualInvoiceUpload
      InvInfo={{
        file: InvoiceData?.file,
        code: InvoiceData?.customCode,
        date: moment(InvoiceData?.docDate).format("DD-MM-YYYY"),
        amount: currencyOnlyFormatter(
          parseFloat(InvoiceData?.totalAmount).toFixed(2)
        ),
        advance: InvoiceData?.advancePercent,
        due: InvoiceData?.dueDays,
        deliveredDate: deliveredDate
      }}
      Title={orderType === "SaleOrder" ? "Invoice" : "Debit Note"}
    />
  ) : (
    <GPInvoiceTable
      DcId={dcId}
      dcInvId={invId}
      orderId={orderId}
      orderType={orderType}
      InvoiceStatus={InvoiceData?.status?.name}
      deliveredDate={deliveredDate}
      orderData={orderInfo}
      tableData={tableData}
      advOptions={advOptions}
      dueOptions={dueOptions}
      InvCreateAllowed={InvCreateAllowed}
      refectDocs={refectDocs}
    />
  );
};

export default CreateInvoiceTable;
