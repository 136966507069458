import { Box, Divider, Typography } from "@mui/material";

import { CalendarTodayOutlined } from "@mui/icons-material";
import DateInput from "../../../shared/formElements/DateInput";
import { LoadingButton } from "@mui/lab";
import { SearchFormKeys } from "../../../constants/formKeys";
import TextInput from "../../../shared/formElements/TextInput";
import { isMobileDevice } from "../../../App";
import { labels } from "../../../constants";
import moment from "moment";
import { primaryBackground } from "../../../shared/theme/globalTheme";
import styled from "@emotion/styled";
import { truncateStringWithEllipsis } from "../../../shared/utils";

const StyleBorderedBox = styled(({ isEdit, ...props }) => (
  <Box
    {...props}
    sx={{
      ...props.sx,
      p: !isEdit
        ? {
            xs: "20px 16px",
            lg: "45px 33px"
          }
        : 0,
      display: "flex",
      alignItems: "center",
      height: { xs: "fit-content" },
      border: isEdit ? "none" : "0.4px solid #95A1B0",
      borderRadius: "8px"
    }}
  />
))(({ theme }) => ({}));

const OrderDetailsLocationAndDate = ({
  isEdit,
  orderData,
  control,
  updatedOrderTotalData
}) => {
  return (
    <StyleBorderedBox
      sx={{ backgroundColor: isEdit && primaryBackground }}
      isEdit={isEdit}
    >
      <Box
        display={"flex"}
        sx={{
          gap: {
            xs: "20px",
            lg: isEdit ? 2 : "50px"
          },
          flexWrap: {
            xs: "wrap",
            lg: "nowrap"
          },
          width: { xs: "100%", lg: "auto" },
          padding: {
            xs: isEdit ? "24px 16px" : 0,
            lg: isEdit ? "0 24px" : 0
          }
          // alignItems: "baseline"
        }}
      >
        <Box
          display={"flex"}
          sx={{
            width: {
              xs: isEdit ? "100%" : "auto",
              lg: "100%"
            },
            flexGrow: { xs: 0.5, lg: "inherit" },
            padding: {
              xs: 0,
              lg: isEdit ? "28px 0" : 0
            }
          }}
        >
          {isEdit ? (
            <DateInput
              disabled
              control={control}
              name={SearchFormKeys.InboundDate}
              label={labels.inboundDateLabelText}
              placeholder={labels.datePlaceholderText}
              endIcon={CalendarTodayOutlined}
            />
          ) : (
            <Box display={"flex"} flexDirection={"column"} gap={"4px"}>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 500
                }}
              >
                {moment(orderData?.recentOrderItem?.inboundDate).format(
                  "DD MMM’YY"
                )}
              </Typography>
              <Typography
                color={"text.secondary"}
                sx={{
                  fontSize: 14,
                  lineHeight: "20px",
                  whiteSpace: "nowrap"
                }}
              >
                {labels.inboundDateLabelText}
              </Typography>
            </Box>
          )}
        </Box>
        <Box
          display={"flex"}
          sx={{
            width: {
              xs: isEdit ? "100%" : "auto",
              lg: "100%"
            },
            flexGrow: { xs: 0.5, lg: "inherit" },
            padding: {
              xs: 0,
              lg: isEdit ? "28px 0" : 0
            }
          }}
        >
          {isEdit ? (
            <DateInput
              control={control}
              name={SearchFormKeys.OutboundDate}
              label={labels.outboundDateLabelText}
              placeholder={labels.datePlaceholderText}
              endIcon={CalendarTodayOutlined}
              minDate={new Date(orderData?.recentOrderItem?.outboundDate)}
            />
          ) : (
            <Box display={"flex"} flexDirection={"column"} gap={"4px"}>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 500
                }}
              >
                {moment(orderData?.recentOrderItem?.outboundDate).format(
                  "DD MMM’YY"
                )}
              </Typography>
              <Typography
                color={"text.secondary"}
                sx={{
                  fontSize: 14,
                  fontWeight: 400,
                  lineHeight: "20px",
                  whiteSpace: "nowrap"
                }}
              >
                {labels.outboundDateLabelText}
              </Typography>
            </Box>
          )}
        </Box>
        <Divider
          orientation={isMobileDevice ? "horizontal" : "vertical"}
          sx={{
            height: "auto",
            borderColor: "#95A1B0",
            width: { xs: "-webkit-fill-available", lg: "auto" },
            marginLeft: { xs: "-16px", lg: "auto" },
            marginRight: { xs: "-16px", lg: "auto" }
          }}
        />
        <Box
          display={"flex"}
          sx={{
            width: "100%",
            flexGrow: { xs: 1, lg: "inherit" },
            padding: {
              xs: 0,
              lg: isEdit ? "28px 0" : 0
            }
          }}
        >
          {isEdit || isMobileDevice ? (
            <TextInput
              disabled
              control={control}
              name={SearchFormKeys.PickupLocation}
              label={labels.pickupLocationLabelText}
              placeholder={labels.pickupLocationPlaceholderText}
            />
          ) : (
            <Box display={"flex"} flexDirection={"column"} gap={"4px"}>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 500
                }}
                whiteSpace={"nowrap"}
                overflow={"hidden"}
                textOverflow={"ellipsis"}
              >
                {truncateStringWithEllipsis(
                  orderData?.searchForm?.pickup?.address,
                  28
                )}
              </Typography>
              <Typography
                color={"text.secondary"}
                sx={{
                  fontSize: 14,
                  fontWeight: 400,
                  whiteSpace: "nowrap"
                }}
              >
                {labels.pickupLocationLabelText}
              </Typography>
            </Box>
          )}
        </Box>
        {isEdit && (
          <Box
            display={"flex"}
            sx={{
              width: "100%",
              flexGrow: { xs: 1, lg: "inherit" },
              padding: {
                xs: 0,
                lg: isEdit ? "28px 0" : 0
              }
            }}
          >
            <LoadingButton
              variant="contained"
              fullWidth
              sx={{ height: 44, textTransform: "none", alignSelf: "flex-end" }}
              loading={updatedOrderTotalData.isFetching}
              onClick={updatedOrderTotalData.refetch}
            >
              {labels.getUpdatedTotalButtonText}
            </LoadingButton>
          </Box>
        )}
      </Box>
    </StyleBorderedBox>
  );
};

export default OrderDetailsLocationAndDate;
