import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";
import { KeyboardArrowRight } from "@mui/icons-material";
import appRoutes from "../../../constants/appRoutes";
import { stringCapitalization } from "../../../shared/utils";
import { StyledChip } from "../../../shared/UiElements/StatusChip";
import { getCustomCell } from "../../../purchase/pages/PurchaseOrders";
import { ValueLabelText } from "../../../purchase/components/Purchase/MobilePOTable";

export const MobileQuotes = (row) => {
  return (
    <Grid container spacing={"20px"}>
      <Grid item xs={12} container rowSpacing={2}>
        <Grid item xs={8.5} container>
          <Grid
            item
            xs={5}
            display={"flex"}
            alignItems={"flex-end"}
            justifyContent={"left"}
          >
            {getCustomCell(
              row?.docNo,
              "",
              `${appRoutes.sales.main}/${appRoutes.sales.quotes}/${row.quoteId}`
            )}
          </Grid>
          <Grid
            item
            xs={7}
            display={"flex"}
            alignItems={"flex-end"}
            justifyContent={"left"}
          >
            <Typography
              fontSize={8}
              lineHeight={"9.68px"}
              fontWeight={400}
              color={"text.disabled"}
            >
              {row?.customer}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={3.5} display={"flex"} justifyContent={"right"}>
          <Typography
            sx={{ fontSize: 14, lineHeight: "20px", fontWeight: 400 }}
          >
            {row.date}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"left"}
        >
          <ValueLabelText label={"Quote Value"} value={row.quoteAmt} />
        </Grid>
        <Grid
          item
          xs={4}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          {row?.quoteProgress === "Converted" && (
            <ValueLabelText label={"SO Value"} value={row.orderAmt} />
          )}
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            display: "flex",
            justifyContent: "right"
          }}
        >
          <IconButton color="primary">
            <KeyboardArrowRight />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid
          item
          xs={4}
          display={"flex"}
          alignItems={"flex-start"}
          justifyContent={"left"}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={0.5}
            alignItems={"flex-start"}
            overflow={"clip"}
          >
            <StyledChip
              label={stringCapitalization(row.quoteProgress)}
              variant="text"
            />
            <Typography
              fontSize={12}
              lineHeight={"16px"}
              color={"text.disabled"}
            >
              Status
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={4}
          display={"flex"}
          alignItems={"flex-start"}
          justifyContent={"center"}
        >
          {Boolean(row?.salesOrder) &&
            getCustomCell(
              row?.salesOrder,
              "",
              row.quoteProgress === "Converted" &&
                `${appRoutes.sales.main}/${appRoutes.sales.salesOrders}/${row.salesId}/salesOrder`
            )}
        </Grid>
        <Grid
          item
          xs={4}
          display={"flex"}
          alignItems={"flex-start"}
          justifyContent={"right"}
        >
          {Boolean(row?.salesOrder) && (
            <ValueLabelText
              label={"Status Changed on"}
              value={row.changeDate}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
