import { Box, Typography } from "@mui/material";

import { StyledHomButton } from "./LandingBanner";
import { useNavigate } from "react-router-dom";
import { useCustomDialog } from "../../shared/customDialog";
import Login from "../../shared/layouts/Login";
import { gtag_report_conversion } from "../../shared/utils/conversionUtils";
import { useContext } from "react";
import { AppContext } from "../../shared/context/auth-context";
import appRoutes from "../../constants/appRoutes";

const ERPHomeButtons = () => {
  const navigate = useNavigate();
  const { showDialog } = useCustomDialog();
  const { currentUser } = useContext(AppContext);

  const handleConversion = () => {
    // Call Google Analytics conversion tracking
    gtag_report_conversion();

    // Open dialog box
    Boolean(currentUser)
      ? navigate("/dashboard")
      : showDialog({
          component: <Login />,
          size: "xs",
          backdropOff: true,
          closeIcon: true
        });
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: 1
      }}
    >
      <Box
        sx={{
          opacity: 1,
          background: "#2773FF",
          borderRadius: 1,
          overflow: "hidden"
        }}
      >
        <StyledHomButton
          isLanding={true}
          bgColor="#F9A90E"
          onClick={handleConversion}
        >
          {"Start Free Trial"}
        </StyledHomButton>
        <StyledHomButton
          isLanding={true}
          bgColor="#1E65EA"
          onClick={() => navigate(appRoutes.erp)}
        >
          {"Know More"}
        </StyledHomButton>
      </Box>
      <Box display={"flex"} padding={"0px 60px !important"} gap={8}>
        <Typography
          sx={{
            fontSize: {
              xs: 14,
              md: 16
            },
            lineHeight: {
              xs: "21px",
              md: "24px"
            },
            letterSpacing: "-0.02em"
          }}
          color={"text.disabled"}
        >
          <span style={{ fontWeight: 700, color: "#2773FF" }}>{"50% Off"}</span>
          {" on Early Booking"}
        </Typography>
      </Box>
    </Box>
  );
};

export default ERPHomeButtons;
