import { getCreateFormData } from "../../customer/services";
import { queryKeys } from "../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchCreateOrderFormData = (
  params,
  warehousePartnerId,
  transportPartnerId,
  serviceType
) => {
  const queryOrderFormData = useQuery(
    [queryKeys.queryFetchCreateOrderFormData, serviceType],
    () =>
      getCreateFormData({
        ...params,
        warehousePartnerId,
        transportPartnerId
      }),
    {
      enabled:
        Boolean(params) &&
        (Boolean(warehousePartnerId) || Boolean(transportPartnerId)),
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true
    }
  );

  return queryOrderFormData;
};
