import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import {
  appConstants,
  invoice_and_docs_labels,
  order_table_labels
} from "../../constants";
import { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import CustomTable from "../../shared/customTable";
import DOCUMENTS from "../../assets/icons/Documents.svg";
import LedgerDoc from "../components/InvoiceAndDocs/LedgerDoc";
import PackingNotesDoc from "../components/InvoiceAndDocs/PackingNotesDoc";
import { dateTimeFormatter } from "../../shared/utils";
import { isMobileDevice } from "../../App";
import { secondaryBackground } from "../../shared/theme/globalTheme";
import styled from "@emotion/styled";
import { useDrawer } from "../../shared/customSideDrawer";
import { useFetchDocuments } from "../../query-hooks/Documents/useFetchDocuments";
import { useFetchInvoice } from "../../query-hooks/Documents/useFetchInvoice";
import { useGetOrderDetails } from "../../query-hooks/Orders/useGetOrderDetails";
import { useInView } from "react-intersection-observer";

const selectedCss = { backgroundColor: "#D4E3FF80", borderRadius: "2px" };

const StyleGrid = styled((props) => <Grid {...props} />)(({ theme }) => ({
  alignItems: "center",
  cursor: "pointer",
  height: 80,
  "&:hover": {
    ...selectedCss
  }
}));

const StyleDocBox = styled((props) => (
  <Box
    {...props}
    sx={{
      position: "relative",
      height: "100%",
      overflow: "auto",
      backgroundColor: secondaryBackground,
      padding: 3,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      gap: 2
    }}
  />
))(({ theme }) => ({}));

const getColumns = (selectedDoc, handleSelectDoc = () => {}) => {
  return [
    {
      id: "expander",
      Header: () => null,
      Cell: ({ cell, row }) =>
        cell.row.original.documents.length > 0 && (
          <span {...row.getToggleRowExpandedProps({})}>
            {row.isExpanded ? (
              <RemoveCircleOutline color="text-secondary" />
            ) : (
              <AddCircleOutline color="text-secondary" />
            )}
          </span>
        ),
      maxWidth: 8
    },

    {
      Header: "Order ID",
      accessor: "orderId"
    },
    {
      Header: "Order date",
      accessor: "orderDate",
      Cell: ({ value }) => {
        return dateTimeFormatter(value, appConstants.fullFormat);
      }
    },
    {
      Header: "All documents",
      accessor: "documents",
      Cell: ({ value, cell, row }) => {
        const dataRow = cell.row.original;
        return row.isExpanded ? (
          <Box sx={{ width: "100%" }}>
            {value?.map((item) => (
              <StyleGrid
                key={item?._id}
                container
                spacing={1}
                my={1}
                sx={
                  selectedDoc &&
                  selectedDoc.orderId === dataRow.id &&
                  selectedDoc.code === item?.name && { ...selectedCss }
                }
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleSelectDoc({
                    orderId: dataRow.id,
                    code: item?.name
                  });
                }}
              >
                <Grid item xs={2}>
                  <img
                    src={DOCUMENTS}
                    style={{ height: "27px", width: "27px" }}
                    alt="document"
                  />
                </Grid>
                <Grid
                  item
                  xs={10}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={0.5}
                >
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 400,
                      lineHeight: "20px"
                    }}
                  >
                    {item?.displayName}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 13,
                      fontWeight: 400,
                      lineHeight: "18px",
                      whiteSpace: "pre-line"
                    }}
                  >
                    {invoice_and_docs_labels.createdOn}:&nbsp;
                    {dateTimeFormatter(
                      item?.createdAt,
                      appConstants.fullFormat
                    )}
                  </Typography>
                </Grid>
              </StyleGrid>
            ))}
          </Box>
        ) : cell.row.original.documents.length > 0 ? (
          <Box
            {...row.getToggleRowExpandedProps({})}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <img
              src={DOCUMENTS}
              style={{ height: "27px", width: "27px" }}
              alt=""
            />
            <Typography
              sx={{
                ml: 2,
                fontSize: 13,
                fontWeight: 500,
                lineHeight: "18px"
              }}
            >
              +{value.length + " " + invoice_and_docs_labels.documents}
            </Typography>
          </Box>
        ) : null;
      }
    }
  ];
};

const getDocuments = (type) => {
  console.log("type", type);
  switch (type) {
    case "PackageNotes":
      return <PackingNotesDoc />;
    case "StorageOrder":
      return <PackingNotesDoc />;
    case "MRR":
      return <>MRR</>;
    case "PaymentLedger":
      return <LedgerDoc />;
    default:
      return <>Not Found</>;
  }
};

const getRows = (rows = []) => {
  return rows.map((row) => ({
    id: row?._id,
    orderId: row?.warehouseOrder?.code || row?.transportOrder.code,
    orderDate: row?.createdAt,
    documents: row?.documents
  }));
};

const InvoiceAndDocs = () => {
  const [selectedDoc, setSelectedDoc] = useState(null);
  const { showDrawer } = useDrawer();

  const { data, isFetched, hasNextPage, fetchNextPage } = useFetchDocuments();
  // const {} = useFetchInvoice();
  const { isLoading } = useGetOrderDetails("65c30ce3982a377ac7457570");
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, inView]);

  const handleSelectDoc = (doc) => {
    setSelectedDoc(doc);
    console.log(doc, "----------------");
    isMobileDevice &&
      showDrawer({
        title: invoice_and_docs_labels.documents,
        component: <StyleDocBox>{getDocuments(doc?.code)}</StyleDocBox>
      });
  };

  return (
    <Box
      sx={{
        padding: {
          xs: "0 16px 16px 16px",
          md: 0
        }
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={!isMobileDevice && selectedDoc?.orderId ? 6 : 12}>
          <CustomTable
            searchPlaceholderText={order_table_labels.searchPlaceholderText}
            columns={getColumns(selectedDoc, handleSelectDoc)}
            data={
              isFetched
                ? data.pages.flatMap((page) => getRows(page?.orders))
                : []
            }
            isLoading={!isFetched}
          />
        </Grid>
        {!isMobileDevice && selectedDoc?.orderId && (
          <Grid item xs={6}>
            <StyleDocBox>
              {!isMobileDevice && (
                <Box>
                  <IconButton
                    aria-label="close"
                    onClick={() => setSelectedDoc(null)}
                    sx={{
                      color: (theme) => theme.palette.text.secondary
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              )}
              {getDocuments(selectedDoc?.code)}
            </StyleDocBox>
          </Grid>
        )}
        {hasNextPage && (
          <Grid
            item
            xs={12}
            ref={ref}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <CircularProgress />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default InvoiceAndDocs;
