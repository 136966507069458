import { Grid, Typography } from "@mui/material";

import { isMobileDevice } from "../../../App";

const AdditionalDetailsCard = (props) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={3}>
        <Typography
          fontSize={16}
          lineHeight={"19.36px"}
          fontWeight={isMobileDevice ? 500 : 600}
          letterSpacing={"0.005em"}
        >
          {props.title}
        </Typography>
      </Grid>
      <Grid item xs={12} md={8} container spacing={2}>
        {props.services?.map(({ serviceLabel, icon: Icon }) => (
          <Grid
            item
            xs={12}
            key={serviceLabel}
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
          >
            {Icon && (
              <Icon fontSize="small" color="primary" sx={{ mr: "11px" }} />
            )}
            <Typography fontSize={14} lineHeight={"16px"}>
              {serviceLabel}
            </Typography>
          </Grid>
        ))}
        {props.products && (
          <Grid item xs={12} md={8}>
            <Typography fontSize={14} lineHeight={"20px"}>
              {props.products.join(", ")}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default AdditionalDetailsCard;
