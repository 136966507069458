import { getUpdateVendorFormData } from "../../purchase/services";
import { queryKeys } from "../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchUpdateVendorFormData = (vendorId) => {
  const queryUpdateVendorFormData = useQuery(
    [queryKeys.queryFetchUpdateVendorFormData,vendorId],
    () => getUpdateVendorFormData(vendorId),
    {
        enabled: Boolean(vendorId),
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchInterval: false,
      }
  );

  return queryUpdateVendorFormData;
};
