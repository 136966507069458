import { ListItemButton, Typography } from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom";

const MobileNavLinkItem = ({ path, icon, name }) => {
  const location = useLocation();
  const Icon = icon;
  const handleClearLocalStorage = (path) => {
    /**
     * remove currentSelectedSubMenuLink from localStorage on click of nav items as an edge case for desktop view
     */
    localStorage.removeItem("currentSelectedSubMenuLink");
  };

  return (
    <ListItemButton
      component={RouterLink}
      to={path}
      selected={location.pathname === path}
      onClick={handleClearLocalStorage}
      sx={{
        color: location.pathname === path ? "#FFFFFF" : "#EFF2F6",
        "&:before": {
          animation:
            location.pathname === path
              ? `lineAnimation 0.3s ease-in-out forwards`
              : "none"
        }
      }}
    >
      <Icon sx={{ color: "inherit", fontSize: 20 }} />
      <Typography>{name}</Typography>
    </ListItemButton>
  );
};

export default MobileNavLinkItem;
