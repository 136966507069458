import { createStore } from "../../../sales/services";
import { useMutation } from "@tanstack/react-query";

export const useCreateShipToAddress = () => {
  const createStoreAddress = useMutation(
    ({ data, customerId, billingId }) =>
      createStore(data, customerId, billingId)
  );
  return createStoreAddress;
};
