import React, { useEffect, useRef, useState, useCallback } from "react";
import {
  FormControl,
  FormHelperText,
  InputBase,
  InputLabel,
  List,
  ListItem,
  Paper,
  Popper,
  Typography
} from "@mui/material";
import { Controller } from "react-hook-form";

const SuggestionInput = ({
  label,
  placeholder,
  helperText,
  name,
  control,
  disabled = false,
  type = "text",
  endIcon: EndIcon,
  startIcon,
  multiline = false,
  mandatoryField = false,
  sx = {},
  onlyCaps = false,
  menuItems = []
}) => {
  const inputRef = useRef(null);
  const [filteredItems, setFilteredItems] = useState(menuItems);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [listItemClicked, setListItemClicked] = useState(false);

  useEffect(() => {
    if (type === "number") {
      inputRef.current.addEventListener("wheel", (event) => {
        event.preventDefault();
      });
    }
  }, [type]);

  const handleChange = useCallback(
    (event, field) => {
      let value = event.target.value;

      if (onlyCaps) {
        value = value.toUpperCase();
      }

      // Validate and restrict the input value
      if (type === "number") {
        if (value === "" || (Number(value) >= 0 && Number(value) <= 100)) {
          field.onChange(value);
        }
      } else {
        field.onChange(value);
      }

      setFilteredItems(
        menuItems.filter((item) =>
          `${item}`.toLowerCase().includes(value.toLowerCase())
        )
      );
      setIsMenuOpen(true); // Ensure the menu stays open on input change
    },
    [menuItems, onlyCaps, type]
  );

  const handleItemClick = useCallback(
    (item, field) => {
      field.onChange(item);
      setFilteredItems(menuItems); // Reset the filtered items to the original menuItems
      setIsMenuOpen(false); // Close the menu after selection
      setAnchorEl(null); // Reset the anchor element
      setListItemClicked(true);
    },
    [menuItems]
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={""}
      render={({ field, fieldState: { error } }) => (
        <FormControl variant="standard" fullWidth disabled={disabled}>
          {label && (
            <InputLabel shrink htmlFor={name}>
              {label}
              {mandatoryField && <span style={{ color: "#F05454" }}> *</span>}
            </InputLabel>
          )}
          <InputBase
            {...field}
            id={name}
            ref={inputRef}
            placeholder={placeholder}
            error={!!error}
            type={type}
            startAdornment={startIcon}
            multiline={multiline}
            rows={1}
            sx={sx}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setAnchorEl(e.currentTarget);
              setIsMenuOpen(true); // Open menu on click
            }}
            onChange={(e) => {
              handleChange(e, field);
              setAnchorEl(e.currentTarget);
            }}
            onBlur={() => {
              if (!listItemClicked) {
                setIsMenuOpen(false);
              }
              setListItemClicked(false);
            }}
            endAdornment={
              EndIcon && (
                <EndIcon
                  fontSize="15"
                  sx={{ mr: "12px", cursor: "pointer", color: "#607088" }}
                  onClick={(e) => {
                    inputRef.current.click();
                  }}
                />
              )
            }
          />
          {helperText && (
            <FormHelperText sx={{ letterSpacing: "0.1em" }}>
              {helperText}
            </FormHelperText>
          )}
          {error && <FormHelperText error>{error.message}</FormHelperText>}
          {isMenuOpen && filteredItems.length > 0 && (
            <Popper
              open={isMenuOpen}
              anchorEl={anchorEl}
              placement="bottom-start"
              style={{ zIndex: 1300 }}
            >
              <Paper sx={{ minWidth: 90, p: "8px 0 0 8px" }}>
                <List
                  sx={{
                    maxHeight: 200,
                    overflow: "auto",
                    ...sx
                  }}
                >
                  {filteredItems.map((item, index) => (
                    <ListItem
                      key={index}
                      onMouseDown={(e) => e.preventDefault()} // Prevent blur on mousedown
                      onClick={() => handleItemClick(item, field)}
                      button
                      sx={{ borderRadius: "8px" }}
                    >
                      <Typography fontSize="14px">{item}</Typography>
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Popper>
          )}
        </FormControl>
      )}
    />
  );
};

export default SuggestionInput;
