export const labelKeys = {
  purchaseOrderNo: "Purchase Order No",
  salesOrderNo: "Sales Order No",
  invoiceNo: "Invoice No",
  creditNoteNo: "Credit Note No",
  quoteNo: "Quote No.",
  SRNo : "SR No",
  date:"date",
  cnDate: "CN date",
  debitNoteNo: "Debit Note No",
  dnDate: "DN date",
  orderDate: "Order date",
  invoiceDate: "Invoice date",
  edd: "EDD",
  deliveryBy: "Delivery by",
  purchaseReturnNo: "Purchase Return No",
  purchaseReturnDate: "Purchase Return date",
  stockTransferOrderNo: "Stock Transfer Order No",
  stockAdjustmentNo: "Stock Adjustment No"
};
