import * as yup from "yup";

import { Box, Button, Typography } from "@mui/material";
import { Assets_label, error_msg } from "../../../constants";
import AddTrucks from "../../../assets/icons/AddTrucks.svg";
import CustomTable from "../../../shared/customTable";
import { useFieldArray, useForm } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";
import TextInput from "../../../shared/formElements/TextInput";
import DropDown from "../../../shared/formElements/DropDown";
import { AddOutlined } from "@mui/icons-material";
import { useFetchAssetTransport } from "../../../query-hooks/TransportAssets/useFetchAssetTransport";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFetchCreateTransportFormData } from "../../../query-hooks/TransportAssets/useFetchCreateTransportFormData";
import { dropdownOptions } from "../../../shared/utils";
import { useCreateTransport } from "../../../query-hooks/TransportAssets/useCreateTransport";
import { enqueueSnackbar } from "notistack";
import { useUpdateTransport } from "../../../query-hooks/TransportAssets/useUpdateTransport";
import { LoadingButton } from "@mui/lab";
import { TitleText } from "../../pages/Assets";

const schema = yup.object({
  trucks: yup.array().of(
    yup.object().shape({
      truckRegNo: yup.string().required(error_msg.required),
      truckMake: yup.string().required(error_msg.required),
      truckType: yup.string().required(error_msg.required)
    })
  )
});

const TrucksTable = () => {
  const [vehicleIndex, setVehicleIndex] = useState(null);
  const { control, handleSubmit, reset, setValue } = useForm({
    resolver: yupResolver(schema)
  });

  const { fields, append, remove } = useFieldArray({
    name: "trucks",
    control
  });

  const {
    data: vehiclesData,
    isFetching: isVehicleFetching,
    refetch: refetchVehicles
  } = useFetchAssetTransport();

  const { data: formData, isFetching: isFormDataFetching } =
    useFetchCreateTransportFormData();

  const {
    mutate: CreateTransport,
    isLoading: isCreating,
    isError: isTransportError,
    error: transportError
  } = useCreateTransport();

  const {
    mutate: UpdateTransport,
    isLoading: isUpdating,
    isError: isUpdateError,
    error: updateError
  } = useUpdateTransport();

  const addExistingTruck = useCallback(
    (data) => {
      data.forEach((item, index) => {
        append({
          sNo: index + 1,
          vehicleId: item._id,
          truckRegNo: item.regNo,
          truckMake: item.make,
          truckType: item.vehicleType,
          inputDisable: true
        });
      });
    },
    [append]
  );

  const addNewVehicle = () => {
    append({
      sNo: fields.length + 1,
      truckRegNo: "",
      truckMake: "",
      truckType: ""
    });
  };

  useEffect(() => {
    if (!isVehicleFetching) {
      addExistingTruck(vehiclesData?.vehicles);
    }
  }, [addExistingTruck, isVehicleFetching, vehiclesData?.vehicles]);

  const onAddVehicle = (data) => {
    const vehicle = data.trucks[fields.length - 1];
    CreateTransport(
      {
        data: {
          regNo: vehicle.truckRegNo,
          make: vehicle.truckMake,
          vehicleType: vehicle.truckType
        }
      },
      {
        onSuccess: () => {
          reset({
            trucks: []
          });
          enqueueSnackbar(`Vehicle Added!`, {
            variant: "success"
          });
          refetchVehicles();
        }
      }
    );
  };

  const onUpdateVehicle = (data) => {
    const updatedVehicle = data?.trucks[vehicleIndex];
    UpdateTransport(
      {
        data: {
          regNo: updatedVehicle.truckRegNo,
          make: updatedVehicle.truckMake,
          vehicleType: updatedVehicle.truckType
        },
        transportId: updatedVehicle.vehicleId
      },
      {
        onSuccess: () => {
          reset({
            trucks: []
          });
          enqueueSnackbar(`Vehicle Updated!`, {
            variant: "success"
          });
          refetchVehicles();
        }
      }
    );
  };

  useEffect(() => {
    if (vehicleIndex === 0 || vehicleIndex) {
      handleSubmit(onUpdateVehicle)();
      setVehicleIndex(null);
    } // eslint-disable-next-line
  }, [vehicleIndex]);

  const toggleEdit = (index) => {
    const updatedFields = [...fields];
    updatedFields[index].inputDisable = !updatedFields[index].inputDisable;
    setValue("trucks", updatedFields);
  };

  const getColumns = () => {
    return [
      {
        Header: "S.No",
        accessor: "sNo",
        width: 80,
        Cell: ({ value }) => {
          return (
            value && (
              <Typography fontSize={14} fontWeight={600} lineHeight={"17px"}>
                {value}
              </Typography>
            )
          );
        }
      },
      {
        Header: "Truck Reg. No",
        accessor: "truckRegNo",
        width: 180
      },
      {
        Header: "Truck Make",
        accessor: "truckMake",
        width: 190
      },
      {
        Header: "Truck Type",
        accessor: "truckType",
        width: 190
      },
      {
        Header: "Action",
        accessor: "actions",
        width: 187,
        Cell: ({ row, value }) => {
          return row.original.sNo > vehiclesData?.vehicles.length ? (
            <Box display={"flex"} gap={1}>
              <Button
                component={LoadingButton}
                loading={isCreating}
                variant="contained"
                onClick={handleSubmit(onAddVehicle)}
                sx={{ height: "30px", width: "45px" }}
              >
                {"save"}
              </Button>
              <Button
                variant="outlined"
                onClick={() => remove(fields.length - 1)}
                sx={{ height: "30px", width: "45px" }}
              >
                {"Cancel"}
              </Button>
            </Box>
          ) : (
            value
          );
        }
      }
    ];
  };

  const getRows = (rows = []) => {
    return rows.map((item, index) => {
      return {
        sNo: item.sNo,
        truckRegNo: (
          <TextInput
            control={control}
            name={`trucks.${index}.truckRegNo`}
            placeholder={`eg: KA07RT2345`}
            disabled={
              index === vehiclesData?.vehicles.length
                ? false
                : item.inputDisable
            }
          />
        ),
        truckMake: (
          <TextInput
            control={control}
            name={`trucks.${index}.truckMake`}
            placeholder={`eg: TATA`}
            disabled={
              index === vehiclesData?.vehicles.length
                ? false
                : item.inputDisable
            }
          />
        ),
        truckType: (
          <DropDown
            control={control}
            name={`trucks.${index}.truckType`}
            placeholder={`eg: Drop`}
            disabled={
              index === vehiclesData?.vehicles.length
                ? false
                : item.inputDisable
            }
            isLoading={isFormDataFetching}
            options={dropdownOptions(formData?.vehicleTypes)}
          />
        ),
        actions: vehiclesData?.vehicles?.length > 0 &&
          vehiclesData?.vehicles?.length === fields.length && (
            <Box display={"flex"} gap={1}>
              {item.inputDisable ? (
                <Button
                  variant="outlined"
                  sx={{ height: "30px", width: "45px" }}
                  onClick={() => toggleEdit(index)}
                >
                  {"Edit"}
                </Button>
              ) : (
                <>
                  <Button
                    variant="outlined"
                    sx={{ height: "30px", width: "45px" }}
                    onClick={() => toggleEdit(index)}
                  >
                    {"Cancel"}
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ height: "30px", width: "45px" }}
                    component={LoadingButton}
                    loading={isUpdating}
                    onClick={() => setVehicleIndex(index)}
                  >
                    {"Save"}
                  </Button>
                </>
              )}
            </Box>
          )
      };
    });
  };

  useEffect(() => {
    if (isTransportError)
      enqueueSnackbar(transportError?.response?.data?.message, {
        variant: "error"
      });
    if (isUpdateError) {
      setVehicleIndex(null);
      enqueueSnackbar(updateError?.response?.data?.message, {
        variant: "error"
      });
    }
  }, [
    transportError?.response?.data?.message,
    updateError?.response?.data?.message,
    isTransportError,
    isUpdateError
  ]);

  return (
    <Box display={"flex"} flexDirection={"column"} gap={2}>
      <Box
        display={"flex"}
        gap={2}
        justifyContent={"left"}
        alignItems={"flex-end"}
      >
        <img src={AddTrucks} alt="Trucks" />
        <TitleText>{Assets_label.addTrucks}</TitleText>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        border={"0.6px solid #027AFF"}
        borderRadius={0}
        padding={"20px 10px"}
      >
        <CustomTable
          columns={getColumns()}
          data={getRows(fields)}
          isLoading={isVehicleFetching}
        />
        {fields?.every((obj) => obj?.inputDisable !== false) &&
          vehiclesData?.vehicles?.length === fields.length && (
            <Box>
              <Button variant="link" disableRipple onClick={addNewVehicle}>
                <AddOutlined />
                {Assets_label.addTrucks}
              </Button>
            </Box>
          )}
      </Box>
    </Box>
  );
};

export default TrucksTable;
