import { useCallback, useEffect, useState } from "react";

import Storage from "../utils/Storage";
import { StorageKeys } from "../../constants/storage-keys";
import { cartServiceKeys } from "../../constants/formKeys";

export const useCart = () => {
  const localCart = Storage.getItem(StorageKeys.CART_ITEM);
  const [cart, setCart] = useState({
    [cartServiceKeys.warehouse]: localCart?.[cartServiceKeys.warehouse] || null,
    [cartServiceKeys.transport]: localCart?.[cartServiceKeys.transport] || null
  });

  const onAddToCart = useCallback((service, item) => {
    setCart((prevCart) => ({ ...prevCart, [service]: item }));
  }, []);

  const onRemoveFromCart = (service) => {
    setCart((prevCart) => ({ ...prevCart, [service]: null }));
  };

  useEffect(() => {
    Storage.setItem(StorageKeys.CART_ITEM, cart);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(cart)]);

  return { cart, onAddToCart, onRemoveFromCart };
};
