import React, { useRef, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import ContactUsSection from "../components/ContactUsSection";
import Footer from "../components/Footer";
import { contactUsFormKeys } from "../../constants/formKeys";
import { home_labels, labels } from "../../constants";
import { useForm } from "react-hook-form";
import { isMobile } from "react-device-detect";
import PricingImage from "../../assets/Pricing.webp";
import BasicPlan from "../../assets/Basic_subscription.webp";
import StandardPlan from "../../assets/Standard_Subscription.webp";
import EnterPricePlan from "../../assets/Enterprise_subscription.webp";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination } from "swiper/modules";

const pricingPlans = [
  {
    image: BasicPlan,
    id: "Basic"
  },
  {
    image: StandardPlan,
    id: "Standard"
  },
  {
    image: EnterPricePlan,
    id: "EnterPrice"
  }
];

const ImageComponent = ({ children }) => {
  return (
    <Box
      display={"flex"}
      sx={{
        width: "100%",
        height: "100%",
        position: "relative",
        overflow: "hidden"
      }}
      justifyContent={"center"}
    >
      {children}
    </Box>
  );
};

const Pricing = () => {
  const swiperRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const targetElement = containerRef.current; // Store current value of containerRef

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && entry.intersectionRatio >= 0.4) {
            // When 40% or more of the swiper is visible, start autoplay
            if (swiperRef.current) swiperRef.current.autoplay.start();
          } else {
            // When less than 40% is visible, stop autoplay
            if (swiperRef.current) swiperRef.current.autoplay.stop();
          }
        });
      },
      {
        threshold: 0.4 // Trigger when 40% of the Swiper is in view
      }
    );

    if (targetElement) {
      observer.observe(targetElement);
    }

    return () => {
      if (targetElement) {
        observer.unobserve(targetElement); // Clean up the observer with the stored targetElement
      }
    };
  }, []);

  const { control } = useForm({
    defaultValues: {
      [contactUsFormKeys.name]: "",
      [contactUsFormKeys.number]: "",
      [contactUsFormKeys.email]: "",
      [contactUsFormKeys.subject]: "",
      [contactUsFormKeys.description]: ""
    }
  });

  useEffect(() => {
    window.scroll({
      left: 0,
      top: 0,
      behavior: "smooth"
    });
  }, []);

  return (
    <>
      <Box
        ref={containerRef}
        display={"flex"}
        flexDirection={"column"}
        sx={{
          padding: { xs: "30px 25px 0px 25px", md: "30px 70px 0px 80px" },
          gap: 2
        }}
      >
        <Typography
          sx={{
            color: "text.secondary",
            fontSize: { xs: "14px", md: "22px" },
            lineHeight: { xs: "21px", md: "33px" },
            letterSpacing: "-1%",
            textAlign: "justify"
          }}
          fontWeight={400}
        >
          {labels.pricingInfo}
        </Typography>
        {isMobile ? (
          <Box>
            <Swiper
              slidesPerView={isMobile ? 1 : 3}
              spaceBetween={30}
              speed={1000}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true
              }}
              loop={true}
              pagination={{
                clickable: true,
                
              }}
              modules={[Autoplay, Pagination]}
              className="mySwiper-5"
              onSwiper={(swiper) => (swiperRef.current = swiper)}
            >
              {pricingPlans.map((plan) => (
                <SwiperSlide key={plan.id}>
                  <Grid item xs={12}>
                    <ImageComponent>
                      <img
                        src={plan.image}
                        alt={plan.id}
                        style={{
                          width: 260,
                          height: 376,
                          objectFit: "contain" // Ensures the entire image fits within the box
                        }}
                      />
                    </ImageComponent>
                  </Grid>
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
        ) : (
          <Box
            display={"flex"}
            padding={"0px 90px"}
            sx={{
              width: "100%",
              position: "relative",
              overflow: "hidden"
            }}
          >
            <img
              src={PricingImage}
              alt={"Pricing"}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain" // Ensures the entire image fits within the box
              }}
            />
          </Box>
        )}
        <Typography
          sx={{
            color: "text.secondary",
            fontSize: { xs: "14px", md: "22px" },
            lineHeight: { xs: "21px", md: "33px" },
            letterSpacing: "-1%",
            textAlign: "justify",
            paddingTop: { xs: "10px", md: "20px" },
            mb: { xs: 0, md: -4 }
          }}
          fontWeight={700}
        >
          {labels.contactUsforDemoText}
        </Typography>
      </Box>
      <ContactUsSection
        control={control}
        title={home_labels.partnerWithUsTitleText}
      />
      <Footer />
    </>
  );
};

export default Pricing;
