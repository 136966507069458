import { Box, Button, Grid, Typography, styled } from "@mui/material";

import { AmountGrid } from "../CompleteOrder/CostBreakup";
import { Link } from "react-router-dom";
import Storage from "../../../shared/utils/Storage";
import { StorageKeys } from "../../../constants/storage-keys";
import { StyledDivider } from "./DetailedWarehouse";
import appRoutes from "../../../constants/appRoutes";
import { appServiceKeys } from "../../../constants/formKeys";
import { currencyFormatter } from "../../../shared/utils";
import { labels } from "../../../constants";

const StyledOrderButton = styled((props) => (
  <Button
    {...props}
    fullWidth
    sx={{
      paddingTop: "22.5px",
      paddingBottom: "22.5px",
      textTransform: "uppercase",
      fontSize: 16,
      fontWeight: 500,
      lineHeight: "19.36px",
      letterSpacing: "0.005em",
      "&:last-child": {
        marginTop: "2px"
      }
    }}
  />
))(() => ({}));

export const StyledGridItem = styled((props) => (
  <Grid
    {...props}
    item
    xs={12}
    display={"flex"}
    justifyContent={"space-between"}
    alignItems={"center"}
    marginTop={"2px"}
  />
))(() => ({}));

export const StyledLabelTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  color: theme.palette.text.secondary,
  lineHeight: "20px",
  fontSize: 14
}));

export const StyledValueTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  color: theme.palette.text.primary,
  lineHeight: "19.36px",
  fontSize: 16,
  letterSpacing: "0.005em"
}));

export const StyledOrderBox = styled(Box)(({ theme }) => ({
  border: "0.4px solid " + theme.palette.text.disabled,
  borderRadius: 8,
  padding: "40px 24px 24px 24px"
}));

const OrderBox = ({
  totalAmount,
  bookButtonLabel,
  onSubmit,
  storageCost,
  additionalCost,
  gstAt,
  subtotal,
  storageType,
  rate,
  gstRegime,
  closeDrawer,
  serviceType = appServiceKeys.warehouse
}) => {
  const localFormData = Storage.getItem(StorageKeys.SEARCH_FORM_DATA);

  return (
    <StyledOrderBox sx={{ border: closeDrawer && "none" }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography fontSize={14} lineHeight={"20px"} color="#111111">
            {labels.totalAmountText}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            fontSize={24}
            fontWeight={900}
            lineHeight={"29.05px"}
            color="text.primary"
          >
            {`${currencyFormatter(parseFloat(totalAmount).toFixed(2))}`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <StyledDivider
            sx={{
              mt: 1,
              mb: 1
            }}
          />
        </Grid>
        <AmountGrid
          quantityLabel={
            serviceType === appServiceKeys.warehouse
              ? labels.quantityPallets
              : labels.distanceLabelText
          }
          quantityUnit={storageType}
          quantity={
            serviceType === appServiceKeys.warehouse
              ? localFormData?.pallets
              : parseFloat(localFormData?.distance).toFixed(2)
          }
          storageLabel={
            serviceType === appServiceKeys.warehouse
              ? labels.storageCost
              : labels.transportCost
          }
          storageUnit={storageType}
          storageRate={rate}
          storageCost={storageCost}
          additionalCost={additionalCost}
          subtotal={subtotal}
          gstRegime={gstRegime}
          gstAmount={gstAt}
        />
        {closeDrawer ? (
          <Grid item xs={12}>
            <Button
              variant="soft"
              fullWidth
              sx={{ height: 48, mt: 3 }}
              onClick={closeDrawer}
            >
              {labels.closeButton}
            </Button>
          </Grid>
        ) : (
          <>
            <Grid item xs={12} marginTop={3}>
              <StyledOrderButton variant="contained" onClick={onSubmit}>
                {bookButtonLabel || labels.bookOrderButtonLabelText}
              </StyledOrderButton>
            </Grid>
            <Grid item xs={12}>
              <StyledOrderButton
                variant="soft"
                component={Link}
                to={appRoutes.explore.listings}
              >
                {labels.sendRFQButtonSingleLabelText}
              </StyledOrderButton>
            </Grid>
          </>
        )}
      </Grid>
    </StyledOrderBox>
  );
};

export default OrderBox;
