import { Box, IconButton, Typography } from "@mui/material";

import { BoldText } from "../../inventory/pages/Performance";
import CustomTable from "../../shared/customTable";
import { CustomTooltip } from "../../shared/theme/globalTheme";
import Filter from "../../purchase/components/Filter";
import Ledger from "../../assets/Ledger.webp";
import { allocation_keys } from "../../constants/formKeys";
import { allocation_labels } from "../../constants";
import moment from "moment";
import { useForm } from "react-hook-form";

const dummy_data = [
  {
    docNo: "QO - 12345",
    date: "2024-02-10T00:00:00.000Z",
    customer: "MS Industries",
    preparedBy: "Raju",
    quoteAmt: 120000,
    status: "Converted",
    changeDate: "2024-02-20T00:00:00.000Z",
    salesOrder: "SO - 12345",
    orderAmt: 110000
  },
  {
    docNo: "QO - 12345",
    date: "2024-02-10T00:00:00.000Z",
    customer: "MS Industries",
    preparedBy: "Raju",
    quoteAmt: 120000,
    status: "Quote Sent",
    changeDate: "2024-02-20T00:00:00.000Z"
  },
  {
    docNo: "QO - 12345",
    date: "2024-10-02T00:00:00.000Z",
    customer: "MS Industries",
    preparedBy: "Raju",
    quoteAmt: 120000,
    status: "Lost"
  }
];

const getColumns = () => {
  return [
    {
      Header: "Doc. No",
      accessor: "docNo",
      width: 143,
      Cell: ({ value }) => {
        return (
          <BoldText
            sx={{
              fontWeight: 400,
              textDecoration: "underline"
            }}
          >
            {value}
          </BoldText>
        );
      }
    },
    {
      Header: "Date",
      accessor: "date",
      width: 103
    },
    {
      Header: "Customer",
      accessor: "customer",
      width: 136
    },
    {
      Header: "SKU",
      accessor: "SKU",
      width: 78
    },
    {
      Header: "QTY",
      accessor: "QTY",
      width: 78
    }
  ];
};

const getRows = (rows = []) => {
  return rows.map((item, index) => {
    return {
      docNo: item.docNo,
      date: moment(item.date).format("DD-MM-YYYY"),
      customer: item.customer
    };
  });
};

const Allocation = () => {
  const { control, reset } = useForm();
  return (
    <>
      <Box
        sx={{ display: { xs: "none", lg: "flex" } }}
        flexDirection={"column"}
        gap={2}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={2}
          sx={{
            position: "sticky",
            top: "64px",
            zIndex: 1000,
            background: "#fff",
            padding: "16px 0"
          }}
        >
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 700,
              lineHeight: "24px",
              color: "#FF8A8A"
            }}
          >
            *Feature Coming Soon*
          </Typography>
          <Box>
            <Filter
              control={control}
              onClear={() => reset()}
              filters={[
                {
                  type: "DropDown",
                  name: allocation_keys.docType,
                  label: allocation_labels.docType,
                  options: [],
                  filterCount: 15
                },
                {
                  type: "DateInput",
                  name: allocation_keys.date,
                  label: allocation_labels.date,
                  placeholder: "Pick Dates",
                  selectsRange: true
                },
                {
                  type: "DropDown",
                  name: allocation_keys.customer,
                  label: allocation_labels.customer,
                  options: [],
                  filterCount: 3
                },
                {
                  type: "DropDown",
                  name: allocation_keys.salesman,
                  label: allocation_labels.salesman,
                  options: [],
                  filterCount: 45
                },
                {
                  type: "CheckboxSelect",
                  name: allocation_keys.warehouses,
                  label: allocation_labels.warehouses,
                  options: [
                    {
                      name: "WH - 1",
                      displayName: "WH - 1"
                    },
                    {
                      name: "WH - 2",
                      displayName: "WH - 2"
                    }
                  ]
                }
              ]}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              border: "0.6px solid #2773FF",
              borderRadius: "8px",
              padding: "15px 0px 15px 30px",
              justifyContent: "start",
              alignItems: "center"
            }}
          >
            <CustomTooltip title={"Ledger"} placement="top">
              <IconButton>
                <img
                  src={Ledger}
                  className="pull-right cursor-field"
                  alt={"value"}
                />
              </IconButton>
            </CustomTooltip>
          </Box>
        </Box>
        <CustomTable
          columns={getColumns()}
          data={getRows(dummy_data)}
          // mobileComponent={MyOrdersMobileCard}
          // isLoading={!isPastFetched}
        />
      </Box>
      <Box
        sx={{
          display: { xs: "flex", lg: "none" },
          justifyContent: "center",
          pt: "20px"
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 700,
            lineHeight: "24px",
            color: "#FF8A8A"
          }}
        >
          *Feature Coming Soon*
        </Typography>
      </Box>
    </>
  );
};

export default Allocation;
