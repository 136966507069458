import { CustomTabs } from "../../shared/customTab/CustomTabs";
import { Box, Button, Grid, Typography } from "@mui/material";
import HistoryTable from "../../purchase/components/HistoryTable";
import {
  OtherDetailsComp,
  shipToAddressData,
  vendorAddressData
} from "../../purchase/pages/CreateOrder";
import OrderReport from "../../purchase/components/OrderReport";
import moment from "moment";
import { addressData } from "../../purchase/pages/CreateStockOrder";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import MobileOrderReport from "../../purchase/components/MobileOrderReport";
import { currencyOnlyFormatter } from "../../shared/utils";
import { isMobileDevice } from "../../App";

const OrderBillingComponent = ({
  orderType,
  activeTab,
  setActiveTab,
  tableData,
  orderHistories,
  orderCode
}) => {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: orderCode
  });

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  const getOrderHistory = (item) => {
    switch (orderType) {
      case "Purchase-Order":
        return {
          name: "Purchase Order",
          actions: [],
          selection: false,
          buyerTitle: "Seller",
          shippingTitle: "Shipto",
          sellerTitle: "Buyer",
          orderInfo: {
            purchaseOrderNo: item?.code,
            orderDate: moment(item?.orderDate).format("DD-MM-YYYY"),
            deliveryBy: moment(item?.deliveryDate).format("DD-MM-YYYY")
          },
          sellerInfo: vendorAddressData(item?.clientData),
          buyerInfo: vendorAddressData(item?.vendorData),
          shipInfo: shipToAddressData(item?.clientData?.warehouse)
        };
      case "Purchase-Return":
        return {
          name: "Purchase Returns",
          actions: [],
          selection: false,
          buyerTitle: "Seller",
          shippingTitle: "Shipping from",
          sellerTitle: "Buyer",
          orderInfo: {
            purchaseReturnNo: item?.code,
            purchaseReturnDate: moment(item?.orderDate).format("DD-MM-YYYY"),
            purchaseOrderNo: item?.relatedOrder?.code,
            orderDate: moment(item?.relatedOrder?.orderDate).format(
              "DD-MM-YYYY"
            )
          },
          sellerInfo: vendorAddressData(item?.clientData),
          buyerInfo: vendorAddressData(item?.vendorData),
          shipInfo: shipToAddressData(item?.clientData?.warehouse)
        };
      case "Sale-Order":
        return {
          name: "Sales Order",
          actions: [],
          selection: false,
          buyerTitle: "Seller",
          shippingTitle: "Shipto",
          sellerTitle: "Buyer",
          orderInfo: {
            salesOrderNo: item?.code,
            orderDate: moment(item?.orderDate).format("DD-MM-YYYY"),
            deliveryBy: moment(item?.deliveryDate).format("DD-MM-YYYY")
          },
          sellerInfo: vendorAddressData(item?.vendorData),
          buyerInfo: vendorAddressData(item?.clientData),
          shipInfo: shipToAddressData(item?.vendorData?.warehouse)
        };
      case "Sale-Return":
        return {
          name: "Sales Returns",
          actions: [],
          selection: false,
          buyerTitle: "Buyer",
          shippingTitle: "Receiving Warehouse",
          sellerTitle: "Seller",
          orderInfo: {
            SRNo: item?.code,
            date: moment(item?.orderDate).format("DD-MM-YYYY"),
            salesOrderNo: item?.relatedOrder?.code,
            orderDate: moment(item?.relatedOrder?.orderDate).format(
              "DD-MM-YYYY"
            )
          },
          sellerInfo: vendorAddressData(item?.clientData),
          buyerInfo: vendorAddressData(item?.vendorData),
          shipInfo: shipToAddressData(item?.clientData?.warehouse)
        };
      case "Stock-Transfer":
        return {
          name: "Stock Transfer Order",
          actions: [],
          selection: false,
          shippingTitle: "Receiving Warehouse",
          buyerTitle: "Origin Warehouse",
          orderInfo: {
            stockTransferOrderNo: item?.code,
            orderDate: moment(item?.orderDate).format("DD-MM-YYYY")
          },
          buyerInfo: addressData(item?.clientData?.warehouse),
          shipInfo: addressData(item?.vendorData?.warehouse)
        };
      default:
        return {};
    }
  };

  const getOrderHistoryTable = (item, isPrint = false) => {
    switch (orderType) {
      case "Purchase-Order":
        return (
          <HistoryTable
            isPrint={isPrint}
            data={item?.items ?? []}
            orderType={"PurchaseOrder"}
            costBreakUp={{
              subtotal: item?.subTotalAmount,
              cgst: item?.taxes?.length > 1 ? item?.taxes[0]?.totalAmount : 0,
              sgst: item?.taxes?.length > 1 ? item?.taxes[1]?.totalAmount : 0,
              igst: item?.taxes?.length === 1 ? item?.taxes[0]?.totalAmount : 0,
              total: item?.totalAmount
            }}
            gstType={item?.taxes ? item?.taxes?.length > 1 : false}
            tnc={item?.tnc ?? ""}
            paymentTerms={[item?.advancePercent, item?.dueDays]}
            shipping={item?.shipping?.displayName}
            reasonOfReturn={""}
            preparedBy={`${item?.preparedBy?.firstName} ${item?.preparedBy?.lastName}`}
          />
        );
      case "Purchase-Return":
        return (
          <HistoryTable
            isPrint={isPrint}
            data={item?.items}
            orderType={"PurchaseReturn"}
            costBreakUp={{
              subtotal: item?.subTotalAmount,
              cgst: item?.taxes?.length > 1 ? item?.taxes[0]?.totalAmount : 0,
              sgst: item?.taxes?.length > 1 ? item?.taxes[1]?.totalAmount : 0,
              igst: item?.taxes?.length === 1 ? item?.taxes[0]?.totalAmount : 0,
              total: item?.totalAmount
            }}
            gstType={item?.taxes ? item?.taxes?.length > 1 : false}
            tnc={item?.tnc ?? ""}
            paymentTerms={[]}
            shipping={""}
            reasonOfReturn={item?.returnReason?.displayName}
            preparedBy={`${item?.preparedBy?.firstName} ${item?.preparedBy?.lastName}`}
          />
        );
      case "Sale-Order":
        return (
          <HistoryTable
            isPrint={isPrint}
            data={item?.items ?? []}
            orderType={"SalesOrder"}
            costBreakUp={{
              subtotal: item?.subTotalAmount,
              cgst: item?.taxes?.length > 1 ? item?.taxes[0]?.totalAmount : 0,
              sgst: item?.taxes?.length > 1 ? item?.taxes[1]?.totalAmount : 0,
              igst: item?.taxes?.length === 1 ? item?.taxes[0]?.totalAmount : 0,
              total: item?.totalAmount
            }}
            gstType={item?.taxes ? item?.taxes?.length > 1 : false}
            tnc={item?.tnc ?? ""}
            paymentTerms={[item?.advancePercent, item?.dueDays]}
            shipping={item?.shipping?.displayName}
            reasonOfReturn={""}
            preparedBy={`${item?.preparedBy?.firstName} ${item?.preparedBy?.lastName}`}
          />
        );
      case "Sale-Return":
        return (
          <HistoryTable
            isPrint={isPrint}
            data={item?.items}
            orderType={"SalesReturns"}
            costBreakUp={{
              subtotal: item?.subTotalAmount,
              cgst: item?.taxes?.length > 1 ? item?.taxes[0]?.totalAmount : 0,
              sgst: item?.taxes?.length > 1 ? item?.taxes[1]?.totalAmount : 0,
              igst: item?.taxes?.length === 1 ? item?.taxes[0]?.totalAmount : 0,
              total: item?.totalAmount
            }}
            gstType={item?.taxes ? item?.taxes?.length > 1 : false}
            tnc={item?.tnc ?? ""}
            paymentTerms={[]}
            shipping={""}
            reasonOfReturn={item?.returnReason?.displayName}
            preparedBy={`${item?.preparedBy?.firstName} ${item?.preparedBy?.lastName}`}
          />
        );
      case "Stock-Transfer":
        return (
          <HistoryTable
            isPrint={isPrint}
            data={item?.items}
            orderType={"StockTransfer"}
            costBreakUp={{}}
            gstType={false}
            tnc={item?.tnc ?? ""}
            paymentTerms={[]}
            shipping={""}
            reasonOfReturn={""}
            preparedBy={`${item?.preparedBy?.firstName} ${item?.preparedBy?.lastName}`}
          />
        );
      default:
        return <>Not Found</>;
    }
  };

  const orderHistoriesAllReport = (item) => {
    const orderData = getOrderHistory(item);

    const otherDetails = [
      { label: `Shipping :`, value: item?.shipping?.displayName },
      {
        label: `Payment Terms :`,
        value: `${item?.advancePercent}% Advance. Balance Due ${item?.dueDays} days from receipt`
      }
    ];

    const otherDetailsComp = (
      <Grid container rowSpacing={2}>
        {otherDetails?.map((item, index) => (
          <OtherDetailsComp key={index} label={item.label} value={item.value} />
        ))}
      </Grid>
    );

    const otherDetailsReturn = (
      <Grid container rowSpacing={2}>
        <OtherDetailsComp
          label={"Return Reason :"}
          value={item?.returnReason?.displayName}
        />
      </Grid>
    );

    return (
      <>
        <Box
          sx={{ display: { xs: "none", md: "flex" } }}
          flexDirection={"column"}
          gap={1}
        >
          <OrderReport
            isDraft={false}
            title={orderData?.name}
            sellerTitle={orderData?.sellerTitle}
            buyerTitle={orderData?.buyerTitle}
            shippingTitle={orderData?.shippingTitle}
            orderInfo={orderData?.orderInfo}
            sellerInfo={orderData?.sellerInfo}
            buyerInfo={orderData?.buyerInfo}
            shipInfo={orderData?.shipInfo}
            actions={orderData?.actions}
          >
            {getOrderHistoryTable(item)}
          </OrderReport>
        </Box>
        <Box
          sx={{ display: { xs: "flex", md: "none" } }}
          flexDirection={"column"}
          gap={1}
        >
          <MobileOrderReport
            status={item?.status?.name}
            hideTotal={orderType === "Stock-Transfer"}
            showOtherDetails={orderType !== "Stock-Transfer"}
            otherInfo={
              orderType === "Purchase-Order" || orderType === "Sale-Order"
                ? otherDetailsComp
                : orderType === "Purchase-Return" || orderType === "Sale-Return"
                ? otherDetailsReturn
                : ""
            }
            mobileTable={getOrderHistoryTable(item)}
            remarksComp={
              <Box
                display={"flex"}
                minWidth={"289px"}
                minHeight={"90px"}
                border={"1px solid #B7BEC7"}
                borderRadius={"8px"}
                padding={"10px"}
              >
                <Typography color={"text.disabled"}>
                  {item?.tnc || ""}
                </Typography>
              </Box>
            }
            Total={
              orderType !== "Stock-Transfer" && [
                {
                  label: "Subtotal Amount :",
                  value: item?.subTotalAmount
                    ? currencyOnlyFormatter(
                        parseFloat(item?.subTotalAmount).toFixed(2)
                      )
                    : "--"
                },
                ...[
                  ...(item?.taxes?.length > 1
                    ? [
                        {
                          label: "CGST :",
                          value: item?.taxes[0]?.totalAmount
                            ? currencyOnlyFormatter(
                                parseFloat(item?.taxes[0]?.totalAmount).toFixed(
                                  2
                                )
                              )
                            : "--"
                        },
                        {
                          label: "SGST :",
                          value: item?.taxes[1]?.totalAmount
                            ? currencyOnlyFormatter(
                                parseFloat(item?.taxes[1]?.totalAmount).toFixed(
                                  2
                                )
                              )
                            : "--"
                        }
                      ]
                    : [
                        {
                          label: "IGST :",
                          value: item?.taxes[0]?.totalAmount
                            ? currencyOnlyFormatter(
                                parseFloat(item?.taxes[0]?.totalAmount).toFixed(
                                  2
                                )
                              )
                            : "--"
                        }
                      ])
                ],
                {
                  label: "Total Amount :",
                  value: item?.totalAmount
                    ? currencyOnlyFormatter(
                        parseFloat(item?.totalAmount).toFixed(2)
                      )
                    : "--"
                }
              ]
            }
            ButtonGroup={""}
            preparedBy={`${item?.preparedBy?.firstName} ${item?.preparedBy?.lastName}`}
            title={orderData?.name}
            sellerTitle={orderData?.sellerTitle}
            buyerTitle={orderData?.buyerTitle}
            shippingTitle={orderData?.shippingTitle}
            orderInfo={orderData?.orderInfo}
            sellerInfo={orderData?.sellerInfo}
            buyerInfo={orderData?.buyerInfo}
            shipInfo={orderData?.shipInfo}
          />
        </Box>
        <div style={{ display: "none" }}>
          <Box ref={componentRef} sx={{ padding: "10px" }}>
            <Box display={"flex"} flexDirection={"column"} gap={1}>
              <OrderReport
                isDraft={false}
                title={orderData?.name}
                sellerTitle={orderData?.sellerTitle}
                buyerTitle={orderData?.buyerTitle}
                shippingTitle={orderData?.shippingTitle}
                orderInfo={orderData?.orderInfo}
                sellerInfo={orderData?.sellerInfo}
                buyerInfo={orderData?.buyerInfo}
                shipInfo={orderData?.shipInfo}
                actions={orderData?.actions}
              >
                {getOrderHistoryTable(item, true)}
              </OrderReport>
            </Box>
          </Box>
        </div>
      </>
    );
  };

  const tabs = [].concat(
    ...(Boolean(orderHistories)
      ? orderHistories?.map((item, index) => ({
          label: `${orderCode}/${index + 1}`,
          Component: orderHistoriesAllReport(item)
        }))
      : []),
    ...(Boolean(tableData)
      ? tableData?.map((item, index) => ({
          label: Boolean(orderHistories?.length)
            ? `${orderCode}/${orderHistories?.length + 1}`
            : orderCode,
          Component: orderHistoriesAllReport(item)
        }))
      : [])
  );

  return (
    <Grid item xs={12}>
      <CustomTabs
        tabs={tabs}
        activeTab={activeTab}
        onChange={handleTabChange}
        headerControls={
          !isMobileDevice && (
            <Button variant="link" disableRipple onClick={handlePrint}>
              Print
            </Button>
          )
        }
      />
    </Grid>
  );
};

export default OrderBillingComponent;
