import { queryKeys } from "../../constants/queryKeys";
import { getSkuById } from "../../inventory/services/index";
import { useQuery } from "@tanstack/react-query";

export const useFetchSkuById = (skuId) => {
  const SkuById = useQuery(
    [queryKeys.queryFetchSkuById, skuId],
    () => getSkuById(skuId),
    {
      enabled: Boolean(skuId),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false,
    }
  );

  return SkuById;
};
