import DetailedTransporter from "./DetailedTranspoter";
import DetailedWarehouse from "./DetailedWarehouse";
import { useParams } from "react-router-dom";

const WarehouseDetailsPage = (props) => {
  const { service } = useParams();

  return service === "transport" ? (
    <DetailedTransporter />
  ) : (
    <DetailedWarehouse />
  );
};

export default WarehouseDetailsPage;
