import { getInvoiceSubtotal } from "../../finance/services/index";
import { queryKeys } from "../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchInvoicesSubtotal = (params, orderType) => {
  const queryInvoiceSubtotal = useQuery(
    [queryKeys.queryFetchInvoiceSubtotal, orderType, JSON.stringify(params)],
    () => getInvoiceSubtotal(params, orderType),
    {
      refetchOnWindowFocus: false
    }
  );

  return queryInvoiceSubtotal;
};
