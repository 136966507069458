import * as yup from "yup";

import {
  BookOrderFormKeys,
  appServiceKeys,
  cartServiceKeys
} from "../../../constants/formKeys";
import { Box, Button, Grid, Link, Typography } from "@mui/material";
import { action_labels, error_msg, labels } from "../../../constants";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { AppContext } from "../../../shared/context/auth-context";
import BillingDetails from "./BillingDetails";
import CheckBoxGroup from "../../../shared/formElements/CheckBoxGroup";
import CostBreakup from "./CostBreakup";
import { InfoOutlined } from "@mui/icons-material";
import PartnerDetails from "./PartnerDetails";
import ServiceOrderDetails from "./ServiceOrderDetails";
import Storage from "../../../shared/utils/Storage";
import { StorageKeys } from "../../../constants/storage-keys";
import { StyledBookNowButton } from "../WarehouseDetails/DetailedWarehouse";
import { StyledResponsiveText } from "../../../shared/theme/globalTheme";
import SuccessfulDialog from "../../../shared/UiElements/SuccessfulDialog";
import appRoutes from "../../../constants/appRoutes";
import { currencyFormatter } from "../../../shared/utils";
import { enqueueSnackbar } from "notistack";
import { isMobileDevice } from "../../../App";
import moment from "moment";
import styled from "@emotion/styled";
import { useCompleteOrder } from "../../../query-hooks/Orders/useCompleteOrder";
import { useCreateInquiryOrder } from "../../../query-hooks/Orders/useCreateInquiryOrder";
import { useCreateOrderFromCart } from "../../../query-hooks/Orders/useCreateOrderFromCart";
import { useCustomDialog } from "../../../shared/customDialog";
import { useDrawer } from "../../../shared/customSideDrawer";
import { useFetchCreateOrderFormData } from "../../../query-hooks/Orders/useFetchCreateOrderFormData";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { vehicleWeights } from "../../pages/Explore";

const schema = yup.object({
  [BookOrderFormKeys.FirstName]: yup.string().required(error_msg.required),
  [BookOrderFormKeys.LastName]: yup.string().required(error_msg.required),
  [BookOrderFormKeys.FirmsName]: yup.string().required(error_msg.required),
  [BookOrderFormKeys.FirmsEmail]: yup.string().required(error_msg.required),
  [BookOrderFormKeys.GSTIN]: yup.string().required(error_msg.required),
  [BookOrderFormKeys.Contact]: yup
    .string()
    .required(error_msg.required)
    .matches(/^[0-9]\d*(\.\d+)?$/, error_msg.onlyDigits),
  [BookOrderFormKeys.StreetAddress]: yup.string().required(error_msg.required),
  [BookOrderFormKeys.Area]: yup.string().required(error_msg.required),
  [BookOrderFormKeys.City]: yup.string().required(error_msg.required),
  [BookOrderFormKeys.State]: yup.string().required(error_msg.required),
  [BookOrderFormKeys.ZipCode]: yup
    .string()
    .required(error_msg.required)
    .matches(/^[0-9]\d*(\.\d+)?$/, error_msg.onlyDigits),
  [BookOrderFormKeys.PickUpDate]: yup.string().required(error_msg.required),
  [BookOrderFormKeys.AcceptTnC]: yup
    .array()
    .test([BookOrderFormKeys.AcceptTnC], error_msg.acceptTnC, (value) =>
      value.includes("TnC")
    )
    .required(error_msg.acceptTnC)
});

export const StyleBorderedGrid = styled((props) => (
  <Grid
    {...props}
    sx={{
      background: {
        md: "#FFFFFF",
        xs: props?.secondary ? "#EFF2F6" : "#FFFFFF"
      },
      border: "0.4px solid #95A1B0",
      borderRadius: "8px"
    }}
  />
))(({ theme }) => ({}));

const HeaderText = ({ header }) => {
  return (
    <StyledResponsiveText
      color={"text.primary"}
      sx={{
        mt: {
          xs: 2,
          md: 3
        },
        mb: {
          xs: "4px",
          md: 1
        }
      }}
      desktop={{
        fontSize: 20,
        fontWeight: 500,
        lineHeight: "33.89px",
        letterSpacing: "-0.002em"
      }}
      mobile={{
        fontSize: 16,
        fontWeight: 500,
        lineHeight: "24.2px",
        letterSpacing: "0.002em"
      }}
    >
      {header}
    </StyledResponsiveText>
  );
};

const inquirySearchForm = (searchForm) => {
  const formatDate = (date) => moment(date).format("YYYY-MM-DD");

  if (searchForm?.partnerType === "Warehouse") {
    return {
      "searchForm[partnerType]": searchForm?.partnerType,
      "searchForm[city]": searchForm?.city,
      "searchForm[inboundDate]": formatDate(searchForm?.inboundDate),
      "searchForm[outboundDate]": formatDate(searchForm?.outboundDate),
      "searchForm[storageType]": searchForm?.storageType?.name,
      "searchForm[storageQuantity]": searchForm?.storageQuantity
    };
  }

  if (searchForm?.partnerType === "Transport") {
    return {
      "searchForm[partnerType]": searchForm?.partnerType,
      "searchForm[city]": searchForm?.city,
      "searchForm[weightCapacityRange]": searchForm?.weightCapacityRange,
      "searchForm[inboundDate]": formatDate(searchForm?.inboundDate),
      "searchForm[pickup][address]": searchForm?.pickup?.address,
      "searchForm[pickup][location][coordinates]": [
        searchForm?.pickup?.location?.coordinates[0],
        searchForm?.pickup?.location?.coordinates[1]
      ],
      "searchForm[distance]": searchForm?.distance,
      "searchForm[destination][address]": searchForm?.destination?.address,
      "searchForm[destination][location][coordinates]": [
        searchForm?.destination?.location?.coordinates[0],
        searchForm?.destination?.location?.coordinates[1]
      ]
    };
  }

  return null;
};

const CompleteOrder = () => {
  const { cart, onRemoveFromCart } = useContext(AppContext);
  const { service, isInquiry } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [state, setState] = useState(location?.state || null);

  useEffect(() => {
    if (isInquiry) {
      // If state is null, try retrieving it from sessionStorage
      const savedState = Storage.getItem(StorageKeys.INQUIRY_ITEM);
      if (savedState) {
        setState(JSON.parse(savedState));
      }
    }
  }, [isInquiry]);

  const [stepper, setStepper] = useState(0);
  const [formValues, setFormValues] = useState({});

  const [serviceType, setServiceType] = useState(service);

  const formData = Storage.getItem(StorageKeys.SEARCH_FORM_DATA);
  const searchFormParams = Storage.getItem(StorageKeys.SEARCH_FORM_PARAMS);
  const serviceLocalStorage = Storage.getItem(StorageKeys.serviceLocalStorage);
  const isTransportOnlyService =
    serviceLocalStorage?.transport && !serviceLocalStorage?.warehouse;
  const cartItem = Boolean(isInquiry) ? state?.order : cart;

  const inquirySearchFormParams = inquirySearchForm(
    cartItem?.warehouse?.searchForm || cartItem?.transport?.searchForm
  );

  if (
    serviceType === "warehouse" &&
    Boolean(searchFormParams) &&
    !Boolean(isInquiry)
  ) {
    delete searchFormParams["searchForm[pickup][address]"];
    delete searchFormParams["searchForm[pickup][location][coordinates]"];
    delete searchFormParams["searchForm[distance]"];
    if (Boolean(searchFormParams["searchForm[additionalServices]"])) {
      searchFormParams["searchForm[additionalServices]"] = searchFormParams[
        "searchForm[additionalServices]"
      ].filter((item) => item !== "Loading");
    }
  }
  if (
    serviceType === "transport" &&
    Boolean(searchFormParams) &&
    !Boolean(isInquiry)
  ) {
    delete searchFormParams["searchForm[outboundDate]"];
    delete searchFormParams["searchForm[storageType]"];
    delete searchFormParams["searchForm[storageQuantity]"];
    if (searchFormParams["searchForm[additionalServices]"]) {
      searchFormParams["searchForm[additionalServices]"] = searchFormParams[
        "searchForm[additionalServices]"
      ].filter((item) => item !== "Forks");
    }
  }

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    reset,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: {
      [BookOrderFormKeys.PickUpDate]: new Date(
        isTransportOnlyService ? formData?.pickUpDate : formData?.inboundDate
      )
    },
    resolver: yupResolver(schema)
  });

  const { showDrawer, hideDrawer } = useDrawer();
  const { showDialog, hideDialog } = useCustomDialog();

  const {
    mutate: createOrder,
    isLoading: isCreateOrderLoading,
    isError: isCreateOrderError,
    error: createOrderError
  } = useCreateOrderFromCart();
  const {
    mutate: createInquiryOrder,
    isLoading: isCreateInquiryOrderLoading,
    isError: isCreateInquiryOrderError,
    error: createInquiryOrderError
  } = useCreateInquiryOrder();
  const {
    mutate: completeOrder,
    isLoading: isCompleteOrderLoading,
    isError: isCompleteOrderError,
    error: completeOrderError
  } = useCompleteOrder();

  const {
    data: orderFormData,
    isFetching: isFetchingOrderFormData,
    isError,
    error
  } = useFetchCreateOrderFormData(
    isInquiry
      ? { ...inquirySearchFormParams }
      : {
          ...searchFormParams
        },
    cartItem?.warehouse?._id,
    cartItem?.transport?._id,
    serviceType
  );

  useEffect(() => {
    const subscription = watch((value) => setFormValues(value));
    return () => subscription.unsubscribe();
  }, [watch]);

  const handleNext = async () => {
    await trigger([
      BookOrderFormKeys.FirstName,
      BookOrderFormKeys.LastName,
      BookOrderFormKeys.FirmsName,
      BookOrderFormKeys.FirmsEmail,
      BookOrderFormKeys.GSTIN,
      BookOrderFormKeys.Contact,
      BookOrderFormKeys.StreetAddress,
      BookOrderFormKeys.Area,
      BookOrderFormKeys.City,
      BookOrderFormKeys.ZipCode,
      BookOrderFormKeys.PickUpDate
    ]).then((isValid) => {
      if (isValid) {
        setStepper(1);
      }
    });
  };

  const handlePayment = (res) => {
    const paymentData = res.data.data;
    // eslint-disable-next-line no-undef
    const easebuzzCheckout = new EasebuzzCheckout(
      process.env.REACT_APP_EASEBUZZ_API_KEY,
      process.env.REACT_APP_EASEBUZZ_PAYMENT_ENV
    );

    let options = {
      access_key: paymentData.easeBuzzData,
      onResponse: (response) => {
        completeOrder(
          {
            data: response,
            orderId: paymentData.order._id,
            paymentId: paymentData.payment._id,
            status: response.status === "success" ? true : false
          },
          {
            onSuccess: () => {
              if (response.status === "success") {
                if (!Boolean(state?.order)) {
                  onRemoveFromCart(cartServiceKeys.warehouse);
                  onRemoveFromCart(cartServiceKeys.transport);
                }
                showDialog({
                  component: (
                    <SuccessfulDialog
                      text={action_labels.orderCreatedText}
                      btn2Text={action_labels.navigateToMyOrdersButtonText}
                      btn2Callback={() => {
                        hideDialog();
                        navigate(
                          `${appRoutes.orders.main}/${appRoutes.orders.myOrdersListView}`
                        );
                      }}
                    />
                  ),
                  backdropOff: true
                });
              }
            }
          }
        );

        if (response.status !== "success") {
          enqueueSnackbar(
            response.status === "userCancelled"
              ? "User cancelled payment."
              : response.error,
            { variant: "error" }
          );
          // setPaymentErrorMessage(response.error);
        }
      },
      theme: "#2773FF" // color hex
    };
    easebuzzCheckout.initiatePayment(options);
  };

  const onSubmit = (data) => {
    const commonProps = {
      partnerType: formData?.service,
      city: formData?.city,
      ...(Boolean(formData?.destination) && {
        destination: {
          address: formData?.destination,
          location: {
            coordinates: [...formData?.destinationCoordinates]
          }
        }
      }),
      ...{
        ...(serviceType === "transport"
          ? {
              inboundDate: moment(formData?.pickUpDate).format("YYYY-MM-DD"),
              weightCapacityRange: vehicleWeights[formData?.truckType]
            }
          : {
              inboundDate: moment(formData?.inboundDate).format("YYYY-MM-DD"),
              outboundDate: moment(formData?.outboundDate).format("YYYY-MM-DD")
            })
      }
    };
    // Add only fields with non-empty values
    const additionalFields = {
      materialCategory: formData?.materialDetails,
      packagingType: formData?.packagingType,
      packageDimension: formData?.dimension,
      packageQuantity: formData?.units,
      totalWeight: formData?.totalWeight,
      itemDescription: formData?.itemDescription,
      additionalServices: formData?.additionalFeature
    };

    for (const [key, value] of Object.entries(additionalFields)) {
      if (value !== undefined && value !== null && value !== "") {
        commonProps[key] = value;
      }
    }
    if (state?.inquiryId) {
      createInquiryOrder(
        {
          data: {
            ...{
              ...(cartItem?.warehouse
                ? { warehousePartnerId: state?.order?.warehouse?._id }
                : {})
            },
            ...{
              ...(cartItem?.transport
                ? { transportPartnerId: state?.order?.transport?._id }
                : {})
            },
            customerBillingDetails: {
              firstName: data[BookOrderFormKeys.FirstName],
              lastName: data[BookOrderFormKeys.LastName],
              companyName: data[BookOrderFormKeys.FirmsName],
              email: data[BookOrderFormKeys.FirmsEmail],
              gstNo: data[BookOrderFormKeys.GSTIN],
              mobile: data[BookOrderFormKeys.Contact],
              address: {
                address: data[BookOrderFormKeys.StreetAddress],
                area: data[BookOrderFormKeys.Area],
                city: data[BookOrderFormKeys.City],
                state: data[BookOrderFormKeys.State],
                pincode: data[BookOrderFormKeys.ZipCode]
              }
            }
          },
          inquiryId: state?.inquiryId
        },
        {
          onSuccess: (res) => handlePayment(res)
        }
      );
    } else {
      createOrder(
        {
          searchForm: {
            ...commonProps,
            ...{
              ...(serviceType === "warehouse"
                ? {
                    storageType: formData.storageType,
                    storageQuantity: formData.pallets
                  }
                : serviceType === "transport"
                ? {
                    pickup: {
                      address: formData.pickupLocation,
                      location: {
                        coordinates: [...formData.pickupLocationCoordinates]
                      }
                    },
                    distance: formData.distance
                  }
                : {
                    // transportRequired: cartItem?.transport ? true : false,
                    // formData.transportRequired,
                    // ...(cartItem?.transport
                    //   ? {
                    //       pickup: {
                    //         address: formData.pickupLocation,
                    //         location: {
                    //           coordinates: [
                    //             ...formData.pickupLocationCoordinates
                    //           ]
                    //         }
                    //       },
                    //       distance: formData.distance,
                    //       additionalServices: formData.additionalFeature.filter(
                    //         (item) => item !== "Forks"
                    //       )
                    //     }
                    //   : {
                    // additionalServices: formData.additionalFeature.filter(
                    //   (item) => item !== "Loading"
                    // ),
                    // outboundDate: moment(formData.outboundDate).format(
                    //   "YYYY-MM-DD"
                    // ),
                    // storageType: formData.storageType,
                    // storageQuantity: formData.pallets,
                    // pickup: {
                    //   address: formData.pickupLocation,
                    //   location: {
                    //     coordinates: [...formData.pickupLocationCoordinates]
                    //   }
                    // },
                    // distance: formData.distance
                    // })
                  })
            }
          },
          customerBillingDetails: {
            firstName: data[BookOrderFormKeys.FirstName],
            lastName: data[BookOrderFormKeys.LastName],
            companyName: data[BookOrderFormKeys.FirmsName],
            email: data[BookOrderFormKeys.FirmsEmail],
            gstNo: data[BookOrderFormKeys.GSTIN],
            mobile: data[BookOrderFormKeys.Contact],
            address: {
              address: data[BookOrderFormKeys.StreetAddress],
              area: data[BookOrderFormKeys.Area],
              city: data[BookOrderFormKeys.City],
              state: data[BookOrderFormKeys.State],
              pincode: data[BookOrderFormKeys.ZipCode]
            }
          },
          ...{
            ...(cartItem.warehouse
              ? { warehousePartnerId: cartItem.warehouse._id }
              : {})
          },
          ...{
            ...(cartItem.transport
              ? { transportPartnerId: cartItem.transport._id }
              : {})
          }
        },
        {
          onSuccess: (res) => handlePayment(res)
        }
      );
    }
  };

  const openMobileCostBreakups = () => {
    showDrawer({
      component: (
        <CostBreakup
          isInquiry={Boolean(isInquiry)}
          warehouseBreakUp={cartItem?.warehouse}
          transportBreakUp={cartItem?.transport}
          formData={formData}
          control={control}
          errors={errors}
          orderFormData={orderFormData}
          closeDrawer={hideDrawer}
        />
      ),
      closeIcon: false
    });
  };

  useEffect(() => {
    if (isCreateOrderError) {
      enqueueSnackbar(createOrderError?.response?.data.message, {
        variant: "error"
      });
    } else if (isCreateInquiryOrderError) {
      enqueueSnackbar(createInquiryOrderError?.response?.data.message, {
        variant: "error"
      });
    } else if (isCompleteOrderError) {
      enqueueSnackbar(completeOrderError?.response?.data.message, {
        variant: "error"
      });
    } else if (isError) {
      enqueueSnackbar(error?.response?.data.message, {
        variant: "error"
      });
    } else {
      return;
    }
  }, [
    completeOrderError?.response?.data.message,
    createInquiryOrderError?.response?.data.message,
    createOrderError?.response?.data.message,
    error?.response?.data.message,
    isCompleteOrderError,
    isCreateInquiryOrderError,
    isCreateOrderError,
    isError
  ]);

  useEffect(() => {
    if (!cartItem?.warehouse) {
      setServiceType(cartServiceKeys.transport);
    } else if (!cartItem?.transport) {
      setServiceType(cartServiceKeys.warehouse);
    } else if (!service) {
      setServiceType("both");
    } else {
      setServiceType(service);
    }
  }, [cartItem?.transport, cartItem?.warehouse, service]);

  const isCartEmpty = !cartItem?.warehouse && !cartItem?.transport;

  return isCartEmpty ? (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      gap={1}
      height={"calc(100vh - 142px)"}
    >
      <Typography fontWeight={500}>{labels.emptyCartText}</Typography>
      <Button
        variant="contained"
        sx={{ height: 48, textTransform: "none" }}
        onClick={() =>
          Boolean(searchFormParams)
            ? navigate(appRoutes.explore.listings)
            : navigate(appRoutes.explore.main)
        }
      >
        {labels.cartEmptyButtonText}
      </Button>
    </Box>
  ) : (
    <>
      <Box
        sx={{
          padding: {
            xs: "0 16px",
            md: 0
          },
          marginBottom: {
            xs: 0,
            md: 2
          },
          height: {
            xs: "calc(100svh - 266px)",
            md: "auto"
          },
          overflowX: "auto"
        }}
      >
        <Box>
          <Grid container spacing={"21px"}>
            {isMobileDevice && stepper === 1 ? null : (
              <>
                <Grid item xs={12}>
                  <HeaderText header={labels.partnerDetails} />
                </Grid>
                <Grid item xs={12}>
                  <PartnerDetails
                    getValues={getValues}
                    cartItem={cartItem}
                    isInquiry={Boolean(isInquiry)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: {
                        xs: "none",
                        md: "block"
                      }
                    }}
                  >
                    <HeaderText header={labels.billingDetails} />
                  </Box>
                  <Box
                    sx={{
                      display: {
                        xs: "block",
                        md: "none"
                      }
                    }}
                  >
                    <HeaderText header={labels.additionalDetailsTitleText} />
                  </Box>
                </Grid>
                <Grid item xs={12} md={7}>
                  <BillingDetails
                    control={control}
                    customerAddress={
                      orderFormData?.customer?.customerLastBillingDetails
                    }
                    isFetching={isFetchingOrderFormData}
                    reset={(fieldsData) =>
                      reset((formValues) => ({
                        ...formValues,
                        ...fieldsData
                      }))
                    }
                  />
                </Grid>
              </>
            )}
            <Grid
              item
              md={5}
              sx={{
                display: {
                  xs: "none",
                  md: "block"
                }
              }}
            >
              <CostBreakup
                isInquiry={Boolean(isInquiry)}
                warehouseBreakUp={cartItem?.warehouse}
                transportBreakUp={cartItem?.transport}
                formData={formData}
                control={control}
                errors={errors}
                orderFormData={orderFormData}
                setValue={setValue}
                isLoading={
                  isCreateOrderLoading ||
                  isCreateInquiryOrderLoading ||
                  isCompleteOrderLoading
                }
                onSubmit={handleSubmit(onSubmit)}
              />
            </Grid>
            {isMobileDevice && stepper === 0 ? null : (
              <>
                <Grid item xs={12}>
                  <HeaderText header={labels.storageOrderForm} />
                </Grid>
                <Grid item xs={12} md={7}>
                  {orderFormData && (
                    <ServiceOrderDetails
                      isInquiry={Boolean(isInquiry)}
                      customerInfo={formValues}
                      orderFormData={orderFormData}
                      type={
                        cartItem?.warehouse && cartItem?.transport
                          ? null
                          : cartItem?.warehouse
                          ? appServiceKeys.warehouse
                          : cartItem?.transport
                          ? appServiceKeys.transport
                          : null
                      }
                      cartItem={cartItem}
                    />
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </Box>
        {stepper === 1 && (
          <Box
            marginTop={2}
            marginBottom={2}
            sx={{
              display: {
                xs: "block",
                md: "none"
              }
            }}
          >
            <CheckBoxGroup
              control={control}
              name={BookOrderFormKeys.AcceptTnC}
              errors={errors}
              options={[
                {
                  displayName: (
                    <Typography
                      component="span"
                      color={"text.primary"}
                      sx={{
                        fontSize: 14,
                        fontWeight: 600,
                        lineHeight: "18px"
                      }}
                    >
                      {labels.acceptAll}
                      <Link
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        {labels.tnc}
                      </Link>
                    </Typography>
                  ),
                  name: "TnC"
                }
              ]}
              setValue={setValue}
            />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: {
            xs: "block",
            md: "none"
          },
          position: "fixed",
          left: 0,
          bottom: "70px",
          width: "100%",
          background: "white",
          zIndex: 1
        }}
      >
        {/* <Divider sx={{ borderColor: "#B7BEC7", mt: 2 }} /> */}
        <Box
          sx={{
            padding: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderTop: "1px solid #B7BEC7"
          }}
        >
          <Box display={"flex"} flexDirection={"column"} gap={0.5}>
            <Box display={"flex"} gap={0.5} alignItems={"center"}>
              <Typography
                color="text.primary"
                fontWeight={700}
                fontSize={16}
                lineHeight={"19.36px"}
                letterSpacing="0.005em"
              >
                {currencyFormatter(
                  parseFloat(
                    (cartItem?.warehouse?.costs?.totalAmount ?? 0) +
                      (cartItem?.transport?.costs?.totalAmount ?? 0)
                  ).toFixed(0)
                )}{" "}
              </Typography>
              <Box display={"flex"} onClick={openMobileCostBreakups}>
                <InfoOutlined fontSize="small" />
              </Box>
            </Box>
            <Typography
              color="text.secondary"
              fontWeight={400}
              fontSize={14}
              lineHeight={"20px"}
            >
              {labels.totalAmountText}
            </Typography>
          </Box>
          <StyledBookNowButton
            variant="contained"
            type="submit"
            onClick={stepper === 0 ? handleNext : handleSubmit(onSubmit)}
          >
            {stepper === 1 ? labels.proceedToPay : labels.next}
          </StyledBookNowButton>
        </Box>
      </Box>
    </>
  );
};

export default CompleteOrder;
