import { getProfile } from "../../shared/api/searchFormData";
import { queryKeys } from "../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { AppContext } from "../../shared/context/auth-context";

export const useFetchProfileQuery = () => {
  const { currentUser } = useContext(AppContext);
  const queryProfile = useQuery(
    [queryKeys.queryGetProfile],
    () => getProfile(),
    { enabled: Boolean(currentUser), refetchOnWindowFocus: false }
  );

  return queryProfile;
};
