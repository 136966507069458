import React, { useState, useEffect, useRef } from "react";
import { Badge, Box, IconButton, Zoom } from "@mui/material";
import {
  Close,
  MenuRounded,
  NotificationsOutlined,
  ShoppingCartOutlined,
  Person,
  LogoutRounded
} from "@mui/icons-material";
import { StyledMobileIconButton } from "../theme/headerTheme";

const MoreMenu = ({
  openCart,
  openSideDrawer,
  cartCount = 0,
  NotificationCount = 0,
  onLogoutClick,
  onProfileClick
}) => {
  const [showIcons, setShowIcons] = useState(false);
  const containerRef = useRef(null);

  const handleToggle = () => {
    setShowIcons((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setShowIcons(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleIconClick = (action) => {
    setShowIcons(false);
    action();
  };

  const hasCount = cartCount || NotificationCount;

  const iconContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative"
  };

  const buttonContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    top: "40px",
    left: "50%",
    transform: "translateX(-50%)",
    boxShadow: showIcons && "0 2px 4px rgba(0, 0, 0, 0.4)",
    borderRadius: "64px",
    py: "10px",
    px: "5px",
    backgroundColor: showIcons && "#F0F2F5",
    gap: 2
  };

  return (
    <Box sx={iconContainerStyle} ref={containerRef}>
      {showIcons && (
        <Box sx={buttonContainerStyle}>
          <Zoom in={showIcons}>
            <Badge
              badgeContent={cartCount > 0 && cartCount}
              color="primary"
              overlap="circular"
              invisible={cartCount === 0}
            >
              <IconButton onClick={() => handleIconClick(openCart)}>
                <ShoppingCartOutlined />
              </IconButton>
            </Badge>
          </Zoom>
          <Zoom
            in={showIcons}
            style={{ transitionDelay: showIcons ? "100ms" : "0ms" }}
          >
            <Badge
              badgeContent={NotificationCount > 0 && NotificationCount}
              color="primary"
              overlap="circular"
              invisible={NotificationCount === 0}
            >
              <IconButton onClick={() => handleIconClick(openSideDrawer)}>
                <NotificationsOutlined />
              </IconButton>
            </Badge>
          </Zoom>
          <Zoom
            in={showIcons}
            style={{ transitionDelay: showIcons ? "200ms" : "0ms" }}
          >
            <IconButton onClick={() => handleIconClick(onProfileClick)}>
              <Person />
            </IconButton>
          </Zoom>
          <Zoom
            in={showIcons}
            style={{ transitionDelay: showIcons ? "200ms" : "0ms" }}
          >
            <IconButton onClick={() => handleIconClick(onLogoutClick)}>
              <LogoutRounded />
            </IconButton>
          </Zoom>
        </Box>
      )}
      <StyledMobileIconButton onClick={handleToggle}>
        <Badge color="error" variant="dot" invisible={!Boolean(hasCount)}>
          {showIcons ? <Close /> : <MenuRounded />}
        </Badge>
      </StyledMobileIconButton>
    </Box>
  );
};

export default MoreMenu;
