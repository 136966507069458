import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import appRoutes from "../../constants/appRoutes";

const useGaTracker = () => {
  const location = useLocation();

  useEffect(() => {
    if (!window.gtag) {
      return;
    }

    const specificPaths = [
      "/",
      appRoutes.pricing,
      appRoutes.credit,
      appRoutes.erp,
      appRoutes.inventoryMangement
    ];

    const path = location.pathname + location.search;
    if (specificPaths.includes(location.pathname)) {
      window.gtag("config", process.env.REACT_APP_GA_TRACKING_ID, {
        page_path: path
      });
    }
  }, [location]);
};

export default useGaTracker;
