import { queryKeys } from "../../constants/queryKeys";
import { getMRRs } from "../../inventory/services";
import { useQuery } from "@tanstack/react-query";

export const useFetchMRRs = (orderId) => {
  const MRRs = useQuery(
    [queryKeys.queryFetchMRRs, orderId],
    () => getMRRs(orderId),
    {
      enabled: Boolean(orderId),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return MRRs;
};
