import { AppContext } from "../../shared/context/auth-context";
import { getNotifications } from "../../customer/services";
import { queryKeys } from "../../constants/queryKeys";
import { useContext } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";

export const useFetchNotifications = () => {
  const { currentUser, profileData } = useContext(AppContext);
  const queryWarehouses = useInfiniteQuery(
    [queryKeys.queryFetchNotifications],
    ({ pageParam = 1 }) => getNotifications(pageParam),
    {
      enabled: Boolean(currentUser) && Boolean(profileData?.user?.role === "CustomerAdmin"),
      getNextPageParam: (lastPage, allPages) => {
        const nextPage =
          lastPage?.notifications.length === lastPage?.pagination.limit
            ? allPages.length + 1
            : undefined;

        return nextPage;
      }
    }
  );

  return queryWarehouses;
};
