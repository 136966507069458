import { getItemLedgers } from "../../inventory/services";
import { queryKeys } from "../../constants/queryKeys";
import { useInfiniteQuery } from "@tanstack/react-query";

export const useFetchItemLedgers = (skuId, params) => {
  const queryLedgers = useInfiniteQuery(
    [queryKeys.queryFetchLedgers, skuId, Boolean(params)],
    ({ pageParam = 1 }) => getItemLedgers(skuId, params, pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage =
          lastPage?.ledgers.length === lastPage?.pagination.limit
            ? allPages.length + 1
            : undefined;

        return nextPage;
      },
      enabled: Boolean(skuId),
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true
    }
  );

  return queryLedgers;
};
