import { getCreateWarehouseFormData } from "../../inventory/services";
import { queryKeys } from "../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchCreateWarehouseFormData = (warehouseId) => {
  const queryCreateWarehouseFormData = useQuery(
    [queryKeys.queryFetchCreateWarehouseFormData],
    () => getCreateWarehouseFormData(),
    {
      enabled: !Boolean(warehouseId),
      refetchOnWindowFocus: false
    }
  );

  return queryCreateWarehouseFormData;
};
