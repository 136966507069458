import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { action_labels, labels, order_table_labels } from "../../../constants";
import { useEffect, useState } from "react";

import DOCUMENTS from "../../../assets/icons/Documents.svg";
import ErrorPopup from "../../../shared/UiElements/ErrorPopup";
import InfoIcon from "@mui/icons-material/Info";
import MODIFY from "../../../assets/icons/Edit.svg";
import OrderDetailsLocationAndDate from "./OrderDetailsLocationAndDate";
import OrderDetailsPayment from "./OrderDetailsPayment";
import { OrderStyledIconButton } from "./ActionButtons";
import OrderTrackStepper from "./OrderTrackStepper";
import { SearchFormKeys } from "../../../constants/formKeys";
import SuccessfulDialog from "../../../shared/UiElements/SuccessfulDialog";
import appRoutes from "../../../constants/appRoutes";
import { enqueueSnackbar } from "notistack";
import { isMobileDevice } from "../../../App";
import moment from "moment";
import { steps } from "./TrackOrder";
import styled from "@emotion/styled";
import { useCompleteOrder } from "../../../query-hooks/Orders/useCompleteOrder";
import { useCustomDialog } from "../../../shared/customDialog";
import { useFetchUpdatedTotal } from "../../../query-hooks/Orders/useFetchUpdatedTotal";
import { useForm } from "react-hook-form";
import { useGetOrderDetails } from "../../../query-hooks/Orders/useGetOrderDetails";
import { useModifyOrderDate } from "../../../query-hooks/Orders/useModifyOrderDate";

const OrderButton = styled(Button)(() => ({
  height: 48,
  width: 182,
  paddingLeft: 2,
  paddingRight: 2
}));

const StyledEditMessagedBox = styled(Box)(({ theme }) => ({
  padding: "15px",
  display: "flex",
  alignItems: "center",
  gap: "15px",
  backgroundColor: theme.palette.warning.background,
  borderRadius: "8px",
  // height: 90,
  width: "100%"
  // [theme.breakpoints.up("lg")]: {
  //   height: 48
  // }
}));

const OrderDetailText = ({ header, name, trx, address }) => {
  return (
    <Grid container spacing={2} py={1}>
      <Grid item xs={4} lg={2}>
        <Typography
          color={"text.secondary"}
          sx={{
            fontSize: 14,
            fontWeight: 400,
            lineHeight: "20px"
          }}
        >
          {header}
        </Typography>
        <Typography
          color={"text.secondary"}
          sx={{
            pt: 1,
            fontSize: 14,
            fontWeight: 400,
            lineHeight: "20px"
          }}
        >
          {order_table_labels.orderId}
        </Typography>
      </Grid>
      <Grid item xs={8} lg={4}>
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 600,
            lineHeight: "20px"
          }}
        >
          {name}
        </Typography>
        <Typography
          sx={{
            pt: 1,
            fontSize: 13,
            fontWeight: 500,
            lineHeight: "18px"
          }}
        >
          {trx}
        </Typography>
        <Typography
          pt={1}
          sx={{
            fontSize: 13,
            fontWeight: 400,
            lineHeight: "18px"
          }}
        >
          {address}
        </Typography>
      </Grid>
      <Grid item lg={6} />
    </Grid>
  );
};

const MoreDetails = ({ isEdit }) => {
  return (
    !isEdit && (
      <>
        <Grid item xs={12} lg={7}>
          <Box pt={{ xs: 0, lg: 4 }}>
            <OrderDetailText
              header={labels.warehouse}
              name={"Warehouse name & code"}
              trx={"XXX12345"}
              address={
                "33/A, Ananya, Telecom Colony, Nt Layout, Mysore Road, Mysore Road 098440 93644"
              }
            />
            <OrderDetailText
              header={labels.transporter}
              name={"Transporter code"}
              trx={"XXX12345"}
              address={"Electronic City, Bangalore, India"}
            />
          </Box>
        </Grid>
        <Grid item xs={12} lg="auto">
          <Divider
            orientation={"vertical"}
            sx={{
              mr: { xs: 0, lg: 5 },
              height: "100%",
              border: "0.4px solid #B7BEC7"
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <OrderTrackStepper detailList={steps} />
        </Grid>
      </>
    )
  );
};

const OrderDetails = (props) => {
  const { uuid } = useParams();
  const navigate = useNavigate();

  const [isEdit, setIsEdit] = useState(false);

  const { showDialog, hideDialog } = useCustomDialog();

  const {
    data: orderData,
    isFetching,
    isError,
    error
  } = useGetOrderDetails(uuid);

  const { control, watch, reset } = useForm({
    defaultValues: {
      [SearchFormKeys.PickupLocation]: "",
      [SearchFormKeys.InboundDate]: "",
      [SearchFormKeys.OutboundDate]: ""
    }
  });

  const outboundDateWatch = watch(SearchFormKeys.OutboundDate);

  const updatedOrderTotalData = useFetchUpdatedTotal(
    uuid,
    moment(outboundDateWatch).format("YYYY-MM-DD")
  );

  const { mutate: modifyOrder, isLoading } = useModifyOrderDate();
  const { mutate: completeOrder, isLoading: isCompleteOrderLoading } =
    useCompleteOrder();

  const handlePayment = (res) => {
    const paymentData = res.data.data;
    console.log(paymentData);
    // eslint-disable-next-line no-undef
    const easebuzzCheckout = new EasebuzzCheckout(
      process.env.REACT_APP_EASEBUZZ_API_KEY,
      process.env.REACT_APP_EASEBUZZ_PAYMENT_ENV
    );

    let options = {
      access_key: paymentData.easeBuzzData,
      onResponse: (response) => {
        completeOrder(
          {
            data: response,
            orderId: uuid,
            paymentId: paymentData.payment._id,
            status: response.status === "success" ? true : false
          },
          {
            onSuccess: () => {
              if (response.status === "success")
                showDialog({
                  component: (
                    <SuccessfulDialog
                      text={action_labels.paymentSuccessText}
                      btn2Text={action_labels.navigateToMyOrdersButtonText}
                      btn2Callback={() => {
                        hideDialog();
                        navigate(
                          `${appRoutes.orders.main}/${appRoutes.orders.myOrdersListView}`
                        );
                      }}
                    />
                  ),
                  backdropOff: true
                });
            },
            onError: (error) => {
              enqueueSnackbar(error?.response?.data.message, {
                variant: "error"
              });
            }
          }
        );

        if (response.status !== "success") {
          enqueueSnackbar(
            response.status === "userCancelled"
              ? "User cancelled payment."
              : response.error,
            { variant: "error" }
          );
        }
      },
      theme: "#2773FF" // color hex
    };
    easebuzzCheckout.initiatePayment(options);
  };

  const handleModifyOrder = () => {
    modifyOrder(
      {
        orderId: uuid,
        outboundDate: moment(outboundDateWatch).format("YYYY-MM-DD")
      },
      {
        onSuccess: (res) => handlePayment(res),
        onError: (error) => {
          enqueueSnackbar(error?.response?.data.message, {
            variant: "error"
          });
        }
      }
    );
  };

  useEffect(() => {
    if (!isFetching) {
      reset({
        [SearchFormKeys.PickupLocation]:
          orderData?.order?.searchForm?.pickup?.address,
        [SearchFormKeys.InboundDate]: new Date(
          orderData?.order?.recentOrderItem?.inboundDate
        ),
        [SearchFormKeys.OutboundDate]: new Date(
          orderData?.order?.recentOrderItem?.outboundDate
        )
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching, reset, isEdit]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(error?.message || error?.response?.data.message, {
        variant: "error"
      });
      showDialog({
        component: (
          <ErrorPopup
            text={error?.message || error?.response?.data.message}
            btn2Text={"Go back"}
          />
        ),
        backdropOff: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, error]);

  return isFetching ? (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isFetching}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : isError ? null : (
    <Box px={{ xs: 2, lg: 0 }} pb={2}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={0}
          lg={6}
          display={{ xs: "none", lg: "flex" }}
          alignItems={"center"}
        >
          <Typography
            sx={{
              fontSize: 20,
              fontWeight: 500,
              lineHeight: "normal",
              letterSpacing: "0.04px"
            }}
          >
            {order_table_labels.orderDetails}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={6} textAlign={{ xs: "", lg: "end" }}>
          {isMobileDevice && !isEdit ? (
            <Box
              sx={{
                position: "absolute",
                display: {
                  xs: "flex",
                  md: "none"
                }
              }}
              right={16}
              marginTop={"-40px"}
              gap={"7px"}
              zIndex={1}
            >
              <OrderStyledIconButton onClick={() => setIsEdit(true)}>
                <img src={MODIFY} className="pull-right cursor-field" alt="" />
              </OrderStyledIconButton>
              <OrderStyledIconButton
                component={Link}
                to={appRoutes.orders.invoicesDocsListView}
              >
                <img
                  src={DOCUMENTS}
                  className="pull-right cursor-field"
                  alt=""
                />
              </OrderStyledIconButton>
            </Box>
          ) : (
            <Box
              gap={2}
              justifyContent={"flex-end"}
              sx={{
                display: {
                  xs: "none",
                  lg: "flex"
                }
              }}
            >
              <OrderButton
                variant="outlined"
                component={Link}
                to={appRoutes.orders.invoicesDocsListView}
              >
                {order_table_labels.viewInvoicesAndDocs}
              </OrderButton>
              {isEdit ? (
                <OrderButton
                  variant={"soft"}
                  onClick={() => {
                    setIsEdit(false);
                    updatedOrderTotalData.remove();
                  }}
                >
                  {order_table_labels.cancel}
                </OrderButton>
              ) : (
                <OrderButton
                  variant={"contained"}
                  onClick={() => setIsEdit(true)}
                >
                  {order_table_labels.modifyOrder}
                </OrderButton>
              )}
            </Box>
          )}
        </Grid>
        <Grid item xs={12} display={{ lg: "flex" }}>
          <Box display="flex" pb={1} gap={0.5}>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 400,
                lineHeight: "20px"
              }}
            >
              {`${order_table_labels.orderId} :`}
            </Typography>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 500,
                lineHeight: "20px"
              }}
            >
              {orderData?.order?.warehouseOrder?.code}
            </Typography>
          </Box>
          <Box display="flex" pb={1}>
            <Typography
              sx={{
                ml: { lg: 3 },
                fontSize: 14,
                fontWeight: 400,
                lineHeight: "20px"
              }}
            >
              {order_table_labels.orderDate}:&nbsp;
            </Typography>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 500,
                lineHeight: "20px"
              }}
            >
              {moment(orderData?.order?.recentOrderItem?.createdAt).format(
                "DD MMM’YY"
              )}
            </Typography>
          </Box>
        </Grid>
        {isEdit && (
          <Grid item xs={12} display={{ lg: "flex" }}>
            <StyledEditMessagedBox>
              <InfoIcon color={"warning"} />
              <Typography
                color={"text.primary"}
                sx={{
                  fontSize: 14,
                  fontWeight: 400,
                  lineHeight: "20px"
                }}
              >
                {order_table_labels.orderEditModeWarningMsg}
              </Typography>
            </StyledEditMessagedBox>
          </Grid>
        )}
        <Grid item xs={12}>
          <OrderDetailsLocationAndDate
            isEdit={isEdit}
            control={control}
            orderData={orderData?.order}
            updatedOrderTotalData={updatedOrderTotalData}
          />
        </Grid>
        {isMobileDevice && <MoreDetails isEdit={isEdit} />}
        <Grid item xs={12}>
          <OrderDetailsPayment
            isEdit={isEdit}
            cancelEdit={() => {
              setIsEdit(false);
              updatedOrderTotalData.remove();
            }}
            orderData={orderData?.order}
            updatedOrderData={updatedOrderTotalData.data}
            isFetchedUpdatedOrderData={updatedOrderTotalData.isFetched}
            modifyOrder={handleModifyOrder}
            isLoading={isLoading || isCompleteOrderLoading}
          />
        </Grid>
        {!isMobileDevice && <MoreDetails />}
      </Grid>
    </Box>
  );
};

export default OrderDetails;
