import { getCustomersSubtotal } from "../../../sales/services";
import { queryKeys } from "../../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchCustomersSubtotal = (params) => {
  const queryCustomerSubtotal = useQuery(
    [queryKeys.queryFetchCustomersSubtotal, JSON.stringify(params)],
    () => getCustomersSubtotal(params),
    {
      refetchOnWindowFocus: false
    }
  );

  return queryCustomerSubtotal;
};
