import { getClosedInquiry } from "../../customer/services";
import { queryKeys } from "../../constants/queryKeys";
import { useInfiniteQuery } from "@tanstack/react-query";

export const useFetchClosedInquiry = () => {
  const queryClosedInquiry = useInfiniteQuery(
    [queryKeys.queryFetchPastInquiry],
    ({ pageParam = 1 }) => getClosedInquiry(pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage =
          lastPage?.inquiries.length === lastPage?.pagination.limit
            ? allPages.length + 1
            : undefined;

        return nextPage;
      },
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true
    }
  );

  return queryClosedInquiry;
};
