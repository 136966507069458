import { Box, Button, styled } from "@mui/material";

import BannerImageDesktop from "../../assets/banner.webp";

export const StyledHomButton = styled(
  ({ bgColor, isLanding = false, ...rem }) => (
    <Button
      {...rem}
      variant="contained"
      sx={{
        fontSize: {
          xs: isLanding ? 14 : 20,
          md: isLanding ? 24 : 32
        },
        lineHeight: {
          xs: isLanding ? "17px" : "24px",
          md: isLanding ? "29px" : "39px"
        },
        width: {
          xs: 160,
          md: 260
        },
        height: {
          xs: 64,
          md: 100
        },
        backgroundColor: bgColor
      }}
    />
  )
)(({ theme }) => ({
  fontWeight: 700,
  borderRadius: 0,
  opacity: 0.99
}));

const LandingBanner = () => {
  const imageWidth = 3612;
  const imageHeight = 3300; 
  const aspectRatio = imageWidth / imageHeight;
  return (
    <Box
      position="relative"
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="auto"
    >
      <Box
        sx={{
          display: {
            xs: "block",
            md: "flex"
          },
          mt: {
            xs: "28px",
            md: 0
          },
          width: {
            xs: "100%",
            md: "600px"
          },
          height: "auto", // Ensure the height adjusts automatically
          overflow: "hidden" // Prevent overflow
        }}
      >
        <img
          loading="lazy"
          src={BannerImageDesktop}
          alt="warex"
          width={imageWidth} // Set explicit width and height attributes
          height={imageHeight}
          style={{
            width: "100%",
            height: "auto",
            aspectRatio: `${aspectRatio}`,
            objectFit: "cover",
            borderRadius: "8px" // Add border-radius if needed
          }}
        />
      </Box>
    </Box>
  );
};

export default LandingBanner;
