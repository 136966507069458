import {
  AddCircleOutline,
  BorderColorOutlined,
  RemoveCircleOutline
} from "@mui/icons-material";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";

import { labelKeys } from "./label-keys";
import { useContext } from "react";
import { AppContext } from "../../shared/context/auth-context";

const OrderInfo = ({ info }) => {
  const renderOrderDetails = () => {
    const orderDetails = [];
    for (const key in info) {
      orderDetails.push(
        <Box key={key} display={"flex"} gap={"4px"}>
          <Typography fontSize={14} fontWeight={600} lineHeight={"16.94px"}>
            {labelKeys[key]} :
          </Typography>
          <Typography
            fontSize={13}
            fontWeight={400}
            lineHeight={"18px"}
            color={"text.secondary"}
          >
            {info[key]}
          </Typography>
        </Box>
      );
    }
    return orderDetails;
  };
  return (
    <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
      {renderOrderDetails()}
    </Box>
  );
};

const OtherInfo = ({ info, title }) => {
  const renderOtherDetails = () => {
    const otherDetails = [];
    for (const key in info) {
      otherDetails.push(
        <Typography
          key={key}
          fontSize={key === "customerName" ? 14 : 13}
          fontWeight={key === "customerName" ? 600 : 400}
          lineHeight={"18px"}
          color={"text.secondary"}
        >
          {info[key]}
        </Typography>
      );
    }
    return otherDetails;
  };
  return (
    <Box display={"flex"} flexDirection={"column"} gap={"12px"}>
      <Typography
        fontWeight={600}
        lineHeight={"19.36px"}
        letterSpacing={"0.5%"}
        color={"primary.dark"}
      >
        {title}
      </Typography>
      <Box display={"flex"} flexDirection={"column"} gap={"8px"} maxWidth={175}>
        {renderOtherDetails()}
      </Box>
    </Box>
  );
};

const OrderReport = ({
  title,
  orderInfo,
  sellerInfo,
  sellerTitle,
  buyerInfo,
  buyerTitle,
  shippingTitle,
  shipInfo,
  children,
  actions,
  onAdd,
  onRemove,
  onEdit,
  onSubmit,
  onSaveDraft,
  onModify,
  isDraft
}) => {
  const { profileData } = useContext(AppContext);
  const { logo } = profileData?.user?.client;

  return (
    <>
      {/* Web view */}
      {isDraft && (
        <Box display={"flex"} justifyContent={"flex-end"}>
          {actions?.includes("Add") && (
            <IconButton onClick={onAdd}>
              <AddCircleOutline color="primary" />
            </IconButton>
          )}
          {actions?.includes("Remove") && (
            <IconButton onClick={onRemove}>
              <RemoveCircleOutline color="error" />
            </IconButton>
          )}
          {actions?.includes("Edit") && (
            <IconButton onClick={onEdit}>
              <BorderColorOutlined color="warning" />
            </IconButton>
          )}
          <Box display={"flex"} gap={2} marginLeft={2}>
            {actions?.includes("SaveDraft") && (
              <Button variant="link" disableRipple onClick={onSaveDraft}>
                Save Draft
              </Button>
            )}
            {actions?.includes("Submit") && (
              <Button variant="link" disableRipple onClick={onSubmit}>
                Submit
              </Button>
            )}
            {actions?.includes("Reset") && (
              <Button
                variant="link"
                disableRipple
                onClick={onModify}
                sx={{ color: "green" }}
              >
                {"Reset"}
              </Button>
            )}
          </Box>
        </Box>
      )}
      <Box
        padding={3}
        sx={{
          border: "0.7px solid #6097FF",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          gap: 3
        }}
      >
        <Typography
          fontSize={20}
          fontWeight={500}
          lineHeight={"24.2px"}
          letterSpacing={"0.2%"}
          textAlign={"center"}
        >
          {title}
        </Typography>
        <Grid container display={"flex"} justifyContent={"space-between"}>
          <Grid
            item
            xs={4}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-between"}
            gap={2}
          >
            {orderInfo && <OrderInfo info={orderInfo} />}
            {buyerInfo && <OtherInfo info={buyerInfo} title={buyerTitle} />}
          </Grid>
          {logo?.thumbUrl && (
            <Grid
              item
              xs={4}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <img src={logo?.thumbUrl} alt="Company Logo" />
            </Grid>
          )}
          <Grid
            item
            xs={4}
            display={"flex"}
            flexDirection={"column"}
            gap={2}
            justifyContent={"flex-end"}
            alignItems={"flex-end"}
          >
            {sellerInfo && <OtherInfo info={sellerInfo} title={sellerTitle} />}
            {shipInfo && <OtherInfo info={shipInfo} title={shippingTitle} />}
          </Grid>
        </Grid>
        <Box display={"flex"} flexDirection={"column"} gap={2}>
          {children}
        </Box>
      </Box>
    </>
  );
};

export default OrderReport;
