import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";
import { KeyboardArrowRight } from "@mui/icons-material";
import { getCustomCell } from "../../pages/PurchaseOrders";
import appRoutes from "../../../constants/appRoutes";
import { stringCapitalization } from "../../../shared/utils";
import { StyledChip } from "../../../shared/UiElements/StatusChip";

export const MobileAdjustmentOrders = (row) => {
  const { onAction } = row;

  return (
    <Grid container spacing={"20px"}>
      <Grid item xs={12} container rowSpacing={2}>
        <Grid item xs={6}>
          {getCustomCell(
            row?.docNo,
            "",
            `${appRoutes.purchase.main}/${appRoutes.purchase.stockAdjustment}/${row.SA_id}/manage-order`
          )}
        </Grid>
        <Grid item xs={6} display={"flex"} justifyContent={"right"}>
          <Typography
            sx={{ fontSize: 14, lineHeight: "20px", fontWeight: 400 }}
          >
            {row.adjustmentDate}
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            alignItems: "center"
          }}
        >
          <Typography fontSize={12} lineHeight={"14.52px"}>
            <span style={{ color: "#B7BEC7" }}>{"By "}</span>
            {row.preparedBy}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            display: "flex",
            justifyContent: "right",
            alignItems: "center"
          }}
        >
          <Typography fontSize={12} lineHeight={"14.52px"}>
            {row.warehouse}
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            display: "flex",
            justifyContent: "right",
            alignItems: "center"
          }}
        >
          <IconButton
            color="primary"
            disabled={!row?.isAdmin || row?.orderProgress !== "Placed"}
            onClick={() =>
              row?.isAdmin &&
              row?.orderProgress === "Placed" &&
              onAction(row?.SA_id)
            }
          >
            <KeyboardArrowRight />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={6}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={0.5}
            justifyContent={"flex-end"}
            alignItems={"flex-start"}
          >
            <StyledChip
              label={stringCapitalization(row.orderProgress)}
              variant="text"
            />
            <Typography
              fontSize={12}
              lineHeight={"16px"}
              color={"text.disabled"}
              pl={"20px"}
            >
              Status
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            justifyContent: "right",
            alignItems: "center"
          }}
        >
          {Boolean(row?.approvedBy) && (
            <Typography fontSize={12} lineHeight={"14.52px"}>
              <span style={{ color: "#B7BEC7" }}>{"By "}</span>
              {row.approvedBy}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
