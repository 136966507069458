import { Box, Typography } from "@mui/material";

const ProductFeatureItem = (props) => {
  const { id, image, label, subtext } = props;
  return (
    <Box
      id={id}
      position={"relative"}
      display={"flex"}
      flexDirection={"column"}
      overflow={"hidden"}
    >
      <Box
        display={"flex"}
        sx={{
          width: "100%",
          position: "relative",
          paddingTop: "100%",
          overflow: "hidden"
        }}
      >
        <img
          loading="lazy"
          src={image}
          alt={label}
          width="100%"
          height="100%"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            objectFit: "contain" // Ensures the entire image fits within the box
          }}
        />
      </Box>
      <Box
        sx={{
          paddingTop: {
            xs: "7px",
            md: "10px"
          },
          width: "100%"
        }}
        textAlign={"center"}
      >
        <Typography
          color={"#0F2744"}
          sx={{
            fontSize: {
              xs: 16,
              md: 28
            },
            lineHeight: {
              xs: "15px",
              md: "22px"
            },
            fontWeight: {
              xs: 500,
              md: 500
            },
            marginTop: {
              xs: 0.8,
              md: 0
            }
          }}
          letterSpacing={"0.002em"}
        >
          {label}
        </Typography>
        <Typography
          color={"#0F2744"}
          sx={{
            fontSize: {
              xs: 12,
              md: 18
            },
            lineHeight: {
              xs: "15px",
              md: "22px"
            },
            fontWeight: {
              xs: 500,
              md: 500
            },
            marginY: {
              xs: 0.2,
              md: 4
            }
          }}
          letterSpacing={"0.002em"}
        >
          {subtext}
        </Typography>
      </Box>
    </Box>
  );
};

export default ProductFeatureItem;
