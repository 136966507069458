import { createTheme } from "@mui/material";
import { globalTheme } from "./globalTheme";

export const checkboxSelectTheme = createTheme({
  ...globalTheme,
  components: {
    ...globalTheme.components,
    MuiPopover: {
      styleOverrides: {
        paper: {
          background: "#FFFFFF",
          boxShadow: "0px 8px 24px rgba(21, 21, 22, 0.1)",
          borderRadius: 8,
          marginTop: 9,
          "& > .MuiList-root": {
            padding: "10px 8px",
            "& > li": {
              background: "#FFFFFF",
              borderRadius: 8,
              fontWeight: 400,
              fontSize: 14,
              padding: "3px 6px",
              lineHeight: "150%",
              color: "#0F0F0F",
              "&:hover": {
                background: "transparent",
              },
              "&.Mui-selected": {
                background: "transparent",
                fontWeight: 600,
              },
            },
          },
        },
      },
    },
  },
});
