import { Box, Divider, Grid, Typography } from "@mui/material";
import { labels, order_table_labels } from "../../../constants";

import { LoadingButton } from "@mui/lab";
import PaymentIcon from "@mui/icons-material/Payment";
import { currencyFormatter } from "../../../shared/utils";
import { isMobileDevice } from "../../../App";
import styled from "@emotion/styled";

const StyleBorderedBox = styled((props) => (
  <Box
    {...props}
    sx={{
      ...props.sx,
      p: 2,
      height: "fit-content",
      border: "0.4px solid #95A1B0",
      borderRadius: "8px"
    }}
  />
))(({ theme }) => ({}));

const StyledPaymentButton = styled((props) => (
  <LoadingButton
    {...props}
    fullWidth
    sx={{
      mt: { xs: 1, lg: 3 },
      paddingTop: "22.5px",
      paddingBottom: "22.5px",
      fontSize: 16,
      fontWeight: 500,
      lineHeight: "19.36px",
      letterSpacing: "0.005em",
      height: { xs: 48, lg: 64 },
      whiteSpace: "nowrap",
      textTransform: "none"
    }}
  />
))(() => ({}));

const CardDetails = () => {
  return (
    <>
      <Typography
        sx={{
          fontSize: 16,
          fontWeight: 600,
          lineHeight: "20px",
          pb: 2
        }}
      >
        {labels.paymentMethod}
      </Typography>
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: 400,
          lineHeight: "20px",
          pb: 1,
          display: "flex",
          alignItems: "center"
        }}
      >
        <PaymentIcon /> &nbsp; {"Ending in 5373"}
      </Typography>
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: 400,
          lineHeight: "20px"
        }}
      >
        {"Transaction ID : XXXX1234"}
      </Typography>
    </>
  );
};

const PaymentSumUp = ({ service, detailsList = [], total }) => {
  return (
    <Box pt={2}>
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: 400,
          lineHeight: "20px"
        }}
      >
        {service + " " + order_table_labels.order}:
      </Typography>
      {detailsList.map((item, index) => (
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          px={2}
          key={index}
        >
          <Typography
            px={2}
            py={0.5}
            color={"text.secondary"}
            sx={{
              fontSize: 14,
              fontWeight: 400,
              lineHeight: "20px"
            }}
          >
            {item?.label}
          </Typography>
          <Typography
            textAlign={"center"}
            px={2}
            py={0.5}
            color={"text.secondary"}
            sx={{
              fontSize: 14,
              fontWeight: 400,
              lineHeight: "20px"
            }}
          >
            {currencyFormatter(parseFloat(item?.amount).toFixed(2))}
          </Typography>
        </Box>
      ))}
      <Box display={"flex"} justifyContent={"space-between"} py={1}>
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 400,
            lineHeight: "20px"
          }}
        >
          {service + " " + labels.total}:
        </Typography>
        <Typography
          mr={4}
          textAlign={"center"}
          sx={{
            fontSize: 16,
            fontWeight: 600,
            lineHeight: "20px"
          }}
        >
          {currencyFormatter(parseFloat(total).toFixed(2))}
        </Typography>
      </Box>
    </Box>
  );
};

const OrderSummary = ({
  isEdit,
  cancelEdit = () => {},
  orderData,
  updatedOrderData,
  isFetched,
  onPayment,
  isLoading
}) => {
  const recentOrderItem =
    isEdit && isFetched
      ? updatedOrderData?.recentOrderItem
      : orderData?.recentOrderItem;

  const getTotalAmount = (arr) => {
    return arr ? arr.reduce((acc, obj) => acc + obj.totalAmount, 0) : 0;
  };

  const warehouseDetails = [
    {
      label: order_table_labels.storageCosts,
      amount: getTotalAmount(recentOrderItem?.warehouseCharges?.services)
    },
    {
      label: order_table_labels.additionalCosts,
      amount: getTotalAmount(
        recentOrderItem?.warehouseCharges?.additionalServices
      )
    },
    {
      label: labels.gst,
      amount: getTotalAmount(recentOrderItem?.warehouseCharges?.taxes)
    }
  ];
  const transportDetails = [
    {
      label: order_table_labels.storageCosts,
      amount: getTotalAmount(recentOrderItem?.transportCharges?.services)
    },
    {
      label: order_table_labels.additionalCosts,
      amount: getTotalAmount(
        recentOrderItem?.transportCharges?.additionalServices
      )
    },
    {
      label: labels.gst,
      amount: getTotalAmount(recentOrderItem?.transportCharges?.taxes)
    }
  ];

  const warehouseTotal = recentOrderItem?.warehouseCharges?.totalAmount ?? 0;
  const transportTotal = recentOrderItem?.transportCharges?.totalAmount ?? 0;
  const previousTotalPaidAmount = orderData?.recentOrderItem?.totalAmount ?? 0;
  const updatedTotalPaidAmount =
    updatedOrderData?.recentOrderItem?.totalAmount ?? 0;
  const grandTotal = recentOrderItem?.totalAmount ?? 0;

  return (
    <>
      <Typography
        sx={{
          fontSize: 16,
          fontWeight: 600,
          lineHeight: "20px"
        }}
      >
        {labels.orderSummary}
      </Typography>
      {recentOrderItem?.warehouseCharges && (
        <>
          <PaymentSumUp
            service={labels.warehouse}
            detailsList={warehouseDetails}
            total={warehouseTotal}
          />
          <Divider sx={{ border: "0.4px solid #B7BEC7" }} />
        </>
      )}
      {recentOrderItem?.transportCharges && (
        <>
          <PaymentSumUp
            service={labels.transport}
            detailsList={transportDetails}
            total={transportTotal}
          />
          <Divider sx={{ border: "0.4px solid #B7BEC7" }} />
        </>
      )}

      {isEdit && isFetched ? (
        <>
          <Box display={"flex"} justifyContent={"space-between"} py={1}>
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: 600,
                lineHeight: "20px"
              }}
            >
              {order_table_labels.previousTotal}:
            </Typography>
            <Typography
              mr={4}
              textAlign={"center"}
              sx={{
                fontSize: 16,
                fontWeight: 600,
                lineHeight: "20px"
              }}
            >
              {currencyFormatter(
                parseFloat(previousTotalPaidAmount).toFixed(2)
              )}
            </Typography>
          </Box>
          <Box display={"flex"} justifyContent={"space-between"} py={1}>
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: 600,
                lineHeight: "20px"
              }}
            >
              {order_table_labels.updatedTotal}:
            </Typography>
            <Typography
              mr={4}
              textAlign={"center"}
              sx={{
                fontSize: 16,
                fontWeight: 600,
                lineHeight: "20px"
              }}
            >
              {currencyFormatter(parseFloat(updatedTotalPaidAmount).toFixed(2))}
            </Typography>
          </Box>
        </>
      ) : (
        <Box display={"flex"} justifyContent={"space-between"} py={1}>
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 600,
              lineHeight: "20px"
            }}
          >
            {labels.grandTotal}:
          </Typography>
          <Typography
            mr={4}
            textAlign={"center"}
            sx={{
              fontSize: 16,
              fontWeight: 600,
              lineHeight: "20px"
            }}
          >
            {currencyFormatter(parseFloat(grandTotal).toFixed(2))}
          </Typography>
        </Box>
      )}
      {isEdit && isFetched && (
        <>
          <StyledPaymentButton
            variant="contained"
            loading={isLoading}
            onClick={onPayment}
            disabled={updatedTotalPaidAmount <= previousTotalPaidAmount}
          >
            {order_table_labels.pay}
            <Typography
              sx={{
                px: 1,
                fontSize: 16,
                fontWeight: 700,
                lineHeight: "20px"
              }}
            >
              {currencyFormatter(
                parseFloat(
                  updatedTotalPaidAmount === 0
                    ? 0
                    : updatedTotalPaidAmount - previousTotalPaidAmount
                ).toFixed(2)
              )}
            </Typography>
            {order_table_labels.toContinue}
          </StyledPaymentButton>
          {isMobileDevice && (
            <StyledPaymentButton variant="soft" onClick={cancelEdit}>
              {order_table_labels.cancel}
            </StyledPaymentButton>
          )}
        </>
      )}
    </>
  );
};

const OrderDetailsPayment = ({
  isEdit,
  cancelEdit,
  orderData,
  updatedOrderData,
  isFetchedUpdatedOrderData,
  modifyOrder,
  isLoading
}) => {
  return (
    <StyleBorderedBox>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={7}>
          <CardDetails />
        </Grid>
        <Grid item xs={12} display={{ xs: "block", lg: "none" }}>
          <Divider
            orientation={"horizontal"}
            sx={{
              border: "0.4px solid #B7BEC7"
            }}
          />
        </Grid>
        <Grid item xs={12} lg={5}>
          <OrderSummary
            isEdit={isEdit}
            cancelEdit={cancelEdit}
            orderData={orderData}
            updatedOrderData={updatedOrderData}
            isFetched={isFetchedUpdatedOrderData}
            onPayment={modifyOrder}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </StyleBorderedBox>
  );
};

export default OrderDetailsPayment;
