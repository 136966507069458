import { queryKeys } from "../../constants/queryKeys";
import { getDCDeliveredFormData } from "../../inventory/services";
import { useQuery } from "@tanstack/react-query";

export const useFetchDeliveredFormData = (orderId, allowAccess) => {
  const DCs = useQuery(
    [queryKeys.queryFetchDCDeliveredFormData, orderId, allowAccess],
    () => getDCDeliveredFormData(orderId),
    {
      enabled: allowAccess && Boolean(orderId),
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return DCs;
};
