import { queryKeys } from "../../constants/queryKeys";
import { getMrrInCreateFormData } from "../../finance/services";
import { useQuery } from "@tanstack/react-query";

export const useFetchMrrInCreateFormData = (orderId, allowAccess) => {
  const MrrInFormData = useQuery(
    [queryKeys.queryFetchMrrInCreateFormData, orderId, allowAccess],
    () => getMrrInCreateFormData(orderId),
    {
      enabled: allowAccess && Boolean(orderId),
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return MrrInFormData;
};
