import * as yup from "yup";

import { Close, ContentCopyRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import { error_msg, sku_labels } from "../../../constants";
import styled from "@emotion/styled";
import { useForm } from "react-hook-form";
import TextInput from "../../../shared/formElements/TextInput";
import DropDown from "../../../shared/formElements/DropDown";
import { sku_filterKeys } from "../../../constants/formKeys";
import SkuImageUpload from "../../../shared/formElements/SkuImageUpload";
import { dropdownOptions } from "../../../shared/utils";
import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useDrawer } from "../../../shared/customSideDrawer";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCustomDialog } from "../../../shared/customDialog";
import StockLevelByWarehouseForm from "./StockLevelByWarehouseForm";
import AddNewSKU from "./AddNewSKU";
import { isMobile, isTablet } from "react-device-detect";

export const HeaderText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 20,
  fontFamily: "Inter",
  lineHeight: "24.2px",
  fontWeight: 500
}));

export const DrawerButton = styled(({ ...props }) => (
  <Button
    {...props}
    variant="contained"
    sx={{
      fontWeight: 500,
      fontSize: "24px",
      width: "100%",
      height: 81,
      borderRadius: 0
    }}
  />
))();

const getCustomBeginningStock = (value, docCount = null) => {
  return (
    <Box display={"flex"} flexDirection={"column"} gap={0.5}>
      <Box display={"flex"}>
        <Typography fontSize={12} color={"#2773FF"}>
          {value}
        </Typography>
        {Boolean(docCount) && (
          <Typography
            component="span"
            sx={{
              verticalAlign: "super",
              fontSize: "0.5em",
              color: "#2773FF"
            }}
          >
            {`+${docCount}`}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

const schema = yup.object({
  [sku_filterKeys.productName]: yup.string().required(error_msg.required),
  [sku_filterKeys.units]: yup.string().required(error_msg.required),
  [sku_filterKeys.sku]: yup.string().required(error_msg.required),
  [sku_filterKeys.gst]: yup.string().required(error_msg.required),
  [sku_filterKeys.listPrice]: yup.string().required(error_msg.required),
  [sku_filterKeys.minimumStockLevel]: yup.string().required(error_msg.required)
});

const skuString = (strings) => {
  return strings
    .filter((str) => str !== "")
    .map((str) => str && str.slice(0, 3).toUpperCase())
    .join("-");
};

const SkuDetailsForm = ({
  title,
  skuId = "",
  cloneSku = false,
  defaultValues = "",
  isLoading,
  isFetching = false,
  onSubmit,
  beginningStock,
  setBeginningStock,
  refetch,
  formData,
  isFormFetching
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch
  } = useForm({ resolver: yupResolver(schema) });

  const [isEditEnabled, setIsEditEnabled] = useState(cloneSku);
  const [stockError, setStockError] = useState(false);
  const [showImg, setShowImg] = useState(true);

  const handleShowImg = () => {
    setShowImg((prev) => !prev);
  };

  const { showDrawer, hideDrawer } = useDrawer();
  const { showDialog, hideDialog } = useCustomDialog();

  const brand = watch(sku_filterKeys.brandName);
  const category = watch(sku_filterKeys.categoryName);
  const product = watch(sku_filterKeys.productName);
  const attribute = watch(sku_filterKeys.attribute1);

  useEffect(() => {
    if (!defaultValues || brand || category || product || attribute) {
      setValue(
        sku_filterKeys.sku,
        skuString([brand, category, product, attribute])
      );
    }
  }, [defaultValues, brand, category, product, attribute, setValue]);

  useEffect(() => {
    if (!isFetching && defaultValues) {
      setValue(sku_filterKeys.productName, defaultValues.productName);
      defaultValues.brandName &&
        setValue(sku_filterKeys.brandName, defaultValues.brandName);
      defaultValues.categoryName &&
        setValue(sku_filterKeys.categoryName, defaultValues.categoryName);
      defaultValues.customAttributes[0]?.value &&
        setValue(
          sku_filterKeys.attribute1,
          defaultValues.customAttributes[0]?.value
        );
      defaultValues.customAttributes[1]?.value &&
        setValue(
          sku_filterKeys.attribute2,
          defaultValues.customAttributes[1]?.value
        );
      defaultValues.customAttributes[2]?.value &&
        setValue(
          sku_filterKeys.attribute3,
          defaultValues.customAttributes[2]?.value
        );
      setValue(sku_filterKeys.units, defaultValues.unit.name);
      setValue(sku_filterKeys.gst, defaultValues.gstRegime);
      setValue(sku_filterKeys.sku, defaultValues.skuCode);
      setValue(sku_filterKeys.minimumStockLevel, defaultValues.minStockLevel);
      setValue(sku_filterKeys.listPrice, defaultValues.rate);
      defaultValues.upc && setValue(sku_filterKeys.upc, defaultValues.upc);
      defaultValues.hsn && setValue(sku_filterKeys.hsn, defaultValues.hsn);
    }
  }, [defaultValues, setValue, isFetching, cloneSku]);

  const onAddClick = () => {
    showDialog({
      component: (
        <StockLevelByWarehouseForm
          warehouses={formData?.warehouses}
          beginningStock={beginningStock}
          hideDialog={hideDialog}
          setBeginningStock={setBeginningStock}
          isEditEnabled={isEditEnabled}
        />
      ),
      backdropOff: true,
      closeIcon: true
    });
  };

  const onSaveClick = () => {
    if (!Boolean(beginningStock?.length)) {
      setStockError(true);
      handleSubmit(onSaveClick)();
      return;
    }
    handleSubmit(onSubmit)();
  };

  const handleCopyClick = (props) => {
    showDrawer({
      ...props,
      component: (
        <AddNewSKU
          defaultValues={defaultValues}
          hideDrawer={hideDrawer}
          refetch={refetch}
        />
      ),
      closeIcon: false
    });
  };

  useEffect(() => {
    if (Boolean(beginningStock.length)) {
      setStockError(false);
    }
  }, [beginningStock]);

  return isFetching ? (
    <Grid
      item
      xs={12}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      height={"100%"}
    >
      <CircularProgress />
    </Grid>
  ) : (
    <Grid container>
      <Grid
        item
        xs={12}
        container
        sx={{ padding: { xs: "20px", md: "30px 40px 30px 40px" } }}
        rowSpacing={2}
      >
        <Grid item xs={12}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <HeaderText>
              {title}
              {cloneSku && (
                <IconButton
                  onClick={() =>
                    handleCopyClick(
                      isMobile || isTablet
                        ? {
                            height: "fit-content"
                          }
                        : {}
                    )
                  }
                >
                  <ContentCopyRounded sx={{ color: "#2773FF" }} />
                </IconButton>
              )}
            </HeaderText>
            <IconButton onClick={hideDrawer}>
              <Close />
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={12} container rowSpacing={1} columnSpacing={6}>
          <Grid item xs={7}>
            <Box display={"flex"} flexDirection={"column"} gap={2}>
              <TextInput
                control={control}
                name={sku_filterKeys.productName}
                label={sku_labels.productName}
                mandatoryField={true}
                disabled={isEditEnabled}
              />
              <Box display={"flex"} width={"70%"}>
                <TextInput
                  control={control}
                  name={sku_filterKeys.brandName}
                  label={sku_labels.brandName}
                  disabled={isEditEnabled}
                  onlyCaps={true}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={5}>
            {showImg && cloneSku && defaultValues?.image?.thumbUrl ? (
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <img
                  src={defaultValues?.image?.thumbUrl}
                  alt={defaultValues?.image?.originalName}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover"
                  }}
                />
                {!isEditEnabled && (
                  <Button variant="link" onClick={handleShowImg}>
                    Edit Image
                  </Button>
                )}
              </Box>
            ) : (
              <>
                <SkuImageUpload
                  name={sku_filterKeys.imageUpload}
                  control={control}
                  uploadButtonText={sku_labels.uploadButtonText}
                  setValue={setValue}
                  isEditEnabled={isEditEnabled}
                />
                <FormHelperText error>
                  {errors.imageUpload?.message}
                </FormHelperText>
              </>
            )}
          </Grid>
          <Grid item xs={6}>
            <TextInput
              control={control}
              name={sku_filterKeys.categoryName}
              label={sku_labels.categoryName}
              disabled={isEditEnabled}
              onlyCaps={true}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              control={control}
              name={sku_filterKeys.attribute1}
              label={sku_labels.attribute1}
              disabled={isEditEnabled}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              control={control}
              name={sku_filterKeys.attribute2}
              label={sku_labels.attribute2}
              disabled={isEditEnabled}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              control={control}
              name={sku_filterKeys.attribute3}
              label={sku_labels.attribute3}
              disabled={isEditEnabled}
            />
          </Grid>
          <Grid item xs={6}>
            <DropDown
              control={control}
              name={sku_filterKeys.units}
              label={sku_labels.units}
              mandatoryField={true}
              options={formData?.units}
              disabled={isEditEnabled}
              isLoading={isFormFetching}
              allowSearch
            />
          </Grid>
          <Grid item xs={6}>
            <DropDown
              control={control}
              name={sku_filterKeys.gst}
              label={sku_labels.gst}
              mandatoryField={true}
              options={dropdownOptions(formData?.gstRegimes)}
              disabled={isEditEnabled}
              isLoading={isFormFetching}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              control={control}
              name={sku_filterKeys.sku}
              label={sku_labels.sku}
              mandatoryField={true}
              disabled={isEditEnabled}
              onlyCaps={true}
            />
          </Grid>
          <Grid item xs={6}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              height={"100%"}
              minWidth={"40%"}
              gap={1}
            >
              <Typography
                sx={{
                  fontSize: 14,
                  lineHeight: "17px",
                  color: Boolean(skuId) ? "#95A1B0" : "text.primary"
                }}
                textAlign={"left"}
              >
                {"Beginning Stock"}
                {
                  <span
                    style={{ color: Boolean(skuId) ? "#95A1B0" : "#F05454" }}
                  >
                    {" "}
                    *
                  </span>
                }
              </Typography>
              <Box
                sx={{
                  border: Boolean(skuId)
                    ? "1px solid #F0F2F5"
                    : "1px solid #B7BEC7",
                  borderRadius: "8px",
                  padding: "11px 5px 12px 10px",
                  fontWeight: 500
                }}
              >
                {Boolean(beginningStock.length) ? (
                  <div onClick={onAddClick}>
                    {getCustomBeginningStock(
                      `${beginningStock[0]?.code} - ${beginningStock[0]?.quantity}`,
                      beginningStock.length - 1
                    )}
                  </div>
                ) : (
                  <Box display={"flex"} justifyContent={"right"}>
                    <Button
                      variant="link"
                      disableRipple
                      onClick={onAddClick}
                      disabled={Boolean(skuId)}
                    >
                      {"Add"}
                    </Button>
                  </Box>
                )}
              </Box>
              {stockError && (
                <FormHelperText error>{"Add Stocks"}</FormHelperText>
              )}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <TextInput
              control={control}
              name={sku_filterKeys.hsn}
              label={sku_labels.hsn}
              disabled={isEditEnabled}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              control={control}
              name={sku_filterKeys.upc}
              label={sku_labels.upc}
              disabled={isEditEnabled}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              control={control}
              name={sku_filterKeys.listPrice}
              label={sku_labels.listPrice}
              mandatoryField={true}
              disabled={isEditEnabled}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              control={control}
              name={sku_filterKeys.minimumStockLevel}
              label={sku_labels.minimumStockLevel}
              mandatoryField={true}
              disabled={isEditEnabled}
            />
          </Grid>
        </Grid>
      </Grid>
      {cloneSku ? (
        isEditEnabled ? (
          <DrawerButton onClick={() => setIsEditEnabled(false)}>
            {"Edit"}
          </DrawerButton>
        ) : (
          <DrawerButton
            component={LoadingButton}
            loading={isLoading}
            onClick={handleSubmit(onSubmit)}
          >
            {"Save"}
          </DrawerButton>
        )
      ) : (
        <DrawerButton
          component={LoadingButton}
          loading={isLoading}
          onClick={onSaveClick}
        >
          {"Save"}
        </DrawerButton>
      )}
    </Grid>
  );
};

export default SkuDetailsForm;
