import { queryKeys } from "../../constants/queryKeys";
import { getVendors } from "../../purchase/services";
import { useInfiniteQuery } from "@tanstack/react-query";

export const useFetchVendors = (params) => {
  const Vendors = useInfiniteQuery(
    [queryKeys.queryFetchVendors, Boolean(params)],
    ({ pageParam = 1 }) => getVendors(params, pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage =
          lastPage?.vendors.length === lastPage?.pagination.limit
            ? allPages.length + 1
            : undefined;

        return nextPage;
      },
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true
    }
  );

  return Vendors;
};
