import {
  Box,
  Button,
  Card,
  CardMedia,
  Divider,
  Grid,
  Tooltip,
  Typography,
  styled
} from "@mui/material";
import { currencyFormatter, stringCapitalization } from "../../../shared/utils";
import { labels, statusKeys } from "../../../constants";

import { AppContext } from "../../../shared/context/auth-context";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import { StyledChip } from "../../../shared/UiElements/StatusChip";
import appRoutes from "../../../constants/appRoutes";
import { inquiryKeyMap } from "../../../constants/formKeys";
import { isMobileDevice } from "../../../App";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Storage from "../../../shared/utils/Storage";
import { StorageKeys } from "../../../constants/storage-keys";

const type = {
  warehouseRfq: "warehouse",
  transportRfq: "transport"
};

const StyledCard = styled(Card)(({ theme }) => ({
  border: "0.4px solid #B7BEC7",
  borderRadius: 8,
  padding: "12px",
  // height: 160,
  width: "auto",
  boxShadow: "0px 8px 24px rgba(21, 21, 22, 0.1)",
  [theme.breakpoints.up("lg")]: {
    boxShadow: "none",
    minWidth: 440
  }
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  height: 135,
  width: 80,
  borderRadius: 8
}));

const StyledPrimaryText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary
}));

const StyledSecondaryText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 13,
  fontWeight: 400
}));

export default function InquiryCartCard(props) {
  const status =
    props?.status.name === statusKeys.OrderBooked
      ? props?.status.name
      : props?.responseStatus.name;

  const isResponded = status === "Responded";
  const auth = useContext(AppContext);

  const navigate = useNavigate();

  const onBookNow = () => {
    const partnerId =
      inquiryKeyMap[props.service] === "warehouse"
        ? props.partner._id
        : props._id;
    auth.setCrumbStack(
      appRoutes.completeOrder.serviceBookOrderRedirection +
        `${inquiryKeyMap[props.service]}/${partnerId}`,
      labels.completeYourOrder
    );
    // auth.onAddToCart(service, warehouseData.warehouse);
    const orderState = {
      order: {
        ...(inquiryKeyMap[props.service] === "warehouse"
          ? {
              warehouse: {
                ...props.partner,
                searchForm: props?.searchForm,
                service: {
                  quantity: props?.quote?.services[0]?.quantity,
                  rateUnit: props?.quote?.services[0]?.rateUnit,
                  rate: props?.quote?.services[0]?.rate,
                  gstRegime: props?.quote?.taxes.reduce(
                    (total, item) => total + item.rate,
                    0
                  )
                },
                costs: props.costs
              }
            }
          : {
              transport: {
                ...props.partner,
                searchForm: props?.searchForm,
                service: {
                  quantity: props?.quote?.services[0]?.quantity,
                  rateUnit: props?.quote?.services[0]?.rateUnit,
                  rate: props?.quote?.services[0]?.rate,
                  gstRegime: props?.quote?.taxes.reduce(
                    (total, item) => total + item.rate,
                    0
                  )
                },
                costs: props.costs
              }
            })
      },
      inquiryId: props.inquiryId
    };

    // Save order state in localStorage before navigating
    Storage.setItem(StorageKeys.INQUIRY_ITEM, JSON.stringify(orderState));
    navigate(
      appRoutes.completeOrder.serviceBookOrderRedirection +
        `${inquiryKeyMap[props.service]}/${partnerId}` +
        `/Inquiry/${props.inquiryId}`
    );
  };

  return (
    <StyledCard elevation={0}>
      <Grid container>
        {!isMobileDevice && (
          <Grid item>
            <StyledCardMedia
              xs={4}
              image={
                type[props.service] === type.warehouseRfq
                  ? props?.partner?.heroImage?.thumbUrl
                  : props?.partner?.vehicles?.[0].heroImage?.thumbUrl
              }
              title={props?.partner?.code}
              sx={{ height: "100%" }}
            />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          md={9}
          sx={{
            ml: {
              xs: 0,
              md: "12px"
            }
          }}
        >
          <Grid container sx={{ padding: "6px 0 0 0" }}>
            <Grid item xs={7}>
              <StyledPrimaryText
                lineHeight="19.36px"
                fontWeight={600}
                letterSpacing="0.005em"
                marginBottom="4px"
                sx={{
                  fontSize: {
                    xs: 13,
                    md: 16
                  }
                }}
              >
                {props?.partner?.code}
              </StyledPrimaryText>
              <StyledSecondaryText
                sx={{
                  display: {
                    xs: "none",
                    md: "block"
                  }
                }}
                lineHeight="18px"
              >{`${props?.partner?.address?.cluster}, ${props?.partner?.address?.city}, ${props?.partner?.address?.state}`}</StyledSecondaryText>
            </Grid>
            <Grid item xs={5} textAlign={"end"}>
              <StyledChip
                label={stringCapitalization(status)}
                variant="standard"
              />
            </Grid>
          </Grid>
          <Box mt={3} display="flex" gap={"12px"} mb={"6px"}>
            <Box display="flex" flexDirection="column">
              <StyledPrimaryText
                fontSize={12}
                fontWeight={500}
                marginBottom="4px"
              >
                {`₹${props?.quote?.services?.[0].rate}/${props?.quote?.services?.[0].rateUnit}`}
              </StyledPrimaryText>
              <StyledSecondaryText>
                {type[props.service] === type.warehouseRfq
                  ? labels.monthlyRentalText
                  : labels.pricePerKmLabelText}
              </StyledSecondaryText>
            </Box>
            <Box display="flex" flexDirection="column">
              <Box display="flex" flexDirection="column">
                <StyledPrimaryText
                  fontSize={12}
                  fontWeight={500}
                  marginBottom="4px"
                >
                  {`₹${parseFloat(props?.costs?.additionalServiceCost).toFixed(
                    2
                  )}`}
                </StyledPrimaryText>
                <StyledSecondaryText>
                  {labels.addonFeesText}
                </StyledSecondaryText>
              </Box>
            </Box>
            <Divider orientation="vertical" sx={{ height: "auto" }} />
            <Box display="flex" flexDirection="column">
              <Box display="flex">
                <StyledPrimaryText
                  fontSize={14}
                  fontWeight={700}
                  marginBottom="4px"
                  lineHeight="16.94px"
                >
                  {`₹${parseFloat(props?.quote?.totalAmount).toFixed(2)}`}
                </StyledPrimaryText>
                {isResponded && (
                  <Tooltip
                    placement="top"
                    title={`Initial price : ${currencyFormatter(
                      parseFloat(props?.totalAmount).toFixed(2)
                    )}`}
                    sx={{
                      background: "#2D343D",
                      fontSize: 12
                    }}
                    arrow
                  >
                    {+props?.quote?.totalAmount > +props?.totalAmount ? (
                      <NorthIcon
                        fontSize="10.5px"
                        sx={{
                          color: "#A83B3B",
                          ml: "3.75px"
                        }}
                      />
                    ) : (
                      <SouthIcon
                        fontSize="10.5px"
                        sx={{
                          color: "#0F8B6C",
                          ml: "3.75px"
                        }}
                      />
                    )}
                  </Tooltip>
                )}
              </Box>
              <StyledSecondaryText>
                {labels.totalAmountText}
              </StyledSecondaryText>
            </Box>
          </Box>
          {isResponded && (
            <Box mt={3} display="flex">
              <Button
                variant="soft"
                fullWidth
                onClick={onBookNow}
                sx={{ height: 40 }}
              >
                {labels.bookNowButtonText}
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
    </StyledCard>
  );
}
