import { getRegisterFilters } from "../../inventory/services/index";
import { queryKeys } from "../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchRegisterFilters = () => {
  const queryRegisterFilters = useQuery(
    [queryKeys.queryFetchRegisterFilters],
    () => getRegisterFilters(),
    {
      refetchOnWindowFocus: false
    }
  );

  return queryRegisterFilters;
};
