import { Box, Button, CircularProgress, Grid } from "@mui/material";
import { useDrawer } from "../../../shared/customSideDrawer";
import CustomTable from "../../../shared/customTable";
import MobileTruckCard from "./MobileTruckCard";
import MobileAddTruck from "./MobileAddTruck";

const getColumns = () => {
  return [
    {
      Header: "Truck Reg. No",
      accessor: "truckRegNo"
    },
    {
      Header: "Truck Make",
      accessor: "truckMake"
    },
    {
      Header: "Truck Type",
      accessor: "truckType"
    }
  ];
};

const getRows = (rows = [], refetch) => {
  return rows.map((item, index) => {
    return {
      vehicleId: item._id,
      truckRegNo: item.regNo,
      truckMake: item.make,
      truckType: item.vehicleType,
      refetch: () => refetch()
    };
  });
};

const MobileTruckTab = ({ data, isFetching, refetch }) => {
  const { showDrawer, hideDrawer } = useDrawer();

  const handleEdit = () => {
    showDrawer({
      title: "Add Truck",
      height: "fit-content",
      component: <MobileAddTruck hideDrawer={hideDrawer} refetch={refetch} />
    });
  };

  return isFetching ? (
    <Grid container>
      <CircularProgress color="inherit" />
    </Grid>
  ) : (
    <Box
      display={"flex"}
      flexDirection={"column"}
      padding={"20px"}
      gap={2}
      mt={-2}
    >
      <Box display={"flex"} justifyContent={"flex-end"} alignItems={"center"}>
        <Button variant="link" disableRipple onClick={() => handleEdit(null)}>
          {"+ Add"}
        </Button>
      </Box>
      <CustomTable
        columns={getColumns()}
        data={getRows(data?.vehicles, refetch)}
        isLoading={isFetching}
        mobileComponent={MobileTruckCard}
      />
    </Box>
  );
};

export default MobileTruckTab;
