import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  Link,
  Typography,
  styled
} from "@mui/material";
import {
  StyledGridItem,
  StyledLabelTypography,
  StyledOrderBox,
  StyledValueTypography
} from "../WarehouseDetails/OrderBox";

import { BookOrderFormKeys } from "../../../constants/formKeys";
import CheckBoxGroup from "../../../shared/formElements/CheckBoxGroup";
import { ExpandMore } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { currencyFormatter } from "../../../shared/utils";
import { labels } from "../../../constants";

const StyledAccordion = styled((props) => <Accordion {...props} />)(
  ({ theme }) => ({
    background: "#FFFFFF",
    border: 0,
    boxShadow: "none",
    borderBottom: "0.4px solid #B7BEC7",
    borderRadius: "0px !important",
    "&:before": {
      display: "none"
    },
    "& > .Mui-expanded > div": {
      margin: 0
    }
  })
);

export const AmountGrid = ({
  quantityLabel,
  quantityUnit,
  quantity,
  storageLabel,
  storageUnit,
  storageRate,
  storageCost,
  additionalCost,
  subtotal,
  gstRegime,
  gstAmount
}) => {
  return (
    <>
      <StyledGridItem>
        <StyledLabelTypography>
          {quantityLabel} <b>({quantityUnit})</b>
        </StyledLabelTypography>
        <StyledValueTypography>{quantity}</StyledValueTypography>
      </StyledGridItem>
      <StyledGridItem>
        <StyledLabelTypography>
          {labels.pricePer} <b>{storageUnit}</b>
        </StyledLabelTypography>
        <StyledValueTypography>
          {currencyFormatter(parseFloat(storageRate).toFixed(2))}
        </StyledValueTypography>
      </StyledGridItem>
      <StyledGridItem>
        <StyledLabelTypography>{storageLabel}</StyledLabelTypography>
        <StyledValueTypography>
          {currencyFormatter(parseFloat(storageCost).toFixed(2))}
        </StyledValueTypography>
      </StyledGridItem>
      <StyledGridItem>
        <StyledLabelTypography>{labels.additionalCost}</StyledLabelTypography>
        <StyledValueTypography>
          {currencyFormatter(parseFloat(additionalCost).toFixed(2))}
        </StyledValueTypography>
      </StyledGridItem>
      <StyledGridItem>
        <StyledLabelTypography>{labels.subtotal}</StyledLabelTypography>
        <StyledValueTypography>
          {currencyFormatter(parseFloat(subtotal).toFixed(2))}
        </StyledValueTypography>
      </StyledGridItem>
      <StyledGridItem>
        <StyledLabelTypography>
          {labels.gstAt} <b>{gstRegime}%</b>
        </StyledLabelTypography>
        <StyledValueTypography>
          {currencyFormatter(parseFloat(gstAmount).toFixed(2))}
        </StyledValueTypography>
      </StyledGridItem>
    </>
  );
};

const WarehouseBreakUp = ({
  warehouse,
  localFormData,
  orderFormData,
  isInquiry
}) => {
  return (
    <Grid container spacing={"10px"}>
      <AmountGrid
        quantityLabel={labels.quantityPallets}
        quantityUnit={
          isInquiry
            ? warehouse?.service?.rateUnit
            : warehouse?.storageTypes?.[0].displayName
        }
        quantity={
          isInquiry ? warehouse?.service?.quantity : localFormData.pallets
        }
        storageLabel={labels.storageCost}
        storageUnit={
          isInquiry
            ? warehouse?.service?.rateUnit
            : warehouse?.storageTypes?.[0].displayName
        }
        storageRate={
          isInquiry
            ? warehouse?.service?.rate
            : warehouse?.storageTypes?.[0].rate ??
              orderFormData?.warehouse?.storageTypes?.[0].rate
        }
        storageCost={warehouse?.costs?.serviceCost}
        additionalCost={warehouse?.costs?.additionalServiceCost}
        subtotal={warehouse?.costs?.subTotalAmount}
        gstRegime={
          isInquiry ? warehouse?.service?.gstRegime : warehouse?.gstRegime
        }
        gstAmount={warehouse?.costs?.taxCost}
      />
    </Grid>
  );
};

const TransportBreakUp = ({ transport, localFormData, isInquiry }) => (
  <Grid container spacing={"10px"}>
    <AmountGrid
      quantityLabel={labels.distanceLabelText}
      quantityUnit={
        isInquiry
          ? transport?.service?.rateUnit
          : transport?.vehicles?.[0].rateUnit
      }
      quantity={
        isInquiry
          ? parseFloat(transport?.service?.quantity).toFixed(2)
          : parseFloat(localFormData.distance).toFixed(2)
      }
      storageLabel={labels.transportCost}
      storageUnit={
        isInquiry
          ? transport?.service?.rateUnit
          : transport?.vehicles?.[0].rateUnit
      }
      storageRate={
        isInquiry ? transport?.service?.rate : transport?.vehicles?.[0].rate
      }
      storageCost={transport?.costs?.serviceCost}
      additionalCost={transport?.costs?.additionalServiceCost}
      subtotal={transport?.costs?.subTotalAmount}
      gstRegime={
        isInquiry ? transport?.service?.gstRegime : transport?.gstRegime
      }
      gstAmount={transport?.costs?.taxCost}
    />
  </Grid>
);

const CostBreakup = ({
  isInquiry,
  warehouseBreakUp,
  transportBreakUp,
  formData,
  control,
  errors,
  setValue,
  isLoading,
  onSubmit,
  orderFormData,
  closeDrawer
}) => {
  const warehouseTotal =
    warehouseBreakUp?.costs?.totalAmount ??
    orderFormData?.warehouse?.costs?.totalAmount;

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={"18px"}
      alignItems={"center"}
    >
      <StyledOrderBox
        sx={{
          border: closeDrawer && "none"
        }}
      >
        <Box display={"flex"} flexDirection={"column"} gap={2}>
          <Box display={"flex"} flexDirection={"column"} gap={1}>
            <Typography
              fontSize={14}
              fontWeight={700}
              color={"black"}
              lineHeight={"20px"}
            >
              {labels.totalAmountText}
            </Typography>
            <Typography
              fontSize={24}
              fontWeight={900}
              lineHeight={"29.05px"}
              color={"primary.text"}
            >
              {currencyFormatter(
                parseFloat(
                  (warehouseTotal ?? 0) +
                    (transportBreakUp?.costs?.totalAmount ?? 0)
                ).toFixed(2)
              )}
            </Typography>
          </Box>
          <Divider sx={{ borderColor: "#B7BEC7" }} />
        </Box>
        {warehouseBreakUp && (
          <StyledAccordion>
            <AccordionSummary
              expandIcon={<ExpandMore sx={{ fontSize: 35 }} />}
              sx={{
                padding: 0
              }}
            >
              <Box display={"flex"} flexDirection={"column"} gap={1}>
                <Typography
                  fontSize={14}
                  fontWeight={700}
                  color={"black"}
                  lineHeight={"20px"}
                >
                  {`${labels.warehouse} Order`}
                </Typography>
                <Typography
                  fontSize={24}
                  fontWeight={900}
                  lineHeight={"29.05px"}
                  color={"primary.text"}
                >
                  {currencyFormatter(parseFloat(warehouseTotal).toFixed(2))}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                padding: "17px 0"
              }}
            >
              <WarehouseBreakUp
                isInquiry={isInquiry}
                localFormData={formData}
                warehouse={warehouseBreakUp}
                orderFormData={orderFormData}
              />
            </AccordionDetails>
          </StyledAccordion>
        )}
        {transportBreakUp && (
          <StyledAccordion>
            <AccordionSummary
              expandIcon={<ExpandMore sx={{ fontSize: 35 }} />}
              sx={{
                padding: 0
              }}
            >
              <Box display={"flex"} flexDirection={"column"} gap={1}>
                <Typography
                  fontSize={14}
                  fontWeight={700}
                  color={"black"}
                  lineHeight={"20px"}
                >
                  {`${labels.transport} Order`}
                </Typography>
                <Typography
                  fontSize={24}
                  fontWeight={900}
                  lineHeight={"29.05px"}
                  color={"primary.text"}
                >
                  {currencyFormatter(
                    parseFloat(transportBreakUp?.costs?.totalAmount).toFixed(2)
                  )}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                padding: "17px 0"
              }}
            >
              <TransportBreakUp
                isInquiry={isInquiry}
                transport={transportBreakUp}
                localFormData={formData}
              />
            </AccordionDetails>
          </StyledAccordion>
        )}
        {closeDrawer ? (
          <Button
            variant="soft"
            fullWidth
            sx={{ height: 48, mt: 3 }}
            onClick={closeDrawer}
          >
            {labels.closeButton}
          </Button>
        ) : (
          <LoadingButton
            variant="contained"
            fullWidth
            sx={{ height: 68, mt: 3 }}
            loading={isLoading}
            onClick={onSubmit}
          >
            {labels.proceedToPay}
          </LoadingButton>
        )}
      </StyledOrderBox>

      {!closeDrawer && (
        <CheckBoxGroup
          control={control}
          name={BookOrderFormKeys.AcceptTnC}
          errors={errors}
          options={[
            {
              displayName: (
                <Typography
                  component="span"
                  color={"text.primary"}
                  sx={{
                    fontSize: 14,
                    fontWeight: 600,
                    lineHeight: "18px"
                  }}
                >
                  {labels.acceptAll}
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    {labels.tnc}
                  </Link>
                </Typography>
              ),
              name: "TnC"
            }
          ]}
          setValue={setValue}
        />
      )}
    </Box>
  );
};

export default CostBreakup;
