import {
  AdminPanelSettingsOutlined,
  Backpack,
  BackpackOutlined,
  CalendarMonthOutlined,
  CenterFocusStrongOutlined,
  DeviceThermostatOutlined,
  InfoOutlined,
  PlaceOutlined
} from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  styled
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import AdditionalDetailsCard from "./AdditionalDetailsCard";
import { AppContext } from "../../../shared/context/auth-context";
import DetailsGrid from "./DetailsGrid";
import ImageGrid from "./ImageGrid";
import OrderBox from "./OrderBox";
import Storage from "../../../shared/utils/Storage";
import { StorageKeys } from "../../../constants/storage-keys";
import { StyledResponsiveText } from "../../../shared/theme/globalTheme";
import WareHouseSearchResults from "./WareHouseSearchResults";
import appRoutes from "../../../constants/appRoutes";
import { currencyFormatter } from "../../../shared/utils";
import { isMobileDevice } from "../../../App";
import { labels } from "../../../constants";
import { useContext } from "react";
import { useDrawer } from "../../../shared/customSideDrawer";
import { useFetchWarehouseByPartnerId } from "../../../query-hooks/Warehouse/useFetchWarehouseByPartnerId";
import { useState } from "react";

export const StyledSectionHeader = styled((props) => (
  <StyledResponsiveText
    {...props}
    desktop={{
      fontSize: 20,
      fontWeight: 500,
      lineHeight: "24.2px",
      letterSpacing: "0.002em"
    }}
    mobile={{
      fontSize: 16,
      fontWeight: 500,
      lineHeight: "19.36px",
      letterSpacing: "0.005em"
    }}
  >
    {props.children}
  </StyledResponsiveText>
))(({ theme }) => ({}));

export const StyledDivider = styled((props) => (
  <Divider
    {...props}
    sx={{
      borderBottomWidth: "0.4px",
      borderBottomColor: "#B7BEC7",
      borderRadius: "0.5px",
      mt: isMobileDevice ? 3 : 2,
      mb: isMobileDevice ? 2 : 5
    }}
  />
))(({ theme }) => ({}));

export const StyledBookNowButton = styled((props) => (
  <Button
    {...props}
    sx={{
      width: 200,
      height: 48,
      fontSize: 15,
      fontWeight: 600,
      lineHeight: "20.43px",
      letterSpacing: "0.02em"
    }}
  />
))(({ theme }) => ({}));

const featureIcons = {
  SecurityGuards: AdminPanelSettingsOutlined,
  TemperatureControls: DeviceThermostatOutlined,
  CctvAndSurveillance: CenterFocusStrongOutlined
};

const DetailedWarehouse = (props) => {
  const searchFormParams = Storage.getItem(StorageKeys.SEARCH_FORM_PARAMS);

  const { service, uuid } = useParams();
  const [showMore, setShowMore] = useState(false);
  const auth = useContext(AppContext);
  const navigate = useNavigate();
  const { showDrawer, hideDrawer } = useDrawer();

  const { isFetching: isWarehouseFetching, data: warehouseData } =
    useFetchWarehouseByPartnerId(searchFormParams, uuid);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const onBookOrder = () => {
    auth.setCrumbStack(
      appRoutes.completeOrder.serviceBookOrderRedirection +
        `${service}/${uuid}`,
      labels.completeYourOrder
    );
    // auth.onAddToCart(service, warehouseData.warehouse);
    navigate(
      appRoutes.completeOrder.serviceBookOrderRedirection +
        `${service}/${uuid}`,
      {
        state: {
          order: {
            warehouse: warehouseData.warehouse
          }
        }
      }
    );
  };

  const openMobileOrderBox = () => {
    showDrawer({
      component: (
        <OrderBox
          totalAmount={warehouseData?.warehouse?.costs?.totalAmount}
          storageCost={warehouseData?.warehouse?.costs?.serviceCost}
          subtotal={warehouseData?.warehouse?.costs?.subTotalAmount}
          gstAt={warehouseData?.warehouse?.costs?.taxCost}
          additionalCost={
            warehouseData?.warehouse?.costs?.additionalServiceCost
          }
          storageType={warehouseData?.warehouse?.storageTypes[0].displayName}
          rate={warehouseData?.warehouse?.storageTypes[0].rate}
          facilityLabel={labels.storageCost}
          bookButtonLabel={labels.bookOrderButtonLabelText}
          gstRegime={warehouseData?.warehouse?.gstRegime}
          closeDrawer={hideDrawer}
        />
      ),
      closeIcon: false
    });
  };

  const warehouse = isWarehouseFetching ? (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isWarehouseFetching}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : (
    <Box
      sx={{
        paddingBottom: {
          xs: 0,
          sm: 2
        }
      }}
    >
      <Box
        maxHeight={360}
        overflow="hidden"
        marginBottom={5}
        sx={{
          display: {
            xs: "flex",
            sm: "none"
          }
        }}
      >
        <img
          src={warehouseData?.warehouse?.files?.[0].fileUrl}
          alt={warehouseData?.warehouse?.files?.[0].originalName}
          width={"100%"}
          style={{ objectFit: "cover" }}
        />
      </Box>
      <Box
        sx={{
          padding: {
            sm: "initial",
            xs: 2
          }
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sx={{
              display: {
                xs: "none",
                sm: "block"
              }
            }}
          >
            <ImageGrid
              imageList={warehouseData?.warehouse?.files.map((item, index) => {
                if (item.highlight || index === 0) {
                  return {
                    img: item.fileUrl,
                    title: item.originalName,
                    rows: 2,
                    cols: 2
                  };
                }
                return {
                  img: item.fileUrl,
                  title: item.originalName
                };
              })}
            />
          </Grid>
          <Grid item xs={12} container spacing={2}>
            <Grid
              item
              xs={12}
              lg={7}
              container
              spacing={2}
              height="fit-content"
            >
              <Grid item xs={12}>
                <StyledResponsiveText
                  color={"text.primary"}
                  desktop={{
                    fontSize: 28,
                    fontWeight: 600,
                    lineHeight: "33.89px",
                    letterSpacing: "-0.002em"
                  }}
                  mobile={{
                    fontSize: 20,
                    fontWeight: 500,
                    lineHeight: "24.2px",
                    letterSpacing: "0.002em"
                  }}
                >
                  {warehouseData?.warehouse.code}
                </StyledResponsiveText>
                <Box
                  component="div"
                  marginTop={"12px"}
                  display="flex"
                  alignItems="center"
                >
                  <PlaceOutlined fontSize="small" sx={{ marginRight: 1 }} />
                  <Typography
                    component="p"
                    color={"text.primary"}
                    sx={{
                      fontSize: 14,
                      fontWeight: 600,
                      lineHeight: "20px",
                      whiteSpace: "nowrap"
                    }}
                  >
                    {`${warehouseData?.warehouse.address.cluster}, `}
                    <Typography
                      component="span"
                      color={"text.secondary"}
                      sx={{
                        fontSize: 14,
                        fontWeight: 400,
                        lineHeight: "16.94px",
                        whiteSpace: "nowrap"
                      }}
                    >
                      {`${warehouseData?.warehouse.address.city}, ${warehouseData?.warehouse.address.state}`}
                    </Typography>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <StyledDivider
                  sx={{
                    mt: 1,
                    mb: 1
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  fontSize={14}
                  lineHeight={"20px"}
                  sx={{ mb: 3, mr: 3 }}
                >
                  {showMore
                    ? warehouseData?.warehouse.description
                    : warehouseData?.warehouse.description.slice(0, 350)}
                  {warehouseData?.warehouse.description.length > 350 && (
                    <Button
                      variant="text"
                      onClick={toggleShowMore}
                      sx={{
                        color: "#0F2744",
                        display: showMore ? "none" : "",
                        "&:hover": {
                          backgroundColor: "transparent"
                        },
                        "&:active": {
                          backgroundColor: "transparent"
                        }
                      }}
                    >
                      <Typography
                        fontSize={14}
                        lineHeight={"20px"}
                        fontWeight={600}
                      >
                        {labels.showMore}
                      </Typography>
                    </Button>
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <StyledSectionHeader>
                  {labels.storageDetailsTitleText}
                </StyledSectionHeader>
              </Grid>
              <Grid item xs={12} marginTop={isMobileDevice ? 1 : 3}>
                <DetailsGrid
                  gridData={[
                    {
                      icon: (
                        <CalendarMonthOutlined
                          fontSize="small"
                          color="primary"
                          sx={{ mr: "11px" }}
                        />
                      ),
                      label: labels.yearEstablishedLabelText,
                      value: warehouseData?.warehouse?.establishedYear
                    },
                    {
                      icon: (
                        <Backpack
                          fontSize="small"
                          color="primary"
                          sx={{ mr: "11px" }}
                        />
                      ),
                      label: labels.totalStorageSpaceLabelText,
                      value: `${warehouseData?.warehouse?.storageArea.total} ${warehouseData?.warehouse?.storageArea.unit}`
                    },
                    {
                      icon: (
                        <BackpackOutlined
                          fontSize="small"
                          color="primary"
                          sx={{ mr: "11px" }}
                        />
                      ),
                      label: labels.availableSpaceLabelText,
                      value: `${warehouseData?.warehouse?.storageArea.available} ${warehouseData?.warehouse?.storageArea.unit}`
                    }
                  ]}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledDivider />
              </Grid>
              <Grid item xs={12}>
                <StyledSectionHeader>
                  {labels.storageTypesAndPricingTitleText}
                </StyledSectionHeader>
              </Grid>
              <Grid item xs={12}>
                <DetailsGrid
                  gridData={warehouseData?.warehouse?.storageTypes.map(
                    (item) => {
                      return {
                        label: item.displayName,
                        value: `${currencyFormatter(item.rate)} / ${
                          item.rateUnit
                        }`
                      };
                    }
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledDivider />
              </Grid>
              <Grid item xs={12} sx={{ display: { xs: "none", sm: "block" } }}>
                <StyledSectionHeader>
                  {labels.additionalDetailsTitleText}
                </StyledSectionHeader>
              </Grid>
              <Grid item xs={12} marginTop={isMobileDevice ? 0 : 3}>
                <AdditionalDetailsCard
                  title={labels.servicesAvailableLabelText}
                  services={warehouseData?.warehouse.features.map((item) => {
                    return {
                      serviceLabel: item.displayName,
                      icon: featureIcons[item.name]
                    };
                  })}
                />
              </Grid>
              <Grid
                item
                xs={12}
                display={{
                  xs: "block",
                  sm: "none"
                }}
              >
                <StyledDivider />
              </Grid>
              <Grid item xs={12} marginTop={isMobileDevice ? 0 : 3}>
                <AdditionalDetailsCard
                  title={labels.approvedProductsLabelText}
                  products={warehouseData?.warehouse.materialCategories.map(
                    (item) => item.displayName
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledDivider />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              lg={5}
              sx={{
                display: {
                  xs: "none",
                  lg: "block"
                }
              }}
            >
              <OrderBox
                totalAmount={warehouseData?.warehouse?.costs?.totalAmount}
                storageCost={warehouseData?.warehouse?.costs?.serviceCost}
                subtotal={warehouseData?.warehouse?.costs?.subTotalAmount}
                gstAt={warehouseData?.warehouse?.costs?.taxCost}
                additionalCost={
                  warehouseData?.warehouse?.costs?.additionalServiceCost
                }
                storageType={
                  warehouseData?.warehouse?.storageTypes[0].displayName
                }
                rate={warehouseData?.warehouse?.storageTypes[0].rate}
                facilityLabel={labels.storageCost}
                bookButtonLabel={labels.bookOrderButtonLabelText}
                onSubmit={onBookOrder}
                gstRegime={warehouseData?.warehouse?.gstRegime}
              />
            </Grid>
            <Grid item xs={12}>
              <WareHouseSearchResults />
            </Grid>
            {/* <Grid item sx={{ mb: { xs: 10, md: 0 } }}></Grid> */}
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          display: {
            xs: "flex",
            lg: "none"
          },
          bottom: {
            xs: "70px",
            sm: 0
          },
          borderTop: "1px solid #B7BEC7"
        }}
        position={"sticky"}
        zIndex={1}
        alignContent={"center"}
        backgroundColor={"#fff"}
        width={"100%"}
      >
        <Divider sx={{ borderColor: "#B7BEC7" }} />
        <Box
          sx={{
            padding: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 5,
            width: "100%"
          }}
        >
          <Box display={"flex"} flexDirection={"column"} gap={0.5}>
            <Box display={"flex"} gap={0.5} alignItems={"center"}>
              <Typography
                color="text.primary"
                fontWeight={600}
                fontSize={16}
                lineHeight={"19.36px"}
                letterSpacing="0.005em"
              >
                {currencyFormatter(
                  parseFloat(
                    warehouseData?.warehouse?.costs?.totalAmount
                  ).toFixed(2)
                )}
              </Typography>
              <Box display={"flex"} onClick={openMobileOrderBox}>
                <InfoOutlined fontSize="small" />
              </Box>
            </Box>
            <Typography
              color="text.secondary"
              fontWeight={400}
              fontSize={14}
              lineHeight={"20px"}
            >
              {labels.totalAmountText}
            </Typography>
          </Box>
          <StyledBookNowButton variant="contained" onClick={onBookOrder}>
            {labels.bookNowButtonText}
          </StyledBookNowButton>
        </Box>
      </Box>
    </Box>
  );

  return <Box>{warehouse}</Box>;
};

export default DetailedWarehouse;
