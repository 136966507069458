import { Box, CircularProgress, Grid, IconButton } from "@mui/material";
import { BoldText } from "../../inventory/pages/Performance";

import CustomTable from "../../shared/customTable";
import { CustomTooltip } from "../../shared/theme/globalTheme";
import Filter from "../../purchase/components/Filter";
import Ledger from "../../assets/Ledger.webp";
import { StyledChip } from "../../shared/UiElements/StatusChip";
import moment from "moment";
import { register_filterKeys } from "../../constants/formKeys";
import { register_table_labels } from "../../constants";
import { dropdownOptions, stringCapitalization } from "../../shared/utils";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { useFetchRegister } from "../../query-hooks/ClientRegister/useFetchRegister";
import { useEffect, useMemo, useState } from "react";
import { getCustomCell } from "../../purchase/pages/PurchaseOrders";
import { enqueueSnackbar } from "notistack";
import { useFetchRegisterFilters } from "../../query-hooks/ClientRegister/useFetchRegisterFilters";
import TextInput from "../../shared/formElements/TextInput";
import { Search, TuneOutlined } from "@mui/icons-material";
import { useDrawer } from "../../shared/customSideDrawer";
import MobileRegister from "../components/Register/MobileRegister";
import { isMobileDevice } from "../../App";

export const ForkLift = (
  <path d="M25.8065 14.8799H23.742H20.6452V13.6H24.6022L26.3226 7.19994H27.871L25.8065 14.8799ZM30.9678 0.799988V23.5299C30.2296 22.9173 29.3421 22.56 28.3871 22.56C25.8216 22.56 23.742 25.1388 23.742 28.32C23.742 28.9932 23.8351 29.6395 24.0063 30.24H21.4131C21.5843 29.6394 21.6774 28.9932 21.6774 28.32C21.6774 25.1388 19.5978 22.56 17.0323 22.56C14.4668 22.56 12.3871 25.1388 12.3871 28.32C12.3871 28.9932 12.4803 29.6394 12.6515 30.24H11.3549V28.96H10.3226V30.24H8.25809H0V27.6799H8.25809V0.799988H10.3226V21.28H11.3549V19.9999L18.5807 0.799988H30.9678ZM20.6451 18.7199C20.6451 17.3062 21.5695 16.16 22.7097 16.16H28.9032V3.35994H19.6129L13.4193 19.9999H20.6451V18.7199ZM17.0323 23.8399C19.0276 23.8399 20.6452 25.8457 20.6452 28.32C20.6452 30.7942 19.0276 32.8 17.0323 32.8C15.0369 32.8 13.4194 30.7942 13.4194 28.32C13.4194 25.8457 15.0369 23.8399 17.0323 23.8399ZM28.3871 23.8399C30.3824 23.8399 32 25.8457 32 28.32C32 30.7942 30.3824 32.8 28.3871 32.8C26.3918 32.8 24.7742 30.7942 24.7742 28.32C24.7742 25.8457 26.3918 23.8399 28.3871 23.8399ZM7.22585 16.1599H4.12908V13.5999H7.22585V16.1599ZM3.09684 16.1599H6.52838e-05V13.5999H3.09684V16.1599ZM7.22585 12.3199H2.06454V9.75989H7.22585V12.3199ZM6.52838e-05 26.4V17.4399H7.22585V26.4H6.52838e-05Z" />
);

export const Unloading = (
  <path d="M2.51003 23.475C2.62503 24.0062 3.06502 24.325 3.49002 24.1812L14.31 20.5562C14.735 20.4125 14.99 19.8625 14.875 19.3312L12.39 7.73749C12.275 7.20624 11.835 6.88749 11.41 7.03124L7.55002 8.33124L8.79002 14.125L5.70002 15.1562L4.46002 9.36249L0.595027 10.6562C0.170027 10.8 -0.0849726 11.35 0.0300273 11.8812L2.51003 23.475ZM19.2 0C18.315 0 17.6 0.893749 17.6 2V22.225L0.295027 28.125C0.0800273 28.2 -0.0449726 28.475 0.0150273 28.7375L0.645027 31.6312C0.705027 31.9 0.925027 32.0562 1.13503 31.9812L20.82 25.2687C20.94 29.0062 23.38 32 26.4 32C29.495 32 32 28.8687 32 25V0L19.2 0ZM26.4 28C25.075 28 24 26.6562 24 25C24 23.3437 25.075 22 26.4 22C27.725 22 28.8 23.3437 28.8 25C28.8 26.6562 27.725 28 26.4 28Z" />
);

export const getRouteByOrderType = (orderType, orderId, relatedOrderId) => {
  const routesBytype = {
    PurchaseOrder: `/Order/Purchase-Order/${orderId}`,
    PurchaseReturn: `/Order/Purchase-Order/${relatedOrderId}/Purchase-Return/${orderId}`,
    SaleOrder: `/Order/Sale-Order/${orderId}`,
    SaleReturn: `/Order/Sale-Order/${relatedOrderId}/Sale-Return/${orderId}`,
    StockTransfer: `/Order/Stock-Transfer/${orderId}`
  };
  return routesBytype[orderType];
};

const Register = () => {
  const [params, setParams] = useState();
  const { control, reset, handleSubmit, watch } = useForm();
  const navigate = useNavigate();
  const { showDrawer, hideDrawer } = useDrawer();

  const { data: filterData, isFetching: isFilterFetching } =
    useFetchRegisterFilters();

  const { ref, inView } = useInView();
  const {
    data,
    isFetching,
    isFetched,
    hasNextPage,
    fetchNextPage,
    refetch,
    isError,
    error
  } = useFetchRegister(params);

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, inView]);

  const getColumns = () => {
    return [
      {
        Header: "Doc. Date",
        accessor: "docDate"
      },
      {
        Header: "Doc. Type",
        accessor: "docType"
      },
      {
        Header: "Doc. Number",
        accessor: "docNumber",
        Cell: ({ row, value }) => {
          return getCustomCell(
            value,
            "",
            getRouteByOrderType(
              row.original.docTypeName,
              row.original.orderId,
              row.original.relatedOrderId
            )
          );
        }
      },
      {
        Header: "Mov. Type",
        accessor: "movType"
      },
      {
        Header: "Warehouse",
        accessor: "warehouse",
        Cell: ({ value }) => {
          return getCustomCell(value, "", ``);
        }
      },
      {
        Header: "Status",
        accessor: "orderProgress",
        Cell: ({ value }) => {
          return <StyledChip label={stringCapitalization(value)} />;
        }
      },
      {
        Header: "Register Actions",
        accessor: "registerAction",
        width: 170,
        Cell: ({ row, value }) => {
          return (
            <IconButton
              onClick={() =>
                value.svgType
                  ? navigate(
                      `/warehouse/register/Material-Received-Report/${row.original.orderId}`
                    )
                  : navigate(
                      `/warehouse/register/Gate-Pass/${row.original.orderId}`
                    )
              }
            >
              <svg
                width="32"
                height="33"
                viewBox="0 0 32 33"
                fill={
                  value.svgType
                    ? value.color
                      ? "#027AFF"
                      : "#B7BEC7"
                    : value.color
                    ? "#F9A90E"
                    : "#B7BEC7"
                }
                xmlns="http://www.w3.org/2000/svg"
              >
                {value.svgType ? ForkLift : Unloading}
              </svg>
            </IconButton>
          );
        }
      },
      {
        Header: "Register Date",
        accessor: "registerDate"
      },
      {
        Header: "Register Entries",
        accessor: "registerEntries",
        width: 170,
        Cell: ({ value }) => {
          return (
            value && (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <BoldText
                  sx={{
                    fontWeight: 400,
                    textDecoration: "underline"
                  }}
                >{`${value}%`}</BoldText>
                <CustomTooltip title={"Ledger"} placement="top">
                  <IconButton>
                    <img
                      src={Ledger}
                      className="pull-right cursor-field"
                      alt={"Ledger"}
                    />
                  </IconButton>
                </CustomTooltip>
              </Box>
            )
          );
        }
      }
    ];
  };

  const getRows = (rows = []) => {
    const enableSvgClick = ["Placed", "PartialReceived", "PartialDispatched"];
    const showDate = ["Placed"];
    return rows.map((item, index) => {
      return {
        orderId: item?._id,
        relatedOrderId: item?.relatedOrder?.order,
        docDate: moment(item?.orderDate).format("DD MMM YYYY"),
        docTypeName: item?.orderType?.name,
        docType: item?.orderType?.displayName,
        docNumber: item?.code,
        movType: item?.orderType?.movementType,
        warehouse: `${item?.clientData?.warehouse?.code}`,
        orderProgress: item?.status?.name,
        toOrderProgress: item?.toStatus?.name,
        itemDetails: item?.itemDetails,
        vendorData: item?.vendorData,
        registerAction: {
          color: enableSvgClick.includes(item?.status?.name),
          svgType: Boolean(item?.orderType?.movementType === "Inwards")
        },
        registerDate: !showDate.includes(item?.status?.name)
          ? moment(item.lastActivityAt).format("DD MMM YYYY")
          : "",
        registerEntries: "",
        ...{
          ...(item?.toStatus?.name
            ? {
                subRow: [
                  {
                    docDate: moment(item?.orderDate).format("DD MMM YYYY"),
                    docType: item?.orderType?.displayName,
                    docNumber: getCustomCell(
                      item?.code,
                      "",
                      `/Order/Stock-Transfer/${item?._id}`
                    ),
                    movType: item?.orderType?.movementType,
                    warehouse: getCustomCell(
                      item?.vendorData?.warehouse?.code,
                      "",
                      ``
                    ),
                    orderProgress: (
                      <StyledChip
                        label={stringCapitalization(item?.toStatus?.name)}
                      />
                    ),
                    registerAction: (
                      <IconButton
                        onClick={() =>
                          (item?.itemDetails?.outQuantity >
                            item?.itemDetails?.inQuantity ||
                            item?.itemDetails?.inQuantity > 0) &&
                          navigate(
                            `/warehouse/register/Material-Received-Report/${item?._id}`
                          )
                        }
                      >
                        <svg
                          width="32"
                          height="33"
                          viewBox="0 0 32 33"
                          fill={
                            item?.itemDetails?.outQuantity >
                            item?.itemDetails?.inQuantity
                              ? "#027AFF"
                              : "#B7BEC7"
                          }
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          {ForkLift}
                        </svg>
                      </IconButton>
                    ),
                    registerDate: !showDate.includes(item?.toStatus?.name)
                      ? moment(item.lastActivityAt).format("DD-MM-YYYY")
                      : "",
                    registerEntries: ""
                  }
                ]
              }
            : {})
        }
      };
    });
  };

  const registerTable = useMemo(
    () => (
      <CustomTable
        columns={getColumns()}
        data={
          isFetched
            ? data?.pages?.flatMap((page) => getRows(page?.registers)) ?? []
            : []
        }
        isLoading={!isFetched}
        mobileComponent={MobileRegister}
      />
    ),
    // eslint-disable-next-line
    [data?.pages, isFetched]
  );

  const docType = watch("docType");

  const onViewClick = (data) => {
    const filterData = {
      ...{
        ...(data["warehouse"] !== ""
          ? {
              [`filters[warehouse]`]: data["warehouse"]
            }
          : {})
      },
      ...{
        ...(data["docType"] !== ""
          ? {
              [`filters[orderType]`]: data["docType"]
            }
          : {})
      },
      ...{
        ...(data["status"] !== ""
          ? {
              [`filters[status]`]: data["status"]
            }
          : {})
      },
      ...{
        ...(data?.date[0] !== null && data?.date[0] !== undefined
          ? {
              [`filters[fromDate]`]: moment(data?.date[0]).format("YYYY-MM-DD")
            }
          : {})
      },
      ...{
        ...(data?.date[1] !== null && data?.date[1] !== undefined
          ? {
              [`filters[toDate]`]: moment(data?.date[1]).format("YYYY-MM-DD")
            }
          : {})
      },
      ...{
        ...(data["docNumber"] !== ""
          ? {
              [`filters[code]`]: data["docNumber"]
            }
          : {})
      }
    };
    setParams(filterData);
    hideDrawer();
  };

  useEffect(
    () => {
      if (isMobileDevice && Boolean(docType)) {
        showDrawer({
          title: "Filters",
          height: "fit-content",
          component: FilterComponent
        });
      }
    }, // eslint-disable-next-line
    [isMobileDevice, JSON.stringify(docType)]
  );

  useEffect(() => {
    if (params) {
      refetch();
    }
  }, [params, refetch]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(error?.response?.data?.message, {
        variant: "error"
      });
    }
  }, [error?.response?.data?.message, isError]);

  const onClear = () => {
    reset({
      warehouse: "",
      docType: "",
      docNumber: "",
      status: "",
      date: ""
    });
    setParams(null);
  };

  const FilterComponent = (
    <Filter
      control={control}
      onApply={handleSubmit(onViewClick)}
      onClear={onClear}
      isFetching={isFilterFetching}
      isLoading={isFetching}
      filters={[
        {
          type: "DropDown",
          name: register_filterKeys.warehouse,
          label: register_table_labels.warehouse,
          unSelectText: "Select Warehouse",
          options: dropdownOptions(filterData?.warehouses, "_id", "code"),
          filterCount: filterData?.warehouses?.length
        },
        {
          type: "DropDown",
          name: register_filterKeys.docType,
          label: register_table_labels.docType,
          unSelectText: "Select Doc.Type",
          options: dropdownOptions(
            filterData?.orderTypes,
            "name",
            "displayName"
          ),
          filterCount: filterData?.orderTypes?.length
        },
        {
          type: "DropDown",
          name: register_filterKeys.status,
          label: register_table_labels.status,
          placeholder: docType ? "Select Status" : "Select DocType",
          unSelectText: docType ? "Select Status" : "Select DocType",
          options: Boolean(docType)
            ? Object.values(
                filterData?.orderTypes.find((item) => item.name === docType)
                  ?.statuses
              )
            : [],
          filterCount: docType
            ? filterData?.orderTypes.find((item) => item.name === docType)
                ?.statuses?.length
            : ""
        },
        {
          type: "DateInput",
          name: register_filterKeys.date,
          label: register_table_labels.date,
          placeholder: register_table_labels.pickDates,
          selectsRange: true
        },
        {
          type: "TextInput",
          name: register_filterKeys.docNumber,
          label: register_table_labels.docNumber,
          placeholder: "PO-XXX01",
          onlyCaps: true,
          isOrderTypeFilter: true
        }
      ]}
    />
  );

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      sx={{
        mb: { xs: 9, md: 0 }
      }}
    >
      <Box
        flexDirection={"column"}
        gap={2}
        sx={{
          display: { xs: "none", lg: "flex" },
          position: "sticky",
          top: "64px",
          zIndex: 1000,
          background: "#fff",
          padding: "16px 0"
        }}
      >
        {FilterComponent}
      </Box>
      <Grid
        container
        spacing={2}
        sx={{
          display: { xs: "flex", lg: "none" },
          alignItems: "center",
          position: "sticky",
          top: "60px",
          zIndex: 1,
          background: "#fff",
          padding: "10px 20px"
        }}
      >
        <Grid item xs={10}>
          <TextInput
            startIcon={
              <Search sx={{ ml: 2, fontSize: 25, color: "text.disabled" }} />
            }
            control={control}
            name={"search"}
            placeholder={"Search by OrderId"}
            sx={{ borderRadius: 54 }}
          />
        </Grid>
        <Grid item xs={2}>
          <IconButton
            size="small"
            sx={{
              backgroundColor: "#F0F2F5"
            }}
            onClick={() => {
              showDrawer({
                title: "Filters",
                height: "fit-content",
                component: FilterComponent
              });
            }}
          >
            <TuneOutlined color="primary" fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
      <Box sx={{ padding: { xs: "0 15px", md: 0 } }}>{registerTable}</Box>
      {hasNextPage && (
        <Grid
          item
          xs={12}
          ref={ref}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress />
        </Grid>
      )}
    </Box>
  );
};

export default Register;
