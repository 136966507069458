import { Box, Grid, IconButton, Typography } from "@mui/material";
import { KeyboardArrowRight } from "@mui/icons-material";
import { useDrawer } from "../../../shared/customSideDrawer";
import EditSkuById from "./EditSkuById";
import { useNavigate } from "react-router-dom";

export const SkuMobileCard = (row) => {
  const { refetch } = row;
  const { showDrawer, hideDrawer } = useDrawer();
  const navigate = useNavigate();
  const onSkuClick = (skuId) => {
    showDrawer({
      height: "fit-content",
      component: (
        <EditSkuById skuId={skuId} hideDrawer={hideDrawer} refetch={refetch} />
      ),
      closeIcon: false
    });
  };
  return (
    <Grid container spacing={"5px"}>
      <Grid
        item
        xs={12}
        container
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Grid item xs={8}>
          <Box display={"flex"} alignItems={"center"} gap={1}>
            {row?.sku?.img && (
              <img
                src={row?.sku?.img}
                alt={row?.brand}
                style={{
                  maxWidth: "50px",
                  maxHeight: "50px"
                }}
              />
            )}
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 600,
                textDecoration: "underline",
                color: "#2773FF",
                whiteSpace: "pre-wrap"
              }}
              onClick={() => onSkuClick(row.skuId)}
            >
              {row?.sku?.name}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            gap={1}
          >
            <Typography
              fontSize={14}
              fontWeight={700}
              lineHeight={"20px"}
              color={"text.title"}
            >
              {row.stock.unit1}
            </Typography>
            <Typography
              fontSize={12}
              fontWeight={400}
              lineHeight={"20px"}
              color={"text.secondary"}
            >
              {`(${row.product.units})`}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "right"
        }}
      >
        <IconButton
          color="primary"
          disableRipple
          onClick={() => navigate(`/inventory/item-ledger/${row.skuId}`)}
        >
          <KeyboardArrowRight />
        </IconButton>
      </Grid>
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={4}>
          <Typography
            sx={{ fontSize: 14, lineHeight: "20px", fontWeight: 400 }}
          >
            {row.product.product}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            sx={{ fontSize: 14, lineHeight: "20px", fontWeight: 400 }}
          >
            {row.brand}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            sx={{ fontSize: 14, lineHeight: "20px", fontWeight: 400 }}
          >
            {row.category}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography
            sx={{
              fontSize: 14,
              lineHeight: "20px",
              fontWeight: 700,
              color: "#BBCADF"
            }}
          >{`(${row.stock.unit2})`}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
