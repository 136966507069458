import { Box, useMediaQuery } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ERPHeader from "./ERPHeader";
import ERPHomeButtons from "./ERPHomeButtons";
import YoutubePreviewWeb from "../../assets/YoutubePreviewWeb.webp";
import YoutubePreviewMob from "../../assets/YoutubePreviewMobile.webp";
import { useInView } from "react-intersection-observer";

const ERPSliderComponent = ({ onVideoPlayPause }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const imageWidth = isMobile ? 390 : 600;
  const imageHeight = isMobile ? 280 : 432;
  const aspectRatio = imageWidth / imageHeight;
  const { ref: desktopRef, inView: desktopInView } = useInView({
    threshold: 0.5
  });

  const { ref: mobileRef, inView: mobileInView } = useInView({
    threshold: 0.5
  });

  const iframeRef = useRef(null);

  const [videoLoaded, setVideoLoaded] = useState(false);

  const baseVideoSrc =
    "https://www.youtube.com/embed/s3w6fEdlU18?enablejsapi=1&playlist=s3w6fEdlU18&loop=1&mute=1"; // Mute the video for autoplay

  const handlePlayPause = (iframe, action) => {
    if (iframe) {
      const message =
        action === "play"
          ? '{"event":"command","func":"playVideo","args":""}'
          : '{"event":"command","func":"pauseVideo","args":""}';
      iframe.contentWindow.postMessage(message, "*");

      // Notify parent of play/pause state
      if (onVideoPlayPause) {
        onVideoPlayPause(action === "play");
      }
    }
  };

  const playVideoOnClick = (setVideoLoaded, iframeRef) => {
    setVideoLoaded(true);
    iframeRef.current?.contentWindow.postMessage(
      '{"event":"command","func":"playVideo","args":""}',
      "*"
    );
  };

  useEffect(() => {
    if (!desktopInView && videoLoaded) {
      handlePlayPause(iframeRef.current, "pause");
    }
    if (!mobileInView && videoLoaded) {
      handlePlayPause(iframeRef.current, "pause");
    }
    // eslint-disable-next-line
  }, [desktopInView, mobileInView, videoLoaded]);

  return (
    <>
      {/* Desktop Layout */}
      {!isMobile && (
        <Box
          ref={desktopRef}
          sx={{
            padding: "0 20px 0 90px !important",
            display: "flex",
            gap: 2
          }}
        >
          <Box
            sx={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              gap: "55px"
            }}
          >
            <ERPHeader />
            <ERPHomeButtons />
          </Box>

          <Box display="flex" alignItems="center" sx={{ width: "50%" }}>
            {!videoLoaded ? (
              <img
                loading="eager"
                src={YoutubePreviewWeb}
                alt="YouTubeVideo"
                width={imageWidth}
                height={imageHeight}
                style={{
                  width: "600px",
                  height: "420px",
                  aspectRatio: `${aspectRatio}`,
                  cursor: "pointer"
                }}
                onClick={() => playVideoOnClick(setVideoLoaded, iframeRef)}
              />
            ) : (
              <iframe
                ref={iframeRef}
                loading="lazy"
                id="desktop-iframe"
                title="ERP for SMEs"
                width="600px"
                height="420px"
                src={`${baseVideoSrc}&autoplay=1`}
                style={{ border: "none" }}
                onLoad={() => handlePlayPause(iframeRef.current, "play")}
              />
            )}
          </Box>
        </Box>
      )}
      {/* Mobile Layout */}
      {isMobile && (
        <Box
          ref={mobileRef}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 20px !important",
            gap: 2
          }}
        >
          <ERPHeader />
          {!videoLoaded ? (
            <img
              loading="lazy"
              src={YoutubePreviewMob}
              alt="YouTubeVideoMobile"
              width={imageWidth}
              height={imageHeight}
              style={{
                width: "100%",
                height: "auto",
                aspectRatio: `${aspectRatio}`,
                objectFit: "cover",
                cursor: "pointer"
              }}
              onClick={() => playVideoOnClick(setVideoLoaded, iframeRef)}
            />
          ) : (
            <iframe
              ref={iframeRef}
              loading="lazy"
              id="mobile-iframe"
              title="ERP for SMEs"
              style={{
                width: "100%",
                minHeight: "320px",
                border: "none"
              }}
              src={`${baseVideoSrc}&autoplay=1`}
              onLoad={() => handlePlayPause(iframeRef.current, "play")}
            />
          )}
          <ERPHomeButtons />
        </Box>
      )}
    </>
  );
};

export default ERPSliderComponent;
