import { getCustomerFilters } from "../../../sales/services";
import { queryKeys } from "../../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchCustomerFilters = () => {
  const queryCustomerFilters = useQuery(
    [queryKeys.queryFetchCustomerFilters],
    () => getCustomerFilters(),
    {
      refetchOnWindowFocus: false
    }
  );

  return queryCustomerFilters;
};
