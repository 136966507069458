import React from "react";

import { CircularProgress, Grid } from "@mui/material";
import { useFetchSOTransactionsById } from "../../query-hooks/ClientSales/SalesOrder/useFetchSOTransactionsById";
import TransactionLedger from "../../purchase/pages/TransactionLedger";

const SOTransactions = ({ orderId, hideDrawer }) => {
  const { data, isFetching } = useFetchSOTransactionsById(orderId);
  const HeaderLabels = {
    Title: "SO Transactions",
    AmountLabel: "(Received Total)",
    NoteLabel: "Credit Note"
  };
  return isFetching ? (
    <Grid
      item
      xs={12}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      height={"100%"}
    >
      <CircularProgress />
    </Grid>
  ) : (
    <TransactionLedger
      HeaderLabels={HeaderLabels}
      hideDrawer={hideDrawer}
      orderInfo={data?.saleOrder}
      InvoiceInfo={data?.invoices}
      NoteInfo={data?.creditNotes}
    />
  );
};

export default SOTransactions;
