import { getSkuFilters } from "../../inventory/services/index";
import { queryKeys } from "../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchSkuFilters = () => {
  const querySkuFilters = useQuery(
    [queryKeys.queryFetchSkuFilters],
    () => getSkuFilters(),
    {
      refetchOnWindowFocus: false
    }
  );

  return querySkuFilters;
};
