import { Box, Typography } from "@mui/material";
import { isMobile } from "react-device-detect";

const IndustryCardItem = (props) => {
  const { id, image1, label } = props;
  const imageWidth = isMobile ? 320 : 352; 
  const imageHeight = isMobile ? 200 : 230;
  const aspectRatio = imageWidth / imageHeight;
  return (
    <Box
      id={id}
      position={"relative"}
      display={"flex"}
      overflow={"hidden"}
      flexDirection={"column"}
    >
      <img
        loading="lazy"
        src={image1}
        alt={label}
        width={imageWidth}
        height={imageHeight}
        style={{
          width: "100%",
          height: "auto",
          aspectRatio: aspectRatio
        }}
      />
      <Box
        sx={{
          position: "absolute",
          padding: "30px 32px",
          background: "#000000",
          opacity: 0.4,
          bottom: 0,
          left: 0,
          width: "100%",
          maxHeight: 80
        }}
      />
      <Typography
        color={"white"}
        sx={{
          fontSize: {
            xs: 16,
            md: 18
          },
          lineHeight: {
            xs: "19px",
            md: "22px"
          },
          fontWeight: {
            xs: 600,
            md: 500
          },
          position: "absolute",
          bottom: 22,
          left: 32
        }}
        letterSpacing={"0.002em"}
      >
        {label}
      </Typography>
    </Box>
  );
};

export default IndustryCardItem;
