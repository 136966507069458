import { getSalesOrderFormData } from "../../../sales/services";
import { queryKeys } from "../../../constants/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useFetchSalesOrderFormData = (orderType) => {
  const querySalesOrderFormData = useQuery(
    [queryKeys.queryFetchSalesOrderFormData],
    () => getSalesOrderFormData(),
    {
      enabled: Boolean(orderType === "salesOrder"),
      refetchOnWindowFocus: false,
      refetchInterval:false
    }
  );

  return querySalesOrderFormData;
};
