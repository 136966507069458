import { queryKeys } from "../../constants/queryKeys";
import { getDebitNoteCreateFormData } from "../../finance/services";
import { useQuery } from "@tanstack/react-query";

export const useFetchDebitNoteCreateFormData = (orderId, invId, orderType) => {
  const DebitNoteFormData = useQuery(
    [queryKeys.queryFetchDebitNoteCreateFormData, orderId, Boolean(invId)],
    () => getDebitNoteCreateFormData(orderId),
    {
      enabled:
        !Boolean(invId) &&
        Boolean(orderId) &&
        Boolean(orderType === "PurchaseReturn"),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  );

  return DebitNoteFormData;
};
