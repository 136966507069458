import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import { Grid, Typography } from "@mui/material";

import AutoCompleteMapField from "./AutoCompleteMapField";
import { SearchFormKeys } from "../../constants/formKeys";
import { StyledButton } from "./StyledButton";
import { labels } from "../../constants";
import { useForm } from "react-hook-form";
import { useState } from "react";

const defaultLocation = { lat: 19.075983, lng: 72.877655 };

const container = {
  width: "auto",
  height: "400px"
};

function GMapLocationConfirm({
  resetParentAutoComplete,
  searchedValue,
  lat,
  lng
}) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY
  });
  const [mapRef, setMapRef] = useState(null);
  const [center, setCenter] = useState(defaultLocation);
  const [address, setAddress] = useState("");
  const { control, reset, handleSubmit } = useForm({
    defaultValues: {
      [SearchFormKeys.ConfirmPickupLocation]: searchedValue || ""
    }
  });

  const generateAddress = (newLocation) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: newLocation }, (results, status) => {
      if (status === "OK") {
        setAddress(results[0].formatted_address);
        reset({
          [SearchFormKeys.ConfirmPickupLocation]: results[0].formatted_address
        });
      }
    });
  };

  const handleOnLoad = (map) => {
    setMapRef(map);
    if (window.google && window.google.maps) {
      const geocoder = new window.google.maps.Geocoder();
      if (lat && lng) {
        geocoder.geocode({ location: { lat, lng } }, (results, status) => {
          if (status === "OK" && results[0]) {
            setAddress(results[0].formatted_address);
            reset({
              [SearchFormKeys.ConfirmPickupLocation]:
                results[0].formatted_address
            });
          } else {
            setAddress("");
          }
        });
      } else if (searchedValue) {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address: searchedValue }, (results, status) => {
          if (status === "OK" && results[0]) {
            const location = results[0].geometry.location;
            setCenter({ lat: location.lat(), lng: location.lng() });
          } else {
            setCenter(defaultLocation);
          }
        });
      }
    }
  };

  const handleCenterChanged = () => {
    if (mapRef) {
      const newCenter = mapRef.getCenter();
      const newLocation = { lat: newCenter.lat(), lng: newCenter.lng() };
      setCenter(newLocation);
      generateAddress(newLocation);
    }
  };

  if (loadError) return <>{"Error loading maps"}</>;
  if (!isLoaded) return <>{"Loading Maps"}</>;

  const onSubmit = () =>
    resetParentAutoComplete(address || searchedValue, center);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "20px",
            fontWeight: 500,
            lineHeight: "24px",
            letterSpacing: "0.002em",
            pt: 2
          }}
        >
          {labels.moveMapToAdjustLocation}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <AutoCompleteMapField
          control={control}
          name={SearchFormKeys.ConfirmPickupLocation}
          placeholder={labels.pickupLocationPlaceholderText}
          reset={resetParentAutoComplete}
        />
      </Grid>
      <Grid item xs={12}>
        <GoogleMap
          center={center}
          zoom={13}
          mapContainerStyle={container}
          onLoad={handleOnLoad}
          onDragEnd={handleCenterChanged}
        >
          <MarkerF position={center} />
        </GoogleMap>
      </Grid>
      <Grid item xs={12}>
        <StyledButton
          fullWidth={true}
          variant="contained"
          onClick={handleSubmit(onSubmit)}
        >
          {labels.confirm}
        </StyledButton>
      </Grid>
    </Grid>
  );
}

export { GMapLocationConfirm };
