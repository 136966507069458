import { submitPurchaseReturnDraft } from "../../../purchase/services";
import { useMutation } from "@tanstack/react-query";

export const useSubmitPurchaseReturnDraft = () => {
  const submitPurchaseReturnById = useMutation(
    ({ data, purchaseOrderId, purchaseReturnId }) =>
      submitPurchaseReturnDraft(data, purchaseOrderId, purchaseReturnId)
  );
  return submitPurchaseReturnById;
};
