import React from "react";
import { useForm } from "react-hook-form";
import {
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import styled from "@emotion/styled";
import { CalendarTodayOutlined } from "@mui/icons-material";
import DropDown from "../../shared/formElements/DropDown";
import DateInput from "../../shared/formElements/DateInput";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: { xs: "10px", md: "15px" },
  fontWeight: 400,
  lineHeight: { xs: "15px", md: "22.5px" }
}));

const SkuPerformanceTable = React.memo(() => {
  const { control, watch } = useForm({
    defaultValues: { topSellingSkus: "topSellingSKUs", timePeriod: "thisWeek" }
  });
  const watchTimePeriod = watch("timePeriod");
  const dummy_array = React.useMemo(() => [1, 2, 3, 4, 5], []);

  return (
    <Card
      sx={{
        boxShadow: "0px 4px 4px 0px #00000040",
        border: "1px solid #B7BEC7",
        borderRadius: "8px"
      }}
    >
      <CardContent>
        <Grid
          container
          mb={2}
          sx={{ padding: { xs: "8px 15px 0 15px", md: "10px 10px 0 20px" } }}
        >
          <Grid item xs={7} sm={7} md={6}>
            <DropDown
              control={control}
              name={"topSellingSkus"}
              placeholder={"Select Filter"}
              options={[
                { name: "topSellingSKUs", displayName: "Top Selling SKUs" },
                { name: "topMarginSKUs", displayName: "Top Margin SKUs" },
                { name: "topSellingBrands", displayName: "Top Selling Brands" },
                { name: "topMarginBrands", displayName: "Top Margin Brands" },
                { name: "worseSellingSKUs", displayName: "Worse Selling SKUs" },
                { name: "worseMarginSKUs", displayName: "Worse Margin SKUs" },
                {
                  name: "worseSellingBrands",
                  displayName: "Worse Selling Brands"
                },
                {
                  name: "worseMarginBrands",
                  displayName: "Worse Margin Brands"
                }
              ]}
            />
          </Grid>
          <Grid item xs={0.5} sm={0.5} md={2}></Grid>
          <Grid item xs={4.5} sm={4.5} md={4}>
            <DropDown
              control={control}
              name={"timePeriod"}
              placeholder={"Select Time-period"}
              options={[
                { name: "thisWeek", displayName: "This Week" },
                { name: "lastWeek", displayName: "Last Week" },
                { name: "thisMonth", displayName: "This Month" },
                { name: "lastMonth", displayName: "Last Month" },
                { name: "custom", displayName: "Custom" }
              ]}
            />
          </Grid>
        </Grid>
        {watchTimePeriod === "custom" && (
          <Grid
            container
            mb={2}
            sx={{ padding: { xs: "8px 15px", md: "10px 20px" } }}
          >
            <Grid item xs={12} md={6}>
              <DateInput
                name="customDateRange"
                control={control}
                selectsRange={true}
                minDate={new Date("2000-01-01")}
                endIcon={CalendarTodayOutlined}
                placeholder="Select date range"
                format="dd-MM-yyyy"
                popperPlacement="bottom-start"
              />
            </Grid>
          </Grid>
        )}
        <TableContainer>
          <Table>
            <TableHead sx={{ background: "#F3F3F4" }}>
              <TableRow>
                <StyledTableCell sx={{ color: "#0F2744" }}>
                  S.No
                </StyledTableCell>
                <StyledTableCell sx={{ color: "#0F2744" }}>
                  SKU Id
                </StyledTableCell>
                <StyledTableCell sx={{ color: "#0F2744" }}>
                  Value
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dummy_array.map((row, index) => (
                <TableRow key={index}>
                  <StyledTableCell sx={{ color: "#607088" }}>{`${
                    index + 1
                  }.`}</StyledTableCell>
                  <StyledTableCell
                    sx={{ color: "#607088" }}
                  >{`SKU_NAME_${row}`}</StyledTableCell>
                  <StyledTableCell sx={{ color: "#607088" }}>0</StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
});

export default SkuPerformanceTable;
