import { client } from "../../shared/api/client";

export const getSubscriptions = async (clientId) => {
  return await client
    .get(`/clients/${clientId}/subscriptions`)
    .then((res) => res.data.data);
};

export const getSubUsers = async (clientId) => {
  return await client
    .get(`/clients/${clientId}/sub-users`)
    .then((res) => res.data.data);
};

export const getCreateSubUserFormData = async (clientId) => {
  return await client
    .get(`/clients/${clientId}/sub-users/form-data`)
    .then((res) => res.data.data);
};

export const getUpdateSubUserFormData = async (clientId, subUserId) => {
  return await client
    .get(`/clients/${clientId}/sub-users/${subUserId}/form-data`)
    .then((res) => res.data.data);
};

export const createSubUser = async (clientId, data) => {
  return await client.post(`/clients/${clientId}/sub-users`, data);
};

export const updateSubUser = async (clientId, subUserId, data) => {
  return await client.put(`/clients/${clientId}/sub-users/${subUserId}`, data);
};

export const deleteSubUser = async (clientId, subUserId) => {
  return await client.delete(`/clients/${clientId}/sub-users/${subUserId}`);
};
